import { Redirect } from "react-router-dom";

import React, { useEffect, useState } from 'react';
import { store } from '../../redux/store';
import { connect } from 'react-redux'
import { resetToken } from '../../redux/token/token.action'
import { resetUser } from '../../redux/user/user.actions'
import { resetRole } from '../../redux/role/role.action';
import apiCallService from "../../service/api-call-service";
import { useToasts } from 'react-toast-notifications'
import { BoxLoading } from 'react-loadingg';


const LogOut = ({ mdn, roMdn }) => {
    const { addToast } = useToasts()

    const [update, setupdate] = useState(false)

    useEffect(() => {
        const handleAuthentication = () => {


            var url = process.env.REACT_APP_USER_LOGOUT;

            var fun = async () => {
                var request = {
                    "mdn": mdn,
                    "roId": roMdn

                }
                var response = await apiCallService.LogoutPostMethodCall(url, request);


                if (response !== undefined && response.response_code === 0) {
                    store.dispatch(resetUser)
                    store.dispatch(resetRole);
                    store.dispatch(resetToken)

                    console.log(store);
                    setupdate(true);
                } else {
                    store.dispatch(resetUser)
                    store.dispatch(resetRole);
                    store.dispatch(resetToken)
                    console.log(store);
                    setupdate(true);
                    // var content = `Somthing Went Wrong Please Try In A Bit. [ ${response.response_description} ]`;
                    // addToast(content, {
                    //     appearance: 'warning',
                    //     autoDismiss: true,
                    //     autoDismissTimeout: 7000
                    // })
                }

            }
            fun();


        }
        handleAuthentication();
    }, []);

    return (
        update ?
            <Redirect to="/" />
            :
            <div className="mid-container">
                <div className="col-12">
                    <div className="no-record">

                        <br />      <br />      <br />
                        <br />      <br />
                        <br /><br />      <br />
                        <br />
                        <h3> Logging out...</h3>
                        <BoxLoading />
                    </div>
                </div>
            </div>




    );


};

const mapStateToProps = ({ token: { token }, user: { mdn, roMdn } }) => ({
    token, mdn, roMdn
});


export default connect(mapStateToProps)(LogOut);