import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import Pagination from 'react-js-pagination';
import ApiCallingService from '../../service/api-call-service'
import UploadMoneyDisbursal from '../component/upload-money-disbursal.component';
import StateManager from 'react-select';
import apiCallService from '../../service/api-call-service';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import history from '../../auth/history';
import CategoryLimit from '../../constants/category-limit.type';
import { setEditBandDetail } from '../../redux/edit-band-limit/edit-band-limit.action';
import Can from '../../auth/can/Can';
import SubEmployeeBandDiv from './sub-emp-band.card.pages';








class EmployeeBandDiv extends React.Component {
    constructor() {
        super();
        this.state = { size: 0 };
    }


    componentDidMount = () => {
        this.setEMployeeListFunftion(this.props.data.band)
    }


    setEMployeeListFunftion = async (band) => {
        const url = `${process.env.REACT_APP_GET_EMPLOYEE_BY_EMPLYEE_BAND}?corp_bands=${band}&ro_Id=${this.props.roMdn}`
        var arr = []
        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log('response setemployeeList', url)
        if (response !== undefined && response.response !== undefined && response.status === 0 && response.size !== undefined) {
            console.log('response setemployeeList', response)
            this.setState({ size: response.size });

        }

    }
    render() {
        return (
            <div >

                <SubEmployeeBandDiv size={this.state.size} userRole={this.props.userRole} setEditBandDetailLink={this.props.setEditBandDetailLink}
                    data={this.props.data}
                />


            </div>
        )
    }
}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount,
    maxFoodAmount,
    maxTravelAmount }, user: { userRole }, role: { roleData }, token: { authenticated, token }, band: { bandData }, }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, userRole, roleData,
        maxTravelAmount, token
    });

export default connect(mapStateToProps)(EmployeeBandDiv);

