import CheckBoxType from './checkbox.types';

const INITIAL_STATE = {
    isActive:false
};

const checkBoxReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        
        case CheckBoxType.CHECKBOX_ACTIVE:
            console.log('CHECKBOX_ACTIVE');
            return {
                
                ...state,
                isActive: !state.isActive
            };
        default:
      return state;
    }
};

export default checkBoxReducer;