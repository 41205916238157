import React from 'react';

import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { setFromDashbard } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import apiCallService from '../../service/api-call-service';
import { connect } from 'react-redux';
import Can from '../../auth/can/Can';
import ReactApexChart from 'react-apexcharts'
import GenerateRequestId from '../../common-function/GenerateRequestId';
import CategoryLimit from '../../constants/category-limit.type';
import { Redirect } from 'react-router-dom';

import ResetLoginPassword from '../../corporate-profile/component/reset-login-password'



const state = {
    labels: ['Jan 2020', 'Feb 2020', 'Mar 2020', 'Apr 2020', 'May 2020', 'Jun 2020'],
    datasets: [{
        data: [589, 445, 483, 503, 689, 692, 634],
        backgroundColor: '#7fbb42',
        label: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
    },
    {
        data: [409, 445, 593, 403, 589, 692, 580],
        backgroundColor: '#32a0da',
        label: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
    }
    ]
}



const data = {
    labels: ['General', 'Food', 'Travel'],
    datasets: [{
        label: 'Categories',
        data: [230, 85, 130],
        backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
        borderWidth: 3,
        borderColor: '#e6ecfd',
    }]
}



class CorpDashBoardComponent extends React.Component {
    constructor() {
        super();
        this.state = {

            roMdn: '',
            cardDetail: [],
            employeeStatusDetail: [],
            balanceEnquiry: 0,
            assignedCardPercentage: 0,
            blockedCardPercentage: 0,
            availableCardPercentage: 0,

            noRecordForBar: false,
            noRecordForPie: false,

            requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH,

            buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH,

            barLoaderPie: false,

            barLoader: false,

            ///states for graph
            series: [{
                name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
                data: [0, 0],
                backgroundColor: '#7fbb42',
            }, {
                name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
                data: [0, 0],
                backgroundColor: '#32a0da',
            }],
            options: {
                chart: {
                    height: 250,
                    type: 'area',
                    // toolbar: {
                    // 	show: false
                    //   }
                },
                fill: {
                    color: ['#32a0da', '#7fbb42']
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'date',
                    categories: []
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },


            ///states for Doughnut
            seriesDoughnut: [],
            optionsDoughnut: {
                chart: {
                    type: 'donut',

                },
                labels: [],
                backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
                responsive: [{
                    breakpoint: 480,
                    options: {

                    }
                }],
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    offsetY: 0,
                }
            },
        }

    }



    componentDidMount = () => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "active",
            adminMngCardClass: "",
            adminReportCLass: ""
        }

        store.dispatch(setActiveHeader(payload));

        this.setState({ roMdn: process.env.REACT_APP_ROID_FOR_SUPER_ADMIN_GRAPH })
        const url = `${process.env.REACT_APP_GET_COUNTWISE_CARD_DETAIL}`;

        const getResult = async () => {
            var response = await apiCallService.getMethodCallWithoutParam(url);
            console.log('ManageCard response ', response)
            if (response !== undefined && response.status !== undefined && response.status === 0) {
                console.log('ManageCard response ', response)
                this.setState({ cardDetail: response.response })
                // setCardDetail(response.response);

            } else {

            }
        }
        getResult();

        this.fetchBalanceDetail();


        this.getTaReport();

        this.pieData();
        this.getCorpDetail();


    }


    getCorpDetail = async () => {
        const url = process.env.REACT_APP_GET_CORPORATE_STATUS_COUNT;
        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log('ManageCard response getCorpDetail', response)
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log('ManageCard response ', response)
            this.setState({ employeeStatusDetail: response })
            // setCardDetail(response.response);

        } else {

        }
    }


    pieData = async () => {

        var seriesDoughnut = []
        var optionsDoughnut = {
            chart: {
                type: 'donut',

            },
            labels: ['General', 'Food', 'Travel'],
            backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
            responsive: [{
                breakpoint: 480,
                options: {

                }
            }],
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetY: 0,
            }
        }
        var labelToSet = [];


        var url = process.env.REACT_APP_API_WALLET_WISE_TA_REPORT


        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());

        var currentDatedd = currentDate.getDate();
        var currentDatemm = currentDate.getMonth() + 1;
        var currentDatey = currentDate.getFullYear();

        var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
        var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
        var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

        var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;


        var someDate = new Date();
        var numberOfDaysToAdd = parseInt(process.env.REACT_APP_CATEGORY_WISE_REPORT_1MONTHS_TIME_IN_DAYS);
        someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

        var dd = someDate.getDate();
        var mm = someDate.getMonth() + 1;
        var y = someDate.getFullYear();



        var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
        var masky = `${mm}`.length === 1 ? '0' + mm : mm



        var someFormattedDate = y + '-' + masky + '-' + maskdd


        console.log('dateRang', dateRang)

        var request = {
            "request": {
                "user": {
                    // "username": `91${this.state.roMdn}`,
                    // "username": `9887835909`
                },
                "transaction_data": {
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "page_index": 0,
                    "from_date": `${someFormattedDate} 00:00:00`,
                    "to_date": `${dateRang} 23:59:59`,
                }
            }
        }

        var foodValue = ''
        var travelValue = ''
        var generalValue = ''

        var response = await apiCallService.postMethodCall(url, request);

        console.log("PIE REPORT RESPONSE", request, response);

        if (response !== undefined && response.response_code === "2206" && response.walletWisesReport !== undefined && response.walletWisesReport !== null) {
            response.walletWisesReport.map(wallet => {
                if (parseInt(wallet.walletId) === parseInt(CategoryLimit.GENERAL)) {
                    generalValue = wallet.numberOfTxn
                } else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.FOOD)) {
                    foodValue = wallet.numberOfTxn
                } else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.TRAVEL)) {
                    travelValue = wallet.numberOfTxn
                }
            })
            if (generalValue !== '') {
                labelToSet.push('General')
                seriesDoughnut.push(generalValue)
            }
            if (foodValue !== '') {
                labelToSet.push('Food')
                seriesDoughnut.push(foodValue)
            }
            if (travelValue !== '') {
                labelToSet.push('Travel')
                seriesDoughnut.push(travelValue)
            }
            optionsDoughnut.labels = labelToSet

            this.setState({ noRecordForPie: false })
            this.setState({ seriesDoughnut: seriesDoughnut });
            this.setState({ optionsDoughnut: optionsDoughnut });
            this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH });
            this.setState({ barLoaderPie: false })
        } else {
            this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH });

            this.setState({ noRecordForPie: true })
            this.setState({ barLoaderPie: false })
        }


    }


    getTaReport = async () => {
        const url = process.env.REACT_APP_GET_TA_REPORT;


        var seriesToAdd = [{
            name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
            data: [0, 0],
            backgroundColor: '#7fbb42',
        }, {
            name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
            data: [0, 0],
            backgroundColor: '#32a0da',
        }]
        var arraySeries = [];
        var arraySeries1 = [];
        var arrayOption = {
            chart: {
                height: 250,
                type: 'area',
                // toolbar: {
                // 	show: false
                //   }
            },
            fill: {
                color: ['#32a0da', '#7fbb42']
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'date',
                categories: []
            },
            tooltip: {
                x: {
                    format: 'yyyy-mm-dd'
                },
            },
        }

        var categoriesToSet = []



        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());

        var currentDatedd = currentDate.getDate();
        var currentDatemm = currentDate.getMonth() + 1;
        var currentDatey = currentDate.getFullYear();

        var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
        var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
        var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

        var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;




        var someDate = new Date();
        var numberOfDaysToAdd = process.env.REACT_APP_FUNDLOADED_AND_DISBUSED_DETAIL_SHOWING_TIME_IN_DAYS;
        someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

        var dd = someDate.getDate();
        var mm = someDate.getMonth() + 1;
        var y = someDate.getFullYear();



        var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
        var masky = `${mm}`.length === 1 ? '0' + mm : mm



        var someFormattedDate = y + '-' + masky + '-' + maskdd


        console.log('dateRang', dateRang)

        var request = {
            "request": {
                "user": {
                    // "username": `91${this.state.roMdn}`,
                    // "username": `9887835909`
                },
                "transaction_data": {
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "page_index": 0,
                    "from_date": `${someFormattedDate} 00:00:00`,
                    "to_date": `${dateRang} 23:59:59`,
                }
            }
        }


        var response = await apiCallService.postMethodCall(url, request);

        console.log("TA REPORT RESPONSE", request, response);
        if (response !== undefined && response.response_code === "2206" && response.debitCreditReport !== undefined) {


            response.debitCreditReport.map(detail => {

                if (detail.transactionType === 'credit') {
                    arraySeries.push(detail.amount / 100)
                } else {
                    arraySeries1.push(detail.amount / 100)
                }

                if (categoriesToSet.length === 0) {
                    categoriesToSet.push(detail.date)
                }

                var index = categoriesToSet.findIndex(det => det === detail.date);
                index === -1 ? categoriesToSet.push(detail.date) : console.log("object already exists")



            })

            seriesToAdd.map(data => {
                if (data.name.toLocaleLowerCase().includes('load')) {
                    data.data = arraySeries1
                } else {
                    data.data = arraySeries
                }

            })
            arrayOption.xaxis.categories = categoriesToSet;

            this.setState({ noRecordForBar: false })
            this.setState({ series: seriesToAdd })
            this.setState({ options: arrayOption });
            this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH })
            this.setState({ barLoader: false })
        } else {
            this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH })
            this.setState({ noRecordForBar: true })
            this.setState({ barLoader: false })
        }

    }


    // fetchBalanceDetail = async () => {

    //     //resp =await BalanceEnquiryService.balanceEnquiry('http://172.160.0.249:7072/api/wallet/BalanceEnquiry', '919887835909', "")

    //     const request = {
    //         device_info: {
    //             api_version: "1.0",
    //             app_version: "9.2",
    //             device_id: "d215ab007e05c498",
    //             device_os: "Android",
    //             device_os_version: "5.1.1",
    //             imei: "357869081907005",
    //             ip_address: "172.160.2.140",
    //             model_name: "SM-J320F",
    //             source: "mobile",
    //         },
    //         request: {
    //             user: {
    //                 username: this.state.roMdn
    //             },
    //             transaction_data: {
    //                 request_id: '988df6a3-8bd6-495e-8373-05bd4671ccfb',
    //                 limit_required: "true"
    //             }
    //         }
    //     };
    //     let headers = {
    //         "client_id": "OEMSAPP",
    //         "token": this.props.token,
    //         "Authorization": "BASIC abcd"
    //     }
    //     console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props);
    //     var response = [];
    //     // async function run() {
    //     const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
    //     console.log(url, request, "header")
    //     response = await apiCallService.postMethodCall(url, request, headers);
    //     if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
    //         const detail = response.response;
    //         console.log('response balanceEnquiry', response.response.Balance.total_balance);

    //         // this.setState({ balanceEnquiry :   parseFloat(parseFloat(response.response.Balance.total_balance)/100).toFixed(2)  });
    //         this.setState({ balanceEnquiry: parseFloat(parseFloat(response.response.Balance.total_balance) / 100).toFixed(2) });

    //     }
    //     // }
    //     // run();


    // }
    fetchBalanceDetail = async () => {
		const request = {
		 clientIndentifier:  this.props.roMdn
		};
		let headers = {
			"client_id": "OEMSAPP",
			"token": this.props.token,
			"Authorization": "BASIC abcd"
		}
		console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props.mdn, this.props.roMdn);
		var response = [];
		// async function run() {
		const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY_CORP
		console.log(url, request, "header")
		response = await apiCallService.postMethodCall(url, request, headers);
		if (response !== undefined && response.response_code !== undefined && response.response_code == 0 && response.response !== undefined) {
		
            this.setState({ balanceEnquiry: parseFloat(response?.response?.availableBalance).toFixed(2) })
            
            
		}
		


	}


    onClickMonths = async (event) => {

        this.setState({ barLoader: true })
        console.log('event.target[3].innerText', event.target.innerText)


        if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH) {
            var seriesToAdd = [{
                name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
                data: [0, 0],
                backgroundColor: '#7fbb42',
            }, {
                name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
                data: [0, 0],
                backgroundColor: '#32a0da',
            }]
            var arraySeries = [];
            var arraySeries1 = [];
            var arrayOption = {
                chart: {
                    height: 250,
                    type: 'area',
                    // toolbar: {
                    // 	show: false
                    //   }
                },
                fill: {
                    color: ['#32a0da', '#7fbb42']
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'text',
                    categories: []
                },
                tooltip: {
                    // x: {
                    // 	format: ''
                    // },
                },
            }

            var categoriesToSet = []

            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate());

            var currentDatedd = currentDate.getDate();
            var currentDatemm = currentDate.getMonth() + 1;
            var currentDatey = currentDate.getFullYear();

            var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
            var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
            var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

            var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;




            var someDate = new Date();
            var numberOfDaysToAdd = parseInt(process.env.REACT_APP_CATEGORY_WISE_REPORT_6MONTHS_TIME_IN_DAYS);
            someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();



            var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
            var masky = `${mm}`.length === 1 ? '0' + mm : mm



            var someFormattedDate = y + '-' + masky + '-' + maskdd



            const url = process.env.REACT_APP_GET_TA_REPORT;
            var request = {
                "request": {
                    "user": {
                        // "username": `91${this.state.roMdn}`,
                        // "username": `9887835909`
                    },
                    "transaction_data": {
                        "request_id": GenerateRequestId.geneRateRequestId(),
                        "requestType": "monthly",
                        "from_date": `${someFormattedDate} 00:00:00`,
                        "to_date": `${dateRang} 23:59:59`,
                    }
                }
            }
            var response = await apiCallService.postMethodCall(url, request);

            console.log("TA REPORT RESPONSE", request, response);
            var response = await apiCallService.postMethodCall(url, request);

            console.log("TA REPORT RESPONSE", request, response);
            if (response !== undefined && response.response_code === "2206" && response.debitCreditReport !== undefined) {


                response.debitCreditReport.map(detail => {

                    if (detail.transactionType === 'credit') {
                        arraySeries.push(detail.amount / 100)
                        // arraySeries1.push(0)
                    } else {
                        // arraySeries.push(0)
                        arraySeries1.push(detail.amount / 100)
                    }
                    if (categoriesToSet.length === 0) {
                        categoriesToSet.push(detail.dateString)
                    }
                    var index = categoriesToSet.findIndex(det => det === detail.dateString);
                    index === -1 ? categoriesToSet.push(detail.dateString) : console.log("object already exists")

                })

                seriesToAdd.map(data => {
                    if (data.name.toLocaleLowerCase().includes('load')) {
                        data.data = arraySeries1
                    } else {
                        data.data = arraySeries
                    }

                })
                arrayOption.xaxis.categories = categoriesToSet;
                console.log("categories debitCreditReport", response.debitCreditReport);
                console.log("categories categoriesToSet", categoriesToSet);
                console.log("categories arraySeries1 : load ", arraySeries1);
                console.log("categories arraySeries : disburse ", arraySeries);
                this.setState({ noRecordForBar: false })
                this.setState({ series: seriesToAdd })
                this.setState({ options: arrayOption });
                this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_1MONTH })
                this.setState({ barLoader: false })

            } else {

                this.setState({ noRecordForBar: true })
                this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_1MONTH })
                this.setState({ barLoader: false })
            }

        }

        else if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_1MONTH) {

            this.getTaReport();
        }


    }


    pieButtonChange = async (event) => {
        this.setState({ barLoaderPie: true })
        console.log('event.target[3].innerText', event.target.innerText)

        if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH) {
            var seriesDoughnut = []
            var optionsDoughnut = {
                chart: {
                    type: 'donut',

                },
                labels: ['General', 'Food', 'Travel'],
                backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
                responsive: [{
                    breakpoint: 480,
                    options: {

                    }
                }],
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    offsetY: 0,
                }
            }
            var labelToSet = [];


            var url = process.env.REACT_APP_API_WALLET_WISE_TA_REPORT


            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate());

            var currentDatedd = currentDate.getDate();
            var currentDatemm = currentDate.getMonth() + 1;
            var currentDatey = currentDate.getFullYear();

            var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
            var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
            var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

            var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;




            var someDate = new Date();
            var numberOfDaysToAdd = parseInt(process.env.REACT_APP_CATEGORY_WISE_REPORT_6MONTHS_TIME_IN_DAYS);
            someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();



            var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
            var masky = `${mm}`.length === 1 ? '0' + mm : mm



            var someFormattedDate = y + '-' + masky + '-' + maskdd


            console.log('dateRang', dateRang)

            var request = {
                "request": {
                    "user": {
                        // "username": `91${this.state.roMdn}`,
                        // "username": `9887835909`
                    },
                    "transaction_data": {
                        "request_id": GenerateRequestId.geneRateRequestId(),
                        "page_index": 0,
                        "from_date": `${someFormattedDate} 00:00:00`,
                        "to_date": `${dateRang} 23:59:59`,
                    }
                }
            }

            var foodValue = ''
            var travelValue = ''
            var generalValue = ''

            var response = await apiCallService.postMethodCall(url, request);

            console.log("PIE REPORT RESPONSE", request, response);

            if (response !== undefined && response.response_code === "2206" && response.walletWisesReport !== undefined && response.walletWisesReport !== null) {
                this.setState({ noRecordForPie: false })
                response.walletWisesReport.map(wallet => {
                    if (parseInt(wallet.walletId) === parseInt(CategoryLimit.GENERAL)) {
                        generalValue = wallet.numberOfTxn
                    } else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.FOOD)) {
                        foodValue = wallet.numberOfTxn
                    } else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.TRAVEL)) {
                        travelValue = wallet.numberOfTxn
                    }
                })

                if (generalValue !== '') {
                    labelToSet.push('General')
                    seriesDoughnut.push(generalValue)
                }
                if (foodValue !== '') {
                    labelToSet.push('Food')
                    seriesDoughnut.push(foodValue)
                }
                if (travelValue !== '') {
                    labelToSet.push('Travel')
                    seriesDoughnut.push(travelValue)
                }
                optionsDoughnut.labels = labelToSet


                this.setState({ seriesDoughnut: seriesDoughnut });
                this.setState({ optionsDoughnut: optionsDoughnut });
                this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_1MONTH });
                this.setState({ barLoaderPie: false })
            } else {
                this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_1MONTH });
                this.setState({ noRecordForPie: true })
                this.setState({ barLoaderPie: false })
            }


        } else if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_1MONTH) {
            this.pieData();
        }
    }


    render() {

        console.log('respEmployee this.state', this.state)
        return (


            <div className="mid-container">

                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset ?

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg">
                                        <div className="dash-card">
                                            <div className="dash-head">
                                                <i className="emp"></i>
                                                <h5>{this.state.employeeStatusDetail.totalCorpCount}</h5>
                                                <p>{process.env.REACT_APP_Var_FOR_Admin_Dashboard_Total_Corporates}

                                                    <a href="/corp-list" className="sbtn btnbl">View All</a>

                                                </p>
                                            </div>

                                            <div className="total-emp">
                                                <i className="atv">&#10004;</i>
                                                <h6>{this.state.employeeStatusDetail.activeCorpCount}</h6>
                                                <p>{process.env.REACT_APP_Var_FOR_Admin_Dashboard_Active_Corporate}</p>
                                            </div>

                                            <div className="total-emp">
                                                <i className="inatv">&#10006;</i>
                                                <h6>{this.state.employeeStatusDetail.inActiveCorpCount}</h6>
                                                <p>{process.env.REACT_APP_Var_FOR_Admin_Dashboard_Inactive_Corporate}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg">
                                        <div className="dash-card dashSize"  >
                                            <div className="dash-head">
                                                <i className="inv"></i>
                                                <h5>{this.state.cardDetail.totalCount}</h5>
                                                <p>{process.env.REACT_APP_Var_FOR_Admin_Dashboard_Total_Cards_Ordered}
                                                    <a href="/corp-order-history" className="sbtn btnbl">View all</a>
                                                </p>
                                            </div>

                                            <div className="card-stock">
                                                <div className="stock-txt">
                                                    <h6>{this.state.cardDetail.inStockCOunt}</h6>
                                                    <p>{process.env.REACT_APP_Var_FOR_Admin_Dashboard_In_stock}</p>
                                                </div>

                                                <div className="stock-bar">
                                                    <div className="progress" style={{ "height": "10px" }}>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ "width": (this.state.cardDetail.inStockCOunt / this.state.cardDetail.totalCount * 100) + "%" }} ></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-stock">
                                                <div className="stock-txt">
                                                    <h6>{this.state.cardDetail.assignedToCorpCount}</h6>
                                                    <p>{process.env.REACT_APP_Var_FOR_Admin_Dashboard_Assigned}</p>
                                                </div>
                                                <div className="stock-bar">
                                                    <div className="progress" style={{ "height": "10px" }}>
                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ "width": (this.state.cardDetail.assignedToCorpCount / this.state.cardDetail.totalCount * 100) + "%" }} ></div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-8">
                                        <div className="dash-card">
                                            <div className="chart-head">
                                                <h2>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Funds_Loaded_Disbursed}

                                                    <button className="sbtn btngr month" onClick={this.onClickMonths} disabled={this.state.barLoader}>
                                                        {
                                                            this.state.barLoader
                                                                ?
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                </div> :
                                                                <>{this.state.requestTypeMonthly}</>
                                                        }
                                                    </button>
                                                </h2>
                                            </div>

                                            <div className="dash-chart">

                                                {
                                                    this.state.noRecordForBar
                                                        ?
                                                        <div className="col-12">
                                                            <div className="no-record">
                                                                <i></i>
                                                                <h3> No Record Available.</h3>
                                                            </div>
                                                        </div>
                                                        :
                                                        <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <div className="dash-card">
                                            <div className="chart-head">
                                                <h2>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed}



                                                    <button className="sbtn btngr month" onClick={this.pieButtonChange} disabled={this.state.barLoaderPie}>
                                                        {
                                                            this.state.barLoaderPie
                                                                ?
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                </div>
                                                                :
                                                                <>{this.state.buttonValueForPieChart}</>
                                                        }
                                                    </button>



                                                </h2>

                                            </div>
                                            <div className="dash-chart">

                                                {
                                                    this.state.noRecordForPie
                                                        ?
                                                        <div className="col-12">
                                                            <div className="no-record">
                                                                <i></i>
                                                                <h3> No Record Available.</h3>
                                                            </div>
                                                        </div>
                                                        :
                                                        <ReactApexChart options={this.state.optionsDoughnut} series={this.state.seriesDoughnut} type="donut" />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            :
                            <ResetLoginPassword />
                    )
                    :
                    <Redirect to="/" />
                }
            </div>


        )
    }

}

const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, mdn, roMdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset
});

export default connect(mapStateToProps)(CorpDashBoardComponent);
