
import rules from "../rules/rbac-rules";
import { connect } from "react-redux";
import { store } from "../../redux/store";
import { setRoleAdminBoolean } from "../../redux/role/role.action";

const check = (rules, role, action, data,roleData) => {


  const permissionsToRedux =roleData;
  var dataToRole=[];
  var x;
  if(permissionsToRedux){
  for(x of permissionsToRedux){
    dataToRole.push(x)
  }}
 
  
  if (!dataToRole) {
    // role is not present in the rules
   // console.log('inside permission if method');
    return false;
  }

  if(dataToRole.includes(action)){
   // console.log('Yes I can Use this for returning true');
    return true
  }

  //console.log('outside Everything')
  return false;
};

const Can = props =>
  check(rules, props.role, props.perform, props.data,props.roleData)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

const mapStateToProps = ({  role: {roleData}}) => ({

  roleData
  });

export default connect(mapStateToProps)(Can);