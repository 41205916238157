import React from 'react';
import { connect } from 'react-redux';

import './App.css';
import { Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import EmployeeList from '../src/employee/pages/employee-list.pages'
// import HomePage from './employee/pages/home.pages';
import { resetUser, setCurrentUser, setSessionExpire } from './redux/user/user.actions';
import Callback from '../src/auth/pages/callback';
import Dashboard from './employee/pages/dashboard.pages';

import ErrorPage from './employee/pages/error.page';
import EmployeeDetail from './employee/pages/employee-detail.page';
import AddNewEmployee from './employee/pages/add-new-employee.pages'
import LogoutComponent from './employee/component/LogoutComponent'
import Header from './common/component/header.component'
import Footer from "./common/component/footer.component";
import SubHeader from './common/component/subheader.component';
import UpdateEmployee from './employee/component/update-employee.component';
import UploadHistory from './employee/pages/upload-history.pages';
import SuccessRecord from "./employee/component/success-record.component";
import SingleSuccessDisbursal from "./employee/component/single-success-record.component";
import FailedRecord from './employee/component/failed-record.component';
import RemovedEmployee from './employee/component/removed-employee.component';
import InProgressRecord from './employee/component/inprogress-record';
import MoneyDisbursal from './money-disbursal/pages/money-disbursal.pages';
import SetBandWiseDisbursalLimits from './money-disbursal/pages/set-band-wise-limits-new.pages';
import SuccessDisbursal from './money-disbursal/pages/success-disbursal.pages';
import FailedDisbursal from './money-disbursal/pages/failed-disbursal.pages';
import CheckerSuccessDisbursal from './money-disbursal/pages/checker-success-disbursal.pages';
import DraftDisbursal from './money-disbursal/pages/draft-status-disbursal.pages';
import InActiveEmployee from './employee/pages/in-active-employeeProfile';
// import EditBandLimit from './money-disbursal/component/edit-band-limit.component'
import ManageCard from './manage-card/pages/manage-card.pages';
import OrderHistory from './manage-card/component/oder-history/all-oder-history.component';
import AssignedCards from './manage-card/component/assigned-card/assigned-card.component';
import BlockedCards from './manage-card/component/blocked-card/blocked-card.component';
import CorporateProfile from './corporate-profile/pages/corporate-profile.pages';
import RolePermissions from './role-permission/pages/role-permission.pages'

import SuperAdminRolePermissions from './role-permission/pages/superAdmin-role-permission.pages'

import CreateRole from './role-permission/component/create-role.component'
import ResetTxnPassword from './corporate-profile/component/reset-txn-password.component'
import BandDetails from './money-disbursal/component/band-details.component';
import UserKyc from './manage-card/component/kyc-proccess/user-kyc.component';
import history from './auth/history';
import TransactionReport from './dasboard-reports/component/txn-report.component';
import CreateNewBand from './money-disbursal/component/create-new-band.component';
import TAReport from './dasboard-reports/component/ta-report.component';
import ViewTA from './dasboard-reports/component/view-ta.component';
import Notification from './notification/component/notification.component';
import ZeroLogin from './login_sha/zero-login-component/zero.login.component';
import AllEmployeeTransactionReport from './dasboard-reports/component/allEmployee-txn-report.coponent';
import ActivityLog from './activity-log/component/activity-log.component';
import CorporateList from './corporate/pages/corporate-list.pages';
import CorporateProfileDetail from './corporate/component/corporate-detail.pages'
import AddNewCorp from './corporate/component/add-new-corp.component'

import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';

import IdleMonitor from './common/component/idle-monitor.component';
import { store } from './redux/store';
import { resetRole } from './redux/role/role.action';
import { resetToken } from './redux/token/token.action';

import LoadMoney from './load-money/pages/load-money.pages';
import LoadMoneyDetail from './load-money/component/load-money.detail.component';
import LoadTxnHistory from './dasboard-reports/component/load-txn.history';

import ResetLoginPassword from './corporate-profile/component/reset-login-password';

import ForgotPassword from './login_sha/forgot-password/forgot-password.component';
import OTPVarify from './login_sha/forgot-password/otp.validation.component';
import ResetPassword from './login_sha/forgot-password/reset-password.componet';
import ResetPasswordSuccessfull from './login_sha/forgot-password/password-reset-successful.component';


import MisReport from './corporate/pages/mis-report.pages'
import CardBalanceReport from './corporate/pages/card-balance-report.pages'
import TransactionDumpReport from './corporate/pages/transaction-dump-report.pages'
import My404Component from './common/component/my404.component'

import AddCorpEmp from './corporate/pages/add-corp-employee.pages';
import CorporateDashboard from './corporate/component/corporate-dashboard.component';

import AdminMangeCard from './corporate/component/admin-manage-card.component';

import CorpOrderHistory from './corporate/component/corp-order-history.component';

import LoadInfo from './load-money/component/load-info.compoent';

import ReversalMoney from './load-money/pages/reversal/reversal-money.pages';

import EmpManageCard from './employee/pages/emp-mange-card.pages';

import AddCorpBankAccount from './corporate-profile/pages/bank/add-corpBank.pages';

import AddBankAccount from './corporate/pages/bank/add-corpBank.pages';

import CorpManageReversal from './corporate/pages/reversal/corp-mange-reversal.pages'

import ReversalMoneyDetail from './load-money/pages/reversal/reversal-money.detail.component';

import ViewAccessLogin from './view-only-accesss/component/forgot-password.component';

import VerifyOtpScreen from './view-only-accesss/component/otp.validation.component';

import SwitchToViewAcess from './view-only-accesss/component/switch.component'

import MangaeGiftCard from './manage-giftCard/pages/mange-giftCard.pages';

import GiftCardHistory from './manage-giftCard/component/history/history-giftCard.pages';

import IssuedGiftCard from './manage-giftCard/pages/issued-giftCard.pages';

import GiftcardsBeneficiary from './manage-giftCard/pages/giftcards-beneficiary.pages';

import SuccessReviewGiftCard from './manage-giftCard/component/success-reviewPage';

import FailReviewGiftCard from './manage-giftCard/component/fail-reviewPage';

class App extends React.Component {


    constructor() {
        super();

        this.state = {
            currentUser: null,
            timeout: 1000 * 5 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        };
    }

    _onAction(e) {
        console.log('user did something', e)
        this.setState({ isTimedOut: false })
    }

    _onActive(e) {
        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle(e) {
        console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            this.props.history.push('/')
        } else {
            this.setState({ showModal: true })
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }

    }

    componentDidMount = () => {
        console.log("this.props.sessionExpire", this.props.sessionExpire)
        if (this.props.sessionExpire && !window.location.href.includes("/logout")) {
            console.log("this.props.sessionExpire inside if", this.props.sessionExpire)
            store.dispatch(setSessionExpire(false))
            store.dispatch(resetRole);
            store.dispatch(resetToken)
            store.dispatch(resetUser)
            console.log(store);

        }

        window.addEventListener("popstate", () => {
            history.go(1);
        });
    }


    render() {
        return (
            <>

                <IdleMonitor />

                {
                    this.props.currentUser ?
                        <>
                            <Header />
                            <SubHeader />
                        </>
                        :
                        null

                }

                < >
                    <Switch>
                        {/* <Route exact path="/" component={HomePage} /> */}

                        <Route exact path="/" component={this.props.adminView === true ? ViewAccessLogin : ZeroLogin} />
                        <Route exact path="/logout" component={LogoutComponent} />
                        <Route exact path="/callback" component={Callback} />
                        <Route exact path="/access-denied" component={ErrorPage} />

                        <Route exact path='/forgot-password' component={ForgotPassword} />
                        <Route exact path='/otp' component={OTPVarify} />
                        <Route exact path='/reset-password' component={ResetPassword} />
                        <Route exact path='/password-reset-successful' component={ResetPasswordSuccessfull} />

                        <Route exact path="/dashboard" component={Dashboard} />

                        <Route exact path="/emp-list" component={EmployeeList} />
                        <Route exact path="/add-new-emp" component={AddNewEmployee} />
                        <Route exact path="/upload-history" component={UploadHistory} />
                        <Route path="/success-record" component={SuccessRecord} />
                        <Route path="/failed-record" component={FailedRecord} />
                        <Route exact path='/removed-employee' component={RemovedEmployee} />
                        <Route path='/inProgress-record' component={InProgressRecord} />

                        <Route exact path='/money-disbursal' component={MoneyDisbursal} />
                        <Route exact path='/success-disbursal' component={SuccessDisbursal} />
                        <Route exact path='/failed-disbursal' component={FailedDisbursal} />
                        <Route exact path='/checker-success-disbursal' component={CheckerSuccessDisbursal} />
                        <Route exact path='/draft-status-disbursal' component={DraftDisbursal} />

                        <Route exact path='/band-list' component={SetBandWiseDisbursalLimits} />
                        <Route exact path='/band-details' component={BandDetails} />
                        <Route exact path='/create-new-band' component={CreateNewBand} />


                        <Route exact path='/manage-card:id' component={ManageCard} />
                        <Route exact path='/manage-card' component={ManageCard} />
                        <Route exact path='/order-history' component={OrderHistory} />
                        <Route exact path='/blocked-cards' component={BlockedCards} />
                        <Route exact path='/assigned-cards' component={AssignedCards} />
                        <Route exact path='/user-kyc' component={UserKyc} />

                        <Route exact path='/allEmp-transaction-report' component={AllEmployeeTransactionReport} />

                        <Route exact path='/activity-log' component={ActivityLog} />


                        <Route exact path='/corporate-profile' component={CorporateProfile} />
                        <Route exact path='/add-corp-bank-account' component={AddCorpBankAccount} />
                        <Route exact path='/reset-login-password' component={ResetLoginPassword} />
                        <Route exact path='/reset-txn-password' component={ResetTxnPassword} />

                        <Route exact path='/roles-permissions' component={RolePermissions} />
                        <Route exact path='/create-role' component={CreateRole} />

                        <Route exact path='/load-money' component={LoadMoney} />
                        <Route exact path='/load-money-detail' component={LoadMoneyDetail} />
                        <Route exact path='/load-txn' component={LoadTxnHistory} />
                        <Route exact path='/info-loadMoney' component={LoadInfo} />


                        <Route exact path='/reverse-money' component={ReversalMoney} />
                        <Route exact path="/reversal-money-detail" component={ReversalMoneyDetail} />

                        <Route exact path='/notification' component={Notification} />

                        <Route exact path='/add-bank-account' component={AddBankAccount} />


                        <Route exact path="/emp-detail" component={EmployeeDetail} />
                        <Route exact path='/emp-manage-card' component={EmpManageCard} />
                        <Route exact path='/inactive-profile' component={InActiveEmployee} />

                        <Route exact path='/transaction-report' component={TransactionReport} />
                        <Route exact path='/add-new-corp' component={AddNewCorp} />
                        <Route exact path='/add-corp-emp' component={AddCorpEmp} />

                        <Route exact path="/corp-list" component={CorporateList} />
                        <Route exact path="/corp-profile" component={CorporateProfileDetail} />
                        <Route exact path='/corp-dashboard' component={CorporateDashboard} />
                        <Route exact path='/superAdmin-roles-permissions' component={SuperAdminRolePermissions} />
                        <Route exact path='/corp-manage-reversal' component={CorpManageReversal} />

                        <Route exact path='/corp-order-history' component={CorpOrderHistory} />
                        <Route exact path='/admin-manage-card' component={AdminMangeCard} />

                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}


                        <Route exact path='/manage-giftCard' component={MangaeGiftCard} />
                        <Route exact path='/giftcard-history' component={GiftCardHistory} />
                        <Route exact path='/issued-giftcards' component={IssuedGiftCard} />
                        <Route exact path='/giftcards-beneficiary' component={GiftcardsBeneficiary} />



                        <Route exact path='/view-ta' component={ViewTA} />
                        <Route exact path='/view-Acess-Login' component={ViewAccessLogin} />
                        <Route exact path='/verifyOtp-viewAcess' component={VerifyOtpScreen} />
                        <Route exact path='/switchTo-viewAcess' component={SwitchToViewAcess} />



                        <Route exact path="/"
                            render={() =>
                                this.props.currentUser ? (
                                    <Redirect to='/' />
                                ) : (
                                    <EmployeeList />
                                )} />

                        <Route path='/404' component={My404Component} />
                        <Redirect from='*' to='/404' />


                        {/* <Route exact path="/update-emp" component={UpdateEmployee} /> */}
                        {/* <Route exact path='/mis-report' component={MisReport} />
                        <Route exact path='/card-balance-report' component={CardBalanceReport} />
                        <Route exact path='/transaction-dump-report' component={TransactionDumpReport} /> */}
                        {/* <Route exact path='/successGiftcards-review' component={SuccessReviewGiftCard} />
                         <Route exact path='/failGiftcards-review' component={FailReviewGiftCard} /> */}
                        {/* <Route exact path='/ta-report' component={TAReport} /> */}
                        {/* <Route exact path='/single-success-record' component={SingleSuccessDisbursal} /> */}
                        {/* <Route exact path='/edit-band-limit' component={EditBandLimit} /> */}

                    </Switch>
                </>

                <Footer />
            </>
        );
    }
}


const mapStateToProps = ({ user: { currentUser, sessionExpire }, adminView: { adminView } }) => ({
    currentUser, sessionExpire, adminView
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
});

App.propTypes = {
    match: PropTypes.any.isRequired,
    history: PropTypes.func.isRequired
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
