import KycCardType from "./kyc-card.types";

const INITIAL_STATE = {
    kycDetail: {}
};


const kycCardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case KycCardType.SET_KYC_CARD_DETAIL:
            return {
                ...state,
                kycDetail: action.payload
            };
        
        default:
            return state;
    }
};

export default kycCardReducer;