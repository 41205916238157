import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import Pagination from 'react-js-pagination';
import ApiCallingService from '../../service/api-call-service'
import UploadMoneyDisbursal from '../component/upload-money-disbursal.component';
import StateManager from 'react-select';
import apiCallService from '../../service/api-call-service';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import history from '../../auth/history';
import CategoryLimit from '../../constants/category-limit.type';
import CsvDownload from 'react-json-to-csv'
import image1 from "../../assests/no-image.png";
import { setEditBandDetail } from '../../redux/edit-band-limit/edit-band-limit.action';
import CommonFunction from '../../common-function/GenerateRequestId';
import CardStatus from '../../common/component/card-status.component';
import SubBandDetailCard from './sub-band-detail-card';
import Can from '../../auth/can/Can';
import ReactTooltip from 'react-tooltip';
import LoadingComponent from './band-detail-loading.component';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

const EditBandLimitComponent = ({ userRole, isPasswordReset, roleData, currentUser, token, location, authenticated, bandData, maxFoodAmount, maxGeneralAmount, editBandDetail, maxTravelAmount, roMdn, wallet }) => {
    var arr = [];
    var listForAction = [];

    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_BAND_EMPLOYEE_RECORD);
    const [masterChecked, setMasterChecked] = useState(false);
    const [checkedPost, setCheckedPost] = useState([]);
    const [employeeList, setemployeeList] = useState([])
    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');
    const [band, setBand] = useState('');
    const [bandUpdate, setBandUpdate] = useState('');
    const [maxFood, setMaxFood] = useState(0);
    const [maxTravel, setMaxTravel] = useState(0);
    const [maxGeneral, setMaxGeneral] = useState(0);
    const [generalAmount, setGeneralAmount] = useState('');
    const [foodAmount, setFoodAmount] = useState('');
    const [travelAmount, setTravelAmount] = useState('');
    const [norecordvar, setNorecordVar] = useState('');
    const [isFoodUpdate, setIsFoodUpdate] = useState(false);
    const [isTravelUpdate, setIsTravelUpdate] = useState(false);
    const [isGeneralUpdate, setisGeneralUpdate] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const [size, setSize] = useState(0);
    const [selectedFilter, setFilterSelected] = useState(false);
    const [foodFilter, setFoodFilter] = useState([]);
    const [generalFIlter, setGeneralFilter] = useState([]);
    const [travelFilter, setTravelFilter] = useState([]);
    const [radioValue, setradioValue] = useState('');
    const [modal, setModal] = useState('modal');
    const [prevGeneral, setPrevGeneral] = useState('');
    const [prevTravel, setPrevTravel] = useState('');
    const [prevFood, setPrevFood] = useState('');

    const [showAndHideDiv, setShowAndHideDiv] = useState(true);


    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));



        // window.history.pushState(null, document.title, window.location.href);
        // window.addEventListener('popstate', function (event){
        //     window.history.pushState(null, document.title,  window.location.href);
        // });


        // console.log('location.state.data', location.state.data);
        // if (location.state) {

        // var { band, wallet_food_limit, general_Limit, travel_Limit } = location.state.data;
        //    var { band, wallet_food_limit, general_Limit, travel_Limit } = editBandDetail;
        console.log('location.state.data band ye hi h na', editBandDetail);
        var band = editBandDetail.band;
        var wallet_food_limit = editBandDetail.wallet_food_limit;
        var general_Limit = editBandDetail.general_Limit;
        var travel_Limit = editBandDetail.travel_Limit;


        console.log('location.state.data wallet_food_limit', wallet_food_limit);

        console.log('location.state.data general_Limit', general_Limit);

        console.log('location.state.data wallet_food_limit', travel_Limit);

        setBand(band);
        setBandUpdate(band);

        setMaxFood(parseFloat(maxFoodAmount) / 100);
        setFoodAmount(wallet_food_limit)
        // setPrevFood(wallet_food_limit)
        setMaxFood(parseFloat(maxFoodAmount) / 100);


        setMaxTravel(parseFloat(maxTravelAmount) / 100)
        setTravelAmount(travel_Limit)
        // setPrevTravel(travel_Limit)


        setMaxGeneral(parseFloat(maxGeneralAmount) / 100);
        setGeneralAmount(general_Limit)
        // setPrevGeneral(general_Limit)


        setEMployeeListFunftion(band, false);

        // }
    }, []);

    const setEditView = () => {
        setShowAndHideDiv(false)
    }
    const setViewDiv = () => {
        setShowAndHideDiv(true)
        if (location.state) {

            // var { band, wallet_food_limit, general_Limit, travel_Limit } = location.state.data;
            //    var { band, wallet_food_limit, general_Limit, travel_Limit } = editBandDetail;
            console.log('location.state.data band ye hi h na', editBandDetail);
            var band = editBandDetail.band;
            var wallet_food_limit = editBandDetail.wallet_food_limit;
            var general_Limit = editBandDetail.general_Limit;
            var travel_Limit = editBandDetail.travel_Limit;


            console.log('location.state.data wallet_food_limit', wallet_food_limit);

            console.log('location.state.data general_Limit', general_Limit);

            console.log('location.state.data wallet_food_limit', travel_Limit);

            setBand(band);
            setBandUpdate(band);

            setMaxFood(parseFloat(maxFoodAmount) / 100);
            setFoodAmount(wallet_food_limit)
            // setPrevFood(wallet_food_limit)
            setMaxFood(parseFloat(maxFoodAmount) / 100);


            setMaxTravel(parseFloat(maxTravelAmount) / 100)
            setTravelAmount(travel_Limit)
            // setPrevTravel(travel_Limit)


            setMaxGeneral(parseFloat(maxGeneralAmount) / 100);
            setGeneralAmount(general_Limit)
            // setPrevGeneral(general_Limit)

            setEMployeeListFunftion(bandUpdate, true);

            setisGeneralUpdate(false);
            setIsFoodUpdate(false);
            setIsTravelUpdate(false);
        }
    }

    const setEMployeeListFunftion = async (band, update) => {
        const url = `${process.env.REACT_APP_GET_EMPLOYEE_BY_EMPLYEE_BAND}?corp_bands=${band}&ro_Id=${roMdn}`
        var arr = []
        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log('response setemployeeList', response)
        if (response !== undefined && response.response !== undefined && response.status === 0 && response.response.employeeDetails !== undefined) {
            console.log('response setemployeeList', response)
            if (response.response.employeeDetails.length > 0) {
                response.response.employeeDetails.map(corpBand => {
                    var isChecked = 'isChecked';
                    corpBand[isChecked] = true;
                    arr.push(corpBand);
                    console.log('band list data', corpBand);

                })

                setMasterChecked(true)

                setNorecordVar('')
            } else {
                setNorecordVar('No Employee For The Band')
            }

        }
        setFilteredList([]);
        setemployeeList(arr)
        setSize(arr.length);
        setLoading(false)
        setCurrentPage(1);
        if (update) {
            setFilterSelected(false)
        }
        setCheckedPost(arr);
    }

    const formSubmit = async (event) => {
        event.preventDefault();

        if (prevFood === foodAmount && prevGeneral === generalAmount && prevTravel === travelAmount) {
            return (
                setDropDownHidden(true),
                setSeverty("warning"),
                setMessage("Please Edit Limit.")
            )
        }

        console.log('travelAmount', travelAmount, travelAmount !== '' && travelAmount !== null)
        var foodAmountToSend = 0
        var travelAmountToSend = 0
        var generalmountToSend = 0
        if (foodAmount !== '' && foodAmount !== null) {
            foodAmountToSend = foodAmount
        }
        if (generalAmount !== '' && generalAmount !== null) {
            generalmountToSend = generalAmount
        }

        if (travelAmount !== '' && travelAmount !== null) {
            travelAmountToSend = travelAmount
        }

        console.log('travelAmount', travelAmountToSend, travelAmount !== '' && travelAmount !== null)
        console.log('location.state.data  triggered')
        var empList = [];
        var isReset = 2

        if (filteredList.length === 0) {
            var request = {
                "band": band,
                "roId": roMdn,
                "amount": {
                    "generalAmount": parseFloat(generalmountToSend) * 100,
                    "foodAmount": parseFloat(foodAmountToSend) * 100,
                    "travelAmount": parseFloat(travelAmountToSend) * 100
                },
                "employeeList": empList,
                "generalUpdate": isGeneralUpdate,
                "foodUpdate": isFoodUpdate,
                "travelUpdate": isTravelUpdate,
                "isReset": isReset,
                "bandUpdate": bandUpdate,
                "updatedBy": currentUser,
                "createdBy": currentUser
            }
            console.log('request', radioValue, request);

            var url = process.env.REACT_APP_URL_TO_SET_LIMIT_FOR_BAND_AND_EMPLOYEE;

            var response = await apiCallService.postMethodCall(url, request);

            console.log('response request', response, request);
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
                setModal('modal')
                setPrevFood(foodAmountToSend)
                setPrevGeneral(generalmountToSend)
                setPrevTravel(travelAmountToSend)
                setDropDownHidden(true);
                setSeverty("success");
                setMessage("Limit Updated Successfully");
                setTravelAmount(travelAmountToSend);
                setGeneralAmount(generalmountToSend);
                setFoodAmount(foodAmountToSend);
                var payload = {
                    band: bandUpdate,
                    corp_id: editBandDetail.corp_id,
                    date_created_on: editBandDetail.date_created_on,
                    disableButton: editBandDetail.disableButton,
                    general_Limit: generalmountToSend,
                    isChecked: editBandDetail.isChecked,
                    is_active: editBandDetail.is_active,
                    travel_Limit: travelAmountToSend,
                    wallet_food_limit: foodAmountToSend
                }
                console.log('payload', payload)

                store.dispatch(setEditBandDetail(payload));
                setEMployeeListFunftion(bandUpdate, true);
                setradioValue('');
                setShowAndHideDiv(true)
            } else if (response.response_message !== undefined && response.response_message !== '') {
                setDropDownHidden(true);
                setSeverty("error");
                setMessage(response.response_message);
            } else {
                setDropDownHidden(true);
                setSeverty("error");
                setMessage(response.response_description);
            }
        }
    }

    const handleMasterChecked = (event) => {
        setMasterChecked(event.target.checked);
        currentPost.map(posts => {
            console.log('inside if handleMasterChecked', posts);
            if (event.target.checked) {
                posts.isChecked = event.target.checked;
                listForAction.push(posts)
            } else if (!event.target.checked) {
                console.log('inside else handleMasterChecked');
                posts.isChecked = event.target.checked;
                listForAction.pop(posts)
            }
        })
        setCheckedPost(currentPost);

    }


    const handleChecked = (event) => {
        var data = listForAction
        console.log(data.length, "and", postsPerPage)
        if (data.length !== postsPerPage) {
            setMasterChecked(false)
        }
        data.map(list => {
            listForAction.push(list);
        })
        currentPost.map(post => {
            if (post.emp_id === event.target.id && event.target.checked === true) {
                console.log('inside if handleChecked');
                console.log('post to be performed action is : ', post)
                post.isChecked = event.target.checked
                listForAction.push(post)
                console.log('list to action performed correct', listForAction);
            } else if (post.emp_id === event.target.id && event.target.checked === false) {
                console.log('removed post from action performed', post)
                listForAction.pop(post)
                setMasterChecked(false)
                post.isChecked = event.target.checked
                console.log('list to action performed wrong', listForAction);
            }
        })
        setCheckedPost(currentPost)
    }



    function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }


    const handleGenralChange = (event) => {
        setGeneralAmount(event.target.value);
        setFilterSelected(true);
        var arr = [];
        var generalFil = []
        foodFilter.map(fil => { arr.push(fil) });
        travelFilter.map(fil => { arr.push(fil) });
        setisGeneralUpdate(true);

        employeeList.map(emp => {
            console.log('handle genral cgaheg', parseFloat(event.target.value) < parseFloat(emp.general) / 100)
            var data = parseFloat(emp.general) / 100
            if (parseFloat(data) > parseFloat(event.target.value)) {
                emp.isChecked = true;
                arr.push(emp)
                generalFil.push(emp)
            }
        })

        var newArray = removeDuplicates(arr, 'mdn');
        setFilteredList(newArray);
        setSize(newArray.length);

        setCurrentPage(1);
        setGeneralFilter(generalFil);

    }
    const handleFoodChange = (event) => {
        setFoodAmount(event.target.value);
        setFilterSelected(true);
        setIsFoodUpdate(true);
        var arr = [];
        var foodFill = [];
        generalFIlter.map(fil => { arr.push(fil) });
        travelFilter.map(fil => { arr.push(fil) });
        employeeList.map(emp => {
            var data = parseFloat(emp.food) / 100
            if (parseFloat(data) > parseFloat(event.target.value)) {
                emp.isChecked = true;
                arr.push(emp)
                foodFill.push(emp)
            }
        })
        var newArray = removeDuplicates(arr, 'mdn');
        setFilteredList(newArray);

        setSize(newArray.length);

        setCurrentPage(1);
        setFoodFilter(foodFill);
    }
    const handleTravelChange = (event) => {
        setTravelAmount(event.target.value);
        setFilterSelected(true);
        setIsTravelUpdate(true)
        var arr = [];
        var travelfill = []

        generalFIlter.map(fil => { arr.push(fil) });
        foodFilter.map(fil => { arr.push(fil) });

        employeeList.map(emp => {
            var data = parseFloat(emp.travel) / 100
            if (parseFloat(data) > parseFloat(event.target.value)) {
                emp.isChecked = true;
                arr.push(emp)
                travelfill.push(emp);
            }
        })
        var newArray = removeDuplicates(arr, 'mdn');
        setFilteredList(newArray);

        setSize(newArray.length);

        setCurrentPage(1);
        setTravelFilter(travelfill);
    }


    const setradioValueClick = (event) => {
        setradioValue(event.target.id)
    }


    const handleSetLimitUpdate = async (event) => {
        var empList = [];


        setPrevFood(foodAmount)
        setPrevGeneral(generalAmount)
        setPrevTravel(travelAmount)

        if (prevFood === foodAmount && prevGeneral === generalAmount && prevTravel === travelAmount) {
            return (
                setDropDownHidden(true),
                setSeverty("success"),
                setMessage("Updation Failed"),
                setradioValue('')
            )
        }
        filteredList.map(fil => {
            if (fil.isChecked) {
                var emp = {
                    employeeEmail: ''
                }
                emp.employeeEmail = fil.email
                empList.push(emp);
            }
        })
        var isReset = 2;
        if (radioValue === 'resetAll') {
            isReset = 1;
        }
        var foodAmountToSend = 0
        var travelAmountToSend = 0
        var generalmountToSend = 0
        if (foodAmount !== '' && foodAmount !== null) {
            foodAmountToSend = foodAmount
        }
        if (generalAmount !== '' && generalAmount !== null) {
            generalmountToSend = generalAmount
        }

        if (travelAmount !== '' && travelAmount !== null) {
            travelAmountToSend = travelAmount
        }


        var request = {
            "band": band,
            "roId": roMdn,
            "amount": {
                "generalAmount": parseFloat(generalmountToSend) * 100,
                "foodAmount": parseFloat(foodAmountToSend) * 100,
                "travelAmount": parseFloat(travelAmountToSend) * 100
            },
            "employeeList": empList,
            "generalUpdate": isGeneralUpdate,
            "foodUpdate": isFoodUpdate,
            "travelUpdate": isTravelUpdate,
            "isReset": isReset,
            "bandUpdate": bandUpdate,
            "updatedBy": currentUser,
            "createdBy": currentUser
        }
        console.log('request', radioValue, request);

        var url = process.env.REACT_APP_URL_TO_SET_LIMIT_FOR_BAND_AND_EMPLOYEE;

        var response = await apiCallService.postMethodCall(url, request);

        console.log('response request', response, request);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setModal('modal')
            setPrevFood(foodAmountToSend)
            setPrevGeneral(generalmountToSend)
            setPrevTravel(travelAmountToSend)
            setDropDownHidden(true);
            setSeverty("success");
            setMessage("Limit Updated Successfully");
            setTravelAmount(travelAmountToSend);
            setGeneralAmount(generalmountToSend);
            setFoodAmount(foodAmountToSend);
            var payload = {
                band: bandUpdate,
                corp_id: editBandDetail.corp_id,
                date_created_on: editBandDetail.date_created_on,
                disableButton: editBandDetail.disableButton,
                general_Limit: generalmountToSend,
                isChecked: editBandDetail.isChecked,
                is_active: editBandDetail.is_active,
                travel_Limit: travelAmountToSend,
                wallet_food_limit: foodAmountToSend
            }

            console.log('payload', payload)

            store.dispatch(setEditBandDetail(payload));
            setEMployeeListFunftion(bandUpdate, true);
            setradioValue('')
            setShowAndHideDiv(true)
            setBand(bandUpdate)
            setisGeneralUpdate(false);
            setIsFoodUpdate(false);
            setIsTravelUpdate(false);

            window.location.reload()
        } else if (response.response_message !== undefined && response.response_message !== '') {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_message);
        } else {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
        }


    }



    const deleteBand = async (event) => {
        event.preventDefault();


        var bandArray = []
        var bandDetail = {
            "band": band,
            "roId": roMdn,
            "isActive": 1,
            "type": "update",
            "updatedBy": currentUser,
            "createdBy": currentUser
        }
        bandArray.push(bandDetail);
        var request = {
            "request": bandArray

        }
        var url = process.env.REACT_APP_ADD_NEW_BAND

        var response = await apiCallService.postMethodCall(url, request);

        console.log("Delete Band Response", url, (request), response)

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setDropDownHidden(true);
            setSeverty("success");
            setMessage(process.env.React_APP_Message_if_band_deleted);
            history.push('/band-list')
            history.go();
        } else if (response.response_message !== undefined && response.response_message !== '') {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_message);
        } else {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
        }

    }


    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    //Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);



    // console.log('current post filteredList', filteredList.length > 0)     

    if (masterChecked) {

        if (filteredList === null) {
            console.log('current post inside if checkedPost', currentPost)
            currentPost = checkedPost
        } else if (selectedFilter) {
            console.log('current post inside if filteredList', currentPost)
            currentPost = filteredList.slice(indexOfFirstPost, indexOfLastPost)
        } else {
            currentPost = employeeList.slice(indexOfFirstPost, indexOfLastPost)
        }
    } else if (bandData === null || filteredList === undefined) {
        console.log('current post inside if else', currentPost)
        currentPost = null
    } else if (selectedFilter) {
        currentPost = filteredList.slice(indexOfFirstPost, indexOfLastPost)
    } else {
        console.log('current post inside else', filteredList)
        currentPost = employeeList.slice(indexOfFirstPost, indexOfLastPost)
    }
    var minVar = 1

    console.log('current post', currentPost)

    var fileToExport = [];
    currentPost.map(data => {
        var obj = {
            Employee_Name: "",
            Employee_Id: "",
            Mobile_No: "",
            Band: "",
            General: "",
            Food_and_Meal: "",
            Travel: ""
        }
        if (data.isChecked) {

            var fName = data.first_name;
            var lName = data.last_name;
            if (data.last_name === undefined) {
                lName = '';
            }
            var generalAmount = 0;
            var travelAmount = 0;
            var foodAmount = 0;

            var fullName = `${fName} ${lName}`;
            obj.Employee_Name = fullName;
            obj.Employee_Id = data.emp_id;
            obj.Mobile_No = data.mdn;
            obj.Band = data.band;
            obj.General = parseFloat(parseFloat(data.general) / 100).toFixed(2);
            obj.Food_and_Meal = parseFloat(parseFloat(data.food) / 100).toFixed(2);
            obj.Travel = parseFloat(parseFloat(data.travel) / 100).toFixed(2);
            fileToExport.push(obj);
        } else {
            // obj.Employee_Name=data.empName;
            // obj.Employee_Id=data.empId;
            // obj.Mobile_No=data.mdn;
            // obj.Band=data.band;
            // obj.General=data.genral/100;
            // obj.Food_and_Meal=data.food/100;
            // obj.Travel=data.travel/100;
            // obj.Transaction_ID=data.transactionId;
            // obj.Status=data.approveStatusVar
            // fileToExport.pop(obj);
        }

    })

    console.log('setradioValue', radioValue)

    var height = window.innerHeight - 400

    return (
        <div >
            {(authenticated)
                ? (
                    isPasswordReset
                        ?
                        <div className="mid-container" id="heightFix">
                            <div className="container-fluid">





                                <Snackbar

                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => setDropDownHidden(false)}
                                >
                                    <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                        {message}
                                    </Alert>
                                </Snackbar>







                                <div className="head">
                                    <div className="row">

                                        <div className="col-10">
                                            <h3>
                                                Band - {bandUpdate}
                                            </h3></div>
                                        <div className="col-2">
                                            <div className="actnbtn" >
                                                <a href="/band-list" data-toggle="tooltip" title="Back" className="back" ><i className='back'></i></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className="emp-list">
                                    <div className="row" >

                                        <div className="col-12">


                                            {showAndHideDiv
                                                ?
                                                <div className="bandcard" id="bandview">

                                                    <ReactTooltip
                                                        id="back"
                                                        place="top"
                                                        type='dark'
                                                        effect='solid'
                                                        // multiline={true}
                                                        backgroundColor="black"
                                                    />
                                                    <div className="row">


                                                        <div className="col-8 col-sm-9 col-md-10">


                                                            {
                                                                (generalAmount) == "." || (generalAmount) == ".0" || (generalAmount) == ".00" || parseInt(generalAmount) >= 0 ?
                                                                    <div className="bandwallet">
                                                                        <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_General}</p>
                                                                        <h6 className="general">₹ {parseFloat(generalAmount).toFixed(2)}</h6>
                                                                    </div>
                                                                    : null
                                                            }

                                                            {
                                                                (foodAmount) == "." || (foodAmount) == ".0" || (foodAmount) == ".00" || parseInt(foodAmount) >= 0 ?
                                                                    <div className="bandwallet">
                                                                        <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Food}</p>
                                                                        <h6 className="food">₹ {parseFloat(foodAmount).toFixed(2)}</h6>
                                                                    </div>
                                                                    : null
                                                            }

                                                            {
                                                                (travelAmount) == "." || (travelAmount) == ".0" || (travelAmount) == ".00" || parseInt(travelAmount) >= 0 ?
                                                                    <div className="bandwallet">
                                                                        <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Travel}</p>
                                                                        <h6 className="travel">₹ {parseFloat(travelAmount).toFixed(2)}</h6>
                                                                    </div>
                                                                    : null
                                                            }


                                                        </div>


                                                        <div className="col-4 col-sm-3 col-md-2">
                                                            <div className="bandbtn">


                                                                <Can
                                                                    role={userRole}
                                                                    perform="editBand-delete:visit"
                                                                    roleData={roleData}
                                                                    yes={() => (


                                                                        employeeList.length > 0
                                                                            ?
                                                                            null :

                                                                            <button className="delete" data-for="back" data-tip="Remove" data-iscapture="true" data-bs-toggle="modal" data-bs-target="#remove">Delete</button>


                                                                    )}
                                                                    no={() =>
                                                                        null
                                                                    }
                                                                />

                                                                <Can
                                                                    role={userRole}
                                                                    perform="editBand-Edit:visit"
                                                                    roleData={roleData}
                                                                    yes={() => (

                                                                        <button className="edit" data-for="back" data-tip="Edit" data-iscapture="true" onClick={setEditView} id="bandedit">Edit</button>

                                                                    )}
                                                                    no={() =>
                                                                        null
                                                                    }
                                                                />


                                                            </div>
                                                            <div className="modal fade" id="remove" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                                <div className="modal-dialog modal-sm">
                                                                    <div className="modal-content alerttext">
                                                                        <div className="modal-header">
                                                                            <h5 id="">{process.env.REACT_APP_VARIABLE_FOR_BAND_DELETE_POPUP}</h5>
                                                                            <a className="close" data-bs-dismiss="modal"  aria-label="Close">&times;</a>
                                                                        </div>
                                                                        <div className="modal-body">{process.env.REACT_APP_VARIABLE_FOR_BAND_DELETE_POPUP_WARNING_BEFORE_BAND_NAME} {band} {process.env.REACT_APP_VARIABLE_FOR_BAND_DELETE_POPUP_WARNING_AFTER_BAND_NAME}
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="mbtn btngr" data-bs-dismiss="modal" >{process.env.REACT_APP_VARIABLE_FOR_BAND_DELETE_POPUP_CLOSE_BUTTON}</button>
                                                                            <button type="button" className="mbtn btnbl" onClick={deleteBand}>{process.env.REACT_APP_VARIABLE_FOR_BAND_DELETE_POPUP_DELETE_BUTTON}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="bandcard bandcardview" id="bandedit" >
                                                    <form className="row" method="post" data-toggle="modal" onSubmit={formSubmit}>
                                                        <div className="col-12 col-md-4 col-lg-3">
                                                            <div className="form-floating">
                                                                <input className="form-control" type="text" id="bandname" placeholder="Band" value={bandUpdate} onChange={e => { setBandUpdate(e.target.value) }} disabled={employeeList.length > 0} />
                                                                <label for="bandname">{process.env.REACT_APP_EDIT_BAND_LABEL_FOR_Band_Name}</label>
                                                            </div>
                                                        </div>


                                                        {(generalAmount) == "." || (generalAmount) == ".0" || (generalAmount) == ".00" || parseInt(generalAmount) >= 0 || generalAmount === "" ?
                                                            <div className="col-12 col-md-4 col-lg-3">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="genl" type="number" value={generalAmount} max={maxGeneral} step="0.01" min="0" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} placeholder="Enter amount" onChange={handleGenralChange} required />
                                                                    <label for="genl">{process.env.REACT_APP_EDIT_BAND_LABEL_FOR_GENERAL_LIMIT}</label>

                                                                </div>
                                                            </div>
                                                            : null
                                                        }

                                                        {(foodAmount) == "." || (foodAmount) == ".0" || (foodAmount) == ".00" || parseInt(foodAmount) >= 0 || foodAmount === "" ?
                                                            <div className="col-12 col-md-4 col-lg-3">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="food" type="number" value={foodAmount} max={maxFood} step="0.01" min="0" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} placeholder="Enter amount" onChange={handleFoodChange} required />
                                                                    <label for="food">{process.env.REACT_APP_EDIT_BAND_LABEL_FOR_FOOD_LIMIT}</label>

                                                                </div>
                                                            </div>
                                                            : null
                                                        }

                                                        {(travelAmount) == "." || (travelAmount) == ".0" || (travelAmount) == ".00" || parseInt(travelAmount) >= 0 || travelAmount === "" ?
                                                            <div className="col-12 col-sm-4 col-md-3">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="trvl"
                                                                        type="number" value={travelAmount} max={maxTravel} step="0.01" min="0" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} onChange={handleTravelChange} required
                                                                    />
                                                                    <label for="trvl">{process.env.REACT_APP_EDIT_BAND_LABEL_FOR_TRAVEL_LIMIT}</label>

                                                                </div>
                                                            </div>
                                                            : null
                                                        }

                                                        <div className="col-12 mt-3">
                                                            <input type="reset" value="Cancel" className="lbtn btngr" id="cnlband" onClick={setViewDiv} />

                                                            {(filteredList.length > 0 && generalAmount !== '' && foodAmount !== '' && travelAmount !== '') ?
                                                                <input className="lbtn btnbl" data-bs-toggle="modal" value="Save" data-bs-target="#limitset" type='submit' />
                                                                :
                                                                <input className="lbtn btnbl" value="Save" type='submit' />

                                                            }
                                                        </div>
                                                    </form>
                                                </div>}

                                        </div>





                                        {

                                            loading ?
                                                <LoadingComponent />
                                                :



                                                employeeList.length > 0

                                                    ?
                                                    currentPost.map(employee => (
                                                        <SubBandDetailCard employee={employee} />
                                                    ))
                                                    :
                                                    <div className="col-12">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3> No Record Available.</h3>
                                                        </div>
                                                    </div>
                                        }






                                        {parseInt(size) > parseInt(postsPerPage)
                                            ?
                                            <div  className="row">
                                                <div className="col-12 col-sm-6">
                                                    <p> Showing {valueToShow} to {(indexOfLastPost > size) ? size : indexOfLastPost} of {size} entries</p>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <div className="pager">
                                                        <Pagination
                                                            activePage={parseInt(currentPage)}
                                                            itemsCountPerPage={parseInt(postsPerPage)}
                                                            totalItemsCount={size}
                                                            pageRangeDisplayed={parseInt(postsPerPage)}
                                                            onChange={paginate}
                                                            className="pagination"
                                                            linkClass="page-link"
                                                            itemClass="page-item"
                                                            nextPageText="Next"
                                                            prevPageText="Prev"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                    <div className="modal fade" id="limitset" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                        <div className="modal-dialog modal-sm">
                                            <div className="modal-content alerttext">
                                                <div className="modal-header">
                                                    <h5  className="modal-title" id="">{process.env.REACT_APP_UPDATE_BAND_LABEL_POPUP_FOR_Update_Band_Limit}</h5>
                                                    <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">

                                                    <div  className="popuptxt text-center">
                                                        <div  className="form-check form-check-inline">
                                                            <input type="radio" id="resetAll" className="form-check-input" name="resetAll" value={radioValue} checked={radioValue === 'resetAll'} onClick={setradioValueClick} />
                                                            <label  className="form-check-label" for="resetAll">{process.env.REACT_APP_UPDATE_BAND_LABEL_POPUP_Update_Band_Limit_FOR_RESET_ALL}</label>
                                                        </div>
                                                        <div  className="form-check form-check-inline">
                                                            <input type="radio" id="maxLimit" className="form-check-input" name="maxLimit" value={radioValue} checked={radioValue === 'maxLimit'} onClick={setradioValueClick} />
                                                            <label  className="form-check-label" for="maxLimit">{process.env.REACT_APP_UPDATE_BAND_LABEL_POPUP_Update_Band_Limit_FOR_MAX_LIMIT}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="mbtn btngr" data-bs-dismiss="modal" onClick={setradioValueClick}>{process.env.REACT_APP_UPDATE_BAND_LABEL_POPUP_Update_Band_Limit_FOR_CANCEL_BUTTON}</button>
                                                    <button type="button" className="mbtn btnbl" data-dismiss={modal} onClick={handleSetLimitUpdate} disabled={radioValue === ''}>{process.env.REACT_APP_UPDATE_BAND_LABEL_POPUP_Update_Band_Limit_FOR_OK_BUTTON}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>




                            </div>
                        </div>

                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount, userRole, currentUser,
    maxFoodAmount,
    maxTravelAmount }, token: { authenticated, token, isPasswordReset }, band: { bandData }, seteditLimt: { editBandDetail }, role: { roleData } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount, currentUser,
        maxFoodAmount, editBandDetail,
        maxTravelAmount, token, userRole, roleData, isPasswordReset
    });

export default connect(mapStateToProps)(EditBandLimitComponent);
