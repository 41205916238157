import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import '../css/loading.styles.css'

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

export default function Animations() {
    //const classes = useStyles();
    return (
        <div className="col-12 col-md-4">

            <div className="emplcard">

                <>
                    <div className="userpro">
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <h5 className="text-truncate"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>

                        <div className="empactn">
                            <button type="button" data-toggle="tooltip" title="Edit" className="edit"></button>
                            <button type="button" data-toggle="tooltip" title="Remove" className="remove"></button>
                        </div>
                    </div>

                    <div className="kycsts">
                        <p className="float-start"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        <p className="float-end"><i className="date"></i><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                    </div>

                    <div className="usrprodtl">
                        <ul>
                            <li className="email">
                                <p>Email ID:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li className="cldr">
                                <p>Date of Birth:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li className="gender">
                                <p>Gender:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li className="doc">
                                <p>PAN:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li className="locat">
                                <p>Location:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                        </ul>
                    </div>

                    <div className="profndtls">
                        <ul>
                            <li>
                                <p>Employee ID:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li>
                                <p>Date of Joining:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li>
                                <p>Department:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                            <li>
                                <p>Employee Band:</p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </li>
                        </ul>
                    </div>

                </>


                <div className="assign-role">
                    <label>Assigned Role</label>
                    <div className="input-group">
                        <select className="form-control">

                        </select>
                        <div className="input-group-append">
                            <button type="button" className="btn btnbl" id="">Ok</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}