
import LocationType from './location.type';

export const setLocation = locationData =>({
    type: LocationType.SET_LOCATION,
    payload: locationData
})

export const setLocationListToView = locationData =>({
    type: LocationType.SET_LOCATION_LIST_TO_VIEW,
    payload: locationData
})


export const setDepartmentList = data => ({
    type: LocationType.SET_DEPARTMENT_LIST,
    payload: data
})

export const setLocationData = (value) => ({
    type: LocationType.SET_LOCATION_COUNTRY_AND_STATE,
    payload:value
});