import LoadMoneyActionType from "./loadMoney.types";

const INITIAL_STATE = {
    loadAmount: 0,
    surchargeAmount: 0,
    totalLoadAmount: 0,
    transactionId: '',
    transactionDateAndTime: '',
    transactionMode: ''
};


const loadMoneyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LoadMoneyActionType.SET_LOAD_AMOUNT_DETAIL:
            return {
                ...state,
                loadAmount: action.payload.loadAmount,
                surchargeAmount: action.payload.surchargeAmount,
                totalLoadAmount: action.payload.totalLoadAmount,
                transactionId: action.payload.transactionId,
                transactionDateAndTime: action.payload.transactionDateAndTime,
                transactionMode: action.payload.transactionMode
            };
        default:
            return state;
    }
};

export default loadMoneyReducer;
