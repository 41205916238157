import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Can from '../../auth/can/Can';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import SubRoleButton from '../component/sub-role-button.component';


const AssignedUserRoleComponent = ({ authenticated, currentUserRoleName, roMdn, maxUser, roleName, setRoleName, roleData, userRole, performAssignRole, role,performDeleteRole,performEditDetail }) => {


    const [roleCount, setRoleCount] = useState(0);

    useEffect(() => {

        const funName = async () => {
            var url = `${process.env.REACT_APP_GET_EMPLOYEE_COUNT_BY_ROLENAME}?ro_Id=${roMdn}&role_name=${roleName}`;

            var resp = await apiCallService.getMethodCallWithoutParam(url);

            console.log(resp)
            if (resp !== undefined && resp.response_code !== undefined && resp.response_code === 0) {
                setRoleCount(resp.activeemployeeSizeByRoleName)
            }
        }
        funName();

    }, []);


    const handleEditChange = () => {
        history.push({
            pathname: "/create-role",
            state: { role: roleName, maxUser: maxUser, assignedUser: roleCount }
        })
        history.go()
    }


    console.log('roleCount', roleCount)

    return (
        < >
            {(authenticated)
                ? (
                    <>
                        <div className="roleassigned">
                            <p> {process.env.REACT_APP_VAR_FOR_ROLES_AND_RESPONSIBILTY_Assigned_User}</p>
                            <h4>{roleCount}</h4>

                            <Can
                                role={userRole}
                                perform={performAssignRole}
                                roleData={roleData}
                                yes={() => (
                                    <Link to={`/emp-list?filter=${roleName}`} className="sbtn btnbl">{process.env.REACT_APP_VAR_FOR_ROLES_AND_RESPONSIBILTY_VIEW}</Link>
                                )}
                                no={() =>
                                    null
                                }
                            />

                        </div>
                        <div className="maxrole">
                            <SubRoleButton role={role} roleName={role.roleName} maxUser={role.maxUser} setRoleName={setRoleName} performDeleteRole={performDeleteRole} performEditDetail={performEditDetail} />
                            <p>{process.env.REACT_APP_VAR_FOR_ROLES_AND_RESPONSIBILTY_Max_User}</p>
                            <h4>{maxUser === 0 || maxUser === null || maxUser === "" ? <>NA</> : maxUser}</h4>
                        </div>
                    </>
                )
                : (
                    <Redirect to='/' />
                )


            }

        </>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount, userRole,
    maxFoodAmount,
    maxTravelAmount }, token: { authenticated, token }, band: { bandData }, seteditLimt: { editBandDetail }, role: { roleData, currentUserRoleName } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, editBandDetail,
        maxTravelAmount, token, roleData, userRole, currentUserRoleName
    });

export default connect(mapStateToProps)(AssignedUserRoleComponent);




