import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import '../css/employee.css';
import ApiCallingService from '../../service/api-call-service'
import Pagination from "react-js-pagination";
import RecordFilterComponent from './record-filter';
import RecordList from './record-list.component';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { store } from '../../redux/store';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
const collectedTrueKeys = {
    corpBands: [],
    location: [],
    department: [],
    responseDescription: []
}
class FailedRecord extends React.Component {
    constructor() {
        super();
        this.state = {
            employeeByBatch: [],
            batchId: '',
            currentPage: 1,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_BULK_EMPLOYEE_RECORDS,
            uploadeDetail: [],
            multiRecord: [],
            filteredRecord: [],
            multiValueD: [],
            multiValueB: [],
            multiValueL: [],
            multiValueR: [],
            allValuesForFilter: [],
            reasonMultiValue: []

        }
    }

    componentDidMount = () => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "active",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));


        const getDetail = async () => {
            if (this.props.location.state) {
                const batchId = this.props.location.state.bulkDetail.batchId;
                const responseStatus = this.props.location.state.status
                var object = "object"
                const data = this.props.location.state.bulkDetail;
                console.log('data', data)
                this.setState({ uploadeDetail: data })

                const url = `${process.env.REACT_APP_GET_Employee_By_BATCHID}?batchId=${batchId}&responseStatus=${responseStatus}`
                //const url = `http://localhost:7072/employee/fetchbatch?batchId=${batchId}`
                var reason = [];
                var response = await ApiCallingService.getMethodCallWithoutParam(url)
                console.log("failRecord response by batchId", response);

                if (!(response === undefined) && !(response.employees === undefined) && !(response.employees === null)) {
                    this.setState({ employeeByBatch: response.employees });
                    this.setState({ filteredRecord: response.employees });

                    response.employees.map(rea => {
                        reason.push(rea.responseDescription);
                    })
                    var actualMap = []
                    var uniq = reason.filter(onlyUnique);
                    uniq.map(un => {
                        var detail = {
                            value: "",
                            label: ""
                        }
                        detail.label = un;
                        detail.value = un;
                        actualMap.push(detail);
                    })

                    var failRecord = response.size;
                    console.log('failRecord', failRecord)
                    console.log("this.state record", this.state.employeeByBatch);
                    this.setState({ reasonMultiValue: actualMap })
                }
            }
        }

        getDetail();

    }



    handleMultiDepartmentchange = (option) => {
        // ((bandListToView === null || !(bandListToView.length && bandListToView)
        this.setState({ multiValueD: option })
        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.department = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.department.push(op.value)

            })
        }
    }
    handleMultiBandchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueB: option })
        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.corpBands = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.corpBands.push(op.value)

            })
        }


    }
    handleMultiLocationchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueL: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.location = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.location.push(op.value)

            })
        }

    }

    handleReasonchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueR: option })

        this.setState({ currentPage: 1 });
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log(' allValuesForFilter else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            this.setState({ allValuesForFilter: data });

        }

        collectedTrueKeys.responseDescription = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.responseDescription.push(op.label);
            })
        }

    }




    clearAll = () => {
        this.setState({ filteredRecord: this.state.employeeByBatch })
        this.setState({ multiValueD: [] })
        this.setState({ multiValueL: [] })
        this.setState({ multiValueB: [] })
        this.setState({ multiValueR: [] })

        this.setState({ allValuesForFilter: [] })


        collectedTrueKeys.department = [];
        collectedTrueKeys.location = [];
        collectedTrueKeys.band = [];
        collectedTrueKeys.responseDescription = [];

    }

    paginate = (pageNumber) => { this.setState({ currentPage: pageNumber }) };


    render() {


        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };




        var i = 0;


        //console.log(posts);
        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;


        var filteredPost = [];

        if ((this.state.allValuesForFilter === null || !(this.state.allValuesForFilter !== undefined && this.state.allValuesForFilter.length && this.state.allValuesForFilter))) {
            filteredPost = this.state.filteredRecord
            // filteredPost = filteredList;
            console.log('inside if of bandlistToView', this.state.allValuesForFilter)
        } else if (this.state.allValuesForFilter.length !== 0) {

            filteredPost = multiPropsFilter(this.state.filteredRecord, collectedTrueKeys);

            console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)

        }


        if (filteredPost === null) {
            currentPost = null
        } else {
            currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost);
        }
        console.log("this.state.employeeByBatch true", filteredPost)


        if (this.props.location.state.bulkDetail.isBulk === 1) {
            var EmplName = '';

            filteredPost.map(bulkDetail => {
                EmplName = bulkDetail.firstName === null && bulkDetail.lastName === null ? "" : `${bulkDetail.firstName} ${bulkDetail.lastName}`
            })
        }



        return (
            (this.props.authenticated)
                ?
                (
                    <div className="mid-container" id="heightFix">
                        <div className="container-fluid">




                            <div className="head">

                                <div className="row">

                                    <div  className="col-10">
                                        <h3>Failed Records </h3>
                                    </div>

                                    <RecordFilterComponent type='fail' clearAll={this.clearAll} multiValueD={this.state.multiValueD} multiValueB={this.state.multiValueB}
                                        multiValueL={this.state.multiValueL} reasonMultiValue={this.state.reasonMultiValue} multiValueR={this.state.multiValueR}
                                        handleMultiDepartmentchange={this.handleMultiDepartmentchange} handleMultiBandchange={this.handleMultiBandchange}
                                        handleMultiLocationchange={this.handleMultiLocationchange} handleReasonchange={this.handleReasonchange} />


                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="alert alert-danger">{this.props.location.state.bulkDetail.isBulk === 1 ? <>{EmplName} added by</> : <>Uploaded by</>} {this.state.uploadeDetail.name} on {this.state.uploadeDetail.downloadDate}</div>
                                </div>
                                {currentPost.length > 0
                                    ?
                                    <RecordList employeeList={currentPost} recordType="fail" />
                                    :
                                    <div className="col-12">
                                        <div className="no-record">
                                            <i></i>
                                            <h3> No Record Available.</h3>
                                        </div>
                                    </div>

                                }
                                {parseInt(filteredPost.length) > parseInt(this.state.postsPerPage)
                                    ?
                                    <>
                                        <div className="col-12 col-sm-6">
                                            <p>Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <Pagination
                                                    activePage={parseInt(this.state.currentPage)}
                                                    itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                    totalItemsCount={parseInt(filteredPost.length)}
                                                    pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                    onChange={this.paginate}
                                                    className="pagination"
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    nextPageText="Next"
                                                    prevPageText="Prev"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <Redirect to='/' />
                )

        )
    }

}

const mapStateToProps = ({ token: { authenticated }, bulk: { statusFail, bulkDetailFail } }) => ({
    authenticated, statusFail, bulkDetailFail
});

export default connect(mapStateToProps)(FailedRecord);