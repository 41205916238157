
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import history from '../../auth/history';
import CsvDownload from 'react-json-to-csv';

class SuccessReviewPage extends React.Component {

    constructor() {
        super();

        this.state = {
            dropDownHidden: false,
            message: "",
            severty: "success",
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards"

        localStorage.setItem('url', '');


    }


    orderAgain = () => {
        history.push("/manage-giftCard");
        history.go();
    }




    render() {

        var fileToExport = [];
        var i = 0;

        this.props.orderResponse !== undefined && this.props.orderResponse.data !== undefined
            && this.props.orderResponse.data.map(fileData => {

                var newData = {
                    SerialNumber: ++i,
                    BenificiaryName: fileData.beneficiary_name,
                    BenificiaryMobile: fileData.beneficiary_mdn,
                    BenificiaryEmail: fileData.beneficiary_email,
                    Count: fileData.count,
                    Amount: fileData.total_amount,
                    Reason: fileData.message
                }
                fileToExport.push(newData);
                // return fileToExport;
            })


        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>
                                <div className="col-12 col-md-6 col-lg-8">
                                    <div className="giftreview">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Gift_Cards_Review} <a href="/manage-giftCard" data-toggle="tooltip" title="" className="back" data-original-title="Back">Back</a></h3>
                                            </div>

                                            <div className="col-12">
                                                <div className="uploadfailed">

                                                    <CsvDownload className="exlfile" data={fileToExport} filename={"failed-order.csv"} >{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Download_Fail_Record}</CsvDownload>


                                                    <h5>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Bulk_upload_failed}</h5>
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Please_download_failed_row_document}</p>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Ordered_By}</p>
                                                    <h5>{this.props.orderResponse.orderBy}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Mobile_Number}</p>
                                                    <h5>+91-{this.props.orderResponse.mobileNumber}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Gift_Cards_Quantity}</p>
                                                    <h5>{this.props.orderResponse.giftCardQuantity}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Beneficiaries}</p>
                                                    <h5>{this.props.orderResponse.totalBenificiary}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Amount}</p>
                                                    <h5>₹{parseFloat(this.props.orderResponse.totalAmount)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Surcharge}</p>
                                                    <h5>NA</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Total_Amount}</p>
                                                    <h5>NA</h5>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="reviewadrs">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Billing_Address}</p>
                                                    <h5>{this.props.orderResponse.uploadedByName}</h5>
                                                    <p>{this.props.orderResponse.billingAddress} </p>
                                                    <p><i className="mobile"></i>+91-{this.props.orderResponse.mobileNumber}</p>
                                                </div>
                                            </div>




                                            <div className="col-12 mt-5">
                                                <button className="mbtn btnbl" onClick={this.orderAgain}>{process.env.REACT_APP_MANAGE_GIFT_CARD_FailedReview_VAR_FOR_Cancel_Button}</button>
                                                {/* <button className="mbtn btnbl" data-bs-toggle="modal" data-bs-target="#sendotp">Order Now</button> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName }, role: { roleData },
    giftCardOrder: { orderResponse }
}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser, fullName, orderResponse

});

export default connect(mapStateToProps)(SuccessReviewPage);