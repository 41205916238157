import React, { useState, useEffect } from 'react';
import '../css/upload-history.styles.css';
import '../css/employee.css';
import ApiCallingService from '../../service/api-call-service';
import UploadList from '../component/upload-list.component';
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import UploadFilter from '../component/upload-filter';
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import moment from "moment";
import apiCallService from '../../service/api-call-service';
import LoadingComponent from './upload-history-loading.component';
import { Redirect } from "react-router-dom";

const collectedTrueKeys = {
    isBulk: [],
    successsCountCondition: [],
    failedCountCondition: [],
}
var ToDateVar = '';
var FromDateVar = '';
const UploadHistory = ({ roMdn, authenticated }) => {
    var arr = [];
    const [bulkDetail, setBulkDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [postsPerPage] = useState((process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_BULK_EMPLOYEE));
    const [responseStatus, setResponseStatus] = useState('');
    const [multiTypeRecord, setMultiTypeRecord] = useState([]);
    const [multiStatusRecord, setMultiStatusRecord] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [filterRecord, setFilterRecord] = useState([]);


    const [allValuesForFilter, setAllValuesForFilter] = useState([]);

    useEffect(() => {

        document.title = "ShakePe Employee Add History"

        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "active",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
        const fetchPosts = async () => {


            const url = `${process.env.REACT_APP_GET_ALL_BATCHID}?roId=${roMdn}`;
            //console.log('url', url);
            const res = await ApiCallingService.getMethodCallWithoutParam(url);
            // console.log('UploadHistory Response : ', res);
            if (!(res === undefined) && !(res === null) && !(res.batchIdResponses === undefined)) {
                setResponseStatus(res.response_code);
                console.log('response inside UploadHistory', res.batchIdResponses);
                setBulkDetail(res.batchIdResponses);
                res.batchIdResponses.map(bulkDetail => {
                    arr.push(bulkDetail);
                    //     console.log(bulkDetail);
                })
                setFilterRecord(arr);
                setBulkDetail(arr);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }


        fetchPosts();
    }, []);



    const handleTypeMultichange = (option) => {
        console.log('option log', option)
        setMultiTypeRecord(option)

        setCurrentPage(1)
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.isBulk = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.isBulk.push(parseInt(op.value));
            })
        }


    }
    const handleStatusMultichange = (option) => {
        //s  console.log('option log', option)
        // { value: "0", label: "Success" },
        // { value: "1", label: "Failed" },
        // { value: "2", label: "Inprogress" }
        setMultiStatusRecord(option)

        setCurrentPage(1)
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }


        // { value: "0", label: "Success" },
        // { value: "1", label: "Failed" }

        collectedTrueKeys.successsCountCondition = [];
        collectedTrueKeys.failedCountCondition = [];
        if (option !== null) {
            option.map(op => {

                if (op.label === "Success") {
                    collectedTrueKeys.successsCountCondition.push(op.value);
                }
                if (op.label === "Failed") {
                    collectedTrueKeys.failedCountCondition.push(op.value);
                }
            })
        }


    }

    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            setToDate(value);
            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value);
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");

            const requestToSend = {
                "roMdn": roMdn,
                "fromDate": `${formatFromDate} 00:00:00`,
                "toDate": `${formatToDate} 23:59:59`

            }


            const url = process.env.REACT_APP_EMPLOYEE_BATCH_BY_DATERANGE;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('response', response)

            if (!(response === undefined) && (response.response_code === 0)) {
                setFilterRecord(response.batchIdResponses);
                setCurrentPage(1)
            } else {
                setFilterRecord([]);
            }


        }
    }


    const clearAll = () => {



        collectedTrueKeys.successsCountCondition = [];
        collectedTrueKeys.failedCountCondition = [];
        collectedTrueKeys.isBulk = []

        ToDateVar = '';
        FromDateVar = '';
        setMultiStatusRecord([]);
        setMultiTypeRecord([])
        setFromDate('');
        setToDate('');
        setFilterRecord(bulkDetail);
        setAllValuesForFilter([])

    }



    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    //Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };



    var filteredPost = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPost = filterRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPost = multiPropsFilter(filterRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)



    }




    if (filteredPost === null || filteredPost === undefined) {
        currentPost = null
    } else {
        currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
    }

    console.log('currentpost', currentPost);
    console.log('filteredPost', bulkDetail);


    return (
        (authenticated)
            ?
            <div className="mid-container" id="heightFix">
                <div className="container-fluid">

                    <UploadFilter clearAll={clearAll} handleTypeMultichange={handleTypeMultichange} handleStatusMultichange={handleStatusMultichange} toDate={toDate} fromDate={fromDate} handleDateChange={handleDateChange} multiStatusRecord={multiStatusRecord} multiTypeRecord={multiTypeRecord} />


                    <div className="emp-list">
                        <div className="row">
                            {
                                loading
                                    ?
                                    <LoadingComponent />
                                    :
                                    filterRecord.length === 0 || !currentPost.length > 0
                                        ?
                                        <div className="col-12">
                                            <div className="no-record">
                                                <i></i>
                                                <h3> No Record Available.</h3>
                                            </div>
                                        </div>
                                        :
                                        <UploadList employeeList={currentPost} responseStatus={responseStatus} />
                            }
                            {
                                parseInt(filteredPost.length) > parseInt(postsPerPage)
                                    ?
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <p>Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <Pagination
                                                    activePage={parseInt(currentPage)}
                                                    itemsCountPerPage={parseInt(postsPerPage)}
                                                    totalItemsCount={filteredPost.length}
                                                    pageRangeDisplayed={parseInt(postsPerPage)}
                                                    onChange={paginate}
                                                    className="pagination"
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    nextPageText="Next"
                                                    prevPageText="Prev"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }


                        </div>
                    </div>
                </div>
            </div>
            :
            <Redirect to="/"></Redirect>

    )
}



const mapStateToProps = ({ user: { roMdn }, token: { authenticated } }) => ({
    roMdn, authenticated
});


export default connect(mapStateToProps)(UploadHistory);



