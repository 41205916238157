import React, { useState, useEffect } from 'react';
import '../css/employee.css';
import ApiCallingService from '../../service/api-call-service';
import Can from '../../auth/can/Can';
import { Redirect } from 'react-router-dom';
import DashBoardImage from '../../assests/dashboard.png'
import { connect } from "react-redux";
import { store } from '../../redux/store';
import { setBand } from '../../redux/band/band.action'
import DashBoardComponent from '../component/dashboard.component';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

import AdminDashBoardComponent from '../../corporate/component/corporate-dashboard.component';

const DashBoardPage = ({ userRole, roleData, roMdn, authenticated, isPasswordReset }) => {
  const [response, setResponse] = useState([]);

  useEffect(() => {

    document.title = "ShakePe Dashboard"

    const fetchPosts = async () => {
      async function run() {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        console.log(url);
        console.log(roMdn)
        const res = await ApiCallingService.getMethodCallWithParam(url, { 'roId': roMdn });
        console.log('response inside AuthService', res);

        setResponse(res)
      }
      run();
    }
    fetchPosts();
  }, []);


  return (

    console.log('inside dashboard before can :', { userRole }),
    console.log('inside dashboard before can :', { roleData }),
    <>
      {(authenticated)
        ? (
          <Can
            role={userRole}
            perform="dashboard-page:visit"
            roleData={roleData}
            yes={() => (
              <>

                {isPasswordReset ?
                  <DashBoardComponent />
                  :
                  <ResetLoginPassword />
                }
              </>
            )}
            no={() =>


              <Can
              role={userRole}
              perform="subheader-admindashboard:visit"
              roleData={roleData}
              yes={() => (
                <>
  
                  {isPasswordReset ?
                    <AdminDashBoardComponent />
                    :
                    <ResetLoginPassword />
                  }
                </>
              )}
              no={() =>
  
                <Redirect to="/corp-list" />
             
              }
            />


             
            }
          />
        )
        : (
          <Redirect to='/' />
        )



      }
    </>
  )
}

const mapStateToProps = ({ user: { userRole }, user: { roMdn }, role: { roleData }, token: { authenticated, isPasswordReset } }) => ({
  userRole,
  roMdn,
  roleData,
  authenticated, isPasswordReset
});


export default connect(mapStateToProps)(DashBoardPage);
