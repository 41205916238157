import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import history from '../../auth/history';
import apiCallService from '../../service/api-call-service';
import '../css/role-permission.styles.css'
import AssignedUserRoleComponent from '../component/sub-assigned-role-user.component';
import Can from '../../auth/can/Can';
import SubRoleButton from '../component/sub-role-button.component';

import LoadingComponent from './roles.loading.pages';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import { store } from '../../redux/store';
import { setFromRole } from '../../redux/role/role.action';
import SuperAdminRolePermissionPages from './superAdmin-role-permission.pages';

// id pattern =`${pagezGroupId} ${pageId} ${actionId}`

var roleAction = []
class RolePermission extends React.Component {

    constructor() {
        super();

        this.state = {
            roleDetail: [],
            pageDetail: [],
            actionType: [],
            result: [],
            dropDownHidden: '',
            message: '',
            severty: '',
            roleName: '',

            loading: true,


            saveprocessBar: false
        };
    }

    componentDidMount = () => {

        document.title = "ShakePe Roles and Permissions"
        this.dd();
        store.dispatch(setFromRole(0))
        localStorage.setItem('from', '')

    }
    dd = async () => {
        var ff = await this.getActionType();
        this.getRoleDetail();
        this.getPageDetail();
    }

    getRoleDetail = async () => {
        var url = `${process.env.REACT_APP_OEMS_ACL_GET_ROLE_ACCESS_DETAIL_BY_ROID}?roId=${this.props.roMdn}`
        console.log('url getRoleDetail', url);

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log('response RolePermission getRoleDetail', response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.responseDetail !== undefined) {
            this.setState({ roleDetail: response.responseDetail });
            console.log('roleAction RolePermission', roleAction)
            roleAction = response.responseDetail.map(detail => {
                var detailD = {
                    roleName: "",
                    actionTypeArray: []
                }
                detailD.roleName = detail.roleName;
                detail.arrayType.map(act => {
                    detailD.actionTypeArray.push(act);
                })
                roleAction.push(detailD);

            });
        }

    }

    getPageDetail = async () => {

        var url = `${process.env.REACT_APP_OEMS_ACL_GET_PAGE_INFO}?roId=${this.props.roMdn}&isAdminStatus=0`;
        console.log('url getPageDetail', url);

        const actual = await apiCallService.getMethodCallWithoutParam(url);
        var response = actual
        console.log('response RolePermission getPageDetail', actual);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.roleBasedArray !== undefined) {

            // response.roleBasedArray.map(roleBased => {
            //     roleBased.responseDetail.map(responseDetail => {
            //         responseDetail.actionTypeAllowedArrayList.map(actionTypeAllowed => {
            //             if (actionTypeAllowed.actionTypes.length === 0) {

            //                 actionTypeAllowed.actionTypes = this.state.actionType
            //             } else {

            //                 this.state.actionType.map(action1 => {
            //                     actionTypeAllowed.actionTypes.map(action2 => {
            //                         if (action1.actionTypeId === action2.actionTypeId) {
            //                             action1.isChecked = true
            //                         } else {
            //                             action1.isChecked = false
            //                         }
            //                     }

            //                     )

            //                 })
            //                 actionTypeAllowed.actionTypes = this.state.actionType
            //             }
            //         })
            //     })
            // })

            this.setState({ pageDetail: response.roleBasedArray });
            this.setState({ loading: false });
        } else {
            this.setState({ loading: false });
        }
    }

    getActionType = async () => {

        var url = `${process.env.REACT_APP_OEMS_ACL_GET_DETAIL_BY_VARIABLE}?findByVar=actionType&roId=${this.props.roMdn}`;
        console.log('url getActionType', url);
        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log('response RolePermission getActionType', response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.responseData !== undefined) {
            var arr = []
            response.responseData.map(data => {
                var isChecked = 'isChecked';
                data[isChecked] = false
            })


            this.setState({ actionType: response.responseData });
        }
    }


    materCheckActionType = (event) => {

        console.log('materCheckActionType id', event.target.id);
        console.log('materCheckActionType name', event.target.name);
        this.setState({ roleName: event.target.name })
        // var actionTypeId = event.target.id;

        var ids = event.target.id;
        var idSeprate = ids.split('/');
        var actionTypeId = idSeprate[0];



        if (event.target.checked) {
            var finalArray = []
            this.state.result.map(result => {
                finalArray.push(result);
            })

            this.state.pageDetail.map(pageGrp => {
                pageGrp.responseDetail.map(page => {
                    if (pageGrp.roleName === event.target.name) {
                        // console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)',parseInt(page.pageGroupId),pageGrp.roleName,event.target.name, parseInt(pageGroupId),parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName===event.target.name)
                        page.actionTypeAllowedArrayList.map(page1 => {
                            page1.actionTypes.map(action => {
                                var subObject = {
                                    "pageGroupId": page.pageGroupId,
                                    "pageId": page1.pageNameId,
                                    "actionTypeId": parseInt(actionTypeId),
                                    "isEnable": 1
                                }
                                if (pageGrp.roleName === event.target.name && action.actionTypeId === parseInt(actionTypeId)) {
                                    finalArray.push(subObject);
                                    var ref = `${event.target.name}/${page.pageGroupId}/${page1.pageNameId}/${actionTypeId}`
                                    document.getElementById(ref).checked = true;
                                    // action.isChecked = `${page.pageGroupId}/${page1.pageNameId}/${actionTypeId}`

                                }
                            })
                        })
                    }
                })

            })
            // this.state.pageDetail.map(pageGr => {
            //     pageGr.pageNameArrayList.map(page => {
            //         var subObject = {
            //             "pageGroupId": pageGr.pageGroupId,
            //             "pageId": page.id,
            //             "actionTypeId": parseInt(actionTypeId),
            //             "isEnable": "1"
            //         }
            //         finalArray.push(subObject);
            //     })
            // })
            console.log(finalArray);
            this.setState({ result: finalArray });
        } else if (!event.target.checked) {
            // var finalArray = []
            // this.state.result.map(result => {
            //     if (result.actionTypeId !== parseInt(actionTypeId)) {
            //         finalArray.push(result);
            //     }
            // })
            // console.log(finalArray);
            // this.setState({ result: finalArray });
            // this.state.pageDetail.map(pageGrp => {
            //     pageGrp.responseDetail.map(page => {
            //         if (pageGrp.roleName === event.target.name) {
            //             // console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)',parseInt(page.pageGroupId),pageGrp.roleName,event.target.name, parseInt(pageGroupId),parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName===event.target.name)
            //             page.actionTypeAllowedArrayList.map(page1 => {
            //                 page1.actionTypes.map(action => {
            //                     var subObject = {
            //                         "pageGroupId": page.pageGroupId,
            //                         "pageId": page1.pageNameId,
            //                         "actionTypeId": parseInt(actionTypeId),
            //                         "isEnable": 1
            //                     }
            //                     if (pageGrp.roleName === event.target.name && action.actionTypeId === parseInt(actionTypeId)) {
            //                         finalArray.push(subObject);
            //                         var ref = `${event.target.name}/${page.pageGroupId}/${page1.pageNameId}/${actionTypeId}`
            //                         document.getElementById(ref).checked = false;
            //                         // action.isChecked = `${page.pageGroupId}/${page1.pageNameId}/${actionTypeId}`

            //                     }
            //                 })
            //             })
            //         }
            //     })

            // })
            var finalArray = []
            this.state.result.map(result => {
                finalArray.push(result);
            })

            this.state.pageDetail.map(pageGrp => {
                pageGrp.responseDetail.map(page => {
                    if (pageGrp.roleName === event.target.name) {
                        // console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)',parseInt(page.pageGroupId),pageGrp.roleName,event.target.name, parseInt(pageGroupId),parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName===event.target.name)
                        page.actionTypeAllowedArrayList.map(page1 => {
                            page1.actionTypes.map(action => {
                                var subObject = {
                                    "pageGroupId": page.pageGroupId,
                                    "pageId": page1.pageNameId,
                                    "actionTypeId": parseInt(actionTypeId),
                                    "isEnable": 0
                                }
                                if (pageGrp.roleName === event.target.name && action.actionTypeId === parseInt(actionTypeId)) {
                                    finalArray.push(subObject);
                                    var ref = `${event.target.name}/${page.pageGroupId}/${page1.pageNameId}/${actionTypeId}`
                                    document.getElementById(ref).checked = false;
                                    // action.isChecked = `${page.pageGroupId}/${page1.pageNameId}/${actionTypeId}`

                                }
                            })
                        })
                    }
                })

            })

            console.log(finalArray);
            this.setState({ result: finalArray });
        }
    }

    masterCheckPageGroupCheck = (event) => {

        console.log('masterCheckPageGroupCheck id', event.target.id);
        console.log('masterCheckPageGroupCheck name', event.target.name);
        this.setState({ roleName: event.target.name })
        // var pageGroupId = event.target.id;
        var ids = event.target.id;
        var idSeprate = ids.split('/');
        var pageGroupId = idSeprate[1];
        if (event.target.checked) {

            var finalArray = [];

            this.state.result.map(result => {
                finalArray.push(result);
            })


            console.log('finalArray before set', finalArray, this.state.result);

            this.state.pageDetail.map(pageGrp => {
                pageGrp.responseDetail.map(page => {
                    if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
                        console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
                        page.actionTypeAllowedArrayList.map(page1 => {
                            page1.actionTypes.map(action => {
                                var subObject = {
                                    "pageGroupId": parseInt(pageGroupId),
                                    "pageId": page1.pageNameId,
                                    "actionTypeId": action.actionTypeId,
                                    "isEnable": 1
                                }

                                if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
                                    console.log(page1.pageNameId, 'actionArrayToSet')
                                    var ref = `${event.target.name}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
                                    document.getElementById(ref).checked = true;
                                    // action.isChecked = `${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
                                    // console.log(action.isChecked,'actionArrayToSet')

                                    finalArray.push(subObject);
                                }
                            })
                        })
                    }
                })



            })



            console.log(finalArray);
            this.setState({ result: finalArray });
        } else {
            //     var array = []
            //     this.state.result.map(
            //         result => {
            //             if (result.pageGroupId !== parseInt(event.target.id)) {
            //                 array.push(result);
            //             }
            //         }
            //     )

            //     this.setState({ result: array });
            //     console.log(array);
            //     this.state.pageDetail.map(pageGrp => {
            //         pageGrp.responseDetail.map(page => {
            //             if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
            //                 console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
            //                 page.actionTypeAllowedArrayList.map(page1 => {
            //                     page1.actionTypes.map(action => {
            //                         var ref = `${event.target.name}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
            //                         document.getElementById(ref).checked = false;

            //                     })
            //                 })
            //             }
            //         })
            //     })

            var finalArray = [];

            this.state.result.map(result => {
                finalArray.push(result);
            })


            console.log('finalArray before set', finalArray, this.state.result);

            this.state.pageDetail.map(pageGrp => {
                pageGrp.responseDetail.map(page => {
                    if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
                        console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
                        page.actionTypeAllowedArrayList.map(page1 => {
                            page1.actionTypes.map(action => {
                                var subObject = {
                                    "pageGroupId": parseInt(pageGroupId),
                                    "pageId": page1.pageNameId,
                                    "actionTypeId": action.actionTypeId,
                                    "isEnable": 0
                                }

                                if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
                                    console.log(page1.pageNameId, 'actionArrayToSet')
                                    var ref = `${event.target.name}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
                                    document.getElementById(ref).checked = false;
                                    // action.isChecked = `${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
                                    // console.log(action.isChecked,'actionArrayToSet')

                                    finalArray.push(subObject);
                                }
                            })
                        })
                    }
                })



            })



            console.log(finalArray);
            this.setState({ result: finalArray });
        }




    }

    handleSingleCheck = (event) => {
        console.log('handleSingleCheck id', event.target.id);
        console.log('handleSingleCheck name', event.target.name);
        this.setState({ roleName: event.target.name })

        var ids = event.target.id;
        var idSeprate = ids.split('/');
        var pageGroupId = idSeprate[1];
        var pageId = idSeprate[2];
        var actionTypeId = idSeprate[3];

        console.log('pageGroupId, pageId, actionTypeId', pageGroupId, pageId, actionTypeId);


        if (event.target.checked) {
            var finalArray = [];

            this.state.result.map(result => {
                finalArray.push(result);
            })
            var subObject = {
                "pageGroupId": parseInt(pageGroupId),
                "pageId": parseInt(pageId),
                "actionTypeId": parseInt(actionTypeId),
                "isEnable": 1
            }
            finalArray.push(subObject);
            console.log(finalArray)
            this.setState({ result: finalArray });

            this.state.pageDetail.map(pageGrp => {
                pageGrp.responseDetail.map(page => {
                    if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
                        console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
                        page.actionTypeAllowedArrayList.map(page1 => {
                            page1.actionTypes.map(action => {

                                if (action.actionTypeId === parseInt(actionTypeId) && page1.pageNameId === parseInt(pageId) && page.pageGroupId === parseInt(pageGroupId)) {
                                    var ref = `${idSeprate[0]}/${pageGroupId}/${pageId}/${actionTypeId}`
                                    document.getElementById(ref).checked = true;
                                    action.isChecked = `${idSeprate[0]}/${pageGroupId}/${pageId}/${actionTypeId}`
                                }

                            })
                        })
                    }
                })

            })



        } else if (!event.target.checked) {
            // var finalArray = [];

            // this.state.result.map(result => {
            //     if (result.pageGroupId === parseInt(pageGroupId) && result.pageId === parseInt(pageId) && result.actionTypeId === parseInt(actionTypeId)) {

            //     } else {
            //         finalArray.push(result);
            //     }
            // })
            // console.log(finalArray)
            // this.setState({ result: finalArray });

            var finalArray = [];

            this.state.result.map(result => {
                finalArray.push(result);
            })
            var subObject = {
                "pageGroupId": parseInt(pageGroupId),
                "pageId": parseInt(pageId),
                "actionTypeId": parseInt(actionTypeId),
                "isEnable": 0
            }
            finalArray.push(subObject);
            console.log(finalArray)
            this.setState({ result: finalArray });


            this.state.pageDetail.map(pageGrp => {
                pageGrp.responseDetail.map(page => {
                    if (parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name) {
                        console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
                        page.actionTypeAllowedArrayList.map(page1 => {
                            page1.actionTypes.map(action => {

                                if (action.actionTypeId === parseInt(actionTypeId) && page1.pageNameId === parseInt(pageId) && page.pageGroupId === parseInt(pageGroupId)) {
                                    var ref = `${idSeprate[0]}/${pageGroupId}/${pageId}/${actionTypeId}`
                                    document.getElementById(ref).checked = false;

                                }

                            })
                        })
                    }
                })

            })
        }

    }


    handleSubmit = async () => {
        this.setState({ saveprocessBar: true });
        var request = {
            "roleName": this.state.roleName,
            "roId": this.props.roMdn,
            "updatedBy": this.props.currentUser,
            "actionArray": this.state.result
        }

        var url = process.env.REACT_APP_OEMS_ACL_ASSIGN_ACTION;

        var response = await apiCallService.postMethodCall(url, request);

        console.log('handleSubmit request', request);
        console.log('handleSubmit response', response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ saveprocessBar: false });
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: response.response_description })
        } else {
            this.setState({ saveprocessBar: false });
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }

    }



    resetAll = (event) => {

        var roleName = this.state.roleName

        var array = []

        this.setState({ result: array });
        console.log(array);
        this.state.pageDetail.map(pageGrp => {
            pageGrp.responseDetail.map(page => {
                console.log('event.target.name', event.target.name)


                if (pageGrp.roleName === roleName) {



                    var refPage = `${roleName}/${page.pageGroupId}`
                    console.log('ref `${pageGrp.roleName}/${page.pageGroupId}`', refPage)
                    document.getElementById(refPage).checked = false;



                    // console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
                    this.state.result.map(
                        result => {
                            if (result.pageGroupId !== parseInt(page.pageGroupId)) {
                                array.push(result);
                            }
                        }
                    )
                    page.actionTypeAllowedArrayList.map(page1 => {
                        page1.actionTypes.map(action => {
                            var ref = `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
                            console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                            document.getElementById(ref).checked = action.isChecked === "1";

                            var refAction = `${action.actionTypeId}/${roleName}`
                            console.log('ref `${action.actionTypeId}/${role.roleName}`', refAction)
                            document.getElementById(refAction).checked = false;



                        })
                    })
                }
            })
        })

    }


    removeRole = async (event) => {
        console.log('event triggered', this.state.roleName);
        var request = {
            "roleName": this.state.roleName,
            "status": 0,
            "roId": this.props.roMdn,
            "type": "update",
            "prevRole": this.state.roleName,
            "maxUserAllowed": "",
            "addOrUpdatedBy": this.props.currentUser
        }


        const url = process.env.REACT_APP_OEMS_ACL_ADD_ROLE_NAME
        console.log('request', url, request)

        var response = await apiCallService.postMethodCall(url, request);

        console.log('response', response)

        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: "success" })
            this.setState({ message: "Role Removed SuccessFully." })
            history.push('/roles-permissions')
            history.go();
        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: "error" })
            this.setState({ message: response.response_description })
        }


    }


    resetAllAsPrvious = (event) => {



        var array = []

        this.setState({ result: array });
        console.log(array);
        this.state.pageDetail.map(pageGrp => {
            pageGrp.responseDetail.map(page => {
                console.log('event.target.name', event.target.name)

                // console.log('parseInt(page.pageGroupId) === parseInt(pageGroupId)', parseInt(page.pageGroupId), pageGrp.roleName, event.target.name, parseInt(pageGroupId), parseInt(page.pageGroupId) === parseInt(pageGroupId) && pageGrp.roleName === event.target.name)
                this.state.result.map(
                    result => {
                        if (result.pageGroupId !== parseInt(page.pageGroupId)) {
                            array.push(result);
                        }
                    }
                )
                page.actionTypeAllowedArrayList.map(page1 => {
                    page1.actionTypes.map(action => {
                        var ref = `${pageGrp.roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`
                        console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                        document.getElementById(ref).checked = action.isChecked === "1";

                    })
                })

            })
        })

    }

    setRoleName = (event) => {

        console.log('event.target.id', event.target.getAttribute("id"))
        this.setState({ roleName: event.target.getAttribute("id") })
    }

    setFromWher = () => {
        localStorage.setItem('from', '')
    }

    render() {

        console.log('RolePermission state', this.state);

        return (
            <div >
                {(this.props.authenticated)
                    ? (
                        this.props.isPasswordReset
                            ?
                            <Can
                                role={this.props.userRole}
                                perform="header-rolesPermissions:visit"
                                roleData={this.props.roleData}
                                yes={() => (



                                    <div className="mid-container">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="head">



                                                        <Can
                                                            role={this.props.userRole}
                                                            perform="rolesAndPemisn-createRole:visit"
                                                            roleData={this.props.roleData}
                                                            yes={() => (
                                                                <div className="head-btn">
                                                                    <a href="/create-role" onClick={this.setFromWher} className="role"> {process.env.REACT_APP_VAR_FOR_ROLES_AND_PERMISSION_PAGE_FOR_Create_Role}</a>
                                                                </div>
                                                            )}
                                                            no={() =>
                                                                null
                                                            }
                                                        />

                                                        <h3>{process.env.REACT_APP_VAR_FOR_ROLES_AND_PERMISSION_PAGE_FOR_Roles_and_Permissions}</h3>
                                                    </div>
                                                </div>
                                                <Snackbar

                                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    open={this.state.dropDownHidden}
                                                    autoHideDuration={3000}
                                                    onClose={e => this.setState({ dropDownHidden: false })}
                                                >
                                                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                        {this.state.message}
                                                    </Alert>
                                                </Snackbar>

                                                <div className="col-12">
                                                    <div className="rolelist" id="rolelist" >

                                                        {

                                                            this.state.loading
                                                                ?
                                                                <LoadingComponent />
                                                                :


                                                                this.state.pageDetail.map(role => (

                                                                    <div className="rolecard">
                                                                        <div className="rolebase" data-bs-toggle="collapse" href={`#role${role.roleId}`}>
                                                                            <div className="rolebox">
                                                                                <div className="rolename">
                                                                                    <i></i>
                                                                                    <h5>{role.roleName}</h5>
                                                                                    <p>{role.roleCreatedOn}</p>
                                                                                </div>

                                                                                {/* <div className="roleassigned">
                                                                    <p>Assigned User</p>
                                                                    <h4>123</h4>
                                                                    <Link to={`/emp-list?filter=${role.roleName}`} className="sbtn btnbl">View</Link>
                                                                </div> */}
                                                                                <AssignedUserRoleComponent roleName={role.roleName} maxUser={role.maxUser} setRoleName={this.setRoleName} role={role} performAssignRole="rolesAndPemisn-viewAssignedRole:visit" 
                                                                                performDeleteRole="rolesAndPemisn-deleteRole:visit" performEditDetail="rolesAndPemisn-editDetail:visit" 
                                                                                />

                                                                            </div>
                                                                        </div>

                                                                        <div id={`role${role.roleId}`} className="collapse" data-bs-parent="#rolelist">
                                                                            <div className="permissions">
                                                                                <div className="row headbg">

                                                                                    <div className="col-4">
                                                                                        <div className="pertxts">{process.env.REACT_APP_VAR_FOR_ROLES_AND_PERMISSION_PAGE_FOR_Permissions}</div>
                                                                                    </div>

                                                                                    {this.state.actionType.map(action => (
                                                                                        <div className="col">
                                                                                            <div className="percheck">
                                                                                                <label className="">
                                                                                                    <input type="checkbox" id={`${action.actionTypeId}/${role.roleName}`} name={role.roleName} className="" onClick={this.materCheckActionType} /> {action.actionType}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))

                                                                                    }


                                                                                </div>

                                                                                <div className="permform" id={`permissions${role.roleId}`}>

                                                                                    {

                                                                                        role.responseDetail.map(page => (
                                                                                            <>
                                                                                                <div className="row rowhead">
                                                                                                    <div className="col-12">
                                                                                                        <div className="percheck">
                                                                                                            <label className="">
                                                                                                                <input type="checkbox" id={`${role.roleName}/${page.pageGroupId}`} name={role.roleName} className="" onClick={this.masterCheckPageGroupCheck} /> {page.pageGroupName}
                                                                                                            </label>
                                                                                                        </div>

                                                                                                        <a className="ctrlbtn collapsed" data-bs-toggle="collapse" href={`#perm${role.roleId}${page.pageGroupId}`}><span>ctrl</span></a>
                                                                                                    </div>
                                                                                                </div>



                                                                                                <div id={`perm${role.roleId}${page.pageGroupId}`} className="collapse" data-bs-parent={`#permissions${role.roleId}`}>
                                                                                                    {page.actionTypeAllowedArrayList.map(pageName => (

                                                                                                        <div className="row rowline">
                                                                                                            <div className="col-4">
                                                                                                                <div className="pertxts"> {pageName.pageName}</div>

                                                                                                            </div>

                                                                                                            {pageName.actionTypes.map(action => (
                                                                                                                <div className="col">
                                                                                                                    <div className="percheck">
                                                                                                                        <label className="">
                                                                                                                            <input type="checkbox" id={`${role.roleName}/${page.pageGroupId}/${pageName.pageNameId}/${action.actionTypeId}`} name={role.roleName} defaultChecked={action.isChecked === "1"} className="" onClick={this.handleSingleCheck} /></label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))

                                                                                                            }

                                                                                                        </div>

                                                                                                    ))

                                                                                                    } </div>
                                                                                            </>
                                                                                        ))

                                                                                    }

                                                                                </div>





                                                                            </div>
                                                                            <div className="roleperm">

                                                                                <Can
                                                                                    role={this.props.userRole}
                                                                                    perform="rolesAndPemisn-resetAll:visit"
                                                                                    roleData={this.props.roleData}
                                                                                    yes={() => (
                                                                                        <button type="button" className="mbtn btngr" onClick={this.resetAll}>Reset all</button>
                                                                                    )}
                                                                                    no={() =>
                                                                                        null
                                                                                    }
                                                                                />

                                                                                <Can
                                                                                    role={this.props.userRole}
                                                                                    perform="rolesAndPemisn-saveAll:visit"
                                                                                    roleData={this.props.roleData}
                                                                                    yes={() => (
                                                                                        role.isDeletable === 1 ?
                                                                                            <button type="button" className="mbtn btnbl" disabled={this.state.saveprocessBar} onClick={this.handleSubmit}>
                                                                                                {
                                                                                                    this.state.saveprocessBar
                                                                                                        ?
                                                                                                        <div className="spinner-border" role="status">
                                                                                                            <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <>Save</>
                                                                                                }
                                                                                            </button>
                                                                                            : null
                                                                                    )}
                                                                                    no={() =>
                                                                                        null
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        </div>

                                                                        {/* <SubRoleButton role={role} roleName={role.roleName} maxUser={role.maxUser} setRoleName={this.setRoleName} performDeleteRole="rolesAndPemisn-deleteRole:visit" performEditDetail="rolesAndPemisn-editDetail:visit" /> */}

                                                                    </div>
                                                                ))


                                                        }

                                                        <div className="modal fade" id="remove" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
                                                            <div className="modal-dialog modal-sm">
                                                                <div className="modal-content alerttext">
                                                                    <div className="modal-header">
                                                                        <h5 id=""> {process.env.REACT_APP_VAR_FOR_ROLES_AND_PERMISSION_PAGE_FOR_Remove_Roles}</h5>
                                                                        <button className="close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                                                                    </div>

                                                                    <div className="modal-body"> {process.env.REACT_APP_VAR_FOR_REMOVE_ROLE_BEFORE_ROLE_NAME} {this.state.roleName} {process.env.REACT_APP_VAR_FOR_REMOVE_ROLE_AFTER_ROLE_NAME}</div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="mbtn btngr" data-bs-dismiss="modal">{process.env.REACT_APP_VAR_FOR_REMOVE_ROLE_BUTTON_AS_CLOSE}</button>
                                                                        <button type="button" className="mbtn btnbl" data-bs-dismiss="modal" aria-label="Close" onClick={this.removeRole}>{process.env.REACT_APP_VAR_FOR_REMOVE_ROLE_BUTTON_AS_Remove}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                )}
                                no={() =>
                                    <Can
                                        role={this.props.userRole}
                                        perform="header-adminRolesPermissions:visit"
                                        roleData={this.props.roleData}
                                        yes={() => (
                                            <Redirect to="/superAdmin-roles-permissions" />
                                        )}
                                        no={() =>
                                            null
                                        }
                                    />

                                }
                            />

                            :
                            <ResetLoginPassword />
                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </div>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, currentUser, isPasswordReset

});

export default connect(mapStateToProps)(RolePermission);