import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import '../css/loading.styles.css'

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

export default function Animations() {
    //const classes = useStyles();
    return (


        <>



            {/* <div className="col-12 col-sm-6 col-xl-4" >

                <div className='loading-card'>

                    <div className="loading-basic">
                    <a  className="file-down" >Download</a>

                        <Skeleton className="loading-basic-img" variant="circle" />

                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <br />
                        <Skeleton className="loading-basic-p-span" variant="text" animation="wave" />
                    </div>


                </div>

            </div> */}


            <div className="col-12 col-sm-6 col-xl-4">
                <div className="upload-card">
                    <button className="file-down">Download</button>

                    <div className="upload-type">
                        <p> <Skeleton className="loading-full" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-full" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="uploadLoadingDate"> <Skeleton className="loading-full" variant="text" animation="wave" /></div>

                  
                    <div className="uploadLoading">
                    <Skeleton className="loading-full" variant="text" animation="wave" />
                       
                    </div>

                </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4">
                <div className="upload-card">
                    <button className="file-down">Download</button>

                    <div className="upload-type">
                        <p> <Skeleton className="loading-full" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-full" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="uploadLoadingDate"> <Skeleton className="loading-full" variant="text" animation="wave" /></div>

                  
                    <div className="uploadLoading">
                    <Skeleton className="loading-full" variant="text" animation="wave" />
                       
                    </div>

                </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
                <div className="upload-card">
                    <button className="file-down">Download</button>

                    <div className="upload-type">
                        <p> <Skeleton className="loading-full" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-full" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="uploadLoadingDate"> <Skeleton className="loading-full" variant="text" animation="wave" /></div>

                  
                    <div className="uploadLoading">
                    <Skeleton className="loading-full" variant="text" animation="wave" />
                       
                    </div>

                </div>
            </div>






        </>

    );
}


{/* <div className="col-12 col-sm-6 col-xl-4" >

                <div className='loading-card'>

                    <div className="loading-basic">
                        <Skeleton className="loading-basic-img" variant="circle" />

                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <br />
                        <Skeleton className="loading-basic-p-span" variant="text" animation="wave" />
                    </div>


                </div>

            </div> */}