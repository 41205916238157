import LocationType from "./location.type";
import { act } from "react-dom/test-utils";

const INITIAL_STATE = {
    locationData : [],
    locationListToView:[],
    departmentaList:[],
    locationCountry: 'NA',
    locationState: 'NA',
    ip:''
};


const locationReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case LocationType.SET_LOCATION:
            return {
                ...state,
                locationData : action.payload
            };
        case LocationType.SET_LOCATION_LIST_TO_VIEW:
            return {
                ...state,
                locationListToView: action.payload
            }
        case LocationType.SET_DEPARTMENT_LIST:
            return{
                ...state,
                departmentaList:action.payload
            }
            case LocationType.SET_LOCATION_COUNTRY_AND_STATE:
                return {
                
                    ...state,
                    locationCountry: action.payload.locationCountry,
                    locationState: action.payload.locationState,
                    ip: action.payload.ip
                };
           
        default:
            return state;
    }
};

export default locationReducer;