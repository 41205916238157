import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Can from '../../auth/can/Can';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import ReactTooltip from 'react-tooltip';


const SubRoleButton = ({ authenticated, currentUserRoleName, roMdn, maxUser, roleName, setRoleName, roleData, userRole, role, performEditDetail, performDeleteRole }) => {


    const [roleCount, setRoleCount] = useState(0);

    useEffect(() => {

        const funName = async () => {
            var url = `${process.env.REACT_APP_GET_EMPLOYEE_COUNT_BY_ROLENAME}?ro_Id=${roMdn}&role_name=${roleName}`;

            var resp = await apiCallService.getMethodCallWithoutParam(url);

            console.log(resp)
            if (resp !== undefined && resp.response_code !== undefined && resp.response_code === 0) {
                setRoleCount(resp.activeemployeeSizeByRoleName)
            }
        }
        funName();

    }, []);


    const handleEditChange = () => {
        history.push({
            pathname: "/create-role",
            state: { role: roleName, maxUser: maxUser, assignedUser: roleCount }
        })
        history.go()
    }


    console.log('roleCount', role)

    return (
        < >
            {(authenticated)
                ? (

                    // <div className="maxrole">
                    <div className="rolebtn">


                        <Can
                            role={userRole}
                            perform={performDeleteRole}
                            roleData={roleData}
                            yes={() => (
                                (parseInt(roleCount) === 0 || !currentUserRoleName === roleName) && role.isDeletable === 1 ?
                                    <>
                                        <ReactTooltip
                                            id="back"
                                            place="top"
                                            type='dark'
                                            effect='solid'
                                            // multiline={true}
                                            backgroundColor={(parseInt(roleCount) === 0 || !currentUserRoleName === roleName) ? "black" : ""}
                                        />
                                        <button className="mdl-js-button mdl-js-ripple-effect" data-bs-toggle="modal" data-for="back" data-tip="Remove" data-iscapture="true" data-bs-target="#remove" id={roleName} name={maxUser} onClick={setRoleName}><i className="delete" id={roleName}>Delete</i></button>
                                    </>
                                    :
                                    null// <button className="mdl-js-button mdl-js-ripple-effect" id={roleName}><i className="delete" id={roleName}>Delete</i></button>

                            )}
                            no={() =>
                                null
                            }
                        />



                        <Can
                            role={userRole}
                            perform={performEditDetail}
                            roleData={roleData}
                            yes={() => (
                                parseInt(roleCount) === 0 || !currentUserRoleName === roleName
                                    ?
                                    <>
                                        <ReactTooltip
                                            id="editbutton"
                                            place="top"
                                            type='dark'
                                            effect='solid'
                                            // multiline={true}
                                            backgroundColor={(parseInt(roleCount) === 0 || !currentUserRoleName === roleName) ? "black" : ""}
                                        />
                                        <button className="mdl-js-button mdl-js-ripple-effect" data-for="editbutton" data-tip="Edit" data-iscapture="true" onClick={handleEditChange}><i className="edit">Edit</i></button>
                                    </>
                                    :
                                    null
                            )}
                            no={() =>
                                null
                            }
                        />

                    </div>
                    //     {/* <p>Max User</p>
                    //     <h4>{maxUser === 0 || maxUser === null || maxUser === "" ? <>NA</> : maxUser}</h4>
                    // </div> */}

                )
                : (
                    <Redirect to='/' />
                )


            }

        </>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount, userRole,
    maxFoodAmount,
    maxTravelAmount }, token: { authenticated, token }, band: { bandData }, seteditLimt: { editBandDetail }, role: { roleData, currentUserRoleName } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, editBandDetail,
        maxTravelAmount, token, roleData, userRole, currentUserRoleName
    });

export default connect(mapStateToProps)(SubRoleButton);




