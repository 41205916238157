
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";

import { Link, Redirect } from "react-router-dom";
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import UploadImage from './upload-file.component';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import apiCallService from '../../service/api-call-service';
import { store } from '../../redux/store';
import { setOrderResponse } from '../../redux/giftCardOrder/order.action';


import FailedSection from '../component/fail-reviewPage';
import SuccessSection from '../component/success-reviewPage';


import DetailSection from '../component/giftCardOrder-detailed';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import Can from '../../auth/can/Can';

var collectedTrueKeys = {
    status: []
}
class MangeGiftCard extends React.Component {

    constructor() {
        super();

        this.state = {
            dropDownHidden: false,
            message: "",
            severty: "success",
            address: "",
            orderSection: true,
            failSection: false,
            successSection: false,
            detailSection: false,

            giftCardDetail: [],

            detailedResposeForDetailPage: [],
            buttonLoader: false,
            history: [],
            loading: false
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards"

        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "",
            adminReportCLass: "",
            manageGiftCardClass: "active"
        }

        store.dispatch(setActiveHeader(payload));

        localStorage.setItem('url', '');
        store.dispatch(setOrderResponse([]));

        this.fetchRo();

        this.frtchGiftCardDetail();
        this.getOrderHistory();
    }


    getOrderHistory = async () => {

        this.setState({ history: [] })

        this.setState({ loading: true })
        // this.setState({ totalLength: 0 })

        var url = process.env.REACT_APP_GET_GIFT_CARD_ORDER_HISTORY_URL;
        var request = {
            "roId": this.props.roMdn,
            "page": 1,
            "size": 1,
            "searchKeyword": "",
            "fromDate": "",
            "toDate": "",
            "collectedTrueKeys": collectedTrueKeys
        }

        var res = await apiCallService.postMethodCall(url, request);

        console.log("response getGiftCardOrderHistory", res)

        if (res !== undefined && res.response_code === 0) {
            console.log('response inside fetch post', res);

            // this.setState({ totalLength: res.size })

            this.setState({ history: res.orderDetailResponses[0] })

            this.setState({ loading: false })
        }

        else {
            this.setState({ history: [] })
            this.setState({ loading: false })
        }


    }


    frtchGiftCardDetail = async () => {
        const url = `${process.env.REACT_APP_GET_GIFT_CARD_DETAILS_URL}?roId=${this.props.roMdn}&responseStatus=5`;

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log("Response for frtchGiftCardDetail", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ giftCardDetail: response })
        }
    }

    fetchRo = async () => {
        // store.dispatch(resetOder);

        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        // console.log(url);

        const res = await apiCallService.getMethodCallWithParam(url, { 'roId': this.props.roMdn });

        console.log('response inside AuthService', res);
        if (res !== undefined && res.response !== undefined && res.response !== undefined) {
            this.setState({ address: res.response.address1 + ", " + res.response.address2 + ", " + res.response.city + ", " + res.response.state + " - " + res.response.pincode })

        }
    }



    creaeGiftCardOrder = async () => {

        if (localStorage.getItem('url') === '') {
            this.setState({
                dropDownHidden: true,
                message: "Please upload file",
                severty: "warning"
            })
        } else {
            this.setState({ buttonLoader: true })
            var request = {
                "fileUrl": localStorage.getItem('url'),
                "uploadedByEmail": this.props.currentUser,
                "roId": this.props.roMdn,
                "uploadedByName": this.props.fullName,
                "uploadedByMdn": this.props.mdn,
                "billingAddress": this.state.address,
                "corpName": this.props.roName

            }
            var url = process.env.REACT_APP_PLACE_GIFT_CARD_ORDER_URL;

            var response = await apiCallService.postMethodCall(url, request);

            if (response !== null && response.response_code !== undefined && response.response_code === 0) {
                this.setState({ buttonLoader: false })
                store.dispatch(setOrderResponse(response));

                this.setState({
                    orderSection: false,
                    failSection: false,
                    successSection: true,
                    detailSection: false
                })



            } else if (response !== null && response.response_code !== undefined && response.response_code === 1) {
                this.setState({ buttonLoader: false })
                store.dispatch(setOrderResponse(response));

                this.setState({
                    orderSection: false,
                    failSection: true,
                    successSection: false,
                    detailSection: false
                })

            } else {
                this.setState({ buttonLoader: false })
                this.setState({ message: response.response_description })
                this.setState({ severty: "error" })
                this.setState({ dropDownHidden: true })
            }
        }
    }


    showDetailedSection = (event, detail) => {
        console.log("y to shi hai na", detail)
        this.setState({ detailedResposeForDetailPage: detail })
        this.setState({
            orderSection: false,
            failSection: false,
            successSection: false,
            detailSection: true
        })
    }



    render() {


        var data = this.state.history

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?


                            <Can
                                role={this.props.userRole}
                                perform="subheader-manageGiftCard:visit"
                                roleData={this.props.roleData}
                                yes={() => (
                                    <>

                                        <div className="mid-container">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <Snackbar

                                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        open={this.state.dropDownHidden}
                                                        autoHideDuration={20000}
                                                        onClose={e => this.setState({ dropDownHidden: false })}
                                                    >
                                                        <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                            {this.state.message}
                                                        </Alert>
                                                    </Snackbar>
                                                    {

                                                        this.state.orderSection &&

                                                        <div className="col-12 col-md-6 col-lg-8">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="viewgcard">
                                                                        <div className="row">
                                                                            <div className="col-12 col-lg-3">
                                                                                <div className="giftcard">
                                                                                    <i className="tgc"></i>
                                                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Total_Gift_Cards}</p>
                                                                                    <h5>{this.state.giftCardDetail.totalGiftCardsOrdered}</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-lg-4">
                                                                                <div className="giftcard">
                                                                                    <i className="tga"></i>
                                                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Total_Amount_Disbursed}</p>
                                                                                    <h5>₹ {this.state.giftCardDetail.totalAmountDisbursed}</h5>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-lg-5">
                                                                                <div className="giftcard last">
                                                                                    <Link to="/issued-giftcards" className="buttonValue sbtn btngn" >View all</Link>
                                                                                    <i className="tgu"></i>
                                                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Total_Users_Issued}</p>
                                                                                    <h5>{this.state.giftCardDetail.totalBeneficiariesAdded}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div >
                                                                        <div className="giftcardupload">
                                                                            <h3>{process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_HEADING_Gift_Cards_Bulk_Order} <a href={process.env.REACT_APP_GIFT_CARD_ORDER_SAMPLE_FILE_URL} target="_blank" className="exl">Sample File</a></h3>



                                                                            <UploadImage
                                                                                currentUser={this.props.currentUser}
                                                                                header=""
                                                                                h6Value={process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_UPLOAD_FILE_Drag_Drop_or}
                                                                                h6SpanValue={process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Upload_File_Browes}
                                                                                h6ValueAfterbreak={process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Upload_FILE_files_here}
                                                                                pValue={process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Upload_file_File_Format}
                                                                            />


                                                                            <button type="button" value="Proceed" className="lbtn btnbl" onClick={this.creaeGiftCardOrder} >

                                                                                {this.state.buttonLoader
                                                                                    ?
                                                                                    <div className="spinner-border" role="status">
                                                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        {process.env.REACT_APP_MANAGE_GIFT_CARD_VAR_FOR_Upload_file_Proceed_button}
                                                                                    </>
                                                                                }

                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>

                                                    }

                                                    {this.state.failSection && <FailedSection />}
                                                    {this.state.successSection && <SuccessSection showDetailedSection={this.showDetailedSection} />}

                                                    {this.state.detailSection && <DetailSection detailedData={this.state.detailedResposeForDetailPage} getOrderHistory={this.getOrderHistory} />}




                                                    {

                                                        ((this.state.history) === null || (this.state.history) === undefined || this.state.history === [] || data.totalBenificiary === undefined || data.totalBenificiary === null
                                                            || data.totalBenificiary === "")
                                                            ?
                                                            <div className="col-12 col-md-6 col-lg-4">
                                                                <div className="lastgiftorder" style={{ minHeight: "445px" }}>
                                                                    <div className="col-12 ">
                                                                        <div className="no-record">
                                                                            <i></i>
                                                                            <h3 style={{ borderBottom: "none" }}> No Record Available.</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-12 col-md-6 col-lg-4">
                                                                <div className="lastgiftorder">
                                                                    <h3>Last Order Details <a href="/giftcard-history" className="sbtn btnbl">Order history</a></h3>
                                                                    <div className="giftorderid">Order ID: <strong>#{data.orderId}</strong></div>

                                                                    <div className="giftorderby">
                                                                        <h5>Order By:</h5>
                                                                        <h6>{data.uploadedByName}</h6>
                                                                        <p><i className="mobile"></i>+91-{data.uploadedByMdn} </p>
                                                                        <p><i className="calander"></i>{data.creation_date}</p>
                                                                    </div>

                                                                    <div className="giftorderqty">
                                                                        <h5>Gift Cards Qty:</h5>
                                                                        <h4>{data.totalCardQuantity}</h4>

                                                                        <p>Beneficiaries: <strong>{data.totalBenificiary}</strong></p>
                                                                        <p>Amount: <strong>₹ {parseFloat(parseFloat(data.totalAmt) / 100).toFixed(2)}</strong></p>
                                                                        {/* <a href={() => false} target="blank" className="invoice">Invoice</a> */}
                                                                    </div>

                                                                    <div className="billingadrs">
                                                                        <h5>Billing Address:</h5>
                                                                        <h6>{data.uploadedByName}</h6>
                                                                        <p>{data.billingAddress}</p>
                                                                        <p><i className="mobile"></i>+91-{data.uploadedByMdn}</p>
                                                                    </div>

                                                                    <div className="order-track">
                                                                        <div className="row g-0">
                                                                            <div className="col-12">
                                                                                <h6>Order Status <span className={data.className}>{data.statusVariable}</span></h6>
                                                                            </div>

                                                                            <div className="col">
                                                                                <div className={data.orderedClass}>
                                                                                    <i className="dot"></i>
                                                                                    <i className="line"></i>
                                                                                    <p>Ordered</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col">
                                                                                <div className={data.paymentClass}>
                                                                                    <i className="dot"></i>
                                                                                    <i className="line"></i>
                                                                                    <p>Payment</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col">
                                                                                <div className={data.deliveredCLass}>
                                                                                    <i className="dot"></i>
                                                                                    <i className="line"></i>
                                                                                    <p>Processed</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>

                                )}
                                no={() =>
                                    <Redirect to="/access-denied" />
                                }
                            />


                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName, roName }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser, fullName, roName

});

export default connect(mapStateToProps)(MangeGiftCard);