import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import img from '../../assests/404.png'

const Error = ({authenticated}) => (

   
    authenticated?
    <div className="mid-container">
        <div className="container-fluid">
            <div className="pagenotfound">
                <img src={img} alt="404 Error" className="img-fluid" />
                <p>{process.env.REACT_APP_VAR_FOR_FOUR_NOT_FOUR}</p>
                <a href="/dashboard" className="mbtn btnbl">{process.env.REACT_APP_VAR_FOR_Back_to_Dashboard}</a>
            </div>
        </div>
    </div>
    :
    <Redirect to="/"></Redirect>

)




const mapStateToProps = ({ user: { roMdn }, token : {authenticated} }) => ({
    roMdn,authenticated
});


export default connect(mapStateToProps)(Error);