import BulkUploadType from "./bulk.types";

const INITIAL_STATE = {
    bulkDetailSingleSuccess: [],
    bulkDetailSuccess: [],
    bulkDetailInprogress: [],
    bulkDetailFail: [],
    statusSucess: 0,
    statusFail: 0,
    statusInprogress: 0,
    statusSingleSucess: 0
};

const bandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BulkUploadType.SET_BULK_DETAIL_SINGLE_SUCCESS:
            return {
                ...state,
                bulkDetailSingleSuccess: action.payload
            };
        case BulkUploadType.SET_BULK_DETAIL_SUCCESS:
            return {
                ...state,
                bulkDetailSuccess: action.payload
            };
        case BulkUploadType.SET_BULK_DETAIL_INPROGRESS:
            return {
                ...state,
                bulkDetailInprogress: action.payload
            };
        case BulkUploadType.SET_BULK_DETAIL_FAIL:
            return {
                ...state,
                bulkDetailFail: action.payload
            };

        case BulkUploadType.SET_BULK_STATUS_SUCCESS:
            return {
                ...state,
                statusSucess: action.payload
            }
        case BulkUploadType.SET_BULK_STATUS_FAIL:
            return {
                ...state,
                statusFail: action.payload
            }
        case BulkUploadType.SET_BULK_STATUS_INPROGESS:
            return {
                ...state,
                statusInprogress: action.payload
            }
        case BulkUploadType.SET_BULK_STATUS_SINGLE_SUCCESS:
            return {
                ...state,
                statusSingleSucess: action.payload
            }

        default:
            return state;
    }
};

export default bandReducer;