import React from 'react';
import Axios from "axios";
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import ApiCallingService from '../../service/api-call-service';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import Can from '../../auth/can/Can';
import history from '../../auth/history';
import apiCallService from '../../service/api-call-service';
import { sha256 } from 'js-sha256';
class UploadMoneyDisbursal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			files: [],
			hidden: 'hidden',
			image: '',
			url: '',
			uploadPercentage: '',
			size: '',
			loading: true,
			success: 'false',
			severty: 'success',
			dropDownHidden: false,
			message: '',
			inputKey: Date.now(),
			dropDownHidden: false,
			message: '',
			balanceEnquiry: 0.00,
			approvedByName: '',
			approvedByEmail: '',

		}
	}



	componentDidMount = () => {

		this.fetchBalanceDetail();

		this.getApprovedByName();



	}


	getApprovedByName = async () => {

		const url = process.env.REACT_APP_EMPLOYEE_BY_ROLENAME;
		var RequestToSendForFirst = {
			"roId": this.props.roMdn,
			"roleName": process.env.REACT_APP_EMPLOYEE_BY_ROLENAME_FOR_EMPLOYEE_NAME
		}

		var response = await apiCallService.postMethodCall(url, RequestToSendForFirst);

		console.log("RequestToSendForFirst response", response, " response.employeeDetails.length ", RequestToSendForFirst);

		if (response.status !== undefined && response.status === 0 && response.response !== undefined && response.response.employeeDetails !== undefined && response.response.employeeDetails.length > 0) {

			var first = '';
			if (response.response.employeeDetails[0].first_name !== undefined) {
				first = response.response.employeeDetails[0].first_name
			}
			var last = '';
			if (response.response.employeeDetails[0].last_name !== undefined) {
				last = response.response.employeeDetails[0].last_name
			}
			this.setState({ approvedByName: `${first} ${last}` })
			this.setState({ approvedByEmail: response.response.employeeDetails[0].email })
			console.log("RequestToSendForFirst response", this.state)
		} else {
			var RequestToSendForSecond = {
				"roId": this.props.roMdn,
				"roleName": process.env.REACT_APP_CORPORATE_ROLENAME_FOR_EMPLOYEE_NAME
			}

			var response = await apiCallService.postMethodCall(url, RequestToSendForSecond);

			console.log("RequestToSendForSecond response", response, " response.employeeDetails.length ", RequestToSendForSecond);

			if (response.status !== undefined && response.status === 0 && response.response !== undefined && response.response.employeeDetails !== undefined && response.response.employeeDetails.length > 0) {

				var first = '';
				if (response.response.employeeDetails[0].first_name !== undefined) {
					first = response.response.employeeDetails[0].first_name
				}
				var last = '';
				if (response.response.employeeDetails[0].last_name !== undefined) {
					last = response.response.employeeDetails[0].last_name
				}
				this.setState({ approvedByName: `${first} ${last}` })
				this.setState({ approvedByEmail: response.response.employeeDetails[0].email })
				console.log("RequestToSendForFirst response", this.state)
			}
		}




	}

	fetchBalanceDetail = async () => {



		const request = {
			
			
			 clientIndentifier:  this.props.roMdn
		};
		let headers = {
			"client_id": "OEMSAPP",
			"token": this.props.token,
			"Authorization": "BASIC abcd"
		}
		console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props.mdn, this.props.roMdn);
		var response = [];
		// async function run() {
		const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY_CORP
		console.log(url, request, "header")
		response = await apiCallService.postMethodCall(url, request, headers);
		if (response !== undefined && response.response_code !== undefined && response.response_code == 0 && response.response !== undefined) {
			
            this.setState({ balanceEnquiry: parseFloat(response?.response?.availableBalance).toFixed(2)})
		}
	}


	// fetchBalanceDetail = async () => {
	// 	//resp =await BalanceEnquiryService.balanceEnquiry('http://172.160.0.249:7072/api/wallet/BalanceEnquiry', '919887835909', "")

	// 	const request = {
	// 		device_info: {
	// 			api_version: "1.0",
	// 			app_version: "9.2",
	// 			device_id: "d215ab007e05c498",
	// 			device_os: "Android",
	// 			device_os_version: "5.1.1",
	// 			imei: "357869081907005",
	// 			ip_address: "172.160.2.140",
	// 			model_name: "SM-J320F",
	// 			source: "mobile",
	// 		},
	// 		request: {
	// 			user: {
	// 				username: this.props.roMdn
	// 			},
	// 			transaction_data: {
	// 				request_id: '988df6a3-8bd6-495e-8373-05bd4671ccfb',
	// 				limit_required: "true"
	// 			}
	// 		}
	// 	};
	// 	let headers = {
	// 		"client_id": "OEMSAPP",
	// 		"token": this.props.token,
	// 		"Authorization": "BASIC abcd"
	// 	}

	// 	console.log("inside balanceEnquiry request :-", request);
	// 	var response = [];
	// 	// async function run() {
	// 	const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
	// 	console.log(url, request, headers)
	// 	response = await ApiCallingService.postMethodCall(url, request, headers);
	// 	const detail = response.response;
	// 	if (response.response_code === 0 && detail !== undefined && detail !== null) {
	// 		console.log('response balance', response)
	// 		this.setState({ balanceEnquiry: detail.Balance.total_balance });
	// 		console.log(this.state.balanceEnquiry)
	// 	}

	// 	// }
	// 	// run();


	// }

	generateHash(messageBody) {
		//var msg= JSON.stringify(messageBody) ;
		var privateKey = process.env.REACT_APP_CLIENT_ID;
		var hash = sha256.hmac.create(privateKey);
		hash.update(messageBody);
		var hexData = hash.hex();
		console.log('hexed data', hexData);
		var base64String = btoa(hexData);
		console.log(messageBody, '---->hash Generated --->', base64String);
		return base64String;
	}


	onChange = async (event) => {
		this.setState({ files: [] });
		console.log('file event', event.target);
		console.log('file event', event.target.files[0].size);
		this.setState({ size: event.target.files[0].size })


		if (event.target.files[0].size > parseInt(process.env.REACT_APP_FILE_SIZE_IN_KB) * 1000) {
			return (
				this.setState({ hidden: '' }),
				this.setState({ dropDownHidden: true }),
				this.setState({ severty: 'error' }),
				this.setState({ message: 'File Size extended' }),
				this.setState({ uploadPercentage: 100 }),
				this.setState({ loading: false }),
				this.setState({ success: false })
			)
		}


		this.setState({ uploadPercentage: 1 })
		this.setState({ hidden: '' });
		this.setState({ files: event.target.files[0] });
		if (event.target.files && event.target.files[0]) {
			this.setState({
				image: URL.createObjectURL(event.target.files[0])
			});
		}
		console.log('state file', this.state.files)
		const file = event.target.files[0];
		const url = process.env.REACT_APP_BULK_UPLOAD
		const formData = new FormData();
		formData.append('file', file)


		const options2 = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total)
				console.log(`${loaded}kb of ${total}kb | ${percent}%`);
				this.setState({ total: total / 100 });
				this.setState({ loaded: loaded })

				if (percent < 100) {
					this.setState({ uploadPercentage: percent })
				}
			}
		}
		var hashCodeForHeader = await this.generateHash(JSON.stringify(formData));

		var urlFromService = ''
		const headers = {
			"device_info": "",
			"client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
			"hashCode": hashCodeForHeader,
			"loggedInUser": this.props.currentUser
		}

		console.log('url', url)
		await Axios.post(url, formData, { headers }).then(response => {

			// console.log('Is this the place of exception', url, formData, response);
			this.setState({ url: response.data.fileDownloadUri });
			urlFromService = response.data.fileDownloadUri;

		}).catch(error => {
			console.log('Is this the place of exception', url, formData, error);
		})

		var extrxctUrl = urlFromService.split('/')
		console.log('extrxctUrl', extrxctUrl);

		const RequestToSend = {
			"fileUrl": `${process.env.REACT_APP_API_FILES_URL}/${extrxctUrl[5]}`,
			"roId": this.props.roMdn,
			"transactionPassword": "12345",
			"uploadedByEmail": this.props.currentUser,
			"uploadedByName": this.props.fullName,
			"approvedByEmail": this.state.approvedByEmail,
			"approvedByName": this.state.approvedByName
		}
		console.log('RequestToSend', RequestToSend)
		//var urlOfBatchProcess = 'http://172.160.0.249:7072/employee/bulkprocess';
		var urlOfBatchProcess = process.env.REACT_APP_BULK_DISBERSAL_UPLOAD_DISBURSE;
		var response = await ApiCallingService.postMethodCall(urlOfBatchProcess, RequestToSend, { headers: "" }, options2);
		console.log('urlOfBatchProcess response', response)

		if (response.response_code === 0) {
			this.setState({ loading: false })
			this.setState({ success: true })
			this.setState({ dropDownHidden: true })
			this.setState({ severty: 'success' })
			this.setState({ message: `File Uploaded Successfully.` })
			this.setState({ uploadPercentage: 100 })
			if (this.props.from !== undefined && this.props.from === "band") {
				history.push('/money-disbursal')
				history.go();
			} else {
				this.props.methodToSetList();
			}

		} else {
			this.setState({ loading: false })
			this.setState({ success: false })
			this.setState({ dropDownHidden: true })
			this.setState({ severty: 'error' })
			this.setState({ message: response.response_description })

		}

	}


	render() {
		var heightAdjust = window.innerHeight - 150
		return (


			<div className="col-12 col-lg-4 col-xl-3">
				<div className="add-emp" style={{ "minHeight": heightAdjust }}>

					<Can
						role={this.props.userRole}
						perform="moneyDisburse-upload-balance:visit"
						roleData={this.props.roleData}
						yes={() => (

							<div className="dis-balance">

								<Can
									role={this.props.userRole}
									perform="moneyDisburse-upload-loadMoney:visit"
									roleData={this.props.roleData}
									yes={() => (

										<a href="/load-money" className="sbtn btnbl">{process.env.REACT_APP_Var_FOR_MONEY_DISBURSAL_Load_money}</a>

									)}
									no={() =>
										null
									}
								/>
								<i className="bal"></i>
								<h5>{parseFloat(this.state.balanceEnquiry).toFixed(2)}</h5>
								<p>{process.env.REACT_APP_Var_FOR_MONEY_DISBURSAL_Account_Balance}







								</p>
							</div>
						)}
						no={() =>
							null
						}
					/>




					<Snackbar

						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={this.state.dropDownHidden}
						autoHideDuration={7000}
						onClose={e => this.setState({ dropDownHidden: false })}
					>
						<Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
							{this.state.message}
						</Alert>
					</Snackbar>




					<Can
						role={this.props.userRole}
						perform="moneyDisburse-uploadDisburdse:visit"
						roleData={this.props.roleData}
						yes={() => (
							<>
								<div className="shead">{process.env.REACT_APP_Var_FOR_MONEY_DISBURSAL_Bulk_Money_Disbursal} <a href={process.env.REACT_APP_URL_FOR_MONEY_DISBERSAL_SAMPLE_FILE}><i className="sample"></i> {process.env.REACT_APP_Var_FOR_MONEY_DISBURSAL_Sample_file}</a></div>

								<div className="file-upload">

									<div className="dropzone-wrapper">
										<div className="dropzone-desc">
											<i className="upload"></i>
											<h6>{process.env.REACT_APP_Var_FOR_ADD_Money_Disbursal_DRAG_AND_DROP}<span> {process.env.REACT_APP_Var_FOR_Money_Disbursal_BROWSE}</span> {process.env.REACT_APP_Var_FOR_Money_Disbursal_Files_here}</h6>
											<p>{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_SampleFile_Format_Description}</p>
										</div>

										<input type="file" name="img_logo" className="dropzone" key={this.state.inputKey} onChange={this.onChange} />
									</div>



									{
										parseInt(this.state.uploadPercentage) === 100 ? null :
											<div className={`preview-zone ${this.state.hidden}`}>
												<div className="box">

													{this.state.uploadPercentage > 0 && <LinearProgressWithLabel value={this.state.uploadPercentage} variant="determinate" />}

													{
														this.state.hidden === ''
															?
															<div className="box-body">
																<i className="sample" style={{ width: "98%" }}>
																	<p style={{ "margin-left": "1.5em" }}>File size {parseFloat(this.state.size / 1000).toFixed(2)}KB   </p>
																</i>
															</div>
															:
															null
													}

													{(this.state.loading)
														?
														<div className="spinner-border text-muted"></div>
														:

														(this.state.success)
															?
															<button type="button" className="success-preview" onClick={e => this.setState({ hidden: 'hidden', files: [], loading: true, inputKey: Date.now() })}>Success</button>
															:
															<button type="button" className="remove-preview" onClick={e => this.setState({ hidden: 'hidden', files: [], loading: true, inputKey: Date.now() })}>Remove</button>

													}
												</div>
											</div>

									}
								</div>
							</>
						)}
						no={() =>
							null
						}
					/>





					<Can
						role={this.props.userRole}
						perform="moneyDisburse-setbandlimit:visit"
						roleData={this.props.roleData}
						yes={() => (

							<div className="link-btn">
								<a href="/band-list" className="bbtn btngn">{process.env.REACT_APP_Var_FOR_MONEY_DISBURSAL_Set_band_wise_Disbursal_Limits}</a>
							</div>
						)}
						no={() =>
							null
						}
					/>



				</div>
			</div>



		);
	}
}

const mapStateToProps = ({ user: { currentUser }, user: { roMdn }, token: { token }, user: { userRole, fullName, mdn }, role: { roleData } }) => ({
	userRole,
	roleData, mdn,
	currentUser,
	roMdn, fullName, token
});

export default connect(mapStateToProps)(UploadMoneyDisbursal);