import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setShowCardDetailSection, showAvailableCardSection } from '../../../redux/div-show-and-hide/div-show-and-hide.action';
import { store } from '../../../redux/store';


const CardOderDetail = ({ authenticated, showAddNewOderSection, roleData, userRole, token, roMdn, orderDate,
    cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox, renderTransactionHistoryAgain,
    totalAmt, ratePerCard, gstPercent, gstAmt, shippingAddress, billingAddress, shippingPersonName, shippingPersonMdn, orderId, totalAmtWithGst, cardType
}) => {


    const showAvailbleDiv = (e) => {
        store.dispatch(showAvailableCardSection());

        store.dispatch(setShowCardDetailSection(true))
    }

    var amount = parseFloat(parseFloat(ratePerCard) * parseFloat(cardQuantity)).toFixed

    return (< >
        {(authenticated)
            ? (
                <div className="cardorderform" style={{ minHeight: "520px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="head-right">
                                <a
                                    onClick={showAvailbleDiv}
                                    data-for="back" data-tip="Back" data-iscapture="true" className="back" style={{ marginRight: "10px", marginTop: "10px" }}
                                ><i></i></a>
                            </div>
                            <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Details}</h3>
                        </div>

                        <div className="col-12">
                            <div className="orderstatus">
                                <h2>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Thank_you_your_order_has_been_placed_successfully}</h2>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Ordered ID:</p>
                                <h5>#{orderId}</h5>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Ordered By:</p>
                                <h5>{fullNameOder}</h5>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Mobile Number:</p>
                                <h5>{mobileNum}</h5>
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Card Quantity:</p>
                                <h5>{cardQuantity}</h5>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Rate Per Card:</p>
                                <h5>₹{parseFloat(ratePerCard).toFixed(2)}</h5>
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Amount:</p>
                                <h5>₹{totalAmt}</h5>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>GST {gstPercent}%:</p>
                                <h5>₹{parseFloat(gstAmt).toFixed(2)}</h5>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 col-xl-3">
                            <div className="orderreview">
                                <p>Total Amount:</p>
                                <h5>₹{totalAmtWithGst}</h5>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="orderreview">
                                <p className="date">Ordered Date: {orderDate}</p>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="reviewaddress">
                                {cardType === 0
                                    ?
                                    null
                                    :
                                    <div className="shipaddress">
                                        <h5>Shipping Address:</h5>
                                        <h6>{shippingPersonName}</h6>
                                        <p>{shippingAddress}</p>
                                        <p><i className="mobile"></i>+91-{shippingPersonMdn}</p>
                                    </div>
                                }

                                <div className="billaddress">
                                    <h5>Billing Address:</h5>
                                    <h6>{fullNameOder}</h6>
                                    <p>{billingAddress}</p>
                                    <p><i className="mobile"></i>+91-{mobileNum}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* <div className="txn-btn">
                                <button type="button" className="print" data-toggle="tooltip" title="" data-original-title="Print">Print</button>
                                <button type="button" className="share" data-toggle="tooltip" title="" data-original-title="Share">Share</button>
                                <button type="button" className="help" data-toggle="tooltip" title="" onclick="window.location.href='help.html'" data-original-title="Help">Support</button>
                            </div> */}

                        </div>


                    </div>

                </div>

            )
            : (
                <Redirect to='/' />
            )


        }

    </>

    )

}





const mapStateToProps = ({ token: { authenticated, token }, user: { userRole, roMdn }, role: { roleData },
    order: { cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox, orderId,
        totalAmt, ratePerCard, gstPercent, gstAmt, billingAddress, shippingAddress, shippingPersonName, shippingPersonMdn,
        orderDate, totalAmtWithGst, cardType
    }
}) => ({
    authenticated, userRole, roleData, token, roMdn, shippingAddress, billingAddress,
    cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox,
    totalAmt, ratePerCard, gstPercent, gstAmt, shippingPersonName, shippingPersonMdn, orderId, orderDate, totalAmtWithGst, cardType
});

export default connect(mapStateToProps)(CardOderDetail);