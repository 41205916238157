
import { TokenActionTypes } from './token.types';

export const setToken = token => ({
  type: TokenActionTypes.SET_TOKEN,
  payload: token
});

export const isAuthenticated = (auth) => ({
  type: TokenActionTypes.IS_AUTHENTICATED,
  payload: auth
})

export const setUnseenCount = (auth) => ({
  type: TokenActionTypes.SET_USEEN_COUNT,
  payload: auth
})

export const setIsPasswordReset = (auth) => ({
  type: TokenActionTypes.SET_IS_PASSWORD_RESET,
  payload: auth
})

export const resetToken = {
  type: TokenActionTypes.RESET_TOKEN
}
