import React ,{useEffect} from "react";
import { Redirect } from "react-router-dom";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../redux/token/token.action'



const Callback = ( props, token ) => {
   
    
	useEffect(() => {

    document.title="ShakePe "
		
			const handleAuthentication=()=>{
      
       
        console.log('inside Authentication',token.value)
       
      store.dispatch(isAuthenticated());
     
      console.log(store);
        
      }
      handleAuthentication();
	  }, []);
	
				return (

        //  <div><Header/>
        //    hello</div>
         <Redirect to="/" />
        );    
		
	  
	  };
	  
const mapStateToProps = ({ token: { token }}) => ({
	token
  });


export default connect(mapStateToProps)(Callback);


/*const value=queryString.parse(props.location.search);
console.log('inside callback : ',value);
authResult.username=value.user;
authResult.token=queryString.parse(props.location.hash);
console.log('inside callback search authResult : ',authResult);*/