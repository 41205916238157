
import { DivShowAndHideTypes } from './div-show-and-hide.types';

export const showAddNewOderSection = () => ({
    type: DivShowAndHideTypes.SHOW_CREATE_NEW_ODER_SECTION,
})

export const showReviewOderSection = () => ({
    type: DivShowAndHideTypes.SHOW_REVIEW_ODER_SECTION,
})
export const showDetailOderSection = () => ({
    type: DivShowAndHideTypes.SHOW_DETAIL_ODER_SECTION,
})

export const showAvailableCardSection = () => ({
    type: DivShowAndHideTypes.SHOW_AVAILABLE_CARD_SECTION
})


export const renderTransactionHistoryAgainAction = (data) => ({
    type: DivShowAndHideTypes.SHOW_RENDER_TRANSACTION_HISTORY,
    payload: data
})

export const showKycDetailSuccessSection = (data) => ({
    type: DivShowAndHideTypes.SHOW_KYC_DETAIL_SUCESS_DIV,
    payload: data
})

export const setFromDashbard = (data) => ({
    type: DivShowAndHideTypes.SET_FROM_DASHBOARD,
    payload: data
})


export const setShowCardDetailSection = (data) => ({
    type: DivShowAndHideTypes.SET_SHOW_CARD_DETAIL_SECTION,
    payload: data
})
