import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { renderTransactionHistoryAgainAction, showAddNewOderSection, showAvailableCardSection, setFromDashbard, setShowCardDetailSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import Can from '../../auth/can/Can';
import '../css/manage-casrd.styles.css'
import CreateCardOder from '../component/oder/new-card-oder.component';
import ReviewCardOder from '../component/oder/new-card-oder.review.component';
import DetailCardOder from '../component/oder/new-card-oder.component.detail';
import TransactionHistory from '../component/oder-history/oder-history.component';
import AvailableCards from '../component/available-card/available-card.component';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import { resetOder } from '../../redux/order/order.action';

import LoadingComponent from './manage-card-loading.component';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';


const ManageCard = ({ authenticated, isPasswordReset, roMdn, roleData, userRole, showAvailableCardSectionFun, showAddNewOderSectionFun, fromDashboard,
    renderTransactionHistory, showAvailableCardSection, showCreateNewOderSection, showReviewOderSection, showDetailOderSection, showCardDetailSection }) => {


    const [loading, setLoading] = useState(true);
    const [cadrDetail, setCardDetail] = useState([]);
    // const [sizeHistory, setSizeHistory] = useState(0);




    useEffect(() => {
        document.title = "ShakePe Manage Cards"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
        store.dispatch(showAvailableCardSectionFun());
        store.dispatch(renderTransactionHistoryAgainAction(true));
        store.dispatch(resetOder)
        store.dispatch(setShowCardDetailSection(true))

        const url = `${process.env.REACT_APP_GET_CARD_EMPLOYEE_COUNT}?roId=${roMdn}`;

        const getResult = async () => {
            var response = await apiCallService.getMethodCallWithoutParam(url);
            console.log('ManageCard response ', response)
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
                setCardDetail(response);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
        getResult();

        if (fromDashboard) {
            store.dispatch(showAddNewOderSection());
            store.dispatch(setFromDashbard(false));
        }




    }, []);


    const handleRoute = (e) => {
        history.push(e.target.id);
        history.go();
    }



    return (<div >
        {(authenticated)
            ? (
                isPasswordReset
                    ?
                    <Can
                        role={userRole}
                        perform="subheader-manageCard:visit"
                        roleData={roleData}
                        yes={() => (
                            <div className="mid-container" id="heightFix">
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className="col-12 col-md-6 col-lg-8">

                                            {
                                                loading
                                                    ?
                                                    <LoadingComponent />
                                                    :

                                                    showCardDetailSection
                                                        ?

                                                        <div className="row">

                                                            <div className="col-12 col-lg-5">
                                                                <div className="total-card">

                                                                    <div className="card-avail">
                                                                        <Can
                                                                            role={userRole}
                                                                            perform="manageCard-orderNow:visit"
                                                                            roleData={roleData}
                                                                            yes={() => (

                                                                                <button onClick={showAddNewOderSectionFun} className="sbtn btnbl" style={{ float: "right" }}>{process.env.REACT_APP_MANAGE_CARD_VAR_Order_button}</button>


                                                                            )}
                                                                            no={() =>
                                                                                null
                                                                            }
                                                                        />

                                                                        <i className="ttl"></i>
                                                                        <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Total_Cards}</p>
                                                                        <h5>{cadrDetail.totalCard}</h5>
                                                                    </div>

                                                                    <div className="cardsavail">
                                                                        <i className="phy"></i>
                                                                        <p>Physical Cards</p>
                                                                        <h5>{cadrDetail.physicalCard}</h5>
                                                                    </div>

                                                                    <div className="cardsavail plus">
                                                                        <i className="vir"></i>
                                                                        <p>Virtual Cards</p>
                                                                        <h5>{cadrDetail.virtualCard}</h5>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-lg-7">

                                                                <div className="total-card">

                                                                    <div className="card-avail">

                                                                        <i className="avl"></i>
                                                                        <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Available_Cards}</p>
                                                                        <h5>{cadrDetail.availableCard}</h5>
                                                                    </div>


                                                                    <div className="cardswith pe-sm-3">

                                                                        <Can
                                                                            role={userRole}
                                                                            perform="manageCard-assignedCardView:visit"
                                                                            roleData={roleData}
                                                                            yes={() => (
                                                                                cadrDetail.assignedCard === undefined || cadrDetail.assignedCard === '' || cadrDetail.assignedCard === 0
                                                                                    ?
                                                                                    null :
                                                                                    <button id="/assigned-cards" className="sbtn btnbl" style={{ float: "right" }} onClick={handleRoute} disabled={cadrDetail.assignedCard === undefined || cadrDetail.assignedCard === '' || cadrDetail.assignedCard === 0}>{process.env.REACT_APP_MANAGE_CARD_VAR_View_ASSIGNED_CARD_Button}</button>


                                                                            )}
                                                                            no={() =>
                                                                                null
                                                                            }
                                                                        />
                                                                        <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Assigned_Cards}</p>
                                                                        <h5>{cadrDetail.assignedCard}</h5>
                                                                    </div>


                                                                    <div className="cardswith card-last ps-sm-3">


                                                                        <Can
                                                                            role={userRole}
                                                                            perform="manageCard-blockedCardView:visit"
                                                                            roleData={roleData}
                                                                            yes={() => (

                                                                                cadrDetail.blockedCard === undefined || cadrDetail.blockedCard === '' || cadrDetail.blockedCard === 0
                                                                                    ?
                                                                                    null
                                                                                    :
                                                                                    <button id="/blocked-cards" className="sbtn btnrd" onClick={handleRoute} style={{ float: "right" }}
                                                                                        disabled={cadrDetail.blockedCard === undefined || cadrDetail.blockedCard === '' || cadrDetail.blockedCard === 0}

                                                                                    >{process.env.REACT_APP_MANAGE_CARD_VAR_View_Blocked_Cards_Button}</button>


                                                                            )}
                                                                            no={() =>
                                                                                null
                                                                            }
                                                                        />
                                                                        <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Blocked_Cards}</p>
                                                                        <h5>{cadrDetail.blockedCard}</h5>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null

                                            }

                                            {(showCreateNewOderSection === true) &&
                                                <CreateCardOder />
                                            }


                                            {(showReviewOderSection === true) &&
                                                <ReviewCardOder />
                                            }


                                            {(showDetailOderSection === true) &&
                                                <DetailCardOder />
                                            }


                                            <Can
                                                role={userRole}
                                                perform="manageCard-availCardView:visit"
                                                roleData={roleData}
                                                yes={() => (
                                                    (showAvailableCardSection === true) &&
                                                    <AvailableCards />


                                                )}
                                                no={() =>
                                                    null
                                                }
                                            />

                                        </div>
                                        {renderTransactionHistory ?
                                            <TransactionHistory />
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        no={() =>
                            <Redirect to="/ta-report" />

                        }
                    />
                    :
                    <ResetLoginPassword />

            )
            : (
                <Redirect to='/' />
            )


        }

    </div>

    )

}


const mapDispatchToProps = dispatch => ({
    showAvailableCardSectionFun: () => dispatch(showAvailableCardSection()),
    showAddNewOderSectionFun: () => dispatch(showAddNewOderSection())
});

const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn }, role: { roleData }, showAndHide: { showCreateNewOderSection, showReviewOderSection, showDetailOderSection, fromDashboard,
    showAvailableCardSection, renderTransactionHistory, showCardDetailSection } }) => ({
        authenticated, userRole, roleData, showAvailableCardSection, roMdn, fromDashboard, isPasswordReset,
        showCreateNewOderSection, showReviewOderSection, showDetailOderSection, renderTransactionHistory, showCardDetailSection
    });

export default connect(mapStateToProps, mapDispatchToProps)(ManageCard);