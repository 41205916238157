import React, { useState, useEffect } from "react";
import '../styles/corporate.css'
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import moment from 'moment';
import GenerateRequestId from "../../common-function/GenerateRequestId";
import apiCallService from "../../service/api-call-service";
import { store } from "../../redux/store";
import { setActiveHeader } from "../../redux/activeHeader/activeheader.action";
import Pagination from "react-js-pagination";
import Avatar from 'react-avatar';
import CommonFunction from '../../common-function/GenerateRequestId'
import CorpLoader from '../component/corporate.loader';
import Can from '../../auth/can/Can';
import ResetLoginPassword from "../../corporate-profile/component/reset-login-password";
const collectedTrueKeys = {
    is_active: []
}

var ToDateVar = '';
var FromDateVar = '';

const CorporateList = ({ authenticated, userRole, roleData, token, roMdn, isPasswordReset }) => {


    const [loading, setLoading] = useState(true);


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(process.env.REACT_APP_VAR_FOR_CORP_LIST_NUMER_OF_POST_PER_PAGE);

    const [multiValueRecords, setMultiRecords] = useState([
        { value: 1, label: "Active" },
        { value: 0, label: "In-Active" }
    ])
    const [placeHolder, setplaceHolder] = useState('...')
    const [multiValueRecord, setMultiValueRecord] = useState('');

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const [searchVariable, setSearchVariable] = useState('');
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [allValuesForFilter, setAllValuesForFilter] = useState([]);

    const [corpDetail, setCorpDetail] = useState([]);





    useEffect(() => {

        document.title = "ShakePe Corporate List";

        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            corpListClass: "active"

        }

        store.dispatch(setActiveHeader(payload))

        getCorpDetail();


    }, []);


    const getCorpDetail = async () => {




        var url = `${process.env.REACT_APP_GET_CORP_LIST_BY_RO_CREATED}?createdByRoId=${roMdn}`;
        // var url = process.env.REACT_APP_GET_ALL_CORPORATE_LIST;
        // var request = {

        // }

        // var result = await apiCallService.postMethodCall(url, request);
        var result = await apiCallService.getMethodCallWithoutParam(url);


        console.log('result', result);


        if (result !== undefined && result.response_code !== undefined && result.response_code === 0 && result.response !== undefined) {
            setCorpDetail(result.response);
            setFilteredRecord(result.response)
            setLoading(false)
        } else {
            setLoading(false)
        }


    }



    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)

    };


    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            setToDate(value)
            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value)
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");



            const url = process.env.REACT_APP_GET_ALL_CORPORATE_LIST



            var request = {
                "fromDate": `${formatFromDate}`,
                "toDate": `${formatToDate}`
            }

            var response = await apiCallService.postMethodCall(url, request);

            console.log("TA REPORT RESPONSE", request, response);
            if (response !== undefined && response.response_code === 0 && response.response !== undefined) {
                console.log("TA REPORT RESPONSE");
                // setCorpDetail(response.response);
                setFilteredRecord(response.response)
                setCurrentPage(1)
            } else {
                setFilteredRecord([])
            }


        }
    }



    const handleMultiRecordchange = (option) => {
        console.log('option log', option)

        setMultiValueRecord(option)
        setCurrentPage(1)

        collectedTrueKeys.is_active = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.is_active.push(op.value)

            })
        }

        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log('else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            setAllValuesForFilter(data)
        }



    }


    const clearAll = () => {
        console.log('clear all triggered')
        setFilteredRecord(corpDetail)
        setMultiValueRecord([]);

        ToDateVar = '';
        FromDateVar = '';

        setAllValuesForFilter([])
        collectedTrueKeys.is_active = []
        setToDate('')
        setFromDate('');

        console.log('clear all triggered  toDate', toDate)
        console.log('clear all triggered fromDate', fromDate)
    }


    const handleSearchChange = (event) => {
        setSearchVariable(event.target.value);
        setCurrentPage(1);
    }






    const customStyles = {
        control: (base, state) => ({
            ...base,
            'border': '1px solid #ddd',
            'borderRadius': '0',
            'boxSizing': 'content-box',
            'minHeight': '30px',
            'lineHeight': '18px',
            'display': 'flex'
        }),

        valueContainer: (base) => ({
            ...base,
            'position': 'relative',
            'overflow': 'hidden',
            'margin': '0',
            'padding': '5px',
            'width': '100%',
            'minHeight': '30px',
            'border': '1px solid #ddd',
            'backgroundColor': '#fff',
            'lineHeight': '18px',
            'cursor': 'text',
            'alignItems': 'center'
        }),
        multiValue: base => ({
            ...base,
            'boxSizing': 'content-box',
            'position': 'relative',
            'margin': '1px 5px 1px 0px',
            'padding': '1px 2px 3px 5px',
            'border': '1px solid #c4d5e1',
            'lineHeight': '18px',
            'maxWidth': '100%',
            'borderRadius': '3px',
            'backgroundColor': '#d6eeff',
            'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
            'color': '#4a546c',
            'lineHeight': '16px',
            'cursor': 'default',
            'height': '20px'
        }),
        menu: (base) => ({
            ...base,
            'padding': '2px 5px',
            'lineHeight': '16px',

        }),
        input: base => ({
            ...base,
            'height': '18px',
            'lineHeight': '18px'
        })

    };

    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };

    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;



    var filteredPost = [];

    if ((filteredRecord === null || !(filteredRecord !== undefined && filteredRecord.length && filteredRecord))) {
        filteredPost = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', filteredRecord)
    } else if (filteredRecord.length !== 0) {

        filteredPost = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)

    }





    // const key = 'roId';

    const filteredPostData = filteredPost


    var searchedRecord = [];


    if (searchVariable !== '' && corpDetail !== undefined) {
        corpDetail.map(post => {
            if (post.name !== undefined && post.name !== null && post.name.toLowerCase().includes(searchVariable.toLowerCase())) {
                searchedRecord.push(post)
            } else if (post.roId !== undefined && post.roId !== null && post.roId.includes(searchVariable)) {
                searchedRecord.push(post)
            } else if (post.contact_person !== undefined && post.contact_person !== null && post.contact_person.includes(searchVariable)) {
                searchedRecord.push(post)
            }
        })
    }



    var size = 0



    if (searchVariable !== '') {
        console.log('inside searchVariable ')
        currentPost = searchedRecord.slice(indexOfFirstPost, indexOfLastPost)
        size = searchedRecord.length;
    } else {
        if (filteredPostData === null || filteredPostData === undefined) {
            console.log('inside filteredPostData ')
            currentPost = null
        } else {
            console.log('inside filteredPostData else')
            currentPost = filteredPostData.slice(indexOfFirstPost, indexOfLastPost)
            size = filteredPostData.length;
        }
    }


    var startdateToDate = moment();
    startdateToDate = startdateToDate.format("YYYY-MM-DD");

    if (toDate === '') {
        var startdateFromDate = moment();
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    } else {
        var startdateFromDate = moment(toDate);
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    }



    var dateMax = moment(fromDate);
    dateMax = dateMax.format("YYYY-MM-DD");
    var dateFromMax = moment(toDate);
    dateFromMax = dateFromMax.format("YYYY-MM-DD");


    var height = window.innerHeight - 250



    return (

        <>
            {(authenticated)
                ? (



                    <Can
                        role={userRole}
                        perform="subheader-corpManage:visit"
                        roleData={roleData}
                        yes={() => (
                            <>
                                {isPasswordReset ?

                                    <div className="mid-container">
                                        <div className="container-fluid">

                                            <div className="head">
                                                <div className="row">
                                                    <div className="col-6 col-md-4 order-md-1">
                                                        <h3>{process.env.REACT_APP_Var_FOR_CORP_LIST_Corporate_List}</h3>
                                                    </div>
                                                    <div className="col-6 col-md-4 order-md-3">
                                                        <div className="flterbox">

                                                            <div className="dropdown " data-toggle="tooltip" title="Filter">
                                                                <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                    <div className="filters">
                                                                        <label for="select-state">Type:</label>

                                                                        <Select

                                                                            name="multiTypeValue"
                                                                            placeholder={placeHolder}
                                                                            value={multiValueRecord}
                                                                            options={multiValueRecords}
                                                                            onChange={handleMultiRecordchange}
                                                                            isMulti={true}
                                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                            styles={customStyles}
                                                                        />
                                                                    </div>

                                                                    <div className="filters">
                                                                        <label htmlFor="select-state">From Date:</label>
                                                                        <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={fromDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                                    </div>

                                                                    <div className="filters">
                                                                        <label htmlFor="select-state">To Date:</label>
                                                                        <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={toDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                                    </div>

                                                                    <div className="fbtn">
                                                                        <button className="sbtn btnrd" type="reset" id="btnClear" onClick={clearAll}>Clear all</button>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </div>
                                                        <div className="actnbtn">
                                                            <a href="/add-new-corp" className="admin">
                                                                {process.env.REACT_APP_Var_FOR_CORP_LIST_Add_New_Corporate_BUTTON}
                                                                <i className="add"></i>
                                                                </a>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4 order-md-2">
                                                        <div className="searchdata">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="empsearch" name="search" placeholder="Search Corporate..." onChange={handleSearchChange} />
                                                                <button className="btn srchbtn" type="button"></button>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>

                                            </div>

                                            <div className="emp-list">
                                                <div className="row">

                                                    <div className="col-12 ">
                                                        <div style={{ minHeight: height }}>
                                                            <div className="row">
                                                                {

                                                                    loading ?
                                                                        <CorpLoader /> :
                                                                        currentPost.length === 0
                                                                            ?
                                                                            <div className="col-12">
                                                                                <div className="no-record">
                                                                                    <i></i>
                                                                                    <h3> No Record Available.</h3>
                                                                                </div>
                                                                            </div>
                                                                            :


                                                                            <>


                                                                                {currentPost.map(corp => (
                                                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                                                                                        <div className="corp-card">
                                                                                            <Link
                                                                                                to={{
                                                                                                    pathname: '/corp-profile',
                                                                                                    state: { roId: corp.roId }
                                                                                                }}
                                                                                            >

                                                                                                <div className="corp-basic">
                                                                                                    <Avatar src={corp.logoUrl} color={Avatar.getRandomColor(corp.roId, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} name={corp.name} size="48px" round={true} />


                                                                                                    <h5>{CommonFunction.Capitalize(corp.name)}</h5>
                                                                                                    <p>{process.env.REACT_APP_VARIABLE_FOR_CORPORATE_LIST_CARD_ROID} {corp.roId}</p>
                                                                                                </div>
                                                                                                {corp.is_active === 1
                                                                                                    ?
                                                                                                    <div className="corp-status"><i className="active"></i> Active Corporate</div>
                                                                                                    :
                                                                                                    <div className="corp-status"><i className="inactive"></i> Inactive Corporate</div>
                                                                                                }
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}

                                                                            </>


                                                                }
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="w-100 mt-3"></div>

                                                    {parseInt(size) > parseInt(postsPerPage)
                                                        ?
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <p>Showing {valueToShow} to {(indexOfLastPost > size) ? size : indexOfLastPost} of {size} entries</p>
                                                            </div>

                                                            <div className="col-12 col-sm-6">
                                                                <div className="pager">
                                                                    <Pagination
                                                                        activePage={parseInt(currentPage)}
                                                                        itemsCountPerPage={parseInt(postsPerPage)}
                                                                        totalItemsCount={size}
                                                                        pageRangeDisplayed={parseInt(postsPerPage)}
                                                                        onChange={paginate}
                                                                        className="pagination"
                                                                        linkClass="page-link"
                                                                        itemClass="page-item"
                                                                        nextPageText="Next"
                                                                        prevPageText="Prev"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :

                                                        null

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ResetLoginPassword />
                                }
                            </>
                        )}
                        no={() =>
                            <Redirect to="/emp-list" />
                            // <div>
                            //     <div ><img src={DashBoardImage} className='dashboardImg'/></div>

                            //   </div>
                        }
                    />



                )
                : (
                    <Redirect to='/' />
                )
            }

        </>

    )
}





const mapStateToProps = ({ user: { userRole }, user: { roMdn }, role: { roleData }, token: { authenticated, token, isPasswordReset } }) => ({
    userRole,
    roMdn,
    roleData,
    authenticated, token, isPasswordReset
});


export default connect(mapStateToProps)(CorporateList);