import BandType from './band.type';

export const setBand = bandData =>({
    type: BandType.SET_BAND,
    payload: bandData
})

export const setBandListToView = bandData =>({
    type: BandType.SET_BAND_LIST_TO_VIEW,
    payload: bandData
})