import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import CategoryLimitType from '../../constants/category-limit.type'
const BalanceInformation = ({ wallet_id, amount, loading, wallet }) => (
    console.log(wallet_id, " ", amount, "BalanceInformation div ", loading, wallet),

    (() => {
        switch (wallet_id) {
            case CategoryLimitType.GENERAL:
                return (
                    <div className="col">
                        <div className="type-balance">
                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_General}</label>
                            {loading
                                ?
                                <Skeleton animation="wave" variant="square" />
                                :
                                <h5 className="general">{parseFloat(amount / 100).toFixed(2)}</h5>
                            }
                        </div>
                    </div>

                );
            case CategoryLimitType.FOOD:
                return (
                    <div className="col">
                        <div className="type-balance">
                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Food}</label>
                            {loading
                                ?
                                <Skeleton animation="wave" variant="square" />
                                :
                                <h5 className="food">{parseFloat(amount / 100).toFixed(2)}</h5>
                            }
                        </div>
                    </div>
                );

            case CategoryLimitType.TRAVEL:
                return (
                    <div className="col">
                        <div className="type-balance">
                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Travel}</label>
                            {loading
                                ?
                                <Skeleton animation="wave" variant="square" />
                                :
                                <h5 className="travel">{parseFloat(amount / 100).toFixed(2)}</h5>
                            }
                        </div>
                    </div>

                );

            default:
                return null
        }
    })()

);



export default BalanceInformation;
