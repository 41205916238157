import React from "react";
import { Link } from "react-router-dom";
import apiCallService from "../../service/api-call-service";
import history from '../../auth/history';
import { withRouter } from 'react-router';


const UploadCard = ({ bulkDetail, responseStatus }) => {
	var inprogress = 0;
	var total = 0;
	total = parseInt(bulkDetail.successCount) + parseInt(bulkDetail.failedCount) + parseInt(bulkDetail.inprogressCount);
	inprogress = 100 - (parseInt(bulkDetail.inprogressCount) / parseInt(total)) * 100;
	if (bulkDetail.inprogressCount === 0) {
		inprogress = 100 - 0
	}
	console.log("fileUrl", bulkDetail.fileUrl)

	const handleNameChange = async () => {
		console.log('Name clicked')
		const url = process.env.REACT_APP_GET_EMPLOYEE_BY_EMAIL;

		var response = await apiCallService.getMethodCallWithParam(url, { "email": bulkDetail.uploadedBy })
		console.log('response', response)
		if (!(response === undefined) && !(response.response === undefined) && !(response.response.employeeDetails === undefined) && !(Object.keys(response.response.employeeDetails).length === 0)) {
			var employee = response.response.employeeDetails
			history.push({
				pathname: '/emp-detail',
				state: { employee, editShowHidden: true }
			})
			history.go();
		}

	}


	return (
		bulkDetail.isBulk === 2
			?
			<div className="col-12 col-sm-6 col-lg-4 col-xl-3">
				<div className="upload-card">
					<a href={bulkDetail.fileUrl} type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</a>

					<div className="upload-type">
						<p>Bulk uploaded by</p>

						{
							(bulkDetail.name === null)
								?
								<p>
									Not Available
								</p>
								:
								// <h6 onClick={handleNameChange}>
								<h6 >
									{bulkDetail.name}
								</h6>
						}

					</div>
					<div className="up-date">{bulkDetail.downloadDate}</div>

					<div className="upload-btn">
						{(bulkDetail.inprogressCount === 0)
							?
							null
							:

							<Link to={{
								pathname: '/inProgress-record',
								state: { bulkDetail, status: 2 }
							}} className="inProgress" data-toggle="tooltip" title="InProgress Records"><span>{bulkDetail.inprogressCount}</span></Link>
						}

						{(bulkDetail.successCount === 0)
							?
							null
							:
							<Link
								to={{
									pathname: '/success-record',
									state: { bulkDetail, status: 0 }
								}}
								className="success" data-toggle="tooltip" title="Success Records"

							> <span>{bulkDetail.successCount}</span> </Link>
						}
						{/* <a href={`/success-record?id=${bulkDetail.batchId}&status=0`} className="success" data-toggle="tooltip" title="Success Records"><span>{bulkDetail.successCount}</span></a> */}
						{(bulkDetail.failedCount === 0)
							?
							null
							:
							<Link
								to={{
									pathname: '/failed-record',
									state: { bulkDetail, status: 1 }
								}}
								className="failed" data-toggle="tooltip" title="Failed Records"

							> <span>{bulkDetail.failedCount}</span> </Link>
						}
						{/* <a href={`/failed-record?id=${bulkDetail.batchId}&status=1`} className="failed" data-toggle="tooltip" title="Failed Records"><span>{bulkDetail.failedCount}</span></a> */}
					</div>
					{(inprogress === 100 || inprogress === 0)
						?
						<br />
						:
						<div className="progress">
							<div className="progress-bar" role="progressbar" style={{ width: `${inprogress}%` }} aria-valuenow={inprogress} aria-valuemin="0" aria-valuemax="100">{parseFloat(inprogress).toFixed(0)}%</div>
						</div>
					}
				</div>
			</div>



			:


			<div className="col-12 col-sm-6 col-lg-4 col-xl-3">
				<div className="upload-card">
					<button type="button" className="file-down mdl-js-button mdl-js-ripple-effect" disabled >Download</button>

					<div className="upload-type">
						<p>{bulkDetail.employeeAddedName} added by</p>

						{
							(bulkDetail.name === null)
								?
								<p>
									Not Available
								</p>
								:
								// <h6 onClick={handleNameChange}>
								<h6 >
									{bulkDetail.name}
								</h6>
						}

					</div>
					<div className="up-date">{bulkDetail.downloadDate}</div>

					<div className="upload-btn">
						{(bulkDetail.successCount === 0)
							? null
							:
							<Link
								to={{
									pathname: '/success-record',
									state: { bulkDetail, status: 0 }
								}}
								className="success" data-toggle="tooltip" title="Success Records"

							></Link>
						}
						{(bulkDetail.failedCount === 0)
							? null
							:
							<Link
								to={{
									pathname: '/failed-record',
									state: { bulkDetail, status: 1 }
								}}
								className="failed" data-toggle="tooltip" title="Failed Records"

							> </Link>
						}
					</div>
					<br />
				</div>
			</div>

	)
};


export default withRouter(UploadCard);