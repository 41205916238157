import React from "react";
import logo from '../../assests/logo.png';




const BenefitsComponent = () => (

	

	<div className="col-12 col-md-6 col-lg-7 col-xl-8 logbg">
				<div className="login-txt">	
				

					<h2>{process.env.REACT_APP_Var_FOR_Benefits_H2_Heading}</h2>					
					<h3>{process.env.REACT_APP_Var_FOR_Benefits_H3_Heading}   </h3>
					<h4><span><img src={logo} alt="ZRO"/></span> {process.env.REACT_APP_Var_FOR_Benefits_Heading} {process.env.REACT_APP_Var_FOR_Benefits_Heading_AfterBreak}</h4>			
					<ul>
						<li><i className="petty"></i><p>{process.env.REACT_APP_Var_FOR_Benefits_Petty_Cash_Management} </p></li>						
						<li><i className="expense"></i><p>{process.env.REACT_APP_Var_FOR_Benefits_Expense_Management_P_VALUE} <br/>{process.env.REACT_APP_Var_FOR_Benefits_Expense_Management_P_VALUE_AFTER_BREAK}</p></li>						
						<li><i className="rewards"></i><p>{process.env.REACT_APP_Var_FOR_Benefits_RANDR_P_VALUE}</p></li>
						<li><i className="salary"></i><p>{process.env.REACT_APP_Var_FOR_Benefits_Salary_Payout}</p></li>
						<li><i className="meal"></i><p>Meal Vouchers</p></li>
					</ul>
					<h6><a href="https://www.zro.money" target="_blank" className="morebtn">{process.env.REACT_APP_Var_FOR_Benefits_And_More}</a></h6>
				</div>
			</div>

);

export default BenefitsComponent;