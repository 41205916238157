import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (


        <>
            <div className="notelist" style={{ backgroundColor: "none" }}>
                <a className='ntcbtn'>
                    <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </a>
            </div>
            <div className="notelist" style={{ backgroundColor: "none" }}>
                <a className='ntcbtn'>
                    <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </a>
            </div>
            <div className="notelist" style={{ backgroundColor: "none" }}>
                <a className='ntcbtn'>
                    <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </a>
            </div>
        </>
    );
}