import CardTypes from './card.types';

export const setAvailableCard = data => ({
    type: CardTypes.SET_AVAILABLE_CARD,
    payload: data
})


export const setReissueMdn = data => ({
    type: CardTypes.SET_REISSUE_MDN,
    payload: data
})


