import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (

        <>
            <div className="row">



                <div className="col-12 col-lg-5">
                    <div className="total-card">

                        <div className="card-avail">


                            <button className="sbtn btnbl" style={{ float: "right" }}>{process.env.REACT_APP_MANAGE_CARD_VAR_Order_button}</button>


                            <i className="ttl"></i>
                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Total_Cards}</p>
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        </div>

                        <div className="cardsavail">
                            <i className="phy"></i>
                            <p>Physical Cards</p>
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        </div>

                        <div className="cardsavail plus">
                            <i className="vir"></i>
                            <p>Virtual Cards</p>
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        </div>

                    </div>
                </div>




                <div className="col-12 col-lg-7">

                    <div className="total-card">

                        <div className="card-avail">

                            <button className="sbtn btngn" style={{ float: "right" }} disabled>{process.env.REACT_APP_MANAGE_CARD_VAR_View_Available_Card_Button}</button>



                            <i className="avl"></i>
                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Available_Cards}</p>
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        </div>


                        <div className="cardswith pr-sm-3">


                            <button id="/assigned-cards" className="sbtn btnbl" style={{ float: "right" }}>{process.env.REACT_APP_MANAGE_CARD_VAR_View_ASSIGNED_CARD_Button}</button>



                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Assigned_Cards}</p>
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        </div>


                        <div className="cardswith card-last pl-sm-3">



                            <button id="/blocked-cards" className="sbtn btnrd" style={{ float: "right" }}


                            >{process.env.REACT_APP_MANAGE_CARD_VAR_View_Blocked_Cards_Button}</button>



                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Blocked_Cards}</p>
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                        </div>

                    </div>
                </div>





            </div>

        </>
        // <div className="total-card">
        //     <div className="row">
        //         <div className="col-12 col-lg-6 col-xl-3 border-right">

        //             <div className="card-avail">
        //                 {/* <button className="sbtn btnbl">Order</button> */}

        //                 <i className="ttl"></i>
        //                 <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Total_Cards}</p>
        //                 <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
        //             </div>

        //         </div>

        //         <div className="col-12 col-lg-6 col-xl-3 border-right">
        //             <div className="card-avail">


        //                 <button className="sbtn btngn" disabled>{process.env.REACT_APP_MANAGE_CARD_VAR_View_Available_Card_Button}</button>



        //                 <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Available_Cards}</p>
        //                 <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
        //             </div>
        //         </div>

        //         <div className="col-12 col-lg-6 col-xl-3 border-right">
        //             <div className="card-avail">



        //                 <button id="/assigned-cards" className="sbtn btnbl" disabled>{process.env.REACT_APP_MANAGE_CARD_VAR_View_ASSIGNED_CARD_Button}</button>


        //                 <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Assigned_Cards}</p>
        //                 <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
        //             </div>
        //         </div>

        //         <div className="col-12 col-lg-6 col-xl-3">
        //             <div className="card-avail ">



        //                 <button id="/blocked-cards" className="sbtn btnrd" disabled>{process.env.REACT_APP_MANAGE_CARD_VAR_View_Blocked_Cards_Button}</button>



        //                 <p>{process.env.REACT_APP_MANAGE_CARD_VAR_Blocked_Cards}</p>
        //                 <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}