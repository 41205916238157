import KycType from "./kyc.types";

const INITIAL_STATE = {
    kycData: [],
    kycListToView: [],
    masterRoleData: [],
    masterRoleListToView: [],
    cardStatusData: [],
    cardStatusListToView: []
};


const kycReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case KycType.SET_KYC:
            return {
                ...state,
                kycData: action.payload
            };
        case KycType.SET_KYC_LIST_TO_VIEW:
            return {
                ...state,
                kycListToView: action.payload
            };
        case KycType.SET_ROLE_MASTER:
            return {
                ...state,
                masterRoleData: action.payload
            };
        case KycType.SET_ROLE_LIST_TO_VIEW:
            return {
                ...state,
                masterRoleListToView: action.payload
            }
        case KycType.SET_ALLOWED_CARD_STATUS:
            return {
                ...state,
                cardStatusData: action.payload
            }
        case KycType.SET_CARD_STATUS_LIST_TO_VIEW:
            return {
                ...state,
                cardStatusListToView: action.payload
            }
        default:
            return state;
    }
};

export default kycReducer;