import React from 'react';
import { connect } from 'react-redux';
import history from '../../auth/history';
import { setAdminViewType } from '../../redux/adminView/adminView.action';
import { resetRole } from '../../redux/role/role.action';
import { store } from '../../redux/store';
import { resetToken } from '../../redux/token/token.action';
import { resetUser } from '../../redux/user/user.actions';
import { Redirect } from "react-router-dom";

class App extends React.Component {


    constructor() {
        super();

        this.state = {

        };
    }


    componentDidMount = () => {

        store.dispatch(setAdminViewType(true))

        store.dispatch(resetUser)
        store.dispatch(resetRole);
        store.dispatch(resetToken)

        history.push('/view-Acess-Login');
        history.go();

    }


    render() {
        return (
            <>

                {
                    (this.props.authenticated)
                        ? (

                            <Redirect to="/dashboard" />

                        )
                        : (

                            <></>

                        )}
            </>
        );
    }
}

const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData }, forgotPassword: { referenceNum, email },
    adminView: { loginUserEmail, loginUserRoID }
}) => ({
    authenticated, userRole, roleData, roMdn, mdn, referenceNum, email, loginUserEmail, loginUserRoID

});

export default connect(mapStateToProps)(App);
