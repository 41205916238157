
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import apiCallService from '../../service/api-call-service';
import Pagination from 'react-js-pagination';

import CsvDownload from 'react-json-to-csv';
import CommonFunction from '../../common-function/GenerateRequestId';
import Avatar from 'react-avatar';
import TrData from './tr.giftcard.detail.pages';
import moment from 'moment';
import Can from '../../auth/can/Can';
var ToDateVar = "";
var FromDateVar = "";
class IssuedGiftCard extends React.Component {

    constructor() {
        super();

        this.state = {
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_GIFT_CARD_DETAIL_LIST,
            // postsPerPage: 5,

            currentPage: 1,
            totalLength: 0,


            fromDate: "",
            toDate: "",


            beneficiaryDetail: [],


            loading: false

        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards History"

        localStorage.setItem('url', '');
        this.getBeneficiaryOrderDetail("", "", this.props.location.state.beneMdn, this.state.currentPage)

    }


    getBeneficiaryOrderDetail = async (fromDate, toDate, mdn, page) => {

        this.setState({ loading: true })
        var url = process.env.REACT_APP_GET_BENEFICIARY_ORDER_DETAIL;

        var request = {

            "fromDate": fromDate,
            "toDate": toDate,
            "roMdn": this.props.roMdn,
            "mdn": this.props.location.state.beneMdn,
            "page": page,
            "size": this.state.postsPerPage,
            "isBalanecedRequired": true,

        }


        var response = await apiCallService.postMethodCall(url, request);

        console.log("Response for getBeneficiaryOrderDetail", response);

        if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null) {
            this.setState({
                beneficiaryDetail: response.response,
                totalLength: response.response.count
            })
            this.setState({ loading: false })
        } else {
            this.setState({
                totalLength: 0
            })
            this.setState({ loading: false })
        }
    }




    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");


            this.getBeneficiaryOrderDetail(`${formatFromDate} 00:00:00`, `${formatToDate} 23:59:59`, this.props.user, 1)
        }
    }




    clearAll = () => {
        console.log('clear all triggered')

        ToDateVar = '';
        FromDateVar = '';


        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)


        this.getBeneficiaryOrderDetail('', '', this.props.user, 1)

    }






    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

        this.getBeneficiaryOrderDetail("", "", this.props.user, pageNumber)

    };





    render() {

        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;

        var fileToExport = [];
        this.state.beneficiaryDetail.subOrderDetail !== undefined &&
            this.state.beneficiaryDetail.subOrderDetail !== null &&

            this.state.beneficiaryDetail.subOrderDetail.map(exptdata => {


                var detailGoesInFile = {
                    "Order Id": `#${exptdata.subOrderDetails.requestId}`,
                    "Gift Card Number": exptdata.cardDetail != null && exptdata.cardDetail.cardList !== undefined ? `#${exptdata.cardDetail.cardList[0]}` : <>NA</>,
                    "Issued Amount": parseFloat(exptdata.subOrderDetails.amount) / 100,
                    "Available Balance": exptdata.cardBalance != null && exptdata.cardBalance !== undefined ? exptdata.cardBalance.balance : <>NA</>,
                    "Gifted By": exptdata.subOrderDetails.corpName,
                    "Issued Date & Time ": exptdata.subOrderDetails.updation_date
                }
                fileToExport.push(detailGoesInFile)

            })



        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");

        var i = indexOfFirstPost;

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?

                            <Can
                                role={this.props.userRole}
                                perform="subheader-manageGiftCard:visit"
                                roleData={this.props.roleData}
                                yes={() => (
                                    <>

                                        {
                                            this.state.loading
                                                ?
                                                <div className="col-12">
                                                    <div className="no-record" style={{ minHeight: "86vh" }}>


                                                        <div className="spinner-border text-success" role="status"><span className="visually-hidden"></span></div>
                                                    </div>
                                                </div>
                                                :

                                                <div className="mid-container">
                                                    <div className="container-fluid">

                                                        <div className="head">
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <h3>{process.env.REACT_APP_Gift_Cards_Details}</h3>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="flterbox">
                                                                        <div className="dropdown" data-toggle="tooltip" title="Filter">
                                                                            <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                                            <div className="dropdown-menu dropdown-menu-right">

                                                                                <div className="filters">
                                                                                    <label htmlFor="select-state">From Date:</label>
                                                                                    <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                                </div>

                                                                                <div className="filters">
                                                                                    <label htmlFor="select-state">To Date:</label>
                                                                                    <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                                </div>

                                                                                <div className="fbtn">
                                                                                    <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div className="actnbtn">
                                                                        <a href="/issued-giftcards" data-toggle="tooltip" data-original-title="Back"><i className="back" ></i> </a>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div className="emp-list">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="benef-card">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-5">
                                                                                <div className="bene-txt">
                                                                                    <Avatar className="avatar" color={Avatar.getRandomColor(this.state.beneficiaryDetail.beneficiary_mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={this.state.beneficiaryDetail.beneficiary_mdn} name={`${CommonFunction.Capitalize(this.state.beneficiaryDetail.beneficiary_name)}`} size="48px" round={true} />

                                                                                    <h5>{this.state.beneficiaryDetail.beneficiary_name}</h5>
                                                                                    <p>+91-{this.state.beneficiaryDetail.beneficiary_mdn}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-6 col-sm-4">
                                                                                <div className="bene-txt">
                                                                                    <p>{process.env.REACT_APP_Gift_Cards_Details_Cards_Quantity}</p>
                                                                                    <h6>{this.state.beneficiaryDetail.count}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-sm-3">
                                                                                <div className="bene-txt">
                                                                                    <p>{process.env.REACT_APP_Gift_Cards_Details_Total_Amount}</p>
                                                                                    <h6>₹ {parseFloat(parseFloat(this.state.beneficiaryDetail.total_amount) / 100).toFixed(2)}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                {
                                                                    this.state.totalLength === 0
                                                                        ?

                                                                        <div className="col-12">
                                                                            <div className="no-record">
                                                                                <i></i>
                                                                                <h3> No Record Available.</h3>
                                                                            </div>
                                                                        </div>
                                                                        :

                                                                        <div className="col-12">
                                                                            <div className="giftable">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-hover table-bordered">
                                                                                        <tbody>
                                                                                            <tr className="table-primary">
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_SNo}</th>
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_Order_ID}</th>
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_Date_Time}</th>
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_Gift_Cards}</th>
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_Expiry_Date}</th>
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_Card_Amount}</th>
                                                                                                <th>{process.env.REACT_APP_Gift_Cards_Details_Table_Card_Balance}</th>
                                                                                            </tr>
                                                                                            {
                                                                                                this.state.beneficiaryDetail.subOrderDetail !== undefined &&
                                                                                                this.state.beneficiaryDetail.subOrderDetail !== null &&

                                                                                                this.state.beneficiaryDetail.subOrderDetail.map(detail => (


                                                                                                    <TrData num={++i} orderDetail={detail} />

                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                }


                                                                <div className="col-12"></div>
                                                                {
                                                                    !(parseInt(this.state.totalLength) > parseInt(this.state.postsPerPage))
                                                                        ?
                                                                        this.state.totalLength === 0
                                                                            ?
                                                                            null
                                                                            :
                                                                            <div className="col-12 col-md-6">
                                                                                <div className="entry">
                                                                                    <p>         </p>
                                                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"card-list.csv"}>Export to CSV</CsvDownload>


                                                                                </div>
                                                                            </div>
                                                                        :

                                                                        <>
                                                                            <div className="col-12 col-md-6">
                                                                                <div className="entry">
                                                                                    <p>

                                                                                        Showing {valueToShow} to {(parseInt(indexOfLastPost) > parseInt(this.state.totalLength)) ? this.state.totalLength : indexOfLastPost} of {this.state.totalLength} entries</p>

                                                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"emp-list.csv"}>Export to CSV</CsvDownload>


                                                                                </div>
                                                                            </div>



                                                                            <div className="col-12 col-sm-6">
                                                                                <div className="pager">
                                                                                    <Pagination
                                                                                        activePage={parseInt(this.state.currentPage)}
                                                                                        itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                                        totalItemsCount={this.state.totalLength}
                                                                                        pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                                        onChange={this.paginate}
                                                                                        className="pagination"
                                                                                        linkClass="page-link"
                                                                                        itemClass="page-item"
                                                                                        nextPageText="Next"
                                                                                        prevPageText="Prev"
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                }





                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }



                                    </>

                                )}
                                no={() =>
                                    <Redirect to="/access-denied" />
                                }
                            />


                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser

});

export default connect(mapStateToProps)(IssuedGiftCard);