import axios from 'axios';
import rules from '../../auth/rules/rbac-rules';
import { setHuburgShowOrHide, setRoleAdminBoolean, setRoleData, setUserMaintain } from '../../redux/role/role.action';
import { store } from '../../redux/store';
import { setUserRole, setCurrentUser, } from '../../redux/user/user.actions';
import { setRememberMail, setRememberPassword } from '../../redux/rememberMe/rememberMe.action';
import history from '../../auth/history';
import { findAllByAltText } from '@testing-library/react';
import { isAuthenticated, setToken } from '../../redux/token/token.action';
import { sha256 } from 'js-sha256';
const state = store.getState();

var errorData = ''
class AclService {

  generateHash(messageBody) {
    //var msg= JSON.stringify(messageBody) ;
    var privateKey = process.env.REACT_APP_CLIENT_ID;
    var hash = sha256.hmac.create(privateKey);
    hash.update(messageBody);
    var hexData = hash.hex();
    console.log('hexed data', hexData);
    var base64String = btoa(hexData);
    console.log(messageBody, '---->hash Generated --->', base64String);
    return base64String;
  }

  async aclServiceCall(url, username, token, password, rememberMe, isPasswordReset, roMdn) {
    console.log('inside aclServiceCall method', url)
    //const str = url.toString().split("?")[0];
    //const str = url.toString().split("?")[0];
    const str = (new URL(url)).pathname + (new URL(url)).search;
    console.log('test ---->', str.replace(/[^a-zA-Z0-9 ]/g, ""));
    var hashCodeForHeader = await this.generateHash(str.replace(/[^a-zA-Z0-9 ]/g, ""));
    //var hashCodeForHeader = await this.generateHash('');

    const headers = {
      "device_info": "",
      "token": token,
      "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
      "hashCode": hashCodeForHeader,
      "loggedInUser": username
    }


    await axios.get(url, {
      headers: {
        "device_info": "",
        "token": token,
        "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
        "hashCode": hashCodeForHeader,
        "loggedInUser": username
      }
    })
      .then(async res => {
        console.log(res.data);
        // if (res !== undefined && res.data !== undefined && res.data.responseCode === 0 && res.data.actionRoleResponses !== undefined && res.data.actionRoleResponses === null) {
        //   localStorage.setItem('errorMessage', res.data.responseMessage);
        //   history.push('/');
        //   history.go();

        // } else

        if (res !== undefined && res.data !== undefined && res.data.actionRoleResponses !== undefined && res.data.actionRoleResponses !== null) {
          const permissions = res.data.actionRoleResponses;
          var data = []
          var x;
          var userRole;
          for (x of permissions) {
            data.push(x.action);
            localStorage.setItem('role', x.role);
            userRole = x.role;
            console.log(rules);

            //console.log(x.actionName);
          }
          console.log(userRole)
          localStorage.setItem('staticData', data);
          rules.admin.static = data;
          console.log('store', store)
          store.dispatch(setRoleData(data));


          store.dispatch(setUserMaintain(0));

          if (data.includes('moneyDisburse-checker:success')) {
            store.dispatch(setRoleAdminBoolean(0));
            console.log('inside check method', userRole);
          } else {
            store.dispatch(setRoleAdminBoolean(1));
          }


          if (data.includes("empmng-remove:visit") || data.includes("empmng-tBlock:visit") || data.includes("empmng-tBlock:visit") || data.includes("empmng-pBlock:visit") || data.includes("empmng-edit:visit")) {
            store.dispatch(setHuburgShowOrHide(true));
            console.log('inside check method', userRole);
          } else {
            store.dispatch(setHuburgShowOrHide(false));
          }



          store.dispatch(setUserRole(userRole));
          console.log('rules', rules);
          console.log(store);


          store.dispatch(setCurrentUser(username));
          store.dispatch(isAuthenticated(true));
          store.dispatch(setToken(token));

          // const rememberMe=state.user.rememberMe;
          console.log('rememberMe', rememberMe)

          if (rememberMe) {
            store.dispatch(setRememberMail(username));
            store.dispatch(setRememberPassword(password));
          } else {
            store.dispatch(setRememberMail(''));
            store.dispatch(setRememberPassword(''));
          }

          return 'success';
        } else {
          // localStorage.setItem('errorMessage', res.data.responseMessage);
          // history.push('/');
          // history.go();


          var urlnew = `${process.env.REACT_APP_ACL_URL}/${username}/${roMdn}/1`;

          const str = (new URL(urlnew)).pathname + (new URL(urlnew)).search;
          console.log('test ---->', str.replace(/[^a-zA-Z0-9 ]/g, ""));
          var hashCodeForHeaderN = await this.generateHash(str.replace(/[^a-zA-Z0-9 ]/g, ""));
          await axios.get(urlnew, {
            headers: {
              "device_info": "",
              "token": token,
              "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
              "hashCode": hashCodeForHeaderN,
              "loggedInUser": username
            }
          })
            .then(resN => {
              console.log(res.data);

              if (resN !== undefined && resN.data !== undefined && resN.data.actionRoleResponses !== undefined && resN.data.actionRoleResponses !== null) {
                const permissions = resN.data.actionRoleResponses;
                var data = []
                var x;
                var userRole;
                for (x of permissions) {
                  data.push(x.action);
                  localStorage.setItem('role', x.role);
                  userRole = x.role;
                  console.log(rules);

                  //console.log(x.actionName);
                }
                console.log(userRole)
                localStorage.setItem('staticData', data);
                rules.admin.static = data;
                console.log('store', store)
                store.dispatch(setRoleData(data));


                store.dispatch(setUserMaintain(1));

                if (data.includes('moneyDisburse-checker:success')) {
                  store.dispatch(setRoleAdminBoolean(0));
                  console.log('inside check method', userRole);
                } else {
                  store.dispatch(setRoleAdminBoolean(1));
                }


                if (data.includes("empmng-remove:visit") || data.includes("empmng-tBlock:visit") || data.includes("empmng-tBlock:visit") || data.includes("empmng-pBlock:visit") || data.includes("empmng-edit:visit")) {
                  store.dispatch(setHuburgShowOrHide(true));
                  console.log('inside check method', userRole);
                } else {
                  store.dispatch(setHuburgShowOrHide(false));
                }



                store.dispatch(setUserRole(userRole));
                console.log('rules', rules);
                console.log(store);


                store.dispatch(setCurrentUser(username));
                store.dispatch(isAuthenticated(true));
                store.dispatch(setToken(token));

                // const rememberMe=state.user.rememberMe;
                console.log('rememberMe', rememberMe)

                if (rememberMe) {
                  store.dispatch(setRememberMail(username));
                  store.dispatch(setRememberPassword(password));
                } else {
                  store.dispatch(setRememberMail(''));
                  store.dispatch(setRememberPassword(''));
                }

                return 'success';
              } else {
                localStorage.setItem('errorMessage', resN.data.responseMessage);
                history.push('/');
                history.go();
              }
            }).catch(errorN => {


              var detail = {
                response_description: ""
              }
              console.log('inside errorr', errorN.response);
              if (!(errorN === undefined) && !(errorN.response === undefined) && !(errorN.response.data === undefined) && !(errorN.response.data.message === undefined)) {
                console.log('error', errorN.response.data.message);
                console.log('error', errorN.response.status);
                console.log('inside errorr', errorN);
                if (errorN.response.data.code !== undefined) {

                  //detail.response_description = `${error.response.data.message}(Error:${error.response.data.code})`;
                  detail.response_description = `${errorN.response.data.debugMessage}`;
                  errorData = detail;
                } else {
                  // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
                  detail.response_description = `${errorN.response.data.debugMessage}`
                  errorData = detail;
                }
                console.log('inside errorr', errorData);
                if (errorN.response.status === 500) {
                  if (errorN.response.data.code !== undefined) {
                    // detail.response_description =  `${error.response.data.message}(Error:${error.response.data.code})`
                    detail.response_description = `${errorN.response.data.debugMessage}`
                    errorData = detail;
                  } else {
                    // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
                    detail.response_description = `${errorN.response.data.debugMessage}`
                    errorData = detail;
                  }
                  // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
                }


              } else {
                console.log('inside errorr ', errorN);
                errorData = 'error';
              }
              localStorage.setItem('errorMessage', errorN.response.data.message);
              history.push('/');
              history.go();

            })












        }
      }).catch(error => {


        var detail = {
          response_description: ""
        }
        console.log('inside errorr', error.response);
        if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
          console.log('error', error.response.data.message);
          console.log('error', error.response.status);
          console.log('inside errorr', error);
          if (error.response.data.code !== undefined) {

            //detail.response_description = `${error.response.data.message}(Error:${error.response.data.code})`;
            detail.response_description = `${error.response.data.debugMessage}`;
            errorData = detail;
          } else {
            // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
            detail.response_description = `${error.response.data.debugMessage}`
            errorData = detail;
          }
          console.log('inside errorr', errorData);
          if (error.response.status === 500) {
            if (error.response.data.code !== undefined) {
              // detail.response_description =  `${error.response.data.message}(Error:${error.response.data.code})`
              detail.response_description = `${error.response.data.debugMessage}`
              errorData = detail;
            } else {
              // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
              detail.response_description = `${error.response.data.debugMessage}`
              errorData = detail;
            }
            // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          }


        } else {
          console.log('inside errorr ', error);
          errorData = 'error';
        }
        localStorage.setItem('errorMessage', error.response.data.message);
        history.push('/');
        history.go();

      })

  }

}



export default new AclService();