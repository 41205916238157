export const professionalDetails = [
    {label: 'SALARIED', value: 'SALARIED'},
    {label: 'SELF-EMPLOYED', value: 'SELF-EMPLOYED'},
    {label: 'HOME MAKER', value: 'HOME MAKER'},
    {label: 'STUDENT', value: 'STUDENT'},
    {label: 'NOT WORKING ', value: 'NOT WORKING '},
    {label: 'AGRICULTURE', value: 'AGRICULTURE'},
    {label: 'BUSINESS', value: 'BUSINESS'},
]


export const annualIncome = [
    {label: '0-1L', value: '0-1L'},
    {label: '1L-5L', value: '1L-5L'},
    {label: '5L-10L', value: '5L-10L'},
    {label: '10L-25L', value: '10L-25L'},
    {label: '25L-50L', value: '25L-50L'},
    {label: '50L-1C', value: '50L-1C'},
]

export const purposeofIssuingcard = [
    {label: 'GENERAL', value: 'GENERAL'},
    {label: 'INCENTIVE', value: 'INCENTIVE'},
    {label: 'GIFT', value: 'GIFT'},
]

export const educationDetails = [
    {label: 'UPTO 10', value: 'UPTO 10'},
    {label: 'UNDER GRADUATE', value: 'UNDER GRADUATE'},
    {label: 'GRADUATE', value: 'GRADUATE'},

    {label: 'POST GRADUATE', value: 'POST GRADUATE'},

    {label: 'PROFESSIONAL', value: 'PROFESSIONAL'},
    {label: 'NOT APPLICABLE', value: 'NOT APPLICABLE'},
]

export const sourceOfFund = [
    {label: 'SALARY', value: 'SALARY'},
    {label: 'WAGES', value: 'WAGES'},
    {label: 'PENSION', value: 'PENSION'},

    {label: 'INCOME FROM RENT', value: 'INCOME FROM RENT'},

    {label: 'INCOME FROM AGRICULTURE', value: 'INCOME FROM AGRICULTURE'},
    {label: 'ROYALTY', value: 'ROYALTY'},


    {label: 'INCOME FROM BUSINESS', value: 'INCOME FROM BUSINESS'},
    {label: 'INCOME FROM INVESTMENT', value: 'INCOME FROM INVESTMENT'},
    {label: 'FAMILY INCOME', value: 'FAMILY INCOME'},
    {label: 'FEES', value: 'FEES'},


    {label: 'COMMISSION', value: 'COMMISSION'}, 
    {label: 'GIFT', value: 'GIFT'},
    {label: 'PRIZE MONEY', value: 'PRIZE MONEY'},
]



export const selfEmployedProfession = [
    {label: 'ARCHITECT', value: 'ARCHITECT'},
    {label: 'COMPANY SECRETARY', value: 'COMPANY SECRETARY'},
    {label: 'DOCTOR', value: 'DOCTOR'},

    {label: 'CHARTERED ACCOUNTANT', value: 'CHARTERED ACCOUNTANT'},

    {label: 'COST ACCOUNTANT', value: 'COST ACCOUNTANT'},
    {label: 'LAWYER', value: 'LAWYER'},
]



export const natureOfBusiness = [
    {label: 'MANIFACTURING', value: 'MANIFACTURING'},
    {label: 'AGRICULTURE', value: 'AGRICULTURE'},
    {label: 'SERVICE PROVIDER', value: 'SERVICE PROVIDER'},

    {label: 'TRADING', value: 'TRADING'},

    {label: 'REAL ESTATE', value: 'REAL ESTATE'},
    
]
