
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import KycStatus from '../../../../constants/kyc-status.type';
import { setActiveHeader } from '../../../../redux/activeHeader/activeheader.action';
import { store } from '../../../../redux/store';
import apiCallService from '../../../../service/api-call-service';
import AadharBiometryComponent from './aadhar-biometry.component';
import AadharOtpComponent from './aadhar-otp.component';
import AadharXmlComponent from './aadhar-xml.component';
import Countdown from 'react-countdown';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const FullUserKyc = ({ mdn, authenticated, roleData, cardRefNumber, employee, userRole, roMdn, bandData, employeeData, token }) => {

    const [kycIdentifier, setKycIdentifier] = useState('');
    const [popUpbuttonShow, setpopUpbuttonShow] = useState(true);
    const [submitButtonProcessBar, setSubmitButtonProcessBar] = useState(false);

    const [otpRefNumber, setOtpRefNum] = useState('');
    const [otp, setOtp] = useState('');

    const [disableVar, setdisableVar] = useState(true);
    const [time, setTime] = useState(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT));
    const [triggeretime, setTriggeretime] = useState(false);

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');
    const [kycDisable, setkycDisable] = useState(false);
    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
        // setKycIdentifier("adrotp");

        if (parseInt(employee.kyc_status) === parseInt(KycStatus.AADHAR_BASED)) {

            setKycIdentifier("");
            // var evwnt={"target":{value:"adrbmc"}}
            // handleFullKycCheckBox(evwnt)
        }

    }, []);


    const handleInputCheckBox = (event) => {
        setKycIdentifier(event.target.name)
    }


    const handleFullKycCheckBox = async (event) => {
        event.preventDefault();
        setKycIdentifier(event.target.name)

        setSubmitButtonProcessBar(true)
        const url = `${process.env.REACT_APP_OEMS_REGESTER_USER_FOR_KYC}/${employee.mdn}`

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log(response)
        setTriggeretime(false);

        console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            setpopUpbuttonShow(false);
            setOtpRefNum(response.response.otp_ref_number);
            setSubmitButtonProcessBar(false)

            setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
            setTriggeretime(true);
            console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
            document.getElementById('sendOtpPopup').click();

        } else {
            setSubmitButtonProcessBar(false)

            if (response.response_description === "User already registered.") {

                goToKycPortal();
            } else {
                setDropDownHidden(true);
                setSeverty("error");
                setMessage(response.response_description);
            }

        }


    }

    const submitKycData = async (event) => {
        event.preventDefault();
        // setKycIdentifier(event.target.name)



        // this.setState({ kycDisable: true })

        setkycDisable(true)


        var request = {
            "mobile_number": employee.mdn,
            "OTP_reference_number": otpRefNumber,
            "otp": otp
        }



        console.log(request)

        const url = process.env.REACT_APP_VERIFY_MOTP_USER_REGISTER;

        var response = await apiCallService.postMethodCall(url, request);

        console.log("response", response);

        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {


            goToKycPortal();
            // var CryptoJS = require("crypto-js");

            // var key = process.env.REACT_APP_ncryptionKey;

            // var mdnTo = CryptoJS.AES.encrypt(employee.mdn, key);
            // var loggedInUser = CryptoJS.AES.encrypt(mdn, key);
            // var tokenTo = CryptoJS.AES.encrypt(token, key);

            // var callBackURl = CryptoJS.AES.encrypt(`http://${document.location.host}/assigned-cards`, key);



            // window.location.replace(`${process.env.REACT_APP_URL_FOR_KYC_PORTAL}?p1=${loggedInUser}&p2=${mdnTo}&p3=${tokenTo}&p4=${callBackURl}`);


        } else {

            setkycDisable(false)
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
            if (response.response_description !== undefined && response.response_description !== null && response.response_description.toLowerCase().includes("otp")) {

            } else {
                document.getElementById('closeDic').click();
            }
        }

    }




    const goToKycPortal = async (event) => {


        var CryptoJS = require("crypto-js");

        var key = process.env.REACT_APP_ncryptionKey;

        var mdnTo = CryptoJS.AES.encrypt(employee.mdn, key);
        var loggedInUser = CryptoJS.AES.encrypt(mdn, key);
        var tokenTo = CryptoJS.AES.encrypt(token, key);

        var callBackURl = CryptoJS.AES.encrypt(`http://${document.location.host}/assigned-cards`, key);


        document.getElementById('closeDic').click();
        window.open(`${process.env.REACT_APP_URL_FOR_KYC_PORTAL}?p1=${loggedInUser}&p2=${mdnTo}&p3=${tokenTo}&p4=${callBackURl}`,"_blank");



    }






    const resendOtp = async (event) => {
        const url = `${process.env.REACT_APP_OEMS_REGESTER_USER_FOR_KYC}/${employee.mdn}`



        var response = await apiCallService.getMethodCallWithoutParam(url);
        setTriggeretime(false);

        console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

            setDropDownHidden(true);
            setSeverty("sucess");
            setMessage(process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile);

            setOtpRefNum(response.response.otp_ref_number);
            setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
            setTriggeretime(true);

        } else {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
        }
    }


    const setOtpChange = (event) => {
        setOtp(event.target.value);
    }




    const validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }




    const MethodTosetDisabledVar = () => {
        setdisableVar(false);

    }
    const MethodTosetEnableVar = () => {
        setdisableVar(true);

    }



    if (employee !== undefined && employee.mdn !== undefined) {
        var myString = employee.mdn.substring(0, 4);
    }

    console.log("cardRefNumber hhhhhhhhh", cardRefNumber)

    return (
        <div >
            {(authenticated)
                ? (


                    <div className="kyctab" id="fullkyc">
                        <div className="row">
                            <Snackbar

                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={dropDownHidden}
                                autoHideDuration={3000}
                                onClose={e => setDropDownHidden(false)}
                            >
                                <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                    {message}
                                </Alert>
                            </Snackbar>
                            <div className="col-12 mb-3">
                                <h6>Through:</h6>



                                {/* <div className="radio-checkbox">
                                    <label className={(kycIdentifier === 'adrotp') ? "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded is-checked" : "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded"} for="abo">
                                        <input type="radio" id="abo" className="mdl-radio__button" name="adrotp" value={kycIdentifier} checked={kycIdentifier === 'adrotp'} onClick={handleInputCheckBox} disabled={parseInt(employee.kyc_status) === parseInt(KycStatus.AADHAR_BASED)} />
                                        <span className="mdl-radio__outer-circle" name="adrotp" ></span>
                                        <span className="mdl-radio__inner-circle" name="adrotp" ></span>
                                        <span className="mdl-radio__label">Aadhaar Based OTP</span>
                                    </label>
                                </div> */}

                                {popUpbuttonShow
                                    ?
                                    <div className="form-check form-check-inline">

                                        <input type="radio" id="abc" className="form-check-input" name="adrbmc" value={kycIdentifier} checked={kycIdentifier === 'adrbmc'} onClick={handleFullKycCheckBox} disabled={parseInt(employee.kyc_status) === parseInt(KycStatus.KYC_DONE)} />
                                        <label className="form-check-label" for="abc"> Aadhaar Biometric
                                        </label>
                                    </div>

                                    :
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="mdl-radio__button" name="adrbmc" value={kycIdentifier} id="sendOtpPopup" data-bs-toggle="modal" data-bs-target="#sendotp" />

                                        <label className="form-check-label" for="sendOtpPopup"> Aadhaar Biometric
                                            Aadhaar Biometric
                                        </label>
                                    </div>
                                }
                                {/* {popUpbuttonShow
                                    ?
                                    <button type="submit" disabled={submitButtonProcessBar} className="lbtn btnbl" >
                                       Submit
                                    </button>
                                    :
                                    <button id="sendOtpPopup" type="button" className="lbtn btnbl" data-bs-toggle="modal" data-bs-target="#sendotp" >Submit</button>
                                } */}



                                {/* <div className="radio-checkbox">
                                    <label className={(kycIdentifier === 'adrxml') ? "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded is-checked" : "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded"} for="abx">
                                        <input type="radio" id="abx" className="mdl-radio__button" name="adrxml" value={kycIdentifier} checked={kycIdentifier === 'adrxml'} onClick={handleInputCheckBox} />
                                        <span className="mdl-radio__outer-circle" name="adrxml" ></span>
                                        <span className="mdl-radio__inner-circle" name="adrxml" ></span>
                                        <span className="mdl-radio__label">Aadhaar Based XML</span>
                                    </label>
                                </div>



                                <div className="radio-checkbox">
                                    <label className={(kycIdentifier === 'adrVdi') ? "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded is-checked" : "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded"} for="abv">
                                        <input type="radio" id="abv" className="mdl-radio__button" name="adrVdi" value={kycIdentifier} checked={kycIdentifier === 'adrVdi'} onClick={handleInputCheckBox} />
                                        <span className="mdl-radio__outer-circle" name="adrVdi" ></span>
                                        <span className="mdl-radio__inner-circle" name="adrVdi" ></span>
                                        <span className="mdl-radio__label">Video</span>
                                    </label>
                                </div> */}



                            </div>

                            {kycIdentifier === "adrotp" ?
                                <AadharOtpComponent cardRefNumber={cardRefNumber} employee={employee} />
                                : null
                            }
                            {kycIdentifier === "adrbmcf" ?
                                <AadharBiometryComponent cardRefNumber={cardRefNumber} employee={employee} />
                                : null
                            }
                            {kycIdentifier === "adrxml" ?
                                <AadharXmlComponent cardRefNumber={cardRefNumber} employee={employee} />
                                : null
                            }
                            {kycIdentifier === "adrVdi" ?
                                <AadharXmlComponent cardRefNumber={cardRefNumber} employee={employee} />
                                : null
                            }

                            {kycIdentifier === "" ?
                                <div className="col-12" id="adrxml" >
                                    <form className="row">



                                        <div  className="col-12 mt-4">
                                            <div  className="itxt">Note: {process.env.REACT_APP_VAR_FOR_SELECT_KYC_MESSAGE}</div>
                                        </div>

                                        <div  className="col-12 mt-4">
                                            <div  className="downdriver">
                                                <h6>{process.env.REACT_APP_VARIABLE_FOR_Download_Biometric_Driver}</h6>
                                                <ul>

                                                    <li><a target="_blank" href={process.env.REACT_APP_PB510_DRIVER_DOWNLOAD_URL}>{process.env.REACT_APP_VARIABLE_FOR_DRIVER_DESCRIPTION}</a></li>
                                                </ul>
                                            </div>
                                        </div>




                                    </form>
                                </div>
                                : null
                            }

                        </div>
                        <div className="modal fade" id="sendotp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
                            <form className="modal-dialog modal-sm" onSubmit={submitKycData}>
                                <div className="modal-content alerttext">
                                    <div className="modal-header">
                                        <h5>One-Time-Password </h5>
                                        <button className="close" id="closeDic" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="otptxt">
                                            <input className="form-control" type="number" placeholder="Enter OTP" maxLength="6"
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                onInput={validateInputLenght} value={otp} onChange={setOtpChange} required />
                                            <p>We have sent the OTP on this mobile number: {myString}XXXXXX</p>

                                            <h5>
                                                {triggeretime ?
                                                    <Countdown date={time} onMount={MethodTosetEnableVar} onComplete={MethodTosetDisabledVar} />
                                                    :
                                                    null
                                                }
                                            </h5>
                                        </div>

                                    </div>
                                    <div className="modal-footer">

                                        {
                                            disableVar
                                                ?
                                                <button type="button" className="mbtn btngr" onClick={resendOtp} disabled={disableVar}>

                                                    Resend OTP

                                                </button>
                                                :
                                                <button type="button" className="mbtn btngn" onClick={resendOtp} disabled={disableVar}>

                                                    Resend OTP

                                                </button>
                                        }

                                        <button type="submit" className="mbtn btnbl" disabled={kycDisable}>

                                            {
                                                kycDisable

                                                    ?
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                    </div>
                                                    :
                                                    <>Submit</>

                                            }


                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>


                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ band: { bandData }, token: { authenticated, token }, user: { userRole, roMdn, mdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, bandData, token, mdn
});

export default connect(mapStateToProps)(FullUserKyc);



