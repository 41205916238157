import React, { useEffect } from 'react';
import Card from './card.component';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from "react-redux";
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action';
import Alert from '@material-ui/lab/Alert';
import LoadingComponent from '../component/loading.component'
import { store } from '../../redux/store';
import { setEmployeeDetails } from '../../redux/user/user.actions';
import "../css/employee.css"

const CardList = ({ employeeList, conditionForNoEmployeeDiv, loading, hidden, toggleDropDownHidden, message, severty, handleChecked, masterChecked }) => {


  var height = window.innerHeight - 250

  return (
    <div className='emp-list'>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={hidden}
        autoHideDuration={30000000}
        onClose={() => toggleDropDownHidden(false)}
      >
        <Alert onClose={() => toggleDropDownHidden(false)} severity={severty}>
          {message}
        </Alert>
      </Snackbar>
      <div className="row">


        {
          loading
            ?
            <LoadingComponent />
            :

            conditionForNoEmployeeDiv
              ?
              employeeList.map(employee => (
                <Card key={employee.emp_id} employee={employee} handleChecked={handleChecked} masterChecked={masterChecked} />
              ))
              :
              <div className="col-12">
                <div className="no-record">
                  <i></i>
                  <h3>{process.env.REACT_APP_Var_FOR_EMPLOYEE_No_Record_Available}</h3>
                </div>
              </div>
        }

      </div>
    </div>
  )
};

const mapStateToProps = ({ dropdown: { hidden }, message: { message, severty } }) => ({
  hidden,
  message, severty
});
const mapDispatchToProps = dispatch => ({
  toggleDropDownHidden: (data) => dispatch(toggleDropDownHidden(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
