import React, { useState, useEffect } from 'react';
import '../css/employee.css';
import '../../corporate/styles/corporate.css';
import ApiCallingService from '../../service/api-call-service';
import Can from '../../auth/can/Can';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { store } from '../../redux/store';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import moment from "moment";
import CategoryLimit from '../../constants/category-limit.type';
import ManageCategoryLimit from '../component/manage-category-limit.component';
import { setBackUrl, setEmployeeDetails } from '../../redux/user/user.actions';

import MangeCardUpdate from './manageCardUpdate';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

var currentUserT = '';
var ro_id = ''
const EmpMangeCardSection = ({ userRole, roleData, roMdn, authenticated, isPasswordReset, location, corpBand,
    empDetail, currentUser, mdn, fullName, maxFoodAmount, maxGeneralAmount, maxTravelAmount
}) => {
    ro_id = roMdn
    currentUserT = currentUser
    const [response, setResponse] = useState([]);
    const [employeeDetail, setEmployeeDetail] = useState([]);
    const [walletArray, setWalletArray] = useState([]);

    const [cardDetail, setCardDetail] = useState([]);

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [severty, setSeverty] = useState('');
    const [message, setMessage] = useState('');

    const [showCardSection, setshowCardSection] = useState(false);

    useEffect(() => {

        document.title = "ShakePe Employee Mange Card"
        console.log("Use effect of employee card manage beignf called again and again")
        fetchPosts();
        fetchEmployeeDetail();
    }, []);


    const fetchPosts = async () => {

        // const url = `${process.env.REACT_APP_GET_CARD_USAGE_MODE_BY_EMP_MDN}?customerId=8755144262/searchIndividualLimit/{customerId}/{roId}`;
        const url = `${process.env.REACT_APP_GET_CARD_USAGE_MODE_BY_EMP_MDN}?customerId=${location.state.empMobile}&roId=${roMdn}`;
        const headers = {
            customerId: location.state.empMobile
        }

        console.log(url);
        console.log(roMdn)
        const res = await ApiCallingService.getMethodCallWithoutParam(url, headers);

        console.log("REACT_APP_GET_CARD_USAGE_MODE_BY_EMP_MDN", url, JSON.stringify(res));
        if (res !== undefined && res.response !== undefined && res.response.individualLimit !== undefined && res.response.individualLimit !== null) {
            setResponse(res.response.individualLimit);
        }



    }

    const fetchEmployeeDetail = async () => {


        // const url = `${process.env.REACT_APP_GET_Employee_Detail_By_Mdn}?ro_Id=${roMdn}&mdn=6487609904`;

        const url = `${process.env.REACT_APP_GET_Employee_Detail_By_Mdn}?ro_Id=${roMdn}&mdn=${location.state.empMobile}`;

        const res = await ApiCallingService.getMethodCallWithoutParam(url);



        console.log("Employee Detail Response Fo $$%5556%^^^", res);

        if (res !== undefined && res.status !== undefined && parseInt(res.status) === 0 && res.response !== undefined && res.response.employeeDetails !== undefined) {

            var detail = res.response.employeeDetails;


            var dateOfBirth = moment(detail.dob).format("YYYY-MM-DD");
            detail.dob = dateOfBirth;
            var dateOfJoining = ''
            if (detail.date_of_joining !== undefined && detail.date_of_joining !== null) {
                dateOfJoining = detail.date_of_joining.split(' ');
                detail.date_of_joining = dateOfJoining[0]
            }
            var walletDetails = res.response.employeeDetails.wallet;
            var wallet = [];
            var arrayForGeneralAmount = [];
            var arrayForTravelAmount = [];
            var arrayForFoodAmount = [];

            arrayForGeneralAmount.push(maxGeneralAmount / 100);
            arrayForTravelAmount.push(maxTravelAmount / 100);
            arrayForFoodAmount.push(maxFoodAmount / 100);

            corpBand.map(band => {
                if (band.band === detail.band) {
                    arrayForGeneralAmount.push(band.general_Limit / 100);
                    arrayForTravelAmount.push(band.travel_Limit / 100);
                    arrayForFoodAmount.push(band.wallet_food_limit / 100);
                }
            })
            console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount ", arrayForGeneralAmount, arrayForTravelAmount, arrayForFoodAmount)

            walletDetails.map(limit => {
                if (limit.wallet_id === parseInt(CategoryLimit.GENERAL)) {
                    console.log('Inside maxFoodAmount')
                    arrayForGeneralAmount.push(limit.amount / 100);
                }
                if (limit.wallet_id === parseInt(CategoryLimit.TRAVEL)) {
                    arrayForTravelAmount.push(limit.amount / 100);
                }
                if (limit.wallet_id === parseInt(CategoryLimit.FOOD)) {
                    arrayForFoodAmount.push(limit.amount / 100);
                }
            })



            arrayForGeneralAmount = arrayForGeneralAmount.filter(function (val) {
                return val !== 0;
            });
            arrayForTravelAmount = arrayForTravelAmount.filter(function (val) {
                return val !== 0;
            });
            arrayForFoodAmount = arrayForFoodAmount.filter(function (val) {
                return val !== 0;
            });


            console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount before 0 remove", arrayForGeneralAmount, arrayForTravelAmount, arrayForFoodAmount)

            var maxGeneralAmount = Math.min(...arrayForGeneralAmount);
            var maxTravelAmount = Math.min(...arrayForTravelAmount);
            var maxFoodAmount = Math.min(...arrayForFoodAmount);

            console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount ", maxFoodAmount, maxTravelAmount, maxFoodAmount)
            walletDetails.map(wal => {
                var label = 'label';
                var max = 'max';

                if (wal.wallet_id === parseInt(CategoryLimit.GENERAL)) {
                    console.log(' wallet detail GENERAL', wallet)
                    wal[label] = 'General';
                    wal[max] = maxGeneralAmount;
                    wallet.push(wal);
                }
                if (wal.wallet_id === parseInt(CategoryLimit.FOOD)) {
                    console.log(' wallet detail FOOD', wallet)
                    wal[label] = 'Food';
                    wal[max] = maxFoodAmount;
                    wallet.push(wal);
                }
                if (wal.wallet_id === parseInt(CategoryLimit.TRAVEL)) {
                    console.log(' wallet detail TRAVEL', wallet)
                    wal[label] = 'Travel'
                    wal[max] = maxTravelAmount;
                    wallet.push(wal);
                }

            })


            setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...detail }));

            console.log('roleName', detail.roleName)

            setWalletArray(wallet);

            setCardDetail(res.response.employeeDetails.card)



            res.response.employeeDetails.card.map(ff => {
                if (ff.cardType !== undefined && ff.cardType !== null && ff.cardType !== "") {

                    setshowCardSection(true)
                }
            })

            //setRoleName(rol);


        } else {
        }




        // }
        // else {
        //     setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...null }));
        // }

    }


    const setEmployee = () => {
        store.dispatch(setEmployeeDetails(employeeDetail));
        store.dispatch(setBackUrl("/emp-list"))
    }


    const enableorDisableMode = async (event, mode) => {
        console.log("enableorDisableMode event.target.checked", event.target.checked)
        console.log("enableorDisableMode event.target.id", event.target.id)
        console.log("enableorDisableMode event.target.name", event.target.name)

        console.log("enableorDisableMode mode", mode)


        var status = process.env.REACT_APP_EMP_TXN_MODE_STATUS_TO_DISABLE
        var id = event.target.name;

        if (event.target.checked) {
            status = process.env.REACT_APP_EMP_TXN_MODE_STATUS_TO_ENABLE
        }


        var url = process.env.REACT_APP_ENABLE_OR_DISABLE_EMP_TXN_MODE
        var request = {
            "request": {
                "customerId": location.state.empMobile,
                "transaction_type_config_id": id,
                "status": status,
                "userLimit": mode.userLimit
            }
        }

        var response = await ApiCallingService.postMethodCall(url, request);

        console.log("enableorDisableMode response", response);

        if (response !== undefined && response.code !== undefined && parseInt(response.code) === 0) {
            setDropDownHidden(true);
            setMessage(process.env.REACT_APP_MESSAGE_FOR_SUCCESSFULY_RESET_MODE);
            setSeverty("success");
            fetchPosts();
        } else {
            setDropDownHidden(true);
            setMessage(response.description);
            setSeverty("error");
        }


    }



    return (

        console.log('inside dashboard before can :', { userRole }),
        console.log('inside dashboard before can :', { roleData }),
        <>
            {(authenticated)
                ? (
                    <Can
                        role={userRole}
                        perform="dashboard-page:visit"
                        roleData={roleData}
                        yes={() => (
                            <>

                                {isPasswordReset ?






                                    <div className="mid-container">

                                        <Snackbar

                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            open={dropDownHidden}
                                            autoHideDuration={20000}
                                            onClose={e => setDropDownHidden(false)}
                                        >
                                            <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                                {message}
                                            </Alert>
                                        </Snackbar>
                                        <div className="container-fluid">



                                            <div className="head">
                                                <div className="row">
                                                    <div className="col-11">
                                                        <h3>Manage Card - {employeeDetail.fullName} </h3>
                                                    </div>


                                                    <div className="col-1">
                                                        <div className="actnbtn">
                                                            <Link
                                                                to={{
                                                                    pathname: '/emp-detail',
                                                                    state: { employee: employeeDetail, editShowHidden: true }
                                                                }}
                                                                onClick={setEmployee}
                                                                data-toggle="tooltip" title="Back"

                                                            >
                                                                <i className="back"></i> </Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="addformcard">
                                                <div className="row">

                                                    <div className="col-12">
                                                        <h3 className="subhead">Category Limit</h3>
                                                    </div>
                                                    {employeeDetail.wallet !== undefined && employeeDetail.wallet !== null && employeeDetail.wallet.map(wallet =>
                                                        (<ManageCategoryLimit wallet={wallet.wallet_id} employee={employeeDetail} maxFoodAmount={maxFoodAmount} maxTravelAmount={maxTravelAmount} maxGeneralAmount={maxGeneralAmount} />)
                                                    )
                                                    }

                                                </div>
                                            </div>

                                            {
                                                showCardSection
                                                    ?
                                                    <div className="addformcard pb-3">

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h3 className="subhead">Card Status</h3>
                                                            </div>
                                                        </div>


                                                        {
                                                            cardDetail.map(cardDetails => (

                                                                <MangeCardUpdate cardDetails={cardDetails} employeeDetail={employeeDetail} />
                                                            ))
                                                        }




                                                    </div>
                                                    :
                                                    null


                                            }

                                            <div className="addformcard pb-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3 className="subhead">Card Usage</h3>
                                                    </div>


                                                    {
                                                        response.map(modeType => (
                                                            <div className="col-12 col-md-6 col-lg-4">

                                                                {/* <div className="usgslder">
                                                                    <h4>{modeType.transactionTypeConfigLabel}</h4>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" id={modeType.transactionTypeConfigLabel} name={modeType.transactionTypeConfigId} onChange={e => { enableorDisableMode(e, modeType) }} checked={modeType.status !== 0 && modeType.status !== 4 && modeType.status !== 3} disabled={modeType.status === 4} />
                                                                    </div>

                                                                    {
                                                                        modeType.status === 4
                                                                            ?
                                                                            <>

                                                                                <div className="usedisable">{process.env.REACT_APP_VAR_FOR_Disabled_by_Corporate_Admin}</div>

                                                                                <div className="use-limit">
                                                                                    <div className="progress">
                                                                                    </div>
                                                                                </div>


                                                                                <div className="user-limit">
                                                                                    <p className="float-left">₹0</p> <p className="float-right">₹{parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE)}</p>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>

                                                                                <div className="use-limit">
                                                                                    <div className="progress">
                                                                                        <div className="progress-bar bg-success" role="progressbar" style={{ "width": `${parseFloat(parseFloat(modeType.userLimit) / parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE))}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                                        </div>
                                                                                        <div className="setamt" style={{ "left": `${parseFloat(parseFloat(modeType.userLimit) / parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE))}%` }}>₹ {parseFloat(parseFloat(modeType.userLimit) / 100)}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="user-limit">
                                                                                    <p className="float-left">₹0</p> <p className="float-right">₹{parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE)}</p>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div> */}
                                                                <div className="managebox">
                                                                    <div className="use-control">

                                                                        <h6>{modeType.transactionTypeConfigLabel}</h6>

                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" type="checkbox" id={modeType.transactionTypeConfigLabel} name={modeType.transactionTypeConfigId} onChange={e => { enableorDisableMode(e, modeType) }} checked={modeType.status !== 0 && modeType.status !== 4 && modeType.status !== 3} disabled={modeType.status === 4} />
                                                                        </div>

                                                                    </div>

                                                                    {
                                                                        modeType.status === 4
                                                                            ?
                                                                            <>

                                                                                <div className="usedisable">{process.env.REACT_APP_VAR_FOR_Disabled_by_Corporate_Admin}</div>

                                                                                <div className="use-limit">
                                                                                    <div className="progress">
                                                                                    </div>
                                                                                </div>


                                                                                <div className="user-limit">
                                                                                    <p className="float-left">₹0</p> <p className="float-right">₹{parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE)}</p>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>

                                                                                <div className="use-limit">
                                                                                    <div className="progress">
                                                                                        <div className="progress-bar bg-success" role="progressbar" style={{ "width": `${parseFloat(parseFloat(modeType.userLimit) / parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE))}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                                        </div>
                                                                                        <div className="setamt" style={{ "left": `${parseFloat(parseFloat(modeType.userLimit) / parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE))}%` }}>₹ {parseFloat(parseFloat(modeType.userLimit) / 100)}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="user-limit">
                                                                                    <p className="float-left">₹0</p> <p className="float-right">₹{parseFloat(process.env.REACT_APP_VAR_FOR_HIGHER_LIMIT_FOR_TXN_MODE_IN_RUPEE)}</p>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>



                                                            </div>
                                                        ))


                                                    }



                                                </div>
                                            </div>


                                        </div>
                                    </div>




                                    :
                                    <ResetLoginPassword />
                                }
                            </>
                        )}
                        no={() =>


                            null



                        }
                    />
                )
                : (
                    <Redirect to='/' />
                )



            }
        </>
    )
}



const mapStateToProps = ({ user: { userRole, roMdn, empDetail, currentUser, mdn, fullName, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount }, role: { roleData }, token: { authenticated, isPasswordReset }, band: { bandData } }) => ({
    userRole,
    roMdn,
    roleData,
    authenticated, isPasswordReset, bandData, empDetail, currentUser, mdn, fullName, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount
});


export default connect(mapStateToProps)(EmpMangeCardSection);
