import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import CardStatusType from '../../../constants/card-status.type';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import image1 from '../../../assests/no-image.png'
import { resetOder } from "../../../redux/order/order.action";
import Avatar from 'react-avatar';
import CreatableSelect from 'react-select/creatable';
import GenerateRequestId from "../../../common-function/GenerateRequestId";
import history from '../../../auth/history';
// var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
import CardStatus from '../../../constants/card-status.type';
import AsyncSelect from 'react-select/async';

import img from '../../../assests/icons/arrow-down.svg'
import { setAvailableCard, setReissueMdn } from "../../../redux/card/card.action";
class AvailableCards extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cardReferenceNumber: '',
            cardType: '',
            employeeList: [],
            searchField: '',
            selectedEmployee: [],
            searchBlock: false,
            empBlock: false,
            dropDownHidden: '',
            message: '',
            severty: '',
            cardIdentifier: "newCard",
            notAssignedEmployee: [],
            blockedEmployee: [],
            addOnEmployee: [],
            fullSearchVar: false,
            virtualCardReference: GenerateRequestId.generateCardNumber(),

            option: [],

            lastFourDigit: '',

            assignedCardProcessBar: false,

            oldKitNumber: ''

        };
    }




    componentDidMount = () => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
        store.dispatch(setAvailableCard([]))
        this.getCardAvailAll();
        var query = getQueryVariable('to')
        var query2 = '';

        console.log("query2 value", query2, this.props.ressuMDN)
        if (this.props.ressuMDN !== undefined && this.props.ressuMDN !== undefined && this.props.ressuMDN !== undefined) {
            query2 = this.props.ressuMDN;
        }


        function getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }

        if (query === 'reIssue') {

            this.setState({ cardIdentifier: "reAssignedCard" })
            this.setState({ searchField: (query2) })
            // this.setState({ fullSearchVar: false })
            // this.setState({ searchBlock: true })

        } else {
            store.dispatch(setReissueMdn(''))
        }


        store.dispatch(resetOder)
        const getDetail = async () => {

            var url = `${process.env.REACT_APP_GET_EMPLOYEE_INACTIVE_CARD_STATUS}?ro_Id=${this.props.roMdn}`

            var response = await apiCallService.getMethodCallWithoutParam(url);

            console.log('response history transaction employeeList notAssignedEmployee', url, response)

            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined) {

                this.setState({ notAssignedEmployee: response.response.employeeDetails })

                if (query === 'reIssue') {
                    // this.setState({ employeeList: response.response.employeeDetails })
                } else {
                    this.setState({ employeeList: response.response.employeeDetails })
                }


                console.log('response history transaction employeeList', response.employeeList, url);

            }
        }


        const getStatusBaseEmployee = async () => {

            var status = [];
            status.push(CardStatus.PERMANENT_BLOCK)
            var url = `${process.env.REACT_APP_GET_EMPLOYEE_LIST_BY_BLOCKED_CARD_STATUS_AND_ACTIVE_KYC}?ro_Id=${this.props.roMdn}&status=${status}`
            var response = await apiCallService.getMethodCallWithoutParam(url);

            console.log('response history transaction employeeList blockedEmployee', url, response)

            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null) {

                this.setState({ blockedEmployee: response.response.employeeDetails })

                if (query === 'reIssue') {
                    this.setState({ employeeList: response.response.employeeDetails })
                }
                response.response.employeeDetails.map(bE => {
                    if (bE.mdn === query2 && query === 'reIssue') {
                        this.setState({ selectedEmployee: bE })
                        this.setState({ empBlock: true })
                    }
                })

                console.log('response history transaction employeeList blockedEmployee', response.employeeList, url);

            }
        }


        const getTempAndActiveCardStatusEmployeeEmployee = async () => {

            var status = [];
            status.push(CardStatus.TEMPORARY_BLOCK)
            status.push(CardStatus.ACTIVE)
            var url = `${process.env.REACT_APP_GET_EMPLOYEE_LIST_BY_BLOCKED_CARD_STATUS_AND_ACTIVE_KYC}?ro_Id=${this.props.roMdn}&status=${status}`
            var response = await apiCallService.getMethodCallWithoutParam(url);

            console.log('response history transaction employeeList addOnEmployee', url, response)

            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null) {

                this.setState({ addOnEmployee: response.response.employeeDetails })

                if (query === 'addOn') {
                    this.setState({ employeeList: response.response.employeeDetails })
                }
                response.response.employeeDetails.map(bE => {
                    if (bE.mdn === query2 && query === 'addOn') {
                        this.setState({ selectedEmployee: bE })
                        this.setState({ empBlock: true })
                    }
                })

                console.log('response history transaction employeeList blockedEmployee', response.employeeList, url);

            }
        }


        getStatusBaseEmployee();
        getDetail();

        getTempAndActiveCardStatusEmployeeEmployee();




    }



    getCardAvailAll = async (searchKeyword) => {
        var cardUrl = `${process.env.REACT_APP_GET_AVAILABLE_CARD}`

        var request = {
            "roId": this.props.roMdn,
            "size": process.env.REACT_APP_NUMBER_OF_CARD_IN_DROPDOWN_PER_TYPE,
            "searchKeyword": searchKeyword
        }
        var cardResponse = await apiCallService.postMethodCall(cardUrl, request);


        var opt = [];
        this.setState({ option: opt })

        console.log('cardResponse %%%%%%', cardResponse, cardUrl)

        if (cardResponse !== undefined && cardResponse.status !== undefined && cardResponse.status === 0 && cardResponse.response !== undefined) {
            if (cardResponse.response === null) {
                store.dispatch(setAvailableCard([]))

                this.setState({ option: opt })
            } else if (cardResponse.response !== null) {


                if (cardResponse.response.virtualCardList !== undefined && cardResponse.response.virtualCardList !== null && cardResponse.response.virtualCardList.length > 0) {
                    var value = { "label": "Virtual Cards", "value": "Virtual Cards", isDisabled: true }
                    opt.push(value)
                }
                cardResponse.response.virtualCardList !== undefined && cardResponse.response.virtualCardList !== null && cardResponse.response.virtualCardList.map(car => {
                    var label = "label"
                    car[label] = car.cardReferenceNumber
                    var value = "value"
                    car[value] = car.cardReferenceNumber
                    opt.push(car);
                })
                console.log("Documentation mai nhi krunga ", opt);



                if (cardResponse.response.physicalCardList !== undefined && cardResponse.response.physicalCardList !== null && cardResponse.response.physicalCardList.length > 0) {
                    var value2 = { "label": "Physical Cards", "value": "Physical Cards", color: 'red', isDisabled: true }
                    opt.push(value2)
                }
                cardResponse.response.physicalCardList !== undefined && cardResponse.response.physicalCardList !== null && cardResponse.response.physicalCardList.map(car => {
                    var label = "label"
                    car[label] = car.cardReferenceNumber
                    var value = "value"
                    car[value] = car.cardReferenceNumber
                    opt.push(car);
                })
                console.log("Documentation mai nhi krunga ", opt);
                store.dispatch(setAvailableCard(cardResponse.response.getCardDetails))
                this.setState({ option: opt })
                console.log("this.state.option Documentation mai nhi krunga ", this.state.option);
            }
        } else {

            this.setState({ option: opt })
        }
        // this.setState({ option: opt })
    }

    handleSearchChange = (event) => {
        this.setState({ fullSearchVar: false })
        this.setState({ searchField: event.target.value })
        this.setState({ searchBlock: true })
    }

    handleShowSearchBlock = (event) => {
        this.setState({ searchBlock: true })
    }

    handleHideSearchBlock = (event) => {
        // this.setState({ searchBlock: false })
    }

    // handleCardAssignedChange = (event) => {
    //     console.log('event.target.value', event.target.value)
    //     if (event.target.value !== "DEFAULT") {
    //         this.setState({ cardReferenceNumber: event.target.value })
    //     }
    //     if (event.target.value === "DEFAULT") {
    //         this.setState({ cardReferenceNumber: '' })
    //     }

    // }

    handleCardAssignedChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        // const inputValue = newValue.replace(/\W/g, '');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {

            this.setState({ cardReferenceNumber: newValue.value });
            this.setState({ lastFourDigit: newValue.lastFourDigit });
            this.setState({ cardType: newValue.cardType })

        } else {
            this.setState({ cardReferenceNumber: '' });
            this.setState({ lastFourDigit: '' })
            this.setState({ cardType: '' })

        }
    };

    handleCardAssignedInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed handleInputChange');
        console.log(inputValue);
        this.getCardAvailAll(inputValue);
        console.log(`action handleInputChange: ${actionMeta.action}`);
        console.groupEnd();
    };

    showEmpBlock = (value, event) => {
        if (value === undefined) { } else {
            this.setState({ selectedEmployee: value })
            this.setState({ empBlock: true })
            this.setState({ fullSearchVar: true })
            this.setState({ searchBlock: false })
            // setEmpBlock(true);
            console.log('record', value)
        }
    }


    handleAssignedCard = async (event) => {
        var employeeDetail = this.state.selectedEmployee;

        console.log("selectedEmployee", this.state.selectedEmployee)

        if (employeeDetail.email == null || employeeDetail.email === "") {
            return (
                this.setState({ dropDownHidden: true }),
                this.setState({ severty: 'warning' }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_EMAIL_REQUIRED_WHILE_MAIL_SEND_TO_USER })
            )
        } else

            if (this.state.cardReferenceNumber === 'Select Card' || this.state.cardReferenceNumber === '') {

                return (
                    this.setState({ dropDownHidden: true }),
                    this.setState({ severty: 'warning' }),
                    this.setState({ message: "Please Select Card" })
                )
            } else


                if (this.state.selectedEmployee === null || this.state.selectedEmployee.length === 0) {

                    return (
                        this.setState({ dropDownHidden: true }),
                        this.setState({ severty: 'warning' }),
                        this.setState({ message: "Please Select Employee." })
                    )
                }
                else {


                    var oldKitNumberValue = '';
                    this.state.selectedEmployee.card.map(data => {
                        if (data.cardStatus === CardStatus.PERMANENT_BLOCK) {
                            oldKitNumberValue = data.cardReferenceNo;
                        }
                    })

                    this.setState({ assignedCardProcessBar: true });


                    var request = {
                        "roId": this.props.roMdn,
                        "cardRefNumber": this.state.cardReferenceNumber,
                        "employeeId": employeeDetail.emp_id,
                        "employeeName": employeeDetail.fullName,
                        "mdn": employeeDetail.mdn,
                        "updatedBy": this.props.fullName,
                        "updatedByRoleName": this.props.userRole,
                        "status": CardStatusType.ASSIGNED,
                        "message": "Assigned Card",
                        "imageUrl": employeeDetail.imageUrl,
                        "band": employeeDetail.band,
                        // "kycStatus": employeeDetail.kyc_status,
                        "email": employeeDetail.email,
                        "firstName": employeeDetail.first_name,
                        "lastName": employeeDetail.last_name,
                        "dob": employeeDetail.dob,
                        "gender": employeeDetail.gender,
                        "pan": employeeDetail.pan,
                        "pincode": employeeDetail.pincode,
                        "managerEmpCorpId": employeeDetail.manager_emp_corp_id,
                        "financeEmpCorpId": employeeDetail.finance_emp_corp_id,
                        "department": employeeDetail.department,
                        "location": employeeDetail.location,
                        "roleName": employeeDetail.roleName,
                        "uid": employeeDetail.aadhar_no,
                        "employeeStatus": 0,
                        "lastFourDigit": this.state.lastFourDigit,
                        "cardType": this.state.cardType,
                        "oldKitNumber": oldKitNumberValue,
                        "updatedByEmail": this.props.currentUser
                        // "kycExpireDate": employeeDetail.kycExpireDate
                    }



                    if (this.state.cardIdentifier === "newCard") {

                        var url = process.env.REACT_APP_UPDATE_EMPLOYEE_CARD_STATUS_WITH_CORP_CARD


                        var response = await apiCallService.postMethodCall(url, request);

                        console.log('handleAssignedCard response', request, " : ", response)

                        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

                            // this.setState({ dropDownHidden: true })
                            this.setState({ severty: 'success' })
                            // this.setState({ message: ' Detail updated successfully.' })


                            this.setState({ virtualCardReference: GenerateRequestId.generateCardNumber() });
                            document.getElementById("cardAssigneMessage").click();

                            this.setState({ assignedCardProcessBar: false });

                        } else {
                            this.setState({ assignedCardProcessBar: false });
                            this.setState({ dropDownHidden: true })
                            this.setState({ severty: 'error' })
                            this.setState({ message: response.response_description })
                        }


                    }

                    if (this.state.cardIdentifier === "reAssignedCard") {


                        var url = process.env.REACT_APP_OEMS_USER_REASSIGNED_CARD;
                        console.log('handleAssignedCard response', JSON.stringify(request))

                        var response = await apiCallService.postMethodCall(url, request);

                        console.log('handleAssignedCard response', request, " : ", response)

                        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

                            this.setState({ dropDownHidden: true })
                            this.setState({ severty: 'success' })
                            this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_SUCCESSFULLY_REISSUE_CARD })


                            this.setState({
                                cardReferenceNumber: '',
                                employeeList: [],
                                searchField: '',
                                selectedEmployee: [],
                                searchBlock: false,
                                empBlock: false,

                                cardIdentifier: "newCard",
                                notAssignedEmployee: [],
                                blockedEmployee: [],
                                fullSearchVar: false,
                                virtualCardReference: GenerateRequestId.generateCardNumber(),

                                option: [],

                                lastFourDigit: '',

                                assignedCardProcessBar: false
                            })

                            this.componentDidMount();

                            history.push('/manage-card')
                            history.go();



                        } else {
                            this.setState({ assignedCardProcessBar: false });
                            this.setState({ dropDownHidden: true })
                            this.setState({ severty: 'error' })
                            this.setState({ message: response.response_description })
                        }



                    }

                    if (this.state.cardIdentifier === "addOnCard") {


                        var url = process.env.REACT_APP_OEMS_USER_ADD_ON_Card_CARD;
                        console.log('handleAssignedCard response', JSON.stringify(request))

                        var response = await apiCallService.postMethodCall(url, request);

                        console.log('handleAssignedCard response', request, " : ", response)

                        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

                            this.setState({ dropDownHidden: true })
                            this.setState({ severty: 'success' })
                            this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_SUCCESSFULLY_ADD_ON_CARD })


                            this.setState({
                                cardReferenceNumber: '',
                                employeeList: [],
                                searchField: '',
                                selectedEmployee: [],
                                searchBlock: false,
                                empBlock: false,

                                cardIdentifier: "newCard",
                                notAssignedEmployee: [],
                                blockedEmployee: [],
                                fullSearchVar: false,
                                virtualCardReference: GenerateRequestId.generateCardNumber(),

                                option: [],

                                lastFourDigit: '',

                                assignedCardProcessBar: false
                            })

                            this.componentDidMount();

                            history.push('/manage-card')
                            history.go();



                        } else {
                            this.setState({ assignedCardProcessBar: false });
                            this.setState({ dropDownHidden: true })
                            this.setState({ severty: 'error' })
                            this.setState({ message: response.response_description })
                        }



                    }

                }
    }

    handleInputCheckBox = (event) => {
        console.log('event triggered', event.target.name)
        console.log(event.target.getAttribute("name"));
        this.setState({ cardIdentifier: event.target.getAttribute("name") })
        this.setState({ searchField: '' })
        this.setState({ selectedEmployee: [] })
        this.setState({ empBlock: false })
        if (event.target.getAttribute("name") === "newCard") {
            this.setState({ employeeList: this.state.notAssignedEmployee })
        } else if (event.target.getAttribute("name") === "reAssignedCard") {
            this.setState({ employeeList: this.state.blockedEmployee })
        }

    }

    handleInputCheckBoxReAssigned = (event) => {
        console.log('event triggered', event.target.name)
        console.log(event.target.getAttribute("name"));
        this.setState({ cardIdentifier: event.target.getAttribute("name") })
        this.setState({ searchField: '' })
        this.setState({ selectedEmployee: [] })
        this.setState({ empBlock: false })
        // if (event.target.getAttribute("name") === "newCard") {
        //     this.setState({ employeeList: this.state.notAssignedEmployee })
        // } else if (event.target.getAttribute("name") === "reAssignedCard") {
        this.setState({ employeeList: this.state.blockedEmployee })
        // }

    }


    handleInputCheckBoxAddOnCard = (event) => {
        console.log('event triggered', event.target.name)
        console.log(event.target.getAttribute("name"));
        this.setState({ cardIdentifier: event.target.getAttribute("name") })
        this.setState({ searchField: '' })
        this.setState({ selectedEmployee: [] })
        this.setState({ empBlock: false })
        // if (event.target.getAttribute("name") === "newCard") {
        //     this.setState({ employeeList: this.state.notAssignedEmployee })
        // } else if (event.target.getAttribute("name") === "reAssignedCard") {
        this.setState({ employeeList: this.state.addOnEmployee })
        // }

    }


    handleInputCheckBoxNewCard = (event) => {
        console.log('event triggered', event.target.name)
        console.log(event.target.getAttribute("name"));
        this.setState({ cardIdentifier: event.target.getAttribute("name") })
        this.setState({ searchField: '' })
        this.setState({ selectedEmployee: [] })
        this.setState({ empBlock: false })
        // if (event.target.getAttribute("name") === "newCard") {
        this.setState({ employeeList: this.state.notAssignedEmployee })
        // } else if (event.target.getAttribute("name") === "reAssignedCard") {
        //     this.setState({ employeeList: this.state.blockedEmployee })
        // }

    }



    filterColors = (inputValue) => {
        console.log("this.state.option ", this.state.option)
        return this.state.option !== undefined && this.state.option.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterColors(inputValue));
        }, 3000);
    };

    setToKyc = () => {
        history.push({
            pathname: '/user-kyc',
            state: { post: { "cardRefNumber": this.state.cardReferenceNumber, "employeeName": this.state.selectedEmployee.fullName, "className": "card-inactive", "statusVariable": "Card Inactive" }, employee: this.state.selectedEmployee }
        })
        history.go()
    }

    render() {

        var searchedRecord = [];


        var searchedBy = ""

        if (this.state.searchField !== '' && this.state.employeeList !== undefined) {
            this.state.employeeList.map(post => {
                if (post.first_name !== undefined && post.first_name !== null && post.first_name.toLowerCase().includes(this.state.searchField.toLowerCase())) {
                    searchedRecord.push(post)
                    searchedBy = "fullName"
                } else if (post.last_name !== undefined && post.last_name !== null && post.last_name.toLowerCase().includes(this.state.searchField.toLowerCase())) {
                    searchedRecord.push(post)
                    searchedBy = "fullName"
                } else if (post.emp_id !== undefined && post.emp_id !== null && post.emp_id.includes(this.state.searchField)) {
                    searchedRecord.push(post)
                    searchedBy = "emp_id"
                } else if (post.mdn !== undefined && post.mdn !== null && post.mdn.includes(this.state.searchField)) {
                    searchedRecord.push(post)
                    searchedBy = "mdn"
                } else if (post.fullName !== undefined && post.fullName !== null && post.fullName.toLowerCase().includes(this.state.searchField.toLowerCase())) {
                    searchedRecord.push(post)
                    searchedBy = "fullName"
                }
            })
        }

        var searchVar = this.state.searchField

        console.log('this.state.empBlock', this.state.fullSearchVar)
        if (this.state.fullSearchVar) {

            if (searchedBy === "fullName") {
                searchVar = this.state.selectedEmployee.fullName
            } else if (searchedBy === "emp_id") {
                searchVar = this.state.selectedEmployee.emp_id
            } else if (searchedBy === "mdn") {
                searchVar = this.state.selectedEmployee.mdn
            }
        }




        console.log(searchedRecord, this.state.searchField, 'searchedRecord');

        console.log("current post", this.state.employeeList);


        const colourStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: 'white',
                border: "none",
                borderBottom: "1px solid #e0e0e0",
                borderRadius: "0px",
                boxShadow: "none",
                backgroundRepeat: "no-repeat",
                backgroundSize: "12px",
                backgroundPosition: "center right",

                "&:hover": {
                    borderBottom: "2px solid #3f51b5",
                    boxShadow: "none"
                },
                "&:focus": {
                    borderBottom: "2px solid #3f51b5",
                    boxShadow: "none"
                }
            }),
            valueContainer: styles => ({
                ...styles,
                padding: "0px 5px 0px 0px"
            }),
            menu: styles => ({
                ...styles,
                zIndex: "9999"
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //   const color = chroma(data.color);
                return {
                    ...styles,
                };
            },
        };

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'white',
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                minHeight: "22px",
                lineHeight: "22px",
                background: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "12px",
                backgroundPosition: "center right",
                marginTop: "-7px"

            }),

            valueContainer: (provided, state) => ({
                ...provided,
                padding: "0px 5px 0px 0px"
            }),

            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: "5px",
                margin: "100px"
            }),
            option: (provided, state) => ({
                ...provided,
                //   borderBottom: '1px dotted pink',
                backgroundColor: state.isDisabled ? '#2684FF' : state.isSelected ? '#deebff' : '',
                color: state.isSelected ? '#354558' : state.isDisabled ? "#ffffff" : '',
                fontSize: state.isDisabled ? "16px" : "14px",
                padding: "8px",
                cursor: state.isDisabled ? '' : state.isSelected ? '' : "pointer",
                "&:hover": {
                    backgroundColor: "#deebff",
                    color: "354558"
                },


            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: '#354558',
                fontSize: "15px",
                cursor: "pointer"
            }
            ),
            placeholder: (provided, state) => ({
                ...provided,
                color: 'hsl(0,0%,80%)',
                fontSize: "16px",
            })
        }



        console.log("this.state", this.state)

        return (
            <div >
                {(this.props.authenticated)
                    ? (
                        <div className="cardorderform">
                            <div className="row">
                                <div className="col-12">
                                    <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Available_and_Assign_Card}</h3>
                                </div>

                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={this.state.dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => this.setState({ dropDownHidden: false })}
                                >
                                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                        {this.state.message}
                                    </Alert>
                                </Snackbar>



                                <div className="col-12 ">
                                    <div className="form-check form-check-inline">
                                        <input type="radio" id="newCard" className="form-check-input" name="newCard" value={this.state.cardIdentifier} checked={this.state.cardIdentifier === 'newCard'} onClick={this.handleInputCheckBoxNewCard} />
                                        <label className="form-check-label" for="newCard" >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Available_and_Assign_Card_New_Card}
                                        </label>
                                    </div>



                                    <div className="form-check form-check-inline">
                                        <input type="radio" id="reAssignedCard" className="form-check-input" name="reAssignedCard" value={this.state.cardIdentifier} checked={this.state.cardIdentifier === 'reAssignedCard'} onClick={this.handleInputCheckBoxReAssigned} />
                                        <label className="form-check-label" for="reAssignedCard" >

                                            {process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Available_and_Assign_Card_Re_assigned_Card}
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input type="radio" id="addOnCard" className="form-check-input" name="addOnCard" value={this.state.cardIdentifier} checked={this.state.cardIdentifier === 'addOnCard'} onClick={this.handleInputCheckBoxAddOnCard} />

                                        <label className={(this.state.cardIdentifier === 'addOnCard') ? "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded is-checked" : "mdl-radio mdl-js-radio mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded"} for="addOnCard" >
                                            {process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Available_and_ADD_On_Card}
                                        </label>
                                    </div>

                                </div>



                                <div className="col-12 col-lg-6 mt-4">
                                    <div className="formselect">
                                        <label htmlFor="cars">{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Available_and_Assign_Card_Available_Cards}</label>

                                        <AsyncSelect
                                            placeholder="Select Card"
                                            cacheOptions
                                            loadOptions={this.loadOptions}
                                            defaultOptions
                                            onChange={this.handleCardAssignedChange}
                                            onInputChange={this.handleCardAssignedInputChange}
                                            styles={customStyles}
                                            components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}

                                        />
                                    </div>
                                </div>



                                <div className="col-12 col-lg-6">
                                    <div className="searchrobox">


                                        <input type="text" className="form-control" placeholder="Search employee..." id="searchemp1" value={searchVar} onChange={this.handleSearchChange} onBlur={this.handleHideSearchBlock} />


                                        {this.state.searchBlock && searchedRecord.length !== 0
                                            ?
                                            <div className="empsearchlist" id="showemp1">

                                                {searchedRecord.map(record => (
                                                    <div className="emprofile" onClick={() => this.showEmpBlock(record)}>
                                                        <Avatar src={record.imageurl} color={Avatar.getRandomColor(record.mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={record.emp_id} name={record.fullName} size="48px" round={true} />

                                                        <h4>{record.fullName} - {record.emp_id}</h4>
                                                        <p>{record.mdn}</p>
                                                    </div>
                                                ))

                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>

                                {this.state.empBlock
                                    ?

                                    <div className="col-12">
                                        <div className="empdetails">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Available_and_Assign_Card_Employee_Details}</h2>
                                                </div>

                                                <div className="col-12 col-lg-4">
                                                    <div className="empview">
                                                        <Avatar src={this.state.selectedEmployee.imageUrl} color={Avatar.getRandomColor(this.state.selectedEmployee.mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={this.state.selectedEmployee.mdn} name={this.state.selectedEmployee.fullName} size="44px" round={true} />

                                                        <h4>{this.state.selectedEmployee.fullName === undefined || this.state.selectedEmployee.fullName === null || this.state.selectedEmployee.fullName === "" ? <>NA</> : this.state.selectedEmployee.fullName}</h4>
                                                        <p>{this.state.selectedEmployee.mdn === undefined || this.state.selectedEmployee.mdn === null || this.state.selectedEmployee.mdn === "" ? <>NA</> : this.state.selectedEmployee.mdn}</p>
                                                    </div>

                                                </div>

                                                <div className="col-12 col-lg-4">
                                                    <div className="empview">
                                                        <p>PAN: {this.state.selectedEmployee.pan === undefined || this.state.selectedEmployee.pan === null || this.state.selectedEmployee.pan === "" ? <>NA</> : this.state.selectedEmployee.pan}</p>
                                                        <p>Aadhaar: {this.state.selectedEmployee.aadhar_no === undefined || this.state.selectedEmployee.aadhar_no === null || this.state.selectedEmployee.aadhar_no === "" ? <>NA</> : this.state.selectedEmployee.aadhar_no}</p>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4">
                                                    <div className="empview">
                                                        <p>Emp ID: {this.state.selectedEmployee.emp_id === undefined || this.state.selectedEmployee.emp_id === null || this.state.selectedEmployee.emp_id === "" ? <>NA</> : this.state.selectedEmployee.emp_id}</p>
                                                        <p>Email: {this.state.selectedEmployee.email === undefined || this.state.selectedEmployee.email === null || this.state.selectedEmployee.email === "" ? <>NA</> : this.state.selectedEmployee.email}</p>
                                                    </div>
                                                </div>


                                                <div className="col-12 col-lg-4">
                                                    <div className="empview">
                                                        <p>Date of Birth: {this.state.selectedEmployee.dob === undefined || this.state.selectedEmployee.dob === null || this.state.selectedEmployee.dob === "" ? <>NA</> : this.state.selectedEmployee.dob}</p>
                                                        <p>Location: {this.state.selectedEmployee.location === undefined || this.state.selectedEmployee.location === null || this.state.selectedEmployee.location === "" ? <>NA</> : this.state.selectedEmployee.location}</p>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4">
                                                    <div className="empview">
                                                        <p>Band: {this.state.selectedEmployee.band === undefined || this.state.selectedEmployee.band === null || this.state.selectedEmployee.band === "" ? <>NA</> : this.state.selectedEmployee.band}</p>
                                                        <p>Date of Join: {this.state.selectedEmployee.date_of_joining === undefined || this.state.selectedEmployee.date_of_joining === null || this.state.selectedEmployee.date_of_joining === "" ? <>NA</> : this.state.selectedEmployee.date_of_joining}</p>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4">
                                                    <div className="empview">
                                                        <p>Department: {this.state.selectedEmployee.department === undefined || this.state.selectedEmployee.department === null || this.state.selectedEmployee.department === "" ? <>NA</> : this.state.selectedEmployee.department}</p>
                                                        <p>Reporting Manager: {this.state.selectedEmployee.manager_emp_corp_id === undefined || this.state.selectedEmployee.manager_emp_corp_id === null || this.state.selectedEmployee.manager_emp_corp_id === "" ? <>NA</> : this.state.selectedEmployee.manager_emp_corp_id}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }

                                <div className="col-12 mt-4">
                                    {this.state.severty === "success"
                                        ?
                                        <input type="submit" value="Assign" id="cardAssigneMessage" className="lbtn btnbl" data-bs-toggle="modal" data-bs-target="#assign" disabled={this.state.selectedEmployee.length === 0} />

                                        :
                                        <button type="button" value="Assign" className="lbtn btnbl" disabled={this.state.assignedCardProcessBar || this.state.selectedEmployee.length === 0} onClick={this.handleAssignedCard} >
                                            {
                                                this.state.assignedCardProcessBar
                                                    ?
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                    </div>
                                                    :
                                                    <>Assign</>
                                            }
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="modal fade" id="assign" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content alerttext">
                                        <div className="modal-header">
                                            <h5 id="">Card Assign</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="asign-success">{process.env.REACT_APP_AVAILABLE_CARD_POPUP_VAR_FOR_Card_has_been_assigned_successfully}</div>
                                        </div>
                                        <div className="modal-footer">
                                            <a className="mbtn btngr" href="/assigned-cards">No</a>
                                            <button className="mbtn btnbl" onClick={this.setToKyc}>Yes</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </div>

        )
    }

}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, fullName, currentUser }, role: { roleData }, card: { availableCard, ressuMDN } }) => ({
    authenticated, userRole, roleData, availableCard, roMdn, fullName, ressuMDN, currentUser
});

export default connect(mapStateToProps)(AvailableCards);