import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import otpImg from '../../assests/otp-img.png'

import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';

import shakePeLogo from '../../assests/shakepe-logo.png'

class ResetLoginPassword extends React.Component {

    constructor() {
        super();

        this.state = {
            confirmPassword: "",
            password: "",
            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            message: "",
            severty: "success",
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,
            submitButton: true

        };
    }


    onclick = (event) => {
        event.preventDefault();
        console.log('button clicked')
        history.push("/")
        history.go();
    }




    render() {

        console.log("States here", this.state);
        var zeroOne = this.props.mdn.substring(0, 1);
        var sevenNine = this.props.mdn.substring(8, 10);
        return (

            // <div className="login-container">
            //     <div className="container-fluid">
            //         <div className="row flex-row-reverse">
            //             <div className="col-12 col-md-6 col-lg-5 col-xl-4 frmbg">
            //                 <div className="login-form">
            //                     <div className="logo"><img src={corpImg} alt="ZRO" className="img-fluid" /></div>
            //                     <h4>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Password_Reset_Successfully}</h4>
            //                     <p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_You_have_successfully_reset_your_password_Please_use_your_new_password_when_logging_in}</p>


            //                     <div className="form-row">
            //                         <div className="col-12 col-sm-6">
            //                             <button id="" className="logbtn" onClick={this.onclick}>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Login_Button}</button>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>

            //             <div className="col-12 col-md-6 col-lg-7 col-xl-8 logbg">
            //                 <div className="forgot-box">
            //                     <img src={otpImg} alt="OTP" className="img-fluid" />
            //                 </div>
            //             </div>

            //         </div>
            //     </div>
            // </div>


            <div  className="log-container">
                <div  className="container">
                    <div  className="row justify-content-sm-center">
                        <div  className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div  className="logbox">
                                <div  className="logtxt">
                                    <img src={shakePeLogo} alt="ShakePe" className="img-fluid" />
                                    <h3>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Password_Reset_Successfully}</h3>
                                    <p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_You_have_successfully_reset_your_password_Please_use_your_new_password_when_logging_in}</p>
                                </div>



                                <div  className="login-form">

                                    <div  className="form-row">

                                        <a href="/" id="" className="lbtn btnbl"  >{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Back_to_login}</a>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(ResetLoginPassword); 