import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import forgotImg from '../../assests/forgot-password.png'
import { ReCaptcha } from 'react-recaptcha-google'
import apiCallService from '../../service/api-call-service';
import { loadReCaptcha } from 'react-recaptcha-google'
import { store } from '../../redux/store';
import { setEmail, setRefernceNumber } from '../../redux/forgot-password/forgot-password.action';
import history from '../../auth/history';

import ErrorBoundary from '../../login_sha/zero-login-pages/ErrorBoundary';
import { setAdminViewType } from '../../redux/adminView/adminView.action';

class ForgotPassword extends React.Component {

    constructor() {
        super();

        this.state = {


            email: "",


            message: '',

            captchaDone: false,

            confirmPassword: "",
            password: "",
            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            message: "",
            severty: "success",
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,
            submitButton: true,
            isValidConfirm: false,
            loading: false

        };
    }


    componentDidMount() {
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }
        loadReCaptcha();

        if (this.props.rememberMe) {
            this.setState({ email: this.props.rememberMail });
            this.setState({ password: this.props.rememberPassword });
        }
    }

    onLoadRecaptcha = () => {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
        console.log('Testing-onLoadRecaptcha :' + this.state)
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({ captchaDone: true });
        console.log(recaptchaToken, "<= your recaptcha token")
    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            message: ''
        })
        console.log('Testing-handleEmailChange :' + this.state)
    }

    generateOTP = async (event) => {
        event.preventDefault();

        if (event.target[2] !== undefined) {
            var captchaValue = event.target[2].value;
        }

        console.log('event target value ', event.target[2], event.target[2], event.target[3])

        console.log('inside initiateLogin successfully', captchaValue);
        if (process.env.REACT_APP_CAPTCHA_CONFIG !== '1') {
            if (captchaValue === 'false') {
                console.log('inside initiateLogin successfully', captchaValue);
                return (this.setState({ message: process.env.REACT_APP_CAPTCHA_NOT_CHECKED_MESSAGE }));

            }
        }

        const url = process.env.REACT_APP_VALIDATE_USER_AND_GENRATE_OTP_URL;

        const response = await apiCallService.getMethodCallWithParam(url, { 'email': this.state.email },);

        console.log('response', response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
            store.dispatch(setRefernceNumber(response.response.referenceNumber))
            store.dispatch(setEmail(this.state.email));

            history.push('/verifyOtp-viewAcess')
            history.go();
        } else if (response.response_message !== undefined && response.response_message !== "") {
            this.setState({ message: response.response_message })
        } else {
            this.setState({ message: response.response_description })
        }





    }

    dropLogin = () => {
        store.dispatch(setAdminViewType(false));
    }



    render() {


        return (


            <div className="login-container">

                {
                    (this.props.authenticated)
                        ? (

                            <Redirect to="/dashboard" />

                        )
                        : (

                            <div className="container-fluid">
                                <div className="row flex-row-reverse">
                                    <div className="col-12 col-md-6 col-lg-5 col-xl-4 frmbg">
                                        <div className="login-form">
                                            <div className="logo"><img src={corpImg} alt="ZRO" className="img-fluid" /></div>
                                            <h4>Switch Corp View Login</h4>
                                            <p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Please_enter_your_email_address}</p>



                                            <p style={{ color: "#f46a6a", marginTop: "-16px" }}> {this.state.message}</p>
                                            <div className='text-danger'>{localStorage.getItem('errorMessage')}</div>
                                            {localStorage.setItem('errorMessage', '')}

                                            <form onSubmit={this.generateOTP}>
                                                <div className={this.state.email !== "" ? "mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder" : "mdl-textfield mdl-js-textfield mdl-textfield--floating-label"}>

                                                    <input className="mdl-textfield__input" type="email" id="" value={this.state.email} onChange={this.handleEmailChange} required />
                                                    <label className="mdl-textfield__label">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_LABEL_Company_Email_ID}</label>
                                                    {/* {
                                            this.state.isValidConfirm ?
                                                <i className="passok"></i>
                                                :
                                                <i className="passeeror"></i>
                                        } */}
                                                </div>

                                                <div className="form-row">
                                                    {/* <img src="images/captcha.png" alt="captcha" className="img-fluid"/> */}
                                                    <ErrorBoundary>
                                                        <ReCaptcha
                                                            id='captcha'
                                                            ref={(el) => { this.captchaDemo = el; }}
                                                            size="normal"
                                                            data-theme="light"
                                                            render="explicit"
                                                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                            onloadCallback={this.onLoadRecaptcha}
                                                            verifyCallback={this.verifyCallback}
                                                            isCaptured={this.state.captchaDone}
                                                            required
                                                        />
                                                    </ErrorBoundary>
                                                </div>


                                                <div className="form-row">
                                                    <div className="col-12 col-sm-6">
                                                        <button type="submit" id="" value={this.state.captchaDone} className="logbtn"  >{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Get_OTP}</button>


                                                    </div>
                                                    <div className="col-12 col-sm-6">

                                                        <button type="button" style={{ float: "right" }} onClick={this.dropLogin} id="" className="logbtn"  >Drop Login</button>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-7 col-xl-8 logbg">
                                        <div className="forgot-box">
                                            <img src={forgotImg} alt="Forgot password" className="img-fluid" />
                                        </div>
                                    </div>

                                </div>
                            </div>


                        )}
            </div>

        )
    }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(ForgotPassword);