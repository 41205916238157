import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import otpImg from '../../assests/otp-img.png'
import { setOTP } from '../../redux/forgot-password/forgot-password.action';
import { store } from '../../redux/store';
import history from '../../auth/history';
import apiCallService from '../../service/api-call-service';
import { setIsPasswordReset, setToken } from '../../redux/token/token.action';
import { setRoMdn } from '../../redux/user/user.actions';
import ACLService from '../../service/service-impl/ACLService';
import Axios from 'axios';
import { setLocationData } from '../../redux/location/location.action';



class ResetLoginPassword extends React.Component {

	constructor() {
		super();

		this.state = {

			otp: "",
			messageValid: '',
			dropDownHidden: false,
			message: "",
			severty: "success",
			maskEmail: ''

		};
	}

	componentDidMount = () => {
		var maskEmail = this.props.email;

		var afterAt = maskEmail.split("@");


		console.log('afterAt', afterAt)

		var afterAtValue = `@${afterAt[1]}`;

		var lengthOfone = afterAt[0];

		console.log('afterAt', afterAtValue, lengthOfone.length);

		var slicedValue = afterAt[0].slice(0, 3);
		console.log('afterAt', slicedValue);

		var starsInclude = '';


		for (let i = 0; i < lengthOfone.length - 3; i++) {
			console.log('afterAt', i, starsInclude);
			starsInclude = `${starsInclude}*`;
		}

		console.log('afterAt', starsInclude);

		var finalValue = `${slicedValue}${starsInclude}${afterAtValue}`;

		console.log('afterAt', finalValue);



		this.setState({ maskEmail: finalValue });




	}


	formSubmit = async (event) => {
		event.preventDefault();

		if (this.state.otp !== '') {


			var long = '';
			var lat = '';
			var locationCountry = '';
			var locationState = ''
			if (navigator.permissions && navigator.permissions.query) {
				navigator.permissions.query({ name: 'geolocation' })
					.then(function (permissionStatus) {
						console.log('geolocation permission state is ', permissionStatus.state);

						permissionStatus.onchange = function () {
							console.log('geolocation permission state has changed to ', this.state);
						};
					});
			} else {
				navigator.geolocation.getCurrentPosition(function (position) {
					//imitate map latlng construct
					const marker = {
						lat: position.coords.latitude,
						long: position.coords.longitude
					};
				})
			}

			navigator.geolocation.watchPosition(async function (position) {
				long = position.coords.longitude;
				lat = position.coords.latitude;
				console.log("Latitude is :", position);
				console.log("Longitude is :", position);
			});


			var sBrowser, sUsrAg = navigator.userAgent;


			if (sUsrAg.indexOf("Firefox") > -1) {
				sBrowser = "Mozilla Firefox";
				// "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
			} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
				sBrowser = "Samsung Internet";
				// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
			} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
				sBrowser = "Opera";
				// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
			} else if (sUsrAg.indexOf("Trident") > -1) {
				sBrowser = "Microsoft Internet Explorer";
				// "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
			} else if (sUsrAg.indexOf("Edge") > -1) {
				sBrowser = "Microsoft Edge";
				// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
			} else if (sUsrAg.indexOf("Chrome") > -1) {
				sBrowser = "Google Chrome or Chromium";
				// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
			} else if (sUsrAg.indexOf("Safari") > -1) {
				sBrowser = "Apple Safari";
				// "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
			} else {
				sBrowser = "unknown";
			}


			const mobile = Boolean(
				window.navigator.userAgent.match(
					/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
				)
			);

			var mobileVar = 'Laptop'

			if (mobile) {
				mobileVar = 'Mobile'
			}


			var responseIp = await Axios.get('https://api.ipify.org?format=json')
				.then(async res => {
					response = res.data;
					console.log(res.data);
					return response;
				});

			// this.getMethodCallWithoutParam('https://api.ipify.org?format=json');

			var address = ''

			var responseAddress = ''
			if (parseInt(process.env.REACT_APP_CALL_GOOGLE_MAP_API) === 1) {
				responseAddress = await Axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=28.4501369,77.081024&key=AIzaSyB3OfJwXzwzOp45JsBsyLq3Om0N-l3nngc')
					.then(async res => {
						response = res.data;
						console.log(res.data);
						return response;
					});
			}

			if (responseAddress !== undefined && responseAddress.plus_code !== undefined && responseAddress.plus_code.compound_code !== undefined) {
				address = responseAddress.plus_code.compound_code;
			}



			var fullAddress = address.split(',');

			console.log('fullAddress', fullAddress, responseAddress);

			var country = fullAddress[2];
			var state = fullAddress[1];

			var payload = {
				locationCountry: country,
				locationState: state,
				ip: responseIp.ip
			}


			store.dispatch(setLocationData(payload));


			var nav = navigator.userAgent;


			var subNav = nav.split(';');
			var os = ''
			var device_os = ''

			if (mobile) {
				os = subNav[2].split(') ')
				device_os = os[0]
			} else {
				os = subNav[0].split('(')
				device_os = os[1]
			}


			var osVersion = subNav[1].split(')')


			console.log('subNav   to calulate', subNav, " : ", os, " : => ", osVersion[0]);




			var url = process.env.REACT_APP_LOGIN_URL;

			var request = {
				"request": {
					"device_info": {
						"device_id": device_os,
						"device_os": "web",
						"device_os_version": osVersion[0],
						"imei": "",
						"ip_address": responseIp.ip,
						"model_name": device_os,
						"source": mobileVar,
						"latitude": lat,
						"longitude": long,
						"locationCountry": country,
						"locationState": state
					},
					"token": {
						"grant_type": "otp",
						"otp": this.state.otp,
						"referenceNumber": this.props.referenceNum,
						"username": this.props.email
					}
				}
			}



			var response = await apiCallService.postMethodCall(url, request);


			console.log("Response from validate otp", response, request, url);

			if (response !== undefined && response.response_code === 0) {
				// store.dispatch(setOTP(this.state.otp));
				console.log(response);
				store.dispatch(setToken(response.response.token.token));
				const url = process.env.REACT_APP_GET_EMPLOYEE_BY_EMAIL
				const res = await apiCallService.getMethodCallWithParam(url, { 'email': this.props.email });
				const detail = res.response;
				console.log('response data ', res);
				if (res.response_message === 'success') {
					if(this.props.loginUserRoID!==""){
						store.dispatch(setRoMdn(detail.employeeDetails.roMdn))
					}else{
						store.dispatch(setRoMdn(this.props.loginUserRoID))
					}

					var ro = "noRo"
					store.dispatch(setIsPasswordReset(detail.employeeDetails.isPasswordReset));

					// if(detail.employeeDetails.isPasswordReset){
					console.log('url to acl', `${process.env.REACT_APP_ACL_URL}/${process.env.REACT_APP_USER_NAME_FOR_VIEW_MODE_CORP_LOGIN}/${ro}/0`)

					// AclService(`${process.env.REACT_APP_ACL_URL}/${username}`,username)
					// await AclService.aclServiceCall(`${process.env.REACT_APP_ACL_URL}/${username}/${detail.employeeDetails.roMdn}`, username, response.response.token.token, password, rememberMe, detail.employeeDetails.isPasswordReset);
					await ACLService.aclServiceCall(`${process.env.REACT_APP_ACL_URL}/${process.env.REACT_APP_USER_NAME_FOR_VIEW_MODE_CORP_LOGIN}/${ro}/0`, this.props.email, response.response.token.token, "", "", detail.employeeDetails.isPasswordReset, this.props.loginUserRoID);
				}

			} else {
				this.setState({ messageValid: response.response_description });
			}


		}
	}


	resenOtp = async () => {
		const url = process.env.REACT_APP_RESEND_OTP_URL;

		const request = {
			"request": {
				"referenceNumber": this.props.referenceNum
			}
		}

		var response = await apiCallService.postMethodCall(url, request);

		console.log("response resenOtp", response)

		if (response !== undefined && response.response_code === 0 && response.response !== undefined) {
			this.setState({ dropDownHidden: true })
			this.setState({ severty: "success" })
			this.setState({ message: response.response.statusMessage })

		} else {
			this.setState({ messageValid: response.response_description });
		}


	}





	render() {

		// console.log("States here", this.state);

		return (
			<div className="login-container">

				{
					(this.props.authenticated)
						? (

							<Redirect to="/dashboard" />

						)
						: (

							<div className="container-fluid">
								<div className="row flex-row-reverse">
									<div className="col-12 col-md-6 col-lg-5 col-xl-4 frmbg">
										<div className="login-form">

											<Snackbar

												anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
												open={this.state.dropDownHidden}
												autoHideDuration={20000}
												onClose={e => this.setState({ dropDownHidden: false })}
											>
												<Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
													{this.state.message}
												</Alert>
											</Snackbar>
											<div className="logo"><img src={corpImg} alt="ZRO" className="img-fluid" /></div>
											<h4>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_One_Time_Password}</h4>
											<p>OTP Sent to Corporate Admin</p>


											<p>Reference Number : {this.props.referenceNum}</p>


											<p style={{ color: "#f46a6a", marginTop: "-16px" }}> {this.state.messageValid}</p>

											<form onSubmit={this.formSubmit}>
												<div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
													<input className="mdl-textfield__input" type="text" id="" value={this.state.otp} onChange={e => { this.setState({ otp: e.target.value }) }} required />
													<label className="mdl-textfield__label">Enter OTP</label>
												</div>

												<div className="form-row">

													<div className="col-12 formtxt">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_If_you_didn0t_receive_a_OTP} <button type="button" onClick={this.resenOtp}> {process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Resend_OTP}</button></div>

													<div className="col-12 mt-5">
														<button type="submit" id="" className="logbtn">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_One_Time_Password_Submit_Button}</button>
													</div>

												</div>
											</form>
										</div>
									</div>

									<div className="col-12 col-md-6 col-lg-7 col-xl-8 logbg">
										<div className="forgot-box">
											<img src={otpImg} alt="OTP" className="img-fluid" />
										</div>
									</div>

								</div>
							</div>

						)}
			</div>

		)
	}
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData }, forgotPassword: { referenceNum, email },
	adminView: { loginUserEmail, loginUserRoID }
}) => ({
	authenticated, userRole, roleData, roMdn, mdn, referenceNum, email, loginUserEmail, loginUserRoID

});

export default connect(mapStateToProps)(ResetLoginPassword);