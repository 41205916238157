import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import GenerateRequestId from '../../../common-function/GenerateRequestId';
import { showAddNewOderSection, showDetailOderSection, renderTransactionHistoryAgainAction } from '../../../redux/div-show-and-hide/div-show-and-hide.action';
import { setGstAmount, setRatePerCard, setGstPercentage, setTotalAmount, setOrderId, setOrderDate, setOrderBack, setTotalAmountWistGst } from '../../../redux/order/order.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';


const CardOderReview = ({ authenticated, showAddNewOderSection, roleData, userRole, token, roMdn, bankId, roName, mdn,
    cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox, renderTransactionHistoryAgain,
    totalAmt, ratePerCard, gstPercent, gstAmt, shippingAddress, billingAddress, shippingPersonName, shippingPersonMdn, totalAmtWithGst, cardType
}) => {

    const [isChecked, setIsChecked] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    // const [ratepercard, setRatePerCard] = useState(process.env.REACT_APP_RATE_PER_CARD);
    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');

    const [orderDisable, setOrderDisable] = useState(false);



    useEffect(() => {

        const fetchDetail = async () => {
            const url = process.env.REACT_APP_CORPORATE_BILLING_RATE_SLAB

            var serviceName = process.env.REACT_APP_SERVICE_NAME_FOR_CARD_ORDER;
            if (cardType === 0) {
                serviceName = process.env.REACT_APP_SERVICE_NAME_FOR_VIRTUAL_CARD_ORDER;
            }
            const request = {
                "roId": roMdn,
                "quantity": parseInt(cardQuantity),
                "serviceName": serviceName
            }

            var response = await apiCallService.postMethodCall(url, request);
            console.log('response fetchDetail', request, response)
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined && response.response !== null) {
                store.dispatch(setTotalAmount(response.response.totalAmt / 100));
                store.dispatch(setRatePerCard(response.response.ratePerCard / 100));
                store.dispatch(setGstPercentage(response.response.gstPercentage));
                store.dispatch(setGstAmount(response.response.gstAmt / 100));
                store.dispatch(setTotalAmountWistGst(response.response.totalAmtWithGst / 100))
                setButtonDisable(false);
            } else {
                setDropDownHidden(true);
                setSeverty("error");
                setMessage(response.response_description);
                setButtonDisable(true);
            }
        }
        fetchDetail();

    }, [])

    const triggerBack = () => {
        store.dispatch(setOrderBack(true));
        store.dispatch(showAddNewOderSection());
    }

    const submitForm = async (event) => {
        event.preventDefault();


        if (buttonDisable) {
            return (
                console.log("Inside Error breach"),
                setDropDownHidden(true),
                setSeverty("error")
            )
        } else {

            setOrderDisable(true);
            var requestId = GenerateRequestId.geneRateRequestId();
            var requestToSend = {
                "roId": roMdn,
                "fullName": fullNameOder,
                "mobile": mobileNum,
                "cardQuantity": cardQuantity,
                "address1": address1,
                "address2": address2,
                "state": state,
                "city": city,
                "pincode": pincode,
                "orderId": requestId,
                "shippingOrBillingAddress": sameAddressCheckbox,
                "shippingAddress": shippingAddress,
                "billingAddress": billingAddress,
                "shippingPersonName": shippingPersonName,
                "shippingPersonMdn": shippingPersonMdn,
                "cardIssuer": bankId,
                "deductionAvailBalance": isChecked,
                "totalAmt": totalAmt,
                "totalAmtWithGst": totalAmtWithGst,
                "gstAmt": gstAmt,
                "roName": roName,
                "mdn": mdn,
                "cardType": cardType
            }

            var url = process.env.REACT_APP_ORDER_CARD_URL
            // let headers = {
            //     "client_id": "OEMSAPP",
            //     "token": token,
            //     "Authorization": "BASIC abcd"
            // }

            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log("response : ", response, "requestToSend : ", requestToSend)
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
                store.dispatch(setOrderId(response.response.orderId));
                store.dispatch(setOrderDate(response.response.dateCreatedOn))
                store.dispatch(renderTransactionHistoryAgainAction(false))
                store.dispatch(showDetailOderSection());
                store.dispatch(renderTransactionHistoryAgainAction(true));
                setOrderDisable(false);
            } else {
                store.dispatch(renderTransactionHistoryAgainAction(false))
                setDropDownHidden(true)
                setSeverty("error")
                setMessage(response.response_description)
                store.dispatch(renderTransactionHistoryAgainAction(true));
                setOrderDisable(false);
            }
        }
    }

    const isCheckedChange = (event) => {
        setIsChecked(event.target.checked);
    }
    var amount = parseFloat(ratePerCard) * parseFloat(cardQuantity)
    // var gst = 18 * parseFloat(amount) / 100

    return (< >
        {(authenticated)
            ? (
                <div className="cardorderform"  style={{minHeight:"520px"}}>
                    <form onSubmit={submitForm}>
                        <div className="row">
                            <Snackbar

                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={dropDownHidden}
                                autoHideDuration={3000}
                                onClose={e => setDropDownHidden(false)}
                            >
                                <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                    {message}
                                </Alert>
                            </Snackbar>

                            <div className="col-12">
                                <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Review} </h3>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Review_Ordered_By}</p>
                                    <h5>{fullNameOder}</h5>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>Mobile Number:</p>
                                    <h5>{mobileNum}</h5>
                                </div>
                            </div>

                            <div className="col-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>Card type:</p>
                                    <h5>{cardType === 1 ? <>Physical Card</> : <>Virtual Card</>}</h5>
                                </div>
                            </div>

                            <div className="col-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>Card Quantity:</p>
                                    <h5>{cardQuantity}</h5>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>Rate Per Card:</p>
                                    <h5>₹{parseFloat(ratePerCard).toFixed(2)}</h5>
                                </div>
                            </div>

                            <div className="col-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>Amount:</p>
                                    <h5>₹{parseFloat(totalAmt).toFixed(2)}</h5>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>GST {gstPercent}%:</p>
                                    <h5>₹{parseFloat(gstAmt).toFixed(2)}</h5>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 col-xl-3">
                                <div className="orderreview">
                                    <p>Total Amount:</p>
                                    <h5>₹{parseFloat(totalAmtWithGst).toFixed(2)}</h5>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="reviewaddress">
                                    {
                                        cardType === 0
                                            ?
                                            null
                                            :
                                            <div className="shipaddress">
                                                <h5>Shipping Address:</h5>
                                                <h6>{shippingPersonName}</h6>
                                                <p>{shippingAddress}</p>
                                                <p><i className="mobile"></i>+91-{shippingPersonMdn}</p>
                                            </div>
                                    }


                                    <div className="billaddress">
                                        <h5>Billing Address:</h5>
                                        <h6>{fullNameOder}</h6>
                                        <p>{billingAddress}</p>
                                        <p><i className="mobile"></i>+91-{mobileNum}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12">

                                <div className="starStyle" >
                                    <span>* </span> {process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Total_Amount_to_be_deducted_from_Available_Balance}
                                </div>
                            </div>


                            <div className="col-12 mt-4">
                                <button value="Back" type={process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Review_Back_Button} className="lbtn btngr" onClick={triggerBack} >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Review_Back_Button}</button>
                                <button type="submit" value={process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Review_Order_Now_Button} className="lbtn btnbl" disabled={orderDisable}>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Order_Review_Order_Now_Button}</button>
                            </div>

                        </div>
                    </form>
                </div>

            )
            : (
                <Redirect to='/' />
            )

        }

    </>

    )

}

const mapDispatchToProps = dispatch => ({
    showAddNewOderSection: () => dispatch(showAddNewOderSection())
});

const mapStateToProps = ({ token: { authenticated, token }, user: { userRole, roMdn, bankId, roName, mdn }, role: { roleData },
    order: { cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox,
        totalAmt, ratePerCard, gstPercent, gstAmt, billingAddress, shippingAddress, shippingPersonName,
        shippingPersonMdn, totalAmtWithGst, cardType
    }
}) => ({
    authenticated, userRole, roleData, token, roMdn, shippingAddress, billingAddress, roName, mdn,
    cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox,
    totalAmt, ratePerCard, gstPercent, gstAmt, shippingPersonName, shippingPersonMdn, bankId, totalAmtWithGst, cardType
});

export default connect(mapStateToProps, mapDispatchToProps)(CardOderReview);