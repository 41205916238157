
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import { Link } from "react-router-dom";
import MinKycDiv from './min-kyc/min-kyc.component';
import FullKycDiv from './full-kyc/full-kyc.component';
import CommonFunction from '../../../common-function/GenerateRequestId';
import apiCallService from '../../../service/api-call-service';
import Avatar from 'react-avatar';
import KycStatus from '../../../constants/kyc-status.type';
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import { showKycDetailSuccessSection } from '../../../redux/div-show-and-hide/div-show-and-hide.action';
import SmallPopUp from "../../../common/component/small.popup.component";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import history from '../../../auth/history';
import CardStatusType from '../../../constants/card-status.type';
import MultiCardStatus from '../../../common/component/multiCardStatus.component';
const UserKyc = ({ authenticated, location, roleData, userRole, isPasswordReset, showKycDetailSuccessDiv, roMdn, bandData, kycDetail, fullName, currentUser }) => {

    const [kycIdentifier, setKycIdentifier] = useState('minKyc');
    const [employee, setEmployee] = useState({});
    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState("");
    const [severty, setSeverty] = useState("error");

    const [cardNumber, setCardNumber] = useState("false");
    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));

        store.dispatch(showKycDetailSuccessSection(false));
        console.log('location.state.post', location.state.post);

        setEmployee(location.state.post);

        location.state.employee.card.map(dd => {
            setCardNumber(dd.cardReferenceNo)
        })
        if (parseInt(location.state.employee.kyc_status) === parseInt(KycStatus.MIN_KYC) || parseInt(location.state.employee.kyc_status) === parseInt(KycStatus.AADHAR_BASED)) {
            setKycIdentifier('fullKyc')
        }

    }, []);




    const activateCard = async (e, value) => {
        e.preventDefault();


        var url = `${process.env.REACT_APP_GET_getCardDetailByOrderId}`;

        var response = await apiCallService.getMethodCallWithParam(url, { orderId: cardNumber });

        console.log(" unAssignUserCard card values", response);
        if (response !== null && response.status !== undefined && response.status === 0 && response.getCardOrder !== undefined) {
            // REACT_APP_VAR_FOR_ACTIVATE_CARD_BUTTON
            var urlActivate = process.env.REACT_APP_ACTIVATE_USER_CARD;
            location.state.employee.roId = location.state.employee.roMdn
            var request = {
                "employeeDetailRequest": location.state.employee,
                "cardRefNum": cardNumber,
                "panNum": location.state.employee.pan,
                "aadharNum": location.state.employee.aadhar_no,
                "lastFOurDigit": response.getCardOrder.lastFourDigit,
                "updatedBy": fullName,
                "updatedByEmail": currentUser,
            }

            var responseActivate = await apiCallService.postMethodCall(urlActivate, request);

            console.log("response for Activate card", responseActivate);


            if (responseActivate !== undefined && responseActivate.response_code !== undefined && parseInt(responseActivate.response_code) === 0) {


                setDropDownHidden(true)
                setMessage("Card Activated successfully")
                setSeverty("success")
                history.push("assigned-cards")
                history.go();

            } else {

                setDropDownHidden(true)
                setMessage(responseActivate.response_description)
                setSeverty("error")

            }


        }
        else {
            setDropDownHidden(true)
            setMessage(response.response_description)
            setSeverty("error")
        }



    }


    const handleInputCheckBox = (event) => {
        console.log('event triggered', event.target.name)
        console.log(event.target.getAttribute("name"));
        setKycIdentifier(event.target.getAttribute("name"));
    }


    console.log('employee detail on kyc', location.state.employee, "condition ", employee, location.state.employee.kyc_status === KycStatus.MIN_KYC)

    var assigned = false;
    // var assignedCardNumber = ""
    var number = ""
    location !== undefined && location.state.employee.card.map(card => {
        if (card.cardStatus === CardStatusType.ASSIGNED) {
            assigned = true;
            // assignedCardNumber = card.cardReferenceNo
        }
        number = card.cardReferenceNo
    })

    console.log("cardRefNumber hhhhhhhhh", number)
    return (
        <div >
            {(authenticated)
                ? (
                    isPasswordReset
                        ?
                        <div className="mid-container">
                            <div className="container-fluid">



                                <div className="head">
                                    <div className="row">

                                        <div  className="col-10">
                                            <h3>User KYC</h3>
                                        </div>
                                        <div  className="col-2">
                                            <div className="actnbtn" >

                                                <Link
                                                    className="back"
                                                    to={{
                                                        pathname: location.state.from,
                                                        state: { employee: location.state.employee !== undefined && location.state.employee, editShowHidden: true }
                                                    }}
                                                    data-toggle="tooltip" title="Back"
                                                ><i className='back'></i></Link>

                                            </div>
                                        </div>



                                    </div>
                                </div>

                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => setDropDownHidden(false)}
                                >
                                    <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                        {message}
                                    </Alert>
                                </Snackbar>

                                <div className="empdtls">
                                    <div className="row">




                                        <div className="col-12 col-md-4">

                                            <div className="emplcard">

                                                <div  className="userpro">
                                                    <Avatar src={location.state.employee.imageUrl} color={Avatar.getRandomColor(location.state.employee.mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={location.state.employee.emp_id} name={`${CommonFunction.Capitalize(location.state.employee.fullName)}`} size="44px" round={true} />

                                                    <h5  className="text-truncate">{location.state.employee.fullName}</h5>
                                                    <p>+91-{location.state.employee.mdn}</p>
                                                </div>


                                                <div  className="usrprodtl border-top mt-3 pt-3">
                                                    <ul>
                                                        <li  className="cldr">
                                                            <p>Date of Birth:</p>
                                                            <h5>{location.state.employee.dob !== undefined && location.state.employee.dob !== null ? location.state.employee.dob.replaceAll("/", "-") : ""}</h5>
                                                        </li>
                                                        <li  className="gender">
                                                            <p>Gender:</p>
                                                            <h5> {location.state.employee.gender}</h5>
                                                        </li>
                                                        <li  className="email">
                                                            <p>Email ID:</p>
                                                            <h5>{location.state.employee.email}</h5>
                                                        </li>
                                                        <li  className="locat">
                                                            <p>Address:</p>
                                                            <h5>{`${location.state.employee.address}, ${location.state.employee.pincode}`}</h5>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                        {
                                            showKycDetailSuccessDiv
                                                ?
                                                <div className="col-12 col-md-8">
                                                    <div className="emplcard">
                                                        <div className="kycform">
                                                            <div className="kycstatus">
                                                                <h3 className="success"> Congratulation, KYC has been done successfully! </h3>
                                                            </div>

                                                            <div className="kycdetailcard">
                                                                <div className="row">
                                                                    <div className="col-12 col-lg-5 border-bottom">
                                                                        <div className="kycvalue">
                                                                            <p>Assigned Card Ref. No.</p>
                                                                            <h6>{number !== undefined && number !== null && number !== "" ?
                                                                                number :
                                                                                <>NA</>

                                                                            }</h6>
                                                                        </div>
                                                                    </div>

                                                                    {kycDetail.aadharCardNum === "" ?
                                                                        <div className="col-12 col-lg-7 border-bottom">
                                                                            <div className="kycvalue">
                                                                                <p>PAN Card No.</p>
                                                                                <h6>{kycDetail.panCardNum}</h6>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="col-12 col-lg-7 border-bottom">
                                                                            <div className="kycvalue">
                                                                                <p>Aadhaar Card No.</p>
                                                                                <h6>{kycDetail.aadharCardNum}</h6>
                                                                            </div>
                                                                        </div>
                                                                    }



                                                                    <div className="col-12 col-lg-5 border-bottom">
                                                                        <div className="kycvalue">
                                                                            <p>KYC Ref. ID</p>
                                                                            <h6>{kycDetail.kycRefId}</h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-lg-7 border-bottom">
                                                                        <div className="kycvalue">
                                                                            <p>Date & Time</p>
                                                                            <h6>{kycDetail.dateAndTime}</h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 border-bottom">
                                                                        <div className="kycvalue">
                                                                            <p>KYC End Date</p>
                                                                            <h6>{kycDetail.kycEndDate}  - {kycDetail.kycEndDateMessage}</h6>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        assigned
                                                                        &&
                                                                        <div className="col-12">
                                                                            <button

                                                                                className="lbtn btnbl"
                                                                                id="activateCard"
                                                                                type="button" data-bs-toggle="modal" data-bs-target="#activateCard123"
                                                                                style={{ marginLeft: "-20px" }}
                                                                            >
                                                                                <>{process.env.REACT_APP_VAR_FOR_ACTIVATE_CARD_BUTTON} </>

                                                                            </button>
                                                                            <SmallPopUp
                                                                                targetid="activateCard123"
                                                                                headerText={process.env.REACT_APP_ACTIVATE_CARD_headerText}
                                                                                bodyText={process.env.REACT_APP_ACTIVATE_CARD_bodyText}
                                                                                onclickFunctionName={activateCard}
                                                                                allValues={location.state.employee.cards}
                                                                                buttonActionName={process.env.REACT_APP_ACTIVATE_CARD_okButton}
                                                                                buttonActionClose={process.env.REACT_APP_ACTIVATE_CARD_cancelButton}
                                                                            />
                                                                        </div>
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                :

                                                <div className="col-12 col-md-8">
                                                    <div className="emplcard">
                                                        <div className="kycform">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6>Select KYC type:</h6>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                    <div className="form-check form-check-inline">
                                                                        <input type="radio" id="minKyc" className="form-check-input" name="minKyc" value={kycIdentifier} checked={kycIdentifier === 'minKyc'} onClick={handleInputCheckBox} disabled={parseInt(location.state.employee.kyc_status) === parseInt(KycStatus.MIN_KYC) || parseInt(location.state.employee.kyc_status) === parseInt(KycStatus.AADHAR_BASED)} />
                                                                        <label className="form-check-label" for="minKyc" >  Min KYC </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input type="radio" id="fullKyc" className="form-check-input" name="fullKyc" value={kycIdentifier} checked={kycIdentifier === 'fullKyc'} onClick={handleInputCheckBox} />
                                                                        <label className="form-check-label" for="fullKyc" >Full KYC </label>
                                                                    </div>

                                                                </div>


                                                                <div className="col-12">

                                                                    {kycIdentifier === 'minKyc' ?
                                                                        <MinKycDiv cardRefNumber={number} employee={location.state.employee} lastFourDigit={employee.lastFourDigit} />
                                                                        : null
                                                                    }
                                                                    {kycIdentifier === 'fullKyc' ?
                                                                        <FullKycDiv cardRefNumber={number} employeeData={employee} employee={location.state.employee} />
                                                                        : null
                                                                    }
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ band: { bandData }, token: { authenticated, isPasswordReset },
    user: { userRole, roMdn, fullName, currentUser }, role: { roleData }, showAndHide: { showKycDetailSuccessDiv }, kycCard: { kycDetail } }) => ({
        currentUser, fullName, authenticated, userRole, roleData, roMdn, bandData, showKycDetailSuccessDiv, isPasswordReset, kycDetail
    });

export default connect(mapStateToProps)(UserKyc);



