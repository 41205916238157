
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../../redux/activeHeader/activeheader.action';
import { store } from '../../../../redux/store';




const AadharXml = ({ authenticated, roleData, userRole, cardRefNumber, roMdn, bandData }) => {


    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass:""
        }

        store.dispatch(setActiveHeader(payload));

    }, []);



    return (
        <div >
            {(authenticated)
                ? (


                    <div className="col-12" id="adrxml" >
                        <form className="row">

                        <div className="col-12 mt-3">
                                <h6>{process.env.REACT_APP_VAR_FOR_PENDING_KYC_IMPLEMNTATION}</h6>
                            </div>

                            {/* <div className="col-12 col-sm-6 col-xl-7">
                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder">
                                    <input className="mdl-textfield__input" type="text" value={cardRefNumber} disabled />
                                    <label className="mdl-textfield__label">Assigned Card Ref. Number</label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-7 col-xl-7">
                                <div className="file-upload">
                                    <label className="control-label">Upload Aadhaar XML file</label>

                                    <div className="dropzone-wrapper">
                                        <div className="dropzone-desc">
                                            <i className="xml"></i>
                                            <h6>Drag & Drop or <span>Browes</span> XML file</h6>
                                        </div>
                                        <input type="file" name="img_logo" className="dropzone" />
                                    </div>
                                    <div className="itxt">Aadhaar generated xml file from UIDAI Portal</div>

                                    <div className="preview-zone hidden">
                                        <div className="box">
                                            <div className="progress" style={{ "height": "6px" }}>
                                                <div className="progress-bar" role="progressbar" style={{ "width": "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div className="box-body"></div>
                                            <button type="button" className="remove-preview">Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="radio-checkbox">
                                    <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                        <input type="checkbox" id="" className="mdl-checkbox__input" required />
                                        <span className="mdl-checkbox__label">  I agree to the <a href={process.env.REACT_APP_TERMS_AND_CONDITION_URL}>terms and conditions</a></span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 mt-4">
                                <input type="reset" value="Reset" className="lbtn btngr" />
                                <input type="submit" value="Submit" className="lbtn btnbl" data-bs-toggle="modal" data-bs-target="#xmlpass" />
                            </div> */}

                        </form>
                    </div>


                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ band: { bandData }, token: { authenticated }, user: { userRole, roMdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, bandData
});

export default connect(mapStateToProps)(AadharXml);



