import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import apiCallService from '../../../service/api-call-service';

import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';

import CreatableSelect from 'react-select/creatable';
import img from '../../../assests/icons/arrow-down.svg'

class SearchIfsc extends React.Component {

    constructor() {
        super();

        this.state = {
            bankDetails: [],
            stateDetails: [],
            cityDetails: [],
            branchDetails: [],
            bankName: '',
            stateName: '',
            cityName: '',
            branchName: '',
            ifscCode: '',
            buttonStatus: true,
            messagePopUpHidden: false,
            severity: 'success',
            message: ''
        };
    }


    componentDidMount = async () => {

        // const url = `${process.env.REACT_APP_GET_BANK_DETAIL}/${process.env.REACT_APP_Var_FOR_ACTIVE_STATUS}`;

        // console.log('URL : ', url);


        const RequestToSend =
        {
            "request": {
                "bank_name": null,
                "state_name": null,
                "city_name": null,
                "branch_name": null

            }
        }



        const url = process.env.REACT_APP_SEARCH_IFSC_CODE

        const response = await apiCallService.postMethodCall(url, RequestToSend)

        console.log('Response bankDetail : ', response);

        if (!(response === undefined) && (response.response_code == 0) && response.response.get_details != null) {

            response.response.get_details.map(bank => {
                var value = "value";
                var label = "label";
                bank[value] = bank.bankName;
                bank[label] = bank.bankName;
            })
            this.setState({ bankDetails: response.response.get_details });
        } else {
            this.setState({ severity: "error" })
            this.setState({ message: response.response_description })
            this.setState({ messagePopUpHidden: true });
            console.log('message printed')
            this.setState({ bankDetails: [] });
        }
    }

    handleChangeBankDropDown = async (newValue, actionMeta) => {

        this.setState({ buttonStatus: true })

        if (newValue !== null && newValue.value !== null) {

            // this.setState({ bankName: newValue.bankName });

            // this.setState({ stateDetails: [] });
            this.setState({ bankName: newValue.bankName });
            this.setState({ stateName: '' });
            this.setState({ cityName: '' });
            this.setState({ branchName: '' });
            this.setState({ ifscCode: '' });

            this.setState({ stateDetails: [] });
            this.setState({ cityDetails: [] });
            this.setState({ branchDetails: [] });

            const RequestToSend = {

                "request": {
                    "bank_name": newValue.bankName,
                    "state_name": null,
                    "city_name": null,
                    "branch_name": null


                }
            }

            const url = process.env.REACT_APP_SEARCH_IFSC_CODE

            var device_info = {
                "roId": "11212342",
                "deviceInfo": {
                    "device_os": "ios",
                    "device_os_version": "11.0",
                    "device_id": "8.3",
                    "model_name": "Simulator",
                    "imei": "F58032B1-496D-44E1-950F-BB8DC4F362A9",
                    "time_stamp": "2018-05-02T16:29:17",
                    "latitude": "",
                    "longitude": "",
                    "ipaddress": "",
                    "channel": ""
                }
            }


            console.log('Request stateDetails ', RequestToSend)

            const response = await apiCallService.postMethodCall(url, RequestToSend);

            console.log('Response stateDetails : ', response);

            if (!(response === undefined) && (response.response_code == 0) && response.response.get_details != null) {

                response.response.get_details.map(state => {
                    var value = "value";
                    var label = "label";
                    state[value] = state.stateName;
                    state[label] = state.stateName;
                })
                this.setState({ stateDetails: response.response.get_details });
            } else {
                this.setState({ severity: "error" })
                this.setState({ message: response.response_description })
                this.setState({ messagePopUpHidden: true });
                console.log('message printed')
            }

            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        } else {
            this.setState({ bankName: '' })
            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        }



    }


    handleChangeStateDropDown = async (newValue, actionMeta) => {
        this.setState({ buttonStatus: true })
        if (newValue !== null && newValue.value !== null) {

            // this.setState({ stateName: newValue.value });

            // this.setState({ cityDetails: [] });
            this.setState({ stateName: newValue.value });
            this.setState({ cityName: '' });
            this.setState({ branchName: '' });
            this.setState({ ifscCode: '' });


            this.setState({ cityDetails: [] });
            this.setState({ branchDetails: [] });

            const RequestToSend = {

                "request": {
                    "bank_name": this.state.bankName,
                    "state_name": newValue.value,
                    "city_name": null,
                    "branch_name": null


                }
            }

            const url = process.env.REACT_APP_SEARCH_IFSC_CODE

            var device_info = {
                "roId": "11212342",
                "deviceInfo": {
                    "device_os": "ios",
                    "device_os_version": "11.0",
                    "device_id": "8.3",
                    "model_name": "Simulator",
                    "imei": "F58032B1-496D-44E1-950F-BB8DC4F362A9",
                    "time_stamp": "2018-05-02T16:29:17",
                    "latitude": "",
                    "longitude": "",
                    "ipaddress": "",
                    "channel": ""
                }
            }


            console.log('Request stateDetails ', RequestToSend)

            const response = await apiCallService.postMethodCall(url, RequestToSend);

            console.log('Response stateDetails : ', response);

            if (!(response === undefined) && (response.response_code == 0) && response.response.get_details != null) {
                response.response.get_details.map(state => {
                    var value = "value";
                    var label = "label";
                    state[value] = state.cityName;
                    state[label] = state.cityName;
                })
                this.setState({ cityDetails: response.response.get_details });
            } else {
                this.setState({ severity: "error" })
                this.setState({ message: response.response_description })
                this.setState({ messagePopUpHidden: true });
                console.log('message printed')
            }

            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        } else {
            this.setState({ bankName: '' })
            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        }




    }


    handleChangeCityDropDown = async (newValue, actionMeta) => {
        this.setState({ buttonStatus: true })
        if (newValue !== null && newValue.value !== null) {

            // this.setState({ cityName: newValue.value });

            // this.setState({ branchDetails: [] });

            this.setState({ cityName: newValue.value });

            this.setState({ branchName: '' });
            this.setState({ ifscCode: '' });


            this.setState({ branchDetails: [] });

            const RequestToSend = {

                "request": {
                    "bank_name": this.state.bankName,
                    "state_name": this.state.stateName,
                    "city_name": newValue.value,
                    "branch_name": null


                }
            }

            const url = process.env.REACT_APP_SEARCH_IFSC_CODE

            var device_info = {
                "roId": "11212342",
                "deviceInfo": {
                    "device_os": "ios",
                    "device_os_version": "11.0",
                    "device_id": "8.3",
                    "model_name": "Simulator",
                    "imei": "F58032B1-496D-44E1-950F-BB8DC4F362A9",
                    "time_stamp": "2018-05-02T16:29:17",
                    "latitude": "",
                    "longitude": "",
                    "ipaddress": "",
                    "channel": ""
                }
            }


            console.log('Request stateDetails ', RequestToSend)

            const response = await apiCallService.postMethodCall(url, RequestToSend);

            console.log('Response stateDetails : ', response);

            if (!(response === undefined) && (response.response_code == 0) && response.response.get_details != null) {
                response.response.get_details.map(state => {
                    var value = "value";
                    var label = "label";
                    state[value] = state.branchName;
                    state[label] = state.branchName;
                })
                this.setState({ branchDetails: response.response.get_details });
            } else {
                this.setState({ severity: "error" })
                this.setState({ message: response.response_description })
                this.setState({ messagePopUpHidden: true });
                console.log('message printed')
            }

            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        } else {
            this.setState({ cityName: '' })
            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        }



    }


    handleChangeBranchDropDown = async (newValue, actionMeta) => {
        this.setState({ buttonStatus: true })
        if (newValue !== null && newValue.value !== null) {

            this.setState({ branchName: newValue.value });

            this.setState({ ifscCode: '' });

            const RequestToSend = {

                "request": {
                    "bank_name": this.state.bankName,
                    "state_name": this.state.stateName,
                    "city_name": this.state.cityName,
                    "branch_name": newValue.value
                }
            }

            const url = process.env.REACT_APP_SEARCH_IFSC_CODE

            var device_info = {
                "roId": "11212342",
                "deviceInfo": {
                    "device_os": "ios",
                    "device_os_version": "11.0",
                    "device_id": "8.3",
                    "model_name": "Simulator",
                    "imei": "F58032B1-496D-44E1-950F-BB8DC4F362A9",
                    "time_stamp": "2018-05-02T16:29:17",
                    "latitude": "",
                    "longitude": "",
                    "ipaddress": "",
                    "channel": ""
                }
            }


            console.log('Request stateDetails ', RequestToSend)

            const response = await apiCallService.postMethodCall(url, RequestToSend);

            console.log('Response stateDetails : ', response);

            if (!(response === undefined) && (response.response_code == 0) && response.response.get_details != null) {
                this.setState({ ifscCode: response.response.ifsc_code });
                // this.props.setIfscCode(response.response.ifsc_code);
            } else {
                this.setState({ severity: "error" })
                this.setState({ message: response.response_description })
                this.setState({ messagePopUpHidden: true });
                console.log('message printed')
            }

            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }


        } else {
            this.setState({ cityNbranchNameame: '' })
            if (!(this.state.bankName === '') && !(this.state.stateName === '') && !(this.state.cityName === '') && !(this.state.branchName === '')) {
                this.setState({ buttonStatus: false })
            }
        }

    }




    saveIFSCCODE = (e) => {
        e.preventDefault();
        this.props.setIfscCode(e, this.state.ifscCode, this.state.bankName)
        document.getElementById("closeModalBtn").click();
    }






    render() {

        // this.getSurchargeCalulated();
        console.log("States here", this.state)


        const colourStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: 'white',
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                minHeight: "22px",
                lineHeight: "22px",
                background: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "12px",
                backgroundPosition: "center right",
                marginTop: "-7px"
            }),

            valueContainer: styles => ({
                ...styles,
                padding: "0px 5px 0px 0px"
            }),


            menu: styles => ({
                ...styles,
                zIndex: "9999"
            }),


            input: styles => ({
                ...styles,
                margin: "0px"
            }),



            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //   const color = chroma(data.color);
                return {
                    ...styles,
                };
            },



        };



        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?


                            <div className="modal fade" id="srchifsc" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                <div className="modal-dialog modal-md">
                                    <form className="modal-content alerttext" onSubmit={this.saveIFSCCODE} >
                                        <div className="modal-header">
                                            <h5 id="">Search Bank IFSC Code</h5>
                                            <button className="close" data-bs-dismiss="modal"  aria-label="Close">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="ifscform">


                                                <div className="select-group">
                                                    <label htmlFor="sg" className="select-label">Bank</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        onChange={this.handleChangeBankDropDown}
                                                        // onInputChange={this.handleChangeBankDropDown}
                                                        options={this.state.bankDetails}
                                                        styles={colourStyles}
                                                        components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                        required={true}
                                                        value={this.state.bankDetails.map(valueData => {
                                                            if (valueData.value === this.state.bankName) {
                                                                return valueData
                                                            }
                                                        })}
                                                        formatCreateLabel={() => undefined}
                                                    />
                                                </div>


                                                <div className="select-group">
                                                    <label htmlFor="sg" className="select-label">State</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        onChange={this.handleChangeStateDropDown}
                                                        // onInputChange={this.handleChangeBankDropDown}
                                                        options={this.state.stateDetails}
                                                        styles={colourStyles}
                                                        components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                        required={true}
                                                        value={this.state.stateDetails.map(valueData => {
                                                            if (valueData.value === this.state.stateName) {
                                                                return valueData
                                                            }
                                                        })}
                                                        formatCreateLabel={() => undefined}
                                                    />
                                                </div>

                                                <div className="select-group">
                                                    <label htmlFor="sg" className="select-label">City</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        onChange={this.handleChangeCityDropDown}
                                                        // onInputChange={this.handleChangeBankDropDown}
                                                        options={this.state.cityDetails}
                                                        styles={colourStyles}
                                                        components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                        required={true}
                                                        value={this.state.cityDetails.map(valueData => {
                                                            if (valueData.value === this.state.cityName) {
                                                                return valueData
                                                            }
                                                        })}
                                                        formatCreateLabel={() => undefined}
                                                    />
                                                </div>


                                                <div className="select-group">
                                                    <label htmlFor="sg" className="select-label">Branch</label>
                                                    <CreatableSelect
                                                        isClearable
                                                        onChange={this.handleChangeBranchDropDown}
                                                        // onInputChange={this.handleChangeBankDropDown}
                                                        options={this.state.branchDetails}
                                                        styles={colourStyles}
                                                        components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                        required={true}
                                                        value={this.state.branchDetails.map(valueData => {
                                                            if (valueData.value === this.state.branchName) {
                                                                return valueData
                                                            }
                                                        })}
                                                        formatCreateLabel={() => undefined}
                                                    />
                                                </div>

                                                {
                                                    this.state.ifscCode !== ""
                                                        ?
                                                        <div className="ifscode">IFSC Code: <span>{this.state.ifscCode}</span></div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" id="closeModalBtn" className="mbtn btngr" data-bs-dismiss="modal" >Cancel</button>
                                            {
                                                this.state.buttonStatus
                                                    ?
                                                    <button type="submit" className="mbtn btngy" disabled={this.state.buttonStatus}>Ok</button>
                                                    :
                                                    <button type="submit" className="mbtn btnbl" disabled={this.state.buttonStatus}>Ok</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>




                            :
                            <ResetLoginPassword />

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, token, currentUser, fullName, isPasswordReset

});

export default connect(mapStateToProps)(SearchIfsc);