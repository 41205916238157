import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (

<>

        <div className="col-12 col-md-6 col-xl-4">
            <div className="bandcard">
                <div className="bandtype">
                    <i></i>
                    <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                    <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </div>


                <div className="empband">


                    {/* <button

                        className="sbtn btnbl"
                    > Update</button> */}


                    <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Employees}</p>
                    <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                </div>

                <div className="walletrow">
                    <div className="row">
                        <div className="col-6 col-sm">
                            <div className="empwallet">

                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_General}</p>
                                <h6 className="general"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>

                        <div className="col-6 col-sm">
                            <div className="empwallet">
                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Food}</p>
                                <h6 className="food"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>

                        <div className="col-6 col-sm">
                            <div className="empwallet">
                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Travel}</p>
                                <h6 className="travel"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4">
            <div className="bandcard">
                <div className="bandtype">
                    <i></i>
                    <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                    <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </div>


                <div className="empband">


                    {/* <button

                        className="sbtn btnbl"
                    > Update</button> */}


                    <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Employees}</p>
                    <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                </div>

                <div className="walletrow">
                    <div className="row">
                        <div className="col-6 col-sm">
                            <div className="empwallet">

                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_General}</p>
                                <h6 className="general"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>

                        <div className="col-6 col-sm">
                            <div className="empwallet">
                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Food}</p>
                                <h6 className="food"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>

                        <div className="col-6 col-sm">
                            <div className="empwallet">
                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Travel}</p>
                                <h6 className="travel"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-12 col-md-6 col-xl-4">
            <div className="bandcard">
                <div className="bandtype">
                    <i></i>
                    <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                    <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </div>


                <div className="empband">


                    {/* <button

                        className="sbtn btnbl"
                    > Update</button> */}


                    <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Employees}</p>
                    <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                </div>

                <div className="walletrow">
                    <div className="row">
                        <div className="col-6 col-sm">
                            <div className="empwallet">

                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_General}</p>
                                <h6 className="general"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>

                        <div className="col-6 col-sm">
                            <div className="empwallet">
                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Food}</p>
                                <h6 className="food"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>

                        <div className="col-6 col-sm">
                            <div className="empwallet">
                                <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Travel}</p>
                                <h6 className="travel"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}