

import React from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';



class MoneyDisbursalRecordFilterComponent extends React.Component {
    constructor(props) {
        super();
        this.state = {

            placeHolder: '...',
            multiValueCategory: [
                { value: "Genral", label: "General" },
                { value: "Food", label: "Food" },
                { value: "Travel", label: "Travel" }
            ],
            multiValueStatus: [
                { value: "0", label: "Disbursed" },
                { value: "2", label: "Pending" },

            ],

        };

    }

    componentDidMount() {
        // window.history.pushState(null, document.title, window.location.href);
        // window.addEventListener('popstate', function (event){
        //     window.history.pushState(null, document.title,  window.location.href);
        // });
    }

    render() {

        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };

        console.log('props Filter Component', this.props.type === "maker")


        return (
            <div className="head">

                <div className="row">
                   

                    <div  className="col-10">
                        <h3>{this.props.varForHeader}</h3>
                    </div>



                    <div className="col-2">
                        <div className="flterbox">

                            <div className="dropdown " data-for="back" data-tip="Filter" data-iscapture="true">
                                <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                <div className="dropdown-menu dropdown-menu-right">
                                    {/* <i className="closefil">x</i> */}

                                    <div className="filters">
                                        <label htmlFor="select-state">Band:</label>
                                        <Select
                                            name="filters"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiValueB}
                                            options={this.props.bandData}
                                            onChange={this.props.handleMultiBandchange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />
                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">Category:</label>
                                        <Select
                                            name="filters"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiValueC}
                                            options={this.state.multiValueCategory}
                                            onChange={this.props.handleMultiCategorychange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />
                                    </div>
                                    {this.props.success === "success"
                                        ?

                                        <div className="filters">
                                            <label htmlFor="select-state">Reason:</label>
                                            <Select
                                                name="filters"
                                                placeholder={this.state.placeHolder}
                                                value={this.props.multiValueR}
                                                options={this.props.reasonMultiValue}
                                                onChange={this.props.handleStatuschange}
                                                isMulti={true}
                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                styles={customStyles}
                                            />
                                        </div>

                                        :
                                        <div className="filters">
                                            <label htmlFor="select-state">Status:</label>
                                            <Select
                                                name="filters"
                                                placeholder={this.state.placeHolder}
                                                value={this.props.multiValueS}
                                                options={this.state.multiValueStatus}
                                                onChange={this.props.handleStatuschange}
                                                isMulti={true}
                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                styles={customStyles}
                                            />
                                        </div>
                                    }
                                    <div className="fbtn">
                                        <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.props.clearAll}>Clear all</button>
                                    </div>

                                </div>

                            </div>
                        </div>



                        <div className="actnbtn">

                            <a href="/money-disbursal"  data-toggle="tooltip" title="Back" className="back"><i className="back"></i> </a>
                            {
                                this.props.type === "checker"
                                    ?
                                    null
                                    :
                                    this.props.singleCheck
                                        ?
                                        <>
                                            {this.props.otpButton
                                                ?

                                                <a data-toggle="modal" title="Approve" className="approve" onClick={this.props.otpGeneration}><i className="tick"></i> </a>
                                                :
                                                <a data-bs-toggle="modal" id="otpPopupOpen" data-bs-target="#approve" title="Approve" className="approve" ><i className="tick"></i> </a>

                                            }
                                            <a data-bs-toggle="modal" data-bs-target="#reject" title="Reject" className="reject" ><i className="reject"></i> </a>
                                        </>
                                        :
                                        <>
                                            <a data-toggle="tooltip" title="Approve" className="approve" ><i className="tick"></i> </a>
                                            <a data-toggle="tooltip" title="Reject" className="reject" ><i className="reject"></i> </a>
                                        </>

                            }
                        </div>
                    </div>



                </div>


            </div>




        )
    }

}



const mapStateToProps = ({ band: { bandData }, location: { locationData }, location: { departmentaList } }) => ({
    bandData,
    locationData,
    departmentaList
});


export default connect(mapStateToProps)(MoneyDisbursalRecordFilterComponent);
