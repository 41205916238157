
class GenerateRequestId {

  geneRateRequestId() {
    const d = Date.now();
    //const n = d.getMilliseconds();
    const val = Math.floor(1000 + Math.random() * 9000);
    console.log(d, ' ', val, ' ', `${d}${val}`)
    return `${d}${val}`;
  }

  generateCardNumber() {
    const d = Date.now();
    //const n = d.getMilliseconds();
    const val = Math.floor(1000 + Math.random() * 900);
    console.log(d, ' generateCardNumber ', ' ', `${d}`)
    return `VIR${d}`;
  }

  generateRONumber() {
    const d = Date.now();
    //const n = d.getMilliseconds();
    const val = Math.random().toString().slice(2,12);
    console.log(val, ' generateCardNumber ', ' ', `${d}`)
    return `${val}`;
  }
  
  Capitalize(str) {
   
    if (str===null || str === undefined) {
     
      return ''
    } else {
     
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
}

export default new GenerateRequestId();