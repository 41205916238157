import React from 'react';
import UploadCard from '../component/upload-card.component';


const UploadList = ({ employeeList, responseStatus }) => {

    console.log('insufr dcrd jift ', employeeList)
    return (
        <>
          

                {
                    employeeList.map(bulkDetail => (
                        <UploadCard bulkDetail={bulkDetail} responseStatus={responseStatus} />
                    ))
                }

         
        </>
    )
};



export default UploadList;
