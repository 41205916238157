import React, { useState, useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import ApiCallingService from '../../service/api-call-service';
import "../css/web-menu.styles.css";
import Can from '../../auth/can/Can';
import { connect } from "react-redux";
import { store } from '../../redux/store';
import { setBand } from '../../redux/band/band.action'
import { setCorporateWallet, setMaxFoodAmount, setMaxGeneralAmount, setMaxTravelAmount, setCorpBandLimit, setBankId, setCorporateName, setUserRole, setCorpBankDetail } from '../../redux/user/user.actions'
import { setLocation, setDepartmentList } from '../../redux/location/location.action'
import { setMasterRole } from '../../redux/kyc/kyc.action';
import { setKyc } from '../../redux/kyc/kyc.action';
import { setCardStatusAllowe } from '../../redux/kyc/kyc.action';

import apiCallService from "../../service/api-call-service";
import { setHuburgShowOrHide, setRoleAdminBoolean, setRoleData, setUserMaintain } from "../../redux/role/role.action";

import rules from '../../auth/rules/rbac-rules';


const SubHeader = ({ adminDashboardClass, userMaintain, currentUser, adminMngCardClass, adminReportCLass, userRole, roleData,
  roMdn, dashBoardClass, manageEmployeeClass, moneyDisbursalClass, manageCardClass, manageLimitClass, activityClass, manageGiftCardClass, corpListClass }) => {

  const [response, setResponse] = useState([]);

  const [email, setEmail] = useState('');

  const [userClass, setUserClass] = useState('btn ');
  const [adminClass, setAdminClass] = useState('btn active');


  const [showSwitch, setShowSwitch] = useState(false);


  useEffect(() => {


    if (parseInt(userMaintain) === 0) {
      setUserClass('btn active');
      setAdminClass('btn ');
    }

    if (parseInt(userMaintain) === 1) {
      setUserClass('btn ');
      setAdminClass('btn active');
    }

    const fetchPosts = async () => {
      async function run() {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        // console.log(url);
        console.log(roMdn)
        const res = await ApiCallingService.getMethodCallWithParam(url, { 'roId': roMdn });
        console.log('response inside AuthService', res);
        if (!(res === undefined) && !(res.response === undefined) && res.response !== undefined) {
          store.dispatch(setBankId(res.response.bankId));
          store.dispatch(setCorporateName(res.response.name));
          store.dispatch(setCorpBankDetail(res.response.corpBankDetail));

        }


        if (!(res === undefined) && !(res.response === undefined) && !(res.response.walletTypes === undefined)) {
          store.dispatch(setCorporateWallet(res.response.walletTypes))
        }
        if (!(res === undefined) && !(res.response === undefined) && !(res.response.walletFoodLimit === undefined)) {
          store.dispatch(setMaxFoodAmount(res.response.walletFoodLimit))
        }
        if (!(res === undefined) && !(res.response === undefined) && !(res.response.genrealLimit === undefined)) {
          store.dispatch(setMaxGeneralAmount(res.response.genrealLimit))
        }
        if (!(res === undefined) && !(res.response === undefined) && !(res.response.travelLimit === undefined)) {
          store.dispatch(setMaxTravelAmount(res.response.travelLimit))
        }

        const band = [];
        const bandLimit = [];
        if (!(res.response === undefined) && !(res === undefined) && !(res.response.corpBands === undefined) && !(res.response.corpBands === null)) {

          res.response.corpBands.map(corpBand => {
            const obj = {
              label: '',
              value: ''
            }
            obj.label = corpBand.band;
            obj.value = corpBand.band;
            band.push(obj);
            const objLimit = {
              band: "",
              general_Limit: "0",
              travel_Limit: "",
              wallet_food_limit: ""
            }
            objLimit.band = corpBand.band;
            objLimit.general_Limit = corpBand.general_Limit;
            objLimit.travel_Limit = corpBand.travel_Limit;
            objLimit.wallet_food_limit = corpBand.wallet_food_limit;
            bandLimit.push(objLimit);
          })

          store.dispatch(setBand(band));
          store.dispatch(setCorpBandLimit(bandLimit));
          const location = [];
          res.response.locations.map(corpBand => {
            const obj = {
              label: '',
              value: ''
            }
            obj.label = corpBand.name;
            obj.value = corpBand.name;
            location.push(obj);
          })
          store.dispatch(setLocation(location));

          // console.log(store)
        }

        const departments = [];
        if (!(res.response === undefined) && !(res === undefined) && !(res.response.departments === undefined) && !(res.response.departments === undefined)) {
          res.response.departments.map(department => {
            const obj = {
              name: '',
              value: '',
              label: '',
            }
            obj.name = department.name
            obj.value = department.name
            obj.label = department.name
            departments.push(obj);
          })
          store.dispatch(setDepartmentList(departments));
          console.log(store);
        }





        const urlRole = `${process.env.REACT_APP_OEMS_ACL_GET_ROLE_ACCESS_DETAIL_BY_ROID}?roId=${roMdn}`;
        const resRole = await ApiCallingService.getMethodCallWithoutParam(urlRole);
        const roleArray = [];
        // console.log('resRole from acl conversion goes successfull', resRole);
        if (!(resRole === undefined) && resRole.response_code !== undefined && resRole.response_code === 0 && !(resRole.responseDetail === undefined) && !(resRole.responseDetail === null)) {
          resRole.responseDetail.map(role => {
            const obj = {
              label: '',
              value: '',
              maxUserAllowed: 0
            }
            obj.label = role.roleName;
            obj.value = role.roleId;
            obj.maxUserAllowed = role.maxUser
            roleArray.push(obj);
          })
          store.dispatch(setMasterRole(roleArray));

          // console.log(store)
        }



        const urlKyc = process.env.REACT_APP_API_GET_KYC;
        const resKyc = await ApiCallingService.getMethodCallWithParam(urlKyc, { "ro_Id": roMdn });
        const kycArray = [];
        if (!(resKyc === undefined) && !(resKyc.response === undefined) && !(resKyc.response.GetKyc === undefined) && !(resKyc.response.GetKyc === null)) {
          resKyc.response.GetKyc.map(kyc => {
            const obj = {
              label: '',
              value: ''
            }
            obj.label = kyc.kyc_status;
            obj.value = kyc.kyc_id;
            kycArray.push(obj);
          })
          store.dispatch(setKyc(kycArray));

          // console.log(store)
        }



        const urlCardStatus = process.env.REACT_APP_API_GET_ALLOWED_CARD_STATUS;
        const resCardStatus = await ApiCallingService.getMethodCallWithParam(urlCardStatus);
        const cardStatusArray = [];
        if (!(resCardStatus === undefined) && !(resCardStatus.response === undefined) && !(resCardStatus.response.GetCard === undefined) && !(resCardStatus.response.GetCard === null)) {
          resCardStatus.response.GetCard.map(cardStatus => {
            const obj = {
              label: '',
              value: ''
            }
            obj.label = cardStatus.card_status_name;
            if (parseInt(cardStatus.card_status) === 4) {
              obj.value = `${cardStatus.card_status},5`;
            } else {
              obj.value = cardStatus.card_status;
            }

            cardStatusArray.push(obj);
          })
          store.dispatch(setCardStatusAllowe(cardStatusArray));

          // console.log(store)
        }

        setResponse(res)
      }
      run();
    }
    fetchPosts();



    getShowAvailOrNot();
  }, []);

  const getShowAvailOrNot = async (event) => {
    const url = `${process.env.REACT_APP_ACL_URL}/${currentUser}/${roMdn}/1`;


    // const url = `${process.env.REACT_APP_ACL_URL}/${currentUser}/${roMdn}`;


    var res = await apiCallService.getMethodCallWithoutParam(url);




    if (res !== undefined && res !== undefined && res.actionRoleResponses !== undefined && res.actionRoleResponses !== null) {

      const permissions = res.actionRoleResponses;
      var data = []
      var x;
      var userRole;
      for (x of permissions) {
        data.push(x.action);
        localStorage.setItem('role', x.role);
        userRole = x.role;
        console.log(rules);

      }
      console.log(userRole)
      localStorage.setItem('staticData', data);
      rules.admin.static = data;

      if (data.includes('subheader-adminSwitch:visit')) {
        setShowSwitch(true);
      } else {
        setShowSwitch(false);
      }


    }




  }



  const changeUserFunction = async (event) => {
    console.log("event.target.id", event.target.id);


    var id = event.target.id;

    const url = `${process.env.REACT_APP_ACL_URL}/${currentUser}/${roMdn}/${event.target.id}`;



    // const url = `${process.env.REACT_APP_ACL_URL}/${currentUser}/${roMdn}`;


    var res = await apiCallService.getMethodCallWithoutParam(url);


    console.log("changeUserFunction response", res);




    if (res !== undefined && res !== undefined && res.actionRoleResponses !== undefined && res.actionRoleResponses !== null) {


      if (parseInt(id) === 0) {
        setUserClass('btn active');
        setAdminClass('btn ');
      }

      if (parseInt(id) === 1) {
        setUserClass('btn ');
        setAdminClass('btn active');
      }

      store.dispatch(setUserMaintain(id));

      const permissions = res.actionRoleResponses;
      var data = []
      var x;
      var userRole;
      for (x of permissions) {
        data.push(x.action);
        localStorage.setItem('role', x.role);
        userRole = x.role;
        console.log(rules);

        //console.log(x.actionName);
      }
      console.log(userRole)
      localStorage.setItem('staticData', data);
      rules.admin.static = data;
      console.log('store', store)
      store.dispatch(setRoleData(data));

      // store.dispatch(setRoleData([]));



      if (data.includes('moneyDisburse-checker:success')) {
        store.dispatch(setRoleAdminBoolean(0));
        console.log('inside check method', userRole);
      } else {
        store.dispatch(setRoleAdminBoolean(1));
      }


      if (data.includes("empmng-remove:visit") || data.includes("empmng-tBlock:visit") || data.includes("empmng-tBlock:visit") || data.includes("empmng-pBlock:visit") || data.includes("empmng-edit:visit")) {
        store.dispatch(setHuburgShowOrHide(true));
        console.log('inside check method', userRole);
      } else {
        store.dispatch(setHuburgShowOrHide(false));
      }



      store.dispatch(setUserRole(userRole));
      console.log('rules', rules);
      console.log(store);


      if (parseInt(id) === 0) {
        document.getElementById("dashboardButton").click();
      }

      if (parseInt(id) === 1) {
        document.getElementById("adminDashboardButton").click();
      }



    }






  }








  console.log("this.menu.childNodes.length", document.getElementById("menu23") !== undefined && document.getElementById("menu23") !== null);
  var liLength = 0
  if (document.getElementById("menu23") !== undefined && document.getElementById("menu23") !== null) {
    liLength = document.getElementById("menu23").childNodes.length;
    console.log("this.menu.childNodes.length", document.getElementById("menu23").childNodes.length);
  }

  return (


    <div className="menu-container">
      <div className="container-fluid">
      <div className="adminButton" >

{
  showSwitch
    ?
    <div className="btn-group swichuser">View:&nbsp;
      {
        userClass !== undefined && userClass.includes('active')
          ?
          <button className={userClass} id="0" >User</button>
          :
          <button className={userClass} id="0" onClick={e => changeUserFunction(e)} >User</button>

      }
      {
        adminClass !== undefined && adminClass.includes('active')
          ?
          <button className={adminClass} id="1" >Admin</button>
          :
          <button className={adminClass} id="1" onClick={e => changeUserFunction(e)} >Admin</button>

      }
    </div>
    :
    null
}
</div>

        <nav className="navbar navbar-expand-lg">

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu">

            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="collapse navbar-collapse menu" id="menu">
            <ul className={liLength < 7 ? "navbar-nav" : "navbar-nav extraMenu"} id="menu23">

              <Can
                role={userRole}
                perform="subheader-dashboard:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/dashboard" className={dashBoardClass} id="dashboardButton">
                      <i className="dashboard"></i>{process.env.REACT_APP_Var_FOR_NavBar_Dashboard}
                    </NavLink>
                  </li>
                )}
                no={() =>
                  null
                }
              />



              <Can
                role={userRole}
                perform="subheader-admindashboard:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/corp-dashboard" className={adminDashboardClass} id="adminDashboardButton">
                      <i className="dashboard"></i> {process.env.REACT_APP_Var_FOR_NavBar_Admin_Dashboard}
                    </NavLink>
                  </li>
                )}
                no={() =>
                  null
                }
              />


              <Can
                role={userRole}
                perform="subheader-corpManage:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/corp-list" className={corpListClass} >
                      <i className="employee"></i>{process.env.REACT_APP_Var_FOR_NavBar_Manage_Corporate}
                    </NavLink>
                  </li>
                )}
                no={() =>
                  null
                }
              />



              <Can
                role={userRole}
                perform="subheader-empmng:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/emp-list" className={manageEmployeeClass} >
                      <i className="employee"></i>{process.env.React_APP_Var_FOR_NavBar_ManageEmployee}
                    </NavLink>
                  </li>
                )}
                no={() =>
                  null
                }
              />


              <Can
                role={userRole}
                perform="subheader-moneyDisbursal:visit"
                roleData={roleData}
                yes={() => (

                  <li>
                    <NavLink to="/money-disbursal" className={moneyDisbursalClass} >
                      <i className="disbursal"></i>{process.env.React_APP_Var_FOR_NavBar_MoneyDisbursal}
                    </NavLink>
                  </li>
                )}
                no={() =>
                  null
                }
              />



              <Can
                role={userRole}
                perform="subheader-manageReversal:visit"
                roleData={roleData}
                yes={() => (
                  <li><a href="corp-manage-reversal"><i className="disbursal"></i>Manage Reversal</a></li>

                )}
                no={() =>
                  null
                }
              />


              <Can
                role={userRole}
                perform="subheader-manageCard:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/manage-card" className={manageCardClass} >
                      <i className="cards"></i>{process.env.React_APP_Var_FOR_NavBar_ManageCard}
                    </NavLink>
                  </li>

                )}
                no={() =>
                  null
                }
              />


              <Can
                role={userRole}
                perform="subheader-manageGiftCard:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/manage-giftCard" className={manageGiftCardClass} >
                      <i className="gifts"></i>Gift Card
                    </NavLink>
                  </li>

                )}
                no={() =>
                  null
                }
              />


              <Can
                role={userRole}
                perform="subheader-adminmanageCard:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/admin-manage-card" className={adminMngCardClass} >
                      <i className="cards"></i>{process.env.REACT_APP_Var_FOR_NavBar_Admin_Manage_Card}
                    </NavLink>
                  </li>

                )}
                no={() =>
                  null
                }
              />




              <Can
                role={userRole}
                perform="subheader-allEmpTxnHistory:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/allEmp-transaction-report" className={adminMngCardClass} >
                      <i className="reports"></i>{process.env.React_APP_Var_FOR_NavBar_Reports_Toggle_TrasactionReport}
                    </NavLink>
                  </li>

                )}
                no={() =>
                  null
                }
              />




              {/* <Can
                role={userRole}
                perform="subheader-reports:visit"
                roleData={roleData}
                yes={() => (
                  <li className="dropdown">
                    <a
                      className={`dropdown-toggle ${manageLimitClass}`}
                      href={() => false}
                      id="navbarDropdown"
                      data-bs-toggle="dropdown"
                    >


                      <i className="reports"></i> {process.env.React_APP_Var_FOR_NavBar_Reports}


                    </a>

                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">


                      <Can
                        role={userRole}
                        perform="subheader-allEmpTxnHistory:visit"
                        roleData={roleData}
                        yes={() => (
                          <li>
                            <a href="/allEmp-transaction-report">{process.env.React_APP_Var_FOR_NavBar_Reports_Toggle_TrasactionReport}</a>
                          </li>

                        )}
                        no={() =>
                          null
                        }
                      />



                      <li>
                        <NavLink to="/ta-report">{process.env.React_APP_Var_FOR_NavBar_Reports_Toggle_TAReport}</NavLink>
                      </li>


                    </ul>
                  </li>

                )}
                no={() =>
                  null
                }
              /> */}


              {/* <Can
                role={userRole}
                perform="subheader-adminreports:visit"
                roleData={roleData}
                yes={() => ( */}
                  {/* // <li className="dropdown"> */}
                    {/* <a
                      className={`dropdown-toggle ${adminReportCLass}`}
                      href={() => false}
                      id="navbarDropdown"
                      data-toggle="dropdown"
                    >


                      <i className="reports"></i>{process.env.React_APP_Var_FOR_NavBar_Admin_Reports}


                    </a> */}

                    {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown"> */}

                      {/* "subheaderReports-misReport:visit" */}
                      {/* <li><NavLink to="/mis-report">{process.env.React_APP_Var_FOR_NavBar_Admin_Reports_MIS_Daily}</NavLink></li> */}
                      {/* "subheaderReports-cardBalanceReport:visit" */}
                      {/* <li><NavLink to="/card-balance-report">{process.env.React_APP_Var_FOR_NavBar_Admin_Reports_RuPay_Card_Balance}</NavLink></li> */}
                      {/* "subheaderReports-transactionDumpReport:visit" */}
                      {/* <li><NavLink to="/transaction-dump-report" >{process.env.React_APP_Var_FOR_NavBar_Admin_Reports_Rupay_Transaction_Dump}</NavLink></li>


                    </ul>
                  </li>

                )}
                no={() =>
                  null
                }
              /> */}




              <Can
                role={userRole}
                perform="subheader-activity:visit"
                roleData={roleData}
                yes={() => (
                  <li>
                    <NavLink to="/activity-log" className={activityClass}>
                      <i className="activity"></i>{process.env.React_APP_Var_FOR_NavBar_ActivityLog}
                    </NavLink>
                  </li>

                )}
                no={() =>
                  null
                }
              />




            </ul>
          </div>
        </nav>

      </div>

    </div>

  )

};

const mapStateToProps = ({ user: { userRole }, user: { roMdn, currentUser }, role: { roleData, userMaintain }, activeHeader: { dashBoardClass, manageEmployeeClass, moneyDisbursalClass,
  manageCardClass, manageLimitClass, activityClass, corpListClass, adminDashboardClass, adminMngCardClass, adminReportCLass, manageGiftCardClass } }) => ({
    userRole,
    roleData, manageEmployeeClass, moneyDisbursalClass, manageCardClass, adminDashboardClass, adminMngCardClass, adminReportCLass,
    roMdn, dashBoardClass, manageLimitClass, activityClass, corpListClass, currentUser, userMaintain, manageGiftCardClass
  });

export default connect(mapStateToProps)(SubHeader);



