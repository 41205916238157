
import { ForgotPasswordActionTypes } from './forgot-password.type';



export const setOTP = data => ({
    type: ForgotPasswordActionTypes.SET_OTP,
    payload: data
})

export const setEmail = data => ({
    type: ForgotPasswordActionTypes.SET_EMAIL,
    payload: data
})

export const setRefernceNumber = data => ({
    type: ForgotPasswordActionTypes.SET_REFERNECE_NUM,
    payload: data
})


