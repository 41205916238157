import React from 'react';
import { connect } from 'react-redux';
import apiCallService from '../../service/api-call-service';
import UploadMoneyDisbersalList from './card-list.component';
import Pagination from 'react-js-pagination';
import UploadMoneyDisbursal from '../component/upload-money-disbursal.component';
import DisburseListFilterComponent from '../component/DisburseListFilter';
import Can from '../../auth/can/Can';
import moment from 'moment';
import { DataTable } from 'react-mdl';
import LoadingComponent from './loading.component';




const collectedTrueKeys = {
    isBulk: [],
    successsCountCondition: [],
    failedCountCondition: [],
    approveStatusVar: [],
}

var ToDateVar = '';
var FromDateVar = '';
class MoneyDisbursalCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            currentPage: 1,
            loading: false,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL,
            size: 0,
            filteredRecord: [],
            multiValueType: [],
            multiValueRecord: [],
            multiValueStatus: [],
            toDate: '',
            fromDate: '',
            paginatedList: [],
            allValuesForFilter: [],
            urlMaster: false,
            loading: true,

        };
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.methodToSetList();

    }

    methodToSetList = async () => {


        var urlMaster = false

        if (this.props.roleData.includes('moneyDisburse-superAdmin:visit')) {
            urlMaster = true
            this.setState({ urlMaster: true });
        }


        if (urlMaster) {


            const url = process.env.REACT_APP_BULK_DISBERSAL_GETALL_BATCHID;
            // console.log('MoneyDisbursalCard url', `${url}/${this.props.roMdn}`)


            var response = await apiCallService.getMethodCallWithoutParam(`${url}/${this.props.roMdn}`, request);

            console.log('MoneyDisbursalCard url response urlMaster', `${url}/${this.props.roMdn}`, response);
            console.log('MoneyDisbursalCard url response urlMaster', response.responses, request);
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.responses !== undefined && response.batchSize !== null) {
                this.setState({ size: response.listSize });
                var list = [];
                response.responses.map(resp => {
                    var data = {
                        batchIds: [],
                        uploadedDate: '',
                        dateInWantedFormat: ''
                    }
                    resp.batchIds.map(dt => {
                        data.batchIds.push(dt);
                    })
                    data.uploadedDate = resp.uploadedDate;
                    // console.log('data.uploadedDate  data.dateInWantedFormat', data.uploadedDate)
                    var date1 = resp.uploadedDate
                    // console.log('date1 original data.dateInWantedFormat', date1)
                    // var date23 = moment(date1).format('DD-MM-YYYY')
                    // console.log('date2  data.dateInWantedFormat date23', date23)
                    var det = date1.split('-');


                    var timeAllag = det[2].split(' ')
                    // console.log('date2 timeAllag  data.dateInWantedFormat det', timeAllag, timeAllag[0])


                    // console.log('date2 split array  data.dateInWantedFormat det', det)
                    // var date2 = `${det[1]}-${det[0]}-${det[2]}`
                    var date2 = `${timeAllag[0]}-${det[1]}-${det[0]}`
                    // console.log('date2 joined after split data.dateInWantedFormat', date2)
                    var date = new Date(date2);
                    // console.log('date after date conversion data.dateInWantedFormat', date)
                    var wanted1 = date.toString();
                    var wanted = wanted1.split(" ");
                    // console.log('wanted  data.dateInWantedFormat', wanted)
                    var dateWanted = `${wanted[0]} ${wanted[2]} ${wanted[1]} ${wanted[3]}`
                    // console.log('dateWanted  data.dateInWantedFormat', dateWanted)
                    data.dateInWantedFormat = dateWanted;
                    // console.log(' data.dateInWantedFormat  data.dateInWantedFormat', data.dateInWantedFormat)
                    list.push(data);
                })
                this.setState({ listData: list });
                this.setState({ filteredRecord: list });
                this.setState({ loading: false });


                // console.log(' Int works till here')
            } else {
                this.setState({ loading: false });
            }

        } else {

            const url = process.env.REACT_APP_GET_ALL_BATCHID_BY_ROLENAME;
            // console.log('MoneyDisbursalCard url', `${url}/${this.props.roMdn}`)
            var request = {
                "roId": this.props.roMdn,
                "roleName": this.props.roleForAdmin
            }

            var response = await apiCallService.postMethodCall(url, request);

            console.log('MoneyDisbursalCard url response', response);
            console.log('MoneyDisbursalCard url response', response.responses, request);
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.responses !== undefined && response.batchSize !== null) {
                this.setState({ size: response.listSize });
                var list = [];
                response.responses.map(resp => {
                    var data = {
                        batchIds: [],
                        uploadedDate: '',
                        dateInWantedFormat: ''
                    }
                    resp.batchIds.map(dt => {
                        data.batchIds.push(dt);
                    })
                    data.uploadedDate = resp.uploadedDate;
                    // console.log('data.uploadedDate  data.dateInWantedFormat', data.uploadedDate)
                    var date1 = resp.uploadedDate
                    // console.log('date1  data.dateInWantedFormat', date1)
                    // var date23 = moment(date1).format('DD-MM-YYYY')
                    // console.log('date2  data.dateInWantedFormat date23', date23)
                    var det = date1.split('-');

                    var timeAllag = det[2].split(' ')
                    // console.log('date2 timeAllag  data.dateInWantedFormat det', timeAllag, timeAllag[0])


                    // console.log('date2 split array  data.dateInWantedFormat det', det)
                    // var date2 = `${det[1]}-${det[0]}-${det[2]}`
                    var date2 = `${timeAllag[0]}-${det[1]}-${det[0]}`
                    // console.log('date2 joined after split data.dateInWantedFormat', date2)
                    var date = new Date(date2);
                    // console.log('date after date conversion data.dateInWantedFormat', date)
                    var wanted1 = date.toString();
                    var wanted = wanted1.split(" ");
                    // console.log('wanted  data.dateInWantedFormat', wanted)
                    var dateWanted = `${wanted[0]} ${wanted[2]} ${wanted[1]} ${wanted[3]}`
                    // console.log('dateWanted  data.dateInWantedFormat', dateWanted)
                    data.dateInWantedFormat = dateWanted;
                    // console.log(' data.dateInWantedFormat  data.dateInWantedFormat', data.dateInWantedFormat)
                    list.push(data);
                })
                this.setState({ listData: list });
                this.setState({ filteredRecord: list });
                this.setState({ loading: false });


                // console.log(' Int works till here')
            } else {
                this.setState({ loading: false });
            }

        }
    }





    handleMultiTypechange = (option) => {
        // ((bandListToView === null || !(bandListToView.length && bandListToView)
        this.setState({ multiValueType: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.isBulk = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.isBulk.push(parseInt(op.value))

            })
        }

    }
    handleMultiRecordchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueRecord: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.successsCountCondition = []
        collectedTrueKeys.failedCountCondition = []


        // { value: "1", label: "Success" },
        // { value: "1", label: "Failed" }



        if (option !== null) {
            option.map(op => {
                if (op.label === "Failed") {
                    collectedTrueKeys.failedCountCondition.push(parseInt(op.value))
                }
                if (op.label === "Success") {
                    collectedTrueKeys.successsCountCondition.push(parseInt(op.value))
                }

            })
        }

    }
    handleMultiStatuschange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueStatus: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.approveStatusVar = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.approveStatusVar.push(op.value)

            })
        }

    }

    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");

            const requestToSend = {
                "roMdn": this.props.roMdn,
                "fromDate": `${formatFromDate} 00:00:00`,
                "toDate": `${formatToDate} 23:59:59`,
                "roleName": this.props.roleForAdmin

            }
            var response = []
            if (this.state.urlMaster) {
                const url = process.env.REACT_APP_MONEY_BATCH_BY_DATERANGE;
                console.log('request to send :', requestToSend, "url :", url)
                response = await apiCallService.postMethodCall(url, requestToSend);
            } else {
                const url = process.env.REACT_APP_MONEY_BATCH_BY_DATERANGE;
                console.log('request to send :', requestToSend, "url :", url)
                response = await apiCallService.postMethodCall(url, requestToSend);
            }



            console.log('response', response)

            if (!(response === undefined) && (response.response_code === 0) && (response.responses !== undefined) && (response.batchSize !== null)) {

                this.setState({ filteredRecord: response.responses })
                this.setState({ currentPage: 1 })
            } else {
                this.setState({ filteredRecord: [] })
            }

        }
    }



    convertListToDateWiseList = (listWithoutDate) => {

        var dateWiseList = [];
        var downloadDates = [];

        listWithoutDate.map(batch => {
            downloadDates.push(batch.uploadedDate);
        })

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        var filteredPostData = downloadDates.filter(onlyUnique);

        filteredPostData.map(dwnldDate => {
            // console.log('dateWiseList dwnldDate', dwnldDate)
            var data = {
                uploadedDate: '',
                batchIds: [],
                dateInWantedFormat: ''
            }
            listWithoutDate.map(batch => {
                // console.log('dateWiseList dwnldDate', batch.dateInWantedFormat)
                if (batch.uploadedDate === dwnldDate) {
                    data.uploadedDate = batch.uploadedDate;
                    data.dateInWantedFormat = batch.dateInWantedFormat
                    data.batchIds.push(batch);
                }
            })
            dateWiseList.push(data);
        })
        // console.log('dateWiseList inFunction', dateWiseList)

        return dateWiseList;


    }

    convertDateWiseListToList = (dateWiseList) => {
        var listWithoutDate = [];

        // console.log('dateWiseList before calculation', dateWiseList);

        dateWiseList.map(wise => {
            wise.batchIds.map(batch => {
                var dateInWantedFormat = "dateInWantedFormat"
                var uploadedDate = "uploadedDate"
                batch[uploadedDate] = wise.uploadedDate;
                batch[dateInWantedFormat] = wise.dateInWantedFormat;
                listWithoutDate.push(batch);
            })
        })

        // console.log('listWithoutDate after calculation', listWithoutDate)

        return listWithoutDate;

    }

    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.listData })
        this.setState({ multiValueType: [] })
        this.setState({ multiValueStatus: [] })
        this.setState({ multiValueRecord: [] })

        ToDateVar = '';
        FromDateVar = '';

        this.setState({ allValuesForFilter: [] })
        collectedTrueKeys.isBulk = []
        collectedTrueKeys.successsCountCondition = []
        collectedTrueKeys.failedCountCondition = []
        collectedTrueKeys.approveStatusVar = []
        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)
    }

    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

    };

    render() {

        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };

        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;

        var listWithoutDate = this.convertDateWiseListToList(this.state.filteredRecord);

        // console.log('List that needs to filter', listWithoutDate);





        var filteredPost = [];

        if ((this.state.allValuesForFilter === null || !(this.state.allValuesForFilter !== undefined && this.state.allValuesForFilter.length && this.state.allValuesForFilter))) {
            filteredPost = listWithoutDate
            // filteredPost = filteredList;
            // console.log('inside if of bandlistToView', this.state.allValuesForFilter)
        } else if (this.state.allValuesForFilter.length !== 0) {

            filteredPost = multiPropsFilter(listWithoutDate, collectedTrueKeys);

            // console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)

        }





        const key = 'batchId';

        const filteredPostData = [...new Map(filteredPost.map(item =>
            [item[key], item])).values()];





        var size = filteredPostData.length;
        // console.log('size dateWiseList', size);



        var listWithDate = this.convertListToDateWiseList(filteredPostData);

        // console.log('List that comes from filter dateWiseList', listWithDate);

        if (this.state.listData === null || this.state.listData === undefined) {
            currentPost = null
        } else {

            if (listWithDate !== null) {


                var finalArr = [];

                var i = 0

                listWithDate.map(ls => {
                    var data = {
                        uploadedDate: '',
                        batchIds: [],
                        dateInWantedFormat: ''
                    }
                    data.uploadedDate = (ls.uploadedDate);
                    data.dateInWantedFormat = ls.dateInWantedFormat;
                    var entry = false;
                    ls.batchIds.map(batch => {
                        i++
                        if (i > indexOfFirstPost && i <= indexOfLastPost) {
                            data.batchIds.push(batch);
                            entry = true
                        }
                    })
                    if (entry) {
                        finalArr.push(data);
                    }

                })
                // console.log('pageNumber,this.state.postsPerPage finalArr', finalArr)

                // console.log('pageNumber,this.state.postsPerPage ', this.state.listData)
                // this.setState({filteredRecord:finalArr})

                currentPost = finalArr
            } else if (listWithDate === null) {
                currentPost = [];
            }
        }

        // console.log('pageNumber,this.state.postsPerPage currentpost', currentPost);


        // console.log('pageNumber,this.state.postsPerPage listData', parseInt(this.state.size) > parseInt(this.state.postsPerPage))

        return (

            <>
                <div className="col-12 col-lg-8 col-xl-9">




                    <DisburseListFilterComponent multiValueType={this.state.multiValueType} multiValueStatus={this.state.multiValueStatus} handleMultiStatuschange={this.handleMultiStatuschange} multiValueRecord={this.state.multiValueRecord} handleMultiRecordchange={this.handleMultiRecordchange}
                        multiTypeRecord={this.state.multiTypeRecord} handleMultiTypechange={this.handleMultiTypechange} toDate={this.setState.toDate} fromDate={this.state.fromDate} handleDateChange={this.handleDateChange} clearAll={this.clearAll} />

                    <div className="emp-list">
                        <div className="row">




                            {this.state.loading ?

                                <LoadingComponent />
                                :


                                currentPost.length > 0 ?
                                    currentPost.map(data => (
                                        <>
                                            <div className="col-12">
                                                <div className="timeline">{(data.dateInWantedFormat)}</div>
                                            </div>

                                            {data.batchIds.map(list => (

                                                <UploadMoneyDisbersalList data={list} uploadedOn={list.downloadDate} />

                                            ))}

                                        </>
                                    ))
                                    :
                                    <div className="col-12">
                                        <div className="no-record">
                                            <i></i>
                                            <h3> No Record Available.</h3>
                                        </div>
                                    </div>




                            }



                            {
                                parseInt(size) > parseInt(this.state.postsPerPage) ?
                                    <div className="row">

                                        <div className="col-12 col-sm-6">
                                            <p>Showing {valueToShow} to {(indexOfLastPost > size) ? size : indexOfLastPost} of {size} entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <Pagination
                                                    activePage={parseInt(this.state.currentPage)}
                                                    itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                    totalItemsCount={size}
                                                    pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                    onChange={this.paginate}
                                                    className="pagination"
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    nextPageText="Next"
                                                    prevPageText="Prev"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>


                    </div>
                </div>


                <Can
                    role={this.props.userRole}
                    perform="moneyDisburse-upload-section:visit"
                    roleData={this.props.roleData}
                    yes={() => (

                        <UploadMoneyDisbursal methodToSetList={this.methodToSetList} />
                    )}
                    no={() =>
                        null
                    }
                />




            </>

        );
    }
}



const mapStateToProps = ({ user: { userRole }, user: { roMdn }, role: { roleData, roleForAdmin }, activeHeader: { dashBoardClass, manageEmployeeClass, moneyDisbursalClass, manageCardClass, manageLimitClass } }) => ({
    userRole,
    roleData, manageEmployeeClass, moneyDisbursalClass, manageCardClass,
    roMdn, dashBoardClass, manageLimitClass, roleForAdmin
});

export default connect(mapStateToProps)(MoneyDisbursalCard);
