import ApiCallingService from "../api-call-service";
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action';
import { store } from '../../redux/store';
import { setMessageToBeShown, setSeverty } from '../../redux/message/message.action';

class CardManagementService {
  async updateCardStatus(url, mdn, useraname, otp, reason, status, header, cardReferenceNum, updateType, updatedBy, ro_Id, isMessageShow, message, updatedByEmail) {
    console.log("inside card update status url  :- ", url);

    const request = {
      device_info: {
        api_version: "1.0",
        app_version: "9.2",
        device_id: "d215ab007e05c498",
        device_os: "Android",
        device_os_version: "5.1.1",
        imei: "357869081907005",
        ip_address: "172.160.2.140",
        model_name: "SM-J320F",
        source: "mobile",
      },
      request: {
        requestId: this.geneRateRequestId(),
        source: "App",
        type: "Virtual",
        oxigenWallet: mdn,
        statusCode: status,
        issuer: "1",
        userID: useraname,
        reason: reason,
        otp: otp,
        cardReferenceNum: cardReferenceNum,
        updateType: updateType,
        updatedBy: updatedBy,
        updatedByEmail: updatedByEmail,
        ro_Id: ro_Id
      }
    };
    console.log("inside card update status request :-", request);
    var response = [];
    async function run() {
      console.log(url, request, header)



      if (isMessageShow === '1') {

        return await ApiCallingService.postMethodCall(url, request, header);
      }
      else {
        response = await ApiCallingService.postMethodCall(url, request, header);



        console.log('response inside update status', response)



        if (response === 'error') {
          console.log(response);

          store.dispatch(setSeverty('error'))
          //response.response_description
          store.dispatch(setMessageToBeShown(response.response_description));
          store.dispatch(toggleDropDownHidden(true));
          return response

        }
        else if (response.status === 'failure') {
          console.log(response);

          store.dispatch(setSeverty('error'))
          //response.response_description
          store.dispatch(setMessageToBeShown(response.response_description));
          store.dispatch(toggleDropDownHidden(true));
          return response

        } else if (parseInt(response.response_code) === 1) {
          console.log(response);

          store.dispatch(setSeverty('error'))

          store.dispatch(setMessageToBeShown(response.response_description));
          store.dispatch(toggleDropDownHidden(true));
          return response

        }

        else {
          console.log("response isme aa rha hai code", message);



          store.dispatch(setSeverty('success'))
          store.dispatch(setMessageToBeShown(message));
          store.dispatch(toggleDropDownHidden(true));

          //  setTimeout(function () {
          window.location.reload();
          // }, 3000);
          return response
        }
      }



    }
    await run();

  }

  geneRateRequestId() {
    const d = Date.now();;
    //const n = d.getMilliseconds();
    const val = Math.floor(1000 + Math.random() * 9000);
    console.log(d, ' ', val, ' ', `${d}${val}`)
    return `${d}${val}`;
  }
}
export default new CardManagementService();