import React from "react";

import '../css/add-employee.css';
import '../css/upload-document.css';
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import Axios from "axios";
import { connect } from "react-redux";
import ApiCallingService from '../../service/api-call-service';
import { Redirect } from "react-router-dom";
import Can from '../../auth/can/Can';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { sha256 } from 'js-sha256';

class AddEmployeeComponent extends React.Component {
	constructor() {
		super();
		this.state = {
			files: [],
			hidden: 'hidden',
			image: '',
			url: '',
			uploadPercentage: '',
			size: '',
			loading: true,
			success: 'false',
			severty: 'success',
			dropDownHidden: false,
			message: '',
			inputKey: Date.now()
		}
	}

	generateHash(messageBody) {
		//var msg= JSON.stringify(messageBody) ;
		var privateKey = process.env.REACT_APP_CLIENT_ID;
		var hash = sha256.hmac.create(privateKey);
		hash.update(messageBody);
		var hexData = hash.hex();
		console.log('hexed data', hexData);
		var base64String = btoa(hexData);
		console.log(messageBody, '---->hash Generated --->', base64String);
		return base64String;
	}


	onChange = async (event) => {
		this.setState({ files: [] });
		console.log('file event', event.target);
		console.log('file event', event.target.files[0].size);
		this.setState({ size: event.target.files[0].size })


		if (event.target.files[0].size > parseInt(process.env.REACT_APP_FILE_SIZE_IN_KB) * 1000) {
			return (
				this.setState({ dropDownHidden: true }),
				this.setState({ severty: 'error' }),
				this.setState({ message: 'File Size extended' })
			)
		}

		this.setState({ uploadPercentage: 1 })
		this.setState({ hidden: '' });
		this.setState({ files: event.target.files[0] });
		if (event.target.files && event.target.files[0]) {
			this.setState({
				image: URL.createObjectURL(event.target.files[0])
			});
		}
		console.log('state file', this.state.files)
		const file = event.target.files[0];
		const url = process.env.REACT_APP_BULK_UPLOAD
		//const url = 'http://localhost:7072/api/upload';
		const formData = new FormData();
		formData.append('file', file)
		const options = {
			method: "POST",
			body: formData
			// If you add this, upload won't work
			// headers: {
			//   'Content-Type': 'multipart/form-data',
			// }
		};
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		const options2 = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total)
				console.log(`${loaded}kb of ${total}kb | ${percent}%`);
				this.setState({ total: total / 100 });
				this.setState({ loaded: loaded })

				if (percent < 100) {
					this.setState({ uploadPercentage: percent })
				}
			}
		}



		console.log('url', url)


		var hashCodeForHeader = await this.generateHash(JSON.stringify(formData));

		var urlFromService = ''
		const headers = {
			"device_info": "",
			"client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
			"hashCode": hashCodeForHeader,
			"loggedInUser": this.props.currentUser
		}



		await Axios.post(url, formData, { headers }).then(response => {
			console.log(response.data.fileDownloadUri);
			this.setState({ url: response.data.fileDownloadUri });
			//this.setState({ uploadPercentage: 100 })
			// , ()=>{
			// setTimeout(() => {
			//   this.setState({ uploadPercentage: 0 })
			// }, 1000);})

		})

		var extrxctUrl = this.state.url.split('/')
		console.log('extrxctUrl', extrxctUrl);

		const RequestToSend = {
			"fileUrl": `${process.env.REACT_APP_API_FILES_URL}/${extrxctUrl[5]}`,
			"uploadedBy": this.props.currentUser,
			"actionType": "1",
			"roId": this.props.roMdn,
			"name": this.props.fullName

		}
		console.log('RequestToSend', RequestToSend)
		//var urlOfBatchProcess = 'http://172.160.0.249:7072/employee/bulkprocess';
		var urlOfBatchProcess = process.env.REACT_APP_BULK_UPLOAD_UrlOfBatchProcess;
		var response = await ApiCallingService.postMethodCall(urlOfBatchProcess, RequestToSend, { headers: {} }, options2);
		console.log('urlOfBatchProcess response', response)

		if (response.response_code === 0) {
			this.setState({ loading: false })
			this.setState({ success: true })
			this.setState({ dropDownHidden: true })
			this.setState({ severty: 'success' })
			this.setState({ message: `File Uploaded Successfully.` })
			this.setState({ uploadPercentage: 100 })

		} else {
			this.setState({ loading: false })
			this.setState({ success: false })
			this.setState({ dropDownHidden: true })
			this.setState({ severty: 'error' })
			this.setState({ message: response.response_description })
			// this.setState({uploadPercentage:100})
		}

	}


	render() {

		var heightAdjust = window.innerHeight - 230
		return (

			<Can
				role={this.props.userRole}
				perform="manage-emp-add-employee:visit"
				roleData={this.props.roleData}
				yes={() => (


					<div className="add-emp" style={{ "minHeight": heightAdjust }}>
						<div className="head">
							<h3 >{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee}</h3>
						</div>
						<div className="shead">{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_BulkUpload} <a href={process.env.REACT_APP_SAMPLE_FILE_URL}><i className="sample"></i> {process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_SampleFile}</a></div>

						<Snackbar

							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={this.state.dropDownHidden}
							autoHideDuration={3000}
							onClose={e => this.setState({ dropDownHidden: false })}
						>
							<Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
								{this.state.message}
							</Alert>
						</Snackbar>

						<Can
							role={this.props.userRole}
							perform="manage-emp-add-employee:bulkAction"
							roleData={this.props.roleData}
							yes={() => (

								<div className="file-upload">
									{/* <div className="droppable"> */}
									<div className="dropzone-wrapper">
										<div className="dropzone-desc">
											<i className="upload"></i>
											<h6>{process.env.REACT_APP_Var_FOR_ADD_BULK_EMPLOYEE_DRAG_AND_DROP}<span> {process.env.REACT_APP_Var_FOR_ADD_BULK_EMPLOYEE_BROWSE}</span> {process.env.REACT_APP_Var_FOR_ADD_BULK_EMPLOYEE_Files_here}</h6>
											<p>{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_SampleFile_Format_Description}</p>
										</div>
										{/* </div> */}
										<input type="file" name="img_logo" className="dropzone" key={this.state.inputKey} onChange={this.onChange} />
									</div>


									{/* <div className="upload-status">
									<div className="proccess-bar">
										<div id="p1" className="mdl-progress mdl-js-progress"></div>
										<div className="output"></div>
										<div className="upload-action">
											<i className="cancel"></i>
											<i className="done"></i>
										</div>
									</div>
									</div> */}
									{
										parseInt(this.state.uploadPercentage) === 100 ? null :
											<div className={`preview-zone ${this.state.hidden}`}>
												<div className="box">
													{/* <div className="progress" styles="height: 6px;">  */}
													{/* <div className="progress-bar" role="progressbar" styles={`width: ${this.state.uploadPercentage}%;`} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
													{this.state.uploadPercentage > 0 && <LinearProgressWithLabel value={this.state.uploadPercentage} variant="determinate" />}
													{/* </div>  */}
													{
														this.state.hidden === ''
															?
															<div className="box-body">

																<p >File size {parseFloat(this.state.size / 1000).toFixed(2)}KB   </p>

															</div>
															:
															null
													}
													{(this.state.loading)
														?
														<div className="spinner-border spinner-border-sm text-primary"></div>
														:

														(this.state.success)
															?
															<button type="button" className="success-preview" onClick={e => this.setState({ hidden: 'hidden', files: [], loading: true, inputKey: Date.now() })}>Success</button>
															:
															<button type="button" className="remove-preview" onClick={e => this.setState({ hidden: 'hidden', files: [], loading: true, inputKey: Date.now() })}>Remove</button>

													}
												</div>
											</div>
									}
								</div>


							)}
							no={() => null}
						/>


						<div className="note-txt">
							<p>
								{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_SampleFile_Format_NOTE}
							</p>
						</div>


						<div className="link-btn">

							<Can
								role={this.props.userRole}
								perform="manage-emp-add-employee:individualAction"
								roleData={this.props.roleData}
								yes={() => (

									<a href="/add-new-emp" className="bbtn btngn">{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_Button_AddNewEmployee}</a>
								)}
								no={() => null}
							/>

							<Can
								role={this.props.userRole}
								perform="manage-emp-add-employee:uploadHistory"
								roleData={this.props.roleData}
								yes={() => (


									<a href="/upload-history" className="bbtn btnyl">{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_Button_UploadHistory}</a>
								)}
								no={() => null}
							/>

							<Can
								role={this.props.userRole}
								perform="manage-emp-add-employee:removedEmployee"
								roleData={this.props.roleData}
								yes={() => (

									<a href="/removed-employee" className="bbtn btngr">{process.env.React_APP_Var_FOR_ManageEmployee_Page_AddEmployee_Button_RemovedEmployee}</a>

								)}
								no={() => null}
							/>



						</div>
					</div>


				)}
				no={() =>
					null
				}
			/>

		)
	}

}

const mapStateToProps = ({ user: { currentUser }, user: { roMdn }, user: { userRole, fullName }, role: { roleData } }) => ({
	userRole,
	roleData,
	currentUser,
	roMdn, fullName
});

export default connect(mapStateToProps)(AddEmployeeComponent);