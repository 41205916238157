import '../styles/activity-log.styles.css';

import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { store } from '../../redux/store';
import apiCallService from '../../service/api-call-service';
import moment from 'moment';
import Select from "react-select";
import Pagination from 'react-js-pagination';
import { setBackUrl, setEmployeeDetails } from '../../redux/user/user.actions';
import LoadingComponent from './log-loading.component';
import Can from '../../auth/can/Can';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

const collectedTrueKeys = {
    activityType: []
}
var ToDateVar = '';
var FromDateVar = '';
const ActivityLog = ({ token, roMdn, userRole, roleData, isPasswordReset }) => {

    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_ActivityList);

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);
    const [multiValueB, setMultiValueB] = useState('')
    const [filteredRecord, setFilteredRecord] = useState([]);


    const [bandData, setBandData] = useState([
        { label: "Login", value: "Login" },
        { label: "Add Employee", value: "Add Employee" },
        { label: "Delete Employee", value: "Delete Employee" },
    ]);


    const [placeHolder, setplaceHolder] = useState("...");

    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');

    const [activityHistories, setActivityHistories] = useState([]);


    const [searchVar, setSearchVar] = useState('');

    useEffect(() => {

        document.title = "ShakePe Activity Log"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "active"
        }

        store.dispatch(setActiveHeader(payload))

        getActivityLog();

    }, []);


    const getActivityLog = async () => {

        var url = process.env.REACT_APP_GET_ALL_ACTIVITY_LOG
        var request = {
            "roId": roMdn,
            "loginType": "1"
        }

        var response = await apiCallService.postMethodCall(url, request);

        console.log("Activity Log response", request, response)

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("Activity Log response.response.activityHistories", response.response.activityHistories)


            function isJson(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            }



            var arrr = [];
            response.response.activityHistories.map(activity => {

                var date = activity.activityTime.split(" ");

                var startDate = "startDate";
                var endDate = "endDate"

                activity[startDate] = date[0];


                var minutes = date[1].split(":");
                console.log('minutes and date', date, minutes)
                var minSecod = '';

                if (parseInt(minutes[0]) >= 12) {
                    console.log('minutes', minutes[0])
                    var dd = parseInt(minutes[0]) - 12;
                    minSecod = `${dd}:${minutes[1]} PM`
                } else {
                    console.log('minutes else', minutes[0])
                    minSecod = `${minutes[0]}:${minutes[1]} AM`
                }

                if (isJson(activity.condition)) {
                    const state = "state"
                    activity[state] = JSON.parse(activity.condition);
                }
                console.log("Activity test json", JSON.parse(activity.condition), isJson(activity.condition))

                activity[endDate] = minSecod;
                arrr.push(activity)

            })

            setActivityHistories(arrr);
            setFilteredRecord(arrr)
            setLoading(false)
        } else {
            setLoading(false)
        }

    }




    const handleMultiBandchange = (option) => {
        console.log('option before', option)
        setMultiValueB(option)
        setCurrentPage(1)

        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.activityType = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.activityType.push(op.value);
            })
        }

    }


    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            setToDate(value)

            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value)
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("DD-MM-YYYY hh:mm:ss");
            var formatToDate = moment(ToDateVar).format("DD-MM-YYYY hh:mm:ss");

            const requestToSend =
            {
                "roId": roMdn,
                "from_date": formatFromDate,
                "to_date": formatToDate
            }


            const url = process.env.REACT_APP_GET_ALL_ACTIVITY_LOG;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('response', response)

            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
                console.log("Activity Log response.response.activityHistories", response.response.activityHistories)

                function isJson(str) {
                    try {
                        JSON.parse(str);
                    } catch (e) {
                        return false;
                    }
                    return true;
                }

                var arrr = [];
                response.response.activityHistories.map(activity => {

                    var date = activity.activityTime.split(" ");

                    var startDate = "startDate";
                    var endDate = "endDate"

                    activity[startDate] = date[0];


                    var minutes = date[1].split(":");
                    console.log('minutes', minutes)
                    var minSecod = '';

                    if (parseInt(minutes[0]) > 12) {
                        console.log('minutes', minutes[0])
                        var dd = parseInt(minutes[0]) - 12;
                        minSecod = `${dd}:${minutes[1]} PM`
                    } else {
                        console.log('minutes else', minutes[0])
                        minSecod = `${minutes[0]}:${minutes[1]} AM`
                    }

                    if (isJson(activity.condition)) {
                        const state = "state"
                        activity[state] = JSON.parse(activity.condition);
                    }


                    activity[endDate] = minSecod;
                    arrr.push(activity)

                })


                setFilteredRecord(arrr)

                setCurrentPage(1)
            } else {
                setFilteredRecord([])
            }

        }
    }


    const clearAll = () => {

        setMultiValueB([])


        setFilteredRecord(activityHistories)


        collectedTrueKeys.notificationType = [];


        setAllValuesForFilter([])

        setToDate('')
        setFromDate('')
        ToDateVar = '';
        FromDateVar = '';


    }

    const handleSearchChange = (event) => {
        setSearchVar(event.target.value);
    }



    const setStateCondition = (employee) => {

        console.log('employeee', employee)
        store.dispatch(setBackUrl("/activity-log"));
        store.dispatch(setEmployeeDetails(employee));
    }


    console.log('Activity Log state', activityHistories)


    var startdateToDate = moment();
    startdateToDate = startdateToDate.format("YYYY-MM-DD");

    if (toDate === '') {
        var startdateFromDate = moment();
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    } else {
        var startdateFromDate = moment(toDate);
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    }



    var dateMax = moment(fromDate);
    dateMax = dateMax.format("YYYY-MM-DD");
    var dateFromMax = moment(toDate);
    dateFromMax = dateFromMax.format("YYYY-MM-DD");

    const customStyles = {
        control: (base, state) => ({
            ...base,
            'border': '1px solid #ddd',
            'borderRadius': '0',
            'boxSizing': 'content-box',
            'minHeight': '30px',
            'lineHeight': '18px',
            'display': 'flex'
        }),

        valueContainer: (base) => ({
            ...base,
            'position': 'relative',
            'overflow': 'hidden',
            'margin': '0',
            'padding': '5px',
            'width': '100%',
            'minHeight': '30px',
            'border': '1px solid #ddd',
            'backgroundColor': '#fff',
            'lineHeight': '18px',
            'cursor': 'text',
            'alignItems': 'center'
        }),
        multiValue: base => ({
            ...base,
            'boxSizing': 'content-box',
            'position': 'relative',
            'margin': '1px 5px 1px 0px',
            'padding': '1px 2px 3px 5px',
            'border': '1px solid #c4d5e1',
            'lineHeight': '18px',
            'maxWidth': '100%',
            'borderRadius': '3px',
            'backgroundColor': '#d6eeff',
            'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
            'color': '#4a546c',
            'lineHeight': '16px',
            'cursor': 'default',
            'height': '20px'
        }),
        menu: (base) => ({
            ...base,
            'padding': '2px 5px',
            'lineHeight': '16px',

        }),
        input: base => ({
            ...base,
            'height': '18px',
            'lineHeight': '18px'
        })

    };


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;






    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };


    var filteredPostData = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



    }

    var searched = [];

    filteredPostData.map(data => {
        if (JSON.stringify(data).toLowerCase().includes(searchVar.toLowerCase())) {
            searched.push(data);
        }
    })


    if (searchVar !== '') {
        currentPost = searched.slice(indexOfFirstPost, indexOfLastPost)
    } else {

        if (filteredPostData === null || filteredPostData === undefined) {
            currentPost = null
        } else {
            currentPost = filteredPostData.slice(indexOfFirstPost, indexOfLastPost)
        }
    }

    console.log('currentpost', currentPost);
    var height = window.innerHeight - 300


    return (

        isPasswordReset ?
            <Can
                role={userRole}
                perform="subheader-activity:visit"
                roleData={roleData}
                yes={() => (
                    <div className="mid-container">
                        <div className="container-fluid">



                            <div className="head">
                                <div className="row">
                                    <div className="col-6 col-md-4 order-md-1">
                                        <h3>Activity Log</h3>
                                    </div>

                                    <div className="col-6 col-md-4 order-md-3">
                                        <div className="flterbox">
                                            <div className="dropdown " title="Filter" data-toggle="tooltip">
                                               <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>


                                                <div className="dropdown-menu dropdown-menu-right">


                                                    <div className="filters">
                                                        <label htmlFor="select-state">Type:</label>
                                                        <Select
                                                            name="filters"
                                                            placeholder={placeHolder}
                                                            value={multiValueB}
                                                            options={bandData}
                                                            onChange={handleMultiBandchange}
                                                            isMulti={true}
                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                            styles={customStyles}
                                                        />
                                                    </div>

                                                    <div className="filters">
                                                        <label htmlFor="select-state">From Date:</label>
                                                        <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={fromDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                    </div>

                                                    <div className="filters">
                                                        <label htmlFor="select-state">To Date:</label>
                                                        <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={toDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                    </div>

                                                    <div className="fbtn">
                                                        <button className="sbtn btnrd" type="reset" id="btnClear" onClick={clearAll}>Clear all</button>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>


                                    <div className="col-12 col-md-4 order-md-2">
                                        <div className="searchdata">
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="empsearch" name="search" placeholder="Search activity..." onChange={handleSearchChange} />

                                               <button className="btn srchbtn" type="button"></button>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>


                            <div className="emp-list">
                                <div className="row">
                                    {
                                        loading ?
                                            <div className="col-12">
                                                <div className="logcard" style={{ minHeight: height }}>
                                                    <ul>


                                                        <LoadingComponent />
                                                    </ul>
                                                </div>
                                            </div>
                                            :


                                            currentPost.length === 0 ?
                                                <div className="col-12">
                                                    <div className="no-record">
                                                        <i></i>
                                                        <h3> No Record Available.</h3>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-12">
                                                    <div className="logcard" style={{ minHeight: height }}>
                                                        <ul>


                                                            {currentPost.map(activity => (
                                                                <li className={activity.className}><p className="logdate">{`${activity.startDate}` === `${moment().format("DD-MM-YYYY")}` ? "Today" : activity.startDate} <span className="logtime">{activity.endDate}</span></p>
                                                                    <p>{activity.beforeLink}
                                                                        <Link
                                                                            to={{
                                                                                pathname: activity.redirectUrl,
                                                                                state: activity.state
                                                                            }}
                                                                            onClick={e => setStateCondition(activity.state.employee)}
                                                                        > {activity.linkVariable} </Link>


                                                                        {activity.afterLink} from {activity.deviceType} {activity.deviceOs} {activity.deviceOsVersion} browser - {activity.browserName},  {activity.locationCountry} ({activity.locationState})  - IP {activity.ipAddress}</p>
                                                                </li>
                                                            ))


                                                            }


                                                        </ul>
                                                    </div>

                                                </div>
                                    }


                                    <div className="w-100"></div>

                                    {parseInt(filteredPostData.length) > parseInt(postsPerPage)
                                        ?
                                        <>
                                            <div className="col-12 col-sm-6">
                                                <p> Showing {valueToShow} to {(indexOfLastPost > filteredPostData.length) ? filteredPostData.length : indexOfLastPost} of {filteredPostData.length} entries</p>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="pager">
                                                    <Pagination
                                                        activePage={parseInt(currentPage)}
                                                        itemsCountPerPage={parseInt(postsPerPage)}
                                                        totalItemsCount={filteredPostData.length}
                                                        pageRangeDisplayed={parseInt(postsPerPage)}
                                                        onChange={paginate}
                                                        className="pagination"
                                                        linkClass="page-link"
                                                        itemClass="page-item"
                                                        nextPageText="Next"
                                                        prevPageText="Prev"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                )}
                no={() =>
                    <Redirect to="/manage-giftCard" />

                }
            />

            :
            <ResetLoginPassword />




    );


};



const mapStateToProps = ({ user: { userRole }, user: { roMdn }, role: { roleData }, token: { authenticated, isPasswordReset } }) => ({
    userRole,
    roMdn,
    roleData,
    authenticated, isPasswordReset
});


export default connect(mapStateToProps)(ActivityLog);

