import React from "react";
import '../../common/css/small.popup.css';
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action';
import { connect } from 'react-redux';
import { useEffect } from "react";
import Countdown from "react-countdown";
import apiCallService from "../../service/api-call-service";
import { useState } from "react";

const VerifyDisbursal = ({ varFor, handleChange, targetid, triggeretime, time, referenceNumber, formDisburseSubmit, mystring, closeToggle, password, otp, formDisburseReject, totalAmount, modal, Close, handleResendOtp, }) => {

    const [disableVar, setdisableVar] = useState(true);

    useEffect(() => {
        setdisableVar(true);
    }, []);


    const MethodTosetDisabledVar = () => {
        setdisableVar(false);

    }
    const MethodTosetEnableVar = () => {
        setdisableVar(true);

    }


    return (
        varFor === 'otp'
            ?
            <div
                className="modal fade"
                id={targetid}
                data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            >
                <div className="modal-dialog">
                    <form onSubmit={event => formDisburseSubmit(event)}>
                        <div className="modal-content alerttext">

                            <div className="modal-header">
                                <h5>{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_HEADING}</h5>
                                <button className="close" id="closeToggleAvail" data-bs-dismiss="modal"  aria-label="Close" onClick={closeToggle}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="distxt">
                                    <input className="form-control abcd" type="password"
                                        // id="password" 
                                        // style={{fontFamily:'text-security-disc'}} 
                                        placeholder="Enter transaction password"
                                        name='password' value={password} onChange={handleChange} required />
                                    <input className="form-control" type="number" placeholder="Enter OTP" name='otp' value={otp} onChange={handleChange} required />
                                    <p>{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_PARAGRAPH_BEFORE_MOBILE_NUM} {mystring} {process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_PARAGRAPH_BEFORE_DISBURSE_AMOUNT}<strong>₹{totalAmount}</strong> </p>

                                    <h5>
                                        {triggeretime ?
                                            <Countdown date={time} onMount={MethodTosetEnableVar} onComplete={MethodTosetDisabledVar} />
                                            :
                                            null
                                        }
                                    </h5>
                                    ref-number:- {referenceNumber}
                                </div>

                            </div>
                            <div className="modal-footer">
                                {
                                    disableVar
                                        ?
                                        <button type="button" className="mbtn btngr" onClick={handleResendOtp} disabled={disableVar}>{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_Resend_OTP}</button>
                                        :
                                        <button type="button" className="mbtn btngn" onClick={handleResendOtp} disabled={disableVar}>{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_Resend_OTP}</button>

                                }
                                <button type="submit" className="mbtn btnbl" data-dismiss={modal} aria-label={Close} >{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_Submit}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            :
            <div className="modal fade" id={targetid} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                <div className="modal-dialog">
                    <form className="modal-content alerttext" onSubmit={formDisburseReject}>
                        <div className="modal-header">
                            <h5>{process.env.REACT_APP_VAR_FOR_DISBURSE_POPUP_HEADING_Reject_Money_Disbursal_Amount}</h5>
                            <button className="close" data-bs-dismiss="modal"  aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="distxt">
                                <p>{process.env.REACT_APP_VAR_FOR_DISBURSE_POPUP_HEADING__Are_you_sure_you_want_to_reject_money_disbursal_amount_Are_you_sure_you_want_to_reject_money_disbursal_amount}</p>
                                <textarea className="form-control" placeholder="Enter reason" name='reason' onChange={handleChange} required></textarea>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="mbtn btngr" data-bs-dismiss="modal" >{process.env.REACT_APP_VAR_FOR_DISBURSE_POPUP_HEADING_Reject_Money_Disbursal_No_Button}</button>
                            <button  className="mbtn btnbl" type="submit">{process.env.REACT_APP_VAR_FOR_DISBURSE_POPUP_HEADING_Reject_Money_Disbursal_Yes_Button}</button>
                        </div>
                    </form>
                </div>
            </div>





    )



}

const mapDispatchToProps = dispatch => ({
    toggleDropDownHidden: (data) => dispatch(toggleDropDownHidden(data))
});

export default connect(
    null,
    mapDispatchToProps
)(VerifyDisbursal);

