import React from 'react';
import '../css/dashboard.styles.css'
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { setFromDashbard } from '../../redux/div-show-and-hide/div-show-and-hide.action';

import apiCallService from '../../service/api-call-service';
import { connect } from 'react-redux';
import Can from '../../auth/can/Can';

import ReactApexChart from 'react-apexcharts'
import GenerateRequestId from '../../common-function/GenerateRequestId';
import CategoryLimit from '../../constants/category-limit.type';



class DashBoardComponent extends React.Component {
	constructor() {
		super();
		this.state = {

			lowBalanceRupeeCompare: parseFloat(process.env.REACT_APP_VAR_FOR_DASHBOARD_LOW_BALANCE_ALERT_RUPEE),
			cardDetail: [],
			employeeStatusDetail: [],
			balanceEnquiry: 0,
			assignedCardPercentage: 0,
			blockedCardPercentage: 0,
			availableCardPercentage: 0,

			noRecordForBar: false,
			noRecordForPie: false,

			requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH,

			buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH,

			barLoaderPie: false,

			barLoader: false,

			///states for graph
			series: [{
				name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
				data: [2000, 1000],
				backgroundColor: '#7fbb42',
			}, {
				name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
				data: [3000, 8000],
				backgroundColor: '#32a0da',
			}],
			options: {
				chart: {
					height: 250,
					type: 'area',
					// toolbar: {
					// 	show: false
					//   }
				},
				fill: {
					color: ['#32a0da', '#7fbb42']
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth'
				},
				xaxis: {
					type: 'date',
					categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z"]
				},
				tooltip: {
					x: {
						format: 'dd/MM/yy HH:mm'
					},
				},
			},


			///states for Doughnut
			seriesDoughnut: [100, 50, 200],
			optionsDoughnut: {
				chart: {
					type: 'donut',
				},
				labels: ["General", "Food", "Travel"],
				backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
				responsive: [{
					breakpoint: 480,
					options: {

					}
				}],
				legend: {
					position: 'bottom',
					horizontalAlign: 'center',
					offsetY: 0,
				}
			},
		}

	}



	componentDidMount = () => {
		var payload = {
			dashBoardClass: "active",
			manageEmployeeClass: "",
			moneyDisbursalClass: "",
			manageCardClass: "",
			manageLimitClass: "",
			activityClass: ""
		}

		store.dispatch(setActiveHeader(payload));

		const url = `${process.env.REACT_APP_GET_CARD_EMPLOYEE_COUNT}?roId=${this.props.roMdn}`;

		const getResult = async () => {
			var response = await apiCallService.getMethodCallWithoutParam(url);
			console.log('ManageCard response ', response)
			if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
				this.setState({ cardDetail: response });
				this.setState({ blockedCardPercentage: parseFloat(parseFloat(response.blockedCard) / parseFloat(response.totalCard) * 100).toFixed(2) });
				this.setState({ availableCardPercentage: parseFloat(parseFloat(response.availableCard) / parseFloat(response.totalCard) * 100).toFixed(2) });

				this.setState({ assignedCardPercentage: parseFloat(parseFloat(response.assignedCard) / parseFloat(response.totalCard) * 100).toFixed(2) });

				console.log('ManageCard response blockedCardPercentage', parseFloat(parseFloat(response.blockedCard) / parseFloat(response.totalCard) * 100).toFixed(2))
				console.log('ManageCard response availableCardPercentage', parseFloat(parseFloat(response.availableCard) / parseFloat(response.totalCard) * 100).toFixed(2))
				console.log('ManageCard response assignedCardPercentage', parseFloat(parseFloat(response.assignedCard) / parseFloat(response.totalCard) * 100).toFixed(2))
			}

			const urlEmployee = `${process.env.REACT_APP_OEMS_EMPLOYE_SERVICE_GETEMPLOYEECOUNT}?ro_Id=${this.props.roMdn}`;
			var respEmployee = await apiCallService.getMethodCallWithoutParam(urlEmployee);
			console.log('respEmployee', respEmployee)
			if (respEmployee !== undefined && respEmployee.response_code !== undefined && respEmployee.response_code === 0) {
				this.setState({ employeeStatusDetail: respEmployee.responses });
			}


			this.fetchBalanceDetail();
		}
		getResult();

		this.getTaReport();

		this.pieData();
		this.getLowBalanceAlert();


	}



	getLowBalanceAlert = async () => {
		const url = process.env.REACT_APP_CORPINFOBYROID_URL;
		// console.log(url);
		console.log(this.props.roMdn)
		const res = await apiCallService.getMethodCallWithParam(url, { 'roId': this.props.roMdn });
		// console.log('response inside AuthService lowBalanceAlertAmount', res.response.lowBalanceAlertAmount);
		if (!(res === undefined) && !(res.response === undefined) && res.response !== undefined &&
			res.response.lowBalanceAlertAmount !== undefined && res.response.lowBalanceAlertAmount !== null && res.response.lowBalanceAlertAmount !== "") {

			this.setState({ lowBalanceRupeeCompare: parseFloat(res.response.lowBalanceAlertAmount) })
		}

	}




	pieData = async () => {

		var seriesDoughnut = []
		var optionsDoughnut = {
			chart: {
				type: 'donut',

			},
			labels: ['General', 'Food', 'Travel'],
			backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
			responsive: [{
				breakpoint: 480,
				options: {

				}
			}],
			legend: {
				position: 'bottom',
				horizontalAlign: 'center',
				offsetY: 0,
			}
		}
		var labelToSet = [];


		var url = process.env.REACT_APP_API_WALLET_WISE_TA_REPORT


		var currentDate = new Date();
		currentDate.setDate(currentDate.getDate());

		var currentDatedd = currentDate.getDate();
		var currentDatemm = currentDate.getMonth() + 1;
		var currentDatey = currentDate.getFullYear();

		var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
		var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
		var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

		var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;


		var someDate = new Date();
		var numberOfDaysToAdd = parseInt(process.env.REACT_APP_CATEGORY_WISE_REPORT_1MONTHS_TIME_IN_DAYS);
		someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

		var dd = someDate.getDate();
		var mm = someDate.getMonth() + 1;
		var y = someDate.getFullYear();



		var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
		var masky = `${mm}`.length === 1 ? '0' + mm : mm



		var someFormattedDate = y + '-' + masky + '-' + maskdd


		console.log('dateRang', dateRang)

		var request = {
			"request": {
				"user": {
					"username": `91${this.props.roMdn}`,
					// "username": `9887835909`
				},
				"transaction_data": {
					"request_id": GenerateRequestId.geneRateRequestId(),
					"page_index": 0,
					"from_date": `${someFormattedDate} 00:00:00`,
					"to_date": `${dateRang} 23:59:59`,
				}
			}
		}

		var foodValue = ''
		var travelValue = ''
		var generalValue = ''

		var response = await apiCallService.postMethodCall(url, request);

		console.log("PIE REPORT RESPONSE", request, response);

		if (response !== undefined && response.response_code === "2206" && response.walletWisesReport !== undefined && response.walletWisesReport !== null) {
			response.walletWisesReport.map(wallet => {
				if (parseInt(wallet.walletId) === parseInt(CategoryLimit.GENERAL)) {
					generalValue = wallet.numberOfTxn
				} else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.FOOD)) {
					foodValue = wallet.numberOfTxn
				} else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.TRAVEL)) {
					travelValue = wallet.numberOfTxn
				}
			})
			if (generalValue !== '') {
				labelToSet.push('General')
				seriesDoughnut.push(generalValue)
			}
			if (foodValue !== '') {
				labelToSet.push('Food')
				seriesDoughnut.push(foodValue)
			}
			if (travelValue !== '') {
				labelToSet.push('Travel')
				seriesDoughnut.push(travelValue)
			}
			optionsDoughnut.labels = labelToSet

			this.setState({ noRecordForPie: false })
			this.setState({ seriesDoughnut: seriesDoughnut });
			this.setState({ optionsDoughnut: optionsDoughnut });
			this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH });
			this.setState({ barLoaderPie: false })
		} else {
			this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH });

			this.setState({ noRecordForPie: true })
			this.setState({ barLoaderPie: false })
		}


	}


	getTaReport = async () => {
		const url = process.env.REACT_APP_GET_TA_REPORT;


		var seriesToAdd = [{
			name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
			data: [0, 0],
			backgroundColor: '#7fbb42',
		}, {
			name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
			data: [0, 0],
			backgroundColor: '#32a0da',
		}]
		var arraySeries = [];
		var arraySeries1 = [];
		var arrayOption = {
			chart: {
				height: 250,
				type: 'area',
				// toolbar: {
				// 	show: false
				//   }
			},
			fill: {
				color: ['#32a0da', '#7fbb42']
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'date',
				categories: []
			},
			tooltip: {
				x: {
					format: 'yyyy-mm-dd'
				},
			},
		}

		var categoriesToSet = []



		var currentDate = new Date();
		currentDate.setDate(currentDate.getDate());

		var currentDatedd = currentDate.getDate();
		var currentDatemm = currentDate.getMonth() + 1;
		var currentDatey = currentDate.getFullYear();

		var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
		var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
		var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

		var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;




		var someDate = new Date();
		var numberOfDaysToAdd = process.env.REACT_APP_FUNDLOADED_AND_DISBUSED_DETAIL_SHOWING_TIME_IN_DAYS;
		someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

		var dd = someDate.getDate();
		var mm = someDate.getMonth() + 1;
		var y = someDate.getFullYear();



		var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
		var masky = `${mm}`.length === 1 ? '0' + mm : mm



		var someFormattedDate = y + '-' + masky + '-' + maskdd


		console.log('dateRang', dateRang)

		var request = {
			"request": {
				"user": {
					"username": `91${this.props.roMdn}`,
					// "username": `9887835909`
				},
				"transaction_data": {
					"request_id": GenerateRequestId.geneRateRequestId(),
					"page_index": 0,
					"from_date": `${someFormattedDate} 00:00:00`,
					"to_date": `${dateRang} 23:59:59`,
				}
			}
		}


		var response = await apiCallService.postMethodCall(url, request);

		console.log("TA REPORT RESPONSE", request, response);
		if (response !== undefined && response.response_code === "2206" && response.debitCreditReport !== undefined) {


			response.debitCreditReport.map(detail => {

				if (detail.transactionType === 'credit') {
					arraySeries1.push(detail.amount / 100)
				} else {
					arraySeries.push(detail.amount / 100)
				}

				if (categoriesToSet.length === 0) {
					categoriesToSet.push(detail.date)
				}

				var index = categoriesToSet.findIndex(det => det === detail.date);
				index === -1 ? categoriesToSet.push(detail.date) : console.log("object already exists")



			})

			seriesToAdd.map(data => {
				if (data.name.toLocaleLowerCase().includes('load')) {
					data.data = arraySeries1
				} else {
					data.data = arraySeries
				}

			})
			arrayOption.xaxis.categories = categoriesToSet;
			console.log("categories debitCreditReport", response.debitCreditReport);
			console.log("categories categoriesToSet", categoriesToSet);
			console.log("categories arraySeries1 : load ", arraySeries1);
			console.log("categories arraySeries : disburse ", arraySeries);
			console.log("categories debitCreditReport seriesToAdd", seriesToAdd);
			console.log("categories categoriesToSet arrayOption", arrayOption);

			this.setState({ noRecordForBar: false })
			this.setState({ series: seriesToAdd })
			this.setState({ options: arrayOption });
			this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH })
			this.setState({ barLoader: false })
		} else {
			this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH })
			this.setState({ noRecordForBar: true })
			this.setState({ barLoader: false })
		}

	}


	fetchBalanceDetail = async () => {


		const request = {
			 clientIndentifier: this.props.roMdn 
			// clientIndentifier: 2067119572
		};
		let headers = {
			"client_id": "OEMSAPP",
			"token": this.props.token,
			"Authorization": "BASIC abcd"
		}
		console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props.mdn, this.props.roMdn);
		var response = [];
		// async function run() {
		const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY_CORP
		console.log(url, request, "header")
		response = await apiCallService.postMethodCall(url, request, headers);
		if (response !== undefined && response.response_code !== undefined && response.response_code == 0 && response.response !== undefined) {
			this.setState({ balanceEnquiry: parseFloat(parseFloat(response?.response?.availableBalance)) });
		}
		// }
		// run();


	}


	onClickMonths = async (event) => {

		this.setState({ barLoader: true })
		console.log('event.target[3].innerText', event.target.innerText)


		if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_6MONTH) {
			var seriesToAdd = [{
				name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Loaded,
				data: [0, 0],
				backgroundColor: '#7fbb42',
			}, {
				name: process.env.REACT_APP_VAR_FOR_DASHBOARD_CHART_LABEL_Fund_Disbursed,
				data: [0, 0],
				backgroundColor: '#32a0da',
			}]
			var arraySeries = [];
			var arraySeries1 = [];
			var arrayOption = {
				chart: {
					height: 250,
					type: 'area',
					// toolbar: {
					// 	show: false
					//   }
				},
				fill: {
					color: ['#32a0da', '#7fbb42']
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth'
				},
				xaxis: {
					type: 'text',
					categories: []
				},
				tooltip: {
					x: {
						format: 'yyyy-mm-dd'
					},
				},
			}

			var categoriesToSet = []

			var currentDate = new Date();
			currentDate.setDate(currentDate.getDate());

			var currentDatedd = currentDate.getDate();
			var currentDatemm = currentDate.getMonth() + 1;
			var currentDatey = currentDate.getFullYear();

			var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
			var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
			var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

			var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;




			var someDate = new Date();
			var numberOfDaysToAdd = parseInt(process.env.REACT_APP_CATEGORY_WISE_REPORT_6MONTHS_TIME_IN_DAYS);
			someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

			var dd = someDate.getDate();
			var mm = someDate.getMonth() + 1;
			var y = someDate.getFullYear();



			var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
			var masky = `${mm}`.length === 1 ? '0' + mm : mm



			var someFormattedDate = y + '-' + masky + '-' + maskdd



			const url = process.env.REACT_APP_GET_TA_REPORT;
			var request = {
				"request": {
					"user": {
						"username": `91${this.props.roMdn}`,
						// "username": `9887835909`
					},
					"transaction_data": {
						"request_id": GenerateRequestId.geneRateRequestId(),
						"requestType": "monthly",
						"from_date": `${someFormattedDate} 00:00:00`,
						"to_date": `${dateRang} 23:59:59`,
					}
				}
			}
			var response = await apiCallService.postMethodCall(url, request);

			console.log("TA REPORT RESPONSE", request, response);
			var response = await apiCallService.postMethodCall(url, request);

			console.log("TA REPORT RESPONSE", request, response);
			if (response !== undefined && response.response_code === "2206" && response.debitCreditReport !== undefined) {


				response.debitCreditReport.map(detail => {

					if (detail.transactionType === 'credit') {
						arraySeries1.push(detail.amount / 100)
					} else {
						arraySeries.push(detail.amount / 100)
					}
					if (categoriesToSet.length === 0) {
						categoriesToSet.push(detail.dateString)
					}
					if (detail.dateString != undefined) {
						var index = categoriesToSet.findIndex(det => det === detail.dateString);
						index === -1 ? categoriesToSet.push(detail.dateString) : console.log("object already exists")
					}
					else {
						var index = categoriesToSet.findIndex(det => det === detail.date);
						index === -1 ? categoriesToSet.push(detail.date) : console.log("object already exists")
					}

				})

				seriesToAdd.map(data => {
					if (data.name.toLocaleLowerCase().includes('load')) {
						data.data = arraySeries1
					} else {
						data.data = arraySeries
					}

				})
				arrayOption.xaxis.categories = categoriesToSet;
				console.log("categories debitCreditReport", response.debitCreditReport);
				console.log("categories categoriesToSet", categoriesToSet);
				console.log("categories arraySeries1 : load ", arraySeries1);
				console.log("categories arraySeries : disburse ", arraySeries);
				console.log("categories debitCreditReport seriesToAdd", seriesToAdd);
				console.log("categories categoriesToSet arrayOption", arrayOption);
				this.setState({ noRecordForBar: false })
				this.setState({ series: seriesToAdd })
				this.setState({ options: arrayOption });
				this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_1MONTH })
				this.setState({ barLoader: false })

			} else {

				this.setState({ noRecordForBar: true })
				this.setState({ requestTypeMonthly: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_1MONTH })
				this.setState({ barLoader: false })
			}

		}

		else if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Disburse_1MONTH) {

			this.getTaReport();
		}


	}


	pieButtonChange = async (event) => {
		this.setState({ barLoaderPie: true })
		console.log('event.target[3].innerText', event.target.innerText)

		if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_6MONTH) {
			var seriesDoughnut = []
			var optionsDoughnut = {
				chart: {
					type: 'donut',

				},
				labels: ['General', 'Food', 'Travel'],
				backgroundColor: ['#32a0da', '#7fbb42', '#fdb913'],
				responsive: [{
					breakpoint: 480,
					options: {

					}
				}],
				legend: {
					position: 'bottom',
					horizontalAlign: 'center',
					offsetY: 0,
				}
			}
			var labelToSet = [];


			var url = process.env.REACT_APP_API_WALLET_WISE_TA_REPORT


			var currentDate = new Date();
			currentDate.setDate(currentDate.getDate());

			var currentDatedd = currentDate.getDate();
			var currentDatemm = currentDate.getMonth() + 1;
			var currentDatey = currentDate.getFullYear();

			var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
			var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
			var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

			var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;




			var someDate = new Date();
			var numberOfDaysToAdd = parseInt(process.env.REACT_APP_CATEGORY_WISE_REPORT_6MONTHS_TIME_IN_DAYS);
			someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

			var dd = someDate.getDate();
			var mm = someDate.getMonth() + 1;
			var y = someDate.getFullYear();



			var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
			var masky = `${mm}`.length === 1 ? '0' + mm : mm



			var someFormattedDate = y + '-' + masky + '-' + maskdd


			console.log('dateRang', dateRang)

			var request = {
				"request": {
					"user": {
						"username": `91${this.props.roMdn}`,
						// "username": `9887835909`
					},
					"transaction_data": {
						"request_id": GenerateRequestId.geneRateRequestId(),
						"page_index": 0,
						"from_date": `${someFormattedDate} 00:00:00`,
						"to_date": `${dateRang} 23:59:59`,
					}
				}
			}

			var foodValue = ''
			var travelValue = ''
			var generalValue = ''

			var response = await apiCallService.postMethodCall(url, request);

			console.log("PIE REPORT RESPONSE", request, response);

			if (response !== undefined && response.response_code === "2206" && response.walletWisesReport !== undefined && response.walletWisesReport !== null) {
				this.setState({ noRecordForPie: false })
				response.walletWisesReport.map(wallet => {
					if (parseInt(wallet.walletId) === parseInt(CategoryLimit.GENERAL)) {
						generalValue = wallet.numberOfTxn
					} else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.FOOD)) {
						foodValue = wallet.numberOfTxn
					} else if (parseInt(wallet.walletId) === parseInt(CategoryLimit.TRAVEL)) {
						travelValue = wallet.numberOfTxn
					}
				})

				if (generalValue !== '') {
					labelToSet.push('General')
					seriesDoughnut.push(generalValue)
				}
				if (foodValue !== '') {
					labelToSet.push('Food')
					seriesDoughnut.push(foodValue)
				}
				if (travelValue !== '') {
					labelToSet.push('Travel')
					seriesDoughnut.push(travelValue)
				}
				optionsDoughnut.labels = labelToSet


				this.setState({ seriesDoughnut: seriesDoughnut });
				this.setState({ optionsDoughnut: optionsDoughnut });
				this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_1MONTH });
				this.setState({ barLoaderPie: false })
			} else {
				this.setState({ buttonValueForPieChart: process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_1MONTH });
				this.setState({ noRecordForPie: true })
				this.setState({ barLoaderPie: false })
			}


		} else if (event.target.innerText === process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed_PIE_CHART_1MONTH) {
			this.pieData();
		}
	}


	render() {

		console.log('this.state.lowBalanceRupeeCompare', this.state.lowBalanceRupeeCompare)
		return (


			<div className="mid-container">
				<div className="container-fluid">
					<div className="row">




						<div className="col-12 col-md-6 col-lg">
							<div className="dash-card">
								<div className="dash-head">

									<Can
										role={this.props.userRole}
										perform="dashboard-viewEmployee:visit"
										roleData={this.props.roleData}
										yes={() => (
											<a href="/emp-list" className="sbtn btnbl">{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Active_Employees_View_all}</a>

										)}
										no={() =>
											null
										}
									/>
									<i className="emp"></i>
									<h5>{this.state.employeeStatusDetail.activeEmployeeCount}</h5>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Active_Employees}

									</p>
								</div>

								<div className="total-emp">
									<i className="atv">&#10004;</i>
									<h6>{this.state.employeeStatusDetail.cardIssuedAndActivatedCount}</h6>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Cards_issued_and_activated}</p>
								</div>

								<div className="total-emp">
									<i className="antv">&#9867;</i>
									<h6>{this.state.employeeStatusDetail.cardAssignedAndNotActivatedcount}</h6>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Cards_issued_and_not_activated}</p>
								</div>

								<div className="total-emp">
									<i className="inatv">&#10006;</i>
									<h6>{this.state.employeeStatusDetail.cardNotAssignedAndNotActivatedcount}</h6>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Cards_not_issued_and_not_activated}</p>
								</div>
							</div>
						</div>




						<div className="col-12 col-md-6 col-lg">
							<div className="dash-card">

								<div className="dash-head">
									<Can
										role={this.props.userRole}
										perform="dashboard-orderNow:visit"
										roleData={this.props.roleData}
										yes={() => (
											<a href="/manage-card" onClick={e => { store.dispatch(setFromDashbard(true)) }} className="sbtn btnbl">{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Order_now}</a>



										)}
										no={() =>
											null
										}
									/>
									<i className="inv"></i>
									<h5>{this.state.cardDetail.totalCard}</h5>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Card_Inventory}
									</p>
								</div>


								<div className="cardtypestock">
									<div className="cardtypecount">
										<i className="phy"></i>
										<h6>{this.state.cardDetail.physicalCard}</h6>
										<p>Physical Card</p>
									</div>
									<div className="cardtypecount plus">
										<i className="vir"></i>
										<h6>{this.state.cardDetail.virtualCard}</h6>
										<p>Virtual Card</p>
									</div>
								</div>


								<div className="card-stock">
									<div className="stock-txt">
										<h6>{this.state.cardDetail.assignedCard}</h6>
										<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Assigned}</p>
									</div>

									<div className="stock-bar">
										<div className="progress" >
											<div className="progress-bar available" role="progressbar" style={{ "width": `${this.state.assignedCardPercentage}%` }} ></div>
										</div>
									</div>
								</div>



								<div className="card-stock">
									<div className="stock-txt">
										<h6>{this.state.cardDetail.blockedCard}</h6>
										<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Blocked}</p>
									</div>
									<div className="stock-bar">
										<div className="progress" style={{ "height": "10px" }}>
											<div className="progress-bar bg-danger" role="progressbar" style={{ "width": `${this.state.blockedCardPercentage}%` }} ></div>
										</div>
									</div>
								</div>
								<div className="card-stock">
									<div className="stock-txt">
										<h6>{this.state.cardDetail.availableCard}</h6>
										<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Available}</p>
									</div>
									<div className="stock-bar">
										<div className="progress" style={{ "height": "10px" }}>
											<div className="progress-bar bg-warning" role="progressbar" style={{ "width": `${this.state.availableCardPercentage}%` }} ></div>
										</div>
									</div>
								</div>


							</div>
						</div>


						<div className="col-12 col-lg">
							<div className="dash-card">
								<div className="dash-head">
									<Can
										role={this.props.userRole}
										perform="dashboard-loadMoney:visit"
										roleData={this.props.roleData}
										yes={() => (
											<a href="/load-money" className="sbtn btnbl">{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Load_money}</a>


										)}
										no={() =>
											null
										}
									/>

									<i className="bal"></i>
									<h5>{this.state.balanceEnquiry === '' ? "0.00" : parseFloat(this.state.balanceEnquiry).toFixed(2)}

									{
	                                 parseFloat(this.state.lowBalanceRupeeCompare) > parseFloat(this.state.balanceEnquiry) 
	                                // || parseFloat(process.env.REACT_APP_VAR_FOR_DASHBOARD_LOW_BALANCE_ALERT_RUPEE) === parseFloat(this.state.balanceEnquiry) 
	                                  ?
	                              	<span className="badge">  Low Balance</span>
		                              :
	                                	null
                                      }
                                    </h5>

									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Account_Balance}





									</p>
								</div>
							</div>

							<div className="dash-card">




								<div className="dash-kyc-full">
									<Can
										role={this.props.userRole}
										perform="dashboard-viewCardActivatedAndKycCompleted:visit"
										roleData={this.props.roleData}
										yes={() => (

											<a href="/assigned-cards" className="sbtn btnbl">{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_KYC_Approved_Card_Activated_View_all}</a>


										)}
										no={() =>
											null
										}
									/>
									<i className="biomatric"></i>
									<h5>{this.state.employeeStatusDetail.employeeKycApprovedAndCardActivated}</h5>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_KYC_Approved_Card_Activated}


									</p>
								</div>

								<div className="dash-kyc-min">
									<i className="mobotp"></i>
									<h5>{this.state.employeeStatusDetail.kycrejectedCount}</h5>
									<p>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_KYC_Under_Process}</p>
								</div>

							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-lg-8">
							<div className="dash-card">
								<div className="chart-head">
									<h2>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Funds_Loaded_Disbursed}
									</h2>
									{/* <Can
											role={this.props.userRole}
											perform="dashboard-disburseNow:visit"
											roleData={this.props.roleData}
											yes={() => ( */}

									<button className="sbtn btngn month" onClick={this.onClickMonths} disabled={this.state.barLoader}>
										{
											this.state.barLoader
												?
												<div className="spinner-border spinner-border-sm" role="status">
													<span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
												</div> :
												<>{this.state.requestTypeMonthly}</>
										}


									</button>


									{/* )}
											no={() =>
												null
											}
										/> */}

								</div>

								<div className="dash-chart">

									{
										this.state.noRecordForBar
											?
											<div className="col-12">
												<div className="no-record">
													<i></i>
													<h3> No Record Available.</h3>
												</div>
											</div>
											:
											<ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
									}
								</div>

							</div>
						</div>

						<div className="col-12 col-lg-4">
							<div className="dash-card">
								<div className="chart-head">
									<h2>{process.env.REACT_APP_Var_FOR_NavBar_DASBOARD_Categories_Funds_Disbursed}



									</h2>
									{/* <Can
											role={this.props.userRole}
											perform="dashboard-fundDisburseViewAll:visit"
											roleData={this.props.roleData}
											yes={() => ( */}

									<button className="sbtn btngn" onClick={this.pieButtonChange} disabled={this.state.barLoaderPie}>
										{
											this.state.barLoaderPie
												?
												<div className="spinner-border spinner-border-sm" role="status">
													<span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
												</div>
												:
												<>{this.state.buttonValueForPieChart}</>
										}
									</button>


									{/* )}
											no={() =>
												null
											}
										/> */}



								</div>
								<div className="dash-chart">

									{
										this.state.noRecordForPie
											?
											<div className="col-12">
												<div className="no-record">
													<i></i>
													<h3> No Record Available.</h3>
												</div>
											</div>
											:
											<ReactApexChart options={this.state.optionsDoughnut} series={this.state.seriesDoughnut} type="donut" />
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		)
	}

}

const mapStateToProps = ({ token: { authenticated }, user: { userRole, mdn, roMdn }, role: { roleData } }) => ({
	authenticated, userRole, roleData, roMdn, mdn
});

export default connect(mapStateToProps)(DashBoardComponent);
