import { ForgotPasswordActionTypes } from './forgot-password.type';

const INITIAL_STATE = {
    referenceNum: '',
    otp: '',
    email: '',

};

const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ForgotPasswordActionTypes.SET_OTP:
            return {
                ...state,
                otp: action.payload
            };

        case ForgotPasswordActionTypes.SET_REFERNECE_NUM:
            return {
                ...state,
                referenceNum: action.payload
            };
        case ForgotPasswordActionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload
            };

        case ForgotPasswordActionTypes.RESET_FORGET:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default forgotPasswordReducer;