import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import '../css/load-money.styles.css'
import SecurePng from '../../assests/secure-logos.png'
import GenerateRequestId from '../../common-function/GenerateRequestId';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import moment from 'moment';
import { store } from '../../redux/store';
import { setLoadAmountDetail } from '../../redux/load-money/loadMoney.action';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TrasactionDescription from '../../dasboard-reports/component/constants/corpSubtrsaction-type.coponent';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

//1-toup
//2-customer load money


var transactionMode = [
    { type: "card", method: "debit", label: "Debit Card", identifier: "DC", imageValue: "debit", serviceName: "loadMoney_dc", message: "Pay using Debit card Only" },
    { type: "upi", method: "upi", label: "UPI Payment", identifier: "UPI", imageValue: "upi", serviceName: "loadMoney_upi", message: "Pay using UPI Only" },
    { type: "card", method: "credit", label: "Credit Card", identifier: "CC", imageValue: "credit", serviceName: "loadMoney_cc", message: "Pay using Credit card Only" },
    // { type: "wallet", method: "wallet", label: "E-Wallet", identifier: "EW", imageValue: "wallet", serviceName: "loadMoney_ew",message:"Pay using E-wallet Only" },
    { type: "netbanking", method: "netbanking", label: "Net Banking", identifier: "NB", imageValue: "banking", serviceName: "loadMoney_nb", message: "Pay using Net Banking Only" }
]


// var loadMasterAmount = 0;
// var totalSurCharge = 0;
// var totalMasterAmount = 0;

class LoadMoney extends React.Component {

    constructor() {
        super();

        this.state = {
            amount: "",
            totalAvailableBalance: 0.00,
            totalLienBalance: 0.00,
            loadViaBankTransfer: "paymentGateway",
            type: "",
            loadAmount: 0.00,
            surchargeAmount: 0.00,
            totalLoadAmount: 0.00,
            recentLoadMoney: [],
            size: 0,
            transactionModeArray: [],
            serviceName: "",
            selectedDetail: {},
            buttonDisable: false,

            selectedMessage: "",

            dropDownHidden: false,
            message: "",
            severty: "success",
            startDate: moment().subtract(90, 'days').format('DDMMYYYY'), 
            enddate: moment().format('DDMMYYYY'),
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Load Money"
        console.log('JSON.stringify(transactionMode) valueToJson', process.env.REACT_APP_TRANSACTION_MODE_ALLOWED_FOR_LOAD_MONEY, " ", JSON.stringify(transactionMode))
        var valueToJson = JSON.parse(process.env.REACT_APP_TRANSACTION_MODE_ALLOWED_FOR_LOAD_MONEY || "[]")
        console.log('JSON.stringify(transactionMode) valueToJson', valueToJson)
        this.setState({ transactionModeArray: valueToJson });

        this.getBalance(this.props.roMdn);
        this.getRecentLoadMoney(this.props.roMdn);
        // this.getBalance('1111111111');
        // this.getRecentLoadMoney('1111111111');

    }

    getBalance = async (mdn) => {

        const request = {
            device_info: {
                api_version: "1.0",
                app_version: "9.2",
                device_id: "d215ab007e05c498",
                device_os: "Android",
                device_os_version: "5.1.1",
                imei: "357869081907005",
                ip_address: "172.160.2.140",
                model_name: "SM-J320F",
                source: "mobile",
            },
            request: {
                user: {
                    username: `91${mdn}`
                },
                transaction_data: {
                    request_id: GenerateRequestId.geneRateRequestId(),
                    limit_required: "true"
                }
            }
        };
        let headers = {
            "client_id": "OEMSAPP",
            "token": this.props.token,
            "Authorization": "BASIC abcd"
        }
        console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props);
        var response = [];

        const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
        console.log(url, request, "header")
        response = await apiCallService.postMethodCall(url, request, headers);
        const detail = response.response;
        console.log('response balanceEnquiry', response)
        if (detail !== undefined && response.response_code === 0) {
            this.setState({ totalAvailableBalance: parseFloat(detail.Balance.total_balance / 100).toFixed(2) })
            this.setState({ totalLienBalance: parseFloat(detail.Balance.total_lien / 100).toFixed(2) })
        } else {
            return 0.00
        }

    }
    getRecentLoadMoney = async (mdn) => {
        let headers = {
            "client_id": "OMTS_77B",
            "token": this.props.token,
            "Authorization": this.props.token
        }
    
        const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY_YBL
    
        var request = {
            clientIndentifier: this.props.roMdn,
            //  "clientIdentifier": "1932159428",
            "txnStartDate": this.state.startDate,
            "txnEndDate": this.state.enddate,
            "pageNumber": this.state.currentPage // Use current page number
        }
    
      
        var response = await apiCallService.postMethodCall(url, request, headers);

        console.log('response txn history', response)
        if (response !== undefined && response.response_code === '0' && response.status === "success" && Array.isArray(response?.response?.transactionListDTO)) {

            var arr = []
            var int = 0;
          
            for(let i = 0; i < response?.response?.transactionListDTO; i++){
                if (int < 6) {
                    arr.push(response?.response?.transactionListDTO[i]);

                    int++;
                    } 
            }
            this.setState({ recentLoadMoney: arr })
            this.setState({ size: response.response.transaction_data.lenght })
        }

    }

    // getRecentLoadMoney = async (mdn) => {
    //     let headers = {
    //         "client_id": "OMTS_77B",
    //         "token": this.props.token,
    //         "Authorization": this.props.token
    //     }

    //     const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY

    //     var request = {
    //         "device_info": {},
    //         "request": {
    //             "user": {
    //                 "username": mdn
    //             },
    //             "transaction_data": {
    //                 "count": 6,
    //                 "from_date": null,
    //                 "page_index": 0,
    //                 "request_id": GenerateRequestId.geneRateRequestId(),
    //                 "time_stamp": new Date(),
    //                 "to_date": null,
    //                 "transaction_status": "3",
    //                 "wallet_type": null,
    //                 "transaction_type": "2"
    //             }
    //         }
    //     }

    //     var response = await apiCallService.postMethodCall(url, request, headers);

    //     console.log('response txn history', response)
    //     if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {

    //         var arr = []
    //         var int = 0;
    //         response.response.transaction_data.map(data => {
    //             if (int < 6) {
    //                 arr.push(data)
    //                 int++
    //             }
    //         })
    //         this.setState({ recentLoadMoney: arr })
    //         this.setState({ size: response.response.transaction_data.lenght })
    //     }

    // }

    onAmountChange = (event) => {
        console.log('triggered onAmountChange', event.target);

        var paise = (parseFloat(event.target.value)) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }



        this.setState({ amount: event.target.value });

        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated(event.target.value === "" ? 0 : parseFloat(event.target.value) * 100, this.state.serviceName);
        }
    }

    onfiveHundredChange = (event) => {
        console.log('triggered onAmountChange', event.target);

        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 500) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }



        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 500 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 500) * 100, this.state.serviceName);
        }
    }
    onOneThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);
        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 1000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }


        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 1000 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 1000) * 100, this.state.serviceName);
        }

    }
    onFiveThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);

        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 5000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ loadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }


        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 5000 })
        this.setState({ buttonDisable: true })
        this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 5000) * 100, this.state.serviceName);

    }
    onFifteenThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);


        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 15000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ loadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }



        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 15000 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 15000) * 100, this.state.serviceName);
        }

    }
    onThirtyThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);
        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 30000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ loadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }


        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 30000 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 30000) * 100, this.state.serviceName);
        }
    }



    setServiceNameState = (event, mode) => {

        console.log('event', event);
        console.log('mode', mode);
        this.setState({ type: mode.identifier, serviceName: mode.serviceName, selectedDetail: mode, selectedMessage: mode.message })
        this.setState({ buttonDisable: true })
        if (this.state.amount !== "") {
            this.getSurchargeCalulated(parseFloat(this.state.amount) * 100, mode.serviceName);
        }

    }


    loadViaBankTransferSet = (event, mode) => {

        // this.setState({ type: "viaBankTransfer", serviceName: "loadMoney_viaBankTransfer", loadViaBankTransfer: "viaBankTransfer" })

        // this.setState({ buttonDisable: true })

        history.push('/info-loadMoney');
        history.go();

        // if (this.state.amount !== "") {
        //     this.getSurchargeCalulated(parseFloat(this.state.amount) * 100, "loadMoney_viaBankTransfer");
        // }
    }






    loadMoney = async (e) => {

        e.preventDefault();

        const url = process.env.REACT_APP_RAZORPAY_ORDER;

        var request = {
            "request": {
                "channel": "1",
                "amount": this.state.loadAmount,
                "surcharge": this.state.surchargeAmount,
                "mdn": this.props.roMdn,
                // "mdn": '1111111111',
                "userType": "1",
                "requestId": GenerateRequestId.geneRateRequestId(),
                "paymentMode": this.state.selectedDetail.identifier,
                "loadBy": this.props.currentUser
            }
        }
        let headers = {
            "client_id": "OEMSAPP",
            "token": this.props.token,
            "Authorization": "BASIC abcd"
        }


        var response = await apiCallService.postMethodCall(url, request, headers);

        console.log('response loadMoney', response, request)

        if (response !== undefined && response.response !== undefined && response.response.responseCode !== undefined && parseInt(response.response.responseCode) === 0) {
            this.paymentHandler(response.response.orderId)
        } else if (response !== undefined && response.response !== undefined && response.response.responseMessage !== undefined && parseInt(response.response.responseMessage) !== '') {

            this.setState({ message: response.response.responseMessage })
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true })
        } else {
            this.setState({ message: response.response_description })
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true })
        }


    }


    paymentHandler = async (orderId) => {
        //const API_URL = 'http://localhost:8000/

        //const orderUrl = `${API_URL}order`;
        //const response = await Axios.get(orderUrl);
        //const { data } = response;

        console.log("selected tpe this.state.selectedDetail.method", this.state.selectedDetail.method, this.state.selectedDetail.type)

        var loadAmount = this.state.loadAmount;
        var surchargeAmount = this.state.surchargeAmount;
        var totalLoadAmount = this.state.totalLoadAmount;

        var instumentArray = [];

        var message = this.state.selectedMessage;

        if (this.state.selectedDetail.method === 'upi') {
            instumentArray.push({
                method: this.state.selectedDetail.method
            })
        } else if (this.state.selectedDetail.method === 'netbanking') {
            instumentArray.push({
                method: this.state.selectedDetail.type
            })
        } else if (this.state.loadViaBankTransfer === 'viaBankTransfer') {
            instumentArray.push({
                method: 'card'
            })
        }
        else {
            instumentArray.push({
                method: this.state.selectedDetail.type,
                types: [this.state.selectedDetail.method]
            })
        }



        const options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY,
            "amount": this.state.totalLoadAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": process.env.REACT_APP_RAZORPAY_CONFIG_CURRENCY,
            "name": process.env.REACT_APP_RAZORPAY_CONFIG_Name,
            "description": process.env.REACT_APP_RAZORPAY_CONFIG_Description,
            "image": process.env.REACT_APP_RAZORPAY_CONFIG_Image,
            "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (response) {


                console.log("Got Success from razorpay", response);


                var url = process.env.REACT_APP_RAZORPAY_UPDATE_ORDER;

                var request = {
                    "request": {
                        "paymentId": response.razorpay_payment_id,
                        "orderId": response.razorpay_order_id,
                        "hashCode": response.razorpay_signature,
                        "responseDescription": "Payment Successfull",
                        "paymentStatus": 0
                    }
                }


                var responseApi = await apiCallService.postMethodCall(url, request);

                console.log("response from update order api", responseApi)
                if (responseApi !== undefined) {
                    var payLoad = {
                        loadAmount: loadAmount,
                        surchargeAmount: surchargeAmount,
                        totalLoadAmount: totalLoadAmount,
                        transactionId: response.razorpay_order_id,
                        transactionDateAndTime: new Date(),
                        transactionMode: 'Payment Gateway'
                    }

                    store.dispatch(setLoadAmountDetail(payLoad))
                    history.push('/load-money-detail');
                    history.go();
                }

                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
            },
            "prefill": {
                "name": this.props.fullName,
                "email": this.props.currentUser,
                "contact": this.props.mdn
            },
            "notes": {
                "address": process.env.REACT_APP_RAZORPAY_CONFIG_notes_address
            },
            "theme": {
                "color": process.env.REACT_APP_RAZORPAY_CONFIG_theme_color
            },
            config: {
                display: {
                    blocks: {
                        cardPayment: { //name for HDFC block
                            name: message,
                            instruments: instumentArray

                        }
                    },
                    sequence: ["block.cardPayment"],
                    preferences: {
                        show_default_blocks: false // Should Checkout show its default blocks?
                    }
                }
            }
        };


        console.log('this.state.selectedDetail.method options', options)
        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', async function (response) {


            console.log("Got Success from razorpay", response);


            var url = process.env.REACT_APP_RAZORPAY_UPDATE_ORDER;

            var request = {
                "request": {
                    "paymentId": response.error.metadata.payment_id,
                    "orderId": response.error.metadata.order_id,
                    "responseDescription": response.error.description,
                    "hashCode": '',
                    "paymentStatus": response.error.code
                }
            }


            var responseApi = await apiCallService.postMethodCall(url, request);

            console.log("response fail condition from update order api", responseApi)
            // if (responseApi !== undefined && responseApi.response !== undefined && responseApi.response.responseCode !== undefined && parseInt(responseApi.response.responseCode) === 0) {
            //     this.paymentHandler(response.response.orderId)
            // } else
            if (responseApi !== undefined && responseApi.response !== undefined && responseApi.response.responseMessage !== undefined && parseInt(responseApi.response.responseMessage) !== '') {

            } else {

            }



            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    };



    getSurchargeCalulated = async (amount, serviceName) => {



        var message = 'Api not called'
        var url = process.env.REACT_APP_CALCULATE_SURCHARGE;
        var request = {
            "roId": this.props.roMdn,
            "quantity": amount,
            "serviceName": serviceName
        }
        console.log('response inside render method', message, ' : ', amount, serviceName)
        // if (amount !== 0 && serviceName !== '') {
        var response = await apiCallService.postMethodCall(url, request);
        message = 'Api called'
        console.log('response inside render method', message)
        console.log('response inside render method', response, request)
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
            this.setState({ buttonDisable: false })
            this.setState({ surchargeAmount: response.response.surcharge })
            this.setState({ totalLoadAmount: parseFloat(response.response.surcharge) + parseFloat(amount) })
            this.setState({ loadAmount: amount })

        } else {
            this.setState({ surchargeAmount: 0 })
            this.setState({ totalLoadAmount: 0 })
            this.setState({ loadAmount: amount })
            this.setState({ message: response.response_description })
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true })
        }
        // }

    }


    render() {

        // this.getSurchargeCalulated();
        console.log("States here", this.state)

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <div className="mid-container">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-8">
                                            <div className="loadmoneyform">
                                                <form action="load-money-details.html">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Load_Money} <a href="/reverse-money" className="sbtn btngr" style={{ float: "right" }}>Reverse Money</a></h3>
                                                        </div>

                                                        <Snackbar

                                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            open={this.state.dropDownHidden}
                                                            autoHideDuration={20000}
                                                            onClose={e => this.setState({ dropDownHidden: false })}
                                                        >
                                                            <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                                {this.state.message}
                                                            </Alert>
                                                        </Snackbar>

                                                        <div className="col-12">
                                                            <div className="avail-balance">
                                                                <ul>
                                                                    <li>
                                                                        <i>&#8377;</i>
                                                                        <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Total_Available_Balance}</p>
                                                                        <h4>{this.state.totalAvailableBalance}</h4>
                                                                    </li>

                                                                    <li>
                                                                        <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Lien_Balance}</p>
                                                                        <h4>{this.state.totalLienBalance}</h4>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-8 col-md-6 col-lg-9 col-xl-6">
                                                            <div  className="webform">
                                                                <div  className="form-floating">
                                                                    <input className="form-control"
                                                                        placeholder="Amount"
                                                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                        type="number"
                                                                        id="acntn" value={this.state.amount} onChange={this.onAmountChange} />
                                                                    <label for="acntn">Enter Load Amount</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="addamt">
                                                                <button type="button" className="addbtn" onClick={this.onfiveHundredChange}>₹ 500</button>
                                                                <button type="button" className="addbtn" onClick={this.onOneThousandChange}>₹ 1000</button>
                                                                <button type="button" className="addbtn" onClick={this.onFiveThousandChange}>₹ 5000</button>
                                                                <button type="button" className="addbtn" onClick={this.onFifteenThousandChange}>₹ 15000</button>
                                                                <button type="button" className="addbtn" onClick={this.onThirtyThousandChange}>₹ 30000</button>
                                                            </div>
                                                        </div>


                                                        <div className="col-12 mt-2">

                                                            <div className="form-check form-check-inline">
                                                                <input type="radio" id="paymentGateway" className="form-check-input" name="Payment Gateway" value={this.state.loadViaBankTransfer} checked={this.state.loadViaBankTransfer === 'paymentGateway'} onClick={e => { this.setState({ loadViaBankTransfer: "paymentGateway" }) }} />
                                                                <label className="form-check-label" for="paymentGateway" >
                                                                    {process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Load_via_Payment_Gateway}
                                                                </label>
                                                            </div>

                                                            <div className="form-check form-check-inline">
                                                                <input type="radio" id="viaBankTransfer" className="form-check-input" name="minKyc" value={this.state.loadViaBankTransfer} checked={this.state.loadViaBankTransfer === 'viaBankTransfer'} onClick={this.loadViaBankTransferSet} />
                                                                <label className="form-check-label" for="viaBankTransfer" >
                                                                    {process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Load_via_Bank_Transfer_Note}
                                                                </label>
                                                            </div>


                                                        </div>

                                                        {this.state.loadViaBankTransfer === "paymentGateway"
                                                            ?
                                                            <div className="col-12">
                                                                <div className="pay-gateway">
                                                                    <div className="row">


                                                                        {
                                                                            this.state.transactionModeArray.map(mode => (
                                                                                <div className="col-12 col-sm-6">
                                                                                    <div className="gatewaybox">

                                                                                        <div className="form-check form-check-inline">
                                                                                            <input type="radio" id={mode.identifier} className="form-check-input" name="debit" value={this.state.type} checked={this.state.type === mode.identifier} onClick={e => this.setServiceNameState(e, mode)} />
                                                                                            <label className="form-check-label payicon" for={mode.identifier} >
                                                                                                <i className={mode.imageValue}></i>{mode.label}
                                                                                            </label>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }


                                                        <div className="col-12 mt-4">
                                                            <div className="sbmtcard">
                                                                <div className="row">
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay plus">
                                                                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Load_Amount}</p>
                                                                            <h4>₹ {this.state.loadAmount === "" ? 0 : parseFloat(parseFloat(this.state.loadAmount) / 100).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay equal">
                                                                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Surcharge}</p>
                                                                            <h4>₹ {this.state.surchargeAmount === "" ? 0 : parseFloat(parseFloat(this.state.surchargeAmount) / 100).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay">
                                                                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Total_Amount}</p>
                                                                            <h4>₹ {this.state.totalLoadAmount === "" ? 0 : parseFloat(parseFloat(this.state.totalLoadAmount) / 100).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay">
                                                                            {parseInt(this.state.totalLoadAmount) === 0 ?
                                                                                null :
                                                                                <button type="button" value="" className="lbtn btnbl" onClick={this.loadMoney} disabled={this.state.buttonDisable}>Proceed</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-12 col-md-7 col-xl-6 mt-5"><img src={SecurePng} alt="secure" className="img-fluid" /></div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6 col-xl-4">
                                            <div className="recent-txn">
                                                <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Recent_Load_Money}
                                                    {
                                                        this.state.size < 6
                                                            ?
                                                            null
                                                            :
                                                            <Link

                                                                to={{
                                                                    pathname: '/load-txn',
                                                                    // state: { mdn: this.props.mdn,roMdn: this.props.roMdn },
                                                                    state: { roMdn: this.props.roMdn },

                                                                }}
                                                                className="sbtn btnbl"
                                                            >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Recent_Load_Money_View_all_Button}</Link>
                                                    }
                                                </h3>
                                                {this.state.size === 0 ?
                                                    <div className="col-12">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3 style={{ borderBottom: "none" }}> No Record Available.</h3>
                                                        </div>
                                                    </div>
                                                    :


                                                    this.state.recentLoadMoney.map(data => (
                                                        <div className="txncard">
                                                            <a style={{ color: "#354558" }} href={() => false} >
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-8">
                                                                        <div className="txntype">
                                                                            <i className="credit" data-toggle="tooltip" title="Credit"></i>
                                                                            <TrasactionDescription sub_transaction_type={data.sub_transaction_type} transaction_description={data.transaction_description} merchant_name={data.merchant_name} />

                                                                            {/* <h5>Load Money - TA Balance</h5> */}
                                                                            <p>#{data.transaction_refnumber} <br />{data.transaction_string_date === null ? data.transaction_date : data.transaction_string_date}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4">

                                                                        <div className="txnamount">
                                                                            <h4>₹ {parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2)}</h4>
                                                                            {data.transaction_status === "1"
                                                                                ?
                                                                                <p className="success">Success</p>
                                                                                :
                                                                                <p className="failed">{data.transaction_description}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )

                                                    )


                                                }


                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>

                            :
                            <ResetLoginPassword />

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, token, currentUser, fullName, isPasswordReset

});

export default connect(mapStateToProps)(LoadMoney);