import { RoleActionTypes } from './role.types';

export const setRoleData = roleData => ({
  type: RoleActionTypes.SET_ROLE_DATA,
  payload: roleData
});
export const setRoleAdminBoolean = roleData => ({
  type: RoleActionTypes.SET_ROLE_FOR_ADMIN_BOOLEAN,
  payload: roleData
});

export const setCurrentUserRoleName = roleData => ({
  type: RoleActionTypes.SET_CURRENT_USER_ROLE_NAME,
  payload: roleData
});

export const setHuburgShowOrHide = data => ({
  type: RoleActionTypes.SETHUBURGSHOWHIDE,
  payload: data
});


export const setFromRole = data => ({
  type: RoleActionTypes.SET_FROM_ROLE_STATUS,
payload: data
});


export const setUserMaintain = data => ({
  type: RoleActionTypes.SET_USER_MAINTAIN,
payload: data
});


export const resetRole = ({
  type: RoleActionTypes.RESET_ROLE
});
