import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import '../css/loading.styles.css'

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

export default function Animations() {
  //const classes = useStyles();
  return (
    < >





      <div className="col-12 col-sm-6 col-xl-4" >

        <div className='loading-card'>
          {/* <div className={classes.root}> */}
          <div className="loading-basic">
            <Skeleton className="loading-basic-img" variant="circle" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <div className="loading-check">
              <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                <input
                  type="checkbox"
                  id='1'
                  className="mdl-checkbox__input"
                  value="1" disabled="disabled"
                />
              </label>
            </div>
            <div className="user-more dropdown">
              <a id='2' className="mdl-button mdl-js-button ">
                <i className="more"></i>
              </a>
            </div>
            <br />
            <Skeleton className="loading-basic-p-span" variant="text" animation="wave" />
          </div>

          {/* <Skeleton variant="rect"  height={110} /> */}
        </div>

      </div>

      <div className="col-12 col-sm-6 col-xl-4" >
        <div className='loading-card'>
          {/* <div className={classes.root}> */}
          <div className="loading-basic">
            <Skeleton className="loading-basic-img" variant="circle" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <div className="loading-check">
              <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                <input
                  type="checkbox"
                  id='1'
                  className="mdl-checkbox__input"
                  value="1" disabled="disabled"
                />
              </label>
            </div>
            <div className="user-more dropdown">
              <a id='2' className="mdl-button mdl-js-button " >
                <i className="more"></i>
              </a>
            </div>
            <br />
            <Skeleton className="loading-basic-p-span" variant="text" animation="wave" />
          </div>

          {/* <Skeleton variant="rect"  height={110} /> */}
        </div>
      </div>
      <div className="col-12 col-sm-6 col-xl-4" >
        <div className='loading-card'>
          {/* <div className={classes.root}> */}
          <div className="loading-basic">
            <Skeleton className="loading-basic-img" variant="circle" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <div className="loading-check">
              <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                <input
                  type="checkbox"
                  id='1'
                  className="mdl-checkbox__input"
                  value="1" disabled="disabled"
                />
              </label>
            </div>
            <div className="user-more dropdown">
              <a id='2' className="mdl-button mdl-js-button " >
                <i className="more"></i>
              </a>
            </div>
            <br />
            <Skeleton className="loading-basic-p-span" variant="text" animation="wave" />
          </div>

          {/* <Skeleton variant="rect"  height={110} /> */}
        </div>

      </div>
    </>
  );
}