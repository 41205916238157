import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (



        <>

            <div className="col-12 col-sm-6 col-xl-4 ">

                <div className="upload-card">
                    <Skeleton className="img-assigned" width={40} height={40} variant="circle" />

                    <div className="upload-type">
                        <p> <Skeleton className="loading-full" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-full" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="uploadLoadingDate"> <Skeleton className="loading-full" variant="text" animation="wave" /></div>


                    <div className="uploadLoading">
                        <Skeleton className="loading-full" variant="text" animation="wave" />

                    </div>

                </div>

            </div>
            <div className="col-12 col-sm-6 col-xl-4">

                <div className="upload-card">
                    <Skeleton className="img-assigned" width={40} height={40} variant="circle" />

                    <div className="upload-type">
                        <p> <Skeleton className="loading-full" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-full" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="uploadLoadingDate"> <Skeleton className="loading-full" variant="text" animation="wave" /></div>


                    <div className="uploadLoading">
                        <Skeleton className="loading-full" variant="text" animation="wave" />

                    </div>

                </div>

            </div>
            <div className="col-12 col-sm-6 col-xl-4">

                <div className="upload-card">
                    <Skeleton className="img-assigned" width={40} height={40} variant="circle" />

                    <div className="upload-type">
                        <p> <Skeleton className="loading-full" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-full" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="uploadLoadingDate"> <Skeleton className="loading-full" variant="text" animation="wave" /></div>


                    <div className="uploadLoading">
                        <Skeleton className="loading-full" variant="text" animation="wave" />

                    </div>

                </div>

            </div>


        </>
    );
}