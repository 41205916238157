import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import '../../css/loading.styles.css'

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

export default function Animations() {
    //const classes = useStyles();
    return (
        <div className="col-12">


            <div className="txncard">
                <div className="row">
                    <div className="col-12 col-md-5 col-lg-5 col-xl-5">
                        <div className="txntype">
                            <Skeleton className="loading-basic-img" variant="circle" />
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                        <div className="txnfrom">
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                            <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        </div>
                    </div>

                    <div className="col-8 col-md-2 col-lg-2 col-xl-3">
                        <div className="txnamount">
                            <h4><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                            <p className="success"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="txncard">
                <div className="row">
                    <div className="col-12 col-md-5 col-lg-5 col-xl-5">
                        <div className="txntype">
                            <Skeleton className="loading-basic-img" variant="circle" />
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                        <div className="txnfrom">
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                            <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        </div>
                    </div>

                    <div className="col-8 col-md-2 col-lg-2 col-xl-3">
                        <div className="txnamount">
                            <h4><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                            <p className="success"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="txncard">
                <div className="row">
                    <div className="col-12 col-md-5 col-lg-5 col-xl-5">
                        <div className="txntype">
                            <Skeleton className="loading-basic-img" variant="circle" />
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                        <div className="txnfrom">
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                            <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        </div>
                    </div>

                    <div className="col-8 col-md-2 col-lg-2 col-xl-3">
                        <div className="txnamount">
                            <h4><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                            <p className="success"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
}