


import React, { useState, useEffect } from 'react';

import '../../employee/css/employee.css';
import "../css/dropdown.styles.css";
import CardStatus from '../../common/component/card-status.component';
import ApiCallingService from '../../service/api-call-service';
//import BalanceEnquiryService from '../../service/service-impl/BalanceEnquiryService'
import CardManagementService from '../../service/service-impl/CardManagementService';
import SmallPopUp from "../../common/component/small.popup.component";
import ManageCategoryLimit from '../component/manage-category-limit.component';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import CommonFunction from '../../common-function/GenerateRequestId';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setMessageToBeShown } from '../../redux/message/message.action';
import ApiCalling from '../../service/api-call-service';
import history from '../../auth/history';
import BalanceImformation from '../component/balance.information.component';
import GenerateRequestId from '../../common-function/GenerateRequestId';
import Select from "react-select";
import moment from "moment";
import CardStatusType from '../../constants/card-status.type';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import VerifyDisburse from '../component/VerifyDisburse.component'
import apiCallService from '../../service/api-call-service';
import CardStatusTypes from '../../constants/card-status.type';
import CategoryLimit from '../../constants/category-limit.type';
import Can from '../../auth/can/Can';
import image1 from "../../assests/no-image.png";
import MultiCardStatus from '../../common/component/multiCardStatus.component';
import { setEmployeeDetails } from "../../redux/user/user.actions";
import { useToasts } from 'react-toast-notifications';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import KycStatus from '../../constants/kyc-status.type';
import AvailCardDiv from './availCard.employee';
import EmpDetailLoadingComponent from './empDetail.loader';

var roleStatustoSend = 1;
const EmployeeDetail = (props) => {
    console.log('Ro Mdn', props.roMdn);
    var resp = [];

    //var arr = {};
    const resultSet = {
        emp_id: '', mdn: '', fullName: '', first_name: '', last_name: '', gender: '', email: '', pan: '', aadhar_no: '', createdBy: '', roleName: '',
        band: '', department: '', location: '', pincode: '', dob: '', date_of_joining: '', card_ref_no: '', card_staus: '', manager_emp_corp_id: '',
        is_paper_kyc: '', finance_emp_corp_id: '', roMdn: '', type: '', roleName: '', modifyBy: '', wallet: []
    }
    const balanceSet = {
        user: { username: '', wallet_level: '' }, Balance: {
            sub_wallet_balance: [{ balance: '', wallet: '', lean: '', name: '', iconUrl: '' }, { balance: '', wallet: '', lean: '', name: '', iconUrl: '' }],
            total_balance: '', monthly_available_limit: '', monthly_consumed_limit: '', annual_available_limit: '', annual_consumed_limit: '', holding_available_limit: '',
            annual_food_available_limit: '', annual_food_consumed_limit: '', total_lien: ''
        }, transaction_data: [{ transaction_RefNumber: '' }]
    }

    const [employeeDetail, setEmployeeDetail] = useState(resultSet);
    const [loading, setLoading] = useState(false);
    const [balanceEnquiry, setBalanceEnquiry] = useState(balanceSet);
    const [editShowHidden, setEditShowHidden] = useState(true);
    const [male, setMale] = useState(false);
    const [female, setFemale] = useState(false);
    const [other, setOther] = useState(false);
    const [severty, setSeverty] = useState('success');
    const [roleName, setRoleName] = useState('');
    const [nullEmailCheck, setNullEmailCheck] = useState(false)
    const [nullLocationCheck, setNullLocationCheck] = useState(false)
    const [nullPincodeCheck, setNullPincodeCheck] = useState(false)
    const [previousEmail, setPreviousEmail] = useState('');
    const [popupverifydisburse, setpopupverifydisburse] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [travelAmount, setTravelAmount] = useState(0.00);
    const [foodAmount, setFoodAmount] = useState(0.00);
    const [generalAmount, setgeneralAmount] = useState(0.00);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [modal, setModal] = useState('');
    const [close, setClose] = useState('');
    const [walletArray, setWalletArray] = useState([]);
    const [checkLimit, setCheckLimit] = useState(true)
    const [time, setTime] = useState(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT));
    const [triggeretime, setTriggeretime] = useState(true);
    const { addToast } = useToasts()

    const [kyc_status, setKycStatus] = useState(1);
    const [kycExpireDate, setKycExpiryDate] = useState("");

    const [loadingCom, setLoadingCom] = useState(false)

    useEffect(() => {
        //var mdn = props.location.state.employee.mdn;
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "active",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));


        // window.history.pushState(null, document.title, window.location.href);
        // window.addEventListener('popstate', function (event){
        //     window.history.pushState(null, document.title,  window.location.href);
        // });



        //var rol=''
        // props.masterRoleData.map(role => {
        // 	rol=role.label

        // })



        const fetchEmployeeDetail = async () => {
            console.log('prps location', props.location.state);

            if (props.location.state) {

                setLoadingCom(true);

                // const url = `${process.env.REACT_APP_GET_INEMPLOYEE_BY_EMAIL}?email=${props.location.state.employee.email}`
                // const res = await ApiCallingService.getMethodCallWithoutParam(url);


                const url = process.env.REACT_APP_GET_EMPLOYEE_BY_MDN_AND_STATUS;
                const res = await ApiCallingService.getMethodCallWithParam(url, { 'mdn': props.location.state.employee.mdn, 'status': 1 });

                console.log("Employee Detail Response Fo $$%5556%^^^", res);

                if (res !== undefined && res.status !== undefined && parseInt(res.status) === 0 && res.response !== undefined && res.response.employeeDetails !== undefined) {
                    setLoadingCom(false);
                    console.log("fetchEmployeeDetail", props.location.state);
                    var detail = res.response.employeeDetails;
                    // var detail = props.empDetail;
                    var dateOfBirth = moment(detail.dob).format("YYYY-MM-DD");
                    detail.dob = dateOfBirth;
                    var dateOfJoining = detail.date_of_joining.split(' ');
                    detail.date_of_joining = dateOfJoining[0]
                    var walletDetails = res.response.employeeDetails.wallet;
                    var wallet = [];
                    var arrayForGeneralAmount = [];
                    var arrayForTravelAmount = [];
                    var arrayForFoodAmount = [];

                    arrayForGeneralAmount.push(props.maxGeneralAmount / 100);
                    arrayForTravelAmount.push(props.maxTravelAmount / 100);
                    arrayForFoodAmount.push(props.maxFoodAmount / 100);

                    props.corpBand.map(band => {
                        if (band.band === detail.band) {
                            arrayForGeneralAmount.push(band.general_Limit / 100);
                            arrayForTravelAmount.push(band.travel_Limit / 100);
                            arrayForFoodAmount.push(band.wallet_food_limit / 100);
                        }
                    })
                    console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount ", maxFoodAmount, maxTravelAmount, maxFoodAmount)

                    walletDetails.map(limit => {
                        if (limit.wallet_id === parseInt(CategoryLimit.GENERAL)) {
                            console.log('Inside maxFoodAmount')
                            arrayForGeneralAmount.push(limit.amount / 100);
                        }
                        if (limit.wallet_id === parseInt(CategoryLimit.TRAVEL)) {
                            arrayForTravelAmount.push(limit.amount / 100);
                        }
                        if (limit.wallet_id === parseInt(CategoryLimit.FOOD)) {
                            arrayForFoodAmount.push(limit.amount / 100);
                        }
                    })


                    detail.card.map(dd => {
                        setKycExpiryDate(dd.expiryDate);
                        setKycStatus(dd.kycStatus);
                    })
                    // var i = arrayForGeneralAmount.indexOf(0);
                    // if (i >= 0) {
                    //     arrayForGeneralAmount.splice(i, 1);
                    // }

                    // var j = arrayForTravelAmount.indexOf(0);
                    // if (j >= 0) {
                    //     arrayForTravelAmount.splice(j, 1);
                    // }
                    // var k = arrayForFoodAmount.indexOf(0);
                    // if (k >= 0) {
                    //     arrayForFoodAmount.splice(k, 1);
                    // }

                    arrayForGeneralAmount = arrayForGeneralAmount.filter(function (val) {
                        return val !== 0;
                    });
                    arrayForTravelAmount = arrayForTravelAmount.filter(function (val) {
                        return val !== 0;
                    });
                    arrayForFoodAmount = arrayForFoodAmount.filter(function (val) {
                        return val !== 0;
                    });

                    var maxGeneralAmount = Math.min(...arrayForGeneralAmount);
                    var maxTravelAmount = Math.min(...arrayForTravelAmount);
                    var maxFoodAmount = Math.min(...arrayForFoodAmount);

                    console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount ", maxFoodAmount, maxTravelAmount, maxFoodAmount)
                    walletDetails.map(wal => {
                        var label = 'label';
                        var max = 'max';

                        if (wal.wallet_id === parseInt(CategoryLimit.GENERAL)) {
                            console.log(' wallet detail GENERAL', wallet)
                            wal[label] = 'General';
                            wal[max] = maxGeneralAmount;
                            wallet.push(wal);
                        }
                        if (wal.wallet_id === parseInt(CategoryLimit.FOOD)) {
                            console.log(' wallet detail FOOD', wallet)
                            wal[label] = 'Food';
                            wal[max] = maxFoodAmount;
                            wallet.push(wal);
                        }
                        if (wal.wallet_id === parseInt(CategoryLimit.TRAVEL)) {
                            console.log(' wallet detail TRAVEL', wallet)
                            wal[label] = 'Travel'
                            wal[max] = maxTravelAmount;
                            wallet.push(wal);
                        }

                    })
                    const editShowHiddenProp = props.location.state.editShowHidden;
                    console.log("editShowHiddenProp", editShowHiddenProp);
                    setEditShowHidden(editShowHiddenProp)
                    setPreviousEmail(detail.email);
                    console.log(' wallet detail', walletDetails)
                    if (detail.gender.toLowerCase() === 'male') {
                        setMale(true);
                    } else if (detail.gender.toLowerCase() === 'female') {
                        setFemale(true);
                    } else {
                        setOther(true);
                    }

                    setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...detail }));
                    console.log('roleName', detail.roleName)
                    setRoleName(detail.roleName);
                    setWalletArray(wallet);
                    console.log('roleName', roleName)
                    //setRoleName(rol);
                }
            }
            else {
                setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...null }));
            }
        }

        const fetchBalanceDetail = async () => {
            setLoading(true);
            //resp =await BalanceEnquiryService.balanceEnquiry('http://172.160.0.249:7072/api/wallet/BalanceEnquiry', '919887835909', "")
            console.log('fetch blance enquiry', resp);
            console.log('fetch blance loading...', loading);
            const request = {
                device_info: {
                    api_version: "1.0",
                    app_version: "9.2",
                    device_id: "d215ab007e05c498",
                    device_os: "Android",
                    device_os_version: "5.1.1",
                    imei: "357869081907005",
                    ip_address: "172.160.2.140",
                    model_name: "SM-J320F",
                    source: "mobile",
                },
                request: {
                    user: {
                        username: employeeDetail.mdn
                    },
                    transaction_data: {
                        request_id: '988df6a3-8bd6-495e-8373-05bd4671ccfb',
                        limit_required: "true"
                    }
                }
            };
            let headers = {
                "client_id": "OEMSAPP",
                "token": props.token,
                "Authorization": "BASIC abcd"
            }
            console.log("inside balanceEnquiry request :-", request);
            var response = [];
            async function run() {
                const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
                console.log(url, request, "header")
                response = await ApiCallingService.postMethodCall(url, request, headers);
                const detail = response.response;
                console.log('response', response.response)
                setBalanceEnquiry(prevBalaceEnquire => ({ ...prevBalaceEnquire, ...detail }));
                setLoading(false);
            }
            run();


        }

        fetchBalanceDetail();
        fetchEmployeeDetail();

    }, [])


    const activateEmployee = async () => {




        const RequestToSend = {
            "request": {
                "request_id": CommonFunction.geneRateRequestId(),
                "employee": {
                    "corpId": props.roMdn,
                    "empId": employeeDetail.emp_id,
                    "mdn": employeeDetail.mdn,
                    "status": "0"
                }
            }
        }
        console.log(RequestToSend);
        const url = process.env.REACT_APP_UPDATE_INACTIVE_EMPLOYEE_TO_ACTIVE_URL;
        console.log('RequestToSend', RequestToSend);
        var response = await apiCallService.postMethodCall(url, RequestToSend);

        console.log('response', response);

        var lastName = ''
        var content = ''
        if (employeeDetail.last_name !== undefined) {
            lastName = employeeDetail.last_name
        }
        if (response !== undefined && response.response !== undefined && response.response.response_code !== undefined && response.response.response_code === 0) {
            console.log("Its working without error")
            content = `${employeeDetail.first_name} ${lastName} Activated Successfully.`
            addToast(content, {
                appearance: 'success',
                autoDismiss: true,
            })
            history.push('/removed-employee');
            history.go();
        } else {
            content = `${employeeDetail.first_name} ${lastName} Failed To Activate.`
            addToast(content, {
                appearance: 'error',
                autoDismiss: true,
            })

        }






    }



    var zeroOne = props.mdn.substring(0, 1);
    var sevenNine = props.mdn.substring(8, 10);

    var mystring = `${zeroOne}******${sevenNine}`;
    console.log('props.senderValues.mdn', mystring)

    var totalAmount = parseFloat(generalAmount) + parseFloat(foodAmount) + parseFloat(travelAmount)

    var arrayForGeneralAmount = [];
    var arrayForTravelAmount = [];
    var arrayForFoodAmount = [];

    arrayForGeneralAmount.push(props.maxGeneralAmount / 100);
    arrayForTravelAmount.push(props.maxTravelAmount / 100);
    arrayForFoodAmount.push(props.maxFoodAmount / 100);

    props.corpBand.map(band => {
        if (band.band === employeeDetail.band) {
            arrayForGeneralAmount.push(band.general_Limit / 100);
            arrayForTravelAmount.push(band.travel_Limit / 100);
            arrayForFoodAmount.push(band.wallet_food_limit / 100);
        }
    })
    console.log('arrayForGeneralAmount', arrayForGeneralAmount, 'arrayForTravelAmount', arrayForTravelAmount, 'arrayForFoodAmount', arrayForFoodAmount)

    var i = arrayForGeneralAmount.indexOf(0);
    if (i >= 0) {
        arrayForGeneralAmount.splice(i, 1);
    }

    var j = arrayForTravelAmount.indexOf(0);
    if (j >= 0) {
        arrayForTravelAmount.splice(j, 1);
    }
    var k = arrayForFoodAmount.indexOf(0);
    if (k >= 0) {
        arrayForFoodAmount.splice(k, 1);
    }

    console.log('arrayForGeneralAmount', arrayForGeneralAmount, 'arrayForTravelAmount', arrayForTravelAmount, 'arrayForFoodAmount', arrayForFoodAmount)
    var maxGeneralAmount = Math.min(...arrayForGeneralAmount);
    var maxTravelAmount = Math.min(...arrayForTravelAmount);
    var maxFoodAmount = Math.min(...arrayForFoodAmount);


    var minAm = 1;




    var imageUrl = employeeDetail.imageUrl;


    return (

        <>
            {(props.authenticated)
                ? (

                    <div className="mid-container">
                        <div className="container-fluid">

                            <div className="head">
                                <div className="row">
                                    <div className="col-10">
                                        <h3>Employee Details</h3>
                                    </div>

                                    <div className="col-2">
                                        <div className="actnbtn">
                                            <a href="/removed-employee" data-toggle="tooltip" title="Back" >
                                                <i className="back"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="empdtls">
                                <div className="row">
                                    {
                                        loadingCom
                                            ?
                                            <EmpDetailLoadingComponent />
                                            :
                                            <div className="col-12 col-md-4">

                                                <div className="emplcard">



                                                    <div className="userpro">
                                                        <Avatar src={imageUrl} color={Avatar.getRandomColor(employeeDetail.emp_id, ['#F44336', "#EC407A", '#AB47BC', "#7E57C2", "#5C6BC0", "#42A5F5", "#29B6F6", "#26C6DA", "#26A69A", "#66BB6A", "#9CCC65", "#D4E157", "#FF7043", "#FFEE58", "#BDBDBD"])} value={employeeDetail.emp_id} name={`${CommonFunction.Capitalize(employeeDetail.first_name)} ${CommonFunction.Capitalize(employeeDetail.last_name)}`} size="44px" round={true} />
                                                        <Can
                                                            role={props.userRole}
                                                            perform="emp-detail:editProfilePic"
                                                            roleData={props.roleData}
                                                            yes={() => (
                                                                <button className="editpro" type="button" data-bs-toggle="modal" data-bs-target="#edtpropic"></button>
                                                            )}
                                                            no={() => null}
                                                        />

                                                        <h5 className="text-truncate">{CommonFunction.Capitalize(employeeDetail.first_name)} {CommonFunction.Capitalize(employeeDetail.last_name)}</h5>
                                                        <p>+91-{employeeDetail.mdn}</p>

                                                        <div className="empactn usrinact">



                                                            <Can
                                                                role={props.userRole}
                                                                perform="removedEmp-singleActivate:visit"
                                                                roleData={props.roleData}
                                                                yes={() => (

                                                                    <button className="activate" data-for="back" data-tip="Activate Employee" data-iscapture="true" data-bs-toggle="modal" data-bs-target="#activate">Activate</button>

                                                                )}
                                                                no={() =>
                                                                    null
                                                                }
                                                            />


                                                        </div>
                                                    </div>

                                                    {

                                                        kyc_status === KycStatus.MIN_KYC
                                                            ?
                                                            <div className="kycsts">
                                                                <p className="float-start"> <i className="min"></i> {process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Min_KYC}</p>
                                                                {kycExpireDate === undefined || kycExpireDate === null || kycExpireDate === ""
                                                                    ?
                                                                    null
                                                                    :
                                                                    <p className="float-end"><i className="date"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_KYC_EXPIRED_LABEL} {kycExpireDate}</p>
                                                                }
                                                            </div>
                                                            :
                                                            kyc_status === KycStatus.KYC_DONE
                                                                ?
                                                                <div className="kycsts">
                                                                    <p className="float-start">  <i className="full"></i> {process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Full_KYC}</p>
                                                                    {
                                                                        kycExpireDate === undefined || kycExpireDate === null || kycExpireDate === ""
                                                                            ?
                                                                            null
                                                                            :
                                                                            <p className="float-end"><i className="date"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_KYC_EXPIRED_LABEL} {kycExpireDate}</p>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="kycsts">
                                                                    <p className="float-start">  <i className="none"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_No_KYC}</p>
                                                                    {kycExpireDate === undefined || kycExpireDate === null || kycExpireDate === ""
                                                                        ?
                                                                        null
                                                                        :
                                                                        <p className="float-end"><i className="date"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_KYC_EXPIRED_LABEL} {kycExpireDate}</p>
                                                                    }
                                                                </div>

                                                    }

                                                    <div className="usrprodtl">
                                                        <ul>
                                                            <li className="email">
                                                                <p>Email ID:</p>
                                                                <h5>{employeeDetail.email == null || employeeDetail.email === "" ? <>NA</> : employeeDetail.email}</h5>
                                                            </li>
                                                            <li className="cldr">
                                                                <p>Date of Birth:</p>
                                                                <h5>{employeeDetail.dob == null || employeeDetail.dob === "" ? <>NA</> : employeeDetail.dob}</h5>
                                                            </li>
                                                            <li className="gender">
                                                                <p>Gender:</p>
                                                                <h5>{employeeDetail.gender == null || employeeDetail.gender === "" ? <>NA</> : employeeDetail.gender}</h5>
                                                            </li>
                                                            <li className="doc">
                                                                <p>PAN:</p>
                                                                <h5> {employeeDetail.pan == null || employeeDetail.pan === "" ? <>NA</> : employeeDetail.pan}</h5>
                                                            </li>
                                                            <li className="locat">
                                                                <p>Location:</p>
                                                                <h5>{employeeDetail.address} - {employeeDetail.pincode}</h5>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="profndtls">
                                                        <ul>
                                                            <li>
                                                                <p>Employee ID:</p>
                                                                <h5>{employeeDetail.emp_id == null || employeeDetail.emp_id === "" ? <>NA</> : employeeDetail.emp_id}</h5>
                                                            </li>
                                                            <li>
                                                                <p>Date of Joining:</p>
                                                                <h5>{employeeDetail.date_of_joining == null || employeeDetail.date_of_joining === "" ? <>NA</> : employeeDetail.date_of_joining}</h5>
                                                            </li>
                                                            <li>
                                                                <p>Department:</p>
                                                                <h5>{employeeDetail.department == null || employeeDetail.department === "" ? <>NA</> : employeeDetail.department}</h5>
                                                            </li>
                                                            <li>
                                                                <p>Employee Band:</p>
                                                                <h5>{employeeDetail.band == null || employeeDetail.band === "" ? <>NA</> : employeeDetail.band}</h5>
                                                            </li>
                                                        </ul>
                                                    </div>



                                                    {/* <Can
                                                        role={props.userRole}
                                                        perform="empDetail-page:updateRole"
                                                        roleData={props.roleData}
                                                        yes={() => (
                                                            <div className="roleform">
                                                                <div className="form-floating input-group">
                                                                    <select className="form-select" id="role" onChange={handleRoleChange}>
                                                                        {employeeDetail.roleName === ""
                                                                            ?
                                                                            <option selected>Select Role</option>
                                                                            :
                                                                            null

                                                                        }
                                                                        {props.masterRoleData.map(role => (
                                                                            <AssignRole employeeDetail={employeeDetail} role={role} />

                                                                        ))}
                                                                        {
                                                                            employeeDetail.roleName === ""
                                                                                ?
                                                                                null
                                                                                :
                                                                                <option selected={'Remove Role' === employeeDetail.roleName}>Remove Role</option>
                                                                        }
                                                                    </select>
                                                                    <label for="role">{process.env.React_APP_Var_FOR_Employee_Detail_Page_Assigned_Role}</label>

                                                                    <button type="button" className="btn btnbl" id="uR" onClick={handleUpdateRole} disabled={updateRoleProcessBar}>
                                                                        {
                                                                            updateRoleProcessBar
                                                                                ?
                                                                                <div className="spinner-border" role="status">
                                                                                    <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                                </div>
                                                                                :
                                                                                <>Ok</>
                                                                        }

                                                                    </button>

                                                                </div>
                                                            </div>
                                                        )}
                                                        no={() =>
                                                            null
                                                        }
                                                    /> */}


                                                </div>


                                            </div>


                                    }

                                    <div className="col-12 col-md-8">
                                        <div className="emplcard">
                                            <div className="balance-info">
                                                <h3 className="subhead">Balance Information</h3>

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 col-xl-4">
                                                        <div className="total-balance">
                                                            <i>&#8377;</i>
                                                            <label>Total Available Balance</label>
                                                            <h4>{balanceEnquiry.Balance.total_balance / 100}{console.log('loading..', loading)}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="card-info">
                                                <h3 className="subhead" >

                                                    Card Information</h3>



                                                {

                                                    employeeDetail.card !== undefined && employeeDetail.card.map(cards => (





                                                        parseInt(cards.cardStatus) === CardStatusType.NOT_ASSIGNED

                                                            ?

                                                            <Can
                                                                role={props.userRole}
                                                                perform="emp-detail:assignAvailCard"
                                                                roleData={props.roleData}
                                                                yes={() => (

                                                                    <>
                                                                        <AvailCardDiv employeeDetail={employeeDetail} />
                                                                        <hr></hr>
                                                                    </>


                                                                )}
                                                                no={() => null}
                                                            />




                                                            :



                                                            <>
                                                                <div className="row">
                                                                    {
                                                                        cards.cardType === 1
                                                                            ?
                                                                            <div className="col-12 col-sm-6 col-md-8 col-lg-6 col-xl-5">
                                                                                <div className="type-card">
                                                                                    <i className="phycl"></i>
                                                                                    <p>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Physical_Card_Ref_No}</p>
                                                                                    <h6>{cards.cardReferenceNo}</h6>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="col-12 col-sm-6 col-md-8 col-lg-6 col-xl-5">

                                                                                <div className="type-card">
                                                                                    <i className="vrtl"></i>
                                                                                    <p>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Virtual_Card_Ref_No}</p>
                                                                                    <h6>{cards.cardReferenceNo}</h6>
                                                                                </div>
                                                                            </div>
                                                                    }

                                                                    <CardStatus cardStatus={parseInt(cards.cardStatus)} type='txt' classNameToSet="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-5" cardStatusClassName="type-card-status" />



                                                                    <div className="col-12 col-lg-3 col-xl-2">
                                                                        <div className="managecard-action">
                                                                            {
                                                                                (parseInt(cards.cardStatus) !== CardStatusType.ASSIGNED) &&


                                                                                <Link
                                                                                    // className="mdl-js-button mdl-js-ripple-effect"
                                                                                    to={{
                                                                                        pathname: '/transaction-report',
                                                                                        state: { mdn: employeeDetail },
                                                                                    }}
                                                                                    className="sbtn btnbl"
                                                                                >{process.env.React_APP_Var_FOR_Employee_Detail_Page_Transaction}</Link>

                                                                                // <a href={`/transaction-report?mdn=${(employeeDetail.mdn)}`} className="sbtn btnbl">{process.env.React_APP_Var_FOR_Employee_Detail_Page_Transaction}</a>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <hr></hr>
                                                            </>


                                                    ))

                                                }
                                            </div>


                                            <div className="load-info">
                                                <h3 className="subhead">{process.env.React_APP_Var_FOR_Employee_Detail_Page_LoadLimitsInformation}</h3>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                                        <div className="load-limit">
                                                            <h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_MonthlyLimit}</h5>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.monthly_available_limit / 100}</span>
                                                                }
                                                            </label>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Consumed}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.monthly_consumed_limit / 100}</span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                                        <div className="load-limit">
                                                            <h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Annual_Limit}</h5>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.annual_available_limit / 100}</span>
                                                                }
                                                            </label>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Consumed}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.annual_consumed_limit / 100}</span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                                        <div className="load-limit">
                                                            <h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_AnnualFoodLimit}</h5>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.annual_food_available_limit / 100}</span>
                                                                }
                                                            </label>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Consumed}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.annual_food_consumed_limit / 100}</span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                                        <div className="load-limit">
                                                            <h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_HoldingLimit}</h5>
                                                            <label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
                                                                {loading
                                                                    ?
                                                                    <Skeleton animation="wave" variant="square" />
                                                                    :
                                                                    <span>{balanceEnquiry.Balance.holding_available_limit / 100}</span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="modal fade" id="activate" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                    <div className="modal-dialog modal-sm">
                                                        <div className="modal-content alerttext">
                                                            <div className="modal-header">
                                                                <h5 id="">Activate Employee</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                Are you sure to activate this employee?
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="mbtn btngr" data-bs-dismiss="modal">Close</button>
                                                                <button type="button" className="mbtn btnbl" data-bs-dismiss="modal" onClick={activateEmployee}>Activate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <Redirect to='/' />
                )

            }

        </>
    )
}




const mapStateToProps = ({ band: { bandData }, dropdown: { hidden }, message: { message }, token: { authenticated, token }, kyc: { masterRoleData },
    role: { roleData }, user: { empDetail, roMdn, userRole, currentUser, mdn, fullName, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount }, location: { departmentaList } }) => ({
        hidden,
        message,
        authenticated,
        currentUser, token, empDetail,
        roMdn, mdn,
        maxFoodAmount, maxGeneralAmount, maxTravelAmount,
        bandData, corpBand,
        masterRoleData,
        departmentaList, fullName, userRole, roleData
    });
const mapDispatchToProps = dispatch => ({
    toggleDropDownHidden: (data) => dispatch(toggleDropDownHidden(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetail);