import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { store } from '../../redux/store';
import apiCallService from '../../service/api-call-service';
import moment from 'moment'
import Select from "react-select";
import { resetOder } from "../../redux/order/order.action";
import LoadingComponent from '../../manage-card/component/oder-history/all-orderHistory-loading.coponent';
import ResetLoginPassword from "../../corporate-profile/component/reset-login-password";
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ReactTooltip from "react-tooltip";
import history from '../../auth/history';

const collectedTrueKeys = {

    status: [],
    successStatusVar: []
}
var ToDateVar = '';
var FromDateVar = '';
const CorpOrderHistory = ({ authenticated, isPasswordReset, roleData, userRole, roMdn, location, currentUser }) => {

    const [loading, setLoading] = useState(true);


    const [historyList, setHistoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_ORDER_HISTORY);


    const [roId, setRoId] = useState('');

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [searchField, setSearchField] = useState('');

    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [placeHolder, setPlaceHolder] = useState('...')
    const [multiValueS, setMultiValueS] = useState([]);
    const [multiValueR, setmultiValueR] = useState([]);
    const [multiValueStatus, setMultiValueStatus] = useState([
        { value: "3", label: "Ordered" },
        { value: "4", label: "Payment" },
        { value: "9", label: "Approved" },
        { value: "5", label: "Shipped" },
        { value: "6", label: "Delivered" },

    ])
    const [orderStatusMultiValue, setOrderStatusMultiValue] = useState([
        { value: "1", label: "Open" },
        { value: "2", label: "Closed" },
        { value: "3", label: "Cancelled" },

    ])


    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');


    const [courierCompanyName, setCourierCompanyName] = useState('');
    const [awbNumber, setAwbNumber] = useState('');
    const [shippedDate, setShippedDate] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');




    const [shippedButtonDusable, setShippedButtonDusable] = useState(false);



    useEffect(() => {
        document.title = "ShakePe Cards Order History"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "active",
            adminReportCLass: ""
        }

        store.dispatch(setActiveHeader(payload));
        store.dispatch(resetOder)

        const getData = async () => {


            var roIdTOSet = roMdn;

            if (location.state !== undefined && location.state.roId !== undefined) {
                roIdTOSet = location.state.roId;
            }

            setRoId(roIdTOSet);


            var url = `${process.env.REACT_APP_GET_ALL_CARD_ORDER_HISTORY}`


            var response = await apiCallService.getMethodCallWithoutParam(url);

            console.log('response history transaction only', url, response);

            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null) {


                // response.response.getCardOrder.map(order => {
                //     console.log('response history transaction order', order);
                // })


                setHistoryList(response.response.getCardOrder);
                setFilteredRecord(response.response.getCardOrder);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }


        getData();

    }, []);


    const handleStatuschange = (option) => {

        setMultiValueS(option)
        setCurrentPage(1);
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.status = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.status.push(parseInt(op.value));
            })
        }
    }

    const handleOrderChangechange = (option) => {

        setmultiValueR(option)
        setCurrentPage(1);
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.successStatusVar = [];
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.successStatusVar.push(op.label);
            })
        }
    }


    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)

        const { value, name } = event.target;
        if (name === 'toDate') {
            setToDate(value)

            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value)

            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== '') {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");

            const requestToSend = {
                "fromDate": `${formatFromDate} 00:00:00`,
                "toDate": `${formatToDate} 23:59:59`
            }


            const url = process.env.REACT_APP_GET_ALL_CARD_ORDER_HISTORY_BY_DATERANGE;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('request to send response', response)

            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null) {


                // response.response.getCardOrder.map(order => {
                //     console.log('response history transaction order', order);
                // })
                setFilteredRecord(response.response.getCardOrder)
                setCurrentPage(1);

            } else {
                setFilteredRecord([])

            }

        }
    }

    const clearAll = () => {
        setMultiValueS([])

        setmultiValueR([])

        setFilteredRecord(historyList);

        setToDate('');
        setFromDate('');

        collectedTrueKeys.status = []


        setAllValuesForFilter([])
    }




    const updateCardOrder = async (history, event) => {

        console.log("History", history);
        var url = process.env.REACT_APP_UPDATE_CARD_ORDER;

        var request = {
            "roId": history.roId,
            "orderId": history.orderId,
            "status": 7
        }

        var response = await apiCallService.postMethodCall(url, request);

        console.log("Response Request For Update Card Order", request, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            window.location.reload();
        } else {
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);
        }
    }


    const sendTOAminCardPage = (historyD, event) => {


        history.push({
            pathname: '/admin-manage-card',
            state: { orderId: historyD.orderId, roId: historyD.roId }
        })
        history.go()

    }


    const cancelShipMent = () => {
        setCourierCompanyName('')
        setTrackingUrl('')
        setShippedDate('')
        setAwbNumber('')

    }

    const handleOderShiped = async (historyD, event) => {

        event.preventDefault();
        setShippedButtonDusable(true);



        var url = process.env.REACT_APP_UPDATE_ORDER_TO_SHIPPED


        var request = {
            "roId": historyD.roId,
            "orderId": historyD.orderId,
            "courierCompanyName": courierCompanyName,
            "awbNumber": awbNumber,
            "shippedDate": shippedDate,
            "trackingUrl": trackingUrl
        }

        const response = await apiCallService.postMethodCall(url, request);


        console.log("Response from shipment", response);


        if (response !== undefined && response.response_code != undefined && response.response_code === 0) {
            setShippedButtonDusable(false)
            history.push('/corp-order-history');
            history.go();
        } else {
            setShippedButtonDusable(false)
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);
        }


    }


    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    //Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };

    var filteredPostData = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



    }

    const key = 'orderId';

    const filteredPost = [...new Map(filteredPostData.map(item =>
        [item[key], item])).values()];

    if (filteredPost === null || filteredPost === undefined) {
        currentPost = null
    } else {
        currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
    }

    console.log('currentpost', currentPost);

    console.log("current post", filteredPost);

    var startdateToDate = moment();
    startdateToDate = startdateToDate.format("YYYY-MM-DD");

    if (toDate === '') {
        var startdateFromDate = moment();
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    } else {
        var startdateFromDate = moment(toDate);
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            'border': '1px solid #ddd',
            'borderRadius': '0',
            'boxSizing': 'content-box',
            'minHeight': '30px',
            'lineHeight': '18px',
            'display': 'flex'
        }),

        valueContainer: (base) => ({
            ...base,
            'position': 'relative',
            'overflow': 'hidden',
            'margin': '0',
            'padding': '5px',
            'width': '100%',
            'minHeight': '30px',
            'border': '1px solid #ddd',
            'backgroundColor': '#fff',
            'lineHeight': '18px',
            'cursor': 'text',
            'alignItems': 'center'
        }),
        multiValue: base => ({
            ...base,
            'boxSizing': 'content-box',
            'position': 'relative',
            'margin': '1px 5px 1px 0px',
            'padding': '1px 2px 3px 5px',
            'border': '1px solid #c4d5e1',
            'lineHeight': '18px',
            'maxWidth': '100%',
            'borderRadius': '3px',
            'backgroundColor': '#d6eeff',
            'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
            'color': '#4a546c',
            'lineHeight': '16px',
            'cursor': 'default',
            'height': '20px'
        }),
        menu: (base) => ({
            ...base,
            'padding': '2px 5px',
            'lineHeight': '16px',

        }),
        input: base => ({
            ...base,
            'height': '18px',
            'lineHeight': '18px'
        })

    };


    var dateMax = moment(fromDate);
    dateMax = dateMax.format("YYYY-MM-DD");
    var dateFromMax = moment(toDate);
    dateFromMax = dateFromMax.format("YYYY-MM-DD");
    var height = window.innerHeight - 250

    return (
        <div >
            {(authenticated)
                ? (
                    isPasswordReset ?
                        <div className="mid-container">
                            <div className="container-fluid">



                                <div className="head">
                                    <div className="row">
                                        <div className="col-10">
                                            <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Cards_Order_History}</h3>
                                        </div>
                                        <div className="col-2">
                                            <div className="flterbox">
                                                <div className="dropdown" data-toggle="tooltip" title="Filter">
                                                    <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>
                                                    <div className="dropdown-menu dropdown-menu-right">

                                                        <div className="filters">
                                                            <label htmlFor="select-state">Order Status:</label>
                                                            <Select
                                                                name="filters"
                                                                placeholder={placeHolder}
                                                                value={multiValueR}
                                                                options={orderStatusMultiValue}
                                                                onChange={handleOrderChangechange}
                                                                isMulti={true}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                styles={customStyles}
                                                            />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">Delivery Status:</label>
                                                            <Select
                                                                name="filters"
                                                                placeholder={placeHolder}
                                                                value={multiValueS}
                                                                options={multiValueStatus}
                                                                onChange={handleStatuschange}
                                                                isMulti={true}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                styles={customStyles}
                                                            />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">From Date:</label>
                                                            <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={fromDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">To Date:</label>
                                                            <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={toDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                        </div>

                                                        <div className="fbtn">
                                                            <button className="sbtn btnrd" type="reset" id="btnClear" onClick={clearAll}>Clear all</button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </div>

                                <div className="row">
                                    <Snackbar

                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={dropDownHidden}
                                        autoHideDuration={3000}
                                        onClose={e => setDropDownHidden(false)}
                                    >
                                        <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                            {message}
                                        </Alert>
                                    </Snackbar>
                                    <div className="col-12 ">
                                        <div className="oemscardlist" id="oemscardrs" style={{ "minHeight": height }}>
                                            {
                                                loading
                                                    ?
                                                    <LoadingComponent />
                                                    :

                                                    currentPost.length === 0
                                                        ?
                                                        <div className="col-12 ">
                                                            <div className="no-record">
                                                                <i></i>
                                                                <h3> No Record Available.</h3>
                                                            </div>
                                                        </div>
                                                        :
                                                        currentPost.map(history => (


                                                            <div className="ordercard">
                                                                <div className="odr-basic">
                                                                    <div className="odrbox">
                                                                        <div className="orderby" data-bs-toggle="collapse" href={`#odr${history.orderId}`}>
                                                                            <i className="truck"></i>
                                                                            <h5>{history.roName}</h5>
                                                                            <h6>Order By: {history.fullName}</h6>
                                                                            <p>#{history.orderId} - {history.dateCreatedOn}</p>
                                                                        </div>

                                                                        <div className="orderqty">
                                                                            <p>{history.cardType === 0 ? <>Virtual Card Quantity</> : <>Physical Card Quantity</>}</p>
                                                                            <h4>{history.cardQuantity}</h4>
                                                                        </div>

                                                                        <div className="ordertraker">

                                                                            {history.responseMessage === undefined || history.responseMessage === null || history.responseMessage === ""
                                                                                ?
                                                                                <div className="order-track" data-for="back" data-tip={history.responseMessage} data-iscapture="true">
                                                                                    <div className="row g-0">
                                                                                        <div className="col-12">
                                                                                            <h6>Order Status <span className={history.successStatusClass}>{history.successStatusVar}</span></h6>

                                                                                        </div>

                                                                                        <div className="col">
                                                                                            <div className={history.orderedClass}>
                                                                                                <i className="dot"></i>
                                                                                                <i className="line"></i>
                                                                                                <p>Ordered</p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col">
                                                                                            <div className={history.paymentClass}>
                                                                                                <i className="dot"></i>
                                                                                                <i className="line"></i>
                                                                                                <p>Payment</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col">
                                                                                            <div className={history.approvalClass}>
                                                                                                <i className="dot"></i>
                                                                                                <i className="line"></i>
                                                                                                <p>Order Approval</p>
                                                                                            </div>
                                                                                        </div>



                                                                                        {
                                                                                            history.cardType === 0
                                                                                                ?
                                                                                                null
                                                                                                :
                                                                                                <>

                                                                                                    <div className="col">
                                                                                                        <div className={history.dispatchClass}>
                                                                                                            <i className="dot"></i>
                                                                                                            <i className="line"></i>
                                                                                                            <p>Shipped</p>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                </>
                                                                                        }


                                                                                        {
                                                                                            history.cardType === 0
                                                                                                ?
                                                                                                <div className="col">
                                                                                                    <div className={history.deliveredCLass}>
                                                                                                        <i className="dot"></i>
                                                                                                        <i className="line"></i>
                                                                                                        <p>Order Completed</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <>

                                                                                                    <div className="col">
                                                                                                        <div className={history.deliveredCLass}>
                                                                                                            <i className="dot"></i>
                                                                                                            <i className="line"></i>
                                                                                                            <p>Delivered</p>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                </>
                                                                                        }

                                                                                    </div>
                                                                                </div>

                                                                                :

                                                                                <>
                                                                                    <ReactTooltip
                                                                                        id="back"
                                                                                        place="top"
                                                                                        type='dark'
                                                                                        effect='solid'
                                                                                        // multiline={true}
                                                                                        backgroundColor="black"
                                                                                    />
                                                                                    <div className="order-track" data-for="back" data-tip={history.responseMessage} data-iscapture="true">
                                                                                        <div className="row g-0">
                                                                                            <div className="col-12">
                                                                                                <h6>Order Status <span className={history.successStatusClass}>{history.successStatusVar}</span></h6>

                                                                                            </div>

                                                                                            <div className="col">
                                                                                                <div className={history.orderedClass}>
                                                                                                    <i className="dot"></i>
                                                                                                    <i className="line"></i>
                                                                                                    <p>Ordered</p>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col">
                                                                                                <div className={history.paymentClass}>
                                                                                                    <i className="dot"></i>
                                                                                                    <i className="line"></i>
                                                                                                    <p>Payment</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <div className={history.approvalClass}>
                                                                                                    <i className="dot"></i>
                                                                                                    <i className="line"></i>
                                                                                                    <p>Order Approval</p>
                                                                                                </div>
                                                                                            </div>



                                                                                            {
                                                                                                history.cardType === 0
                                                                                                    ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>

                                                                                                        <div className="col">
                                                                                                            <div className={history.dispatchClass}>
                                                                                                                <i className="dot"></i>
                                                                                                                <i className="line"></i>
                                                                                                                <p>Shipped</p>
                                                                                                            </div>
                                                                                                        </div>



                                                                                                    </>
                                                                                            }


                                                                                            {
                                                                                                history.cardType === 0
                                                                                                    ?
                                                                                                    <div className="col">
                                                                                                        <div className={history.deliveredCLass}>
                                                                                                            <i className="dot"></i>
                                                                                                            <i className="line"></i>
                                                                                                            <p>Order Completed</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <>

                                                                                                        <div className="col">
                                                                                                            <div className={history.deliveredCLass}>
                                                                                                                <i className="dot"></i>
                                                                                                                <i className="line"></i>
                                                                                                                <p>Delivered</p>
                                                                                                            </div>
                                                                                                        </div>



                                                                                                    </>
                                                                                            }


                                                                                            {/* </ul> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </>


                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={`odr${history.orderId}`} className="collapse" data-bs-parent="#oemscardrs">
                                                                    <div className="orderaddress">


                                                                        {history.cardType === 0
                                                                            ?
                                                                            null
                                                                            :
                                                                            <div className="shipadrs">
                                                                                <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Shipping_Address}</h5>
                                                                                <h6>{history.shippingPersonName}</h6>
                                                                                <p>{history.shippingAddress}</p>
                                                                                <p><i className="mobile"></i>+91-{history.shippingPersonMdn}</p>
                                                                            </div>
                                                                        }

                                                                        <div className="billadrs">
                                                                            <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Billing_Address} {history.bankInvoiceurl === '' || history.bankInvoiceurl === null ? null : <a href={history.bankInvoiceurl} target="blank" className="invoice">Invoice</a>}</h5>
                                                                            <h6>{history.fullName}</h6>
                                                                            <p>{history.billingAddress}</p>
                                                                            <p><i className="mobile"></i>+91-{history.mobile}</p>
                                                                        </div>


                                                                    </div>

                                                                    {
                                                                        history.shippedCardDetail === undefined && history.shippedCardDetail === null && history.shippedCardDetail.length !== 0 && history.approvedCardDetails === undefined && history.approvedCardDetails === null && history.approvedCardDetails.length === 0
                                                                            ?
                                                                            null :
                                                                            <div className="cardordtls">
                                                                                <div className="row">



                                                                                    {
                                                                                        history.approvedCardDetails !== undefined && history.approvedCardDetails !== null && history.approvedCardDetails.length !== 0
                                                                                            ?
                                                                                            <div className="col-12 col-md-8">
                                                                                                <div className="cardsrlinfo">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12"><h5>Assigned Cards Details</h5></div>

                                                                                                        {history.approvedCardDetails.map(data => (
                                                                                                            <>
                                                                                                                <div className="col-12 col-sm-4"><h6><span>Serial number from:</span><br /> {data.startRange}</h6></div>
                                                                                                                <div className="col-12 col-sm-4"><h6><span>Serial number to:</span><br /> {data.endRange}</h6></div>
                                                                                                                <div className="col-12 col-sm-4"><h6><span>Quantity:</span><br /> {data.quantity}</h6></div>
                                                                                                            </>
                                                                                                        ))

                                                                                                        }



                                                                                                        <div className="col-8 col-sm-4 offset-sm-4"><h4 className="text-right"><span>Total Card Quantity: =</span></h4></div>
                                                                                                        <div className="col-4"><h4 >{history.cardQuantity}</h4></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                    {history.shippedCardDetail !== undefined && history.shippedCardDetail !== null
                                                                                        ?
                                                                                        <div className="col-12 col-md-4">
                                                                                            <div className="cardshipdtls">
                                                                                                <h5>Courier Shipping Details</h5>
                                                                                                <p>Name: {history.shippedCardDetail.courierCompanyName}</p>
                                                                                                <p>AWB Number: {history.shippedCardDetail.awbNumber}</p>
                                                                                                <p>Shipped Date: {history.shippedCardDetail.shippedDate}</p>
                                                                                                {history.shippedCardDetail.deliveredDate !== undefined && history.shippedCardDetail.deliveredDate !== null
                                                                                                    ? <p>Delivered Date: {history.shippedCardDetail.deliveredDate}</p>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                <p>Track Shipment: <a href={history.shippedCardDetail.trackingUrl} target="_blank">Click here</a></p>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null}
                                                                                </div>
                                                                            </div>

                                                                    }
                                                                    <div className="ordbtn">
                                                                        {history.status === 6 || history.status === 5 || history.status === 8 || history.status === 7 || history.status === 9
                                                                            ?
                                                                            null
                                                                            :

                                                                            <button className="sbtn btnrd" id={`ordbtn${history.orderId}`} data-bs-toggle="modal" data-bs-target={`#odrcnl${history.orderId}`}>{process.env.REACT_APP_GET_ALL_CARD_ORDER_HISTORY_Order_Cancel_button_txt}</button>

                                                                        }
                                                                        {history.status === 4
                                                                            ?

                                                                            <button className="sbtn btnbl" onClick={e => sendTOAminCardPage(history, e)}>Assign card</button>

                                                                            : null
                                                                        }
                                                                        {history.status === 9
                                                                            ?

                                                                            <button className="sbtn btngn" data-bs-toggle="modal" data-bs-target={`#ship${history.orderId}`}>Shipped Details</button>

                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="modal fade" id={`ship${history.orderId}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                                    <div className="modal-dialog ">
                                                                        <div className="modal-content alerttext">

                                                                            <div className="modal-header">
                                                                                <h5 id="">Courier Shipping Details</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={cancelShipMent} aria-label="Close"></button>
                                                                            </div>

                                                                            <div className="modal-body shipform">
                                                                                <form onSubmit={e => handleOderShiped(history, e)}>
                                                                                    <label>Courier Company Name:</label>
                                                                                    <input type="text" placeholder="Enter name" value={courierCompanyName} onChange={e => setCourierCompanyName(e.target.value)} className="form-control" required />

                                                                                    <label>AWB Number:</label>
                                                                                    <input type="text" placeholder="Enter AWB no." value={awbNumber} onChange={e => setAwbNumber(e.target.value)} className="form-control" required />

                                                                                    <label>Shipped Date:</label>
                                                                                    <input type="date" placeholder="DD/MM/YYYY" min={moment().format('YYYY-MM-DD')} value={shippedDate} onChange={e => setShippedDate(e.target.value)} className="form-control" required />

                                                                                    <label>Tracking URL:</label>
                                                                                    <input type="text" placeholder="Enter URL" value={trackingUrl} onChange={e => setTrackingUrl(e.target.value)} className="form-control" required />

                                                                                    <button type="button" className="mbtn btngr" onClick={cancelShipMent} data-bs-dismiss="modal">Cancel</button>
                                                                                    <button type="submit" className="mbtn btnbl" disabled={shippedButtonDusable}>
                                                                                        {shippedButtonDusable
                                                                                            ?
                                                                                            <div className="spinner-border" role="status">
                                                                                                <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                                            </div>
                                                                                            :
                                                                                            <> Submit</>
                                                                                        }</button>
                                                                                </form>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="modal fade" id={`odrcnl${history.orderId}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                                    <div className="modal-dialog modal-sm">
                                                                        <div className="modal-content alerttext">
                                                                            <div className="modal-header">
                                                                                <h5 id="">Cancel Order</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                                                            </div>
                                                                            <div className="modal-body">
                                                                                Are you sure you want to cancel this order?</div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="mbtn btngr" data-bs-dismiss="modal">No</button>
                                                                                <button type="button" className="mbtn btnbl" onClick={e => updateCardOrder(history, e)}>Yes</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        ))
                                            }
                                        </div>
                                    </div>

                                    {parseInt(filteredPost.length) > parseInt(postsPerPage)
                                        ?
                                        <>
                                            <div className="col-12 col-sm-6">
                                                <p> Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="pager">
                                                    <Pagination
                                                        activePage={parseInt(currentPage)}
                                                        itemsCountPerPage={parseInt(postsPerPage)}
                                                        totalItemsCount={filteredPost.length}
                                                        pageRangeDisplayed={parseInt(postsPerPage)}
                                                        onChange={paginate}
                                                        className="pagination"
                                                        linkClass="page-link"
                                                        itemClass="page-item"
                                                        nextPageText="Next"
                                                        prevPageText="Prev"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, currentUser }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, isPasswordReset, currentUser
});

export default connect(mapStateToProps)(CorpOrderHistory);
