import { store, persistor } from '../src/redux/store'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
// import { IntlProvider } from "react-intl";
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications'
import { SnackbarProvider, useSnackbar } from 'notistack';
// import ReactEncrypt from 'react-encrypt'
// import 'bootstrap/dist/css/bootstrap-theme.css';\
import App from './App';
import ReactDOM from 'react-dom';
//import 'react-mdl/extra/material.css';
//import 'react-mdl/extra/material.js';
// import '../node_modules/text-security/text-security.css'

if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}

const encryptKey = "ewfWE@#%$rfdsefgdsf";


const rootElement = document.getElementById('root');
ReactDOM.render(
  // <IntlProvider locale='en'>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <ToastProvider>
            <SnackbarProvider maxSnack={3}>


              {/* <ReactEncrypt
                encryptKey={encryptKey}
              > */}

                <App />


              {/* </ReactEncrypt> */}


            </SnackbarProvider>
          </ToastProvider>
        </PersistGate>
      </BrowserRouter>

    </Provider>
  // </IntlProvider>
  , rootElement);
