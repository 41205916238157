import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { renderTransactionHistoryAgainAction, showAddNewOderSection, showAvailableCardSection, setFromDashbard } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import Can from '../../auth/can/Can';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import { resetOder } from '../../redux/order/order.action';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import Avatar from 'react-avatar';
import { resetRole } from '../../redux/role/role.action';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import LoadingCoponent from '../../manage-card/component/oder-history/order-history.loading.component';
import ReactTooltip from 'react-tooltip';



const ManageCard = ({ authenticated, isPasswordReset, roMdn, roleData, userRole, showAvailableCardSectionFun, showAddNewOderSectionFun, fromDashboard,
    renderTransactionHistory, showAvailableCardSection, showCreateNewOderSection, showReviewOderSection, showDetailOderSection, currentUser, location }) => {


    const [loading, setLoading] = useState(true);
    const [loadingOrder, setLoadingOrder] = useState(true);
    const [cadrDetail, setCardDetail] = useState([]);
    // const [sizeHistory, setSizeHistory] = useState(0);
    const [searchVar, setSearchVar] = useState('');

    const [cardQuantity, setCardQuantity] = useState('');

    const [corpDetail, setCorpDetail] = useState([]);

    const [selectedCorp, setSelectedCorp] = useState('');

    const [orderId, setOrderId] = useState('');

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');


    const [rangeArray, setRangeArray] = useState([]);


    // const [fromRange, setFromRange] = useState('');
    // const [toRange, setToRange] = useState('');
    const [cardAssignCount, setCardAssignCount] = useState(0);

    const [buttonDisable, setButtonDisable] = useState(true);


    const [poupShow, setPopupShow] = useState(true);

    const [cardNotAvailMsg, setCardNotAvailMsg] = useState('');
    const [tableDetail, setTableDetail] = useState([]);

    const [orderHistory, setOrderHistory] = useState([]);


    const [value, setValue] = useState('');

    const [comparisionStatus, setComparisionStatus] = useState(false);

    const [comparisionMessage, setComparisionMessage] = useState(false);


    const [cardType, setCardType] = useState(0);


    const [apiLoader, setApiLoader] = useState(false);

    const [fontSize, setFontSize] = useState("20px")


    useEffect(() => {
        document.title = "ShakePe Manage Cards"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "active",
            adminReportCLass: ""
        }

        store.dispatch(setActiveHeader(payload));
        store.dispatch(showAvailableCardSectionFun());
        store.dispatch(renderTransactionHistoryAgainAction(true));
        store.dispatch(resetOder)

        const url = `${process.env.REACT_APP_GET_COUNTWISE_CARD_DETAIL}`;

        const getResult = async () => {
            var response = await apiCallService.getMethodCallWithoutParam(url);
            console.log('ManageCard response ', response)
            if (response !== undefined && response.status !== undefined && response.status === 0) {
                setCardDetail(response.response);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
        getResult();

        if (fromDashboard) {
            store.dispatch(showAddNewOderSection());
            store.dispatch(setFromDashbard(false));
        }
        getCorpDetail();

        getOrderHistory();


        console.log("location of card", location.state)

        if (location.state && location.state.orderId !== undefined && location.state.orderId !== null && location.state.orderId !== ""
            && location.state.roId !== undefined && location.state.roId !== null && location.state.roId !== ""
        ) {
            getOrderDetailForOderId(location.state.orderId, "");
            fetchDetailRO(location.state.roId)

        }

    }, []);




    const getOrderDetailForOderId = async (value, event) => {

        if (value === undefined) { } else {



            var url = `${process.env.REACT_APP_GET_ORDER_HISTORY_BY_ORDERID}?orderId=${value}`;

            var response = await apiCallService.getMethodCallWithoutParam(url);


            if (response !== null && response.status !== undefined && response.status === 0 && response.getCardOrder !== undefined) {




                console.log('record', value)
                setCardQuantity(response.getCardOrder.cardQuantity);

                setOrderId(response.getCardOrder.orderId)
                setCardType(response.getCardOrder.cardType);
                var array = [];

                var dd = {
                    "startRange": "",
                    "endRange": "",
                    "assignedCard": 0,
                    "indexValue": 0,
                    "cardType": response.getCardOrder.cardType
                }

                array.push(dd);

                setRangeArray(array)




            }
            else {
                setSearchVar('')
                setDropDownHidden(true);
                setSeverty('error');
                setMessage(response.response_description);
                setSelectedCorp('')
            }
        }
    }


    const fetchDetailRO = async (roId) => {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        const res = await apiCallService.getMethodCallWithParam(url, { 'roId': roId });

        console.log('CorporateProfile', res)

        if (res !== undefined && res.response_code !== undefined && res.response_code === 0 && res.response !== undefined) {
            setSelectedCorp(res.response)
            setValue(res.response.name)
            setSearchVar('')


        }

    }





    const getOrderHistory = async () => {
        setLoadingOrder(true)
        const url = process.env.REACT_APP_GET_ALL_CARD_ORDER_HISTORY;

        const response = await apiCallService.getMethodCallWithoutParam(url);

        console.log(response);
        if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null) {

            var dt = 0;

            response.response.getCardOrder.map(order => {
                if (dt === 0) {
                    setOrderHistory(order);
                    dt++;
                }
            })
            setLoadingOrder(false);

        } else {
            setLoadingOrder(false);
            setOrderHistory({});
        }
    }


    const getCorpDetail = async () => {

        var url = process.env.REACT_APP_GET_ALL_CORPORATE_LIST;
        var request = {

        }

        var result = await apiCallService.postMethodCall(url, request);


        console.log('result', result);


        if (result !== undefined && result.response_code !== undefined && result.response_code === 0 && result.response !== undefined) {
            setCorpDetail(result.response);
            setLoading(false)
        } else {
            setLoading(false)
        }


    }



    const handleRoute = (e) => {
        history.push(e.target.id);
        history.go();
    }


    const changeSearchVar = (event) => {
        setSearchVar(event.target.value);
        setValue("")
    }

    const handleSelecteCoporate = async (value, event) => {
        setValue(value.name)
        if (value === undefined) { } else {



            var url = `${process.env.REACT_APP_GET_LATEST_ORDER_FOR_CORPORATE}?roId=${value.roId}`;

            var response = await apiCallService.getMethodCallWithoutParam(url);


            if (response !== null && response.status !== undefined && response.status === 0 && response.response !== undefined) {



                // const urlgetFirstAvailCard = process.env.REACT_APP_GET_FIRST_AVAILABLE_CARD;

                // var getFirstAvailCardResponse = await apiCallService.getMethodCallWithoutParam(urlgetFirstAvailCard);

                // if (getFirstAvailCardResponse !== undefined && getFirstAvailCardResponse.status !== undefined && getFirstAvailCardResponse.status === 0) {

                setSelectedCorp(value)

                setSearchVar('')
                // setEmpBlock(true);
                console.log('record', value)
                setCardQuantity(response.response.cardQuantity);
                setCardType(response.response.cardType);
                // setFromRange(getFirstAvailCardResponse.response.card_seial_no)
                setOrderId(response.response.orderId)
                var array = [];

                var dd = {
                    "startRange": "",
                    "endRange": "",
                    "assignedCard": 0,
                    "indexValue": 0,
                    "cardType": cardType
                }

                array.push(dd);

                setRangeArray(array)




            }
            // else {
            //     setSelectedCorp(value)
            //     // setSearchVar('')
            //     console.log('record', value)
            //     setCardQuantity(response.response.cardQuantity);
            // }



            // } 
            else {
                setSearchVar('')
                setDropDownHidden(true);
                setSeverty('error');
                setMessage(response.response_description);
                setSelectedCorp('')
            }



        }
    }



    const checkCardAvailability = async (value, event) => {

        console.log("value aa rhi h?", value)



        // Check start range duplicat
        var findDuplicateStartRange = rangeArray.map(function (item) { return item.startRange });

        var isDuplicateStartRange = findDuplicateStartRange.some(function (item, idx) {
            return findDuplicateStartRange.indexOf(item) != idx
        });


        console.log("Duplicate exist", isDuplicateStartRange)

        if (isDuplicateStartRange) {
            // setDropDownHidden(true);
            // setSeverty('error');
            // setMessage("Duplicate Start range");
            setComparisionStatus(true)
            setComparisionMessage(process.env.React_APP_Message_FOR_Duplicate_Start_range)
            setButtonDisable(true)
            setCardNotAvailMsg(process.env.React_APP_Message_FOR_Duplicate_Start_range)
            setTableDetail([])
        } else {

            // Check end range duplicat
            var findDuplicateEndRange = rangeArray.map(function (item) { return item.endRange });

            var isDuplicateEndRange = findDuplicateEndRange.some(function (item, idx) {
                return findDuplicateEndRange.indexOf(item) != idx
            });


            console.log("Duplicate exist", isDuplicateEndRange)

            if (isDuplicateEndRange) {
                // setDropDownHidden(true);
                // setSeverty('error');
                // setMessage("Duplicate End range");
                setComparisionStatus(true)
                setComparisionMessage(process.env.React_APP_Message_FOR_Duplicate_End_range)
                setButtonDisable(true)
                setCardNotAvailMsg(process.env.React_APP_Message_FOR_Duplicate_End_range)
                setTableDetail([])

            }
            else {


                var length = value.startRange.length;
                var startvalue = value.startRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER), length);
                var endvalue = value.endRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER), length);

                if (parseInt(cardType) === 0) {
                    startvalue = value.startRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER_FOR_VIRTUAL), length);
                    endvalue = value.endRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER_FOR_VIRTUAL), length);
                    setButtonDisable(true)
                }




                if (value.startRange !== '' && value.endRange !== '' && parseInt(startvalue) > parseInt(endvalue)) {

                    // setDropDownHidden(true);
                    // setSeverty('error');
                    // setMessage(process.env.React_APP_Message_FOR_CARD_SERIAL_NUM_COMPARISION);
                    setComparisionStatus(true)
                    setComparisionMessage(process.env.React_APP_Message_FOR_CARD_SERIAL_NUM_COMPARISION)
                    setButtonDisable(true)

                    setCardNotAvailMsg(process.env.React_APP_Message_FOR_CARD_SERIAL_NUM_COMPARISION)
                    setTableDetail([])


                    // setTimeout(function(){ console.log("Time out for 3 secds") }, 3000);

                }
                else

                    if (value.startRange !== '' && value.endRange !== '') {

                        var url = process.env.REACT_APP_CHECK_CARD_AVAILABILITY;

                        var request = {
                            "startRange": value.startRange,
                            "endRange": value.endRange,
                            "assignedCard": 0,
                            "cardType": cardType
                        }

                        var response = await apiCallService.postMethodCall(url, request);
                        setPopupShow(false)
                        console.log("fromRange === toRange outside", response, request)
                        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
                            setButtonDisable(false)
                            setPopupShow(false)
                            console.log("fromRange === toRange ", value.startRange, value.endRange)
                            // if (fromRange === toRange) {
                            //     console.log("fromRange === toRange")
                            //     setCardAssignCount(1)
                            // } else {
                            console.log("fromRange === toRange else", response, value.startRange, value.endRange)
                            setCardAssignCount(value.endRange - value.startRange + 1);
                            var array = [];
                            rangeArray.map((val, index) => {
                                if (val.startRange === value.startRange) {
                                    var length = val.startRange.length;
                                    var start = val.startRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER), length);
                                    var end = val.endRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER), length);
                                    if (parseInt(cardType) === 0) {
                                        start = value.startRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER_FOR_VIRTUAL), length);
                                        end = value.endRange.substring(parseInt(process.env.REACT_APP_CHAR_IN_CARD_REFERNECE_NUMBER_FOR_VIRTUAL), length);
                                    }
                                    console.log("val.startRange.length", val.startRange.length, " start ", start, " end ", end)

                                    val.assignedCard = end - start + 1
                                    // val.indexValue = index + 1
                                    array.push(val)
                                } else {
                                    // val.indexValue = index + 1
                                    array.push(val)
                                }
                            })

                            setRangeArray(array);
                            setButtonDisable(false)
                            setCardNotAvailMsg(process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_Number_Of_Cards_Must_Be_Same_As_Ordered)
                            setTableDetail(response.cardDetail)
                            setFontSize("15px")
                            // }

                        } else if (response !== undefined && response.response_code !== undefined && response.response_code === 2) {
                            setButtonDisable(true)
                            console.log(response)
                            setCardNotAvailMsg(response.response_description)
                            setTableDetail(response.cardDetail)
                            setFontSize("20px")
                            document.getElementById("openPopup").click();
                        } else {
                            setFontSize("20px")
                            setDropDownHidden(true);
                            setSeverty('error');
                            setMessage(response.response_description);
                        }


                    }

            }

        }
    }


    const handleInputChange = (event) => {
        setComparisionStatus(false)
        var array = [];
        rangeArray.map((val, index) => {
            if (parseInt(val.indexValue) === parseInt(event.target.id)) {
                val.endRange = event.target.value
                // val.indexValue = index + 1
                array.push(val)

            } else {
                // val.indexValue = index + 1
                array.push(val)
            }
        })

        setRangeArray(array);

    }

    const handleInputChangeStartRange = (event) => {

        setComparisionStatus(false)
        var array = [];
        rangeArray.map((val, index) => {

            if (parseInt(val.indexValue) === parseInt(event.target.id)) {
                val.startRange = event.target.value
                // val.indexValue = index + 1
                array.push(val)
            } else {
                // val.indexValue = index + 1
                array.push(val)
            }
        })

        setRangeArray(array);

    }


    const addRangeArray = (event) => {
        var array = [];
        rangeArray.map(val => {
            array.push(val)
        })

        var detail = {
            "startRange": "",
            "endRange": "",
            "assignedCard": 0,
            "indexValue": rangeArray.length,
            "cardType": cardType
        }
        array.push(detail)
        setRangeArray(array);
    }

    const removeRangeArray = (event) => {
        var array = [];
        console.log('event.target.id', event.target, event.target.id)
        if (rangeArray !== null && rangeArray.length === 1) {

        } else {
            rangeArray.map((val, index) => {
                console.log('val.startRange', val.indexValue)
                if (parseInt(event.target.id) === val.indexValue) {

                } else {

                    array.push(val)
                }
            })
            array.map((dd, index) => {
                dd.indexValue = index
            })
            setRangeArray(array);
        }


    }


    const assignCardToCorp = async (e) => {

        e.preventDefault();

        setApiLoader(true);

        var url = process.env.REACT_APP_SAVE_ALLOCATE_CARD_REQUEST

        var request = {
            "roId": selectedCorp.roId,
            "allocatedBy": currentUser,
            "orderId": orderId,
            "request": rangeArray,
            "cardType": cardType
        }

        const response = await apiCallService.postMethodCall(url, request);

        console.log(response, JSON.stringify(request));
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setDropDownHidden(true);
            setSeverty('success');
            setMessage(response.response_description);
            history.push('/admin-manage-card')
            history.go();
            setApiLoader(false);
        } else {
            setApiLoader(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);
        }
    }







    var searchedRecord = [];


    if (searchVar !== '' && corpDetail !== undefined) {
        corpDetail.map(post => {
            if (post.name !== undefined && post.name !== null && post.name.toLowerCase().includes(searchVar.toLowerCase())) {
                searchedRecord.push(post)
            } else if (post.roId !== undefined && post.roId !== null && post.roId.includes(searchVar)) {
                searchedRecord.push(post)
            }
        })
    }



    console.log("Array of Range Jhakas", rangeArray);


    var totalassignedCard = 0;

    var showMessage = false
    rangeArray.map(ty => {
        totalassignedCard = totalassignedCard + ty.assignedCard
        showMessage = true
    })


    return (<div >
        {(authenticated)
            ? (
                isPasswordReset
                    ?
                    <Can
                        role={userRole}
                        perform="subheader-adminmanageCard:visit"
                        roleData={roleData}
                        yes={() => (
                            <div className="mid-container">
                                <Snackbar

                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => setDropDownHidden(false)}
                                >
                                    <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                        {message}
                                    </Alert>
                                </Snackbar>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-8">


                                            <div className="row">
                                                <div className="col-12 col-lg-5">
                                                    <div className="total-card">
                                                        <div className="card-avail">

                                                            <i className="ttl"></i>
                                                            <p>{process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_Total_Cards_Ordered} </p>
                                                            <h5>{cadrDetail.totalCount}</h5>
                                                        </div>

                                                        <div className="cardsavail">
                                                            <i className="phy"></i>
                                                            <p>Physical Cards</p>
                                                            <h5>{cadrDetail.totalPhysicalCount}</h5>
                                                        </div>

                                                        <div className="cardsavail plus">
                                                            <i className="vir"></i>
                                                            <p>Virtual Cards</p>
                                                            <h5>{cadrDetail.totalVirtualCount}</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-7">
                                                    <div className="total-card">
                                                        <div className="card-avail">

                                                            <i className="avl"></i>
                                                            <a href="corp-order-history" className="sbtn btngn">View</a>
                                                            <p>{process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_Assigned_Cards}</p>
                                                            <h5 className="assign">{cadrDetail.assignedToCorpCount}</h5>
                                                        </div>
                                                        <div className="cardswith pr-sm-3">

                                                            <p>{process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_In_stock_VIRTUAL}</p>
                                                            <h5 className="avail">{cadrDetail.inStockVirtualCardCount}</h5>
                                                        </div>
                                                        <div className="cardswith card-last pl-sm-3">

                                                            <p>{process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_In_stock_PHYSICAL}</p>
                                                            <h5 className="avail">{cadrDetail.inStockPhysicalCardCount}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="cardorderform">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>{process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_Assign_Card_to_Corporate}</h3>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        {/* <div className="searchcorp"> */}
                                                        <div className="webform">
                                                            <div className="form-floating">

                                                                <input className='form-control' type="text" placeholder={"Search Corporate..."} id="searchemp" value={value === "" ? searchVar : value} onChange={changeSearchVar} />
                                                                <label for="searchemp">Select Corporate</label>
                                                                {searchVar === ''
                                                                    ?
                                                                    null
                                                                    :
                                                                    <div className="empsearchlist" id="showemp">
                                                                        {searchedRecord.map(corp => (
                                                                            <div className="emprofile" id={corp.roId} onClick={e => handleSelecteCoporate(corp, e)}>
                                                                                <Avatar id={corp.roId} src={corp.logoUrl} onClick={e => handleSelecteCoporate(corp, e)} color={Avatar.getRandomColor(corp.roId, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={corp.roId} name={corp.name} size="48px" round={true} />


                                                                                <h4 id={corp.roId} name={corp.roId} >{corp.name}</h4>
                                                                                <p id={corp.roId} name={corp.roId} >RO ID: {corp.roId}</p>
                                                                            </div>
                                                                        ))

                                                                        }

                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        {/* </div> */}
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        {/* <div className="searchcorp"> */}
                                                        <div className="webform">
                                                            <div className="form-floating">
                                                                <input className='form-control' type="text" id="carQty" placeholder="No Quantity" value={cardQuantity} disabled />
                                                                <label for="carQty">Assign Cards</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {selectedCorp === ''
                                                        ?
                                                        null
                                                        :
                                                        <>
                                                            <div className="col-12">
                                                                <div className="empdetails">
                                                                    <div className="row">
                                                                        <div className="col-12 col-lg-6">
                                                                            <div className="empview">
                                                                                <Avatar id={selectedCorp.roId} src={selectedCorp.logoUrl} color={Avatar.getRandomColor(selectedCorp.roId, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={selectedCorp.roId} name={selectedCorp.name} size="48px" round={true} />
                                                                                <h4>{selectedCorp.name}</h4>
                                                                                <p>RO ID: {selectedCorp.roId}</p>
                                                                            </div>

                                                                        </div>

                                                                        <div className="col-12 col-lg-6">
                                                                            <div className="empview">
                                                                                <p>Contact Person: {selectedCorp.contact_person}</p>
                                                                                <p>Email: {selectedCorp.contact_email}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <div className="empview">
                                                                                <p><strong>Address</strong>: {selectedCorp.address}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {rangeArray.map(value => (
                                                                <div className="col-12">
                                                                    <div className="cardserial">
                                                                        <div className="row">

                                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                                                <div className="webform">
                                                                                    <div className="form-floating">
                                                                                        <input className="form-control" type="text" id={value.indexValue} value={value.startRange} onChange={handleInputChangeStartRange} onBlur={(event) => checkCardAvailability(value, event)} />
                                                                                        <label for={value.indexValue}>{cardType === 1 ? <>From Physical Card Serial no.</> : <>From Virtual Card Serial no.</>}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                                                                <div className="webform">
                                                                                    <div className="form-floating">
                                                                                        <input className="form-control" type="text" id={value.indexValue} value={value.endRange} onChange={handleInputChange} onBlur={(event) => checkCardAvailability(value, event)} />
                                                                                        <label for={value.indexValue}>{cardType === 1 ? <>To Physical Card Serial no.</> : <>To Virtual Card Serial no.</>}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-lg-4">
                                                                                <div className="cardcount">
                                                                                    <p>{cardType === 1 ? <>Physical Card Quantity</> : <>Virtual Card Quantity</>}</p>
                                                                                    <h4>{value.assignedCard}</h4>

                                                                                    <div className="cardcountbtn">
                                                                                        <ReactTooltip
                                                                                            id="backClose"
                                                                                            place="top"
                                                                                            type='dark'
                                                                                            effect='solid'
                                                                                            // multiline={true}
                                                                                            backgroundColor="black"
                                                                                        />
                                                                                        <button className="mdl-js-button mdl-js-ripple-effect" data-for="backClose" data-tip="Add" data-iscapture="true" onClick={addRangeArray}><i className="add">Add</i></button>
                                                                                        {
                                                                                            rangeArray !== null && rangeArray.length === 1
                                                                                                ?
                                                                                                null
                                                                                                :
                                                                                                <button className="mdl-js-button mdl-js-ripple-effect" id={value.indexValue} data-for="backClose" data-tip="Remove" data-iscapture="true" onClick={removeRangeArray}><i id={value.indexValue} className="remove">Remove</i></button>

                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))

                                                            }


                                                            {
                                                                comparisionStatus
                                                                    ?
                                                                    <div className="col-12 mt-4" style={{ color: "red" }}>

                                                                        {comparisionMessage}

                                                                    </div>
                                                                    :
                                                                    null
                                                            }



                                                            <div className="col-12 mt-4">
                                                                <div className="totalassigned">
                                                                    {process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_Total_Assign_Card_Quantity} = <span>{totalassignedCard}</span>
                                                                </div>
                                                            </div>


                                                            {showMessage && parseInt(totalassignedCard) !== parseInt(cardQuantity)
                                                                ?

                                                                <div className="col-12 mt-4 ">

                                                                    <div className="starStyle" >
                                                                        <span>* </span>  {process.env.REACT_APP_Var_FOR_ADMIN_MANAGE_CARD_Number_Of_Cards_Must_Be_Same_As_Ordered}
                                                                    </div>


                                                                </div>
                                                                : null
                                                            }

                                                        </>


                                                    }




                                                    <div className="col-12 mt-4">
                                                        {
                                                            buttonDisable || parseInt(totalassignedCard) !== parseInt(cardQuantity)
                                                                ?
                                                                <>
                                                                    {
                                                                        poupShow ?
                                                                            <button type="button" className="lbtn btngy"  >Assign Now </button>
                                                                            :
                                                                            <button type="button" id="openPopup" className="lbtn btngy" data-bs-toggle="modal" data-bs-target="#morerates">Assign Now </button>
                                                                    }
                                                                </>
                                                                :
                                                                <button type="submit" value="Assign Now" className="lbtn btnbl" onClick={assignCardToCorp} disabled={buttonDisable || apiLoader} >

                                                                    {
                                                                        apiLoader
                                                                            ?
                                                                            <div className="spinner-border" role="status">
                                                                                <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                            </div>
                                                                            :
                                                                            <>Assign Now</>}
                                                                </button>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="modal fade bd-example-modal-sm" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" id="morerates">
                                                    <div className="modal-dialog modal-sm">
                                                        <div className="modal-content">

                                                            <div className="modal-header">
                                                                <h5 className="modal-title" style={{ fontSize: fontSize }}>{cardNotAvailMsg}</h5>
                                                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body" style={{ maxHeight: "230px", overflow: "auto" }}>
                                                                <table className="table table-bordered">
                                                                    <tbody>
                                                                        {tableDetail.map(data => (
                                                                            <tr>
                                                                                <td>{data.cardNumber} </td>
                                                                                <td><strong>{data.reason}</strong></td>
                                                                            </tr>
                                                                        ))


                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        {



                                            loadingOrder
                                                ?
                                                <LoadingCoponent />
                                                :
                                                JSON.stringify(orderHistory) === "{}"
                                                    ?
                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="lastorder">



                                                            <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_Details}


                                                            </h3>

                                                            <div className="col-12 ">
                                                                <div className="no-record">
                                                                    <i></i>
                                                                    <h3 style={{ borderBottom: "none" }}> No Record Available.</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :



                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="lastorder">
                                                            <h3>Last Order Details <a href="/corp-order-history" className="sbtn btnbl">Order history</a></h3>
                                                            <h6>{orderHistory.roName}</h6>
                                                            <p>Order ID: <strong>#{orderHistory.orderId}</strong></p>


                                                            <div className="lstorderby">
                                                                <h5>Order By:</h5>
                                                                <h6>{orderHistory.fullName}</h6>
                                                                <p><i className="mobile"></i>+91-{orderHistory.mobile}</p>
                                                                <p><i className="calander"></i>{orderHistory.dateCreatedOn}</p>
                                                            </div>

                                                            <div className="lstorderqty">
                                                                <h5>{parseInt(orderHistory.cardType) === 1 ? <>Physical Card Quantity:</> : <>Virtual Card Quantity:</>}</h5>
                                                                <h4>{orderHistory.cardQuantity}</h4>

                                                            </div>


                                                            <div className="lstordradrs">


                                                                {orderHistory.cardType === 0
                                                                    ?
                                                                    null
                                                                    :
                                                                    <div className="lstshipadrs">
                                                                        <h5>Shipping Address:</h5>
                                                                        <h6>{orderHistory.shippingPersonName}</h6>
                                                                        <p>{orderHistory.shippingAddress}</p>
                                                                        <p><i className="mobile"></i>+91-{orderHistory.shippingPersonMdn}</p>
                                                                    </div>
                                                                }

                                                                <div className="lstbiladrs">
                                                                    <h5>Billing Address:</h5>
                                                                    <h6>{orderHistory.fullName}</h6>
                                                                    <p>{orderHistory.billingAddress}</p>
                                                                    <p><i className="mobile"></i>+91-{orderHistory.mobile}</p>
                                                                </div>
                                                            </div>

                                                            <div className="order-track">

                                                                <div className="row g-0">
                                                                    <div className="col-12">
                                                                        <h6>Order Status

                                                                        </h6>
                                                                    </div >
                                                                    <div className="col">
                                                                        <div className={orderHistory.orderedClass}>
                                                                            <i className="dot"></i>
                                                                            <i className="line"></i>
                                                                            <p>Ordered</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className={orderHistory.paymentClass}>
                                                                            <i className="dot"></i>
                                                                            <i className="line"></i>
                                                                            <p>Payment</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className={orderHistory.approvalClass}>
                                                                            <i className="dot"></i>
                                                                            <i className="line"></i>
                                                                            <p>Order Approval</p>
                                                                        </div>
                                                                    </div>




                                                                    {
                                                                        orderHistory.cardType === 0
                                                                            ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                <div className="col">
                                                                                    <div className={orderHistory.dispatchClass}>
                                                                                        <i className="dot"></i>
                                                                                        <i className="line"></i>
                                                                                        <p>Shipped</p>
                                                                                    </div>
                                                                                </div>




                                                                            </>
                                                                    }


                                                                    {
                                                                        orderHistory.cardType === 0
                                                                            ?
                                                                            <div className="col">
                                                                                <div className={orderHistory.deliveredCLass}>
                                                                                    <i className="dot"></i>
                                                                                    <i className="line"></i>
                                                                                    <p>Order Completed</p>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className="col">
                                                                                    <div className={orderHistory.deliveredCLass}>
                                                                                        <i className="dot"></i>
                                                                                        <i className="line"></i>
                                                                                        <p>Delivered</p>
                                                                                    </div>
                                                                                </div>




                                                                            </>
                                                                    }

                                                                </div >

                                                            </div>




                                                        </div>
                                                    </div>



                                        }

                                    </div>
                                </div>
                            </div>
                        )}
                        no={() =>
                            <Redirect to="/mis-report" />

                        }
                    />
                    :
                    <ResetLoginPassword />

            )
            : (
                <Redirect to='/' />
            )


        }

    </div>

    )

}


const mapDispatchToProps = dispatch => ({
    showAvailableCardSectionFun: () => dispatch(showAvailableCardSection()),
    showAddNewOderSectionFun: () => dispatch(showAddNewOderSection())
});

const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, currentUser }, role: { roleData }, showAndHide: { showCreateNewOderSection, showReviewOderSection, showDetailOderSection, fromDashboard,
    showAvailableCardSection, renderTransactionHistory } }) => ({
        authenticated, userRole, roleData, showAvailableCardSection, roMdn, fromDashboard, isPasswordReset,
        showCreateNewOderSection, showReviewOderSection, showDetailOderSection, renderTransactionHistory, currentUser
    });

export default connect(mapStateToProps, mapDispatchToProps)(ManageCard);