import React, { useEffect } from "react";
import { connect } from 'react-redux';
import BalanceImformation from '../component/balance.information.component';

const SubBalance = ({ wallet, loading, sub_wallet_balance }) => {

    console.log('SubBalance to show y vala hi wallet h na', wallet, sub_wallet_balance)

    var amount = 0.00;

    sub_wallet_balance.map(balance => {
        if (parseInt(balance.wallet) === parseInt(wallet.wallet_id)) {
            amount = balance.balance
        }
    })




    return (

        <BalanceImformation loading={loading} amount={amount} wallet_id={`${wallet.wallet_id}`} wallet={wallet} />

    );


};

const mapStateToProps = ({ token: { token } }) => ({
    token
});


export default connect(mapStateToProps)(SubBalance);

