import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import { resetOder } from '../../redux/order/order.action';
import apiCallService from '../../service/api-call-service';
import '../css/corpoeate-profile.styles.css'
import ResetLoginPassword from './reset-login-password';



class CorporateProfile extends React.Component {

    constructor() {
        super();

        this.state = {
            confirmPassword: "",
            password: "",
            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            message: "",
            severty: "success",
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,
            submitButton: true

        };
    }





    sendOtp = async (event) => {

        event.preventDefault();

        if (this.state.password === this.state.confirmPassword) {
            const url = `${process.env.REACT_APP_OEMS_GET_OTP_FOR_RESET_TXN_PASSWORD}/${this.props.roMdn}?type=${process.env.REACT_APP_OEMS_OTP_TYPE_TXN_PASSWORD}`;
            // const url = `${process.env.REACT_APP_OEMS_GET_OTP_FOR_RESET_TXN_PASSWORD}/1111111111?type=${process.env.REACT_APP_OEMS_OTP_TYPE_TXN_PASSWORD}`;

            var response = await apiCallService.getMethodCallWithoutParam(url);
            console.log(response, url)
            if (response !== undefined && response.message !== undefined && response.message === 'success') {
                this.setState({
                    buttonShow: true
                })
                this.setState({ triggeretime: true })
                this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
                console.log(' document.getElementById("otpDiv")', document.getElementById("otpDiv"))
                document.getElementById("otpDiv").click();


            } else {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: "error" })
                this.setState({ message: response.response_description })

            }
        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: "error" })
            this.setState({ message: process.env.React_APP_Message_Corp_Profile_Page_If_mismatch_new_n_confirm_password })

        }
    }


    resendOTP = async (event) => {

        event.preventDefault();

        if (this.state.password === this.state.confirmPassword) {
            const url = `${process.env.REACT_APP_OEMS_GET_OTP_FOR_RESET_TXN_PASSWORD}/${this.props.roMdn}?type=${process.env.REACT_APP_OEMS_OTP_TYPE_TXN_PASSWORD}`;
            // const url = `${process.env.REACT_APP_OEMS_GET_OTP_FOR_RESET_TXN_PASSWORD}/1111111111?type=${process.env.REACT_APP_OEMS_OTP_TYPE_TXN_PASSWORD}`;
            this.setState({ triggeretime: false })
            var response = await apiCallService.getMethodCallWithoutParam(url);
            console.log(response, url)
            if (response !== undefined && response.message !== undefined && response.message === 'success') {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: "success" })
                this.setState({ message: process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile })
                this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
                this.setState({ triggeretime: true })

            } else {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: "error" })
                this.setState({ message: response.response_description })

            }
        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: "error" })
            this.setState({ message: process.env.React_APP_Message_Corp_Profile_Page_If_mismatch_new_n_confirm_password })

        }
    }



    submitPasswordChange = async (event) => {
        event.preventDefault();

        var url = process.env.REACT_APP_OEMS_RESET_TXN_PASSWORD
        var request = {
            "mdn": this.props.roMdn,
            "newPassword": this.state.password,
            "otp": this.state.otp
        }
        console.log("request", request);

        var response = await apiCallService.postMethodCall(url, request);

        console.log('response REACT_APP_OEMS_RESET_TXN_PASSWORD', url, response);

        if (response !== undefined && response.message !== undefined) {
            this.setState({
                buttonShow: false
            })

            this.setState({
                severty: "success"
            })
            this.setState({
                message: response.message,
            })
            this.setState({
                dropDownHidden: true
            })

            this.props.setSuccessMessage(response);


            this.setState({ submitButton: false })

            document.getElementById("submitButton").click();

            this.setState({ triggeretime: false, buttonShow: false })

            this.props.setResetTxnSectionFalse();

        } else {
            this.setState({
                severty: "error"
            })
            this.setState({
                message: response.response_description,
            })
            this.setState({
                dropDownHidden: true
            })
            // this.setState({ triggeretime: false, buttonShow: false })
        }
    }

    MethodTosetEnableVar = () => {
        this.setState({ disableVar: true });
    }

    MethodTosetDisabledVar = () => {
        this.setState({ disableVar: false });
    }

    changeToPassword = () => {
        setTimeout(function () {
            document.getElementById("password").setAttribute("type", "password")
        }, 500);
    }



    render() {

        console.log("States here", this.state);
        var zeroOne = this.props.mdn.substring(0, 1);
        var sevenNine = this.props.mdn.substring(8, 10);
        return (< >
            {(this.props.authenticated)
                ? (
                    this.props.isPasswordReset
                        ?
                        < >
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="corp-info">
                                    <Snackbar

                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={this.state.dropDownHidden}
                                        autoHideDuration={20000}
                                        onClose={e => this.setState({ dropDownHidden: false })}
                                    >
                                        <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                            {this.state.message}
                                        </Alert>
                                    </Snackbar>

                                    <h3 className="subhead">Reset Transaction Password</h3>
                                    <form className="row" onSubmit={this.sendOtp} autoComplete="off">
                                        <div className="col-12 col-sm-6">

                                            <div  className="webform">
                                                <div  className="form-floating">
                                                    <input className="form-control" type="password" id="password" spellCheck="false" autoComplete="off" style={{ fontFamily: 'text-security-disc' }} maxLength={6} minLength={6} onKeyDown={(evt) => !(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "._", "\b", "Delete", "Backspace"].includes(evt.key)) && evt.preventDefault()} value={this.state.password} onChange={e => this.setState({ password: e.target.value })} required />

                                                    <label for="password">New Password</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div  className="webform">
                                                <div  className="form-floating">
                                                    <input className="form-control" type="password" id="password" spellCheck="false" autoComplete="off" style={{ fontFamily: 'text-security-disc' }} maxLength={6} minLength={6} onKeyDown={(evt) => !(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "._", "\b", "Delete", "Backspace"].includes(evt.key)) && evt.preventDefault()} value={this.state.confirmPassword} onChange={e => this.setState({ confirmPassword: e.target.value })} required />
                                                    <label for="password">Confirm New Password</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <button className="mbtn btngr mt-3" onClick={this.props.setResetTxnSectionFalse}>Cancel</button>
                                            {
                                                this.state.buttonShow
                                                    ?
                                                    <button className="mbtn btnbl mt-3" type="button" id="otpDiv" data-bs-toggle="modal" data-bs-target="#sendotp">Send OTP</button>
                                                    :
                                                    <button className="mbtn btnbl mt-3" type="submit">Send OTP</button>

                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>




                            <div className="col-12">
                                <div className="notxt">Note : Please ensure that no critical process is in process / has been scheduled at the time of resetting transaction password.</div>
                            </div>




                            <div className="modal fade" id="sendotp" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
                                <div className="modal-dialog modal-sm">
                                    <form className="modal-content alerttext" onSubmit={this.submitPasswordChange}>
                                        <div className="modal-header">
                                            <h5>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_HEADING_One_Time_Password} </h5>
                                            <button className="close" data-bs-dismiss="modal" aria-label="Close" onClick={e => { this.setState({ triggeretime: false, buttonShow: false }) }}>&times;</button>
                                        </div>
                                        <div className="modal-body" >
                                            <div className="otptxt">
                                                <input className="form-control" type="text" placeholder={process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_Enter_OTP} value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} required />
                                                <p>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_PARAGRAPH_OF_OTP} {zeroOne}XXXXXXX{sevenNine}</p>

                                                <h5>
                                                    {this.state.triggeretime ?
                                                        <Countdown date={this.state.time} onMount={this.MethodTosetEnableVar} onComplete={this.MethodTosetDisabledVar} />
                                                        :
                                                        null
                                                    }
                                                </h5>
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            {
                                                this.state.disableVar
                                                    ?
                                                    <button type="button" className="mbtn btngr" onClick={this.resendOTP} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                    :
                                                    <button type="button" className="mbtn btngn" onClick={this.resendOTP} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>

                                            }




                                            {this.state.submitButton
                                                ?
                                                <button type="submit" className="mbtn btnbl"
                                                >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                :
                                                <button type="button" id="submitButton" className="mbtn btnbl"
                                                    data-bs-dismiss="modal" aria-label="Close"
                                                >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset

});

export default connect(mapStateToProps)(CorporateProfile);