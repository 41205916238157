import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import apiCallService from '../../service/api-call-service';
import '../css/corpoeate-profile.styles.css'
import ResetTxnSectionComponent from '../component/reset-txn-password.component';
import Countdown from 'react-countdown';
import ResetLoginPassword from '../component/reset-login-password';
import history from '../../auth/history';
import ReactTooltip from 'react-tooltip';
import Avatar from 'react-avatar';
import UploadImage from '../../employee/component/upload-image.component';

import { setBankAddAndUpdateUrl, setBankBackButton } from '../../redux/user/user.actions'
import { store } from '../../redux/store';
import Can from '../../auth/can/Can';


// const [corpDetailToUpdate, setCorpDetailToUpdate] = useState([]);


//     const [districtBilling, setDistrictBilling] = useState('');

//     const [stateList, setStateList] = useState([]);
//     const [districtList, setDistrictList] = useState([]);
//     const [cityList, setCityList] = useState([]);

class CorporateProfile extends React.Component {

    constructor() {
        super();

        this.state = {
            showEditContact: 'none',
            showContactPerson: 'block',
            lastUpdatedDate: "",
            fullName: '',
            email: '',
            mobileNum: '',
            prevfullName: '',
            prevemail: '',
            prevmobileNum: '',

            generalAmount: '',
            travelAmount: '',
            foodAmount: '',
            corpName: '',
            isActive: 1,
            corpAddress: '',
            // dropDownHidden: '',
            message: '',
            severty: '',
            resetTxnSection: false,
            maxGeneralAmount: 1,
            maxTravelAmount: 1,
            maxFoodAmount: 1,
            logoUrl: '',

            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,

            otpButtonClose: false,
            buttonShowLimit: false,


            stateList: [],
            districtList: [],
            cityList: [],
            address1: '',
            address2: '',
            state: '',
            district: '',
            city: '',
            pincode: '',

            editAddressSection: false,
            corpInfo: [],

            response: [],
            updateCorpProcessBar: false

        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Corporate Profile"
        this.fetchDetail();
        this.fetchMax();

        localStorage.setItem('url', '');

        if (this.props.location.state !== undefined && this.props.location.state.txnSection) {
            this.setState({ resetTxnSection: true })
        }
        this.getStates();

        this.fetchPosts();

    }



    fetchPosts = async () => {

        const url = `${process.env.REACT_APP_GET_CARD_USAGE_MODE_BY_ROMDN}/${this.props.roMdn}`;



        console.log(url);
        console.log(this.props.roMdn)
        const res = await apiCallService.getMethodCallWithoutParam(url);


        if (res !== undefined && res !== null) {

            this.setState({ response: res })
        }



    }

    getStates = async () => {

        var url = process.env.REACT_APP_OEMS_GET_ALL_STATES;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("state response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("state response", response.statesList);

            var arr = [];

            response.statesList.map(state => {
                var value = "value";
                var label = "label";
                state[value] = state.stateName;
                state[label] = state.stateName;
            })

            this.setState({ stateList: response.statesList })
        }
    }

    validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    getDistrict = async (id, type) => {

        var distListTOSet = [];
        console.log('get cities method call', id)
        var url = `${process.env.REACT_APP_OEMS_GET_ALL_DISTRICT}/${id}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("districtList response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("districtList response", response.districtList);

            response.districtList.map(disstrict => {

                var value = "value";
                var label = "label";
                disstrict[value] = disstrict.distName;
                disstrict[label] = disstrict.distName;
                distListTOSet.push(disstrict);


            })


            this.setState({ districtList: distListTOSet })


        }

    }

    getCities = async (id) => {

        var cityListTOSet = [];
        console.log('get cities method call', id)


        var urlDis = `${process.env.REACT_APP_OEMS_GET_ALL_CITIES}/${id}`;

        var responsecityList = await apiCallService.getMethodCallWithoutParam(urlDis);
        console.log("cityList response", responsecityList);

        if (responsecityList !== undefined && responsecityList.response_code !== undefined && responsecityList.response_code === 0) {
            console.log("cityList response", responsecityList.cityList);

            responsecityList.cityList.map(city => {
                var value = "value";
                var label = "label";
                city[value] = city.cityName;
                city[label] = city.cityName;
                cityListTOSet.push(city);


            })


            this.setState({ cityList: cityListTOSet })
        }


    }



    fetchMax = async () => {

        var arrayForGeneralAmount = [];
        var arrayForTravelAmount = [];
        var arrayForFoodAmount = [];



        //     REACT_APP_GET_EMPLOYEE_MAX_AMOUNT=http://3.6.104.48:7072/getWalletMaxAmount
        // REACT_APP_GET_BAND_MAX_AMOUNT
        var urlForEmployee = `${process.env.REACT_APP_GET_EMPLOYEE_MAX_AMOUNT}?roId=${this.props.roMdn}`
        var urlForBand = `${process.env.REACT_APP_GET_BAND_MAX_AMOUNT}?roId=${this.props.roMdn}`


        var responseForEmployee = await apiCallService.getMethodCallWithoutParam(urlForEmployee);

        console.log("max amount responseForEmployee", responseForEmployee, urlForEmployee)
        if (responseForEmployee !== undefined) {

            if (responseForEmployee.genralLimitMax !== undefined) {
                arrayForGeneralAmount.push(parseFloat(responseForEmployee.genralLimitMax / 100).toFixed(2));
            }
            if (responseForEmployee.travelLimitMax !== undefined) {
                arrayForTravelAmount.push(parseFloat(responseForEmployee.travelLimitMax / 100).toFixed(2));
            }

            if (responseForEmployee.foodLimitMax !== undefined) {
                arrayForFoodAmount.push(parseFloat(responseForEmployee.foodLimitMax / 100).toFixed(2))
            }
        }
        var responseForBand = await apiCallService.getMethodCallWithoutParam(urlForBand);

        console.log("max amount responseForBand", responseForBand, urlForBand)
        if (responseForBand !== undefined) {

            if (responseForBand.genralLimitMax !== undefined) {
                arrayForGeneralAmount.push(parseFloat(responseForBand.genralLimitMax / 100).toFixed(2));
            }
            if (responseForBand.travelLimitMax !== undefined) {
                arrayForTravelAmount.push(parseFloat(responseForBand.travelLimitMax / 100).toFixed(2));
            }
            if (responseForBand.foodLimitMax !== undefined) {
                arrayForFoodAmount.push(parseFloat(responseForBand.foodLimitMax / 100).toFixed(2))
            }
        }

        // var i = arrayForGeneralAmount.indexOf(0);
        // if (i >= 0) {
        //     arrayForGeneralAmount.splice(i, 1);
        // }

        // var j = arrayForTravelAmount.indexOf(0);
        // if (j >= 0) {
        //     arrayForTravelAmount.splice(j, 1);
        // }
        // var k = arrayForFoodAmount.indexOf(0);
        // if (k >= 0) {
        //     arrayForFoodAmount.splice(k, 1);
        // }

        arrayForGeneralAmount = arrayForGeneralAmount.filter(function (val) {
            return val !== 0;
        });
        arrayForTravelAmount = arrayForTravelAmount.filter(function (val) {
            return val !== 0;
        });
        arrayForFoodAmount = arrayForFoodAmount.filter(function (val) {
            return val !== 0;
        });
        console.log('arrayForGeneralAmount arrayForTravelAmount arrayForFoodAmount', arrayForGeneralAmount, arrayForTravelAmount, arrayForFoodAmount)
        var maxGeneralAmount = Math.max(...arrayForGeneralAmount);
        var maxTravelAmount = Math.max(...arrayForTravelAmount);
        var maxFoodAmount = Math.max(...arrayForFoodAmount);
        console.log('arrayForGeneralAmount arrayForTravelAmount arrayForFoodAmount', maxGeneralAmount, maxTravelAmount, maxFoodAmount)


        this.setState({ maxFoodAmount: maxFoodAmount })
        this.setState({ maxGeneralAmount: maxGeneralAmount })
        this.setState({ maxTravelAmount: maxTravelAmount })
    }




    fetchDetail = async () => {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        const res = await apiCallService.getMethodCallWithParam(url, { 'roId': this.props.roMdn });

        console.log('CorporateProfile', res)

        if (res !== undefined && res.response_code !== undefined && res.response_code === 0 && res.response !== undefined) {
            this.setState({
                fullName: res.response.contact_person,
                email: res.response.contact_email,
                mobileNum: res.response.contact_number,
                prevfullName: res.response.contact_person,
                prevemail: res.response.contact_email,
                prevmobileNum: res.response.contact_number,
                corpName: res.response.name,
                corpAddress: res.response.address,
                isActive: res.response.is_active,
                generalAmount: parseInt(res.response.genrealLimit) / 100,
                travelAmount: parseInt(res.response.travelLimit) / 100,
                foodAmount: parseInt(res.response.walletFoodLimit) / 100,
                lastUpdatedDate: res.response.limitLstUpdatedDate,
                address1: res.response.address1,
                address2: res.response.address2,
                state: res.response.state,
                city: res.response.city,
                pincode: res.response.pincode,
                district: res.response.district,
                logoUrl: res.response.logoUrl,
                corpInfo: res.response
            })

            var cityArr = [];
            var stateArray = [];
            var distrArray = [];
            var cirtyyyy = {
                cityName: res.response.city,
                cityId: res.response.city
            }
            cityArr.push(cirtyyyy);
            var stateyyyy = {
                value: res.response.state.trim(),
                label: res.response.state.trim()
            }
            stateArray.push(stateyyyy);
            var distyyyy = {
                distName: res.response.district,
                distId: res.response.district
            }
            distrArray.push(distyyyy);
            this.setState({
                cityList: cityArr,
                // stateList: stateArray,
                districtList: distrArray
            })
        }

    }
    showEditBlockAndHideContact = () => {
        this.setState({
            showEditContact: 'block',
            showContactPerson: 'none'
        })


    }

    showEditAddressBlock = () => {
        this.setState({
            editAddressSection: true
        })
    }
    hideEditAddressBlock = () => {
        this.setState({
            editAddressSection: false,
            address1: this.state.corpInfo.address1,
            address2: this.state.corpInfo.address2,
            state: this.state.corpInfo.state,
            city: this.state.corpInfo.city,
            pincode: this.state.corpInfo.pincode,
            district: this.state.corpInfo.district
        })

        var cityArr = [];
        var stateArray = [];
        var distrArray = [];
        var cirtyyyy = {
            cityName: this.state.corpInfo.city,
            cityId: this.state.corpInfo.city
        }
        cityArr.push(cirtyyyy);
        var stateyyyy = {
            value: this.state.corpInfo.state.trim(),
            label: this.state.corpInfo.state.trim()
        }
        stateArray.push(stateyyyy);
        var distyyyy = {
            distName: this.state.corpInfo.district,
            distId: this.state.corpInfo.district
        }
        distrArray.push(distyyyy);
        this.setState({
            cityList: cityArr,
            // stateList: stateArray,
            districtList: distrArray
        })
    }

    showContactBlockAndHideEditBlock = () => {
        this.setState({
            showEditContact: 'none',
            showContactPerson: 'block'
        })
        this.setState({
            fullName: this.state.prevfullName,
            email: this.state.prevemail,
            mobileNum: this.state.prevmobileNum,
        })
    }



    otpGeneration = async (event) => {
        event.preventDefault();
        console.log('request allValues', this.state.mobileNum)
        console.log('event target id otpGeneration', event.target.id)
        console.log('fetch target id by different method')

        var detail = event.target.id;

        var url = process.env.REACT_APP_URL_TO_GET_OTP
        var requestToSend = {
            "request": {
                "eventType": "rowalletlinkup",
                "destinations": [
                    {
                        "address": this.state.mobileNum,
                        "type": "mobile"
                    }
                ]
            }
        }

        console.log('request allValues', requestToSend)

        var response = await apiCallService.postMethodCall(url, requestToSend);
        this.setState({ triggeretime: false });

        console.log('request response allValues', response);
        if (response.response_code === 0 && response.response !== undefined) {
            this.setState({ buttonShow: true });
            this.setState({ referenceNum: response.response.referenceNumber })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true })

            console.log("otpDiv", document.getElementById("otpDiv"))
            document.getElementById("otpDiv").click();

        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }
    }


    resendOtp = async (event) => {
        var url = process.env.REACT_APP_URL_TO_GET_OTP
        var requestToSend = {
            "request": {
                "eventType": "rowalletlinkup",
                "destinations": [
                    {
                        "address": this.state.mobileNum,
                        "type": "mobile"
                    }
                ]
            }
        }

        console.log('request allValues', requestToSend)

        var response = await apiCallService.postMethodCall(url, requestToSend);
        this.setState({ triggeretime: false })

        console.log('request response allValues', response);
        if (response.response_code === 0 && response.response !== undefined) {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile })
            this.setState({ referenceNum: response.response.referenceNumber })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true })

        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }
    }


    otpGenerationLimit = async (event) => {
        event.preventDefault();

        console.log('request allValues', this.state.mobileNum)
        console.log('event target id otpGenerationLimit', event.target.id)
        console.log('fetch target id by different method')

        // var detail = event.target.id;

        var url = process.env.REACT_APP_URL_TO_GET_OTP
        var requestToSend = {
            "request": {
                "eventType": "rowalletlinkup",
                "destinations": [
                    {
                        "address": this.state.mobileNum,
                        "type": "mobile"
                    }
                ]
            }
        }

        console.log('request allValues', requestToSend)

        var response = await apiCallService.postMethodCall(url, requestToSend);


        console.log('request response allValues', response);
        if (response.response_code === 0 && response.response !== undefined) {

            this.setState({ buttonShowLimit: true })
            this.setState({ referenceNum: response.response.referenceNumber })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true })
            // console.log("sendotpsaveCorpLimitButton", document.getElementById("sendotpsaveCorpLimitButton"))
            document.getElementById("sendotpsaveCorpLimitButton").click();

        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }
    }

    saveCorpDetail = async (event) => {
        event.preventDefault();

        console.log("Save Corpoprate trigger", event.target.id);

        var req = {
            "request": {
                "ro_id": this.props.roMdn,
                "corporateRequest": {
                    "name": this.state.corpName,
                    "contact_email": this.state.email,
                    "contact_number": this.state.mobileNum,
                    "contact_person": this.state.fullName,
                    "updated_by": this.props.currentUser
                },
                "otp": {
                    "referenceNumber": this.state.referenceNum,
                    "otp": this.state.otp
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL;

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ otpButtonClose: true })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.React_APP_Message_Corp_Profile_Page_If_edit_corp_detail_and_click_in_save })
            this.showContactBlockAndHideEditBlock();

            this.setState({
                prevfullName: this.state.fullName,
                prevemail: this.state.email,
                prevmobileNum: this.state.mobileNum
            })



            // console.log("closeOtpSection", document.getElementById("closeOtpSection"))
            document.getElementById("closeOtpSection").click();
            this.setState({ buttonShow: false })
            this.setState({ otpButtonClose: false })
            this.setState({ otp: '' })

            console.log("this.state after udate", this.state);
            window.location.reload();

        } else {
            this.setState({ otpButtonClose: true })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
            this.setState({ buttonShow: false })
            this.setState({ otpButtonClose: false })

        }


    }



    saveCorpLimit = async (event) => {

        event.preventDefault();

        if (this.state.generalAmount === '' && this.state.foodAmount === '' && this.state.travelAmount === '') {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'warning' })
            this.setState({ message: 'Please Select atleast one limit to update.' })
        }
        else {
            var req = {
                "request": {
                    "ro_id": this.props.roMdn,
                    "corporateRequest": {
                        "name": this.state.corpName,
                        "email": this.state.email,
                        "contact_number": this.state.mobileNum,
                        "contact_person": this.state.fullName,
                        "updated_by": this.props.currentUser
                    },
                    "corpLimit": [{
                        "genreal_limit": this.state.generalAmount * 100,
                        "food_limit": this.state.foodAmount * 100,
                        "travel_limit": this.state.travelAmount * 100

                    }],
                    "otp": {
                        "referenceNumber": this.state.referenceNum,
                        "otp": this.state.otp
                    }

                }
            }

            var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL;

            var response = await apiCallService.postMethodCall(url, req);

            console.log('response Corporate saveCorpLimit', req, response);

            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {

                this.setState({ otpButtonClose: true })
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'success' })
                this.setState({ message: process.env.React_APP_Message_Corp_Profile_Page_If_edit_corp_limit })
                this.fetchDetail();
                // console.log("closeOtpLimitSection", document.getElementById("closeOtpLimitSection"))
                document.getElementById("closeOtpLimitSection").click();
                this.setState({ buttonShow: false })
                this.setState({ otpButtonClose: false })
                this.setState({ otp: '' })

            } else {
                this.setState({ otpButtonClose: true })
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'error' })
                this.setState({ message: response.response_description })
                this.setState({ buttonShow: false })
                this.setState({ otpButtonClose: false })

            }
        }

    }


    validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    setResetTxnSectionTrue = () => {
        this.setState({ resetTxnSection: true })
    }
    setResetTxnSectionFalse = () => {
        this.setState({ resetTxnSection: false })
    }



    setSuccessMessage = (response) => {
        this.setState({
            buttonShow: false
        })

        this.setState({
            severty: "success"
        })
        this.setState({
            message: response.message,
        })
        this.setState({
            dropDownHidden: true
        })
    }


    MethodTosetEnableVar = () => {
        this.setState({ disableVar: true });
    }

    MethodTosetDisabledVar = () => {
        this.setState({ disableVar: false });
    }


    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }




    handleStateChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        console.log('event.target', customAtrribute)


        const { name, value, id } = { name: "state", value: event.target.value };
        console.log('target', event.target.getAttribute('key'));
        console.log('name :', name, 'value :', value)

        this.setState({ state: value })


        // setState(event.target.value)
        this.getDistrict(customAtrribute, '');

    }
    handleDistrictChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');

        this.setState({ district: event.target.value });

        console.log('event.target', customAtrribute)
        this.getCities(customAtrribute);

    }

    handleCityChange = (event) => {

        const { name, value, id } = { name: "city", value: event.target.value };
        console.log('target', event.target.getAttribute('key'));
        console.log('name :', name, 'value :', value)

        this.setState({ city: value });


    }




    saveCorpAddress = async (event) => {
        event.preventDefault();

        console.log("Save Corpoprate trigger", event.target.id);
        var fullAddress = `${this.state.address1}, ${this.state.address2}, ${this.state.city}, ${this.state.state} - ${this.state.pincode}`;
        var req = {
            "request": {
                "ro_id": this.props.roMdn,
                "corporateRequest": {
                    "address": fullAddress,
                    "address1": this.state.address1,
                    "address2": this.state.address2,
                    "city": this.state.city,
                    "state": this.state.state,
                    "district": this.state.district,
                    "pincode": this.state.pincode,
                    "updated_by": this.props.currentUser
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL_WITHOUT_OTP;

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            // this.setState({ otpButtonClose: true })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.React_APP_Message_Corp_Profile_Page_If_edit_corp_detail_and_click_in_save })
            // this.showContactBlockAndHideEditBlock();

            // this.setState({
            //     prevfullName: this.state.fullName,
            //     prevemail: this.state.email,
            //     prevmobileNum: this.state.mobileNum
            // })


            // // console.log("closeOtpSection", document.getElementById("closeOtpSection"))
            // document.getElementById("closeOtpSection").click();
            // this.setState({ buttonShow: false })
            // this.setState({ otpButtonClose: false })
            // this.setState({ otp: '' })
            history.push('/corporate-profile');
            history.go();

        } else {
            // this.setState({ otpButtonClose: true })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
            // this.setState({ buttonShow: false })
            // this.setState({ otpButtonClose: false })

        }


    }



    enableorDisableMode = async (event) => {
        console.log("enableorDisableMode event.target.checked", event.target.checked)
        console.log("enableorDisableMode event.target.id", event.target.id)
        console.log("enableorDisableMode event.target.name", event.target.name)


        var status = process.env.REACT_APP_CORP_TXN_MODE_STATUS_TO_DISABLE
        var id = event.target.name;

        if (event.target.checked) {
            status = process.env.REACT_APP_CORP_TXN_MODE_STATUS_TO_ENABLE
        }


        var request = [];

        var subRequest = {

            "customerId": this.props.roMdn,
            "transaction_type_config_id": id,
            "status": status


        }

        // {
        //     "request":[{
        //         "customerId":"1234567890",
        //         "transaction_type_config_id":1,
        //         "status":1,
        //         "userLimit":0

        //     }]
        // }
        request.push(subRequest)

        var url = process.env.REACT_APP_ENABLE_OR_DISABLE_Corporate_TXN_MODE
        var requestN = { request }

        var response = await apiCallService.postMethodCall(url, requestN);

        console.log("enableorDisableMode response", JSON.stringify(requestN), response);

        if (response !== undefined && response.code !== undefined && parseInt(response.code) === 0) {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_SUCCESSFULY_RESET_MODE })
            this.fetchPosts();
        } else
            if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'success' })
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_SUCCESSFULY_RESET_MODE })
                this.fetchPosts();
            } else {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'error' })
                this.setState({ message: response.response_description })
            }



    }





    updateCorpLogo = async (e) => {
        e.preventDefault();

        this.setState({ updateCorpProcessBar: true })

        var imgUrl = this.state.logoUrl;

        if (localStorage.getItem('url') !== '') {
            imgUrl = localStorage.getItem('url');
        }
        if (imgUrl === '') {
            return (

                this.setState({ dropDownHidden: true }),
                this.setState({ severty: 'error' }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_TO_UPLOAD_PICTURE_BEFORE_SUBMIT }),
                this.setState({ updateCorpProcessBar: false })

            )
        }
        console.log("yes hitted")
        var req = {
            "request": {
                "ro_id": this.props.roMdn,
                "corporateRequest": {
                    "created_byEmail": this.props.currentUser,
                    "logoUrl": imgUrl,
                    "updated_by": this.props.currentUser,
                    "updated_byRoId": this.props.roMdn,
                    "updated_byEmail": this.props.currentUser
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL_WITHOUT_OTP;

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ updateCorpProcessBar: false })

            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.React_APP_Message_FOR_SUCCESS_ON_PROFILE_PIC_UPDATE })

            history.push('/corporate-profile');
            history.go();



        } else {
            this.setState({ updateCorpProcessBar: false })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.description })

        }
    }


    goToBankDetailPage = (event) => {
        event.preventDefault();
        console.log('event.target.id', event.target.id);
        store.dispatch(setBankBackButton('/corporate-profile'));

        if (event.target.id === 'addBank') {
            store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_ADD_BANK_DETAIL));
        } else {
            store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_UPDATE_BANK_DETAIL));
        }


        history.push('/add-corp-bank-account');
        history.go();

    }




    render() {

        var zeroOne = this.state.mobileNum.substring(0, 1);
        var sevenNine = this.state.mobileNum.substring(8, 10);


        console.log("this.state test", this.state)

        return (<div >
            {(this.props.authenticated)
                ? (
                    this.props.isPasswordReset
                        ?
                        <div className="mid-container">
                            <div className="container-fluid">

                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={this.state.dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => this.setState({ dropDownHidden: false })}
                                >
                                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                        {this.state.message}
                                    </Alert>
                                </Snackbar>

                                <div className="head">
                                    <div className="row">

                                        <div  className="col-12 col-md-4">
                                            <h3>{process.env.REACT_APP_CORP_PROFILE_HEADING_VAR}</h3>
                                        </div>



                                        <div  className="col-12 col-md-8">
                                            <div  className="head-btn">


                                                <Can
                                                    role={this.props.userRole}
                                                    perform="corpProfile-resetLoginPass:action"
                                                    roleData={this.props.roleData}
                                                    yes={() => (
                                                        <a href="/reset-login-password" className="logpass">Reset Login Password</a>

                                                    )}
                                                    no={() => null}
                                                />


                                                <Can
                                                    role={this.props.userRole}
                                                    perform="corpProfile-resettxnPass:action"
                                                    roleData={this.props.roleData}
                                                    yes={() => (
                                                        <button className="pass" onClick={this.setResetTxnSectionTrue}>Reset TXN Password</button>


                                                    )}
                                                    no={() => null}
                                                />

                                                <Can
                                                    role={this.props.userRole}
                                                    perform="corpProfile-manageBand:action"
                                                    roleData={this.props.roleData}
                                                    yes={() => (
                                                        <a href="/band-list" className="band">Manage Band</a>


                                                    )}
                                                    no={() => null}
                                                />



                                            </div>
                                        </div>



                                    </div>

                                </div>

                                <div className="emp-list">
                                    <div className="row">



                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="corp-details">
                                                {/* <img src={corpImg} alt="corp name" /> */}
                                                <Avatar src={this.state.logoUrl} color={Avatar.getRandomColor(this.props.roMdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={this.props.roMdn} name={this.state.corpName} size="70px" round={true} />

                                                <Can
                                                    role={this.props.userRole}
                                                    perform="corpProfile-editProfilePic:action"
                                                    roleData={this.props.roleData}
                                                    yes={() => (
                                                        <button className="editpro" data-bs-toggle="modal" data-bs-target="#edtpropic"></button>
                                                    )}
                                                    no={() => null}
                                                />

                                                <h5>{this.state.corpName} <br /><span>Corp ID: {this.props.roMdn}</span></h5>
                                                <h6 className="active">Active </h6>

                                                {this.state.editAddressSection
                                                    ? null :
                                                    <p><i className="location"></i> {this.state.corpAddress}

                                                        <Can
                                                            role={this.props.userRole}
                                                            perform="corpProfile-editAddressInfo:action"
                                                            roleData={this.props.roleData}
                                                            yes={() => (
                                                                <>
                                                                    <ReactTooltip
                                                                        id="back"
                                                                        place="top"
                                                                        type='dark'
                                                                        effect='solid'
                                                                        // multiline={true}
                                                                        backgroundColor="black"
                                                                    />
                                                                    <button className="edit" data-for="back" data-tip="Edit" data-iscapture="true" id="editinfo" onClick={this.showEditAddressBlock}>Edit</button>
                                                                </>
                                                            )}
                                                            no={() => null}
                                                        />
                                                    </p>
                                                }
                                                {this.state.editAddressSection
                                                    ?
                                                    <div className="editAdress">
                                                        <form className="row" onSubmit={this.saveCorpAddress}>
                                                            <div className="col-12 col-md-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <input className="form-control" placeholder="Address 1" type="text" id="address1" name="address1" onChange={this.handleChange} value={this.state.address1} required />
                                                                        <label for="address1">Address 1</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <input className="form-control" type="text" id="address2" placeholder="Address 2" name="address2" onChange={this.handleChange} value={this.state.address2} />
                                                                        <label for="address2">Address 2</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <select  className="form-select" id="sg" name="documentType3" value={this.state.state} onChange={this.handleStateChange} required>
                                                                            <option value="">Select</option>
                                                                            {this.state.stateList.map((documentType) =>
                                                                            (<option keyId={documentType.stateId} value={documentType.stateName}>
                                                                                {documentType.stateName}
                                                                            </option>))
                                                                            }
                                                                        </select>
                                                                        <label for="sg">State</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <select  className="form-select" id="sg" name="documentType3" value={this.state.district} onChange={this.handleDistrictChange} required>
                                                                            <option value="">select</option>
                                                                            {this.state.districtList.map((documentType) =>
                                                                            (<option keyId={documentType.distId} value={documentType.distName}>
                                                                                {documentType.distName}
                                                                            </option>))
                                                                            }
                                                                        </select>
                                                                        <label for="sg">District</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <select  className="form-select" id="sg" name="documentType4" value={this.state.city} onChange={this.handleCityChange} required>
                                                                            <option value="">select</option>
                                                                            {this.state.cityList.map((documentType) =>
                                                                            (<option keyId={documentType.cityId} value={documentType.cityName}>
                                                                                {documentType.cityName}
                                                                            </option>))
                                                                            }
                                                                        </select>
                                                                        <label for="sg">City</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <input className="form-control" type="number"
                                                                            id="pincode"
                                                                            placeholder="Pincode"
                                                                            name="pincode"
                                                                            maxLength='6'
                                                                            pattern="[0-9]{6}"
                                                                            onInput={this.validateInputLenght}
                                                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.handleChange} value={this.state.pincode} required />
                                                                        <label for="pincode">Pincode</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 ">
                                                                <button className="mbtn btngr mt-1" id="savecorpinfo" type="button" onClick={this.hideEditAddressBlock} >Cancel</button>
                                                                <button className="mbtn btnbl mt-1" id="savecorpinfo" type="submit" >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_BUTTON_Save}</button>

                                                            </div>
                                                        </form>

                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </div>


                                        {this.state.resetTxnSection
                                            ?
                                            <ResetTxnSectionComponent mdn={this.state.mobileNum} setResetTxnSectionFalse={this.setResetTxnSectionFalse} setSuccessMessage={this.setSuccessMessage} />
                                            :

                                            <>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="corp-info" id="corpinfo" style={{ "display": `${this.state.showContactPerson}` }} >
                                                        <h3 className="subhead">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_ONLY_Contact_Person}
                                                            <Can
                                                                role={this.props.userRole}
                                                                perform="corpProfile-editContactPersonInfo:action"
                                                                roleData={this.props.roleData}
                                                                yes={() => (
                                                                    <button className="mdl-js-button mdl-js-ripple-effect edit" data-toggle="tooltip" title="Edit" id="editinfo" onClick={this.showEditBlockAndHideContact}>Edit</button>


                                                                )}
                                                                no={() => null}
                                                            />

                                                        </h3>
                                                        <p><i className="name"></i> {this.state.fullName}</p>
                                                        <p><i className="mobile"></i> +91-{this.state.mobileNum}</p>
                                                        <p><i className="email"></i> {this.state.email}</p>
                                                    </div>

                                                    <div className="corp-info" id="editcorpinfo" style={{ "display": `${this.state.showEditContact}` }}>
                                                        <h3 className="subhead">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_Edit_Contact_Person} <button className="mdl-js-button mdl-js-ripple-effect close" data-toggle="tooltip" title="Close" id="closeinfo" onClick={this.showContactBlockAndHideEditBlock}>Close</button>
                                                        </h3>
                                                        <form className="row" onSubmit={this.otpGeneration} >
                                                            <div className="col-12 col-sm-6">


                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <input className="form-control" type="text" id="fname" placeholder="First Name" value={this.state.fullName} pattern="[a-zA-Z ]{2,60}" onChange={e => this.setState({ fullName: e.target.value })} required />
                                                                        <label for="fname">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_Full_Name}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6">

                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <input className="form-control" type="number" maxLength="10" pattern="[0-9]{10}" onInput={this.validateInputLenght} onChange={e => this.setState({ mobileNum: e.target.value })}
                                                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} id="mnum" value={this.state.mobileNum} required />
                                                                        <label for="mnum">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_Mobile_Number}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6">
                                                                <div  className="webform">
                                                                    <div  className="form-floating">
                                                                        <input className="form-control" type="email" id="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} required />
                                                                        <label for="email">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_Email_ID}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6">
                                                                {
                                                                    this.state.buttonShow
                                                                        ?
                                                                        <button className="mbtn btnbl mt-3" type="button" id="otpDiv" data-bs-toggle="modal" data-bs-target="#sendotp" >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_BUTTON_Save}</button>
                                                                        :
                                                                        <button className="mbtn btnbl mt-3" id="savecorpinfo" type="submit" >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_BUTTON_Save}</button>

                                                                }
                                                            </div>
                                                        </form>

                                                    </div>
                                                    <div className="modal fade" id="sendotp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                        <div className="modal-dialog modal-sm">
                                                            <form className="modal-content alerttext" onSubmit={this.saveCorpDetail}>
                                                                <div className="modal-header">
                                                                    <h5>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_HEADING_One_Time_Password} </h5>
                                                                    <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="otptxt">
                                                                        <input className="form-control" type="text" placeholder={process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_Enter_OTP} value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} required />
                                                                        <p>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_PARAGRAPH_OF_OTP} {zeroOne}XXXXXXX{sevenNine}</p>

                                                                        <h5>
                                                                            {this.state.triggeretime ?
                                                                                <Countdown date={this.state.time} onMount={this.MethodTosetEnableVar} onComplete={this.MethodTosetDisabledVar} />
                                                                                :
                                                                                null
                                                                            }
                                                                        </h5>
                                                                        ref number:- {this.state.referenceNum}
                                                                    </div>

                                                                </div>
                                                                <div className="modal-footer">
                                                                    {this.state.disableVar
                                                                        ?
                                                                        <button type="button" className="mbtn btngr" onClick={this.resendOtp} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                                        :
                                                                        <button type="button" className="mbtn btngn" onClick={this.resendOtp} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                                    }
                                                                    {this.state.otpButtonClose
                                                                        ?
                                                                        <button type="button" className="mbtn btnbl" id="closeOtpSection" data-bs-dismiss="modal" aria-label="Close">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                                        :
                                                                        <button type="submit" id="savecorpinfo" className="mbtn btnbl" >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                                    }
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-12 col-xl-6">
                                                    <div className="subs-wallet">
                                                        <form className="manage-limit" onSubmit={this.otpGenerationLimit}>
                                                            <h3 className="subhead">{process.env.REACT_APP_CORP_PROFILE_FOR_Subscribed_Wallet_and_Loading_Limits} <span className="infotxt"><br />{process.env.REACT_APP_CORP_PROFILE_FOR_Last_updated_date} {this.state.lastUpdatedDate}</span></h3>

                                                            <div className="row">

                                                                {this.state.generalAmount !== null && !isNaN(this.state.generalAmount)
                                                                    ? <div className="col-12 col-sm-4 col-md-3">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="number" id="1" placeholder="amount" step="0.01" min={this.state.maxGeneralAmount} max={process.env.React_APP_MAX_GENERAL_IN_RUPEES} value={(this.state.generalAmount)} onChange={e => this.setState({ generalAmount: (e.target.value) })} />
                                                                            <label for="1">{process.env.REACT_APP_CORP_PROFILE_FOR_Subscribed_Wallet_General_LABEL}</label>
                                                                            {/* <i className="rupee">&#8377;</i> */}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {this.state.foodAmount !== null && !isNaN(this.state.foodAmount)
                                                                    ? <div className="col-12 col-sm-4 col-md-3">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" placeholder="amount" type="number" id="2" step="0.01" min={this.state.maxFoodAmount} max={process.env.React_APP_MAX_FOOD_IN_RUPEES} value={(this.state.foodAmount)} onChange={e => this.setState({ foodAmount: (e.target.value) })} />
                                                                            <label for="2">{process.env.REACT_APP_CORP_PROFILE_FOR_Subscribed_Wallet_Food_LABEL}</label>
                                                                            {/* <i className="rupee">&#8377;</i> */}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {this.state.travelAmount !== null && !isNaN(this.state.travelAmount)
                                                                    ?
                                                                    <div className="col-12 col-sm-4 col-md-3">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" placeholder="amount" type="number" id="3" step="0.01" min={this.state.maxTravelAmount} max={process.env.React_APP_MAX_TRAVEL_IN_RUPEES} value={(this.state.travelAmount)} onChange={e => this.setState({ travelAmount: (e.target.value) })} />
                                                                            <label for="3">{process.env.REACT_APP_CORP_PROFILE_FOR_Subscribed_Wallet_Travel_LABEL}</label>
                                                                            {/* <i className="rupee">&#8377;</i> */}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }


                                                                <Can
                                                                    role={this.props.userRole}
                                                                    perform="corpProfile-editCorpLimit:action"
                                                                    roleData={this.props.roleData}
                                                                    yes={() => (
                                                                        <div className="col-12 col-sm-4 col-md-3">
                                                                            {
                                                                                this.state.buttonShowLimit
                                                                                    ?
                                                                                    <button type="button" className="mbtn btnbl mt-3" id="sendotpsaveCorpLimitButton" data-bs-toggle="modal" data-bs-target="#sendotpsaveCorpLimit" >{process.env.REACT_APP_CORP_PROFILE_FOR_Subscribed_Wallet_BUTTON_UPDATE}</button>
                                                                                    :
                                                                                    <button className="mbtn btnbl mt-3" type="submit">{process.env.REACT_APP_CORP_PROFILE_FOR_Subscribed_Wallet_BUTTON_UPDATE}</button>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    no={() => null}
                                                                />



                                                            </div>
                                                        </form>
                                                        <div className="modal fade" id="sendotpsaveCorpLimit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                            <div className="modal-dialog modal-sm">
                                                                <form className="modal-content alerttext" onSubmit={this.saveCorpLimit}>
                                                                    <div className="modal-header">
                                                                        <h5>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_HEADING_One_Time_Password} </h5>
                                                                        <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div className="otptxt">
                                                                            <input className="form-control" type="text" placeholder={process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_Enter_OTP} value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} required />
                                                                            <p>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_PARAGRAPH_OF_OTP} {zeroOne}XXXXXXX{sevenNine}</p>

                                                                            <h5>
                                                                                {
                                                                                    this.state.triggeretime ?
                                                                                        <Countdown date={this.state.time} onMount={this.MethodTosetEnableVar} onComplete={this.MethodTosetDisabledVar} />
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </h5>
                                                                            ref no. {this.state.referenceNum}
                                                                        </div>

                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        {this.state.disableVar
                                                                            ?
                                                                            <button type="button" className="mbtn btngr" onClick={this.resendOtp} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                                            :
                                                                            <button type="button" className="mbtn btngn" onClick={this.resendOtp} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                                        }
                                                                        {
                                                                            this.state.otpButtonClose
                                                                                ?
                                                                                <button type="button" className="mbtn btnbl" id="closeOtpLimitSection" data-bs-dismiss="modal" aria-label="Close">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                                                :
                                                                                <button type="submit" className="mbtn btnbl" >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                                        }
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                                <Can
                                                    role={this.props.userRole}
                                                    perform="corpProfile-manageCardUsage:action"
                                                    roleData={this.props.roleData}
                                                    yes={() => (
                                                        <div className="col-12 col-xl-6">
                                                            <div className="manage-categry">
                                                                <h3 className="subhead">Manage Card Usage</h3>
                                                                <div className="row">

                                                                    {
                                                                        this.state.response !== undefined && this.state.response !== null && this.state.response.map(modeType => (
                                                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4">
                                                                                <div className="managebox">
                                                                                    <h6>{modeType.transactionTypeConfigLabel}</h6>

                                                                                    <div  className="form-check form-switch">
                                                                                        <input type="checkbox" id={modeType.transactionTypeConfigLabel} name={modeType.transactionTypeConfigId} className="form-check-input" onChange={this.enableorDisableMode} checked={modeType.status !== 0} />
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                        ))


                                                                    }
                                                                </div>



                                                            </div>
                                                        </div>
                                                    )}
                                                    no={() => null}
                                                />




                                                <div className="col-12">
                                                    <div className="corpbankdetails">
                                                        <h3 className="subhead">{process.env.REACT_APP_VAR_FOR_CORP_PROFILE_PAGE_Bank_Account_Details_HEADING}

                                                            <Can
                                                                role={this.props.userRole}
                                                                perform="corpProfile-updateBankDetail:action"
                                                                roleData={this.props.roleData}
                                                                yes={() => (

                                                                    this.props.corpBankDetail != null
                                                                        ?
                                                                        <button className="mdl-js-button mdl-js-ripple-effect edit" id="updateBank" data-toggle="tooltip" title="Update" onClick={this.goToBankDetailPage}>Edit</button>
                                                                        : null

                                                                )}
                                                                no={() => null}
                                                            />


                                                        </h3>

                                                        {
                                                            this.props.corpBankDetail != null
                                                                ?
                                                                <div className="row">
                                                                    <p className="col-12 col-sm-6 col-lg-4"><span>{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_Bank_Name_Label}</span> {this.props.corpBankDetail.bank}</p>
                                                                    <p className="col-12 col-sm-6 col-lg-4"><span>{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_Full_Name_Label}</span> {this.props.corpBankDetail.fullName}</p>
                                                                    <p className="col-12 col-sm-6 col-lg-4"><span>{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_Account_No_Label}</span> {this.props.corpBankDetail.accountnumber}</p>
                                                                    <p className="col-12 col-sm-6 col-lg-4"><span>{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_Account_Type_Label}</span> {this.props.corpBankDetail.accountType}</p>
                                                                    <p className="col-12 col-sm-6 col-lg-4"><span>{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_IFSC_Code_Label}</span> {this.props.corpBankDetail.ifscCode}</p>
                                                                    {this.props.corpBankDetail !== undefined && this.props.corpBankDetail !== null && this.props.corpBankDetail.cancelledChequeURL !== undefined && this.props.corpBankDetail.cancelledChequeURL !== "" && this.props.corpBankDetail.cancelledChequeURL !== null &&
                                                                        <p className="col-12"><a href={this.props.corpBankDetail.cancelledChequeURL} target="_blank" className="imglink">{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_Cancelled_Cheque_Label}</a></p>}
                                                                </div>
                                                                :
                                                                <Can
                                                                    role={this.props.userRole}
                                                                    perform="corpProfile-addBankDetail:action"
                                                                    roleData={this.props.roleData}
                                                                    yes={() => (
                                                                        <div className="row">
                                                                            <p className="col-12 col-sm-6 col-lg-4">
                                                                                <span>{process.env.REACT_APP_VAR_FOR__CORP_PROFILE_PAGE_FIRST_TIME_CORP_Add_Your_Bank_Detail}</span>
                                                                                <button className="mbtn btnbl" id="addBank" onClick={this.goToBankDetailPage}>Add</button>
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    no={() => null}
                                                                />
                                                        }
                                                    </div>
                                                </div>

                                            </>

                                        }


                                    </div>
                                </div>














                                <div className="modal fade" id="edtpropic" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content alerttext">
                                            <div className="modal-header">
                                                <h5 id="">Update Corporate Logo</h5>
                                                <button type="button"  className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <UploadImage currentUser={this.props.currentUser}
                                                    header=""
                                                    h6Value={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_DRAG_AND_DROP}
                                                    h6SpanValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_BROWSE}
                                                    h6ValueAfterbreak={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_PROFILE_PIC}
                                                    pValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FILE_DETAIL}
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="mbtn btnbl" onClick={this.updateCorpLogo} disabled={this.state.updateCorpProcessBar}>
                                                    {
                                                        this.state.updateCorpProcessBar
                                                            ?
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                            </div>
                                                            :
                                                            <>Save</>}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, currentUser, corpBankDetail }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, currentUser, isPasswordReset, corpBankDetail

});

export default connect(mapStateToProps)(CorporateProfile);