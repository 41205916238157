import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { store } from '../../redux/store';
import apiCallService from '../../service/api-call-service';
import CardStatusType from '../../constants/card-status.type';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import image1 from '../../assests/no-image.png'
import { resetOder } from "../../redux/order/order.action";
import Avatar from 'react-avatar';
import CreatableSelect from 'react-select/creatable';
import GenerateRequestId from "../../common-function/GenerateRequestId";
import history from '../../auth/history';
// var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
import { setEmployeeDetails } from "../../redux/user/user.actions";
import AsyncSelect from 'react-select/async';

import img from '../../assests/icons/arrow-down.svg'
import { setAvailableCard } from "../../redux/card/card.action";
class AvailableCards extends React.Component {

	constructor() {
		super();

		this.state = {
			cardReferenceNumber: '',
			employeeList: [],
			searchField: '',
			selectedEmployee: [],
			searchBlock: false,
			empBlock: false,
			dropDownHidden: '',
			message: '',
			severty: '',
			cardIdentifier: "newCard",
			notAssignedEmployee: [],
			blockedEmployee: [],
			fullSearchVar: false,
			virtualCardReference: GenerateRequestId.generateCardNumber(),


			option: [],

			lastFourDigit: '',
			searchKeyword: "",
			assignedCardProcessBar: false,
			cardType: ""
		};
	}




	componentDidMount = () => {

		this.getCardAvailAll(this.state.searchKeyword);

	}



	getCardAvailAll = async (searchKeyword) => {
		var cardUrl = `${process.env.REACT_APP_GET_AVAILABLE_CARD}`

		var request = {
			"roId": this.props.roMdn,
			"size": process.env.REACT_APP_NUMBER_OF_CARD_IN_DROPDOWN_PER_TYPE,
			"searchKeyword": searchKeyword
		}
		var cardResponse = await apiCallService.postMethodCall(cardUrl, request);


		var opt = [];
		this.setState({ option: opt })
		// var value = { "label": "Virtual Cards", "value": "Virtual Cards", isDisabled: true }
		// opt.push(value);
		// var value1 = { "label": GenerateRequestId.generateCardNumber(), "value": GenerateRequestId.generateCardNumber() }
		// opt.push(value1)
		console.log('cardResponse %%%%%%', cardResponse, cardUrl, request)

		if (cardResponse !== undefined && cardResponse.status !== undefined && cardResponse.status === 0 && cardResponse.response !== undefined) {
			if (cardResponse.response === null) {
				store.dispatch(setAvailableCard([]))

				this.setState({ option: opt })
			} else if (cardResponse.response !== null) {


				if (cardResponse.response.virtualCardList !== undefined && cardResponse.response.virtualCardList !== null && cardResponse.response.virtualCardList.length > 0) {
					var value = { "label": "Virtual Cards", "value": "Virtual Cards", isDisabled: true }
					opt.push(value)
				}
				cardResponse.response.virtualCardList !== undefined && cardResponse.response.virtualCardList !== null && cardResponse.response.virtualCardList.map(car => {
					var label = "label"
					car[label] = car.cardReferenceNumber
					var value = "value"
					car[value] = car.cardReferenceNumber
					opt.push(car);
				})
				console.log("Documentation mai nhi krunga ", opt);



				if (cardResponse.response.physicalCardList !== undefined && cardResponse.response.physicalCardList !== null && cardResponse.response.physicalCardList.length > 0) {
					var value2 = { "label": "Physical Cards", "value": "Physical Cards", color: 'red', isDisabled: true }
					opt.push(value2)
				}
				cardResponse.response.physicalCardList !== undefined && cardResponse.response.physicalCardList !== null && cardResponse.response.physicalCardList.map(car => {
					var label = "label"
					car[label] = car.cardReferenceNumber
					var value = "value"
					car[value] = car.cardReferenceNumber
					opt.push(car);
				})
				console.log("Documentation mai nhi krunga ", opt);
				store.dispatch(setAvailableCard(cardResponse.response.getCardDetails))
				this.setState({ option: opt })
				console.log("this.state.option Documentation mai nhi krunga ", this.state.option);
			}
		} else {

			this.setState({ option: opt })
		}
		// this.setState({ option: opt })
	}






	handleCardAssignedChange = (newValue, actionMeta) => {
		console.group('Value Changed handleChange');
		// const inputValue = newValue.replace(/\W/g, '');
		console.log('Value Changed handleChange newValue', newValue);
		console.log(`action handleChange: ${actionMeta.action}`);
		console.groupEnd();
		if (newValue !== null && newValue.value !== null) {

			this.setState({ cardReferenceNumber: newValue.value });
			this.setState({ lastFourDigit: newValue.lastFourDigit })
			this.setState({ cardType: newValue.cardType })

		} else {
			this.setState({ cardReferenceNumber: '' });
			this.setState({ lastFourDigit: '' })
			this.setState({ cardType: '' })

		}
	};



	handleCardAssignedInputChange = (inputValue, actionMeta) => {
		console.group('Input Changed handleInputChange');
		console.log(inputValue);
		this.getCardAvailAll(inputValue);
		console.log(`action handleInputChange: ${actionMeta.action}`);
		console.groupEnd();
	};


	handleAssignedCard = async (event) => {

		if (this.props.employeeDetail.email == null || this.props.employeeDetail.email === "") {
			return (
				this.setState({ dropDownHidden: true }),
				this.setState({ severty: 'warning' }),
				this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_EMAIL_REQUIRED_WHILE_MAIL_SEND_TO_USER })
			)
		} else

			if (this.state.cardReferenceNumber === 'Select Card' || this.state.cardReferenceNumber === '') {
				return (

					this.setState({ dropDownHidden: true }),
					this.setState({ severty: 'error' }),
					this.setState({ message: 'Please Select Card Reference Number' })

				)
			} else {
				this.setState({ assignCardProcessBar: true });
				var url = process.env.REACT_APP_UPDATE_EMPLOYEE_CARD_STATUS_WITH_CORP_CARD

				var request = {
					"roId": this.props.roMdn,
					"cardRefNumber": this.state.cardReferenceNumber,
					"employeeId": this.props.employeeDetail.emp_id,
					"employeeName": this.props.employeeDetail.fullName,
					"mdn": this.props.employeeDetail.mdn,
					"updatedBy": this.props.fullName,
					"updatedByRoleName": this.props.userRole,
					"status": CardStatusType.ASSIGNED,
					"message": "Assigned Card",
					"imageUrl": this.props.employeeDetail.imageUrl,
					"band": this.props.employeeDetail.band,
					"kycStatus": this.props.employeeDetail.kyc_status,
					"email": this.props.employeeDetail.email,
					"firstName": this.props.employeeDetail.first_name,
					"lastName": this.props.employeeDetail.last_name,
					"dob": this.props.employeeDetail.dob,
					"gender": this.props.employeeDetail.gender,
					"pan": this.props.employeeDetail.pan,
					"pincode": this.props.employeeDetail.pincode,
					"managerEmpCorpId": this.props.employeeDetail.manager_emp_corp_id,
					"financeEmpCorpId": this.props.employeeDetail.finance_emp_corp_id,
					"department": this.props.employeeDetail.department,
					"location": this.props.employeeDetail.location,
					"roleName": this.props.employeeDetail.roleName,
					"modify_by": this.props.currentUser,
					"lastFourDigit": this.state.lastFourDigit,
					"employeeStatus": 0,
					"cardType": this.state.cardType,
					"updatedByEmail": this.props.currentUser,
				}


				var response = await apiCallService.postMethodCall(url, request);

				console.log('handleAssignedCard response', request, response)

				if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
					this.setState({ dropDownHidden: true })
					this.setState({ severty: 'success' })
					this.setState({ message: "Successfully Assigned" })
					var employee = this.props.employeeDetail;
					employee.card_ref_no = this.state.cardReferenceNumber;
					employee.card_staus = CardStatusType.ASSIGNED;
					store.dispatch(setEmployeeDetails(employee));

					// setEmployeeDetail(employee)
					this.setState({ assignCardProcessBar: false });

					window.location.reload();
				} else {
					this.setState({ assignCardProcessBar: false });
					this.setState({ dropDownHidden: true })
					this.setState({ severty: 'error' })
					this.setState({ message: response.response_description })
				}

			}
	}









	handleInputCheckBox = (event) => {
		console.log('event triggered', event.target.name)
		console.log(event.target.getAttribute("name"));
		this.setState({ cardIdentifier: event.target.getAttribute("name") })
		this.setState({ searchField: '' })
		this.setState({ selectedEmployee: [] })
		this.setState({ empBlock: false })
		if (event.target.getAttribute("name") === "newCard") {
			this.setState({ employeeList: this.state.notAssignedEmployee })
		} else if (event.target.getAttribute("name") === "reAssignedCard") {
			this.setState({ employeeList: this.state.blockedEmployee })
		}

	}

	filterColors = (inputValue) => {
		console.log("this.state.option ", this.state.option)
		return this.state.option !== undefined && this.state.option.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};
	loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterColors(inputValue));
		}, 3000);
	};

	setToKyc = () => {
		history.push({
			pathname: '/user-kyc',
			state: { post: { "cardRefNumber": this.state.cardReferenceNumber, "employeeName": this.state.selectedEmployee.fullName, "className": "card-inactive", "statusVariable": "Card Inactive" }, employee: this.state.selectedEmployee }
		})
		history.go()
	}

	render() {


		const customStyles = {
			control: (provided, state) => ({
				...provided,
				backgroundColor: 'white',
				border: "none",
				borderRadius: "0px",
				boxShadow: "none",
				minHeight: "22px",
				lineHeight: "22px",
				background: `url(${img})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "12px",
				backgroundPosition: "center right",
				marginTop: "-7px"
			}),

			valueContainer: (provided, state) => ({
				...provided,
				padding: "0px 5px 0px 0px"
			}),

			indicatorContainer: (provided, state) => ({
				...provided,
				padding: "5px",
				margin: "100px"
			}),
			option: (provided, state) => ({
				...provided,
				//   borderBottom: '1px dotted pink',
				backgroundColor: state.isDisabled ? '#2684FF' : state.isSelected ? '#deebff' : '',
				color: state.isSelected ? '#354558' : state.isDisabled ? "#ffffff" : '',
				fontSize: state.isDisabled ? "16px" : "14px",
				padding: "8px",
				cursor: state.isDisabled ? '' : state.isSelected ? '' : "pointer",
				"&:hover": {
					backgroundColor: "#deebff",
					color: "354558"
				},


			}),
			singleValue: (provided, state) => ({
				...provided,
				color: '#354558',
				fontSize: "15px",
				cursor: "pointer"
			}
			),
			placeholder: (provided, state) => ({
				...provided,
				color: 'hsl(0,0%,80%)',
				fontSize: "16px",
			})
		}




		return (
			<div >
				{(this.props.authenticated)
					? (


						<div className="row">
							<Snackbar
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={this.state.dropDownHidden}
								autoHideDuration={3000}
								onClose={e => this.setState({ dropDownHidden: false })}
							>
								<Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
									{this.state.message}
								</Alert>
							</Snackbar>
							<div className="col-12 col-lg-6 col-xl-4">
								<div className="card-ref">
									<i></i>
									<label>Card Reference No.</label>
									<h4>NA</h4>
								</div>
							</div>



							<div className="col-12 col-lg-6 col-xl-5">
								<div className="webform">
									<div className="formselect">
										<label htmlFor="cars">Available Cards</label>

										<AsyncSelect
											placeholder="Select Card"
											cacheOptions
											loadOptions={this.loadOptions}
											defaultOptions
											onChange={this.handleCardAssignedChange}
											onInputChange={this.handleCardAssignedInputChange}
											styles={customStyles}
											components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
										/>
									</div>
								</div>
							</div>





							<div className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl">
								<button type="submit" onClick={this.handleAssignedCard} className="lbtn btnbl float-md-end" disabled={this.state.assignCardProcessBar}>
									{
										this.state.assignCardProcessBar
											?
											<div className="spinner-border" role="status">
												<span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
											</div>
											:
											<>Assign</>
									}

								</button>


							</div>



						</div>
						// </div>

					)
					: (
						<Redirect to='/' />
					)


				}

			</div>

		)
	}

}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, fullName }, role: { roleData }, card: { availableCard } }) => ({
	authenticated, userRole, roleData, availableCard, roMdn, fullName
});

export default connect(mapStateToProps)(AvailableCards);