import React from "react";
import { Link, Redirect } from "react-router-dom";
import apiCallService from "../../service/api-call-service";
import history from '../../auth/history';
import Can from "../../auth/can/Can";
import { connect } from "react-redux";


const UploadMoneyDisbersalList = ({ data, userRole, roleData, uploadedOn }) => {

    var inprogress = 0;
    var total = 0;
    total = parseInt(data.draftCount) + parseInt(data.failedCount) + parseInt(data.successCount) + parseInt(data.rejectedCount) + parseInt(data.inprogressCount) + parseInt(data.approvedCount);

    inprogress = ((parseInt(data.draftCount) / parseInt(total)) * 100);
    if (data.draftCount === 0) {
        inprogress = 100 - 0
    }
    console.log("Check All Record Date ", data, uploadedOn)

    return (
        data.isBulk === 1 ?



            <Can
                role={userRole}
                perform="moneyDisburse-superAdmin:visit"
                roleData={roleData}
                yes={() => (


                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="upload-card">
                            <button type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</button>

                            <div className="upload-type">
                                <p>Single disbursal by</p>
                                {
                                    (data.name === null)
                                        ?
                                        <h6>
                                            Not Available
                                        </h6>
                                        :
                                        <h6 >
                                            {data.name}
                                        </h6>
                                }
                            </div>
                            <div className="up-date">{data.downloadDate}</div>

                            <div className="upload-btn">

                                {(data.inprogressCount === 0) && (data.successCount === 0) && (data.approvedCount === 0)
                                    ?
                                    null
                                    :
                                    <Link
                                        to={{
                                            pathname: '/success-disbursal',
                                            state: { data, status: '2,0,5,6', uploadedOn: uploadedOn }
                                        }}
                                        className="success" data-toggle="tooltip" title="Success Records"

                                    >  </Link>



                                }

                                {(data.failedCount === 0) && (data.rejectedCount === 0)
                                    ?
                                    null
                                    :

                                    <Link
                                        to={{
                                            pathname: '/failed-disbursal',
                                            state: { data, status: '3,4', uploadedOn: uploadedOn }
                                        }}
                                        className="failed" data-toggle="tooltip" title="Rejected Records"

                                    >  </Link>


                                }
                            </div>

                            <div className="dis-sts" >
                                <div className="dis-left" data-toggle="tooltip" title={data.approvedCount > 0 ? "Disbursal Pending" : ""} >
                                    <p className={`${data.className}`}>{data.approveStatusVar}</p>
                                    <p  >{data.approvedBy}</p>
                                </div>
                                <div className="dis-right">
                                    <p>Total Disbursed Amount</p>
                                    <p><strong>₹ {parseFloat(data.totalAmount / 100).toFixed(2)}</strong></p>
                                </div>
                            </div>






                        </div>









                    </div>





                )}
                no={() =>



                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="upload-card">
                            <a type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip">Download</a>

                            <div className="upload-type">
                                <p>Single disbursal by</p>

                                {
                                    (data.name === null)
                                        ?
                                        <h6>
                                            Not Available
                                        </h6>
                                        :
                                        <h6 >
                                            {data.name}
                                        </h6>
                                }

                            </div>
                            <div className="up-date">{data.downloadDate}</div>

                            <div className="upload-btn">



                                {(data.inprogressCount === 0) && (data.successCount === 0) && (data.approvedCount === 0)
                                    ?
                                    null
                                    :
                                    <>
                                        <Can
                                            role={userRole}
                                            perform="moneyDisburse-checker:draft"
                                            roleData={roleData}
                                            yes={() => (

                                                <Link
                                                    to={{
                                                        pathname: '/success-disbursal',
                                                        state: { data, status: '2,0,5,6', uploadedOn: uploadedOn }
                                                    }}
                                                    className="success" data-toggle="tooltip" title="Success Records"

                                                >  </Link>
                                            )}
                                            no={() =>
                                                null
                                            }
                                        />

                                        <Can
                                            role={userRole}
                                            perform="moneyDisburse-checker:success"
                                            roleData={roleData}
                                            yes={() => (

                                                <Link
                                                    to={{
                                                        pathname: '/checker-success-disbursal',
                                                        state: { data, status: '2,0,5,6', uploadedOn: uploadedOn }
                                                    }}
                                                    className="inProgress" data-toggle="tooltip" title="Approved Records"

                                                >  </Link>
                                            )}
                                            no={() =>
                                                null
                                            }
                                        />
                                    </>


                                }




                                {(data.failedCount === 0) && (data.rejectedCount === 0)
                                    ?
                                    null
                                    :

                                    <Link
                                        to={{
                                            pathname: '/failed-disbursal',
                                            state: { data, status: '3,4', uploadedOn: uploadedOn }
                                        }}
                                        className="failed" data-toggle="tooltip" title="Rejected Records"

                                    >  </Link>





                                }
                            </div>
                            <div className="dis-sts" >
                                <div className="dis-left" data-toggle="tooltip" title={data.approvedCount > 0 ? "Disbursal Pending" : ""} >
                                    <p className={`${data.className}`}>{data.approveStatusVar}</p>
                                    <p  >{data.approvedBy}</p>
                                </div>
                                <div className="dis-right">
                                    <p>Total Disbursed Amount</p>
                                    <p><strong>₹ {parseFloat(data.totalAmount / 100).toFixed(2)}</strong></p>
                                </div>
                            </div>



                        </div>
                    </div>









                }
            />



            :



            <Can
                role={userRole}
                perform="moneyDisburse-superAdmin:visit"
                roleData={roleData}
                yes={() => (



                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="upload-card">
                            <a href={data.fileUrl} type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</a>

                            <div className="upload-type">
                                <p>Bulk disbursal by</p>

                                {
                                    (data.name === null)
                                        ?
                                        <h6>
                                            Not Available
                                        </h6>
                                        :
                                        <h6 >
                                            {data.name}
                                        </h6>
                                }

                            </div>
                            <div className="up-date">{data.downloadDate}</div>

                            <div className="upload-btn">
                                {(data.draftCount === 0)
                                    ?
                                    null
                                    :


                                    <Link to={{
                                        pathname: '/draft-status-disbursal',
                                        state: { data, status: 1, uploadedOn: uploadedOn }
                                    }} className="inProgress" data-toggle="tooltip" title="InProgress Records"
                                    ><span>{data.draftCount}</span></Link>



                                }

                                {(data.inprogressCount === 0) && (data.successCount === 0) && (data.approvedCount === 0)
                                    ?
                                    null
                                    :
                                    <>
                                        {(data.successCount === 0) && (data.approvedCount === 0)
                                            ?
                                            null :
                                            <Link
                                                to={{
                                                    pathname: '/success-disbursal',
                                                    state: { data, status: '0,5,6', uploadedOn: uploadedOn }
                                                }}
                                                className="success" data-toggle="tooltip" title="Approved Records"

                                            > <span>{data.successCount + data.approvedCount}</span> </Link>
                                        }

                                        {data.inprogressCount === 0 ?
                                            null :
                                            <Link
                                                to={{
                                                    pathname: '/checker-success-disbursal',
                                                    state: { data, status: '2', uploadedOn: uploadedOn }
                                                }}
                                                className="inProgress" data-toggle="tooltip" title="Pending Approval"

                                            > <span> {data.inprogressCount}</span> </Link>}

                                    </>


                                }
                                {(data.failedCount === 0) && (data.rejectedCount === 0)
                                    ?
                                    null
                                    :
                                    <>

                                        <Link
                                            to={{
                                                pathname: '/failed-disbursal',
                                                state: { data, status: '3,4', uploadedOn: uploadedOn }
                                            }}
                                            className="failed" data-toggle="tooltip" title="Rejected Records"

                                        > <span>{data.failedCount + data.rejectedCount}</span> </Link>




                                    </>





                                }
                            </div>
                            <div className="dis-sts" >
                                <div className="dis-left" data-toggle="tooltip" title={data.approvedCount > 0 ? "Disbursal Pending" : ""} >
                                    <p className={`${data.className}`}>{data.approveStatusVar}</p>
                                    <p  >{data.approvedBy}</p>
                                </div>
                                <div className="dis-right">
                                    <p>Total Disbursed Amount</p>
                                    <p><strong>₹ {parseFloat(data.totalAmount / 100).toFixed(2)}</strong></p>
                                </div>
                            </div>


                            {(inprogress === 100 || inprogress === 0 || inprogress === NaN)
                                ?
                                <br />
                                :

                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${inprogress}%` }} aria-valuenow={inprogress} aria-valuemin="0" aria-valuemax="100">{parseFloat(inprogress).toFixed(0)}%</div>
                                </div>

                            }
                        </div>
                    </div>

                )}
                no={() =>

                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="upload-card">
                            <a href={data.fileUrl} type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</a>

                            <div className="upload-type">
                                <p>Bulk disbursal by</p>

                                {
                                    (data.name === null)
                                        ?
                                        <h6>
                                            Not Available
                                        </h6>
                                        :
                                        <h6 >
                                            {data.name}
                                        </h6>
                                }

                            </div>
                            <div className="up-date">{data.downloadDate}</div>

                            <div className="upload-btn">
                                {(data.draftCount === 0)
                                    ?
                                    null
                                    :

                                    <Can
                                        role={userRole}
                                        perform="moneyDisburse-maker:success"
                                        roleData={roleData}
                                        yes={() => (
                                            <Link to={{
                                                pathname: '/draft-status-disbursal',
                                                state: { data, status: 1, uploadedOn: uploadedOn }
                                            }} className="inProgress" data-toggle="tooltip" title="InProgress Records"
                                            ><span>{data.draftCount}</span></Link>
                                        )}
                                        no={() =>
                                            null
                                        }
                                    />


                                }

                                {(data.inprogressCount === 0) && (data.successCount === 0) && (data.approvedCount === 0)
                                    ?
                                    null
                                    :
                                    <>
                                        <Can
                                            role={userRole}
                                            perform="moneyDisburse-checker:draft"
                                            roleData={roleData}
                                            yes={() => (

                                                <Link
                                                    to={{
                                                        pathname: '/success-disbursal',
                                                        state: { data, status: '2,0,5,6', uploadedOn: uploadedOn }
                                                    }}
                                                    className="success" data-toggle="tooltip" title="Success Records"

                                                > <span>{data.successCount + data.inprogressCount + data.approvedCount}</span> </Link>
                                            )}
                                            no={() =>
                                                null
                                            }
                                        />

                                        <Can
                                            role={userRole}
                                            perform="moneyDisburse-checker:success"
                                            roleData={roleData}
                                            yes={() => (

                                                <Link
                                                    to={{
                                                        pathname: '/checker-success-disbursal',
                                                        state: { data, status: '2,0,5,6', uploadedOn: uploadedOn }
                                                    }}
                                                    className="inProgress" data-toggle="tooltip" title="Approved Records"

                                                > <span> {data.successCount + data.inprogressCount + data.approvedCount}</span> </Link>
                                            )}
                                            no={() =>
                                                null
                                            }
                                        />
                                    </>


                                }
                                {(data.failedCount === 0) && (data.rejectedCount === 0)
                                    ?
                                    null
                                    :
                                    <>
                                        {(data.rejectedCount === 0)
                                            ?
                                            null
                                            :
                                            <Can
                                                role={userRole}
                                                perform="moneyDisburse-checker:fail"
                                                roleData={roleData}
                                                yes={() => (

                                                    <Link
                                                        to={{
                                                            pathname: '/failed-disbursal',
                                                            state: { data, status: '4', uploadedOn: uploadedOn }
                                                        }}
                                                        className="failed" data-toggle="tooltip" title="Rejected Records"

                                                    > <span>{data.rejectedCount}</span> </Link>
                                                )}
                                                no={() =>
                                                    null
                                                }
                                            />}
                                        <Can
                                            role={userRole}
                                            perform="moneyDisburse-maker:fail"
                                            roleData={roleData}
                                            yes={() => (

                                                <Link
                                                    to={{
                                                        pathname: '/failed-disbursal',
                                                        state: { data, status: '3,4', uploadedOn: uploadedOn }
                                                    }}
                                                    className="failed" data-toggle="tooltip" title="Failed Records"

                                                > <span>{data.failedCount + data.rejectedCount}</span> </Link>
                                            )}
                                            no={() =>
                                                null
                                            }
                                        />
                                    </>





                                }
                            </div>
                            <div className="dis-sts" >
                                <div className="dis-left" data-toggle="tooltip" title={data.approvedCount > 0 ? "Disbursal Pending" : ""} >
                                    <p className={`${data.className}`}>{data.approveStatusVar}</p>
                                    <p  >{data.approvedBy}</p>
                                </div>
                                <div className="dis-right">
                                    <p>Total Disbursed Amount</p>
                                    <p><strong>₹ {parseFloat(data.totalAmount / 100).toFixed(2)}</strong></p>
                                </div>
                            </div>


                            {(inprogress === 100 || inprogress === 0 || inprogress == NaN)
                                ?
                                <br />
                                :
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${inprogress}%` }} aria-valuenow={inprogress} aria-valuemin="0" aria-valuemax="100">{parseFloat(inprogress).toFixed(0)}%</div>
                                </div>

                            }
                        </div>
                    </div>

                }
            />



    )
};


const mapStateToProps = ({ user: { userRole }, role: { roleData } }) => ({
    userRole, roleData
});

export default connect(mapStateToProps)(UploadMoneyDisbersalList);
