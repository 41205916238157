import '../css/small.popup.css';

import React from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import img from '../../assests/icons/arrow-down.svg'
import CardStatus from '../../constants/card-status.type';
import apiCallService from '../../service/api-call-service';


class MultiCardSmallPopUp extends React.Component {

  constructor() {
    super();

    this.state = {

      selectedCardDetail: "",
      cardOption: [],
      mandatoryMessage: "Please Select Card Reference Number",
      messageShow: false,
      selectedReason: "",
      reasonOption: [],
      mandatoryMessageReason: "Please Select Reason",
      reasonMessageShow: false,

    };
  }


  componentDidMount = () => {

    console.log("cardDetails in small popup componentDidMountMamual called", this.props.cardDetails)

    var arr = []

    this.getCardOptions();

    if (this.props.targetid.includes("perm")) {
      this.props.cardDetails.map(cd => {
        if (cd.cardType === 1 && (cd.cardStatus === CardStatus.ACTIVE || cd.cardStatus === CardStatus.TEMPORARY_BLOCK)) {
          var label = "label";
          var value = "value";
          cd[label] = cd.cardReferenceNo;
          cd[value] = cd.cardReferenceNo;
          arr.push(cd);
        }

      })
    }
    else if (this.props.targetid.includes("unBLock")) {
      this.props.cardDetails.map(cd => {
        if (cd.cardStatus === CardStatus.TEMPORARY_BLOCK) {
          var label = "label";
          var value = "value";
          cd[label] = cd.cardReferenceNo;
          cd[value] = cd.cardReferenceNo;
          arr.push(cd);
        }

      })
    }
    else if (this.props.targetid.includes("temp")) {
      this.props.cardDetails.map(cd => {
        if (cd.cardStatus === CardStatus.ACTIVE) {
          var label = "label";
          var value = "value";
          cd[label] = cd.cardReferenceNo;
          cd[value] = cd.cardReferenceNo;
          arr.push(cd);
        }

      })
    }


    else {
      this.props.cardDetails.map(cd => {
        var label = "label";
        var value = "value";
        cd[label] = cd.cardReferenceNo;
        cd[value] = cd.cardReferenceNo;
        arr.push(cd);
      })
    }



    this.setState({ cardOption: arr })

  }



  getCardOptions = async () => {
    var arr = []
    console.log("option load ku nhi ho rhe bat kya hai this.props.targetid", this.props.targetid)
    if (this.props.targetid.includes("perm") || this.props.targetid.includes("permtBlock-employee") || this.props.targetid.includes("pBlock")) {


      var url = process.env.REACT_APP_CORPORATE_CARDSERVICE_GETPERMBLOCKREASON;

      var response = await apiCallService.getMethodCallWithoutParam(url);
      console.log("option load Time", Date.now())
      console.log("option load ku nhi ho rhe bat kya hai", response)
      if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.cardBlockedReasons !== undefined && response.cardBlockedReasons !== null) {


        response.cardBlockedReasons.map(dd => {
          var label = "label";
          var value = "value";
          dd[label] = dd.reason
          dd[value] = dd.reason
          arr.push(dd)
          this.setState({ reasonOption: arr })
        })
        console.log("kya y load hai arr", arr)
        // this.setState({ reasonOption: arr })
        console.log("option load Time bad mai", Date.now())
      }


    } else if (this.props.targetid.includes("temp") || this.props.targetid.includes("tBlock") || this.props.targetid.includes("tempBlock-employee")) {
      var urlN = process.env.REACT_APP_CORPORATE_CARDSERVICE_GETTEMPBLOCKREASON;

      var responseN = await apiCallService.getMethodCallWithoutParam(urlN);

      console.log("option load ku nhi ho rhe bat kya hai", responseN)
      if (responseN !== undefined && responseN.response_code !== undefined && responseN.response_code === 0 && responseN.temporaryCardBlockedReasons !== undefined && responseN.temporaryCardBlockedReasons !== null) {


        responseN.temporaryCardBlockedReasons.map(dd => {
          var label = "label";
          var value = "value";
          dd[label] = dd.reason
          dd[value] = dd.reason
          arr.push(dd)
          this.setState({ reasonOption: arr })
        })
        console.log("kya y load hai arr", arr)
        // this.setState({ reasonOption: arr })
      }

    }

    console.log("kya y load hai arr", this.state.reasonOption)
    // this.setState({ reasonOption: arr })


  }



  handleCardAssignedChange = (newValue, actionMeta) => {
    console.group('Value Changed handleChange');
    // const inputValue = newValue.replace(/\W/g, '');
    console.log('Value Changed handleChange newValue', newValue);
    console.log(`action handleChange: ${actionMeta.action}`);
    console.groupEnd();
    if (newValue !== null && newValue.value !== null) {

      this.setState({ messageShow: false })
      this.setState({ selectedCardDetail: newValue });

    } else {
      this.setState({ messageShow: false })
      this.setState({ selectedCardDetail: '' });
    }
  };


  handleReasonAssignedChange = (newValue, actionMeta) => {
    console.group('Value Changed handleChange');
    // const inputValue = newValue.replace(/\W/g, '');
    console.log('Value Changed handleChange newValue', newValue);
    console.log(`action handleChange: ${actionMeta.action}`);
    console.groupEnd();
    if (newValue !== null && newValue.value !== null) {

      this.setState({ reasonMessageShow: false })
      this.setState({ selectedReason: newValue });

    } else {
      this.setState({ reasonMessageShow: false })
      this.setState({ selectedReason: '' });
    }
  };





  filterColors = (inputValue) => {
    console.log("this.state.option ", this.state.cardOption)
    return this.state.cardOption !== undefined && this.state.cardOption.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterColors(inputValue));
    }, 3000);
  };

  filterReasonColors = (inputValue) => {
    console.log("this.state.option reasonOption", this.state.reasonOption)
    return this.state.reasonOption !== undefined && this.state.reasonOption.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  loadReasonOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterReasonColors(inputValue));
    }, 3000);
  };


  render() {


    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        border: "none",
        borderRadius: "0px",
        boxShadow: "none",
        minHeight: "22px",
        lineHeight: "22px",
        background: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "12px",
        backgroundPosition: "center right",
        marginTop: "-7px"

      }),

      valueContainer: (provided, state) => ({
        ...provided,
        padding: "0px 5px 0px 0px"
      }),

      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: "5px",
        margin: "100px"
      }),
      option: (provided, state) => ({
        ...provided,
        //   borderBottom: '1px dotted pink',
        backgroundColor: state.isDisabled ? '#2684FF' : state.isSelected ? '#deebff' : '',
        color: state.isSelected ? '#354558' : state.isDisabled ? "#ffffff" : '',
        fontSize: state.isDisabled ? "16px" : "14px",
        padding: "8px",
        cursor: state.isDisabled ? '' : state.isSelected ? '' : "pointer",
        "&:hover": {
          backgroundColor: "#deebff",
          color: "354558"
        },


      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#354558',
        fontSize: "15px",
        cursor: "pointer"
      }
      ),
      placeholder: (provided, state) => ({
        ...provided,
        color: 'hsl(0,0%,80%)',
        fontSize: "16px",
      })
    }

    return (
      < >
        {(this.props.authenticated)
          ? (
            <div className="modal fade" id={this.props.targetid} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
              <div className="modal-dialog modal-sm">
                <div className="modal-content alerttext">
                  <div className="modal-header">
                    <h5 id="">{this.props.headerText}</h5>

                  </div>
                  <div className="modal-body">
                    <div className="popupform">


                      <div className="assign-avail-card">
                        <label >Select to:</label>

                        <AsyncSelect
                          placeholder="Select Card"
                          cacheOptions
                          loadOptions={this.loadOptions}
                          defaultOptions
                          onChange={this.handleCardAssignedChange}
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                        />
                        {this.state.messageShow
                          ?
                          <p style={{ color: "#f05258", fontSize: "12px" }}>{this.state.mandatoryMessage}</p>
                          :
                          null
                        }
                      </div>
                    </div>




                    {(this.props.targetid.includes("perm") || this.props.targetid.includes("temp") || this.props.targetid.includes("tBlock")
                      || this.props.targetid.includes("permtBlock-employee") || this.props.targetid.includes("pBlock"))
                      &&
                      <div className="popupform">



                        <div className="assign-avail-card">
                          <label >Reason:</label>

                          <AsyncSelect
                            placeholder="Select Reason"
                            cacheOptions
                            loadOptions={this.loadReasonOptions}
                            defaultOptions
                            onChange={this.handleReasonAssignedChange}
                            styles={customStyles}
                            components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                          />
                          {this.state.reasonMessageShow
                            ?
                            <p style={{ color: "#f05258", fontSize: "12px" }}>{this.state.mandatoryMessageReason}</p>
                            :
                            null
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="mbtn btngr" data-bs-dismiss="modal">
                      {this.props.buttonActionClose}
                    </button>

                    {
                      this.state.selectedCardDetail === ""
                        ?
                        <button type="button" className="mbtn btnbl" onClick={(e) => this.setState({ messageShow: true })}>

                          {this.props.buttonActionName}
                        </button>

                        :
                        this.state.selectedReason === "" && (this.props.targetid.includes("perm") || this.props.targetid.includes("temp") || this.props.targetid.includes("tBlock")
                          || this.props.targetid.includes("permtBlock-employee") || this.props.targetid.includes("pBlock"))
                          ?

                          <button type="button" className="mbtn btnbl" onClick={(e) => this.setState({ reasonMessageShow: true })}>

                            {this.props.buttonActionName}
                          </button>
                          :
                          <button type="button" className="mbtn btnbl" data-bs-dismiss="modal" onClick={(e) => this.props.onclickFunctionName(e, this.props.allValues, this.state.selectedCardDetail, this.state.selectedReason)}>

                            {this.props.buttonActionName}
                          </button>
                    }
                  </div>
                </div>
              </div>
            </div>

          )
          : (
            <Redirect to='/' />
          )


        }

      </>

    )
  }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
  authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(MultiCardSmallPopUp);
