import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import store from '../../../redux/store';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../../redux/token/token.action'
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';


const LoadMoneyDetail = ({ loadAmount, surchargeAmount, totalLoadAmount, transactionDateAndTime, transactionId, transactionMode,authenticated, token, isPasswordReset }) => {


    useEffect(() => {

        document.title = "ShakePe Reversal Money Details"


    }, []);

    return (
        (authenticated)
            ? (

                isPasswordReset
                    ?
                    <div className="mid-container">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="head">
                                        <h3>Money Reversal Details</h3>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="loadtxn">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="success"> Money reversal requested successfully! </h3>
                                            </div>

                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 col-lg-4 border-top">
                                                        <div className="txnvalue">
                                                            <p>Reversal Amount</p>
                                                            <h6>₹ {parseFloat(parseFloat(loadAmount) / 100).toFixed(2)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 border-top">
                                                        <div className="txnvalue">
                                                            <p>Surcharge</p>
                                                            <h6>₹ {parseFloat(parseFloat(surchargeAmount) / 100).toFixed(2)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 border-top">
                                                        <div className="txnvalue">
                                                            <p>Total Amount</p>
                                                            <h6>₹ {parseFloat(parseFloat(totalLoadAmount) / 100).toFixed(2)}</h6>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-4 border-top">
                                                        <div className="txnvalue">
                                                            <p>Request ID</p>
                                                            <h6>{transactionId}</h6>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-4 border-top">
                                                        <div className="txnvalue">
                                                            <p> Date & Time</p>
                                                            <h6>{transactionDateAndTime}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 border-top">
                                                        <div className="txnvalue">
                                                            <p>Reversal Mode</p>
                                                            <h6>{transactionMode}</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                            <div className="col-12 col-md-9 border-top pt-4 pb-4">
                                                <a href="/load-money" className="lbtn btnbl"> Add Funds </a>

                                            </div>

                                            <div className="col-12 col-md-3 border-top">
                                                <div className="txn-btn mt-4">
                                                    {/* <button type="button" className="print" data-toggle="tooltip" title="Print">Print</button>
								<button type="button" className="share" data-toggle="tooltip" title="Share">Share</button>
								<button type="button" className="help" data-toggle="tooltip" title="Support">Support</button> */}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    :
                    <ResetLoginPassword />

            )
            : (
                <Redirect to='/' />
            )

    );


};

const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, loadMoney: { loadAmount, surchargeAmount, totalLoadAmount, transactionDateAndTime, transactionId, transactionMode } }) => ({
    authenticated, token, isPasswordReset, loadAmount, surchargeAmount, totalLoadAmount, transactionDateAndTime, transactionId, transactionMode
});


export default connect(mapStateToProps)(LoadMoneyDetail);
