import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import '../css/dashboard-reports.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import GenerateRequestId from '../../common-function/GenerateRequestId';
import apiCallService from '../../service/api-call-service';
import CategoryLimit from '../../constants/category-limit.type';
import Pagination from 'react-js-pagination';
import CsvDownload from 'react-json-to-csv';
import Select from "react-select";
import { createThisTypeNode } from 'typescript';
import moment from 'moment';
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import LoadingComponent from './loading/ta-report.loading.component';
import Can from '../../auth/can/Can';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

const collectedTrueKeys = {
    transactionType: []
}

var ToDateVar = '';
var FromDateVar = '';

// REACT_APP_GET_TA_REPORT=http://3.6.104.48:7003/rbl/wallet/reportcd

// REACT_APP_TA_REPORT_COUNT=50

class TAReport extends React.Component {

    constructor() {
        super();

        this.state = {


            loading: true,

            transactionData: [],
            empDetail: [],
            totalAmount: 0,
            currentPage: 1,
            postsPerPage: 18,
            size: 0,
            searchField: "",

            filteredRecord: [],
            multiValueType: [],
            multiValueRecord: [],
            multiValueStatus: [],
            toDate: '',
            fromDate: '',
            paginatedList: [],
            allValuesForFilter: [],


            placeHolder: "...",
            multiValueRecords: [
                { value: "debit", label: "Debit" },
                { value: "credit", label: "Credit" }
            ]
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe TA Report"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "active",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "",
            adminReportCLass: ""
        }

        store.dispatch(setActiveHeader(payload));

        console.log("inside there in ta report class")

        this.getTaReport();

    }

    getTaReport = async () => {
        const url = process.env.REACT_APP_GET_TA_REPORT;

        var request = {
            "request": {
                "user": {
                    "username": `91${this.props.roMdn}`
                },
                "transaction_data": {
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "count": parseInt(process.env.REACT_APP_TA_REPORT_COUNT),
                    "page_index": 0
                }
            }
        }


        var response = await apiCallService.postMethodCall(url, request);

        console.log("TA REPORT RESPONSE", request, response);
        if (response !== undefined && response.response_code === "2206" && response.debitCreditReport !== undefined) {
            this.setState({ transactionData: response.debitCreditReport })
            this.setState({ filteredRecord: response.debitCreditReport })
            this.setState({ loading: false })
        } else {
            this.setState({ loading: false })
        }
    }





    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

    };


    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");


            let headers = {
                "client_id": "OMTS_77B",
                "token": this.props.token,
                "Authorization": this.props.token
            }

            const url = process.env.REACT_APP_GET_TA_REPORT



            var request = {
                "request": {
                    "user": {
                        "username": `91${this.props.roMdn}`
                    },
                    "transaction_data": {
                        "request_id": GenerateRequestId.geneRateRequestId(),
                        "from_date": `${formatFromDate} 00:00:00`,
                        "to_date": `${formatToDate} 23:59:59`,
                    }
                }
            }

            var response = await apiCallService.postMethodCall(url, request, headers);

            console.log("TA REPORT RESPONSE", request, response);
            if (response !== undefined && response.response_code === "2206" && response.debitCreditReport !== undefined) {

                this.setState({ filteredRecord: response.debitCreditReport })
                this.setState({ currentPage: 1 })
            } else {
                this.setState({ filteredRecord: [] })
            }


        }
    }



    handleMultiRecordchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueRecord: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.transactionType = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.transactionType.push(op.value)

            })
        }

    }


    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.transactionData })
        this.setState({ multiValueRecord: [] })

        ToDateVar = '';
        FromDateVar = '';

        this.setState({ allValuesForFilter: [] })
        collectedTrueKeys.transactionType = []
        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)
    }


    render() {

        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };

        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };

        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;



        var filteredPost = [];

        if ((this.state.filteredRecord === null || !(this.state.filteredRecord !== undefined && this.state.filteredRecord.length && this.state.filteredRecord))) {
            filteredPost = this.state.filteredRecord
            // filteredPost = filteredList;
            console.log('inside if of bandlistToView', this.state.filteredRecord)
        } else if (this.state.filteredRecord.length !== 0) {

            filteredPost = multiPropsFilter(this.state.filteredRecord, collectedTrueKeys);

            console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)

        }





        const key = 'date';

        const filteredPostData = filteredPost





        var size = filteredPostData.length;
        console.log('size dateWiseList', size);






        if (filteredPostData === null || filteredPostData === undefined) {
            currentPost = null
        } else {
            currentPost = filteredPostData.slice(indexOfFirstPost, indexOfLastPost)
        }




        var detail = true;
        if (currentPost.length === 0) {
            detail = false
        }


        var fileToExport = [];
        var j = 0;
        filteredPostData.map(data => {
            var obj = {
                Total_Balance: "",
                Transaction_Date: "",
                Transaction_Type: ""
            }

            obj.Total_Balance = parseFloat(parseFloat(data.amount).toFixed(2) / 100).toFixed(2);
            obj.Transaction_Date = data.date;

            {
                data.transactionType === "credit" ?
                    obj.Transaction_Type = "Credit"
                    :
                    obj.Transaction_Type = "Debit"
            }


            fileToExport.push(obj);

        })




        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            var startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        var dateFromMax = moment(this.state.toDate);
        dateFromMax = dateFromMax.format("YYYY-MM-DD");

        var heightAdjust = window.innerHeight - 230

        return (
            < >
                {(this.props.authenticated)
                    ? (
                        this.props.isPasswordReset
                            ?

                            <Can
                                role={this.props.userRole}
                                perform="subheader-reports:visit"
                                roleData={this.props.roleData}
                                yes={() => (
                                    <>

                                        <div className="mid-container">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="head">
                                                            <div className="head-right">
                                                                <div className="dropdown float-right" data-toggle="tooltip" title="Filter">
                                                                    <a href="#" className="fil-btn" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Filter</a>
                                                                    <form>
                                                                        <div className="dropdown-menu dropdown-menu-right">

                                                                            <div className="filters">
                                                                                <label for="select-state">Type:</label>

                                                                                <Select

                                                                                    name="multiTypeValue"
                                                                                    placeholder={this.state.placeHolder}
                                                                                    value={this.state.multiValueRecord}
                                                                                    options={this.state.multiValueRecords}
                                                                                    onChange={this.handleMultiRecordchange}
                                                                                    isMulti={true}
                                                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                                    styles={customStyles}
                                                                                />
                                                                            </div>

                                                                            <div className="filters">
                                                                                <label htmlFor="select-state">From Date:</label>
                                                                                <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                            </div>

                                                                            <div className="filters">
                                                                                <label htmlFor="select-state">To Date:</label>
                                                                                <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                            </div>

                                                                            <div className="fbtn">
                                                                                <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                            <h3>{process.env.REACT_APP_VAR_FOR_TA_Report}</h3>
                                                        </div>
                                                    </div>




                                                    {
                                                        this.state.loading
                                                            ?
                                                            <LoadingComponent />
                                                            :


                                                            currentPost.length > 0
                                                                ?

                                                                <div className="col-12 ">
                                                                    <div style={{ minHeight: heightAdjust }}>
                                                                        <div className="row">
                                                                            {
                                                                                currentPost.map(data => (
                                                                                    <div className="col-12 col-md-6 col-xl-4">
                                                                                        <div className="txncard">
                                                                                            <div className="row">
                                                                                                <div className="col-7 col-md-6">
                                                                                                    <div className="txntype">
                                                                                                        <i className={data.transactionType} data-toggle="tooltip" title={data.transactionType}></i>
                                                                                                        {data.transactionType === "credit" ?
                                                                                                            <h5>{process.env.REACT_APP_TA_REPORT_PAGE_VAR_FOR_TA} - Credit</h5>
                                                                                                            :

                                                                                                            <h5>{process.env.REACT_APP_TA_REPORT_PAGE_VAR_FOR_TA} - Debit</h5>
                                                                                                        }


                                                                                                        <p>{data.date}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-5 col-md-6">
                                                                                                    <div className="taamount">
                                                                                                        <h4>₹ {parseFloat(parseFloat(data.amount).toFixed(2) / 100).toFixed(2)}</h4>
                                                                                                        <a className="sbtn btnbl" href={`/view-ta?dateRang=${data.date}&type=${data.transactionType}`}>{process.env.REACT_APP_VAR_FOR_TA_Report_View_Button}</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                ))
                                                                            }</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="col-12">
                                                                    <div className="no-record">
                                                                        <i></i>
                                                                        <h3> No Record Available.</h3>
                                                                    </div>
                                                                </div>

                                                    }



                                                    <div className="w-100 mt-3"></div>


                                                    {parseInt(filteredPost.length) > parseInt(this.state.postsPerPage)
                                                        ?
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <p>
                                                                    {!detail ? <></> :
                                                                        <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"ta-report.csv"}>Export to CSV</CsvDownload>
                                                                    }
                                Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                                            </div>

                                                            <div className="col-12 col-sm-6">
                                                                <div className="pager">
                                                                    <Pagination
                                                                        activePage={parseInt(this.state.currentPage)}
                                                                        itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                        totalItemsCount={size}
                                                                        pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                        onChange={this.paginate}
                                                                        className="pagination"
                                                                        linkClass="page-link"
                                                                        itemClass="page-item"
                                                                        nextPageText="Next"
                                                                        prevPageText="Prev"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="col-12 col-sm-6">
                                                            <p>
                                                                {!detail ? <></> :
                                                                    <CsvDownload className="mbtn btnbl" filename={"ta-report.csv"} data={fileToExport} >Export to CSV</CsvDownload>
                                                                }
                                                            </p>
                                                        </div>
                                                    }






                                                </div>
                                            </div>
                                        </div>

                                    </>
                                )}
                                no={() =>
                                    <Redirect to="/activity-log" />

                                }
                            />
                            :
                            <ResetLoginPassword />


                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset

});

export default connect(mapStateToProps)(TAReport);