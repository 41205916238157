import { DivShowAndHideTypes } from './div-show-and-hide.types';

const INITIAL_STATE = {
    showCreateNewOderSection: true,
    showReviewOderSection: false,
    showDetailOderSection: false,
    showAvailableCardSection: false,
    renderTransactionHistory: true,
    showKycDetailSuccessDiv: false,
    fromDashboard:false,
    showCardDetailSection:true

};

const divShowAndHideReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DivShowAndHideTypes.SHOW_CREATE_NEW_ODER_SECTION:
            return {
                ...state,
                showCreateNewOderSection: true,
                showReviewOderSection: false,
                showDetailOderSection: false,
                showAvailableCardSection: false,
                showCardDetailSection:false
            };
        case DivShowAndHideTypes.SHOW_REVIEW_ODER_SECTION:
            return {
                ...state,
                showCreateNewOderSection: false,
                showReviewOderSection: true,
                showDetailOderSection: false,
                showAvailableCardSection: false
            };
        case DivShowAndHideTypes.SHOW_DETAIL_ODER_SECTION:
            return {
                ...state,
                showCreateNewOderSection: false,
                showReviewOderSection: false,
                showDetailOderSection: true,
                showAvailableCardSection: false
            };
        case DivShowAndHideTypes.SHOW_AVAILABLE_CARD_SECTION:
            return {
                ...state,
                showCreateNewOderSection: false,
                showReviewOderSection: false,
                showDetailOderSection: false,
                showAvailableCardSection: true,
                showCardDetailSection:true
            };

        case DivShowAndHideTypes.SHOW_RENDER_TRANSACTION_HISTORY:
            return {
                ...state,
                renderTransactionHistory: action.payload
            }

        case DivShowAndHideTypes.SHOW_KYC_DETAIL_SUCESS_DIV:
            return {
                ...state,
                showKycDetailSuccessDiv: action.payload
            }
        case DivShowAndHideTypes.SET_FROM_DASHBOARD:
            return{
                ...state,
                fromDashboard:action.payload
            }
            case DivShowAndHideTypes.SET_SHOW_CARD_DETAIL_SECTION:
                return{
                    ...state,
         showCardDetailSection:action.payload
        }

        default:
            return state;
    }
};

export default divShowAndHideReducer;