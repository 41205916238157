import React from "react";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ApiCalling from '../../service/api-call-service';
import GenerateRequestId from '../../common-function/GenerateRequestId';
import { connect } from "react-redux";
import Can from '../../auth/can/Can';
import moment from "moment";
import { setEmployeeDetails } from "../../redux/user/user.actions";
import { store } from "../../redux/store";
import history from '../../auth/history'
class ManageCategoryLimit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryValue: '',
            isHidden: false,
            disabled: true,
            dropDownHidden: false,
            message: '',
            employee: this.props.employee,
            severty: '',
            loading: false,
            form: true
        }
        console.log("props inside constructor manage category", this.props)
    }




    onHandleClick = async (event) => {
        console.log('isNaN(this.state.categoryValue)', this.state.categoryValue.includes('..'), " : ", this.state.categoryValue)

        console.log('Testing-onHandleClick :' + this.state.categoryValue)
        console.log('Inside ManageCategory', this.props)
        const detail = this.props.employee;
        const { emp_id, mdn, first_name, imageUrl, last_name, gender, email, card_staus, pan, band, department, aadhar_no, manager_emp_corp_id,
            roleName, finance_emp_corp_id, location, pincode, dob, date_of_joining } = detail

        var walletType = this.props.wallet;
        var wallet_id = '';
        var category = '';

        this.props.employee.wallet.map(wallet => {

            const walletid = wallet.wallet_id;
            console.log(walletid, " ", walletType)
            console.log("wallet.wallet_id==walletType", walletid === (walletType))
            if (walletid === (walletType)) {
                wallet_id = wallet.wallet_id
            }

        })

        console.log(" ,(wallet_id===2)", (wallet_id === 2))
        if (wallet_id === 1) {
            category = process.env.React_APP_MANAGECOTOGORY_LIMIT_LABEL_1
        }
        else if (wallet_id === 2) {
            category = process.env.React_APP_MANAGECOTOGORY_LIMIT_LABEL_2
        } else if (wallet_id === 3) {
            category = process.env.React_APP_MANAGECOTOGORY_LIMIT_LABEL_3
        }
        var wallet_id = 0;
        var amount = '';
        if (category === 'Food') {
            wallet_id = 2;
            amount = this.state.categoryValue;
        } else if (category === 'General') {
            wallet_id = 1;
            amount = this.state.categoryValue;
        } else if (category === 'Travel') {
            wallet_id = 3;
            amount = this.state.categoryValue;
        }

        var response;
        var dateOfBirth = moment(dob).format("YYYY/MM/DD");
        var req_id = GenerateRequestId.geneRateRequestId();
        const RequestToSend = {
            "request": {
                "request_id": req_id,
                "employee": {
                    "corp_id": this.props.roMdn,
                    "emp_id": emp_id,
                    "mdn": mdn,
                    "first_name": first_name,
                    "last_name": last_name,
                    "email": email,
                    "pan": pan,
                    "band": band,
                    "department": department,
                    "location": location,
                    "pincode": pincode,
                    "dob": dateOfBirth,
                    "gender": gender,
                    "roleName": roleName,
                    "date_of_joining": date_of_joining,
                    "created_by": this.props.currentUser,

                    "imageUrl": imageUrl,
                    "card_staus": card_staus,
                    "manager_emp_corp_id": manager_emp_corp_id,
                    "finance_emp_corp_id": finance_emp_corp_id,
                    "modify_by": this.props.currentUser,
                    "previousEmail": email,
                    "wallet": [
                        {
                            "wallet_id": wallet_id,
                            "amount": amount * 100
                        }
                    ]
                }
            }
        }

        console.log('request for limit ', RequestToSend);
        const url = process.env.REACT_APP_UPDATE_EMPLOYEE;
        this.setState({ loading: true })
        response = await ApiCalling.postMethodCall(url, RequestToSend);

        console.log('Update Api response', response)
        console.log("data for cntion ", response.status === 'FAILURE')
        if (!(response.status === undefined) && response.status.toUpperCase() === 'FAILURE') {
            console.log('inside severty check')
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true });
            // this.setState({ isHidden: false });
            this.setState({ loading: false });
            this.setState({ message: response.response_description });
        } else if (!(response.response === undefined) && response.response.response_message.toLowerCase() === 'success') {
            this.setState({ dropDownHidden: true });
            this.setState({ isHidden: false });
            this.setState({ severty: "success" })
            this.setState({ message: "Limit set successfully" });
            this.setState({ disabled: true })
            this.setState({ loading: false });
            this.setState({ categoryValue: amount })

            var dataCat = RequestToSend.request.employee;
            var wal = []
            this.props.employee.wallet.map(wallet => {

                const walletid = wallet.wallet_id;
                console.log(walletid, " ", walletType)
                console.log("wallet.wallet_id==walletType", dataCat)
                if (walletid === (walletType)) {
                    wallet.amount = parseInt(this.state.categoryValue) * 100
                    wal.push(wallet)
                } else {
                    wal.push(wallet)
                }

            })
            dataCat.wallet = wal;
            var aadharno = "aadhar_no";
            dataCat[aadharno] = aadhar_no;
            console.log("wallet.wallet_id==walletType dataCat", dataCat)
            store.dispatch(setEmployeeDetails(dataCat));


            history.push({
                pathname: '/emp-manage-card',
                state: { "empMobile": dataCat.mdn }
            })
            history.go();

        } else {
            this.setState({ dropDownHidden: true });
            this.setState({ isHidden: false });
            this.setState({ disabled: true });
            this.setState({ severty: "warning" });
            this.setState({ message: 'Somthing Went Wrong Please Try In a Minute Again' });
            this.setState({ loading: false });
        }

    }

    handleButtonChange = (event, amount) => {
        this.setState({ categoryValue: amount })
        this.setState({ isHidden: true })
        this.setState({ disabled: false })
        this.setState({ form: false });
        console.log('Testing-handlePasswordChange :' + amount)

    }
    handleFormSubmit = (event) => {
        event.preventDefault();
        if (this.state.form) {
            this.onHandleClick(event);
        }
    }


    render() {

        var walletType = this.props.wallet;
        console.log('walletType', this.props.employee.wallet)

        var amount = '';
        var wallet_id = '';
        var label = '';

        this.props.employee.wallet.map(wallet => {

            var walletid = wallet.wallet_id;
            console.log(walletid, " ", walletType)
            console.log("wallet.wallet_id==walletType", walletid === (walletType))
            if (walletid === (walletType)) {
                amount = wallet.amount / 100;
                wallet_id = wallet.wallet_id
            }

        })
        var maxAmount = 0

        console.log('!this.state.categoryValue===outside', !(this.state.categoryValue === ''))
        if (!(this.state.categoryValue === '')) {
            console.log('!this.state.categoryValue===inside', !(this.state.categoryValue === ''))
            amount = this.state.categoryValue;
        }
        console.log(" ,(wallet_id===2)", (wallet_id === 2))
        if (wallet_id === 1) {
            label = process.env.React_APP_MANAGECOTOGORY_LIMIT_LABEL_1
            maxAmount = this.props.maxGeneralAmount
        }
        else if (wallet_id === 2) {
            label = process.env.React_APP_MANAGECOTOGORY_LIMIT_LABEL_2
            maxAmount = this.props.maxFoodAmount
        } else if (wallet_id === 3) {
            label = process.env.React_APP_MANAGECOTOGORY_LIMIT_LABEL_3
            maxAmount = this.props.maxTravelAmount
        }


        return (
            <div className="col-12 col-sm-6 col-md-3">
                <form onSubmit={this.handleFormSubmit} className="webform">
                    <div className="form-floating">
                        {this.state.disabled
                            ?
                            <input className="form-control amunt" type="number" id=""
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                max={parseInt(maxAmount) / 100} value={amount} onChange={e => this.setState({ categoryValue: e.target.value })} disabled={this.state.disabled} required />
                            :
                            <input className="form-control amunt"
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                type="number" id="" max={parseInt(maxAmount) / 100} value={this.state.categoryValue} onChange={e => this.setState({ categoryValue: e.target.value, form: true })} disabled={this.state.disabled} required />
                        }
                        <label for="">{label}</label>
                        <Can
                            role={this.props.userRole}
                            perform="employeeDetail-manageCategory:save"
                            roleData={this.props.roleData}
                            yes={() => (

                                this.state.isHidden ?
                                    <button className="sbtn btngn" type="submit" disabled={this.state.dropDownHidden}>
                                        {!this.state.loading
                                            ?
                                            <>Save</>
                                            :
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        }
                                    </button>
                                    :
                                    <button className="sbtn btnbl" onClick={event => this.handleButtonChange(event, amount)}>Edit</button>

                            )}
                            no={() =>
                                null

                            }
                        />

                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={this.state.dropDownHidden}
                            autoHideDuration={3000}
                            onClose={e => this.setState({ dropDownHidden: false })}
                        >
                            <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                {this.state.message}
                            </Alert>
                        </Snackbar>
                    </div>
                </form>
            </div>


        )
    }

}

const mapStateToProps = ({ user: { userRole }, user: { roMdn }, role: { roleData }, user: { currentUser } }) => ({
    roMdn,
    currentUser,
    userRole,
    roleData
});





export default connect(mapStateToProps)(ManageCategoryLimit);