import OrderTypes from './order.types';


export const setCardQuantity = (quan) => ({
    type: OrderTypes.SET_CARD_QUANTITY,
    payload: quan
});

export const setFullNameOder = (name) => ({
    type: OrderTypes.SET_FULL_NAME,
    payload: name
});

export const setMobileNumber = (num) => ({
    type: OrderTypes.SET_MOBILE_NUMBER,
    payload: num
});

export const setAddressOne = (add) => ({
    type: OrderTypes.SET_ADDRESS_ONE,
    payload: add
});

export const setAddressTwo = (add) => ({
    type: OrderTypes.SET_ADDRESS_TWO,
    payload: add
});

export const setState = (state) => ({
    type: OrderTypes.SET_STATE,
    payload: state
});

export const setCity = (city) => ({
    type: OrderTypes.SET_CITY,
    payload: city
});
export const setPinCode = (pin) => ({
    type: OrderTypes.SET_PINCODE,
    payload: pin
});

export const setSameAddressCheckbox = (checked) => ({
    type: OrderTypes.SET_SAME_ADDRESS_CHECKBOX,
    payload: checked
});


export const setBillingAddress = (checked) => ({
    type: OrderTypes.SET_BILLING_ADDRESS,
    payload: checked
});

export const setShippingAddress = (checked) => ({
    type: OrderTypes.SET_SHIPPING_ADDRESS,
    payload: checked
});

export const setShippingPersonName = (checked) => ({
    type: OrderTypes.SET_SHIPPING_PERSON_NAME,
    payload: checked
});

export const setShippingPersonMDN = (checked) => ({
    type: OrderTypes.SET_SHIPPING_PERSON_MDN,
    payload: checked
});

export const setTotalAmount = (checked) => ({
    type: OrderTypes.SET_TOTAL_AMOUNT,
    payload: checked
});
export const setRatePerCard = (checked) => ({
    type: OrderTypes.SET_RATE_PER_CARD,
    payload: checked
});
export const setGstPercentage = (checked) => ({
    type: OrderTypes.SET_GST_PERCENTAGE,
    payload: checked
});
export const setGstAmount = (checked) => ({
    type: OrderTypes.SET_GST_AMOUNT,
    payload: checked
});

export const setOrderId = (value) => ({
    type: OrderTypes.SET_ORDER_ID,
    payload: value
});

export const setOrderDate = (value) => ({
    type: OrderTypes.SET_ORDER_DATE,
    payload: value
});

export const setOrderBack = (value) => ({
    type: OrderTypes.SET_ORDER_BACK,
    payload: value
});

export const setDistrictBilling = (value) => ({
    type: OrderTypes.SET_DISTRICT_BILLING,
    payload: value
});


export const setDistrictShip = (value) => ({
    type: OrderTypes.SET_DISTRICT_SHIP,
    payload: value
});

export const setCardType = (value) => ({
    type: OrderTypes.SET_CARD_TYPE,
    payload: value
});


export const setTotalAmountWistGst = (value) => ({
    type: OrderTypes.SET_TOATAL_AMOUNT_WITH_GST,
    payload: value
});

export const resetOder = {
    type: OrderTypes.RESET_ORDER
}