import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import CardStatus from '../../../constants/card-status.type';
import CsvDownload from 'react-json-to-csv';
import Select from "react-select";
import moment from 'moment'
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { resetOder } from "../../../redux/order/order.action";
import { setEmployeeDetails } from "../../../redux/user/user.actions";

import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import LoadingComponent from '../loading/assigned.loading.component';
import SubAssignedCards from './sub-assigned-card';


const collectedTrueKeys = {
    band: [],
    status: [],
    updatedBy: []
}
var ToDateVar = '';
var FromDateVar = '';
var listForAction = [];

var currentPost = '';
class AssignedCards extends React.Component {


    constructor() {
        super();

        this.state = {

            loading: true,

            roId: '',

            cardList: [],
            currentPage: 1,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_ASSIGNED_EMPLOYEE_RECORD,

            dropDownHidden: false,
            message: '',
            severty: '',

            masterChecked: false,
            checkedPost: [],

            allValuesForFilter: [],
            filteredRecord: [],
            searchField: '',

            toDate: '',
            fromDate: '',
            placeHolder: '...',
            multiValueB: [],
            multiValueS: [],
            multiValueR: [],
            multiValueStatus: [
                { value: "0", label: "Active" },
                { value: "2", label: "Inactive" },

            ],
            approvedByMultiValue: []

        };
    }


    componentDidMount = () => {
        var arr = [];


        document.title = "ShakePe Assigned Cards"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        var roidToset = this.props.roMdn;
        if (this.props.location.state !== undefined && this.props.location.state.roId !== undefined) {
            roidToset = this.props.location.state.roId
            console.log('location.state.roId', this.props.location.state.roId, " :  ", this.props.roMdn)
        }


        this.setState({ roId: roidToset })


        store.dispatch(setActiveHeader(payload));
        store.dispatch(resetOder)
        const fetData = async () => {
            var status = [];
            status.push(CardStatus.ACTIVE)
            status.push(CardStatus.ASSIGNED)
            var url = `${process.env.REACT_APP_GET_CARD_EMPLOYEE_DETAIL_BY_ROID_AND_STATUS}?roId=${roidToset}&status=${status}`

            var response = await apiCallService.getMethodCallWithoutParam(url);

            console.log('response history transaction', url, response);

            var approvedBy = []
            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {

                response.disbursalData.map(data => {
                    var isChecked = 'isChecked';
                    data[isChecked] = false;
                    arr.push(data);

                    data.band = data.employeeDetail.band
                    approvedBy.push(data.updatedBy);
                })
                var actualMap = []
                var uniq = approvedBy.filter(this.onlyUnique);
                uniq.map(un => {
                    var detail = {
                        value: "",
                        label: ""
                    }
                    detail.label = un;
                    detail.value = un;
                    actualMap.push(detail);
                })

            }

            this.setState({
                cardList: arr,
                filteredRecord: arr,
                approvedByMultiValue: actualMap,
                loading: false
            })
            // setCardList(arr);
            // setFilteredRecord(arr);
            // setApprovedByMultiValue(actualMap);
            // setLoading(false);
        }
        fetData();


    }


    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    setSearch = (e) => {
        this.setState({
            searchField: e.target.value,
            currentPage: 1
        })
        // setSearchField(e.target.value);
        // setCurrentPage(1)
    }

    handleMultiBandchange = (option) => {

        this.setState({
            multiValueB: option,
            currentPage: 1
        })
        // setMultiValueB(option)
        // setCurrentPage(1);
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({
                allValuesForFilter: option

            })
            // setAllValuesForFilter(option)
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({
                allValuesForFilter: data

            })
            // setAllValuesForFilter(data);
        }

        collectedTrueKeys.band = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.band.push(op.value)

            })
        }
    }


    handleStatuschange = (option) => {

        this.setState({
            multiValueS: option,
            currentPage: 1
        })
        // setMultiValueS(option)
        // setCurrentPage(1);
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option })
            // setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            this.setState({ allValuesForFilter: data })
            // setAllValuesForFilter(data)
        }

        collectedTrueKeys.status = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.status.push(op.value);
            })
        }
    }


    handleApprovedChangechange = (option) => {

        this.setState({
            multiValueR: option,
            currentPage: 1
        })
        // setmultiValueR(option)
        // setCurrentPage(1);
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option })
            // setAllValuesForFilter(option)
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data })
            // setAllValuesForFilter(data);
        }

        collectedTrueKeys.updatedBy = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.updatedBy.push(op.value)

            })
        }
    }


    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;

        if (name === 'toDate') {
            this.setState({ toDate: value });
            // setToDate(value)

            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            // setFromDate(value)

            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD hh:mm:ss");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD hh:mm:ss");
            var status = [];
            // status.push(CardStatus.ACTIVE)
            // status.push(CardStatus.ASSIGNED)

            const requestToSend = {
                "roMdn": this.state.roId,
                "fromDate": formatFromDate,
                "toDate": formatToDate,
                "status": [
                    {
                        "status": CardStatus.ACTIVE
                    },
                    {
                        "status": CardStatus.ASSIGNED
                    }

                ]
            }


            const url = process.env.REACT_APP_GET_ASSIGNED_CARD_BY_DATERANGE;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('response', response)

            if (!(response === undefined) && (response.response_code === 0)) {

                var aty = []
                response.disbursalData.map(data => {
                    var isChecked = 'isChecked';
                    data[isChecked] = false;
                    aty.push(data);


                })
                this.setState({ currentPage: 1 })
                this.setState({ filteredRecord: aty })
                // setCurrentPage(1);
                // setFilteredRecord(aty)

            } else {
                this.setState({ filteredRecord: [] })
                // setFilteredRecord([])

            }

        }
    }


    clearAll = () => {

        this.setState({
            multiValueS: [],
            multiValueB: [],
            multiValueR: [],
            filteredRecord: this.state.cardList,
            toDate: '',
            fromDate: ''
        })
        // setMultiValueS([])
        // setMultiValueB([])
        // setmultiValueR([])

        // setFilteredRecord(cardList);

        // setToDate('')
        // setFromDate('')

        ToDateVar = ''
        FromDateVar = ''

        collectedTrueKeys.updatedBy = []
        collectedTrueKeys.status = []
        collectedTrueKeys.band = [];

        this.setState({
            allValuesForFilter: []
        })
        // setAllValuesForFilter([])
    }




    handleMasterChecked = (event) => {
        this.setState({ masterChecked: event.target.checked })
        // setMasterChecked(event.target.checked);
        this.state.filteredRecord.map(posts => {
            console.log('inside if handleMasterChecked', posts);
            if (event.target.checked) {
                posts.isChecked = event.target.checked;
                listForAction.push(posts)
            } else if (!event.target.checked) {
                console.log('inside else handleMasterChecked');
                posts.isChecked = event.target.checked;
                listForAction.pop(posts)
            }
        })
        this.setState({ checkedPost: currentPost })
        // setCheckedPost(currentPost)

    }



    handleChecked = (event) => {
        var data = listForAction
        console.log(data.lenght, "and", this.state.postsPerPage)
        if (data.lenght !== this.state.postsPerPage) {
            // setMasterChecked(false)
            this.setState({ masterChecked: false })
        }
        data.map(list => {
            listForAction.push(list);
        })
        this.state.filteredRecord.map(post => {
            console.log('post.emp_id === event.target.id && event.target.checked === true', post.band === event.target.name && event.target.checked === false)
            if (post.requestId === event.target.id && event.target.checked === true) {
                console.log('inside if handleChecked');
                console.log('post to be performed action is : ', post)
                post.isChecked = event.target.checked
                listForAction.push(post)
                console.log('list to action performed correct', listForAction);
            } else if (post.requestId === event.target.id && event.target.checked === false) {
                console.log('removed post from action performed', post)
                listForAction.pop(post)
                this.setState({ masterChecked: false })
                // setMasterChecked(false)
                post.isChecked = event.target.checked
                console.log('list to action performed wrong', listForAction);
            }
        })
        this.setState({ checkedPost: currentPost })
        // setCheckedPost(currentPost)
    }



    //Change Page
    paginate = (pageNumber) => {

        console.log("this is calling but why not impacting")
        this.setState({ loading: true })
        this.setState({ currentPage: pageNumber })
        this.setState({ loading: false })
    }

    render() {




        //console.log(posts);

        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;




        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };

        var filteredPostData = [];

        if ((this.state.allValuesForFilter === null || !(this.state.allValuesForFilter !== undefined && this.state.allValuesForFilter.length && this.state.allValuesForFilter))) {
            filteredPostData = this.state.filteredRecord
            // filteredPost = filteredList;
            console.log('inside if of bandlistToView', this.state.allValuesForFilter)
        } else if (this.state.allValuesForFilter.length !== 0) {

            filteredPostData = multiPropsFilter(this.state.filteredRecord, collectedTrueKeys);

            console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



        }

        const key = 'cardRefNumber';

        const filteredPost = [...new Map(filteredPostData.map(item =>
            [item[key], item])).values()];


        var searchedRecord = [];


        if (this.state.searchField !== '' && this.state.cardList !== undefined) {
            this.state.cardList.map(post => {
                if (post.employeeName !== undefined && post.employeeName !== null && post.employeeName.toLowerCase().includes(this.state.searchField.toLowerCase())) {
                    searchedRecord.push(post)
                } else if (post.employeeId !== undefined && post.employeeId !== null && post.employeeId.includes(this.state.searchField)) {
                    searchedRecord.push(post)
                } else if (post.mdn !== undefined && post.mdn !== null && post.mdn.includes(this.state.searchField)) {
                    searchedRecord.push(post)
                }
            })
        }

        var size = 0

        if (this.state.searchField !== '') {
            size = searchedRecord.length
            currentPost = searchedRecord.slice(indexOfFirstPost, indexOfLastPost)
        } else
            if (filteredPost === null || filteredPost === undefined) {
                currentPost = null
            } else {
                size = filteredPost.length
                currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
            }

        console.log('currentpost', currentPost);
        console.log("current post", this.state.cardList);


        var fileToExport = [];
        this.state.cardList.map(data => {
            var obj = {
                Employee_Name: "",
                Employee_Id: "",
                Mobile_No: "",
                Band: "",
                Card_Ref_No: "",
                Assigned_By: "",
                Assigned_On: "",
                Status: ""
            }
                obj.Employee_Name = data.employeeName;
                obj.Employee_Id = data.employeeId;
                obj.Mobile_No = data.mdn;
                obj.Band = data.band;
                obj.Card_Ref_No = data.cardRefNumber;
                obj.Assigned_By = data.updatedBy;
                obj.Assigned_On = data.dateUpdatedOn;
                obj.Status = data.statusVariable
                fileToExport.push(obj);

        })

        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };

        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            var startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        var dateFromMax = moment(this.state.toDate);
        dateFromMax = dateFromMax.format("YYYY-MM-DD");

        var height = window.innerHeight - 300


        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>

                                <div className="mid-container">
                                    <div className="container-fluid">


                                        <Snackbar

                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            open={this.state.dropDownHidden}
                                            autoHideDuration={3000}
                                            onClose={e => this.setState({ dropDownHidden: false })}
                                        >
                                            <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                {this.state.message}
                                            </Alert>
                                        </Snackbar>
                                        <div className="head">
                                            <div className="row">
                                                <div  className="col-6 col-md-4 order-md-1">
                                                    {/* <h3>

                                                        <input type="checkbox" id="" className="checkall" checked={this.state.masterChecked} onClick={this.handleMasterChecked} data-toggle="tooltip" title="Select All" />

                                                        Assigned Cards</h3> */}
                                                </div>

                                                <div  className="col-6 col-md-4 order-md-3">
                                                    <div className="flterbox">
                                                        <div className="dropdown " data-toggle="tooltip" title="Filter">
                                                            <button  className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                {/* <i className="closefil">x</i> */}

                                                                <div className="filters">
                                                                    <label htmlFor="select-state">Assigned By:</label>
                                                                    <Select
                                                                        name="filters"
                                                                        placeholder={this.state.placeHolder}
                                                                        value={this.state.multiValueR}
                                                                        options={this.state.approvedByMultiValue}
                                                                        onChange={this.handleApprovedChangechange}
                                                                        isMulti={true}
                                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>

                                                                <div className="filters">
                                                                    <label htmlFor="select-state">Card Status:</label>
                                                                    <Select
                                                                        name="filters"
                                                                        placeholder={this.state.placeHolder}
                                                                        value={this.state.multiValueS}
                                                                        options={this.state.multiValueStatus}
                                                                        onChange={this.handleStatuschange}
                                                                        isMulti={true}
                                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>

                                                                <div className="filters">
                                                                    <label htmlFor="select-state">Band:</label>
                                                                    <Select
                                                                        name="filters"
                                                                        placeholder={this.state.placeHolder}
                                                                        value={this.state.multiValueB}
                                                                        options={this.props.bandData}
                                                                        onChange={this.handleMultiBandchange}
                                                                        isMulti={true}
                                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>


                                                                <div className="filters">
                                                                    <label htmlFor="select-state">From Date:</label>
                                                                    <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                </div>

                                                                <div className="filters">
                                                                    <label htmlFor="select-state">To Date:</label>
                                                                    <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                </div>

                                                                <div className="fbtn">
                                                                    <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>



                                                <div className="col-12 col-md-4 order-md-2">
                                                    <div className="searchdata">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" id="empsearch" name="search" placeholder="Search  Employee..." onChange={this.setSearch} />

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="emp-list">

                                            <div className="row">

                                                {

                                                    this.state.loading ?
                                                        <LoadingComponent />
                                                        :
                                                        currentPost.length > 0 ?

                                                            currentPost.map(employee => (


                                                                <SubAssignedCards setEmployee={this.props.setEmployee} post={employee} handleChecked={this.handleChecked} show={parseInt(this.props.userMaintain) === 1} iscalledfromassigncard={true} />


                                                            ))

                                                            :
                                                            <div className="col-12">
                                                                <div className="no-record">
                                                                    <i></i>
                                                                    <h3> No Record Available.</h3>
                                                                </div>
                                                            </div>



                                                }


                                                <div className="col-12"></div>

                                                {parseInt(size) > parseInt(this.state.postsPerPage)
                                                    ?
                                                    <>
                                                        <div className="col-12 col-sm-6">
                                                            <p>
                                                                {
                                                                // csvShow ?

                                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"assigned-employee.csv"}>Export to CSV</CsvDownload>
                                                                    // : null
                                                                }

                                                                Showing {valueToShow} to {(indexOfLastPost > size) ? size : indexOfLastPost} of {size} entries</p>
                                                        </div>

                                                        <div className="col-12 col-sm-6">
                                                            <div className="pager">
                                                                <Pagination
                                                                    activePage={parseInt(this.state.currentPage)}
                                                                    itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                    totalItemsCount={size}
                                                                    pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                    onChange={this.paginate}
                                                                    className="pagination"
                                                                    linkClass="page-link"
                                                                    itemClass="page-item"
                                                                    nextPageText="Next"
                                                                    prevPageText="Prev"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    filteredPost.length === 0
                                                        ?
                                                        null
                                                        :

                                                        <div className="col-12 col-sm-6">
                                                            <p>

                                                                {
                                                                // csvShow ?
                                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"assigned-employee.csv"}>Export to CSV</CsvDownload>
                                                                    // : null
                                                                }
                                                            </p>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}



const mapStateToProps = ({ band: { bandData }, token: { authenticated, isPasswordReset }, user: { userRole, roMdn }, role: { roleData, userMaintain } }) => ({
    authenticated, userRole, roleData, roMdn, bandData, isPasswordReset, userMaintain
});

const mapDispatchToProps = dispatch => ({
    setEmployee: emp => dispatch(setEmployeeDetails(emp))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedCards);