import React from "react";
import '../css/employee.css';
import GenerateRequestId from '../../common-function/GenerateRequestId';
import ApiCalling from '../../service/api-call-service';
import { connect } from "react-redux";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Redirect } from 'react-router-dom';
import history from '../../auth/history';




class UpdateEmployee extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            mdn: '',
            email_id: '',
            dob: '',
            pan: '',
            aadhaar: '',
            male: false,
            female: false,
            other: false,
            general: false,
            food: false,
            travel: false,
            employee_id: '',
            reporting_manager_emp_id: '',
            finance_admin_emp_id: '',
            department_name: '',
            employee_band: '',
            date_of_joining: '',
            location: '',
            pincode: '',
            category: '',
            role_name: '',
            dropDownHidden: false,
            message: '',
            severty: 'success',
            address:'',

        }
    }


    componentDidMount = () => {

        console.log('inside componentDidMount for update check', this.props);
        const detail = this.props.location.state;
        console.log(detail);
        if (detail !== undefined) {
            const { emp_id, mdn, first_name, last_name, gender, email, pan, band, department, manager_emp_corp_id, finance_emp_corp_id,address,
                roleName, location, pincode, dob, date_of_joining, aadhar_no, wallet } = detail
            this.setState({ first_name: first_name })
            this.setState({ last_name: last_name })
            console.log('mdn', mdn)
            this.setState({ mdn: mdn })
            this.setState({ email_id: email })
            this.setState({ dob: dob })
            console.log('date', dob)
            this.setState({ pan: pan })
            this.setState({ aadhaar: aadhar_no })
            this.setState({ role_name: roleName })
            if (gender.toLowerCase() === 'male') {
                this.setState({ male: true })
            } else if (gender.toLowerCase() === 'female') {
                this.setState({ female: true })
            } else {
                this.setState({ other: true })
            }
            this.setState({ employee_id: emp_id })
            this.setState({ department_name: department })
            this.setState({ employee_band: band })
            this.setState({ location: location })
            this.setState({ pincode: pincode })
            this.setState({ employee_band: band })
            this.setState({ finance_admin_emp_id: finance_emp_corp_id })
            this.setState({ reporting_manager_emp_id: manager_emp_corp_id })
            this.setState({ address:address})

            console.log('wallet state', wallet);
            console.log('wallet state', date_of_joining);
            var dateOfJoining = date_of_joining.split(' ');
            console.log('wallet state', dateOfJoining[0]);

            this.setState({ date_of_joining: dateOfJoining[0] })
            wallet.map(wallet => {
                if (wallet.wallet_id === 1) {
                    this.setState({ general: true })
                }
                if (wallet.wallet_id === 2) {
                    this.setState({ food: true })
                }
                if (wallet.wallet_id === 3) {
                    this.setState({ travel: true })
                }

            })
        }
        console.log('states', this.state);

    }


    onHandleMaleChecked = (event) => {
        console.log('Testing-onHandleMaleChecked :' + this.state.male)
        this.setState({ male: true })
        this.setState({ female: false })
        this.setState({ other: false })
        console.log('Testing-onHandleMaleChecked :' + this.state.male)
    }
    onHandleFemaleChecked = (event) => {

        console.log('Testing-onHandleFemaleChecked :' + this.state.female)
        this.setState({ male: false })
        this.setState({ female: true })
        this.setState({ other: false })
        console.log('Testing-onHandleFemaleChecked :' + this.state.female)
    }
    onHandleOtherChecked = (event) => {

        console.log('Testing-onHandleOtherChecked :' + this.state.other)

        this.setState({ male: false })
        this.setState({ female: false })
        this.setState({ other: true })
        console.log('Testing-onHandleOtherChecked :' + this.state.other)
    }

    radioChangeHandler = (name, event) => {
        this.setState({
            category: name
        })
        console.log('Testing-onHandleOtherChecked :' + this.state.category)
    }



    handleSubmit = async (event) => {

        console.log('this.state.mdn',
            ((this.state.mdn === '') ||
                (this.state.first_name === '') ||
                (this.state.last_name === '') ||
                (this.state.email_id === '') ||
                (this.state.pan === '') ||
                (this.state.employee_band === '') ||
                (this.state.department_name === '') ||
                (this.state.location === '') ||
                (this.state.pincode === '') ||
                (this.state.dob === '') ||
                (this.state.date_of_joining === '') ||
                (this.state.aadhaar === '') ||
                (this.state.employee_id === '')))

        if (
            (this.state.mdn === '') ||
            (this.state.first_name === '') ||
            (this.state.last_name === '') ||
            (this.state.email_id === '') ||
            (this.state.pan === '') ||
            (this.state.employee_band === '') ||
            (this.state.department_name === '') ||
            (this.state.location === '') ||
            (this.state.pincode === '') ||
            (this.state.dob === '') ||
            (this.state.date_of_joining === '') ||
            (this.state.aadhaar === '') ||
            (this.state.employee_id === '')

        ) {
            this.setState({ dropDownHidden: true });
            this.setState({ severty: "warning" })
            this.setState({ message: 'Please Enter All Mandetory Field' });
        }
        else {
            console.log('Testing-handleSubmit event:' + event)
            console.log('Testing-handleSubmit :' + this.state)
            var req_id = GenerateRequestId.geneRateRequestId();
            console.log(this.state.male);
            console.log(this.state.female);
            console.log(this.state.other);
            var gender = '';
            if (this.state.male) {
                gender = 'Male'
            } else if (this.state.female) {
                gender = 'Female'
            } else {
                gender = 'Other'
            }

            var wallet = [];
            var foodWallet = {
                wallet_id: '',
                amount: ''
            }
            var travelWallet = {
                wallet_id: '',
                amount: ''
            }
            var generalWallet = {
                wallet_id: '',
                amount: ''
            }
            if (this.state.food) {
                foodWallet.wallet_id = '2';
                foodWallet.amount = process.env.REACT_APP_FOOD_LIMIT;
                console.log('inside food')
                wallet.push(foodWallet);
            }
            if (this.state.general) {
                generalWallet.wallet_id = '1';
                generalWallet.amount = process.env.REACT_APP_GENERAL_LIMIT;
                console.log('inside general')
                wallet.push(generalWallet);
            }
            if (this.state.travel) {
                travelWallet.wallet_id = '3';
                travelWallet.amount = process.env.REACT_APP_TRAVEL_LIMIT;
                console.log('inside travel')
                wallet.push(travelWallet);
            }


            const RequestToSend = {
                "request": {
                    "request_id": req_id,
                    "employee": {
                        "corp_id": this.props.roMdn,
                        "emp_id": this.state.employee_id,
                        "mdn": this.state.mdn,
                        "first_name": this.state.first_name,
                        "last_name": this.state.last_name,
                        "email": this.state.email_id,
                        "pan": this.state.pan,
                        "band": this.state.employee_band,
                        "department": this.state.department_name,
                        "location": this.state.location,
                        "pincode": this.state.pincode,
                        "dob": this.state.dob,
                        "gender": gender,
                        "created_by": this.props.currentUser,
                        "date_of_joining": this.state.date_of_joining,
                        "roleName": this.state.role_name,
                        "password": null,
                        "aadhar_no": this.state.aadhaar,
                        "imageUrl": "",
                        "manager_emp_corp_id": this.state.reporting_manager_emp_id,
                        "finance_emp_corp_id": this.state.finance_admin_emp_id,
                        "modify_by": this.props.currentUser,
                        "previousEmail": this.props.location.state.email,
                        "wallet": wallet,
                        "address":this.state.address
                    }
                }
            }

            console.log(RequestToSend);
            const url = process.env.REACT_APP_UPDATE_EMPLOYEE;


            const response = await ApiCalling.postMethodCall(url, RequestToSend);
            //const response = 'failure'
            console.log('ADD EMployee Api response', response)

            if (response.status === 'failure') {
                this.setState({ dropDownHidden: true });
                this.setState({ message: response.response_description });
                this.setState({ severty: "error" })
            } else {
                this.setState({ dropDownHidden: true });
                this.setState({ isHidden: false });
                this.setState({ disabled: true })
                this.setState({ message: response.response.response_message });
                history.push('/emp-list');
                history.go();
            }
        }
    }

    handleAutoSubmit = (event) => {
        event.preventDefault();
        console.log('inside handle Auto Submit');
    }


    render() {

        console.log('after callback', this.props)
        console.log('states', this.state);

        return (
            <>
                {(this.props.authenticated)
                    ? (
                        <div className="mid-container" id="heightFix">
                            <div className="container-fluid">

                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={this.state.dropDownHidden}
                                    autoHideDuration={6000}
                                    onClose={e => this.setState({ dropDownHidden: false })}
                                >
                                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                        {this.state.message}
                                    </Alert>
                                </Snackbar>



                                <form onSubmit={this.handleAutoSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="head">{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_EmployeePersonalDetails} <span className="note">{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_Note}</span></h3>
                                        </div>


                                        <div className="col-12">
                                            <div className="add-emp-form">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">

                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                value={this.state.first_name}
                                                                onChange={e => this.setState({ first_name: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_FIRSTNAME}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                value={this.state.last_name}
                                                                onChange={e => this.setState({ last_name: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_LASTNAME}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id="mob-input"
                                                                maxLength="10"
                                                                value={this.state.mdn}
                                                                onChange={e => this.setState({ mdn: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label" htmlFor="mob-input">{process.env.React_APP_LABELFOR_MOBILENUMBER}</label>
                                                            <span className="mdl-textfield__error">{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_PleaseEnterMobileNumber}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="email"
                                                                id=""
                                                                value={this.state.email_id}
                                                                onChange={e => this.setState({ email_id: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_EMAIL}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="date"
                                                                id=""
                                                                value={this.state.dob}
                                                                onChange={e => this.setState({ dob: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_DATEOFBIRTH}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                value={this.state.pan}
                                                                onChange={e => this.setState({ pan: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_PAN}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id="adr-input"
                                                                maxLength="12"
                                                                pattern="-?[0-9]*(\.[0-9]+)?"
                                                                value={this.state.aadhaar}
                                                                onChange={e => this.setState({ aadhaar: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label" htmlFor="adr-input">{process.env.React_APP_LABELFOR_AADHARNUMBER}</label>
                                                            <span className="mdl-textfield__error">{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_PLEASEENTERAADHARNO}</span>

                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-8 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder">
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_GENDER}</label>

                                                            <div className="radio-checkbox">
                                                                <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="op1">
                                                                    <input type="radio" id="op1" className="mdl-radio__button" name="options" checked={this.state.male} onChange={this.onHandleMaleChecked} />
                                                                    <span className="mdl-radio__label">{process.env.React_APP_LABELFOR_MALE}</span>
                                                                </label>
                                                            </div>

                                                            <div className="radio-checkbox">
                                                                <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="op2">
                                                                    <input type="radio" id="op2" className="mdl-radio__button" name="options" checked={this.state.female} onChange={this.onHandleFemaleChecked} />
                                                                    <span className="mdl-radio__label">{process.env.React_APP_LABELFOR_FEMALE}</span>
                                                                </label>
                                                            </div>

                                                            <div className="radio-checkbox">
                                                                <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="op3">
                                                                    <input type="radio" id="op3" className="mdl-radio__button" name="options" checked={this.state.other} onChange={this.onHandleOtherChecked} />
                                                                    <span className="mdl-radio__label">{process.env.React_APP_LABELFOR_OTHER}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <h3 className="head">{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_EmployeeProfessionalDetails}</h3>
                                        </div>

                                        <div className="col-12">
                                            <div className="add-emp-form">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                value={this.state.employee_id}
                                                                onChange={e => this.setState({ employee_id: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_EMPLOYEEID}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input className="mdl-textfield__input" type="text" id="" value={this.state.reporting_manager_emp_id} onChange={e => this.setState({ reporting_manager_emp_id: e.target.value })} />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_ReportingManagerEmpID}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input className="mdl-textfield__input" type="text" id="" value={this.state.finance_admin_emp_id} onChange={e => this.setState({ finance_admin_emp_id: e.target.value })} />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_FinanceAdminEmpID}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                value={this.state.department_name}
                                                                onChange={e => this.setState({ department_name: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_DepartmentName}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                                                            <input type="text" value={this.state.employee_band} className="mdl-textfield__input" id="band" onChange={(e) => this.setState({ employee_band: e.target.value })} />
                                                            {/* <input type="hidden" value='' name="band" /> */}
                                                            {/* <i className="mdl-icon-toggle__label down-arrow"></i> */}
                                                            <label htmlFor="band" className="mdl-textfield__label">{process.env.React_APP_LABELFOR_EmployeeBand}</label>
                                                            <ul htmlFor="band" className="mdl-menu mdl-js-menu">
                                                                <li className="mdl-menu__item" data-val="M01" onClick={() => this.setState({ employee_band: 'M01' })}>M01</li>
                                                                <li className="mdl-menu__item" data-val="M02" onClick={() => this.setState({ employee_band: 'M02' })}>M02</li>
                                                                <li className="mdl-menu__item" data-val="M03" onClick={() => this.setState({ employee_band: 'M03' })}>M03</li>
                                                                <li className="mdl-menu__item" data-val="M04" onClick={() => this.setState({ employee_band: 'M04' })}>M04</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="date"
                                                                id=""
                                                                value={this.state.date_of_joining}
                                                                onChange={e => this.setState({ date_of_joining: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_DateofJoining}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                value={this.state.location}
                                                                onChange={e => this.setState({ location: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_Location}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                                            <input
                                                                className="mdl-textfield__input"
                                                                type="text"
                                                                id=""
                                                                maxLength='6'
                                                                value={this.state.pincode}
                                                                onChange={e => this.setState({ pincode: e.target.value })}
                                                            />
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_Pincode}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder">
                                                            <label className="mdl-textfield__label">{process.env.React_APP_LABELFOR_ChooseCategoryType}</label>

                                                            <div className="radio-checkbox">
                                                                <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                                                    <input type="checkbox" id="" className="mdl-checkbox__input" checked={this.state.general} onChange={() => this.setState({ general: !(this.state.general) })} />
                                                                    <span className="mdl-checkbox__label">General Category</span>
                                                                </label>
                                                            </div>

                                                            <div className="radio-checkbox">
                                                                <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                                                    <input type="checkbox" id="" className="mdl-checkbox__input" checked={this.state.food} onChange={() => this.setState({ food: !(this.state.food) })} />
                                                                    <span className="mdl-checkbox__label">Food Meal Category</span>
                                                                </label>
                                                            </div>

                                                            <div className="radio-checkbox">
                                                                <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                                                    <input type="checkbox" id="" className="mdl-checkbox__input" checked={this.state.travel} onChange={() => this.setState({ travel: !(this.state.travel) })} />
                                                                    <span className="mdl-checkbox__label">Travel Category</span>
                                                                </label>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-xl-4">
                                                        <div className="file-upload">
                                                            <label>Profile Picture</label>

                                                            <div className="droppable">
                                                                <i className="upload"></i>
                                                                <h5>Drag & Drop or <span>Browes</span> files here</h5>
                                                                <p>File formats: jpg, gif, png and max size of the uploaded file can be 500 KB</p>
                                                            </div>

                                                            <div className="upload-status">
                                                                <div className="proccess-bar">
                                                                    <div id="p1" className="mdl-progress mdl-js-progress"></div>
                                                                    <div className="output"></div>
                                                                    <div className="upload-action">
                                                                        <i className="cancel"></i>
                                                                        <i className="done"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <input type="reset" value="Reset" className="lbtn btngr" />
                                            <button type="button" value="Save" onClick={this.handleSubmit} className="lbtn btnbl" >Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                    : (
                        <Redirect to='/' />
                    )
                }

            </>
        )
    }

}

const mapStateToProps = ({ user: { roMdn }, user: { currentUser }, token: { authenticated } }) => ({
    roMdn,
    currentUser,
    authenticated
});


export default connect(mapStateToProps)(UpdateEmployee);