import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import '../css/loading.styles.css'

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

export default function Animations() {
    //const classes = useStyles();
    return (
        <>


            <div className="col-12 col-md-6 col-lg-4 col-xl-3" >

                <div className='loading-card' style={{ minHeight: "82px" }}>
                    {/* <div className={classes.root}> */}
                    <div className="loading-basic" >
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <div className="loading-check">
                            <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                <input
                                    type="checkbox"
                                    id='1'
                                    className="mdl-checkbox__input"
                                    value="1" disabled="disabled"
                                />
                            </label>
                        </div>

                    </div>

                    {/* <Skeleton variant="rect"  height={110} /> */}
                </div>

            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3" >

                <div className='loading-card' style={{ minHeight: "82px" }}>
                    {/* <div className={classes.root}> */}
                    <div className="loading-basic" >
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <div className="loading-check">
                            <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                <input
                                    type="checkbox"
                                    id='1'
                                    className="mdl-checkbox__input"
                                    value="1" disabled="disabled"
                                />
                            </label>
                        </div>

                    </div>

                    {/* <Skeleton variant="rect"  height={110} /> */}
                </div>

            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3" >

                <div className='loading-card' style={{ minHeight: "82px" }}>
                    {/* <div className={classes.root}> */}
                    <div className="loading-basic" >
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <div className="loading-check">
                            <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                <input
                                    type="checkbox"
                                    id='1'
                                    className="mdl-checkbox__input"
                                    value="1" disabled="disabled"
                                />
                            </label>
                        </div>

                    </div>

                    {/* <Skeleton variant="rect"  height={110} /> */}
                </div>

            </div>

        </>
    );
}