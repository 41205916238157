import React from "react";
import GenerateRequestId from '../../common-function/GenerateRequestId';
import ApiCalling from '../../service/api-call-service';
import { connect } from "react-redux";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import history from '../../auth/history'
import { Redirect } from 'react-router-dom';
import CategoryLimitType from '../../constants/category-limit.type';
import UploadImage from '../../employee/component/upload-image.component';
import moment from "moment";
import { wait } from "../../common-function/time-out";
import CreatableSelect from 'react-select/creatable';
import img from '../../assests/icons/arrow-down.svg'
import apiCallService from "../../service/api-call-service";
import Avatar from 'react-avatar';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';


class AddCorpEmployee extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            mobile_num: '',
            email_id: '',
            dob: '',
            pan: '',
            aadhaar: '',
            male: false,
            female: false,
            other: false,
            employee_id: '',
            reporting_manager_emp_id: '',
            finance_admin_emp_id: '',
            department_name: '',
            employee_band: '',
            date_of_joining: '',
            location: '',
            pincode: '',
            category: '',
            food: false,
            travel: false,
            general: false,
            dropDownHidden: false,
            message: '',
            foodAmount: -1,
            travelAmount: -1,
            generalAmount: -1,
            severty: 'success',
            colourOptions: [],

            empList: [],
            searchVarForReportingMgn: '',
            showReportMangEMpSection: false,

            showFinEMpSection: false,
            searchVarForFinAdmin: '',
            roId: '',
            currentUser: '',
            fullName: '',


            corpDetail: [],

            walletArray: [],
            address: ''

        }
    }


    componentDidMount = () => {
        localStorage.setItem('url', '');
        document.title = "ShakePe Add New Employee"

        this.getEmployeeDetail();


        console.log("Props issue hai kys????", this.props.location)

        this.setState({ roId: this.props.location.state.roId })
        this.setState({ fullName: this.props.location.state.fullName })
        this.setState({ currentUser: this.props.location.state.currentUser })
        this.fetchDetail();
    }


    fetchPosts = async (pageNumber, searchField, ctk, orderOn, orderType) => {
        this.setState({ loading: true })
        this.setState({ currentPost: [] })
        const url = process.env.REACT_APP_GET_EMPLOYEE_BY_ROID_WITH_FILTER
        console.log(url);
        var request = {
            "roId": this.props.location.state.roId,
            "page": pageNumber,
            "size": 100,
            "searchKeyword": searchField,
            "collectedTrueKeys": {
                "band": [],
                "location": [],
                "roleName": [],
                "card_staus": [],
                "kyc_status": []
            },
            "orderOn": orderOn,
            "orderType": orderType,
        }
        const res = await apiCallService.postMethodCall(url, request);


        console.log('response emp-list', request, res);
        if (res !== undefined && res.status === 0) {
            console.log('response inside fetch post', res.response.employeeDetails);

            //   this.setState({ totalLength: res.response.totalEmployee })

            var arry = [];
            res.response.employeeDetails.map(detail => {
                var isChecked = 'isChecked';
                detail[isChecked] = false;
                var fullName = 'fullName';
                if (detail.last_name === undefined) {
                    detail[fullName] = `${detail.first_name}`
                } else {
                    detail[fullName] = `${detail.first_name} ${detail.last_name}`
                }
                arry.push(detail)
            })

            this.setState({ empList: res.response.employeeDetails })

        }

        else {
            this.setState({ empList: [] })
        }

    }

    getEmployeeDetail = async () => {
        const url = process.env.REACT_APP_GET_EMPLOYEE_BY_ROID
        console.log(url);
        const res = await apiCallService.getMethodCallWithParam(url, { 'ro_Id': this.props.location.state.roId });
        console.log('fetchEmployeeList', res)
        if (res !== undefined && res.response !== undefined && res.status === 0 && res.response.employeeDetails !== undefined) {
            this.setState({ empList: res.response.employeeDetails })
        }
    }

    fetchDetail = async () => {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        const res = await apiCallService.getMethodCallWithParam(url, { 'roId': this.props.location.state.roId });

        console.log('CorporateProfile', res)

        if (res !== undefined && res.response_code !== undefined && res.response_code === 0 && res.response !== undefined) {
            this.setState({ corpDetail: res.response })

        }

    }

    onHandleMaleChecked = (event) => {
        console.log('Testing-onHandleMaleChecked :' + this.state.male)
        this.setState({ male: true })
        this.setState({ female: false })
        this.setState({ other: false })
        console.log('Testing-onHandleMaleChecked :' + this.state.male)
    }
    onHandleFemaleChecked = (event) => {

        console.log('Testing-onHandleFemaleChecked :' + this.state.female)
        this.setState({ male: false })
        this.setState({ female: true })
        this.setState({ other: false })
        console.log('Testing-onHandleFemaleChecked :' + this.state.female)
    }
    onHandleOtherChecked = (event) => {

        console.log('Testing-onHandleOtherChecked :' + this.state.other)

        this.setState({ male: false })
        this.setState({ female: false })
        this.setState({ other: true })
        console.log('Testing-onHandleOtherChecked :' + this.state.other)
    }

    radioChangeHandler = (name, event) => {
        this.setState({
            category: name
        })
        console.log('Testing-onHandleOtherChecked :' + this.state.category)
    }



    handleSubmit = async (event) => {


        if (this.state.food === false && this.state.general === false && this.state.travel === false || this.state.foodAmount < 0 && this.state.generalAmount < 0 && this.state.travelAmount < 0) {
            this.setState({ dropDownHidden: true });
            this.setState({ severty: "warning" })
            this.setState({ message: 'Please Select AtLeast One Category Type' });
            return;
        }

        if (this.state.male === false && this.state.female === false && this.state.other === false) {
            this.setState({ dropDownHidden: true });
            this.setState({ severty: "warning" })
            this.setState({ message: 'Please Select Gender' });
            return;
        }

        else {
            console.log('Testing-handleSubmit event:' + event)
            console.log('Testing-handleSubmit :' + this.state)
            var req_id = GenerateRequestId.geneRateRequestId();
            console.log(this.state.male);
            console.log(this.state.female);
            console.log(this.state.other);
            console.log('employeeManager', this.state.reporting_manager_emp_id, ' financeManger', this.state.finance_admin_emp_id)
            var gender = '';
            if (this.state.male) {
                gender = 'Male'
            } else if (this.state.female) {
                gender = 'Female'
            } else if (this.state.other) {
                gender = 'Other'
            }



            var wallet = [];
            var foodWallet = {
                wallet_id: '',
                amount: ''
            }
            var travelWallet = {
                wallet_id: '',
                amount: ''
            }
            var generalWallet = {
                wallet_id: '',
                amount: ''
            }
            if (this.state.food) {
                foodWallet.wallet_id = CategoryLimitType.FOOD;
                foodWallet.amount = this.state.foodAmount * 100;
                console.log('inside food')
                wallet.push(foodWallet);
            }
            if (this.state.general) {
                generalWallet.wallet_id = CategoryLimitType.GENERAL;
                generalWallet.amount = this.state.generalAmount * 100;
                console.log('inside general')
                wallet.push(generalWallet);
            }
            if (this.state.travel) {
                travelWallet.wallet_id = CategoryLimitType.TRAVEL;
                travelWallet.amount = this.state.travelAmount * 100;
                console.log('inside travel')
                wallet.push(travelWallet);
            }

            const imgUrl = localStorage.getItem('url')
            console.log('imageUrl', imgUrl);
            console.log(wallet)
            var dateOfJoining = moment(this.state.date_of_joining).format("YYYY/MM/DD");

            var dateOfBirth = moment(this.state.dob).format("YYYY/MM/DD");


            console.log('current user', this.props.location.state.currentUser);
            const RequestToSend = {
                "request": {
                    "request_id": req_id,
                    "employee": {
                        "corp_id": this.state.roId,
                        "emp_id": this.state.employee_id,
                        "mdn": this.state.mobile_num,
                        "first_name": this.state.first_name,
                        "last_name": this.state.last_name,
                        "email": this.state.email_id,
                        "pan": this.state.pan,
                        "band": this.state.employee_band,
                        "department": this.state.department_name,
                        "location": this.state.location,
                        "pincode": this.state.pincode,
                        "dob": dateOfBirth,
                        "gender": gender,
                        "password": '1234567',
                        "date_of_joining": dateOfJoining,
                        "aadhar_no": this.state.aadhaar,
                        "imageUrl": imgUrl,
                        "manager_emp_corp_id": this.state.reporting_manager_emp_id,
                        "finance_emp_corp_id": this.state.finance_admin_emp_id,
                        "created_by": this.state.currentUser,
                        "wallet": wallet,
                        "created_by_name": this.state.fullName,
                        "address": this.state.address
                    }

                }
            }


            console.log(RequestToSend);
            const url = process.env.REACT_APP_ADD_NEW_CORPORATE_EMPLOYEE;


            const response = await ApiCalling.postMethodCall(url, RequestToSend);
            console.log('ADD EMployee Api response', response)

            if (!(response.status === undefined) && response.status.toLowerCase() === 'failure') {
                this.setState({ dropDownHidden: true });
                this.setState({ message: response.response_description });
                this.setState({ severty: "error" })
            } else if (!(response.response === undefined) && !(response.response.response_message === undefined) && response.response.response_message.toLowerCase() === 'success') {
                let i = 0
                this.setState({ severty: "success" });
                for (i = 0; i < 1; i++) {
                    this.setState({ dropDownHidden: true });
                    this.setState({ isHidden: false });
                    this.setState({ disabled: true });
                    this.setState({ message: 'Employee successfully added.' });
                }
                //s wait(7000);
                if (i === 1) {
                    // 
                    history.push('/corp-list');
                    history.go();
                    // return(
                    //     <Redirect to='/emp-list'/>
                    // )
                }
            } else {
                this.setState({ dropDownHidden: true });
                if (!(response.response_description === undefined)) {
                    this.setState({ message: response.response_description })
                } else {
                    this.setState({ message: 'Somthing went wrong. Please try in a bit again' });
                }
                this.setState({ severty: "warning" })
            }

        }


    }

    handleResetButton = e => {

        this.setState({ first_name: '' })
        this.setState({ last_name: '' })
        this.setState({ mobile_num: '' })
        this.setState({ email_id: '' })
        this.setState({ dob: '' })
        this.setState({ pan: '' })
        this.setState({ aadhaar: '' })
        this.setState({ employee_id: '' })
        this.setState({ reporting_manager_emp_id: '' })
        this.setState({ finance_admin_emp_id: '' })
        this.setState({ department_name: '' })
        this.setState({ employee_band: '' })
        this.setState({ date_of_joining: '' })
        this.setState({ location: '' })
        this.setState({ pincode: '' })
        this.setState({ category: '' })
        this.setState({ food: false })
        this.setState({ travel: false })
        this.setState({ general: true })
        this.setState({ dropDownHidden: false })
        this.setState({ message: '' })
        this.setState({ foodAmount: '' })
        this.setState({ travelAmount: '' })
        this.setState({ generalAmount: '' })
        this.setState({ severty: 'success' })



        this.setState({ empList: [] })
        this.setState({ searchVarForReportingMgn: '' })
        this.setState({ showReportMangEMpSection: false })
        this.setState({ showFinEMpSection: false })
        this.setState({ searchVarForFinAdmin: '' })


    }

    handleBand = e => {
        const name = e.target.innerText;
        console.log('e.taget.innertext', name, " :", e.target.innerText);
        this.setState({ employee_band: name })

    }




    handleWalletDetailChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        console.log('e.taget.innertext', name, " :", value);
        this.setState({ [name]: value })

    }

    handleAutoSubmit = (event) => {
        event.preventDefault();
        console.log('inside handle Auto Submit');
        this.handleSubmit(event);
    }

    handleMultiChange = (e) => {

        console.log('multivalue', e)
        const name = e.target.innerText;
        console.log('e.taget.innertext', name, " :", e.target.innerText);
        this.setState({ department_name: name })

    }

    validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    handleDepartmentChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {
            this.setState({ department_name: newValue.value })
        } else {
            this.setState({ department_name: '' })
        }
    };
    handleDepartmentInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed handleInputChange');
        console.log(inputValue);
        console.log(`action handleInputChange: ${actionMeta.action}`);
        console.groupEnd();
    };

    handleBandChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {
            this.setState({ employee_band: newValue.value })
        } else {
            this.setState({ employee_band: '' })
        }

    };
    handleBandInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed handleInputChange');
        console.log(inputValue);
        console.log(`action handleInputChange: ${actionMeta.action}`);
        console.groupEnd();
    };


    searchFinAdminId = (event) => {
        console.log('event.target.value', event.target.value)
        this.setState({ searchVarForFinAdmin: event.target.value });
        this.setState({ finance_admin_emp_id: event.target.value })
        this.setState({ showFinEMpSection: false });

        this.fetchPosts(1, event.target.value, [], 'dateCreatedOn', 'desc');
    }

    handleFinAdminEmId = (event) => {
        console.log('event -->', event.currentTarget.id)
        console.log('event.target.id', event.target.id)
        console.log('event.target.id', event.target.name)
        this.setState({ searchVarForFinAdmin: event.currentTarget.id })
        this.setState({ finance_admin_emp_id: event.currentTarget.id })
        this.setState({ showFinEMpSection: true })


    }


    searchReportinMangId = (event) => {
        console.log('event.target.value', event.target.value)
        this.setState({ searchVarForReportingMgn: event.target.value });
        this.setState({ showReportMangEMpSection: false });
        this.setState({ reporting_manager_emp_id: event.target.value })

        this.fetchPosts(1, event.target.value, [], 'dateCreatedOn', 'desc');

    }

    handleReportinMangId = (event) => {
        console.log('event.target.id', event.currentTarget.id)
        console.log('event.target.id', event.target.id)
        console.log('event.target.name', event.target.name)
        this.setState({ searchVarForReportingMgn: event.currentTarget.id })
        this.setState({ reporting_manager_emp_id: event.currentTarget.id })
        this.setState({ showReportMangEMpSection: true })
    }




    // searchFinAdminId = (event) => {
    //     console.log('event.target.value', event.target.value)
    //     this.setState({ searchVarForFinAdmin: event.target.value });
    //     this.setState({ finance_admin_emp_id: event.target.value })
    //     this.setState({ showFinEMpSection: false });
    // }

    // handleFinAdminEmId = (event) => {
    //     console.log('event -->', event.currentTarget.id)
    //     console.log('event.target.id', event.target.id)
    //     console.log('event.target.id', event.target.name)
    //     this.setState({ searchVarForFinAdmin: event.currentTarget.id })
    //     this.setState({ finance_admin_emp_id: event.currentTarget.id })
    //     this.setState({ showFinEMpSection: true })
    // }


    // searchReportinMangId = (event) => {
    //     console.log('event.target.value', event.target.value)
    //     this.setState({ searchVarForReportingMgn: event.target.value });
    //     this.setState({ showReportMangEMpSection: false });
    //     this.setState({ reporting_manager_emp_id: event.target.value })
    // }

    // handleReportinMangId = (event) => {
    //     console.log('event.target.id', event.currentTarget.id)
    //     console.log('event.target.id', event.target.id)
    //     console.log('event.target.name', event.target.name)
    //     this.setState({ searchVarForReportingMgn: event.currentTarget.id })
    //     this.setState({ reporting_manager_emp_id: event.currentTarget.id })
    //     this.setState({ showReportMangEMpSection: true })
    // }


    setChosseCategory = (e) => {
        console.log("setChosseCategory", e.target.id, e.target.checked, e.target.name,
            this.state.travelAmount
        )

        var id = e.target.id
        this.setState({ [e.target.name]: e.target.checked })
        if (e.target.checked === true) {
            console.log("setChosseCategory", e.target.id, e.target.checked, e.target.name, this.state.travelAmount)
            this.setState({ [id]: "" })
        } else {
            this.setState({ [id]: "-1" })
        }
    }




    render() {

        var startdate = moment();
        startdate = startdate.subtract(18, "years");
        startdate = startdate.format("YYYY-MM-DD");

        console.log('moment().subtract(500, "years")', startdate, this.state, "     ", moment().format('YYYY-MM-DD'));

        var arrayForGeneralAmount = [];
        var arrayForTravelAmount = [];
        var arrayForFoodAmount = [];

        arrayForGeneralAmount.push(this.state.corpDetail.genrealLimit / 100);
        arrayForTravelAmount.push(this.state.corpDetail.travelLimit / 100);
        arrayForFoodAmount.push(this.state.corpDetail.walletFoodLimit / 100);

        this.state.corpDetail !== undefined && this.state.corpDetail.corpBands !== undefined
            && this.state.corpDetail.corpBands.map(band => {
                if (band.band === this.state.employee_band) {
                    arrayForGeneralAmount.push(band.general_Limit / 100);
                    arrayForTravelAmount.push(band.travel_Limit / 100);
                    arrayForFoodAmount.push(band.wallet_food_limit / 100);
                }
            })


        arrayForGeneralAmount = arrayForGeneralAmount.filter(function (val) {
            return val !== 0;
        });
        arrayForTravelAmount = arrayForTravelAmount.filter(function (val) {
            return val !== 0;
        });
        arrayForFoodAmount = arrayForFoodAmount.filter(function (val) {
            return val !== 0;
        });

        console.log('arrayForGeneralAmount arrayForTravelAmount arrayForFoodAmount', arrayForGeneralAmount, arrayForTravelAmount, arrayForFoodAmount)
        var maxGeneralAmount = Math.min(...arrayForGeneralAmount);
        var maxTravelAmount = Math.min(...arrayForTravelAmount);
        var maxFoodAmount = Math.min(...arrayForFoodAmount);



        var walletDetailToSet = [];



        this.state.corpDetail.walletTypes !== undefined && this.state.corpDetail.walletTypes.map(oneData => {
            var max = "max";
            var label = "label";
            var name = "name"
            var id = "id"
            var value = "value"
            var isDisabled = "isDisabled"
            var isChecked = "isChecked"
            var labelForChoseCategory = "labelForChoseCategory"
            var chooseId = "chooseId"
            var chooseName = "chooseName"

            if (oneData.wallet_type_id === 1) {
                oneData[max] = maxGeneralAmount
                oneData[label] = process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_GENERAL_LIMIT
                oneData[name] = "generalAmount"
                oneData[id] = oneData.wallet_type_id
                oneData[value] = parseInt(this.state.generalAmount) === -1 ? '' : this.state.generalAmount
                oneData[isDisabled] = !this.state.general

                oneData[isChecked] = this.state.general
                oneData[labelForChoseCategory] = process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_General_Category
                oneData[chooseId] = "general"
                oneData[chooseName] = "general"


                walletDetailToSet.push(oneData)
            }
            if (oneData.wallet_type_id === 2) {
                oneData[max] = maxFoodAmount
                oneData[label] = process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FOOD_LIMIT
                oneData[name] = "foodAmount"
                oneData[id] = oneData.wallet_type_id
                oneData[value] = parseInt(this.state.foodAmount) === -1 ? '' : this.state.foodAmount
                oneData[isDisabled] = !this.state.food


                oneData[isChecked] = this.state.food
                oneData[labelForChoseCategory] = process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_Food_Meal_Category
                oneData[chooseId] = "food"
                oneData[chooseName] = "food"
                walletDetailToSet.push(oneData)
            }
            if (oneData.wallet_type_id === 3) {
                oneData[max] = maxTravelAmount
                oneData[label] = process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_TRAVEL_LIMIT
                oneData[name] = "travelAmount"
                oneData[id] = oneData.wallet_type_id
                oneData[value] = parseInt(this.state.travelAmount) === -1 ? '' : this.state.travelAmount
                oneData[isDisabled] = !this.state.travel


                oneData[isChecked] = this.state.travel
                oneData[labelForChoseCategory] = process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_Food_Travel_Category
                oneData[chooseId] = "travel"
                oneData[chooseName] = "travel"
                walletDetailToSet.push(oneData)
            }
        })


        const colourStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: 'white',
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                minHeight: "22px",
                lineHeight: "22px",
                background: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "12px",
                backgroundPosition: "center right",
                marginTop: "-7px"
            }),

            valueContainer: styles => ({
                ...styles,
                padding: "0px 5px 0px 0px"
            }),


            menu: styles => ({
                ...styles,
                zIndex: "9999"
            }),


            input: styles => ({
                ...styles,
                margin: "0px"
            }),



            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //   const color = chroma(data.color);
                return {
                    ...styles,
                };
            },



        };




        var searchFinaceFinalList = [];

        if (this.state.searchVarForFinAdmin !== '' && this.state.empList !== undefined) {
            this.state.empList.map(post => {
                if (post.first_name !== undefined && post.first_name !== null && post.first_name.toLowerCase().includes(this.state.searchVarForFinAdmin.toLowerCase())) {
                    searchFinaceFinalList.push(post)
                } else if (post.last_name !== undefined && post.last_name !== null && post.last_name.toLowerCase().includes(this.state.searchVarForFinAdmin.toLowerCase())) {
                    searchFinaceFinalList.push(post)
                } else if (post.emp_id !== undefined && post.emp_id !== null && post.emp_id.includes(this.state.searchVarForFinAdmin)) {
                    searchFinaceFinalList.push(post)
                } else if (post.mdn !== undefined && post.mdn !== null && post.mdn.includes(this.state.searchVarForFinAdmin)) {
                    searchFinaceFinalList.push(post)
                }
            })
        }


        var searchReportingMgnFinal = [];

        if (this.state.searchVarForReportingMgn !== '' && this.state.empList !== undefined) {
            this.state.empList.map(post => {
                if (post.first_name !== undefined && post.first_name !== null && post.first_name.toLowerCase().includes(this.state.searchVarForReportingMgn.toLowerCase())) {
                    searchReportingMgnFinal.push(post)
                } else if (post.last_name !== undefined && post.last_name !== null && post.last_name.toLowerCase().includes(this.state.searchVarForReportingMgn.toLowerCase())) {
                    searchReportingMgnFinal.push(post)
                } else if (post.emp_id !== undefined && post.emp_id !== null && post.emp_id.includes(this.state.searchVarForReportingMgn)) {
                    searchReportingMgnFinal.push(post)
                } else if (post.mdn !== undefined && post.mdn !== null && post.mdn.includes(this.state.searchVarForReportingMgn)) {
                    searchReportingMgnFinal.push(post)
                }
            })
        }


        console.log('this.state value for all data', this.state)

        return (
            <>
                {(this.props.authenticated)
                    ? (
                        this.props.isPasswordReset
                            ?
                            <div className="mid-container" id="heightFix">
                                <div className="container-fluid">
                                    <Snackbar

                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={this.state.dropDownHidden}
                                        autoHideDuration={20000}
                                        onClose={e => this.setState({ dropDownHidden: false })}
                                    >
                                        <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                            {this.state.message}
                                        </Alert>
                                    </Snackbar>


                                    <form onSubmit={this.handleAutoSubmit}>

                                        <div className="head">
                                            <div className="row">
                                                <div className="col-8 col-md-3 order-md-1">
                                                    <h3>{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_EmployeePersonalDetails}</h3>
                                                </div>

                                                <div className="col-4 col-md-1 order-md-3">
                                                    <div className="actnbtn">
                                                        <a href="/emp-list" data-toggle="tooltip" title="Back"><i className="back"></i></a>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-8 order-md-2">
                                                    <p className="empnote">{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_Note}</p>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="addformcard">
                                            <div className="row">

                                                <div className="col-12 col-md-9 col-lg-9 col-xl-10">
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                                            <div className="webform">
                                                                <div className="form-floating">
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="fname"
                                                                        pattern="[a-zA-Z. ]{1,30}"
                                                                        value={this.state.first_name}
                                                                        onChange={e => this.setState({ first_name: e.target.value })}
                                                                        required

                                                                    />
                                                                    <label for="fname">{process.env.React_APP_LABELFOR_FIRSTNAME}</label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="lname"
                                                                        pattern="[a-zA-Z. ]{1,30}"
                                                                        value={this.state.last_name}
                                                                        onChange={e => this.setState({ last_name: e.target.value })}
                                                                        placeholder={process.env.React_APP_LABELFOR_LASTNAME}

                                                                    />
                                                                    <label for="lname">{process.env.React_APP_LABELFOR_LASTNAME}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control"
                                                                        placeholder={process.env.React_APP_LABELFOR_MOBILENUMBER}
                                                                        type="number"
                                                                        id="mob-input"
                                                                        maxLength="10"
                                                                        pattern="[0-9]{10}"
                                                                        onInput={this.validateInputLenght}
                                                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                        value={this.state.mobile_num}
                                                                        onChange={e => this.setState({ mobile_num: e.target.value })}
                                                                        required
                                                                    />
                                                                    <label for="mob-input">{process.env.React_APP_LABELFOR_MOBILENUMBER}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control"
                                                                        placeholder={process.env.React_APP_LABELFOR_EMAIL}
                                                                        type="email"
                                                                        id="email"
                                                                        value={this.state.email_id}
                                                                        onChange={e => this.setState({ email_id: e.target.value })}

                                                                    />
                                                                    <label for="email">{process.env.React_APP_LABELFOR_EMAIL}</label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control cldr"
                                                                        placeholder={process.env.React_APP_LABELFOR_DATEOFBIRTH}
                                                                        type="date"
                                                                        id="dob"
                                                                        max={startdate}
                                                                        value={this.state.dob}
                                                                        onChange={e => this.setState({ dob: e.target.value })}
                                                                        required

                                                                    />
                                                                    <label for="dob">{process.env.React_APP_LABELFOR_DATEOFBIRTH}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control"
                                                                        placeholder={process.env.React_APP_LABELFOR_PAN}
                                                                        type="text"
                                                                        id="pan"
                                                                        maxlength="10"
                                                                        pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                                                                        value={this.state.pan}
                                                                        onChange={e => this.setState({ pan: e.target.value })}


                                                                    />
                                                                    <label for="pan">{process.env.React_APP_LABELFOR_PAN}</label>
                                                                    <div className="pan-note">{process.env.React_APP_PAN_NOTE}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control"
                                                                        placeholder={process.env.React_APP_LABELFOR_AADHARNUMBER}
                                                                        type="number"
                                                                        id="adr-input"
                                                                        maxLength="12"
                                                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                        pattern="[1-9]{1}[0-9]{11}"
                                                                        value={this.state.aadhaar}
                                                                        onInput={this.validateInputLenght}
                                                                        onChange={e => this.setState({ aadhaar: e.target.value })}


                                                                    />
                                                                    <label for="pan">{process.env.React_APP_LABELFOR_AADHARNUMBER}</label>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <p className="gentxt">{process.env.React_APP_LABELFOR_GENDER}</p>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="options" id="op1" value="option1" checked={this.state.male} onChange={this.onHandleMaleChecked} />
                                                                    <label className="form-check-label" for="op1">{process.env.React_APP_LABELFOR_MALE}</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="options" id="op2" value="option2" checked={this.state.female} onChange={this.onHandleFemaleChecked} />
                                                                    <label className="form-check-label" for="gen2">{process.env.React_APP_LABELFOR_FEMALE}</label>

                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="options" id="op3" value="option3" checked={this.state.other} onChange={this.onHandleOtherChecked} />
                                                                    <label className="form-check-label" for="gen3">{process.env.React_APP_LABELFOR_OTHER}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="webform">
                                                                <div className="form-floating">

                                                                    <input
                                                                        className="form-control"
                                                                        placeholder={process.env.React_APP_LABELFOR_Location}
                                                                        type="text"
                                                                        id="location"
                                                                        maxLength="60"
                                                                        value={this.state.location}
                                                                        onChange={e => this.setState({ location: e.target.value })}
                                                                        required


                                                                    />
                                                                    <label for="location">{process.env.React_APP_LABELFOR_Location}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>


                                        <div className="head">
                                            <h3 >{process.env.React_APP_VAR_FOR_ADD_AND_UPDATE_EMPLOYEE_PAGE_EmployeeProfessionalDetails}</h3>
                                        </div>


                                        <div className="addformcard">
                                            <div className="row">

                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">
                                                        <div className="form-floating">

                                                            <input
                                                                className="form-control"
                                                                placeholder={process.env.React_APP_LABELFOR_EMPLOYEEID}
                                                                type="text"
                                                                id="empId"
                                                                value={this.state.employee_id}
                                                                onChange={e => this.setState({ employee_id: e.target.value })}
                                                                required

                                                            />
                                                            <label for="empId">{process.env.React_APP_LABELFOR_EMPLOYEEID}</label>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">

                                                        <div className="searchempbox">
                                                            <div className="form-floating">
                                                                <input className="form-control" type="text" placeholder="Search..." id="searchfm1" value={this.state.searchVarForReportingMgn} onChange={this.searchReportinMangId} />
                                                                <label for="searchfm1">{process.env.React_APP_LABELFOR_ReportingManagerEmpID}</label>

                                                                {searchReportingMgnFinal.length === 0 || this.state.showReportMangEMpSection
                                                                    ?
                                                                    null
                                                                    :
                                                                    <div className="empsearchlist" id="showfm">
                                                                        {
                                                                            searchReportingMgnFinal.map(emp => (
                                                                                <div className="emprofile" id={emp.emp_id} name={emp.emp_id} onClick={this.handleReportinMangId}>
                                                                                    {/* <img src="images/user-1.jpg" alt="" /> */}
                                                                                    <Avatar id={emp.emp_id} src={emp.imageurl} color={Avatar.getRandomColor(emp.mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={emp.emp_id} name={`${emp.first_name} ${emp.last_name}`} size="48px" round={true} />

                                                                                    <h4 id={emp.emp_id} name={emp.emp_id}>{emp.first_name} {emp.last_name} - {emp.emp_id}</h4>
                                                                                    <p id={emp.emp_id} name={emp.emp_id}>{emp.mdn}</p>
                                                                                </div>
                                                                            ))


                                                                        }

                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">

                                                        <div className="searchempbox">
                                                            <div className="form-floating">
                                                                <input className="form-control" type="text" placeholder="Search..." id="searchfm" value={this.state.searchVarForFinAdmin} onChange={this.searchFinAdminId} />
                                                                <label for="searchfm">{process.env.React_APP_LABELFOR_FinanceAdminEmpID}</label>
                                                                {searchFinaceFinalList.length === 0 || this.state.showFinEMpSection
                                                                    ?
                                                                    null
                                                                    :
                                                                    <div className="empsearchlist" id="showfm">
                                                                        {
                                                                            searchFinaceFinalList.map(emp => (
                                                                                <div className="emprofile" id={emp.emp_id} name={emp.emp_id} onClick={this.handleFinAdminEmId}>
                                                                                    <div>
                                                                                        <Avatar id={emp.emp_id} src={emp.imageurl} onClick={this.handleFinAdminEmId} color={Avatar.getRandomColor(emp.mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={emp.emp_id} name={`${emp.first_name} ${emp.last_name}`} size="48px" round={true} />
                                                                                    </div>
                                                                                    <h4 id={emp.emp_id} name={emp.emp_id}>{emp.first_name} {emp.last_name} - {emp.emp_id}</h4>
                                                                                    <p id={emp.emp_id} name={emp.emp_id}>{emp.mdn}</p>
                                                                                </div>
                                                                            ))


                                                                        }

                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">
                                                        <div className="formselect">
                                                            <label for="sg" >{process.env.React_APP_LABELFOR_DepartmentName}</label>
                                                            <CreatableSelect
                                                                isClearable
                                                                onChange={this.handleDepartmentChange}
                                                                onInputChange={this.handleDepartmentInputChange}
                                                                options={this.props.departmentaList}
                                                                styles={colourStyles}
                                                                components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>






                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">
                                                        <div className="formselect">
                                                            <label htmlFor="sg">Band</label>
                                                            <CreatableSelect
                                                                isClearable
                                                                onChange={this.handleBandChange}
                                                                onInputChange={this.handleBandInputChange}
                                                                options={this.props.bandData}
                                                                styles={colourStyles}
                                                                components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">
                                                        <div className="form-floating">

                                                            <input
                                                                className="form-control cldr"
                                                                placeholder={process.env.React_APP_LABELFOR_DateofJoining}
                                                                type="date"
                                                                id="doj"
                                                                max={moment().format('YYYY-MM-DD')}
                                                                value={this.state.date_of_joining}
                                                                onChange={e => this.setState({ date_of_joining: e.target.value })}
                                                                required

                                                            />
                                                            <label for="doj">{process.env.React_APP_LABELFOR_DateofJoining}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">
                                                        <div className="form-floating">

                                                            <input
                                                                className="form-control"
                                                                placeholder={process.env.React_APP_LABELFOR_Address}
                                                                type="text"
                                                                id="address"
                                                                maxLength="500"
                                                                value={this.state.address}
                                                                onChange={e => this.setState({ address: e.target.value })}
                                                                required

                                                            />
                                                            <label for="address">{process.env.React_APP_LABELFOR_Address}</label>
                                                        </div>
                                                        <div className="pan-note">{process.env.React_APP_ADDRESS_NOTE}</div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                    <div className="webform">
                                                        <div className="form-floating">

                                                            <input
                                                                className="form-control"
                                                                placeholder={process.env.React_APP_LABELFOR_Pincode}
                                                                type="number"
                                                                id="pincode"
                                                                maxLength='6'
                                                                pattern="[0-9]{6}"
                                                                value={this.state.pincode}
                                                                onInput={this.validateInputLenght}
                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                onChange={e => this.setState({ pincode: e.target.value })}
                                                                required

                                                            />
                                                            <label for="pincode">{process.env.React_APP_LABELFOR_Pincode}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="head">
                                                        <h3>{process.env.React_APP_LABELFOR_ChooseCategoryType}</h3>
                                                    </div>
                                                </div>

                                                {
                                                    walletDetailToSet.map(ww => (
                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="checkform">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name={ww.chooseName} id={ww.name} checked={ww.isChecked} onChange={this.setChosseCategory} />

                                                                    <label className="form-check-label" for="general">{ww.labelForChoseCategory}</label>
                                                                </div>
                                                            </div>
                                                            <div className="webform">
                                                                <div className="form-floating">
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        max={ww.max}
                                                                        name={ww.name}
                                                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                        id={ww.id}
                                                                        placeholder={ww.label}
                                                                        value={ww.value}
                                                                        onChange={this.handleWalletDetailChange}
                                                                        disabled={ww.isDisabled}
                                                                    />


                                                                    <label for="amt1">{ww.label}</label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    ))
                                                }

                                            </div>


                                        </div>


                                        <div className="addrow">
                                            <input type="reset" value="Reset" className="lbtn btngr" onClick={this.handleResetButton} />
                                            <button type="submit" value="Save" className="lbtn btnbl" >Save</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            :
                            <ResetLoginPassword />
                    )
                    : (
                        <Redirect to='/' />
                    )

                }

            </>
        )
    }

}



const mapStateToProps = ({ band: { bandData }, user: { roMdn }, user: { currentUser, fullName, wallet, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount }, token: { authenticated, isPasswordReset }, location: { departmentaList } }) => ({
    roMdn,
    currentUser,
    authenticated,
    bandData, fullName, isPasswordReset,
    wallet,
    departmentaList, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount
});


export default connect(mapStateToProps)(AddCorpEmployee);