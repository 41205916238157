import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import '../../css/loading.styles.css'

// const useStyles = makeStyles({
//   root: {
//     width: 300,
//   },
// });

export default function Animations() {
    //const classes = useStyles();
    return (
        <>

            <div className="col-12 col-md-6 col-lg-4" >

                <div className='loading-ta'>

                    <div className="loading-corp">
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />

                    </div>


                </div>

            </div>
            <div className="col-12 col-md-6 col-lg-4" >

                <div className='loading-ta'>

                    <div className="loading-corp">
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />

                    </div>


                </div>

            </div>
            <div className="col-12 col-md-6 col-lg-4" >

                <div className='loading-ta'>

                    <div className="loading-corp">
                        <Skeleton className="loading-basic-img" variant="circle" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        <Skeleton className="loading-basic-p" variant="text" animation="wave" />

                    </div>


                </div>

            </div>



        </>

    );
}