import React from 'react';
import img from '../../assests/403.png'

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Error = ({ authenticated }) => (
    authenticated ?
        <div className="mid-container">
            <div className="container-fluid">
                <div className="pagenotfound">
                    <img src={img} alt="403 Error" className="img-fluid" />
                    <h3>{process.env.REACT_APP_VAR_FOR_FOUR_NOT_THREE}</h3>
                    <p>{process.env.REACT_APP_VAR_FOR_FOUR_NOT_THREE_MESSAGE_BEFORE_BREAK} <br /> {process.env.REACT_APP_VAR_FOR_FOUR_NOT_THREE_MESSAGE_AFTER_BREAK}</p>
                    <a href="/dashboard" className="mbtn btnbl">{process.env.REACT_APP_VAR_FOR_Back_to_Dashboard}</a>
                </div>
            </div>
        </div>
        :
        <Redirect to="/"></Redirect>

)


const mapStateToProps = ({ user: { roMdn }, token: { authenticated } }) => ({
    roMdn, authenticated
});


export default connect(mapStateToProps)(Error);

