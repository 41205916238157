
export const RoleActionTypes = {
    SET_ROLE_DATA: 'SET_ROLE_DATA',
    SET_ROLE_FOR_ADMIN_BOOLEAN:'SET_ROLE_FOR_ADMIN_BOOLEAN',
    SET_CURRENT_USER_ROLE_NAME:'SET_CURRENT_USER_ROLE_NAME',
    SETHUBURGSHOWHIDE:"SETHUBURGSHOWHIDE",
    SET_FROM_ROLE_STATUS:"SET_FROM_ROLE_STATUS",
    RESET_ROLE: "RESET_ROLE",
    SET_USER_MAINTAIN: "SET_USER_MAINTAIN"
  };
  