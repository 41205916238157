import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import ApiCallingService from '../api-call-service';
import { setToken, setIsPasswordReset } from '../../redux/token/token.action';
import { setCurrentUser, setRoMdn } from '../../redux/user/user.actions';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../redux/token/token.action'
import { sha256 } from 'js-sha256';
import Axios from 'axios';
import GenerateRequestId from '../../common-function/GenerateRequestId';
import { setLocationData } from '../../redux/location/location.action';
class AuthService {

  generateHash(messageBody) {
    //var msg= JSON.stringify(messageBody) ;
    var privateKey = process.env.REACT_APP_CLIENT_ID;
    var hash = sha256.hmac.create(privateKey);
    hash.update(messageBody);
    var hexData = hash.hex();
    console.log('hexed data', hexData);
    var base64String = btoa(hexData);
    console.log(messageBody, '---->hash Generated --->', base64String);
    return base64String;
  }

  async apiCall(url, password, username, rememberMe) {
    console.log('inside AuthService ', url)
    var long = '';
    var lat = '';
    var locationCountry = '';
    var locationState = ''
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          console.log('geolocation permission state is ', permissionStatus.state);

          permissionStatus.onchange = function () {
            console.log('geolocation permission state has changed to ', this.state);
          };
        });
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        //imitate map latlng construct
        const marker = {
          lat: position.coords.latitude,
          long: position.coords.longitude
        };
      })
    }

    navigator.geolocation.watchPosition(async function (position) {
      long = position.coords.longitude;
      lat = position.coords.latitude;
      console.log("Latitude is :", position);
      console.log("Longitude is :", position);
    });


    var sBrowser, sUsrAg = navigator.userAgent;


    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }


    const mobile = Boolean(
      window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    var mobileVar = 'Laptop'

    if (mobile) {
      mobileVar = 'Mobile'
    }


    var responseIp = await Axios.get('https://api.ipify.org?format=json')
      .then(async res => {
        response = res.data;
        console.log(res.data);
        return response;
      });

    // this.getMethodCallWithoutParam('https://api.ipify.org?format=json');

    var address = ''

    var responseAddress = ''
    if (parseInt(process.env.REACT_APP_CALL_GOOGLE_MAP_API) === 1) {
      responseAddress = await Axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=28.4501369,77.081024&key=AIzaSyB3OfJwXzwzOp45JsBsyLq3Om0N-l3nngc')
        .then(async res => {
          response = res.data;
          console.log(res.data);
          return response;
        });
    }

    if (responseAddress !== undefined && responseAddress.plus_code !== undefined && responseAddress.plus_code.compound_code !== undefined) {
      address = responseAddress.plus_code.compound_code;
    }



    var fullAddress = address.split(',');

    console.log('fullAddress', fullAddress, responseAddress);

    var country = fullAddress[2];
    var state = fullAddress[1];

    var payload = {
      locationCountry: country,
      locationState: state,
      ip: responseIp.ip
    }


    store.dispatch(setLocationData(payload));


    var nav = navigator.userAgent;


    var subNav = nav.split(';');
    var os = ''
    var device_os = ''

    if (mobile) {
      os = subNav[2].split(') ')
      device_os = os[0]
    } else {
      os = subNav[0].split('(')
      device_os = os[1]
    }


    var osVersion = subNav[1].split(')')


    console.log('subNav   to calulate', subNav, " : ", os, " : => ", osVersion[0]);


    const request = {
      "request": {
        "token": {
          "grant_type": "password",
          "redirect_uri": "",
          "password": password,
          "scope": "ow",
          "username": username
        },
        "device_info": {
          "device_id": device_os,
          "device_os": "web",
          "device_os_version": osVersion[0],
          "imei": "",
          "ip_address": responseIp.ip,
          "model_name": device_os,
          "source": mobileVar,
          "latitude": lat,
          "longitude": long,
          "locationCountry": country,
          "locationState": state
        },
        "transaction_info": {
          "request_id": GenerateRequestId.geneRateRequestId(),
          "time_stamp": new Date(),
          "txn_description": ""
        }
      }
    }
    var hashCodeForHeader = await this.generateHash(JSON.stringify(request));


    const headers = {
      "device_info": "",
      "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
      "hashCode": hashCodeForHeader,
      "loggedInUser": username
    }



    var response = [];
    async function run() {
      console.log(url, request, headers)
      response = await ApiCallingService.postMethodCall(url, request, headers);
      // response='sucess'
      console.log('response inside AuthService', response)

      if (response === 'error') {
        console.log(response);
        localStorage.setItem('errorMessage', 'Somthing went wrong we are looking at our End');
        history.push('/');
        history.go();
      }
      else if (response.status === 'failure') {
        console.log(response);
        localStorage.setItem('errorMessage', response.response_description);
        history.push('/');
        history.go();
      }
      else {
        console.log(response);
        store.dispatch(setToken(response.response.token.token));
        const url = process.env.REACT_APP_GET_EMPLOYEE_BY_EMAIL
        const res = await ApiCallingService.getMethodCallWithParam(url, { 'email': username });
        const detail = res.response;
        console.log('response data ', res);
        if (res.response_message === 'success') {
          store.dispatch(setRoMdn(detail.employeeDetails.roMdn))

          store.dispatch(setIsPasswordReset(detail.employeeDetails.isPasswordReset));

          // if(detail.employeeDetails.isPasswordReset){
          console.log('url to acl', `${process.env.REACT_APP_ACL_URL}/${username}/${detail.employeeDetails.roMdn}/0`)

          // AclService(`${process.env.REACT_APP_ACL_URL}/${username}`,username)
          // await AclService.aclServiceCall(`${process.env.REACT_APP_ACL_URL}/${username}/${detail.employeeDetails.roMdn}`, username, response.response.token.token, password, rememberMe, detail.employeeDetails.isPasswordReset);
          await AclService.aclServiceCall(`${process.env.REACT_APP_ACL_URL}/${username}/${detail.employeeDetails.roMdn}/0`, username, response.response.token.token, password, rememberMe, detail.employeeDetails.isPasswordReset,detail.employeeDetails.roMdn);

          // }else{
          //   history.push('/reset-login-password')
          //   history.go();
          // }


        }

      }
    }
    run();

  }
}

export default new
  AuthService();







  //     axios.post(url, {
  //   "request": {
  //     "token": {
  //       "grant_type": "password",
  //       "redirect_uri": "",
  //       "password": password,
  //       "scope": "ow",
  //       "username": username
  //     },
  //     "device_info": {
  //       "api_version": "1.0",
  //       "app_version": "9.2",
  //       "device_id": "d215ab007e05c498",
  //       "device_os": "Android",
  //       "device_os_version": "5.1.1",
  //       "imei": "357869081907005",
  //       "ip_address": "172.160.2.140",
  //       "model_name": "SM-J320F",
  //       "source": "mobile"
  //     },
  //     "transaction_info": {
  //       "request_id": "50073870108512914681",
  //       "time_stamp": "2020-01-27T12:20:37.005",
  //       "txn_description": ""
  //     }
  //   }
  //   ,
  //   headers: {
  //     'client_id': header
  //   }
  // }
  // )
  //   .then(res => {
  //     if(res.data.status === 'failure'){
  //     console.log(res.data);
  //     localStorage.setItem('errorMessage',res.data.response_description);
  //      history.push('/');
  //      history.go();
  //     }
  //    else{
  //     console.log(res.data);

  //    // AclService(`${process.env.REACT_APP_ACL_URL}/${username}`,username)


  //     localStorage.setItem('token', res.data.response.token.token);
  //     localStorage.setItem('user', username);

  //     history.push(`/callback#${res.data.response.token.token}`);
  //     // history.push('/callback#1234');
  //     history.go();
  // }

  //   }).catch(error => {
  //     console.log(error);
  //   })
