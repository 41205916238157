import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import history from '../../auth/history';
import apiCallService from '../../service/api-call-service';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';



const CreateRole = ({ authenticated, roMdn, currentUser, isPasswordReset, roleData, userRole, location, fromRoleStatus }) => {


    const [roleName, setRoleName] = useState('');
    const [maxUserAllowed, setMaxUserAllowed] = useState('');
    const [identicalTo, setIdenticalTo] = useState('');

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');

    useEffect(() => {

        document.title = "ShakePe Add New Role"

        if (location.state !== undefined && location.state.role !== undefined) {
            console.log('location.state', location.state)
            setRoleName(location.state.role)
        }
        if (location.state !== undefined && location.state.assignedUser !== undefined) {
            console.log('location.state', location.state)
            setMaxUserAllowed(location.state.assignedUser)
        }

    }, []);



    const saveRole = async (event) => {
        event.preventDefault();
        var request = {
            "roleName": roleName,
            "maxUserAllowed": maxUserAllowed === '' ? 0 : parseInt(maxUserAllowed),
            // "roleIdenticalTo": identicalTo,
            "status": 1,
            "roId": roMdn,
            "prevRole": location.state !== undefined && location.state.role !== undefined ? location.state.role : roleName,
            "type": location.state !== undefined && location.state.role !== undefined ? "update" : "add",
            "isAdminStatus": fromRoleStatus,
            "addOrUpdatedBy": currentUser
        }

        const url = process.env.REACT_APP_OEMS_ACL_ADD_ROLE_NAME
        console.log('request', url, request)

        var response = await apiCallService.postMethodCall(url, request);

        console.log('response', response)

        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            setDropDownHidden(true);
            setSeverty("success");
            setMessage(response.response_description);


            if (localStorage.getItem('from') === 'admin') {
                history.push("/superAdmin-roles-permissions")
                history.go()
            } else {
                history.push("/roles-permissions")
                history.go();
            }
        } else {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
        }

    }


    const setRole = (event) => {
        setRoleName(event.target.value);
    }

    const setMaxUser = (event) => {
        setMaxUserAllowed(event.target.value);
    }

    const setIdentical = (event) => {
        setIdenticalTo(event.target.value);
    }


    var roleUpdate = false;
    if (location.state !== undefined && location.state.assignedUser !== undefined) {
        roleUpdate = location.state.assignedUser !== 0;
        var max = location.state.assignedUser;
    }


    console.log('roleUpdate && (maxUserAllowed === 0 || maxUserAllowed === "")', maxUserAllowed, roleUpdate && (maxUserAllowed === 0 || maxUserAllowed === ""))

    return (
        <div >
            {(authenticated)
                ? (
                    isPasswordReset ?

                        <div className="mid-container">
                            <div className="container-fluid">



                                <div className="head">
                                    <div className="row">

                                        <div  className="col-10">
                                            <h3>Create Role</h3>
                                        </div>

                                        <div  className="col-2">
                                            <div className="actnbtn" data-toggle="tooltip" title="Back">
                                                {localStorage.getItem('from') === 'admin'
                                                    ?
                                                    <a href="/superAdmin-roles-permissions" ><i className="back"></i> </a>
                                                    :
                                                    <a href="/roles-permissions" ><i className="back"></i> </a>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Snackbar

                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => setDropDownHidden(false)}
                                >
                                    <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                        {message}
                                    </Alert>
                                </Snackbar>

                                <div className="row">
                                    <div className="col-12">
                                        <form className="createrole" onSubmit={saveRole}>

                                            <div className="row">

                                                <div className="col-12 col-md-4">
                                                    <div  className="webform">
                                                        <div  className="form-floating">
                                                            <input className="form-control" placeholder="Role Name" type="text" id="roleName" value={roleName} onChange={setRole} required disabled={roleUpdate} />
                                                            <label for="roleName">Role Name</label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-12 col-md-4">
                                                    <div  className="webform">
                                                        <div  className="form-floating">
                                                            <input className="form-control" placeholder="Max Asign User" type="number" id="maxUserAllowed" onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                value={maxUserAllowed} onChange={setMaxUser} />
                                                            <label for="maxUserAllowed">Max Asign User</label>
                                                        </div>
                                                    </div>
                                                    {roleUpdate && (parseInt(maxUserAllowed) === 0 || maxUserAllowed === "") ?
                                                        null
                                                        :
                                                        roleUpdate && maxUserAllowed < max
                                                            ?
                                                            <p style={{ color: "#f46a6a", marginTop: "-16px" }}> value must be greater than or equal to {max}</p> :

                                                            null
                                                    }
                                                </div>

                                                <div className="col-12">
                                                    {localStorage.getItem('from') === 'admin'
                                                        ?
                                                        <a className="mbtn btngr mt-3" href="/superAdmin-roles-permissions" type="reset">Cancel</a>
                                                        :
                                                        <a className="mbtn btngr mt-3" href="/roles-permissions" type="reset">Cancel</a>}
                                                    <button className="mbtn btnbl mt-3" type="submit">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, currentUser }, role: { roleData, fromRoleStatus },

}) => ({
    authenticated, userRole, roleData, roMdn, isPasswordReset, fromRoleStatus, currentUser

});

export default connect(mapStateToProps)(CreateRole);