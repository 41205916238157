import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "../../css/order-history.styles.css"
import Can from '../../../auth/can/Can';
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import LoadingCoponent from './order-history.loading.component';

const TransactionHistory = ({ authenticated, roleData, roMdn, userRole }) => {

    const [historyList, setHistoryList] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));

        const fun = async () => {

            var url = `${process.env.REACT_APP_CARD_ORDER_HISTORY}?roId=${roMdn}`

            var response = await apiCallService.getMethodCallWithoutParam(url);

            console.log('response history transaction', url, response);

            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response !== null && response.response.getCardOrder !== undefined) {


                response.response.getCardOrder.map(order => {
                    console.log('response history transaction order', order);
                    var dt = 0;

                    response.response.getCardOrder.map(order => {
                        if (dt === 0) {
                            setHistoryList(order);
                            dt++;
                        }
                    })

                })

                setLoading(false);
            } else {
                setLoading(false);
            }
        }

        fun();

    }, []);
    console.log('setHistoryList', historyList)

    return (
        < >
            {(authenticated)
                ? (
                    loading
                        ?
                        <LoadingCoponent />
                        :
                        JSON.stringify(historyList) === "{}"
                            ?
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="lastorder">

                                    <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_Details}
                                        <Can
                                            role={userRole}
                                            perform="manageCard-viewAllHistory:visit"
                                            roleData={roleData}
                                            yes={() => (

                                                <a href="/order-history" className="sbtn btnbl">{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_history}</a>

                                            )}
                                            no={() =>
                                                null
                                            }
                                        />

                                    </h3>

                                    <div className="col-12 ">
                                        <div className="no-record">
                                            <i></i>
                                            <h3 style={{ borderBottom: "none" }}> No Record Available.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="lastorder">
                                    <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_Details}

                                        <Can
                                            role={userRole}
                                            perform="manageCard-viewAllOrderHistory:visit"
                                            roleData={roleData}
                                            yes={() => (

                                                <a href="/order-history" className="sbtn btnbl">{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_history}</a>

                                            )}
                                            no={() =>
                                                null
                                            }
                                        />

                                    </h3>
                                    <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_ID} <strong>#{historyList.orderId}</strong></p>


                                    <div className="lstorderby">
                                        <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_By}</h5>
                                        <h6>{historyList.fullName}</h6>
                                        <p><i className="mobile"></i>+91-{historyList.mobile}</p>
                                        <p><i className="calander"></i>{historyList.dateCreatedOn}</p>
                                    </div>

                                    <div className="lstorderqty">
                                        <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Quantity}:</h5>
                                        <h4>{historyList.cardQuantity}</h4>
                                        <h5>Card Type:</h5>
                                        <h6>{historyList.cardType === 1 ? <>Physical</> : <>Virtual</>}</h6>
                                        {historyList.bankInvoiceurl !== '' && historyList.bankInvoiceurl !== null
                                            ?
                                            <a href="#" target="blank" className="invoice">Invoice</a>
                                            : null
                                        }
                                    </div>

                                    <div className="lstordradrs">
                                        {historyList.cardType === 0
                                            ?
                                            null
                                            :
                                            <div className="lstshipadrs">
                                                <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Shipping_Address}</h5>
                                                <h6>{historyList.shippingPersonName}</h6>
                                                <p>{historyList.shippingAddress}</p>
                                                <p><i className="mobile"></i>+91-{historyList.shippingPersonMdn}</p>
                                            </div>
                                        }

                                        <div className="lstbiladrs">
                                            <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Billing_Address}</h5>
                                            <h6>{historyList.fullName}</h6>
                                            <p>{historyList.billingAddress}</p>
                                            <p><i className="mobile"></i>+91-{historyList.mobile}</p>
                                        </div>
                                    </div>

                                    <div className="order-track">
                                        {/* <ul> */}
                                        <div className="row g-0">
                                            <div className="col">
                                                <div  className={historyList.orderedClass}>
                                                    <i className="dot"></i>
                                                    <i className="line"></i>
                                                    <p>Ordered</p>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div  className={historyList.paymentClass}>
                                                    <i className="dot"></i>
                                                    <i className="line"></i>
                                                    <p>Payment</p>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div  className={historyList.approvalClass}>
                                                    <i className="dot"></i>
                                                    <i className="line"></i>
                                                    <p>Order Approval</p>
                                                </div>
                                            </div>


                                            {historyList.cardType === 0
                                                ?
                                                null
                                                :
                                                <>

                                                    <div className="col">
                                                        <div  className={historyList.dispatchClass}>
                                                            <i className="dot"></i>
                                                            <i className="line"></i>
                                                            <p>Shipped</p>
                                                        </div>
                                                    </div>


                                                </>
                                            }
                                            {historyList.cardType === 0
                                                ?
                                                <div className="col">
                                                    <div  className={historyList.deliveredCLass}>
                                                        <i className="dot"></i>
                                                        <i className="line"></i>
                                                        <p>Order Completed</p>
                                                    </div>
                                                </div>
                                                :
                                                <>

                                                    <div className="col">
                                                        <div  className={historyList.deliveredCLass}>
                                                            <i className="dot"></i>
                                                            <i className="line"></i>
                                                            <p>Delivered</p>
                                                        </div>
                                                    </div>


                                                </>
                                            }

                                        </div>
                                        {/* </ul> */}
                                    </div>
                                </div>
                            </div>

                )
                : (
                    <Redirect to='/' />
                )


            }

        </>

    )

}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn
});

export default connect(mapStateToProps)(TransactionHistory);