import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import otpImg from '../../assests/otp-img.png'
import history from '../../auth/history';
import apiCallService from '../../service/api-call-service';
import shakePeLogo from '../../assests/shakepe-logo.png'



class ResetLoginPassword extends React.Component {

    constructor() {
        super();

        this.state = {

            isValidNew: false,
            isValidConfirm: false,

            newPassword: '',
            confirmPassword: "",



            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            message: "",
            severty: "success",
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,
            submitButton: true

        };
    }


    formSubmit = async (event) => {
        event.preventDefault();


        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({ isValidConfirm: false })
        } else {
            this.setState({ isValidConfirm: true })
            var url = process.env.REACT_APP_RESET_OR_UPDATE_LOGIN_PASSWORD;

            var request = {
                "email": this.props.email,
                "password": this.state.confirmPassword,
                "updateOrReset": "reset",
                // "referenceNum": this.props.referenceNum,
                // "otp": this.props.otp,
                "deviceInfo":
                {
                    "device_os": "11",
                    "device_os_version": "23",
                    "device_id": "12",
                    "imei": "1234",
                    "latitude": "2345",
                    "longitude": "23456",
                    "ipaddress": "2344",
                    "channel": "1",
                    "browserName": "fgg",
                    "deviceType": "ert",
                    "model_name": "hhjj",
                    "time_stamp": "12-12-122",
                    "locationCountry": "india",
                    "locationState": "bihar"

                }
            }


            var response = await apiCallService.postMethodCall(url, request)

            console.log('response formSubmit', response, request, url)

            if (response !== undefined && response.response_code === 0) {
                history.push("/password-reset-successful");
                history.go();
            } else {
                this.setState({ message: response.response_description })
            }

        }



    }

    inputChangeNewPassword = (event) => {
        console.log('event.target.value', event.target.value)
        this.setState({ newPassword: event.target.value, message: "" })
        var isValidCurrnetPasswrd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(event.target.value);
        console.log('event.target.value', isValidCurrnetPasswrd)
        this.setState({ isValidNew: isValidCurrnetPasswrd });
    }

    inputChangeConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value, message: "" })
        var isValidCurrnetPasswrd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(event.target.value);
        this.setState({ isValidConfirm: isValidCurrnetPasswrd && event.target.value === this.state.newPassword });
    }








    render() {


        return (
            // <div className="login-container">
            //     <div className="container-fluid">
            //         <div className="row flex-row-reverse">
            //             <div className="col-12 col-md-6 col-lg-5 col-xl-4 frmbg">
            //                 <div className="login-form">
            //                     <div className="logo"><img src={corpImg} alt="ZRO" className="img-fluid" /></div>
            //                     <h4>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Reset_Password}</h4>
            //                     <p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Please_enter_your_new_password_for_account}</p>

            //                     <>{this.state.message}</>
            //                     <form onSubmit={this.formSubmit}>
            //                         <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            //                             <input className="mdl-textfield__input abcd" type="text" id="password" style={{fontFamily:'text-security-disc'}} id="" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" onChange={this.inputChangeNewPassword} required />
            //                             <label className="mdl-textfield__label">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_New_Password}</label>
            //                             {
            //                                 this.state.isValidNew ?
            //                                     <i className="passok"></i>
            //                                     :
            //                                     <i className="passeeror"></i>
            //                             }

            //                         </div>

            //                         <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            //                             <input className="mdl-textfield__input abcd" type="text" id="password" style={{fontFamily:'text-security-disc'}} id="" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" onChange={this.inputChangeConfirmPassword} required />
            //                             <label className="mdl-textfield__label">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Confirm_Password}</label>
            //                             {
            //                                 this.state.isValidConfirm ?
            //                                     <i className="passok"></i>
            //                                     :
            //                                     <i className="passeeror"></i>
            //                             }
            //                         </div>

            //                         <div className="form-row">
            //                             <div className="col-12 col-sm-6">
            //                                 <button type="submit" id="" className="logbtn">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_RESET_PASSWORD_Submit}</button>
            //                             </div>
            //                         </div>

            //                         <div className="passtype">
            //                             <p>Password should be of 8 characters with atleast:</p>
            //                             <ul>
            //                                 <li>1 UPPER Case (A-Z)</li>
            //                                 <li>1 lower case (a-z)</li>
            //                                 <li>1 numeric character (0-9)</li>
            //                                 <li>1 special character (!, @, #, $, %, ?, &)</li>
            //                             </ul>
            //                         </div>
            //                     </form>
            //                 </div>
            //             </div>

            //             <div className="col-12 col-md-6 col-lg-7 col-xl-8 logbg">
            //                 <div className="forgot-box">
            //                     <img src={otpImg} alt="OTP" className="img-fluid" />
            //                 </div>
            //             </div>

            //         </div>
            //     </div>
            // </div>
            <div  className="log-container">
                <div  className="container">
                    <div  className="row justify-content-sm-center">
                        <div  className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div  className="logbox">
                                <div  className="logtxt">
                                    <img src={shakePeLogo} alt="ShakePe" className="img-fluid" />
                                    <h3>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Reset_Password}</h3>
                                    <p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Please_enter_your_new_password_for_account}</p>
                                </div>

                                <>{this.state.message}</>

                                <div  className="login-form">

                                    <form onSubmit={this.formSubmit}>
                                        <div  className="form-row">
                                            <div  className="form-floating">
                                                <input
                                                    type="password"
                                                     className="form-control"
                                                    id="newPassword"
                                                    placeholder="New Password"
                                                    value={this.state.email}
                                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                                    onChange={this.inputChangeNewPassword}
                                                    required
                                                />

                                                <label for="newPassword">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_New_Password}</label>




                                                {
                                                    this.state.isValidNew ?
                                                        <i className="passok"></i>
                                                        :
                                                        <i className="passeeror"></i>
                                                }

                                            </div>
                                        </div>



                                        <div  className="form-row">
                                            <div  className="form-floating">
                                                <input
                                                    type="password"
                                                     className="form-control"
                                                    id="cnfrmPassword"
                                                    placeholder="Confirm Password"
                                                    value={this.state.email}
                                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                                    onChange={this.inputChangeConfirmPassword}
                                                    required
                                                />

                                                <label for="cnfrmPassword">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Confirm_Password}</label>




                                                {
                                                    this.state.isValidConfirm ?
                                                        <i className="passok"></i>
                                                        :
                                                        <i className="passeeror"></i>
                                                }
                                            </div>

                                        </div>

                                        <div  className="form-row">

                                            <button type="submit" id="" className="lbtn btnbl"  >{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_RESET_PASSWORD_Submit}</button>


                                            <a href="/" className="forgot">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Back_to_login}</a>
                                        </div>



                                        <div className="passtype">
                                            <p>Password should be of 8 characters with atleast:</p>
                                            <ul>
                                                <li>1 UPPER Case (A-Z)</li>
                                                <li>1 lower case (a-z)</li>
                                                <li>1 numeric character (0-9)</li>
                                                <li>1 special character (!, @, #, $, %, ?, &)</li>
                                            </ul>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ token: { authenticated }, role: { roleData }, forgotPassword: { email, otp, referenceNum }

}) => ({
    authenticated, roleData, email, otp, referenceNum

});

export default connect(mapStateToProps)(ResetLoginPassword); 