import React from 'react';
import UploadCard from '../component/upload-card.component';

const RecordList = ({ employeeList, recordType }) => {
    var i = 0;
    console.log('insufr dcrd jift ', employeeList)
    console.log('recordType ', recordType === "fail")
    return (



        <div className="col-12">
            <div className="table-responsive">
                <table className="table table-hover table-bordered">
                    <tbody>
                        <tr className="table-primary">
                            <th>SNo.</th>
                            <th>Full Name</th>
                            <th>Mobile No.</th>
                            <th>Email ID</th>
                            <th>Employee ID</th>
                            <th>Department</th>
                            <th>Band</th>
                            <th>Reporting Manager ID</th>
                            <th>Location</th>
                            {
                                recordType === "fail" ?
                                    <th>Reason</th> :
                                    null
                            }
                        </tr>

                        {
                            employeeList.map(bulkDetail => (
                                <tr>
                                    <td>{++i}</td>
                                    <td>{(bulkDetail.firstName === null && bulkDetail.lastName === null) ? <>NA</> : <>{bulkDetail.firstName} {bulkDetail.lastName}</>}</td>
                                    <td>{bulkDetail.mobileNo === null || bulkDetail.mobileNo === "" ? <>NA</> : bulkDetail.mobileNo}</td>
                                    <td>{bulkDetail.email === null || bulkDetail.email === "" ? <>NA</> : bulkDetail.email}</td>
                                    <td>{bulkDetail.employeeId === null || bulkDetail.employeeId === "" ? <>NA</> : bulkDetail.employeeId}</td>
                                    <td>{bulkDetail.department === null || bulkDetail.department === "" ? <>NA</> : bulkDetail.department}</td>
                                    <td>{bulkDetail.corpBands === null || bulkDetail.corpBands === "" ? <>NA</> : bulkDetail.corpBands}</td>
                                    <td>{bulkDetail.managerEmpCorpId === null || bulkDetail.managerEmpCorpId === "" ? <>NA</> : bulkDetail.managerEmpCorpId}</td>
                                    <td>{bulkDetail.location === null || bulkDetail.location === "" ? <>NA</> : bulkDetail.location}</td>
                                    {
                                        recordType === "fail" ?
                                            <td>{bulkDetail.responseDescription === null || bulkDetail.responseDescription === "" ? <>NA</> : bulkDetail.responseDescription}</td>
                                            :
                                            null
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>



    )
};



export default RecordList;
