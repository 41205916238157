
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';


class IssuedGiftCard extends React.Component {

    constructor() {
        super();

        this.state = {
            balance: [],
            cardDetail: [],
            orderDetail: [],
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards History"

        localStorage.setItem('url', '');

        if (this.props.orderDetail !== undefined && this.props.orderDetail) {
            console.log("this.props.location.state.orderDetail ", this.props.orderDetail)
            this.setState({ orderDetail: this.props.orderDetail.subOrderDetails })
            this.setState({ balance: this.props.orderDetail.cardBalance })
            this.setState({ cardDetail: this.props.orderDetail.cardDetail })


        } else {

        }


    }



    // getBalance = async (orderDetail) => {

    //     this.setState({ balanceLoading: true })
    //     var url = process.env.REACT_APP_GET_GIFT_CARD_BALANCE
    //     var request = {
    //         "request_id": GenerateRequestId.geneRateRequestId(),
    //         "gift_card_user_id": orderDetail.giftcard_userId,
    //         "entity_id": orderDetail.entity_id,
    //     }
    //     var response = await apiCallService.postMethodCall(url, request, '');
    //     console.log("ybl balance response", response);
    //     if (response !== undefined && response.response_code !== undefined && response.response_code === '0' && response.response !== undefined && response.response !== null) {
    //         this.setState({ balance: response.response })
    //         this.setState({ balanceLoading: false })
    //     } else {
    //         this.setState({ balanceLoading: false })
    //     }
    // }

    // fetchCardDetail = async (orderDetail) => {
    //     this.setState({ cardDetailLoading: true })
    //     var url = process.env.REACT_APP_FETCH_CARD_DETAILS
    //     var request = {
    //         "request_id": GenerateRequestId.geneRateRequestId(),
    //         "gift_card_user_id": orderDetail.giftcard_userId,
    //         "entity_id": orderDetail.entity_id,
    //     }
    //     var response = await apiCallService.postMethodCall(url, request, '');
    //     console.log("ybl card detail response", response);
    //     if (response !== undefined && response.response_code !== undefined && response.response_code === '0' && response.response !== undefined && response.response !== null) {
    //         this.setState({ cardDetailLoading: false })
    //         this.setState({ cardDetail: response.response })
    //     } else {
    //         this.setState({ cardDetailLoading: false })
    //     }
    // }




    render() {

        console.log(" balance ybl", this.state.balance)
        console.log(" cardDetail ybl", this.state.cardDetail)
        console.log(" orderDetail ybl ", this.state.orderDetail)
        var exYr = "N";
        var exD = "A";
        if (this.state.cardDetail != null && this.state.cardDetail.expiryDateList != null && this.state.cardDetail.expiryDateList !== undefined) {
            exYr = this.state.cardDetail.expiryDateList[0].slice(2, 4);
            exD = this.state.cardDetail.expiryDateList[0].slice(0, 2);
        }
        var expDate = `${exD}/${exYr}`

        console.log("exyr is ybl " + exYr + " exD is ybl " + exD);

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>


                                <tr>
                                    <td>{this.props.num}</td>
                                    <td>#{this.state.orderDetail.requestId}</td>
                                    <td>{this.state.orderDetail.creation_date}</td>
                                    <td>{this.state.cardDetail != null && this.state.cardDetail.cardList !== undefined ? this.state.cardDetail.cardList[0] : <>NA</>}</td>
                                    <td> {expDate}</td>
                                    <td>₹ {parseFloat(parseFloat(this.state.orderDetail.amount) / 100).toFixed(2)}</td>
                                    <td>₹ {this.state.balance.balance}</td>
                                </tr>




                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser

});

export default connect(mapStateToProps)(IssuedGiftCard);