import React from "react";
import '../css/small.popup.css';
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action';
import { connect } from 'react-redux';

import { Redirect } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import img from '../../assests/icons/arrow-down.svg'
import apiCallService from "../../service/api-call-service";
// import CardStatus from '../../constants/card-status.type';


class MultiCardSmallPopUp extends React.Component {

  constructor() {
    super();

    this.state = {

      reason: "",
      cardOption: [],
      mandatoryMessage: "Please Select Reason",
      messageShow: false

    };
  }


  componentDidMount = () => {

    console.log("cardDetails in small popup", this.props.cardDetails)

    this.getCardOptions();

  }


  getCardOptions = async () => {

    console.log("sabse alag log lagao taki pata chae yha aa rha hai", this.props.targetid)
    if (this.props.targetid.includes("perm") || this.props.targetid.includes("permtBlock-employee") || this.props.targetid.includes("pBlock")) {


      var url = process.env.REACT_APP_CORPORATE_CARDSERVICE_GETPERMBLOCKREASON;

      var response = await apiCallService.getMethodCallWithoutParam(url);

      // console.log("option load ku nhi ho rhe bat kya hai", response)
      if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.cardBlockedReasons !== undefined && response.cardBlockedReasons !== null) {

        var arr = []
        response.cardBlockedReasons.map(dd => {
          var label = "label";
          var value = "value";
          dd[label] = dd.reason
          dd[value] = dd.reason
          arr.push(dd)
          this.setState({ cardOption: arr })
        })
        // console.log("option load ku nhi ho rhe bat kya hai", arr)
        // this.setState({ cardOption: arr })
      }


    }
    // if (this.props.targetid.includes("temp") || this.props.targetid.includes("tBlock") || this.props.targetid.includes("tempBlock-employee")) {
    //   var urlN = process.env.REACT_APP_CORPORATE_CARDSERVICE_GETTEMPBLOCKREASON;

    //   var responseN = await apiCallService.getMethodCallWithoutParam(urlN);

    //   console.log("sabse alag log lagao taki pata chae yha aa rha hai", this.props.targetid, "  ", responseN)
    //   if (responseN !== undefined && responseN.response_code !== undefined && responseN.response_code === 0 && responseN.temporaryCardBlockedReasons !== undefined && responseN.temporaryCardBlockedReasons !== null) {

    //     var arrNew = []
    //     responseN.temporaryCardBlockedReasons.map(dd => {
    //       var label = "label";
    //       var value = "value";
    //       dd[label] = dd.reason
    //       dd[value] = dd.reason
    //       arrNew.push(dd)
    //       this.setState({ cardOption: arrNew })
    //     })
    //     // console.log("option load ku nhi ho rhe bat kya hai", arr)
    //     // this.setState({ cardOption: arr })
    //   }

    // }





  }


  handleCardAssignedChange = (newValue, actionMeta) => {
    console.group('Value Changed handleChange');
    // const inputValue = newValue.replace(/\W/g, '');
    console.log('Value Changed handleChange newValue', newValue);
    console.log(`action handleChange: ${actionMeta.action}`);
    console.groupEnd();
    if (newValue !== null && newValue.value !== null) {

      this.setState({ messageShow: false })
      this.setState({ reason: newValue });

    } else {
      this.setState({ messageShow: false })
      this.setState({ reason: '' });
    }
  };






  filterColors = (inputValue) => {
    console.log("this.state.option ", this.state.cardOption)
    return this.state.cardOption !== undefined && this.state.cardOption.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterColors(inputValue));
    }, 3000);
  };



  render() {


    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        border: "none",
        borderRadius: "0px",
        boxShadow: "none",
        minHeight: "22px",
        lineHeight: "22px",
        background: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "12px",
        backgroundPosition: "center right",
        marginTop: "-7px"

      }),

      valueContainer: (provided, state) => ({
        ...provided,
        padding: "0px 5px 0px 0px"
      }),

      indicatorContainer: (provided, state) => ({
        ...provided,
        padding: "5px",
        margin: "100px"
      }),
      option: (provided, state) => ({
        ...provided,
        //   borderBottom: '1px dotted pink',
        backgroundColor: state.isDisabled ? '#2684FF' : state.isSelected ? '#deebff' : '',
        color: state.isSelected ? '#354558' : state.isDisabled ? "#ffffff" : '',
        fontSize: state.isDisabled ? "16px" : "14px",
        padding: "8px",
        cursor: state.isDisabled ? '' : state.isSelected ? '' : "pointer",
        "&:hover": {
          backgroundColor: "#deebff",
          color: "354558"
        },


      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#354558',
        fontSize: "15px",
        cursor: "pointer"
      }
      ),
      placeholder: (provided, state) => ({
        ...provided,
        color: 'hsl(0,0%,80%)',
        fontSize: "16px",
      })
    }

    return (
      < >
        {(this.props.authenticated)
          ? (


            <div
              className="modal fade"
              id={this.props.targetid}
              tabIndex="-1"
              data-bs-keyboard="false"
              data-bs-backdrop="static"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content alerttext">
                  <div className="modal-header">
                    <h5 className="modal-title">{this.props.headerText}</h5>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body" style={{ lineHeight: "1.5" }}>
                    {this.props.bodyText}

                    {(this.props.targetid.includes("perm") || this.props.targetid.includes("temp") || this.props.targetid.includes("tBlock")
                      || this.props.targetid.includes("permtBlock-employee") || this.props.targetid.includes("pBlock"))
                      &&
                      <div className="assign-avail-card">
                        <label htmlFor="cars"> Reason : </label>

                        <AsyncSelect
                          placeholder="Select Reason"
                          cacheOptions
                          loadOptions={this.loadOptions}
                          defaultOptions
                          onChange={this.handleCardAssignedChange}
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                        />
                        {
                          this.state.messageShow
                            ?
                            <p style={{ color: "#f05258", fontSize: "12px" }}>{this.state.mandatoryMessage}</p>
                            :
                            null
                        }
                      </div>
                    }
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="mbtn btngr"
                      data-bs-dismiss="modal"
                    >
                      {this.props.buttonActionClose}
                    </button>
                    {
                      this.state.reason === "" && (this.props.targetid.includes("perm") || this.props.targetid.includes("temp") || this.props.targetid.includes("tBlock")
                        || this.props.targetid.includes("permtBlock-employee") || this.props.targetid.includes("pBlock"))
                        ?
                        <button type="button" className="mbtn btnbl" onClick={(e) => this.setState({ messageShow: true })}>

                          {this.props.buttonActionName}
                        </button>
                        :

                        <button type="button" className="mbtn btnbl" data-bs-dismiss="modal" onClick={(e) => this.props.onclickFunctionName(e, this.props.allValues, this.props.cardDetails, this.state.reason)}>
                          {/* <button type="button" className="mbtn btnbl" data-bs-dismiss="modal" onClick={toggleDropDownHidden}> */}
                          {this.props.buttonActionName}
                        </button>
                    }





                  </div>
                </div>
              </div>
            </div>

          )
          : (
            <Redirect to='/' />
          )


        }

      </>

    )
  }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
  authenticated, userRole, roleData, roMdn, mdn

});

const mapDispatchToProps = dispatch => ({
  toggleDropDownHidden: (data) => dispatch(toggleDropDownHidden(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiCardSmallPopUp);
