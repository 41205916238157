import React, { useState, useEffect } from "react";
import '../styles/corporate.css'
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import moment from 'moment';
import GenerateRequestId from "../../common-function/GenerateRequestId";
import apiCallService from "../../service/api-call-service";
import { store } from "../../redux/store";
import { setActiveHeader } from "../../redux/activeHeader/activeheader.action";
import CategoryLimit from "../../constants/category-limit.type";
import UploadImage from '../../employee/component/upload-image.component';
import history from '../../auth/history';

import CreatableSelect from 'react-select/creatable';
import img from '../../assests/icons/arrow-down.svg'
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { setSeverty } from "../../redux/message/message.action";
import { sha256 } from 'js-sha256';
import { setBankAddAndUpdateUrl, setBankBackButton, setButtonName } from "../../redux/user/user.actions";


var k = 0;
const AddNewCorp = ({ authenticated, token, roMdn, currentUser }) => {


    const [comapnyName, setCompanyName] = useState('');
    const [comapanyEmailId, setCompanyEmailId] = useState('');
    const [phnNum, setPhnNum] = useState('');
    const [roId, setRoId] = useState('');
    const [terminalId, setTerminalId] = useState('');
    const [panTanNum, setPanTanNumber] = useState('');
    const [gstNum, setGstNum] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPinCode] = useState('');
    const [category, setCategory] = useState([]);
    const [foodCategory, setFoodCategory] = useState('');
    const [travelCategory, setTravelCategory] = useState('');
    const [contactPersonFirstName, setContactPersonFirstName] = useState('');
    const [contactPersonLastName, setContactPersonLastName] = useState('');
    const [contactPersonMobNum, setContactPersonMobNum] = useState('');
    const [contactPersonEmail, setContactPersonEmail] = useState('');
    const [districtBilling, setDistrictBilling] = useState('');

    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [pricePlan, setPricePlan] = useState([]);

    const [corpPlanAssigned, setCorpPlanAssigned] = useState('');
    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setseverty] = useState('error');


    const [uploadedDocumemnt, setUploadedDocument] = useState([]);

    const [documentList, setDocumentList] = useState([]);

    const [documentName, setDocumentName] = useState('');

    const [files, setFiles] = useState([]);
    const [size, setSize] = useState(0);
    const [image, setImage] = useState('');

    const [hidden, setHidden] = useState('hidden');
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState();
    const [uploadPercentage, setuploadPercentage] = useState('')
    const [total, setTotal] = useState('');
    const [loaded, setLoaded] = useState('');

    const [inputKey, setInputKey] = useState(Date.now());


    const [processBar, setProcessBar] = useState(false);


    const [showInputDoc, setshowInputDoc] = useState(false);

    const [documentNameForSelect, setDocumentNameForSelect] = useState('');


    const [tableLoading, setTableLoading] = useState(false);
    const [tableDate, setTableDate] = useState(false);




    const generateHash = (messageBody) => {
        //var msg= JSON.stringify(messageBody) ;
        var privateKey = process.env.REACT_APP_CLIENT_ID;
        var hash = sha256.hmac.create(privateKey);
        hash.update(messageBody);
        var hexData = hash.hex();
        console.log('hexed data', hexData);
        var base64String = btoa(hexData);
        console.log(messageBody, '---->hash Generated --->', base64String);
        return base64String;
    }



    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",

        }

        store.dispatch(setActiveHeader(payload))

        localStorage.setItem('url', '');
        document.title = "ShakePe Add New Corporate"


        setRoId(GenerateRequestId.generateRONumber());
        setTerminalId(GenerateRequestId.generateRONumber())
        console.log('url for add corporate', process.env.REACT_APP_ADD_NEW_CORPORATE);
        getStates();
        getPricePlan();


        getDocumemntAllowed();



    }, []);

    const getDocumemntAllowed = async () => {


        var url = process.env.REACT_APP_GET_CORP_DOCUMENT_ALLOWED;

        var response = await apiCallService.getMethodCallWithoutParam(url);


        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            var documentArray = [];
            response.documents.map(docu => {
                var value = "value";
                var label = "label";
                var isDisabled = "isDisabled";
                docu[isDisabled] = false
                docu[value] = docu.document
                docu[label] = docu.document
                documentArray.push(docu);

            })
        }


        // var documentArray = [{ "value": "Agreement", "label": "Agreement", isDisabled: false }, { "value": "Company Pan", "label": "Company Pan", isDisabled: false }, { "value": "Other", "label": "Other" }];

        setDocumentList(documentArray);
    }

    const reset = async () => {
        history.push('/add-new-corp')
        history.go();
    }

    const getPricePlan = async () => {

        const url = process.env.REACT_APP_GET_CORPORATE_PRICE_PLAN;

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log(response);
        if (response !== undefined && response.pricePlans !== undefined && response.pricePlans !== null) {
            setPricePlan(response.pricePlans);
        }

    }

    const getStates = async () => {

        var url = process.env.REACT_APP_OEMS_GET_ALL_STATES;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("state response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("state response", response.statesList);

            var arr = [];

            response.statesList.map(state => {
                var value = "value";
                var label = "label";
                state[value] = state.stateName;
                state[label] = state.stateName;
            })

            setStateList(response.statesList);
        }
    }

    const validateInputLenght = (object) => {
        console.log("max length", object.target, "object.target", object.target.maxLength, " object.target.value.length ", object.target.value.length)
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const getDistrict = async (id, type) => {

        var distListTOSet = [];
        console.log('get cities method call', id)
        var url = `${process.env.REACT_APP_OEMS_GET_ALL_DISTRICT}/${id}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("districtList response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("districtList response", response.districtList);

            response.districtList.map(disstrict => {

                var value = "value";
                var label = "label";
                disstrict[value] = disstrict.distName;
                disstrict[label] = disstrict.distName;
                distListTOSet.push(disstrict);


            })


            setDistrictList(distListTOSet);


        }

    }

    const getCities = async (id) => {

        var cityListTOSet = [];
        console.log('get cities method call', id)


        var urlDis = `${process.env.REACT_APP_OEMS_GET_ALL_CITIES}/${id}`;

        var responsecityList = await apiCallService.getMethodCallWithoutParam(urlDis);
        console.log("cityList response", responsecityList);

        if (responsecityList !== undefined && responsecityList.response_code !== undefined && responsecityList.response_code === 0) {
            console.log("cityList response", responsecityList.cityList);

            responsecityList.cityList.map(city => {
                var value = "value";
                var label = "label";
                city[value] = city.cityName;
                city[label] = city.cityName;
                cityListTOSet.push(city);


            })


            setCityList(cityListTOSet);

            console.log('setCityList(cityListTOSet);', cityList)
        }


    }




    const addCorporate = async (event) => {
        event.preventDefault();
        setProcessBar(true)
        if (corpPlanAssigned === '') {
            return (
                setProcessBar(false),
                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Please_Select_Atleast_One_Plan)
            )
        }



        if (uploadedDocumemnt.length > 0) {
            uploadedDocumemnt.map(docType => {
                if (docType.documentType !== "" && docType.documentUrl === "") {
                    return (
                        setProcessBar(false),
                        setDropDownHidden(true),
                        setSeverty('error'),
                        setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Please_Upload_SelectedDocType_Document)
                    )
                }
            })
        }

        if (documentName !== "" && uploadedDocumemnt.length === 0) {
            return (
                setProcessBar(false),
                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Please_Upload_SelectedDocType_Document)
            )
        }



        if (category !== null && category.length > 0) {
            setProcessBar(true);
            const url = process.env.REACT_APP_ADD_NEW_CORPORATE;
            const imgUrl = localStorage.getItem('url')
            var fullAddress = `${address1}, ${address2}, ${city}, ${state} - ${pincode}`;
            var request = {
                "request": {
                    "ro_id": roId,
                    "corporateRequest":
                    {
                        "name": comapnyName,
                        "email": comapanyEmailId,
                        "companyNumber": phnNum,
                        "panTanNum": panTanNum,
                        "gstNum": gstNum,
                        "address": fullAddress,
                        "contact_first_name": contactPersonFirstName,
                        "contact_last_name": contactPersonLastName,
                        "contact_number": contactPersonMobNum,
                        "contact_email": contactPersonEmail,
                        // "terminal_id": terminalId,
                        "is_active": 1,
                        "callback": "null",
                        "created_byRoId": roMdn,
                        "created_byEmail": currentUser,
                        "pincode": pincode,
                        "logoUrl": imgUrl,
                        "address1": address1,
                        "address2": address2,
                        "city": city,
                        "state": state,
                        "district": district,
                        "roId": roId,
                        "contact_person": contactPersonFirstName + " " + contactPersonLastName


                    },
                    "corpLimit": category,
                    "pricePlanCode": corpPlanAssigned,
                    "document": uploadedDocumemnt

                }
            }

            console.log('request from add corporate API', request);

            var response = await apiCallService.postMethodCall(url, request);

            console.log('response from add corporate API', response);

            if (response !== undefined && response.response_code === 0) {
                setProcessBar(false)



                store.dispatch(setBankBackButton('/add-corp-emp'));


                store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_ADD_BANK_DETAIL_Without_OTP));

                store.dispatch(setButtonName(process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Skip_Button));



                history.push({
                    pathname: '/add-bank-account',
                    state: { corpDetail: request.request.corporateRequest }
                })
                history.go();


            } else {
                setProcessBar(false)
                setDropDownHidden(true);
                setSeverty('error');
                setMessage(response.response_description);
            }


        } else {
            setProcessBar(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Please_Select_Atleast_One_Category);
        }




    }

    const setCaltegoryArray = (event) => {
        var arr = [];
        if (event.target.checked) {
            arr.push(event.target.id);
        }

        category.map(val => {
            if (event.target.id === val && !event.target.checked) {

            } else {
                arr.push(val);
            }

        })

        setCategory(arr);
    }



    const handleStateChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        console.log('event.target', customAtrribute)


        setState(event.target.value)
        getDistrict(customAtrribute, '');

    }
    const handleDistrictChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');


        setDistrictBilling(event.target.value)
        setDistrict(event.target.value)
        console.log('event.target', customAtrribute)
        getCities(customAtrribute);

    }

    const handleCityChange = (event) => {

        setCity(event.target.value)

    }

    const assignPlanToCorp = (event) => {
        console.log('event.target.id', event.target.id)
        console.log('event.target.name', event.target.name)
        setCorpPlanAssigned(event.target.id);
    }

    const handleDepartmentChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {
            if (newValue.value.toLowerCase().includes("other")) {
                setshowInputDoc(true);
                setDocumentNameForSelect(newValue.value)
                setDocumentName('')
            } else {
                setDocumentNameForSelect(newValue.value)
                setDocumentName(newValue.value)
                setshowInputDoc(false);
            }
        } else {
            setDocumentName('')
            setDocumentNameForSelect('')
        }
    };
    const handleDepartmentInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed handleInputChange');
        console.log(inputValue);
        console.log(`action handleInputChange: ${actionMeta.action}`);
        console.groupEnd();
    };



    const uploadDocument = (event) => {
        setFiles([]);
        console.log('file event', event.target);
        console.log('file event', event.target.files[0]);
        setSize(event.target.files[0].size)
        if (documentName === '' && documentNameForSelect.toLowerCase().includes("other")) {
            return (

                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_Please_Select_Document_NAME_FOR_DOCUMENT_TYPE_OTHER))
        } else

            if (documentName === '') {
                return (

                    setDropDownHidden(true),
                    setSeverty('error'),
                    setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Please_Select_Document_Type))
            }
            else

                if (uploadedDocumemnt.length === 5) {
                    return (setHidden(''),
                        setLoading(false),
                        setSuccess(false),
                        setuploadPercentage(0),
                        setDropDownHidden(true),
                        setSeverty('error'),
                        setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Number_of_File_Extended))
                } else

                    if (event.target.files[0].type.includes('jpeg') || event.target.files[0].type.includes('jpg') || event.target.files[0].type.includes('png') || event.target.files[0].type.includes('doc') || event.target.files[0].type.includes('pdf') || event.target.files[0].type.includes('zip')) {
                        console.log('Inside ', event.target.files[0].type)

                        if (event.target.files[0].size > parseInt(process.env.REACT_APP_CORP_DOCUMENT_SIZE_IN_KB) * 1000) {
                            return (
                                setHidden(''),
                                setLoading(false),
                                setSuccess(false),
                                setuploadPercentage(100),
                                setDropDownHidden(true),
                                setSeverty('error'),
                                setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_File_Size_extended)
                            )
                        }

                        setuploadPercentage(1);
                        setHidden('');
                        setFiles(event.target.files[0])
                        if (event.target.files && event.target.files[0]) {
                            setImage(URL.createObjectURL(event.target.files[0]))

                        }

                        const file = event.target.files[0];
                        const url = process.env.REACT_APP_BULK_UPLOAD
                        //const url = 'http://localhost:7072/api/upload';
                        const formData = new FormData();
                        formData.append('file', file)

                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }

                        const options2 = {
                            onUploadProgress: (progressEvent) => {
                                const { loaded, total } = progressEvent;
                                let percent = Math.floor((loaded * 100) / total)
                                console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                                setTotal(total / 100)
                                setLoaded(loaded)

                                if (percent < 100) {
                                    setuploadPercentage(percent)
                                }
                            }
                        }
                        var hashCodeForHeader = generateHash(JSON.stringify(formData));
                        const headers = {
                            "device_info": "",
                            "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
                            "hashCode": hashCodeForHeader,
                            "loggedInUser": currentUser
                        }
                        const options = {
                            method: "POST",
                            body: formData,
                            headers: headers
                            // If you add this, upload won't work
                            // headers: {
                            //   'Content-Type': 'multipart/form-data',
                            // }
                        };

                        console.log('url', url)
                        fetch(url, options, options2).then(response => { return response.json(); })
                            .then(responseData => {
                                console.log(responseData.fileDownloadUri);
                                setuploadPercentage(100)

                                if (responseData.fileDownloadUri != undefined) {
                                    var extrxctUrl = responseData.fileDownloadUri.split('/')
                                    console.log('extrxctUrl', extrxctUrl);

                                    setLoading(false);
                                    setSuccess(true)
                                    setuploadPercentage(100)

                                    var anArrayForDoc = [];
                                    uploadedDocumemnt.map(up => {
                                        anArrayForDoc.push(up);
                                    })

                                    if (documentName !== '') {
                                        var variableCnnn = {
                                            documentType: documentName,
                                            documentUrl: `${process.env.REACT_APP_API_FILES_URL}/${extrxctUrl[5]}`,
                                            status: "0"
                                        }
                                        anArrayForDoc.push(variableCnnn);
                                    }


                                    setUploadedDocument(anArrayForDoc);
                                    var array = [];
                                    documentList.map(list => {
                                        if (list.label === documentName && list.label !== 'Other') {
                                            list.isDisabled = true
                                        }
                                        array.push(list)
                                    })
                                    setDocumentName('');
                                    setDocumentNameForSelect('')
                                    setshowInputDoc(false);
                                    setDocumentList(array);
                                } else {
                                    console.log("error.data exception hai", responseData.message);
                                    setHidden('')
                                    setLoading(false)
                                    setSuccess(false)
                                    setuploadPercentage(100)
                                    setDropDownHidden(true);
                                    setSeverty('error');
                                    setMessage(responseData.message);
                                }



                            })
                            .catch(error => {
                                console.log(error.data);
                                setHidden('')
                                setLoading(false)
                                setSuccess(false)
                                setuploadPercentage(100)
                                setDropDownHidden(true);
                                setSeverty('error');
                                setMessage("Some Error in Service call.");
                            })

                    } else {

                        setHidden('')
                        setLoading(false)
                        setSuccess(false)
                        setuploadPercentage(100)
                        setDropDownHidden(true);
                        setSeverty('error');
                        setMessage(process.env.REACT_APP_Var_FOR_CORP_ADD_MESSAGE_FOR_Incorrect_file_Type);

                    }
    }


    const successPreview = () => {
        setHidden('hidden')
        setFiles([])
        setLoading(true)
        setInputKey(Date.now())

        var array = [];
        documentList.map(list => {
            if (list.label === documentName && list.label !== 'Other') {
                list.isDisabled = true
            }
            array.push(list)
        })

        setDocumentList(array);


    }

    const removeDocFromArray = (doc, event) => {

        var final = []
        uploadedDocumemnt.map(uplo => {
            if (uplo.documentType === doc) {

            } else {
                final.push(uplo);
            }
        })

        setUploadedDocument(final);

        var array = [];
        documentList.map(list => {
            if (list.label === doc && list.label !== 'Other') {
                list.isDisabled = false
            }
            array.push(list)
        })

        setDocumentList(array);
    }


    const removePreview = () => {
        setHidden('hidden')
        setFiles([])
        setLoading(true)
        setInputKey(Date.now())
    }


    const getTableDetailPlan = async (event, priceCode) => {

        setTableLoading(true);
        setTableDate([]);

        const url = `${process.env.REACT_APP_GET_PRICE_DETAIL_API_URL}?priceDetail=${priceCode}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setTableDate(response);
        }

    }



    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'white',
            border: "none",
            borderRadius: "0px",
            boxShadow: "none",
            minHeight: "22px",
            lineHeight: "22px",
            background: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "12px",
            backgroundPosition: "center right",
            marginTop: "-7px"
        }),

        valueContainer: styles => ({
            ...styles,
            padding: "0px 5px 0px 0px"
        }),


        menu: styles => ({
            ...styles,
            zIndex: "9999"
        }),


        input: styles => ({
            ...styles,
            margin: "0px"
        }),



        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //   const color = chroma(data.color);
            return {
                ...styles,
            };
        },



    };


    console.log("Document uploadDocument List", uploadedDocumemnt)

    return (

        <>
            {(authenticated)
                ? (
                    <div className="mid-container">
                        <div className="container-fluid">

                            <div className="head">
                                <div className="row">
                                    <div className="col-10">
                                        <h3>Add New Corporate</h3>
                                    </div>

                                    <div className="col-2">
                                        <div className="actnbtn">
                                            <a href="/corp-list" data-toggle="tooltip" title="Back" ><i className="back"></i></a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <Snackbar

                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={dropDownHidden}
                                autoHideDuration={20000}
                                onClose={e => setDropDownHidden(false)}
                            >
                                <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                    {message}
                                </Alert>
                            </Snackbar>

                            <form className="add-member" onSubmit={addCorporate}>
                                <div className="row">

                                    <div className="col-12 col-lg-9">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text" pattern="[a-zA-Z0-9.- ]{2,100}" id="cname" value={comapnyName} onChange={e => { setCompanyName(e.target.value) }} required />
                                                        <label for="cname">Company Name</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="email" id="comapanyEmailId" maxLength="50" value={comapanyEmailId} onChange={e => { setCompanyEmailId(e.target.value) }} required />
                                                        <label for="comapanyEmailId">Company Email ID</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" id="mob-input"
                                                            type="number"
                                                            maxLength="10"
                                                            pattern="[0-9]{10}"
                                                            onInput={validateInputLenght}
                                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                            value={phnNum} onChange={e => { setPhnNum(e.target.value) }} required />
                                                        <label for="mob-input">Phone Number</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text" id="roId" value={roId} onChange={e => { setRoId(e.target.value) }} required disabled />
                                                        <label for="roId">{process.env.REACT_APP_ADD_NEW_CORPORATE_VARIABLE_FOR_ROID_LABEL}</label>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text"
                                                            id="panTanNum"
                                                            maxlength="10"
                                                            pattern="[A-Z]{4}[A-Z0-9]{1}[0-9]{4}[A-Z]{1}" value={panTanNum} onChange={e => { setPanTanNumber(e.target.value) }} required />
                                                        <label for="panTanNum">PAN/TAN Number</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" maxLength="15" onInput={validateInputLenght} type="text" pattern="\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}" value={gstNum} onChange={e => { setGstNum(e.target.value) }} required />
                                                        <label for="adr-input">GST Number</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text" id="ad1" maxLength="100" value={address1} onChange={e => { setAddress1(e.target.value) }} required />
                                                        <label for="ad1">Address 1</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text" id="" maxLength="100" value={address2} onChange={e => { setAddress2(e.target.value) }} />
                                                        <label for="ad1">Address 2</label>
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <select id="sg" className="form-select" name="documentType3" value={state} onChange={handleStateChange} required>
                                                            <option value="">Select</option>
                                                            {stateList.map((documentType) =>
                                                            (<option keyId={documentType.stateId} value={documentType.stateName}>
                                                                {documentType.stateName}
                                                            </option>))
                                                            }
                                                        </select>
                                                        <label htmlFor="sg" >State</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <select id="sg" name="documentType3" className="form-select" value={districtBilling} onChange={handleDistrictChange} required>
                                                            <option value="">select</option>
                                                            {
                                                                districtList.map((documentType) =>
                                                                (<option keyId={documentType.distId} value={documentType.distName}>
                                                                    {documentType.distName}
                                                                </option>))
                                                            }
                                                        </select>
                                                        <label htmlFor="sg" >District</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <select id="sg" name="documentType4" className="form-select" value={city} onChange={handleCityChange} required >
                                                            <option value="">select</option>
                                                            {cityList.map((documentType) =>
                                                            (<option keyId={documentType.cityId} value={documentType.cityName}>
                                                                {documentType.cityName}
                                                            </option>))
                                                            }
                                                        </select>
                                                        <label htmlFor="sg" >City</label>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-12 col-sm-6 col-md-4">
                                                <div className="addform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="number"
                                                            id="pincode"
                                                            maxLength='6'
                                                            pattern="[0-9]{6}"
                                                            onInput={validateInputLenght}
                                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} value={pincode} onChange={e => { setPinCode(e.target.value) }} required />
                                                        <label for="pincode">Pincode</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="addform">
                                                    <p className="gentxt">Choose Category Type</p>
                                                    <div className="form-check form-check-inline">
                                                        <input type="checkbox" id="1" className="form-check-input" onClick={setCaltegoryArray} />
                                                        <label className="form-check-label"> {process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_General_Category}
                                                        </label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <input type="checkbox" id="2" className="form-check-input" onClick={setCaltegoryArray} />
                                                        <label className="form-check-label"> {process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_Food_Meal_Category}
                                                        </label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <input type="checkbox" id="3" className="form-check-input" onClick={setCaltegoryArray} />
                                                        <label className="form-check-label">
                                                            {process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_Food_Travel_Category}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-3">
                                        <UploadImage currentUser={currentUser} header="Upload Company Logo"
                                            h6Value={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_DRAG_AND_DROP}
                                            h6SpanValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_BROWSE}
                                            h6ValueAfterbreak={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_PROFILE_PIC}
                                            pValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FILE_DETAIL}
                                        />
                                    </div>


                                    <div className="col-12">
                                        <div className="head">
                                            <h3>Contact Person Details</h3>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="addform">
                                            <div className="form-floating">
                                                <input className="form-control" type="text" id="contactPersonFirstName" pattern="[a-zA-Z ]{2,35}" value={contactPersonFirstName} onChange={e => setContactPersonFirstName(e.target.value)} required />
                                                <label for="contactPersonFirstName">First Name</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="addform">
                                            <div className="form-floating">
                                                <input className="form-control" type="text" id="contactPersonLastName" pattern="[a-zA-Z ]{2,35}" value={contactPersonLastName} onChange={e => setContactPersonLastName(e.target.value)} />
                                                <label for="contactPersonLastName">Last Name</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="addform">
                                            <div className="form-floating">
                                                <input className="form-control" id="contactPersonMobNum"
                                                    type="number"
                                                    maxLength="10"
                                                    pattern="[0-9]{10}"
                                                    onInput={validateInputLenght}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                    value={contactPersonMobNum} onChange={e => setContactPersonMobNum(e.target.value)} required />
                                                <label for="contactPersonMobNum">Mobile No.</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <div className="addform">
                                            <div className="form-floating">
                                                <input className="form-control" type="email" maxLength="50" id="contactPersonEmail" value={contactPersonEmail} onChange={e => setContactPersonEmail(e.target.value)} required />
                                                <label for="contactPersonEmail">Email ID</label>
                                            </div>
                                        </div>
                                    </div>





                                    <div className="col-12">
                                        <div className="add-emp-form">
                                            <div className="head">
                                                <h3>Corporate Pricing Plan</h3>
                                            </div>

                                            <div className="row">

                                                {
                                                    pricePlan.map(plan => (
                                                        <div className="col-12 col-sm-6 col-md-3">
                                                            <div className="pricetype">
                                                                <label>
                                                                    <input type="radio" name={plan.pricePlanCode} id={plan.pricePlanCode} checked={corpPlanAssigned === plan.pricePlanCode} onClick={assignPlanToCorp} />
                                                                    <h5 className={plan.pricePlanName.toLowerCase()}>{plan.pricePlanName}</h5>
                                                                </label>
                                                                <i className="info" data-bs-toggle="modal" data-bs-target={`#pricePlan${plan.pricePlanCode}`} onClick={e => getTableDetailPlan(e, plan.pricePlanCode)}></i>

                                                                <div className="modal fade" id={`pricePlan${plan.pricePlanCode}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                                    <div className="modal-dialog modal-lg modal-dialog-scrollable">
                                                                        <div className="modal-content alerttext">
                                                                            <div className="modal-header">
                                                                                <h5 id="" style={{ background: "none" }}>{tableDate.priceName} - {tableDate.planProvider}</h5>
                                                                                <button className="close" data-bs-dismiss="modal"  aria-label="Close">&times;</button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <table className="table table-bordered">


                                                                                    {
                                                                                        tableDate.priceDetails !== undefined && tableDate.priceDetails.length === 0

                                                                                            ?

                                                                                            <div className="col-12">
                                                                                                <div className="no-record">
                                                                                                    <i></i>
                                                                                                    <h3> No Record Available.</h3>
                                                                                                </div>
                                                                                            </div>
                                                                                            :

                                                                                            tableDate.priceDetails !== undefined && tableDate.priceDetails.map(tD => (
                                                                                                <>
                                                                                                    {
                                                                                                        tD.priceFor === ""
                                                                                                            ?
                                                                                                            null
                                                                                                            :
                                                                                                            <tr className="table-primary">
                                                                                                                <td colspan="2">{tD.priceFor}</td>
                                                                                                            </tr>
                                                                                                    }

                                                                                                    {tD.priceDetails !== undefined && tD.priceDetails.map(inpd => (
                                                                                                        <tr>
                                                                                                            <td>{inpd.pricePlanName}</td>
                                                                                                            <td>{inpd.priceValue}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                    }
                                                                                                </>
                                                                                            ))
                                                                                    }



                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div className="head">
                                            <h3>Upload Documents </h3>
                                            <p className="infotxt ">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Uploaded_Document_TYPE_ALLOWED_TEXT}</p>
                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div className="add-emp-form">


                                            <div className="row">
                                                <div className="col-12 col-sm-4 col-md-3 col-lg-2 ">
                                                    <div className="webform">
                                                        <div className="formselect">
                                                            <label htmlFor="sg" className="select-label">Document Type</label>
                                                            <CreatableSelect
                                                                isClearable
                                                                onChange={handleDepartmentChange}
                                                                onInputChange={handleDepartmentInputChange}
                                                                options={documentList}
                                                                styles={colourStyles}
                                                                components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                                value={documentList.map(bandData => {
                                                                    if (bandData.value === documentNameForSelect) {
                                                                        return bandData
                                                                    }
                                                                })}
                                                                formatCreateLabel={() => undefined}
                                                            />
                                                        </div>
                                                    </div>

                                                    {showInputDoc
                                                        ?
                                                        <div className="selectdoc">
                                                            <input type="text" value={documentName} className="otherfiled"
                                                                placeholder="Enter document name" onChange={e => setDocumentName(e.target.value)} />
                                                        </div>
                                                        : null
                                                    }

                                                    <div className="uploaddoc">
                                                        <div className="dropzone-wrapper">
                                                            <div className="dropzone-desc">
                                                                <i className="addoc"></i>
                                                                <h6>Add file</h6>
                                                            </div>
                                                            <input type="file" name="img_logo" className="dropzone" key={inputKey} onChange={uploadDocument} />
                                                        </div>

                                                        {
                                                            parseInt(uploadPercentage) === 100 ? null :

                                                                <div className={`preview-zone ${hidden}`}>
                                                                    <div className="box">

                                                                        {uploadPercentage > 0 && <LinearProgressWithLabel value={uploadPercentage} variant="determinate" />}

                                                                        {
                                                                            hidden === ''
                                                                                ?
                                                                                <div className="box-body">

                                                                                    <img width="40" src={localStorage.getItem('url')} ></img><p style={{ "marginLeft": "1.5em" }}>File size {parseFloat(size / 1000).toFixed(2)}KB  </p>

                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                        {
                                                                            (loading)
                                                                                ?
                                                                                <div className="spinner-border text-muted"></div>
                                                                                :

                                                                                (success)
                                                                                    ?
                                                                                    <button type="button" className="success-preview" onClick={successPreview}>Success</button>
                                                                                    :
                                                                                    <button type="button" className="remove-preview" onClick={removePreview}>Remove</button>

                                                                        }
                                                                    </div>
                                                                </div>}
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-8 col-md-9 col-lg-10">

                                                    {
                                                        uploadedDocumemnt.map(document => (


                                                            <div className="corpuploadAdd">
                                                                <button className="removedoc" onClick={e => removeDocFromArray(document.documentType, e)}></button>
                                                                <i ></i>
                                                                <p>{document.documentType}</p>
                                                            </div>


                                                        ))

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 mt-4">
                                        <input type="reset" value="Reset" className="lbtn btngr" onClick={reset} />
                                        <button type="submit" className="lbtn btnbl" disabled={processBar}>
                                            {
                                                processBar
                                                    ?
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                    </div>
                                                    :
                                                    <>Submit</>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>


                )
                : (
                    <Redirect to='/' />
                )
            }


        </>

    )
}


const mapStateToProps = ({ token: { authenticated, token }, user: { roMdn, currentUser } }) => ({
    authenticated, token, roMdn, currentUser
});

export default connect(mapStateToProps)(AddNewCorp);