import { UserActionTypes } from './user.types';
import { act } from 'react-dom/test-utils';

const INITIAL_STATE = {
  currentUser: '',
  fullName: '',
  userRole: '',
  roMdn: '',
  mdn: '',
  wallet: [],
  corpBand: [],
  maxGeneralAmount: 0,
  maxFoodAmount: 0,
  maxTravelAmount: 0,
  rememberMe: false,
  rememberPassword: "",
  empDetail: [],
  bankId: '',
  backUrl: "#",
  sessionExpire: false,
  roName: "",
  corpBankDetail: [],

  bankBackButton: "",

  buttonName: "",
  bankUrl: ""
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };
    case UserActionTypes.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload
      };
    case UserActionTypes.SET_RO_MDN:
      return {
        ...state,
        roMdn: action.payload
      };
    case UserActionTypes.SET_CORPORATE_WALLET:
      return {
        ...state,
        wallet: action.payload
      };
    case UserActionTypes.SET_FULL_NAME:
      return {
        ...state,
        fullName: action.payload
      }
    case UserActionTypes.SET_MDN:
      return {
        ...state,
        mdn: action.payload
      }

    case UserActionTypes.SET_RO_NAME:
      return {
        ...state,
        roName: action.payload
      }

    case UserActionTypes.SET_MAX_GENERAL_AMOUNT:
      return {
        ...state,
        maxGeneralAmount: action.payload
      }
    case UserActionTypes.SET_MAX_FOOD_AMOUNT:
      return {
        ...state,
        maxFoodAmount: action.payload
      }
    case UserActionTypes.SET_MAX_TRAVEL_AMOUNT:
      return {
        ...state,
        maxTravelAmount: action.payload
      }
    case UserActionTypes.SET_CORP_BAND_LIMITS:
      return {
        ...state,
        corpBand: action.payload
      }

    case UserActionTypes.SET_EMPLOYEE_DETAIL:
      return {
        ...state,
        empDetail: action.payload
      }
    case UserActionTypes.SET_BANKID:
      return {
        ...state,
        bankId: action.payload
      }

    case UserActionTypes.SET_BACK_URL:
      return {
        ...state,
        backUrl: action.payload
      }

    case UserActionTypes.SET_SESSION_EXPIRE:
      return {
        ...state,
        sessionExpire: action.payload
      }

    case UserActionTypes.SET_CORP_BANK_DETAIL:
      return {
        ...state,
        corpBankDetail: action.payload
      }


    case UserActionTypes.SET_BUTTON_NAME:
      return {
        ...state,
        buttonName: action.payload
      }

    case UserActionTypes.SET_BANK_ADD_UPDATE_URL:
      return {
        ...state,
        bankUrl: action.payload
      }


    case UserActionTypes.SET_BANK_BACK_BUTTON:
      return {
        ...state,
        bankBackButton: action.payload
      }


    case UserActionTypes.RESET_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};



export default userReducer;
