
import CardList from './card-list.component';
import React from 'react';
import { connect } from "react-redux";

import GenerateRequestId from '../../common-function/GenerateRequestId';
import apiCallService from '../../service/api-call-service';
import Pagination from 'react-js-pagination';
import CsvDownload from 'react-json-to-csv';

import FilterComponent from "./filter.component";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import CardStatus from '../../constants/card-status.type';
import KycType from '../../redux/kyc/kyc.types';
import { setBandListToView } from '../../redux/band/band.action';
import { setLocationListToView } from '../../redux/location/location.action';
import { setCardStatusListToView, setKycListToView, setMasterRoleToView } from '../../redux/kyc/kyc.action';
import { useToasts } from 'react-toast-notifications';


function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}


const collectedTrueKeys = {
  band: [],
  location: [],
  roleName: [],
  card_staus: [],
  kyc_status: []
}


class ManageEmployee extends React.Component {

  constructor() {
    super();

    this.state = {

      loading: false,
      posts: [],
      filteredPost: [],
      postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_EMPLOYEE,
      currentPage: 1,
      totalLength: 0,

      currentPost: [],

      bulkButtonShow: false,
      masterChecked: false,

      searchField: '',

      placeHolder: '...',
      allValuesForFilter: [],
      multiValue: [],
      multiValueLocation: [],
      multiValueForRole: [],
      multiValueForCardStatus: [],
      multiValueForKYCStatus: [],


      checkedPostForAction: [],


      updatedRole: '',
      maxUserAssignedToRole: 0,
      alphaSort: '',
      dateSort: '',
      orderOn: 'dateCreatedOn',
      orderType: 'desc'




    };
  }


  componentDidMount = () => {
    var payload = {
      dashBoardClass: "",
      manageEmployeeClass: "active",
      moneyDisbursalClass: "",
      manageCardClass: "",
      manageLimitClass: "",
      activityClass: ""
    }

    store.dispatch(setActiveHeader(payload));

    function getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    }
    var query = getQueryVariable('filter')

    // console.log('query', query)
    // if (query) {
    //   var option = { label: query, value: "90" }

    // }


    collectedTrueKeys.roleName = []
    if (query) {
      var option = { label: query, value: "90" }
      this.setState({ multiValueForRole: option });
      collectedTrueKeys.roleName.push(query)
    }



    this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

  }


  fetchPosts = async (pageNumber, searchField, ctk, orderOn, orderType) => {
    this.setState({ loading: true })
    this.setState({ currentPost: [] })
    const url = process.env.REACT_APP_GET_EMPLOYEE_BY_ROID_WITH_FILTER
    console.log(url);
    var request = {
      "roId": this.props.roMdn,
      "page": pageNumber,
      "size": this.state.postsPerPage,
      "searchKeyword": searchField,
      "collectedTrueKeys": ctk,
      "orderOn": orderOn,
      "orderType": orderType,
    }
    const res = await apiCallService.postMethodCall(url, request);


    console.log('response emp-list', request, res);
    if (res !== undefined && res.status === 0) {
      console.log('response inside fetch post', res.response.employeeDetails);

      this.setState({ totalLength: res.response.totalEmployee })

      var arry = [];
      res.response.employeeDetails.map(detail => {
        var isChecked = 'isChecked';
        detail[isChecked] = false;
        var fullName = 'fullName';
        if (detail.last_name === undefined) {
          detail[fullName] = `${detail.first_name}`
        } else {
          detail[fullName] = `${detail.first_name} ${detail.last_name}`
        }
        arry.push(detail)
      })


      this.setState({ currentPost: res.response.employeeDetails })

      this.setState({ loading: false })
    }

    else {
      this.setState({ currentPost: [] })
      this.setState({ loading: false })
    }

  }




  handleMultiChange = (option) => {


    console.log('Inside handle band', option)

    this.setState({ placeHolder: "" })

    console.log('multivalue', option)
    store.dispatch(setBandListToView(option));
    console.log(store)
    this.setState({ multiValue: option })
    // setCurrentPage(1)


    var data = []
    if (this.state.allValuesForFilter === null || this.state.allValuesForFilter.length === 0) {

      console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
      // setAllValuesForFilter(option)
      this.setState({ allValuesForFilter: option })
    } else {
      console.log(' allValuesForFilter else', this.state.allValuesForFilter.length === 0)
      this.state.allValuesForFilter.map(all => {
        data.push(all);
      })
      option !== null && option.map(op => {
        data.push(op);

      })
      // setAllValuesForFilter(data)
      this.setState({ allValuesForFilter: data })
    }

    collectedTrueKeys.band = [];
    if (option !== null) {
      option.map(op => {

        collectedTrueKeys.band.push(op.label);
      })
    }

  }




  handleMultiChangeLocation = (option) => {

    // setPrevFilter(filteredList);
    this.setState({ placeHolder: "" })
    console.log('multivalue', option)
    store.dispatch(setLocationListToView(option));
    console.log(store)
    this.setState({ multiValueLocation: option });
    // setCurrentPage(1)


    var data = []
    if (this.state.allValuesForFilter === null || this.state.allValuesForFilter.length === 0) {

      console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
      // setAllValuesForFilter(option)
      this.setState({ allValuesForFilter: option })
    } else {
      console.log('else', this.state.allValuesForFilter.length === 0)
      this.state.allValuesForFilter.map(all => {
        data.push(all);
      })
      option !== null && option.map(op => {
        data.push(op);
      })
      // setAllValuesForFilter(data)
      this.setState({ allValuesForFilter: data })
    }

    collectedTrueKeys.location = []
    if (option !== null) {
      option.map(op => {
        collectedTrueKeys.location.push(op.label)

      })
    }


  }

  handleMultiChangeRole = (option) => {


    // setPrevFilter(filteredList);
    this.setState({ placeHolder: "" })
    console.log('multivalue', option)
    store.dispatch(setMasterRoleToView(option));
    console.log(store)
    this.setState({ multiValueForRole: option });
    // setCurrentPage(1)



    var data = []
    if (this.state.allValuesForFilter === null || this.state.allValuesForFilter.length === 0) {

      console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
      // setAllValuesForFilter(option)
      this.setState({ allValuesForFilter: option })
    } else {
      console.log('else', this.state.allValuesForFilter.length === 0)
      this.state.allValuesForFilter.map(all => {
        data.push(all);
      })
      option !== null && option.map(op => {
        data.push(op);
      })
      // setAllValuesForFilter(data)
      this.setState({ allValuesForFilter: data })
    }

    collectedTrueKeys.roleName = []
    if (option !== null) {
      option.map(op => {
        collectedTrueKeys.roleName.push(op.label)

      })
    }


  }

  handleMultiChangeCardStatus = (option) => {



    // setPrevFilter(filteredList);
    this.setState({ placeHolder: "" })
    console.log('multivalue', option)
    store.dispatch(setCardStatusListToView(option));
    console.log(store)
    // setMultiValueForCardStatus(option)

    this.setState({ multiValueForCardStatus: option });
    // setCurrentPage(1)


    var data = []
    if (this.state.allValuesForFilter === null || this.state.allValuesForFilter.length === 0) {

      console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
      // setAllValuesForFilter(option)
      this.setState({ allValuesForFilter: option })
    } else {
      console.log('else', this.state.allValuesForFilter.length === 0)
      this.state.allValuesForFilter.map(all => {
        data.push(all);
      })
      option !== null && option.map(op => {
        data.push(op);
      })
      // setAllValuesForFilter(data)
      this.setState({ allValuesForFilter: data })
    }

    collectedTrueKeys.card_staus = []

    if (option !== null) {
      option.map(op => {
        console.log("IOp Value os", op)
        if (op.value !== undefined && op.value != null && op.value !== "") {
          var dv = op.value + ""
          var sd = dv.split(',');
          sd.map(e => {
            collectedTrueKeys.card_staus.push(parseInt(e))
          })

        }
      })
    }


  }

  handleMultiChangeKyc = (option) => {


    // setPrevFilter(filteredList);
    this.setState({ placeHolder: "" })
    console.log('multivalue', option)
    store.dispatch(setKycListToView(option));
    console.log(store)
    this.setState({ multiValueForKYCStatus: option });
    // setMultiValueForKYCStatus(option)

    // setCurrentPage(1)


    var data = []
    if (this.state.allValuesForFilter === null || this.state.allValuesForFilter.length === 0) {

      console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
      // setAllValuesForFilter(option)
      this.setState({ allValuesForFilter: option })
    } else {
      console.log('else', this.state.allValuesForFilter.length === 0)
      this.state.allValuesForFilter.map(all => {
        data.push(all);
      })
      option !== null && option.map(op => {
        data.push(op);
      })
      // setAllValuesForFilter(data)
      this.setState({ allValuesForFilter: data })
    }

    collectedTrueKeys.kyc_status = []
    if (option !== null) {
      option.map(op => {
        collectedTrueKeys.kyc_status.push(op.value)

      })
    }

  }

  clearAllFilter = () => {

    store.dispatch(setBandListToView(''));
    store.dispatch(setLocationListToView(''));
    store.dispatch(setMasterRoleToView(''));
    store.dispatch(setCardStatusListToView(''));
    store.dispatch(setKycListToView(''));



    this.setState({ multiValue: [] })
    this.setState({ multiValueForCardStatus: [] })
    this.setState({ multiValueForRole: [] })
    this.setState({ multiValueLocation: [] })
    this.setState({ multiValueForKYCStatus: [] })
    this.setState({ placeHolder: "..." })

    collectedTrueKeys.kyc_status = []
    collectedTrueKeys.card_staus = []
    collectedTrueKeys.roleName = []
    collectedTrueKeys.location = []
    collectedTrueKeys.band = [];


    this.setState({ allValuesForFilter: [] })


    // setFilteredList(posts);
    this.setState({ currentPage: 1 });
    this.fetchPosts(1, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

  }

  apply = () => {
    this.setState({ currentPage: 1 });
    this.fetchPosts(1, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
  }





  handleMasterChecked = (event) => {

    console.log('Handle handleMasterChecked clicked')

    this.setState({ masterChecked: event.target.checked })

    this.setState({ bulKButtonShow: event.target.checked })

    var arrayData = this.state.currentPost
    arrayData.map(post => {
      post.isChecked = event.target.checked
      var ref = post.emp_id
      console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
      if (document.getElementById(ref)) { document.getElementById(ref).checked = event.target.checked }
    })

    this.setState({ currentPost: arrayData })

    this.setState({ checkedPostForAction: arrayData });


  }




  handleChecked = (event) => {
    // this.setState({ masterChecked: event.target.checked })


    console.log("Master checked single")
    var arrayData = [];

    this.state.currentPost.map(detail => {
      arrayData.push(detail);
    })

    if (event.target.checked === false) {
      console.log("Master checked single")
      this.setState({ masterChecked: event.target.checked })
      this.setState({ bulKButtonShow: event.target.checked })
    }
    arrayData.map(post => {
      if (post.emp_id === event.target.id) {
        post.isChecked = event.target.checked
        var ref = post.emp_id
        if (document.getElementById(ref)) {
          document.getElementById(ref).checked = event.target.checked;
          // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

        }
      }
    })

    this.setState({ currentPost: arrayData })
    this.setState({ checkedPostForAction: arrayData });

  }





  handleChange = (event) => {
    this.setState({ searchField: event.target.value })
    this.setState({ currentPage: 1 });
    this.fetchPosts(1, event.target.value, collectedTrueKeys, this.state.orderOn, this.state.orderType);
  };




  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber });

    this.fetchPosts(pageNumber, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

  };




  radioChangeHandler = (event) => {
    console.log('Event triggered radioChangeHandler', event.target)
    this.setState({ alphaSort: event.target.id })
    this.setState({ dateSort: '' })
    if (event.target.id === 'op3') {
      this.setState({ orderOn: 'firstName' })
      this.setState({ orderType: 'asc' })
      this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, 'firstName', 'asc');
    }
    if (event.target.id === 'op4') {
      this.setState({ orderOn: 'firstName' })
      this.setState({ orderType: 'desc' })
      this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, 'firstName', 'desc');
    }
    if (event.target.id === 'op1') {
      console.log('Event triggered inside op1', event.target.id)
      this.setState({ orderOn: 'dateOfJoining' })
      this.setState({ orderType: 'asc' })
      this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, 'dateOfJoining', 'asc');
    }
    if (event.target.id === 'op2') {
      console.log('Event triggered inside op2', event.target.id)
      this.setState({ orderOn: 'dateOfJoining' })
      this.setState({ orderType: 'desc' })
      this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, 'dateOfJoining', 'desc');
    }

  }
  radioChangeHandlerForDateOfJoining = (event) => {
    console.log('Event triggered radioChangeHandlerForDateOfJoining', event.target.id)
    this.setState({ alphaSort: '' })
    this.setState({ dateSort: event.target.id })
    if (event.target.id === 'op1') {
      console.log('Event triggered inside op1', event.target.id)
      this.setState({ orderOn: 'dateOfJoining' })
      this.setState({ orderType: 'asc' })
      this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, 'dateOfJoining', 'asc');
    }
    if (event.target.id === 'op2') {
      console.log('Event triggered inside op2', event.target.id)
      this.setState({ orderOn: 'dateOfJoining' })
      this.setState({ orderType: 'desc' })
      this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, 'dateOfJoining', 'desc');
    }

    // setDateSort(event.target.id);
    // setAlphaSort('')
  }

  updateRoleChangeHandler = (event) => {
    console.log(event.target);
    console.log('event.target.getAttribute("name")', event.target.getAttribute("name"));
    var detail = event.target.getAttribute("name")
    var splitValue = detail.split('/');
    this.setState({ updatedRole: splitValue[0] })

    this.setState({ maxUserAssignedToRole: splitValue[1] })


    console.log("Role to Update : ", this.state.updatedRole)
  }




  handleUpdateRoleButton = async (event) => {
    console.log('handleUpdateRoleButton', this.state.updatedRole)

    var maxAssign = this.state.maxUserAssignedToRole;


    var roleStatus = 1;
    var updateRoleName = this.state.updatedRole;


    if (this.state.updatedRole === "removeRole") {
      roleStatus = 0;
      updateRoleName = '';
    }

    this.setState({ updatedRole: '' })

    this.state.checkedPostForAction.map(async (list, key) => {

      if (list.isChecked) {

        var prevRole = 'undefined'

        if (this.state.updatedRole === "removeRole") {
          prevRole = list.roleName;
        }

        if (list.email === this.props.currentUser) {

          // var listPost = []

          list.isChecked = false
          var ref = list.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }

          this.setState({ masterChecked: false })
          content = `${list.first_name} ${list.last_name === undefined ? " " : list.last_name} - ${process.env.React_APP_Message_For_if_logged_in_user_try_to_update_role_Or_Remove_role}`
          this.props.addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        }

        else if (list.roleName === "" && this.state.updatedRole === "removeRole") {
          // var listPost = []

          list.isChecked = false
          var ref = list.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }
          this.setState({ masterChecked: false });
          content = `${list.first_name} ${list.last_name} - You Don't Have Any Role.`
          this.props.addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        }

        else {




          console.log('handleUpdateRole')
          var requestToSent = {
            "request": {
              "employee": {
                "corp_id": this.props.roMdn,
                "email": list.email,
                "emp_id": list.emp_id,
                "mdn": list.mdn,
                "roleName": updateRoleName,
                "role_status": roleStatus,
                "previousEmail": list.email,
                "maxRoleAssigned": maxAssign,
                "prevRole": prevRole,
                "modify_by": this.props.currentUser
              }
            }
          }

          console.log('request to send', requestToSent)

          var lastName = ''
          if (list.last_name === undefined) {
            lastName = ''
          } else {
            lastName = list.last_name
          }
          var url = process.env.REACT_APP_UPDATE_EMPLOYEE_ROLE;

          var response = await apiCallService.postMethodCall(url, requestToSent);

          console.log('update handleUpdateRoleButton role response', response)
          var content = ''
          if (!(response === undefined) && !(response.response === undefined) && (response.response.response_code === 0)) {
            // var listPost = []

            list.roleName = updateRoleName;
            list.role_status = roleStatus;
            list.isChecked = false
            var ref = list.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }
            if (this.state.updatedRole === "removeRole") {
              content = `${list.first_name} ${lastName} - Role successfully removed.`
            } else {
              content = `${list.first_name} ${lastName} - Role successfully updated.`
            }
            maxAssign--;

            this.props.addToast(content, {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })

            this.setState({ currentPage: 1 });
            this.fetchPosts(1, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

          } else {
            var listPost = []

            list.isChecked = false
            var ref = list.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }
            content = `${list.first_name} ${lastName} - ${response.response_description}`
            this.props.addToast(content, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })
            this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
            // finalList.push(list)
            // this.fetchPosts(1, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
          }
        }
      } else {
        // finalList.push(list)
      }
    })
    // this.setState({currentPost:finalList});

  }


  handleTempBlock = (event, allValue, cD, reason) => {
    // toggleDropDownHidden();

    // var data = JSON.parse(localStorage.getItem("listForAction") || "[]");;
    var lenght = 0;
    this.state.currentPost.map(list => {
      if (list.isChecked) {
        lenght++;
      }

    })
    let i = 0;

    var cardDetail = "";



    //console.log('list for action for remove employee',listForAction)

    this.state.currentPost.map(async (data, key) => {
      if (data.isChecked) {
        console.log('lost', data.emp_id);
        var lastName = ""
        if (data.last_name !== undefined) {
          lastName = data.last_name
        }
        data.card.map(cd => {
          cardDetail = cd;
        })

        console.log('data', data, parseInt(cardDetail.cardStatus), parseInt(CardStatus.NOT_ASSIGNED), parseInt(cardDetail.cardStatus) === parseInt(CardStatus.NOT_ASSIGNED))
        if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.NOT_ASSIGNED)) {

          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }

          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.React_APP_Message_For_if_Card_not_assigned_user_try_tempBlock + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        } else if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.ASSIGNED)) {

          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }
          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.React_APP_Message_For_if_Card_assigned_user_try_tempBlock + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        }
        else if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.PERMANENT_BLOCK) || parseInt(cardDetail.cardStatus) === parseInt(CardStatus.REPLACED)) {

          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }
          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_Card_already_Permanently_Blocked + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        }
        else if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.TEMPORARY_BLOCK)) {

          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }
          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_CARD_ALREADY_TEM_BLOCK + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        }

        else {
          //url, mdn, useraname, otp, reason, status
          console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
          // updateCardStatus(url, mdn, useraname, otp, reason, status,header,cardReferenceNum,updateType,updatedBy ) {


          const request = {
            device_info: {
              api_version: "1.0",
              app_version: "9.2",
              device_id: "d215ab007e05c498",
              device_os: "Android",
              device_os_version: "5.1.1",
              imei: "357869081907005",
              ip_address: "172.160.2.140",
              model_name: "SM-J320F",
              source: "mobile",
            },
            request: {
              requestId: GenerateRequestId.geneRateRequestId(),
              source: "App",
              type: "Virtual",
              oxigenWallet: data.mdn,
              statusCode: CardStatus.TEMPORARY_BLOCK,
              issuer: "1",
              userID: data.fullName,
              reason: reason.reason,
              otp: "",
              cardReferenceNum: cardDetail.cardReferenceNo,
              updateType: "temp",
              updatedBy: this.props.fullName,
              updatedByEmail: this.props.currentUser,
              ro_Id: this.props.roMdn
            }
          };
          console.log("inside card update status request :-", request);
          console.log('response hadle temp block', JSON.stringify(request));
          var response = await apiCallService.postMethodCall(process.env.REACT_APP_UPDATECARDSTATUS_URL, request);

          // var response = await CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "", "temporary block", "3","", data.card_ref_no,"temp",currentUser,"1");
          //store.dispatch(toggleDropDownHidden());
          //response.response_description
          //store.dispatch(setMessageToBeShown("SuccessFull Permanent Block."));



          var content = ''
          if ((response !== undefined) && response.status === 'failure') {
            content = `${data.first_name} ${lastName} - ${response.response_description}`
            this.props.addToast(content, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })
            // var listPost = []
            // posts.map(post => {
            //   if (post.mdn === data.mdn) {
            //     post.isChecked = false;
            //     setMasterChecked(false)
            //     // listPost.push(post);
            //   } else {
            //     // listPost.push(post);
            //   }
            // })
            data.isChecked = false
            var ref = data.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }
            this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

          } else if ((response !== undefined) && response.response_description != undefined && response.response_description.toLowerCase() === 'success') {
            console.log('inside success')
            content = `${data.first_name} ${lastName} - ${process.env.REACT_APP_VAR_FOR_MESSAGE_SuccessFully_TEMPORARY_Blocked}.`;
            this.props.addToast(content, {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })

            // var listPost = []

            data.isChecked = false
            var ref = data.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }


            this.setState({ currentPage: 1 });
            this.fetchPosts(1, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

          } else {
            content = `Somthing Went Wron Please Try In A Bit.`;
            this.props.addToast(content, {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })
          }

        }
      }

    })
  }




  handleRemoveEmployee = async (event) => {
    console.log('event handleRemoveEmployee triggered', event.target);

    // var data = JSON.parse(localStorage.getItem("listForAction") || "[]");


    this.state.checkedPostForAction.map(async (list, key) => {

      if (list.isChecked) {
        console.log('lost', this.props.currentUser, " && ", list.email);
        if (list.email === this.props.currentUser) {
          // var listPost = []

          this.setState({ masterChecked: false })
          list.isChecked = false;
          var ref = list.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }
          content = `${list.first_name} ${list.last_name} - ${process.env.React_APP_Message_For_if_logged_in_user_try_to_delete_it_self_Bulk_Employee}`
          this.props.addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })

          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        }
        else {

          const RequestToSend = {
            "request": {
              "request_id": GenerateRequestId.geneRateRequestId(),
              "employee": {
                "corp_id": this.props.roMdn,
                "emp_id": list.emp_id,
                "mdn": list.mdn,
                "status": "1",
                // "card_staus": cardDetail.cardStatus,
                // "cardReferenceNumber": cardDetail.card_ref_no,
                "modify_by": this.props.currentUser
              }
            }
          }
          console.log(RequestToSend);
          const url = process.env.REACT_APP_UPDATE_EMPLOYEE;

          var lastName = ''
          if (list.last_name === undefined) {
            lastName = ''
          } else {
            lastName = list.last_name
          }
          const response = await apiCallService.postMethodCall(url, RequestToSend);
          var content = ''
          console.log('Update EMployee Api response yes ', response);
          if ((response !== undefined) && response.status === 'failure') {

            console.log('Update EMployee Api response inside fail', response);
            var listPost1 = []
            this.setState({ masterChecked: false })
            list.isChecked = false;
            var ref = list.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }
            content = `${list.first_name} ${lastName} - ${response.response_description}.`
            this.props.addToast(content, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })

            this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

          } else if (response !== undefined && response.response.response_message === 'success') {

            console.log('Update EMployee Api response inside success', response);


            this.setState({ masterChecked: false })
            list.isChecked = false;
            var ref = list.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }
            console.log('inside success')
            content = `${list.first_name} ${lastName}  removed successfully.`;
            this.props.addToast(content, {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })


            await this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);


          } else {

            content = `${response.response_description} (${list.first_name} ${lastName})`;
            this.props.addToast(content, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })
          }
        }

      } else {

      }

    })

  }

  handlePermanentBlock = (event, allValue, cD, reason) => {
    console.log("clicked handlePermanentBlock from emp manage");
    //store.dispatch(toggleDropDownHidden());
    // var data = JSON.parse(localStorage.getItem("listForAction") || "[]");;
    var lenght = 0;
    this.state.currentPost.map(list => {
      if (list.isChecked) {
        lenght++
      }
    })
    var content = '';
    let i = 1;

    this.state.currentPost.map(async (data, key) => {

      if (data.isChecked) {
        console.log('lost', data.emp_id);
        var lastName = ""
        if (data.last_name !== undefined) {
          lastName = data.last_name
        }

        var cardDetail = "";

        data.card.map(cd => {
          cardDetail = cd;
        })
        console.log('data clicked handlePermanentBlock from emp manage', data, parseInt(cardDetail.cardStatus), parseInt(CardStatus.NOT_ASSIGNED), parseInt(cardDetail.cardStatus) === parseInt(CardStatus.NOT_ASSIGNED))
        if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.NOT_ASSIGNED)) {
          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }


          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.React_APP_Message_For_if_Card_not_assigned_user_try_permanentBlock + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })

          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

        } else if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.ASSIGNED)) {
          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }

          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.React_APP_Message_For_if_Card_assigned_user_try_permanentBlock + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })

          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

        } else if (parseInt(cardDetail.cardType) === 0) {
          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }

          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_Virtual_Card_cannot_blocked_Permanently + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

        } else if (parseInt(cardDetail.cardStatus) === parseInt(CardStatus.PERMANENT_BLOCK) || parseInt(cardDetail.cardStatus) === parseInt(CardStatus.REPLACED)) {
          data.isChecked = false
          var ref = data.emp_id
          if (document.getElementById(ref)) {
            document.getElementById(ref).checked = false;
            console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

          }

          this.props.addToast(data.first_name + " " + lastName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_Card_is_already_Permanently_Blocked + "", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 7000
          })
          this.fetchPosts(this.state.currentPage, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

        }
        else {

          const request = {
            device_info: {
              api_version: "1.0",
              app_version: "9.2",
              device_id: "d215ab007e05c498",
              device_os: "Android",
              device_os_version: "5.1.1",
              imei: "357869081907005",
              ip_address: "172.160.2.140",
              model_name: "SM-J320F",
              source: "mobile",
            },
            request: {
              requestId: GenerateRequestId.geneRateRequestId(),
              source: "App",
              type: "Virtual",
              oxigenWallet: data.mdn,
              statusCode: CardStatus.PERMANENT_BLOCK,
              issuer: "1",
              userID: data.fullName,
              reason: reason.reason,
              otp: "",
              cardReferenceNum: cardDetail.cardReferenceNo,
              updateType: "permanent",
              updatedBy: this.props.fullName,
              updatedByEmail: this.props.currentUser,
              ro_Id: this.props.roMdn
            }
          };
          console.log("inside card update status request :-", request);
          var response = await apiCallService.postMethodCall(process.env.REACT_APP_UPDATECARDSTATUS_URL, request);

          console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
          // updateCardStatus(url, mdn, useraname, otp, reason, status,header,cardReferenceNum,updateType,updatedBy ) {

          // var response = await CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "", "Permanent block", "4","",data.card_ref_no,'permanent',currentUser);


          if (!(response === undefined) && response.status === 'failure') {
            content = `${data.first_name} ${lastName} -  ${response.response_description}`
            this.props.addToast(content, {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })
            var listPost = []


          } else if (!(response === undefined) && response.response_description !== undefined && (response.response_description.toLowerCase() === 'success' || response.response_description === 'updated succesfully')) {
            console.log('inside success')
            data.isChecked = false
            var ref = data.emp_id
            if (document.getElementById(ref)) {
              document.getElementById(ref).checked = false;
              console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref, document.getElementById(ref))

            }

            content = `${data.first_name} ${lastName} - ${process.env.REACT_APP_VAR_FOR_MESSAGE_SuccessFully_PERMANENT_Blocked}`;
            this.props.addToast(content, {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })

            var listPost = []


            this.setState({ currentPage: 1 });
            this.fetchPosts(1, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);

          } else {
            content = `Somthing Went Wrong Please Try In A Bit.`;
            this.props.addToast(content, {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 7000
            })

          }
        }
      }

    })


  }






  render() {

    //console.log(posts);

    //Get Current posts
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    console.log('currentpost', this.state.currentPost);
    console.log('filteredPost', this.state.filteredPost);


    var fileToExport = [];

    var detail = false;
    this.state.currentPost.map(ps => {
      if (ps.isChecked) {
        detail = true;
      }
    })

    const getCardStatusVar = (cardStatus) => {
      switch (cardStatus) {
        case CardStatus.ACTIVE:
          return "Card Active"
        case CardStatus.ASSIGNED:
          return "Card Assigned"
        case CardStatus.NOT_ASSIGNED:
          return "Card Not Assigned"
        case CardStatus.TEMPORARY_BLOCK:
          return "Card Temporary block"
        case CardStatus.PERMANENT_BLOCK:
          return "Card Permanent block"
      }

    }

    const getKycStatusVar = (cardStatus) => {
      switch (cardStatus) {
        case KycType.Pending_With_Bank:
          return "Pending With Bank"
        case KycType.Rejected_By_Bank:
          return "Rejected By Bank"
        case KycType.Pending_With_Oxigen_KYC_Team:
          return "Pending With Oxigen KYC Team"
        case KycType.Documents_Not_Submitted:
          return "Documents Not Submitted"
        case KycType.KYC_DONE:
          return "KYC Done"
        case KycType.MIN_KYC:
          return "Min KYC Done"
      }

    }

    this.state.currentPost.map(exptdata => {
      if (exptdata.isChecked) {
        var dateofJoining = exptdata.date_of_joining !== null && exptdata.date_of_joining.split(" ");
        var detailGoesInFile = {
          "Employee Id": exptdata.emp_id,
          "Employee Name": exptdata.fullName,
          "Band": exptdata.band,
          // "Card Reference No.": exptdata.card_ref_no,
          // "Card Status": getCardStatusVar(exptdata.card_staus),
          "Created By": exptdata.createdBy,
          "Date of joining": dateofJoining[0],
          "Department": exptdata.department,
          "Dob": exptdata.dob,
          "email": exptdata.email,
          "Gender": exptdata.gender,
          "Kyc Status": getKycStatusVar(exptdata.kyc_status),
          "Location": exptdata.location,
          "Mobile No.": exptdata.mdn,
          "Pan": exptdata.pan,
          "Pincode": exptdata.pincode,
          "RoId": exptdata.roMdn,
          "Role Name": exptdata.roleName
        }
        fileToExport.push(detailGoesInFile)
      }

    })




    return (
      < >


        <>



          <FilterComponent handleSearchChange={this.handleChange} isSelected={this.state.alphaSort} dateSelected={this.state.dateSort} radioChangeHandler={this.radioChangeHandler}
            radioDateHandler={this.radioChangeHandlerForDateOfJoining} handleMasterChecked={this.handleMasterChecked} handleRemoveEmployee={this.handleRemoveEmployee}
            handleTempBlock={this.handleTempBlock} handlePermanentBlock={this.handlePermanentBlock} masterChecked={this.state.masterChecked} bulKButtonShow={this.state.bulkButtonShow}
            updateRoleChangeHandler={this.updateRoleChangeHandler} updatedRole={this.state.updatedRole} handleUpdateRoleButton={this.handleUpdateRoleButton} currentPost={this.state.currentPost}
            handleMultiChange={this.handleMultiChange} handleMultiChangeLocation={this.handleMultiChangeLocation} handleMultiChangeRole={this.handleMultiChangeRole}
            handleMultiChangeCardStatus={this.handleMultiChangeCardStatus} handleMultiChangeKyc={this.handleMultiChangeKyc} clearAllFilter={this.clearAllFilter}
            multiValue={this.state.multiValue} multiValueLocation={this.state.multiValueLocation} multiValueForRole={this.state.multiValueForRole} multiValueForCardStatus={this.state.multiValueForCardStatus}
            multiValueForKYCStatus={this.state.multiValueForKYCStatus} placeHolder={this.state.placeHolder} apply={this.apply} allValueFilter={this.state.allValuesForFilter}
            loading={this.state.loading}
          />



          <CardList employeeList={this.state.currentPost} loading={this.state.loading} handleChecked={this.handleChecked} bulKButtonShow={this.state.bulkButtonShow} conditionForNoEmployeeDiv={this.state.currentPost !== null ? this.state.currentPost.length > 0 : false}></CardList>


          {
            this.state.loading || this.state.currentPost === null || this.state.currentPost.length === 0
              ?
              null
              :
              !(this.state.totalLength > parseInt(this.state.postsPerPage))
                ?
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <p>
                      {!detail ? <></> :
                        <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"emp-list.csv"}>Export to CSV</CsvDownload>
                      }
                    </p>
                  </div>

                </div>
                :
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <p>
                      {!detail ? <></> :
                        <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"emp-list.csv"}>Export to CSV</CsvDownload>
                      }
                      Showing {valueToShow} to {(parseInt(indexOfLastPost) > parseInt(this.state.totalLength)) ? this.state.totalLength : indexOfLastPost} of {this.state.totalLength} entries</p>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="pager">
                      <Pagination
                        activePage={parseInt(this.state.currentPage)}
                        itemsCountPerPage={parseInt(this.state.postsPerPage)}
                        totalItemsCount={this.state.totalLength}
                        pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                        onChange={this.paginate}
                        className="pagination"
                        linkClass="page-link"
                        itemClass="page-item"
                        nextPageText="Next"
                        prevPageText="Prev"
                      />
                    </div>
                  </div>
                </div>
          }

        </>



      </>

    )
  }
}



const mapStateToProps = ({ user: { roMdn, currentUser }, role: { huburgVar }, band: { bandListToView }, location: { locationListToView }, kyc: { kycListToView }, kyc: { masterRoleListToView }, kyc: { cardStatusListToView } }) => ({
  roMdn,
  bandListToView,
  locationListToView, currentUser,
  kycListToView,
  masterRoleListToView,
  cardStatusListToView, huburgVar
});

export default withToast(connect(mapStateToProps)(ManageEmployee));