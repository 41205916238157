const OrderTypes = {
    SET_CARD_QUANTITY: 'SET_CARD_QUANTITY',
    SET_FULL_NAME: 'SET_FULL_NAME',
    SET_MOBILE_NUMBER: 'SET_MOBILE_NUMBER',
    SET_ADDRESS_ONE: 'SET_ADDRESS_ONE',
    SET_ADDRESS_TWO: 'SET_ADDRESS_TWO',
    SET_STATE: 'SET_STATE',
    SET_CITY: 'SET_CITY',
    SET_PINCODE: 'SET_PINCODE',
    SET_SAME_ADDRESS_CHECKBOX: 'SET_SAME_ADDRESS_CHECKBOX',
    SET_SHIPPING_ADDRESS: 'SET_SHIPPING_ADDRESS',
    SET_BILLING_ADDRESS: 'SET_BILLING_ADDRESS',
    SET_SHIPPING_PERSON_NAME: 'SET_SHIPPING_PERSON_NAME',
    SET_SHIPPING_PERSON_MDN: 'SET_SHIPPING_PERSON_MDN',
    SET_TOTAL_AMOUNT: 'SET_TOTAL_AMOUNT',
    SET_RATE_PER_CARD: 'SET_RATE_PER_CARD',
    SET_GST_PERCENTAGE: 'SET_GST_PERCENTAGE',
    SET_GST_AMOUNT: 'SET_GST_AMOUNT',
    SET_ORDER_ID: 'SET_ORDER_ID',
    SET_ORDER_DATE: 'SET_ORDER_DATE',
    SET_ORDER_BACK: 'SET_ORDER_BACK',
    RESET_ORDER: "RESET_ORDER",
    SET_DISTRICT_SHIP: "SET_DISTRICT_SHIP",
    SET_DISTRICT_BILLING: "SET_DISTRICT_BILLING",
    SET_TOATAL_AMOUNT_WITH_GST: "SET_TOATAL_AMOUNT_WITH_GST",
    SET_CARD_TYPE: "SET_CARD_TYPE"
};

export default OrderTypes;