
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import history from '../../auth/history';


class GiftCardDetailedPage extends React.Component {

    constructor() {
        super();

        this.state = {


        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards"

        this.props.getOrderHistory();

    }


    orderAgain = () => {
        history.push("/manage-giftCard");
        history.go();
    }





    render() {


        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());

        var currentDatedd = currentDate.getDate();
        var currentDatemm = currentDate.getMonth() + 1;
        var currentDatey = currentDate.getFullYear();
        var currentDatefgg = currentDate.getHours();
        var currentDatefggm = currentDate.getMinutes();
        var currentDatefggs = currentDate.getSeconds();



        var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
        var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
        var maskcurrentDatefgg = `${currentDatefgg}`.length === 1 ? '0' + currentDatefgg : currentDatefgg
        var maskcurrentDatefggm = `${currentDatefggm}`.length === 1 ? '0' + currentDatefggm : currentDatefggm
        var maskcurrentDatefggs = `${currentDatefggs}`.length === 1 ? '0' + currentDatefggs : currentDatefggs





        var currentDatesomeFormattedDate = maskcurrentDatedd + '-' + maskcurrentDatemm + '-' + currentDatey + ' ' + maskcurrentDatefgg + ':' + maskcurrentDatefggm + ':' + maskcurrentDatefggs


        var date = currentDatesomeFormattedDate;

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>

                                <div className="col-12 col-md-6 col-lg-8">
                                    <div className="giftreview">
                                        {/* <form action="gifts-order-details.html"> */}
                                        <div className="row">
                                            <div className="col-12">
                                                <h3>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Order_Details}</h3>
                                            </div>

                                            <div className="col-12">
                                                <div className="orderstatus">
                                                    <h2>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Thank_you_your_Gift_Cards_order_has_been_placed_successfully}</h2>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Order_ID} </p>
                                                    <h5>#{this.props.detailedData.orderId}</h5>
                                                    <p className="date">{date}</p>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Ordered_By}</p>
                                                    <h5>{this.props.orderResponse.uploadedByName}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Mobile_Number}</p>
                                                    <h5>+91-{this.props.orderResponse.mobileNumber}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Gift_Cards_Quantity}</p>
                                                    <h5>{this.props.orderResponse.giftCardQuantity}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Beneficiaries}</p>
                                                    <h5>{this.props.orderResponse.totalBenificiary}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Amount}</p>
                                                    <h5>₹{parseFloat(parseFloat(this.props.orderResponse.totalAmount)/100).toFixed(2)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Surcharge}</p>
                                                    <h5>₹{parseFloat(parseFloat(this.props.detailedData.surcharge)/100).toFixed(2)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Total_Amount}</p>
                                                    <h5>{parseFloat(parseFloat(parseFloat(parseFloat(this.props.orderResponse.totalAmount).toFixed(2) + parseFloat(this.props.detailedData.surcharge)).toFixed(2))/100).toFixed(2)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="reviewadrs">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Billing_Address}</p>
                                                    <h5>{this.props.orderResponse.uploadedByName}</h5>
                                                    <p>{this.props.orderResponse.billingAddress}</p>
                                                    <p><i className="mobile"></i>+91-{this.props.orderResponse.mobileNumber}</p>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <button className="lbtn btnbl" type="button" onClick={this.orderAgain}>{process.env.REACT_APP_MANAGE_GIFT_CARD_Order_Derail_VAR_FOR_Order_Again_Button}</button>

                                                {/* <div className="txn-btn"> */}
                                                {/* <button type="button" className="print" data-toggle="tooltip" title="" data-original-title="Print">Print</button>
                                                        <button type="button" className="share" data-toggle="tooltip" title="" data-original-title="Share">Share</button>
                                                        <button type="button" className="help" data-toggle="tooltip" title="" onclick="window.location.href='help.html'" data-original-title="Help">Support</button> */}
                                                {/* </div> */}
                                            </div>

                                        </div>
                                        {/* </form> */}
                                    </div>
                                </div>


                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName }, role: { roleData },
    giftCardOrder: { orderResponse }
}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser, fullName, orderResponse

});

export default connect(mapStateToProps)(GiftCardDetailedPage);