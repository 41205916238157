import BandType from "./band.type";

const INITIAL_STATE = {
    bandData : [],
    bandListToView:[]
};

const bandReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case BandType.SET_BAND:
            return {
                ...state,
                bandData : action.payload
            };
        case BandType.SET_BAND_LIST_TO_VIEW:
            return {
                ...state,
                bandListToView : action.payload
            }
    
        default:
            return state;
    }
};

export default bandReducer;