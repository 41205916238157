
import React from 'react';
import '../../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import { store } from '../../../redux/store';
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import CsvDownload from 'react-json-to-csv';
import apiCallService from '../../../service/api-call-service';

class SubGiftCardHistory extends React.Component {

    constructor() {
        super();

        this.state = {


            fileDetail: []




        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards History"

        localStorage.setItem('url', '');

        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "",
            adminReportCLass: "",
            manageGiftCardClass: "active"
        }

        store.dispatch(setActiveHeader(payload));


        this.getOrderDetailFOrFile();


    }

    getOrderDetailFOrFile = async () => {
        var url = `${process.env.REACT_APP_GET_GIFT_CARD_ORDER_DETAIL_BY_ORDER_ID_URL}?orderId=${this.props.data.orderId}`

        var response = await apiCallService.getMethodCallWithoutParam(url);


        console.log("Response for suborderId", response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ fileDetail: response.giftOrderData })
        }



    }

    render() {

        var fileToExport = [];
        var i = 0;

        this.state.fileDetail !== undefined
            && this.state.fileDetail.map(fileData => {

                var newData = {
                    SerialNumber: ++i,
                    BenificiaryName: fileData.beneficiary_name,
                    BenificiaryMobile: fileData.beneficiary_mdn,
                    Count: fileData.count,
                    Amount: parseFloat(parseFloat(fileData.total_amount) / 100).toFixed(2),
                    Reason: fileData.message
                }
                fileToExport.push(newData);
                // return fileToExport;
            })


        console.log("fileToExport fileToExport", fileToExport, this.state.fileDetail)
        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>
                                <div id={`odr${this.props.data.orderId}`} className="collapse" data-bs-parent="#giftcardhistory">
                                    <div className="row">
                                        <div className="col-12 col-md col-lg-6 border-top">
                                            <div className="cardbilladrs">
                                                <h5>Billing Address:</h5>
                                                <h6>{this.props.data.uploadedByName}</h6>
                                                <p>{this.props.data.billingAddress}</p>
                                                <p><i className="mobile"></i>+91-{this.props.data.uploadedByMdn}</p>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md col-lg-6 border-top">
                                            <div className="godrdetail">
                                                <h5>Order Details:

                                                    <CsvDownload className="exl" data={fileToExport} filename={"giftCardOrder.csv"} >Download</CsvDownload>

                                                </h5>
                                                <h6>Beneficiaries: <span>{this.props.data.totalBenificiary}</span></h6>
                                                <h6>Cards Quantity: <span>{this.props.data.totalCardQuantity}</span></h6>
                                                <h6>Cards Amount: <span>₹ {parseFloat(parseFloat(this.props.data.totalAmt) / 100).toFixed(2)}</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser

});

export default connect(mapStateToProps)(SubGiftCardHistory);