import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import forgotImg from '../../assests/forgot-password.png'
import { ReCaptcha } from 'react-recaptcha-google'
import apiCallService from '../../service/api-call-service';
import { loadReCaptcha } from 'react-recaptcha-google'
import { store } from '../../redux/store';
import { setEmail, setRefernceNumber } from '../../redux/forgot-password/forgot-password.action';
import history from '../../auth/history';
import ErrorBoundary from '../zero-login-pages/ErrorBoundary';

import shakePeLogo from '../../assests/shakepe-logo.png'

class ForgotPassword extends React.Component {

    constructor() {
        super();

        this.state = {


            email: '',


            message: '',

            captchaDone: false,

            confirmPassword: "",
            password: "",
            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            message: "",
            severty: "success",
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,
            submitButton: true

        };
    }


    componentDidMount() {
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }
        loadReCaptcha();

        if (this.props.rememberMe) {
            this.setState({ email: this.props.rememberMail });
            this.setState({ password: this.props.rememberPassword });
        }
    }

    onLoadRecaptcha = () => {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
        console.log('Testing-onLoadRecaptcha :' + this.state)
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({ captchaDone: true });
        console.log(recaptchaToken, "<= your recaptcha token")
    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            message: ''
        })
        console.log('Testing-handleEmailChange :' + this.state)
    }

    generateOTP = async (event) => {
        event.preventDefault();

        if (event.target[2] !== undefined) {
            var captchaValue = event.target[2].value;
        }

        console.log('event target value ', event.target[2], event.target[2], event.target[3])

        console.log('inside initiateLogin successfully', captchaValue);
        if (process.env.REACT_APP_CAPTCHA_CONFIG !== '1') {
            if (captchaValue === 'false') {
                console.log('inside initiateLogin successfully', captchaValue);
                return (this.setState({ message: process.env.REACT_APP_CAPTCHA_NOT_CHECKED_MESSAGE }));

            }
        }

        const url = process.env.REACT_APP_GENERATE_OTP_FOR_FORGOT_PASSWORD;
        var request =
        {
            "request": {
                "eventType": "LOGIN10",
                "destinations": [{
                    "address": this.state.email,
                    "type": "email"
                }]
            }
        }
        var response = await apiCallService.postMethodCall(url, request);

        console.log('response', response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
            store.dispatch(setRefernceNumber(response.response.referenceNumber))
            store.dispatch(setEmail(this.state.email));

            history.push('/otp')
            history.go();
        } else {
            this.setState({ message: response.response_description })
        }





    }




    render() {


        return (



            <div  className="log-container">
                <div  className="container">
                    <div  className="row justify-content-sm-center">
                        <div  className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div  className="logbox">
                                <div  className="logtxt">
                                    <img src={shakePeLogo} alt="ShakePe" className="img-fluid" />
                                    <h3>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Forgot_your_password}</h3>
                                    <p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Please_enter_your_email_address}</p>
                                </div>

                                <p style={{ color: "#f46a6a", marginTop: "-16px" }}> {this.state.message}</p>
                                
                                <div  className="login-form">
                                    <form onSubmit={this.generateOTP}>
                                        <div  className="form-row">
                                            <div  className="form-floating">
                                                <input
                                                    type="email"
                                                     className="form-control"
                                                    id="email"
                                                    placeholder="Email ID"
                                                    value={this.state.email}
                                                    onChange={this.handleEmailChange}
                                                    required
                                                />

                                                <label for="email">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_LABEL_Company_Email_ID}</label>
                                            </div>
                                        </div>



                                        <div  className="form-row">

                                            <ErrorBoundary>
                                                <ReCaptcha
                                                    id='captcha'
                                                    ref={(el) => { this.captchaDemo = el; }}
                                                    size="normal"
                                                    data-theme="light"
                                                    render="explicit"
                                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                    onloadCallback={this.onLoadRecaptcha}
                                                    verifyCallback={this.verifyCallback}
                                                    isCaptured={this.state.captchaDone}
                                                    required
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div  className="form-row">

                                            <button type="submit" id="" value={this.state.captchaDone} className="lbtn btnbl"  >{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Get_OTP}</button>

                                            <a href="/" className="forgot">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Back_to_login}</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(ForgotPassword); 