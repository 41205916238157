import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import image from "../../assests/user.jpg";

import apiCallService from '../../service/api-call-service';
import Pagination from 'react-js-pagination';
import CsvDownload from 'react-json-to-csv'
import MoneydisbursalRecordFilterComponent from '../component/moneydisbursal-record-filter.component';


const collectedTrueKeys = {
    band: [],
    category: [],
    responseStatus: []
}

const DraftDisbursal = ({ authenticated, location }) => {

    var arr = [];
    var listForAction = [];

    var multiCategoryRecord = [];


    const [uploadeDetail, setUploadeDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL_RECORD);
    const [masterChecked, setMasterChecked] = useState(false);
    const [checkedPost, setCheckedPost] = useState([]);
    const [uploadedBy, setUploadedBy] = useState('');
    const [approvedBy, setApprovedBy] = useState('');
    const [uploadedOn, setUploadedOn] = useState('')

    const [multiValueC, setMultiValueC] = useState('')
    const [multiValueB, setMultiValueB] = useState('')
    const [multiValueS, setMultiValueS] = useState('');
    const [filteredRecord, setFilteredRecord] = useState([]);

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);

    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));

        const getdetail = async () => {
            if (location.state) {
                const batchId = location.state.data.batchId;
                const responseStatus = location.state.status
                var object = "object"
                const data = location.state.data;
                console.log('data', data)
                var responseArr = responseStatus;
                console.log('responseArr', responseArr);
                const uploadedBy = location.state.data.name
                const approvedBy = location.state.data.approvedBy
                const uploadedOn = location.state.uploadedOn

                setUploadedBy(uploadedBy);
                setApprovedBy(approvedBy);
                setUploadedOn(uploadedOn);
                //this.setState({ uploadeDetail: data });
                const url = `${process.env.REACT_APP_BULK_DISBERSAL_FETCH_BATCHID_BY_RSPONSE_STATUS}?batchId=${batchId}&responseStatus=${responseArr}`
                console.log('SuccessDisbursal url', url);

                var response = await apiCallService.getMethodCallWithoutParam(url);
                console.log('data response', response)
                if (response !== undefined && response !== null && response.disbursalData !== undefined && response.disbursalData !== null) {
                    response.disbursalData.map(corpBand => {
                        var isChecked = 'isChecked';
                        corpBand[isChecked] = false;
                        arr.push(corpBand);
                        console.log('band list data', corpBand);
                    })
                    console.log('data response', response)
                }
                setUploadeDetail(arr);
                setFilteredRecord(arr);

            }
        }
        getdetail();

    }, []);

    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    const handleMultiCategorychange = (option) => {
        // ((bandListToView === null || !(bandListToView.length && bandListToView)
        setCurrentPage(1)
        setMultiValueC(option)
        multiCategoryRecord = option;
        var lis = []
        if ((multiCategoryRecord === null || !(multiCategoryRecord.length && multiCategoryRecord))) {
            console.log('In last Min Call')
            uploadeDetail.map(bat => {
                lis.push(bat)
            })
        } else if (multiCategoryRecord !== null && multiCategoryRecord.length !== 0) {
            multiCategoryRecord.map(rec => {
                uploadeDetail.map(fil => {
                    var cat = rec.value.toLowerCase();
                    console.log('matched', fil[cat] / 100 > 0);
                    if (fil[cat] / 100 > 0) {
                        console.log('matched')
                        lis.push(fil);
                    }
                })
            })

        }
        setFilteredRecord(lis);
    }
    const handleMultiBandchange = (option) => {
        //s  console.log('option log', option)
        setCurrentPage(1)
        setMultiValueB(option)

        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.band = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.band.push(op.label);
            })
        }
    }
    const handleStatuschange = (option) => {
        //s  console.log('option log', option)
        setCurrentPage(1)
        setMultiValueS(option)

        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.responseStatus = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.responseStatus.push(parseInt(op.value));
            })
        }

    }


    const clearAll = () => {
        setMultiValueS([])
        setMultiValueB([])
        setMultiValueC([])

        setFilteredRecord(uploadeDetail)

        collectedTrueKeys.responseStatus = []
        collectedTrueKeys.category = []
        collectedTrueKeys.band = [];

        multiCategoryRecord = []

        setAllValuesForFilter([])
    }




    const handleMasterChecked = (event) => {
        setMasterChecked(event.target.checked);
        currentPost.map(posts => {
            console.log('inside if handleMasterChecked', posts);
            if (event.target.checked) {
                posts.isChecked = event.target.checked;
                listForAction.push(posts)
            } else if (!event.target.checked) {
                console.log('inside else handleMasterChecked');
                posts.isChecked = event.target.checked;
                listForAction.pop(posts)
            }
        })
        setCheckedPost(currentPost);

    }


    const handleChecked = (event) => {
        var data = listForAction
        console.log(data.lenght, "and", postsPerPage)
        if (data.lenght !== postsPerPage) {
            setMasterChecked(false)
        }
        data.map(list => {
            listForAction.push(list);
        })
        currentPost.map(post => {
            console.log('post.emp_id === event.target.id && event.target.checked === true', post.band === event.target.name && event.target.checked === false)
            if (post.requestId === event.target.id && event.target.checked === true) {
                console.log('inside if handleChecked');
                console.log('post to be performed action is : ', post)
                post.isChecked = event.target.checked
                listForAction.push(post)
                console.log('list to action performed correct', listForAction);
            } else if (post.requestId === event.target.id && event.target.checked === false) {
                console.log('removed post from action performed', post)
                listForAction.pop(post)
                setMasterChecked(false)
                post.isChecked = event.target.checked
                console.log('list to action performed wrong', listForAction);
            }
        })
        setCheckedPost(currentPost)
    }


    //Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };



    var filteredPostData = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



    }

    const key = 'mdn';

    const filteredPost = [...new Map(filteredPostData.map(item =>
        [item[key], item])).values()];





    if (filteredPost === null || filteredPost === undefined) {
        currentPost = null
    } else {
        currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
    }

    console.log('currentpost', currentPost);
    console.log('filteredPost', uploadeDetail);

    var fileToExport = [];
    filteredPost.map(data => {
        var obj = {
            Employee_Name: "",
            Employee_Id: "",
            Mobile_No: "",
            Band: "",
            General: "",
            Food_and_Meal: "",
            Travel: "",
            Transaction_ID: "",
            Status: ""
        }
        if (data.isChecked) {
            obj.Employee_Name = data.empName;
            obj.Employee_Id = data.empId;
            obj.Mobile_No = data.mdn;
            obj.Band = data.band;
            obj.General = data.genral / 100;
            obj.Food_and_Meal = data.food / 100;
            obj.Travel = data.travel / 100;
            obj.Transaction_ID = data.transactionId;
            obj.Status = data.approveStatusVar
            fileToExport.push(obj);
        } else {
            // obj.Employee_Name=data.empName;
            // obj.Employee_Id=data.empId;
            // obj.Mobile_No=data.mdn;
            // obj.Band=data.band;
            // obj.General=data.genral/100;
            // obj.Food_and_Meal=data.food/100;
            // obj.Travel=data.travel/100;
            // obj.Transaction_ID=data.transactionId;
            // obj.Status=data.approveStatusVar
            // fileToExport.pop(obj);
        }

    })

    var detail = false;

    currentPost.map(pos => {
        if (pos.isChecked) {
            detail = true
        }
    })



    return (<div >
        {(authenticated)
            ? (
                <div className="mid-container" id="heightFix">
                    <div className="container-fluid">





                        <MoneydisbursalRecordFilterComponent clearAll={clearAll} handleMultiBandchange={handleMultiBandchange} handleMultiCategorychange={handleMultiCategorychange} handleStatuschange={handleStatuschange}
                            multiValueB={multiValueB} multiValueC={multiValueC} multiValueS={multiValueS} type="checker" varForHeader="InProgress Money Disbursal" />

                        <div className="emp-list">
                            <div className="row">

                                <div className="col-12">
                                    <div className="alert alert-warning">{location.state.data.isBulk === 1 ? <>Single disbursal by</> : <>Uploaded by</>} {uploadedBy}  </div>
                                </div>

                                <div className="col-12">
                                    <div className="table-responsive disbrslist">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr  className="table-primary">
                                                    <th>
                                                        <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                                            <input type="checkbox" id="" className="mdl-checkbox__input" checked={masterChecked} onClick={handleMasterChecked} />
                                                        </label>
                                                    </th>
                                                    <th>Employee Name & ID</th>
                                                    <th>Mobile No.</th>
                                                    <th>Band</th>
                                                    <th>General</th>
                                                    <th>Food</th>
                                                    <th>Travel</th>
                                                    <th>Transaction ID</th>
                                                    <th>Status</th>
                                                </tr>

                                                {currentPost.map(detail => (
                                                    <tr>
                                                        <td>
                                                            <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                                                <input type="checkbox" id={detail.requestId} className="mdl-checkbox__input" checked={detail.isChecked} onClick={handleChecked} />
                                                            </label>
                                                        </td>
                                                        <td><img src={image} alt="" className="userimg" /> {detail.empName === null ? <>NA</> : detail.empName} <br />{detail.empId === null ? <>NA</> : detail.empId} </td>
                                                        <td>{detail.mdn === null ? <>NA</> : detail.mdn}</td>
                                                        <td>{detail.band === null || detail.band === "null" ? <>NA</> : detail.band}</td>
                                                        <td> {detail.genral === "" ? <> - </> : <>₹ {parseFloat(detail.genral / 100).toFixed(2)} </>}</td>
                                                        <td> {detail.food === "" ? <> - </> : <>₹ {parseFloat(detail.food / 100).toFixed(2)} </>}</td>
                                                        <td> {detail.travel === "" ? <> - </> : <>₹ {parseFloat(detail.travel / 100).toFixed(2)} </>}</td>
                                                        <td>{detail.transactionId}</td>
                                                        <td className={`${detail.className}`}>{detail.approveStatusVar}</td>
                                                    </tr>
                                                ))



                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {parseInt(filteredPost.length) > parseInt(postsPerPage)
                                    ?
                                    <>
                                        <div className="col-12 col-sm-6">
                                            <p>
                                                {!detail ? <></> :
                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"disbursal-detail.csv"}>Export to CSV</CsvDownload>
                                                }
                                                Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <Pagination
                                                    activePage={parseInt(currentPage)}
                                                    itemsCountPerPage={parseInt(postsPerPage)}
                                                    totalItemsCount={filteredPost.length}
                                                    pageRangeDisplayed={parseInt(postsPerPage)}
                                                    onChange={paginate}
                                                    className="pagination"
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    nextPageText="Next"
                                                    prevPageText="Prev"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="col-12 col-sm-6">
                                        <p>
                                            {!detail ? <></> :
                                                <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"disbursal-detail.csv"}>Export to CSV</CsvDownload>
                                            }
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            )
            : (
                <Redirect to='/' />
            )


        }

    </div>

    )

}


const mapStateToProps = ({ token: { authenticated } }) => ({
    authenticated
});

export default connect(mapStateToProps)(DraftDisbursal);