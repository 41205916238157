import ActiveHeaderType from "./activeheader.type";

const INITIAL_STATE = {
    dashBoardClass: "active",
    manageEmployeeClass: "",
    moneyDisbursalClass: "",
    manageCardClass: "",
    manageLimitClass: "",
    activityClass:"",
    reportClass:"",


    corpListClass:"",

    adminDashboardClass:"",
    adminMngCardClass:"",
    adminReportCLass:"",
    manageGiftCardClass:""

};

const bandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActiveHeaderType.SET_ACTIVE_HEADER:
            return {
                ...state,
                dashBoardClass: action.payload.dashBoardClass,
                manageEmployeeClass: action.payload.manageEmployeeClass,
                moneyDisbursalClass: action.payload.moneyDisbursalClass,
                manageCardClass: action.payload.manageCardClass,
                manageLimitClass: action.payload.manageLimitClass,
                activityClass: action.payload.activityClass,
                corpListClass: action.payload.corpListClass,
                adminDashboardClass: action.payload.adminDashboardClass,
                adminMngCardClass: action.payload.adminMngCardClass,
                adminReportCLass: action.payload.adminReportCLass,
                manageGiftCardClass:action.payload.manageGiftCardClass

            };


        default:
            return state;
    }
};

export default bandReducer;