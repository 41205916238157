const SubTransaction = {
    "MERCHANT_PAYMENT": 11,
    "WITHDRAWAL": 12,
    "SURCHARGE": 13,
    "LOAD": 14,
    "SELF_Load": 0,
    "MANUAL_LOAD": 19,
    "DEBIT": 15,
    "CREDIT": 16,
    "MANUAL_DEBIT": 17,
    "MANUAL_CREDIT": 18,
    "MANUAL_WITHDRAWAL": 19,
    "MANUAL_MERCHANT_PAYMENT": 20,
    "MERCHANT_PAYMENT_REVERSAL": 21,
    "WITHDRAWAL_REVERSAL": 22,
    "SURCHARGE_REVERSAL": 23,
    "LOAD_REVERSAL": 24,
    "MANUAL_MERCHANT_PAYMENT_REVERSAL": 25,
    "MANUAL_WITHDRAWAL_REVERSAL": 26,
    "MANUAL_SURCHARGE_REVERSAL": 27,
    "MANUAL_LOAD_REVERSAL": 28,
    "ECOMMERECE_PAYMENT": 29,
    "MANUAL_ECOMMERECE_PAYMENT": 30,
    "ECOMMERECE_PAYMENT_REVERSAL": 31,
    "MANUAL_ECOMMERECE_PAYMENT_REVERSAL": 32,
    "DEBIT_REVERSAL": 33,
    "DISBURSAL_DEBIT": 34,
    "DISBURSAL_DEBIT_REVERSAL": 35,
    "P2P_TRANSACTIONS": 42,
}
export default SubTransaction