// import { Snackbar } from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import React from 'react';
import '../css/dashboard-reports.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import GenerateRequestId from '../../common-function/GenerateRequestId';
import apiCallService from '../../service/api-call-service';
// import CategoryLimit from '../../constants/category-limit.type';
import { convertArrayToCSV } from "convert-array-to-csv";
import FileSaver from "file-saver";
import Pagination from 'react-js-pagination';
import CsvDownload from 'react-json-to-csv';
import Select from "react-select";
// import { createThisTypeNode } from 'typescript';
import moment from 'moment';
// import Avatar from 'react-avatar';
// import CommonFunction from '../../common-function/GenerateRequestId';
// import PropTypes from 'prop-types';
import LoadingComponent from './loading/txn-loading.component';
import ReactTooltip from 'react-tooltip';
import TrasactionDescription from './constants/subtrsaction-type.coponent';
import { store } from '../../redux/store';
import { setEmployeeDetails } from '../../redux/user/user.actions';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
// var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

const collectedTrueKeys = {
    wallet_type: [],
    transaction_status: [],
    transaction_type: []
}

var ToDateVar = '';
var FromDateVar = '';
class AllEmployeeTxnReport extends React.Component {

    constructor() {
        super();

        this.state = {

            totalCount: 0,
            loading: true,
            transactionData: [],
            empDetail: [],
            totalAmount: 0,
            currentPage: 1,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL,
            size: 0,
            searchField: "",

            filteredRecord: [],
            multiValueType: [],
            multiValueRecord: [],
            multiValueStatus: [],
            toDate: '',
            fromDate: '',
            paginatedList: [],
            allValuesForFilter: [],


            placeHolder: "...",
            multiValueTypeOpt: [
                { value: "1", label: "General" },
                { value: "2", label: "Food" },
                { value: "3", label: "Travel" }
            ],
            multiValueRecords: [
                { value: "Debit", label: "Debit" },
                { value: "Credit", label: "Credit" }
            ],
            multiValueStatusOptn: [
                { value: "1", label: "Success" },
                { value: "2", label: "Failed" }
            ],
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Transactions Report"

        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "active",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "",
            adminReportCLass: ""
        }

        store.dispatch(setActiveHeader(payload));


        this.getTransactionData((this.props.roMdn));



    }

    getTransactionData = async (mdn) => {
        let headers = {
            "client_id": "OMTS_77B",
            "token": this.props.token,
            "Authorization": this.props.token
        }

        const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());

        var currentDatedd = currentDate.getDate();
        var currentDatemm = currentDate.getMonth() + 1;
        var currentDatey = currentDate.getFullYear();

        var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
        var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
        var maskcurrentDatefgg = `${currentDatey}`.length === 1 ? '0' + currentDatey : currentDatey

        var dateRang = maskcurrentDatefgg + '-' + maskcurrentDatemm + '-' + maskcurrentDatedd;


        var someDate = new Date();
        var numberOfDaysToAdd = "30";
        someDate.setDate(someDate.getDate() - numberOfDaysToAdd);

        var dd = someDate.getDate();
        var mm = someDate.getMonth() + 1;
        var y = someDate.getFullYear();



        var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
        var masky = `${mm}`.length === 1 ? '0' + mm : mm



        var someFormattedDate = y + '-' + masky + '-' + maskdd


        var request = {
            "device_info": {},
            "request": {
                "user": {
                    "username": mdn
                },
                "transaction_data": {
                    "count": 100,
                    "page_index": 0,
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "time_stamp": new Date(),
                    "transaction_status": "3",
                    "wallet_type": null,
                    "transaction_type": null,
                    // "from_date": `${someFormattedDate} 00:00:00`,
                    // "to_date": `${dateRang} 23:59:59`,
                    "allEmployee": "1"
                }
            }
        }

        var response = await apiCallService.postMethodCall(url, request, headers);

        console.log('response txn history', response)
        if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {
            this.setState({ transactionData: response.response.transaction_data })
            this.setState({ filteredRecord: response.response.transaction_data })
            this.setState({ totalCount: response.response.totalCount })
            this.setState({ loading: false })
        } else {
            this.setState({ loading: false })
        }

        //  parseFloat(parseFloat(tAm.transaction_amount.balance).toFixed(2)/100).toFixed(2)
    }




    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

    };


    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");


            let headers = {
                "client_id": "OMTS_77B",
                "token": this.props.token,
                "Authorization": this.props.token
            }

            const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY

            var request = {
                "device_info": {},
                "request": {
                    "user": {
                        "username": `${this.props.roMdn}`
                    },
                    "transaction_data": {
                        // "count": 100,
                        "from_date": `${formatFromDate} 00:00:00`,
                        "page_index": 0,
                        "request_id": GenerateRequestId.geneRateRequestId(),
                        "time_stamp": new Date(),
                        "to_date": `${formatToDate} 23:59:59`,
                        "transaction_status": "3",
                        "wallet_type": null,
                        "transaction_type": null,
                        "allEmployee": "1"
                    }
                }
            }

            var response = await apiCallService.postMethodCall(url, request, headers);

            console.log('response txn history', request, response)
            if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {
                // this.setState({ transactionData: response.response.transaction_data })
                this.setState({ filteredRecord: response.response.transaction_data })
                this.setState({ currentPage: 1 })
            } else {
                this.setState({ filteredRecord: [] })
            }


        }
    }



    handleMultiRecordchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueRecord: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.transaction_type = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.transaction_type.push(op.value)

            })
        }

    }


    handleMultiStatuschange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueStatus: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.transaction_status = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.transaction_status.push(op.value)

            })
        }

    }

    handleMultiCategorychange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueType: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.wallet_type = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.wallet_type.push(op.value)

            })
        }

    }

    setSearch = (e) => {
        this.setState({ searchField: e.target.value })
        this.setState({ currentPage: 1 })
    }



    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.transactionData })
        this.setState({ multiValueType: [] })
        this.setState({ multiValueStatus: [] })
        this.setState({ multiValueRecord: [] })

        ToDateVar = '';
        FromDateVar = '';

        this.setState({ allValuesForFilter: [] })
        collectedTrueKeys.transaction_type = []
        collectedTrueKeys.transaction_status = []
        collectedTrueKeys.wallet_type = []
        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)
    }

    setEmployee = () => {
        store.dispatch(setEmployeeDetails(this.state.empDetail));
        // store.dispatch(setBackUrl("/emp-list"))
    }


    render() {

        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                // 'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };

        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };

        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;



        var filteredPost = [];

        if ((this.state.filteredRecord === null || !(this.state.filteredRecord !== undefined && this.state.filteredRecord.length && this.state.filteredRecord))) {
            filteredPost = this.state.filteredRecord
            // filteredPost = filteredList;
            console.log('inside if of bandlistToView', this.state.filteredRecord)
        } else if (this.state.filteredRecord.length !== 0) {

            filteredPost = multiPropsFilter(this.state.filteredRecord, collectedTrueKeys);

            console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)

        }





        const key = 'transaction_Id';

        const filteredPostData = [...new Map(filteredPost.map(item =>
            [item[key], item])).values()];





        var size = filteredPostData.length;
        console.log('size dateWiseList', size);





        var searchedRecord = [];


        if (this.state.searchField !== '' && this.state.transactionData !== undefined) {
            this.state.transactionData.map(post => {
                if (post.transaction_refnumber !== undefined && post.transaction_refnumber !== null && post.transaction_refnumber.includes(this.state.searchField)) {
                    searchedRecord.push(post)
                } else if (post.mdn !== undefined && post.mdn !== null && post.mdn.includes(this.state.searchField)) {
                    searchedRecord.push(post)
                }
            })
        }

        var forexport = filteredPostData
        var sizeFilter = 0;
        if (this.state.searchField !== '') {
            forexport = searchedRecord;
            currentPost = searchedRecord.slice(indexOfFirstPost, indexOfLastPost)
            sizeFilter = searchedRecord.length
        } else
            if (filteredPostData === null || filteredPostData === undefined) {
                currentPost = null
            } else {
                currentPost = filteredPostData.slice(indexOfFirstPost, indexOfLastPost)
                sizeFilter = filteredPostData.length
            }




        var detail = true;
        if (currentPost.length === 0) {
            detail = false
        }

        console.log('currentPost', currentPost)

        var fileToExport = [];
        // var j = 0;
        forexport.map(data => {
            var obj = {
                Employee_Name: "",
                Mobile_No: "",
                // Employee_Id: "",
                Available_Balance: "",
                Transaction_ID: "",
                Transaction_Date: "",
                // Wallet_Type: "",
                Transaction_Type: "",
                Surcharge: "",
                Amount: "",
                Status: ""
            }

            obj.Employee_Name = `${data.empName}`;
            obj.Mobile_No = data.mdn;
            // obj.Employee_Id = data.empId;
            console.log('data.transaction_refnumber', data.transaction_refnumber.length)
            if (data.transaction_refnumber.length > 0) {
                obj.Transaction_ID = `'${data.transaction_refnumber}`;
            } else {
                obj.Transaction_ID = data.transaction_refnumber;
            }
            if (data.transaction_date.length > 0) {
                obj.Transaction_Date = `'${data.transaction_string_date}`;
            } else {
                obj.Transaction_Date = data.transaction_string_date;
            }



            // {
            //     data.wallet_type === CategoryLimit.FOOD
            //         ?
            //         obj.Wallet_Type = "Food Wallet"
            //         :
            //         data.wallet_type === CategoryLimit.TRAVEL
            //             ?
            //             obj.Wallet_Type = "Travel Wallet"
            //             :
            //             data.wallet_type === CategoryLimit.GENERAL
            //                 ?

            //                 obj.Wallet_Type = "General Wallet"
            //                 :
            //                 obj.Wallet_Type = "NA"


            // }

            obj.Type = data.wallet_name;


            obj.Transaction_Type = data.transaction_type;
            obj.Surcharge = parseFloat(parseFloat(data.transaction_amount.surcharge).toFixed(2) / 100).toFixed(2)
            obj.Amount = parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2);
            obj.Available_Balance = parseFloat(parseFloat(data.transaction_amount.balance).toFixed(2) / 100).toFixed(2);;


            data.transaction_status === "1"
                ?
                obj.Status = "Success"
                :
                obj.Status = `${data.transaction_description}`


            fileToExport.push(obj);

        })

        const exportCsv = () =>{
            const csv = convertArrayToCSV(fileToExport);
            const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            FileSaver.saveAs(csvData, "txn-report.csv");
        }


        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        var dateFromMax = moment(this.state.toDate);
        dateFromMax = dateFromMax.format("YYYY-MM-DD");

        var heightAdjust = window.innerHeight - 230

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>

                                <div className="mid-container">
                                    <div className="container-fluid">


                                        <div className="head">
                                            <div className="row">

                                                <div  className="col-6 col-md-4 order-md-1">
                                                    <h3>{process.env.React_APP_DESCRIPTION_FOR_TXN_History_Transactions_Report}</h3>
                                                </div>

                                                <div  className="col-6 col-md-4 order-md-3">
                                                    <div className="flterbox">

                                                        <div className="dropdown" data-toggle="tooltip" title="Filter" >
                                                            <button  className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                            <div className="dropdown-menu dropdown-menu-right">

                                                                <div className="filters" >
                                                                    <label for="select-state">Type:</label>

                                                                    <Select

                                                                        name="multiTypeValue"
                                                                        placeholder={this.state.placeHolder}
                                                                        value={this.state.multiValueRecord}
                                                                        options={this.state.multiValueRecords}
                                                                        onChange={this.handleMultiRecordchange}
                                                                        isMulti={true}
                                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>

                                                                <div className="filters">
                                                                    <label for="select-state">Status:</label>
                                                                    <Select

                                                                        name="multiTypeValue"
                                                                        placeholder={this.state.placeHolder}
                                                                        value={this.state.multiValueStatus}
                                                                        options={this.state.multiValueStatusOptn}
                                                                        onChange={this.handleMultiStatuschange}
                                                                        isMulti={true}
                                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>

                                                                <div className="filters">
                                                                    <label for="select-state">Category:</label>

                                                                    <Select

                                                                        name="multiTypeValue"
                                                                        placeholder={this.state.placeHolder}
                                                                        value={this.state.multiValueType}
                                                                        options={this.state.multiValueTypeOpt}
                                                                        onChange={this.handleMultiCategorychange}
                                                                        isMulti={true}
                                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>

                                                                <div className="filters">
                                                                    <label htmlFor="select-state">From Date:</label>
                                                                    <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                </div>

                                                                <div className="filters">
                                                                    <label htmlFor="select-state">To Date:</label>
                                                                    <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                </div>

                                                                <div className="fbtn">
                                                                    <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                                </div>
                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-4 order-md-2">
                                                    <div className="searchdata">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" id="empsearch" name="search" placeholder="Search transactions..." onChange={this.setSearch} />

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>



                                        <div className="emp-list">
                                        <div className="row">
                                            {
                                                this.state.loading
                                                    ?
                                                    <LoadingComponent />
                                                    :



                                                    <>


                                                        <div className="col-12">

                                   



                                                                {


                                                                    currentPost.length > 0
                                                                        ?
                                                                        currentPost.map(data => (
                                                                            <div className="txncard">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-md-6 col-lg-3 col-xl-4">
                                                                                        <div className="txntype">
                                                                                            <i className={data.transaction_type === 'Credit' ? "credit" : "debit"} data-toggle="tooltip" title={data.transaction_type}></i>


                                                                                            <TrasactionDescription sub_transaction_type={data.sub_transaction_type} merchant_name={data.merchant_name} />

                                                                                            <p>#{data.transaction_refnumber} - {data.transaction_string_date === null ? data.transaction_date : data.transaction_string_date}</p>
                                                                                            <p>{data.empName} - {data.mdn} </p>

                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                                                                                        <div className="txnmode">
                                                                                            <p>Mode</p>
                                                                                            <h6>{data.payment_mode === null ? <>NA</> : data.payment_mode}
                                                                                                {/* - {data.merchant_name === null ? <>NA</> : data.merchant_name} */}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="col-12 col-md-6 col-lg-3 col-xl-2">
                                                                                        {data.transaction_type === 'Credit'
                                                                                            ?
                                                                                            <div className="txnfrom">
                                                                                                <p>{process.env.React_APP_DESCRIPTION_FOR_TXN_History_Credit_TO}</p>
                                                                                                <h6> {data.wallet_name === null ? <>NA</> : data.wallet_name}</h6>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="txnfrom">
                                                                                                <p>{process.env.React_APP_DESCRIPTION_FOR_TXN_History_Debited_from}</p>
                                                                                                <h6> {data.wallet_name === null ? <>NA</> : data.wallet_name}</h6>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-4 col-md-3 col-lg-2 col-xl-1">
                                                                                        <div className="txnsurchage">
                                                                                            <p>Surcharge</p>
                                                                                            <h5>₹ {parseFloat(parseFloat(data.transaction_amount.surcharge).toFixed(2) / 100).toFixed(2)}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                                                                                        <div className="txnamount">
                                                                                            <h4>₹ {parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2)}</h4>
                                                                                            {data.transaction_status === "1"
                                                                                                ?
                                                                                                <p className="success">Success</p>
                                                                                                :
                                                                                                <p className="failed"> {data.transaction_description}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        :
                                                                        <div className="col-12">
                                                                            <div className="no-record">
                                                                                <i></i>
                                                                                <h3>  No Record Available.</h3>
                                                                            </div>
                                                                        </div>
                                                                    // <LoadingComponent />

                                                                }

                                                          

                                                        </div>






                                                        {sizeFilter > parseInt(this.state.postsPerPage)
                                                            ?
                                                            <>
                                                                <div className="col-12 col-sm-6">
                                                                    <p>
                                                                        {!detail ? <></> :
                                                                            // <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"txn-report.csv"} >Export to CSV</CsvDownload>
                                                                            <button onClick={exportCsv} style={{marginRight:'5px'}} className='sbtn btnbl mr-2' >Export to CSV</button>
                                                                        }
                                                                        Showing {valueToShow} to {(indexOfLastPost > sizeFilter) ? sizeFilter : indexOfLastPost} of {sizeFilter} entries</p>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="pager">
                                                                        <Pagination
                                                                            activePage={parseInt(this.state.currentPage)}
                                                                            itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                            totalItemsCount={sizeFilter}
                                                                            pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                            onChange={this.paginate}
                                                                            className="pagination"
                                                                            linkClass="page-link"
                                                                            itemClass="page-item"
                                                                            nextPageText="Next"
                                                                            prevPageText="Prev"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="col-12 col-sm-6">
                                                                <p>
                                                                    {!detail ? <></> :
                                                                        // <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"txn-report.csv"}>Export to CSV</CsvDownload>
                                                                        <button style={{marginRight:'5px'}} onClick={exportCsv} className='sbtn btnbl mr-2' >Export to CSV</button>
                                                                    }
                                                                </p>
                                                            </div>
                                                        }

                                                    </>
                                                // :

                                                // <div className="col-12">
                                                //     <div className="no-record">
                                                //         <i></i>
                                                //         <h3> No Record Available.</h3>
                                                //     </div>
                                                // </div>


                                            }

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset

});

export default connect(mapStateToProps)(AllEmployeeTxnReport);