import CardTypes from './card.types';

const INITIAL_STATE = {
    availableCard: [],
    ressuMDN: ""
};

const cardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case CardTypes.SET_AVAILABLE_CARD:
            return {

                ...state,
                availableCard: action.payload
            };
        case CardTypes.SET_REISSUE_MDN:
            return {

                ...state,
                ressuMDN: action.payload
            };
        default:
            return state;
    }
};

export default cardReducer;