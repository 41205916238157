import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (
        <>
            <div className="ordercard">
                <div className="odr-basic">
                    <div className="odrbox">
                        <div className="orderby">
                            <i className="truck"></i>
                            <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>

                        <div className="orderqty">
                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Quantity}</p>
                            <h4> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                        </div>

                        <div className="ordertraker">
                            <div className="order-track">
                                <h6>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_Status} </h6>
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="ordercard">
                <div className="odr-basic">
                    <div className="odrbox">
                        <div className="orderby">
                            <i className="truck"></i>
                            <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>

                        <div className="orderqty">
                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Quantity}</p>
                            <h4> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                        </div>

                        <div className="ordertraker">
                            <div className="order-track">
                                <h6>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_Status} </h6>
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="ordercard">
                <div className="odr-basic">
                    <div className="odrbox">
                        <div className="orderby">
                            <i className="truck"></i>
                            <h5> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>

                        <div className="orderqty">
                            <p>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Quantity}</p>
                            <h4> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                        </div>

                        <div className="ordertraker">
                            <div className="order-track">
                                <h6>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_Status} </h6>
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>

    );
}