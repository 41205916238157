import React, { useEffect } from "react";
import ManageEmployeeList from '../component/ManageEmployee.page';
import AddEmployee from '../component/add-bulk-employee.component';
import '../css/employee.css'
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Can from "../../auth/can/Can";
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
const EmployeeList = ({ authenticated, userRole, roleData, isPasswordReset }) => {

	useEffect(() => {
		document.title = "ShakePe Employee List";
	}, [])

	return (

		<>
			{(authenticated)
				? (
					isPasswordReset ?
						<Can
							role={userRole}
							perform="subheader-empmng:visit"
							roleData={roleData}
							yes={() => (
								<div className="mid-container" >
									<div className="container-fluid">
										<div className="row">
											<div className="col-12 col-lg-8 col-xl-9">


												<ManageEmployeeList />

											</div>

											<div className="col-12 col-lg-4 col-xl-3">
												<AddEmployee />
											</div>
										</div>
									</div>

								</div>
							)}
							no={() =>
								<Redirect to="/money-disbursal" />

							}
						/>
						: <ResetLoginPassword />





				)
				: (
					<Redirect to='/' />
				)


			}

		</>



	)
}



const mapStateToProps = ({ user: { userRole }, user: { roMdn }, role: { roleData }, token: { authenticated, isPasswordReset } }) => ({
	userRole,
	roMdn,
	roleData,
	authenticated, isPasswordReset
});


export default connect(mapStateToProps)(EmployeeList);