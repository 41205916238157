import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import '../../css/load-money.styles.css'
import SecurePng from '../../../assests/secure-logos.png'
import GenerateRequestId from '../../../common-function/GenerateRequestId';
import apiCallService from '../../../service/api-call-service';
import history from '../../../auth/history';
import { store } from '../../../redux/store';
import { setLoadAmountDetail } from '../../../redux/load-money/loadMoney.action';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TrasactionDescription from '../../../dasboard-reports/component/constants/corpSubtrsaction-type.coponent';
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import { setBankAddAndUpdateUrl, setBankBackButton } from '../../../redux/user/user.actions';

//1-toup
//2-customer load money




// var loadMasterAmount = 0;
// var totalSurCharge = 0;
// var totalMasterAmount = 0;

class ReversalMoney extends React.Component {

    constructor() {
        super();

        this.state = {
            amount: "",
            totalAvailableBalance: 0.00,
            totalLienBalance: 0.00,
            loadViaBankTransfer: "paymentGateway",
            type: "",
            loadAmount: 0.00,
            surchargeAmount: 0.00,
            totalLoadAmount: 0.00,
            recentLoadMoney: [],
            size: 0,

            serviceName: "",
            selectedDetail: {},
            buttonDisable: false,

            selectedMessage: "",

            dropDownHidden: false,
            message: "",
            severty: "success",
        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Reversal Money"


        this.getBalance(this.props.roMdn);
        this.getRecentLoadMoney(this.props.roMdn);

        this.setState({ serviceName: process.env.REACT_APP_SERVICE_NAME_FOR_REVERSAL })

    }

    getBalance = async (mdn) => {

        const request = {
            device_info: {
                api_version: "1.0",
                app_version: "9.2",
                device_id: "d215ab007e05c498",
                device_os: "Android",
                device_os_version: "5.1.1",
                imei: "357869081907005",
                ip_address: "172.160.2.140",
                model_name: "SM-J320F",
                source: "mobile",
            },
            request: {
                user: {
                    username: `91${mdn}`
                },
                transaction_data: {
                    request_id: GenerateRequestId.geneRateRequestId(),
                    limit_required: "true"
                }
            }
        };
        let headers = {
            "client_id": "OEMSAPP",
            "token": this.props.token,
            "Authorization": "BASIC abcd"
        }
        console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props);
        var response = [];

        const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
        console.log(url, request, "header")
        response = await apiCallService.postMethodCall(url, request, headers);
        const detail = response.response;
        console.log('response balanceEnquiry', response)
        if (detail !== undefined && response.response_code === 0) {
            this.setState({ totalAvailableBalance: parseFloat(detail.Balance.total_balance / 100).toFixed(2) })
            this.setState({ totalLienBalance: parseFloat(detail.Balance.total_lien / 100).toFixed(2) })
        } else {
            return 0.00
        }

    }


    getRecentLoadMoney = async (mdn) => {
        let headers = {
            "client_id": "OMTS_77B",
            "token": this.props.token,
            "Authorization": this.props.token
        }

        const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY

        var request = {
            "device_info": {},
            "request": {
                "user": {
                    "username": mdn
                },
                "transaction_data": {
                    "count": 5,
                    "from_date": null,
                    "page_index": 0,
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "time_stamp": new Date(),
                    "to_date": null,
                    "transaction_status": "3",
                    "wallet_type": null,
                    "transaction_type": "2"
                }
            }
        }

        var response = await apiCallService.postMethodCall(url, request, headers);

        console.log('response txn history', response)
        if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {

            var arr = []
            var int = 0;
            response.response.transaction_data.map(data => {
                if (int < 6) {
                    arr.push(data)
                    int++
                }
            })
            this.setState({ recentLoadMoney: arr })
            this.setState({ size: response.response.transaction_data.lenght })
        }

    }

    onAmountChange = (event) => {
        console.log('triggered onAmountChange', event.target);

        var paise = (parseFloat(event.target.value)) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }



        this.setState({ amount: event.target.value });

        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated(event.target.value === "" ? 0 : parseFloat(event.target.value) * 100, this.state.serviceName);
        }
    }

    onfiveHundredChange = (event) => {
        console.log('triggered onAmountChange', event.target);

        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 500) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }



        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 500 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 500) * 100, this.state.serviceName);
        }
    }
    onOneThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);
        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 1000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }


        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 1000 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 1000) * 100, this.state.serviceName);
        }

    }
    onFiveThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);

        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 5000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ loadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }


        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 5000 })
        this.setState({ buttonDisable: true })
        this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 5000) * 100, this.state.serviceName);

    }
    onFifteenThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);


        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 15000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ loadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }



        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 15000 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 15000) * 100, this.state.serviceName);
        }

    }
    onThirtyThousandChange = (event) => {
        console.log('triggered onAmountChange', event.target);
        var paise = (parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 30000) * 100
        if (parseFloat(paise) > parseFloat(process.env.REACT_APP_MAX_VALUE_FOR_LOAD_INPUT_IN_PAISE)) {
            return (
                this.setState({ surchargeAmount: 0 }),
                this.setState({ totalLoadAmount: 0 }),
                this.setState({ loadAmount: 0 }),
                this.setState({ message: process.env.REACT_APP_MESSAGE_FOR_MAX_VALUE_FOR_LOAD_INPUT }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true })
            )
        }


        this.setState({ amount: parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 30000 })
        this.setState({ buttonDisable: true })
        if (this.state.serviceName !== "") {
            this.getSurchargeCalulated((parseFloat(this.state.amount === '' ? 0 : this.state.amount) + 30000) * 100, this.state.serviceName);
        }
    }




    loadMoney = async (e) => {

        e.preventDefault();

        if (this.props.corpBankDetail === null) {
            return (this.setState({ message: "Bank Detail Not Available" }),
                this.setState({ severty: "error" }),
                this.setState({ dropDownHidden: true }))
        }


        const url = process.env.REACT_APP_SAVE_REVERSAL_DETAIL_URL;

        var request =
        {
            "corpId": this.props.roMdn,
            "reversalamt": this.state.loadAmount,
            "surcharge": this.state.surchargeAmount,
            "totalamt": this.state.totalLoadAmount,
            "beneAccountNumber": this.props.corpBankDetail.accountnumber,
            "beneName": this.props.corpBankDetail.fullName,
            "ifscCode": this.props.corpBankDetail.ifscCode,
            "emailAddress": this.props.currentUser,
            "mobileNumber": this.props.mdn,
            "remarks": this.state.reason,
            "status": 1,
            "reveralCreatedBy": this.props.currentUser,
            "reversalRequestId": GenerateRequestId.geneRateRequestId()

        }



        var response = await apiCallService.postMethodCall(url, request);

        console.log('response Reversal', response, request)

        if (response !== undefined && response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

            var payLoad = {
                loadAmount: this.state.loadAmount,
                surchargeAmount: this.state.surchargeAmount,
                totalLoadAmount: this.state.totalLoadAmount,
                transactionId: request.reversalRequestId,
                transactionDateAndTime: new Date(),
                transactionMode: response.modeType
            }

            store.dispatch(setLoadAmountDetail(payLoad))
            history.push('/reversal-money-detail');
            history.go();
        } else {
            this.setState({ message: response.response_description })
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true })
        }


    }






    getSurchargeCalulated = async (amount, serviceName) => {

        var message = 'Api not called'
        var url = process.env.REACT_APP_CALCULATE_SURCHARGE;
        var request = {
            "roId": this.props.roMdn,
            "quantity": amount,
            "serviceName": serviceName
        }
        console.log('response inside render method', message, ' : ', amount, serviceName)
        // if (amount !== 0 && serviceName !== '') {
        var response = await apiCallService.postMethodCall(url, request);
        message = 'Api called'
        console.log('response inside render method', message)
        console.log('response inside render method', response, request)
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
            this.setState({ buttonDisable: false })
            this.setState({ surchargeAmount: response.response.surcharge })
            this.setState({ totalLoadAmount: parseFloat(response.response.surcharge) + parseFloat(amount) })
            this.setState({ loadAmount: amount })

        } else {
            this.setState({ surchargeAmount: 0 })
            this.setState({ totalLoadAmount: 0 })
            this.setState({ loadAmount: amount })
            this.setState({ message: response.response_description })
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true })
        }
        // }

    }



    goToBankDetailPage = (event) => {
        event.preventDefault();
        console.log('event.target.id', event.target.id);
        store.dispatch(setBankBackButton('/reverse-money'));

        if (event.target.id === 'addBank') {
            store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_ADD_BANK_DETAIL));
        } else {
            store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_UPDATE_BANK_DETAIL));
        }


        history.push('/add-corp-bank-account');
        history.go();

    }


    render() {

        // this.getSurchargeCalulated();
        console.log("States here", this.state)

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <div className="mid-container">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-8">
                                            <div className="reversmoneyform" style={{ minHeight: "610px" }}>
                                                <form onSubmit={this.loadMoney}>



                                                    <div className="row">
                                                        <div className="col-12">
                                                            {/* <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Load_Money}</h3> */}
                                                            <h3>{process.env.REACT_APP_VAR_FOR_ReverseMoney_PAGE_Money_Reversal_Heading} </h3>
                                                        </div>

                                                        <Snackbar

                                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            open={this.state.dropDownHidden}
                                                            autoHideDuration={20000}
                                                            onClose={e => this.setState({ dropDownHidden: false })}
                                                        >
                                                            <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                                {this.state.message}
                                                            </Alert>
                                                        </Snackbar>

                                                        <div className="col-12">

                                                            <div className="row flex-row-reverse">

                                                                <div className="col-12 col-xl-6">
                                                                    <div className="reverseblnc">
                                                                        <i>&#8377;</i>
                                                                        <p>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Total_Available_Balance}</p>
                                                                        <h4>{this.state.totalAvailableBalance}</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-xl-6">
                                                                    <div  className="webform">
                                                                        <div  className="form-floating">
                                                                            <input className="form-control"
                                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                                type="number"
                                                                                id="acntn" value={this.state.amount} onChange={this.onAmountChange} />
                                                                            <label for="acntn">{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Enter_Reverse_Amount}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>


                                                        </div>


                                                        <div className="col-12">
                                                            <div className="addamt">
                                                                <button type="button" className="addbtn" onClick={this.onfiveHundredChange}>₹ 500</button>
                                                                <button type="button" className="addbtn" onClick={this.onOneThousandChange}>₹ 1000</button>
                                                                <button type="button" className="addbtn" onClick={this.onFiveThousandChange}>₹ 5000</button>
                                                                <button type="button" className="addbtn" onClick={this.onFifteenThousandChange}>₹ 15000</button>
                                                                <button type="button" className="addbtn" onClick={this.onThirtyThousandChange}>₹ 30000</button>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 mt-3">
                                                            <div  className="webform">
                                                                <div  className="form-floating">
                                                                    <input className="form-control" type="text" id="reason" value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })} required />
                                                                    <label for="reason">{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Enter_reason}</label>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="col-12">
                                                            <div className="corpbankdtls">
                                                                <h5>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Money_Reversal_Bank_Account}

                                                                    {
                                                                        this.props.corpBankDetail != null
                                                                            ?
                                                                            <button className="sbtn btnbl" type="button" id="updateBank" data-toggle="tooltip" title="Update" onClick={this.goToBankDetailPage}>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Update_Button}</button>
                                                                            :

                                                                            <button className="sbtn btnbl" type="button" id="addBank" onClick={this.goToBankDetailPage}>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Add_Button}</button>

                                                                    }


                                                                </h5>
                                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Bank_Name_Label}</span> {this.props.corpBankDetail !== null ? this.props.corpBankDetail.bank : <>NA</>}</p>
                                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Full_Name_Label}</span> {this.props.corpBankDetail !== null ? this.props.corpBankDetail.fullName : <>NA</>}</p>
                                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__Account_No_Label}</span> {this.props.corpBankDetail !== null ? this.props.corpBankDetail.accountnumber : <>NA</>}</p>
                                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__Account_Type_Label}</span> {this.props.corpBankDetail !== null ? this.props.corpBankDetail.accountType : <>NA</>}</p>
                                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__IFSC_Code_Label}</span> {this.props.corpBankDetail !== null ? this.props.corpBankDetail.ifscCode : <>NA</>}</p>

                                                                <div className="attachrow">
                                                                    {
                                                                        this.props.corpBankDetail !== undefined && this.props.corpBankDetail !== null && this.props.corpBankDetail.cancelledChequeURL !== undefined && this.props.corpBankDetail.cancelledChequeURL !== "" && this.props.corpBankDetail.cancelledChequeURL !== null
                                                                            ?
                                                                            <a href={this.props.corpBankDetail.cancelledChequeURL} target="_blank" className="imglink">{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__Cancelled_Cheque_Label}</a>

                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>




                                                        <div className="col-12 ">
                                                            <div className="sbmtcard">
                                                                <div className="row">
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay plus">
                                                                            <p>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Reversal_Amount}</p>
                                                                            <h4>₹ {this.state.loadAmount === "" ? 0 : parseFloat(parseFloat(this.state.loadAmount) / 100).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay equal">
                                                                            <p>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_SURCHARGE}</p>
                                                                            <h4>₹ {this.state.surchargeAmount === "" ? 0 : parseFloat(parseFloat(this.state.surchargeAmount) / 100).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay">
                                                                            <p>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Total_Amount}</p>
                                                                            <h4>₹ {this.state.totalLoadAmount === "" ? 0 : parseFloat(parseFloat(this.state.totalLoadAmount) / 100).toFixed(2)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-md-3 col-lg-3">
                                                                        <div className="amnpay">
                                                                            {parseInt(this.state.totalLoadAmount) === 0 ?
                                                                                null :
                                                                                <button type="submit" value="" className="lbtn btnbl" disabled={this.state.buttonDisable}>Proceed</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6 col-xl-4">
                                            <div className="recent-txn" style={{ "min-height": "610px" }}>
                                                <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Recent_Load_Money}
                                                    {
                                                        this.state.size < 5
                                                            ?
                                                            null
                                                            :
                                                            <Link

                                                                to={{
                                                                    pathname: '/load-txn',
                                                                    // state: { mdn: this.props.mdn,roMdn: this.props.roMdn },
                                                                    state: { roMdn: this.props.roMdn },

                                                                }}
                                                                className="sbtn btnbl"
                                                            >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Recent_Load_Money_View_all_Button}</Link>
                                                    }
                                                </h3>
                                                {this.state.size === 0 ?
                                                    <div className="col-12">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3 style={{ borderBottom: "none" }}> No Record Available.</h3>
                                                        </div>
                                                    </div>
                                                    :


                                                    this.state.recentLoadMoney.map(data => (
                                                        <div className="txncard">
                                                            <a style={{ color: "#354558" }} >
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-8">
                                                                        <div className="txntype">
                                                                            <i className="credit" data-toggle="tooltip" title="Credit"></i>
                                                                            <TrasactionDescription sub_transaction_type={data.sub_transaction_type} transaction_description={data.transaction_description} merchant_name={data.merchant_name} />

                                                                            {/* <h5>Load Money - TA Balance</h5> */}
                                                                            <p>#{data.transaction_refnumber} <br />{data.transaction_string_date === null ? data.transaction_date : data.transaction_string_date}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4">

                                                                        <div className="txnamount">
                                                                            <h4>₹ {parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2)}</h4>
                                                                            {data.transaction_status === "1"
                                                                                ?
                                                                                <p className="success">Success</p>
                                                                                :
                                                                                <p className="failed">{data.transaction_description}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )

                                                    )


                                                }


                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>

                            :
                            <ResetLoginPassword />

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName, corpBankDetail }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, token, currentUser, fullName, isPasswordReset, corpBankDetail

});

export default connect(mapStateToProps)(ReversalMoney);