import '../css/upload-filter.styles.css';

import React from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';



class RecordFilterComponent extends React.Component {
    constructor(props) {
        super();
        this.state = {
            placeHolder: '...',

        };

    }
    componentDidMount() {
        // window.history.pushState(null, document.title, window.location.href);
        // window.addEventListener('popstate', function (event){
        //     window.history.pushState(null, document.title,  window.location.href);
        // });
    }

    render() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };


        return (



            <div className="col-2">
                <div className="flterbox">

                    <div className="dropdown " data-toggle="tooltip" title="Filter" >

                        <button className="fltrbtn dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"></button>

                        <div className="dropdown-menu dropdown-menu-end" >


                            <div className="filters">
                                <label htmlFor="select-state">Department:</label>
                                <Select
                                    name="filters"
                                    placeholder={this.state.placeHolder}
                                    value={this.props.multiValueD}
                                    options={this.props.departmentaList}
                                    onChange={this.props.handleMultiDepartmentchange}
                                    isMulti={true}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                    styles={customStyles}
                                />
                            </div>

                            <div className="filters">
                                <label htmlFor="select-state">Band:</label>
                                <Select
                                    name="filters"
                                    placeholder={this.state.placeHolder}
                                    value={this.props.multiValueB}
                                    options={this.props.bandData}
                                    onChange={this.props.handleMultiBandchange}
                                    isMulti={true}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                    styles={customStyles}
                                />
                            </div>

                            <div className="filters">
                                <label htmlFor="select-state">Location:</label>
                                <Select
                                    name="filters"
                                    placeholder={this.state.placeHolder}
                                    value={this.props.multiValueL}
                                    options={this.props.locationData}
                                    onChange={this.props.handleMultiLocationchange}
                                    isMulti={true}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                    styles={customStyles}
                                />
                            </div>
                            {this.props.type === 'fail' ?
                                <div className="filters">
                                    <label htmlFor="select-state">Reason:</label>
                                    <Select
                                        name="filters"
                                        placeholder={this.state.placeHolder}
                                        value={this.props.multiValueR}
                                        options={this.props.reasonMultiValue}
                                        onChange={this.props.handleReasonchange}
                                        isMulti={true}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                        styles={customStyles}
                                    />
                                </div>
                                : null
                            }
                            <div className="fbtn">
                                <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.props.clearAll}>Clear all</button>
                            </div>

                        </div>

                    </div>


                </div>
                <div  className="actnbtn">
                    <a href="/upload-history" data-toggle="tooltip" title="Back" ><i  className="back"></i></a>
                </div>
            </div>



        )
    }

}



const mapStateToProps = ({ band: { bandData }, location: { locationData }, location: { departmentaList } }) => ({
    bandData,
    locationData,
    departmentaList
});


export default connect(mapStateToProps)(RecordFilterComponent);
