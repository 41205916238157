import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'

import image from "../../assests/user.jpg";

import CsvDownload from 'react-json-to-csv'
import apiCallService from '../../service/api-call-service';
import Pagination from 'react-js-pagination';
import VerifyDisburse from '../../employee/component/VerifyDisburse.component'
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action';
import { setMessageToBeShown } from '../../redux/message/message.action';
import history from '../../auth/history';
import moneydisbursalRecordFilterComponent from '../component/moneydisbursal-record-filter.component';
import MoneydisbursalRecordFilterComponent from '../component/moneydisbursal-record-filter.component';
import GenerateRequestId from '../../common-function/GenerateRequestId';


const collectedTrueKeys = {
	band: [],
	category: [],
	responseStatus: []
}
const CheckerSuccessDisbursal = ({ authenticated, location, mdn, fullName, currentUser, roMdn }, props) => {

	var arr = [];
	var listForAction = [];
	var multiCategoryRecord = [];

	const [uploadeDetail, setUploadeDetail] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL_RECORD);
	const [otp, setOtp] = useState('');
	const [password, setPassword] = useState('');
	const [referenceNumber, setReferenceNumber] = useState('');
	const [uploadedBy, setUploadedBy] = useState('');
	const [approvedBy, setApprovedBy] = useState('');
	const [uploadedOn, setUploadedOn] = useState('');
	const [masterChecked, setMasterChecked] = useState(false);
	const [checkedPost, setCheckedPost] = useState([]);
	const [batchId, setBatchId] = useState('');
	const [dropDownHidden, setDropDownHidden] = useState(false);
	const [message, setMessage] = useState('');
	const [severty, setSeverty] = useState('');
	const [reason, setReason] = useState('');

	const [multiValueC, setMultiValueC] = useState('')
	const [multiValueB, setMultiValueB] = useState('')
	const [multiValueS, setMultiValueS] = useState('');
	const [filteredRecord, setFilteredRecord] = useState([]);

	const [time, setTime] = useState(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT));
	const [triggeretime, setTriggeretime] = useState(true);
	const [disbursalSize, setDisbursalSize] = useState(0);

	const [allValuesForFilter, setAllValuesForFilter] = useState([]);

	const [otpButton, setOtpButton] = useState(true)

	useEffect(() => {
		var payload = {
			dashBoardClass: "",
			manageEmployeeClass: "",
			moneyDisbursalClass: "active",
			manageCardClass: "",
			manageLimitClass: "",
			activityClass: ""
		}

		store.dispatch(setActiveHeader(payload));

		methodToSetUploadList();


	}, []);


	const methodToSetUploadList = async () => {
		if (location.state) {
			const batchId = location.state.data.batchId;
			const responseStatus = location.state.status
			var object = "object"
			const data = location.state.data;
			console.log('data', data)
			var responseArr = responseStatus.split(",");
			console.log('responseArr', responseArr);
			const uploadedBy = location.state.data.name
			const approvedBy = location.state.data.approvedBy
			const uploadedOn = location.state.uploadedOn
			setBatchId(batchId);
			setUploadedBy(uploadedBy);
			setApprovedBy(approvedBy);
			setUploadedOn(uploadedOn);
			//this.setState({ uploadeDetail: data });
			const url = `${process.env.REACT_APP_BULK_DISBERSAL_FETCH_BATCHID_BY_RSPONSE_STATUS}?batchId=${batchId}&responseStatus=${responseArr}`
			console.log('SuccessDisbursal url', url);

			var response = await apiCallService.getMethodCallWithoutParam(url);
			console.log('data response', response);
			if (response !== undefined && response !== null && response.disbursalData !== undefined && response.disbursalData !== null) {
				setDisbursalSize(response.disbursalSize);
				response.disbursalData.map(corpBand => {
					var isChecked = 'isChecked';
					corpBand[isChecked] = false;
					arr.push(corpBand);
					console.log('band list data', corpBand);
				})
				console.log('data response', response)
			}
			setUploadeDetail(arr);
			setFilteredRecord(arr);
		}
	}


	const handleMultiCategorychange = (option) => {
		// ((bandListToView === null || !(bandListToView.length && bandListToView)
		setMultiValueC(option)
		multiCategoryRecord = option;
		var lis = []
		if ((multiCategoryRecord === null || !(multiCategoryRecord.length && multiCategoryRecord))) {
			console.log('In last Min Call')
			uploadeDetail.map(bat => {
				lis.push(bat)
			})
		} else if (multiCategoryRecord !== null && multiCategoryRecord.length !== 0) {
			multiCategoryRecord.map(rec => {
				uploadeDetail.map(fil => {
					var cat = rec.value.toLowerCase();
					console.log('matched', fil[cat] / 100 > 0);
					if (fil[cat] / 100 > 0) {
						console.log('matched')
						lis.push(fil);
					}
				})
			})

		}
		setFilteredRecord(lis);
	}
	const handleMultiBandchange = (option) => {
		console.log('option before', option)
		setMultiValueB(option)

		var data = []
		if (allValuesForFilter.length === 0) {

			console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
			setAllValuesForFilter(option)
		} else {
			console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
			allValuesForFilter.map(all => {
				data.push(all);
			})
			option !== null && option.map(op => {
				data.push(op);

			})
			setAllValuesForFilter(data)
		}

		collectedTrueKeys.band = [];
		if (option !== null) {
			option.map(op => {

				collectedTrueKeys.band.push(op.label);
			})
		}

	}
	const handleStatuschange = (option) => {
		//s  console.log('option log', option)
		setMultiValueS(option)

		var data = []
		if (allValuesForFilter.length === 0) {

			console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
			setAllValuesForFilter(option)
		} else {
			console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
			allValuesForFilter.map(all => {
				data.push(all);
			})
			option !== null && option.map(op => {
				data.push(op);

			})
			setAllValuesForFilter(data)
		}

		collectedTrueKeys.responseStatus = [];
		if (option !== null) {
			option.map(op => {

				collectedTrueKeys.responseStatus.push(parseInt(op.value));
			})
		}

	}


	const clearAll = () => {
		setMultiValueS([])
		setMultiValueB([])
		setMultiValueC([])

		setFilteredRecord(uploadeDetail)

		collectedTrueKeys.responseStatus = []
		collectedTrueKeys.category = []
		collectedTrueKeys.band = [];

		multiCategoryRecord = []

		setAllValuesForFilter([])

	}




	const handleMasterChecked = (event) => {
		setMasterChecked(event.target.checked);
		uploadeDetail.map(posts => {
			console.log('inside if handleMasterChecked', posts);
			if (event.target.checked) {
				if (posts.responseStatus === 0 || posts.responseStatus === 5) {
					posts.isChecked = false;
					listForAction.push(posts)
				} else {
					posts.isChecked = event.target.checked;
					listForAction.push(posts)
				}
			} else if (!event.target.checked) {
				console.log('inside else handleMasterChecked');
				posts.isChecked = event.target.checked;
				listForAction.pop(posts)
			}
		})
		setCheckedPost(uploadeDetail);

	}


	const handleChecked = (event) => {
		var data = listForAction
		console.log(data.lenght, "and", postsPerPage)
		if (data.lenght !== postsPerPage) {
			setMasterChecked(false)
		}
		data.map(list => {
			listForAction.push(list);
		})
		currentPost.map(post => {
			console.log('post.emp_id === event.target.id && event.target.checked === true', post.band === event.target.name && event.target.checked === false)
			if (post.requestId === event.target.id && event.target.checked === true) {
				console.log('inside if handleChecked');
				console.log('post to be performed action is : ', post)
				post.isChecked = event.target.checked
				listForAction.push(post)
				console.log('list to action performed correct', listForAction);
			} else if (post.requestId === event.target.id && event.target.checked === false) {
				console.log('removed post from action performed', post)
				listForAction.pop(post)
				setMasterChecked(false)
				post.isChecked = event.target.checked
				console.log('list to action performed wrong', listForAction);
			}
		})
		setCheckedPost(currentPost)
	}



	const otpGeneration = async () => {



		var totalCost = 0
		uploadeDetail.map(detail => {
			if (detail.isChecked) {
				if (detail.genral !== '') {
					totalCost = parseFloat(totalCost) + parseFloat(detail.genral);
				}
				if (detail.food !== '') {
					totalCost = parseFloat(totalCost) + parseFloat(detail.food);
				}
				if (detail.travel !== '') {
					totalCost = parseFloat(totalCost) + parseFloat(detail.travel);
				}
			}
		})


		console.log("Total Balnce is totalCost", totalCost)


		const request = {
			clientIndentifier:  roMdn 
	   };
		let headers = {
			"client_id": "OEMSAPP",
			"token": props.token,
			"Authorization": "BASIC abcd"
		}
		// console.log("Total Balnce is totalCost inside balanceEnquiry request :-", request, " Employee Details ", props);
		var response = [];

		const urlB = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY_CORP
		console.log(urlB, request, "header")
		response = await apiCallService.postMethodCall(urlB, request, headers);
		const detail = response.response;
		console.log('Total Balnce is totalCost response', response.response)


		var RoBalance = 0;
		if (response !== undefined && response.response_code !== undefined && response.response_code == 0 && response.response !== undefined) {
			RoBalance = response?.response?.availableBalance
		}

		console.log('Total Balnce is totalCost response', RoBalance, " : ", parseInt(RoBalance) < parseInt(totalCost))
		if (parseInt(RoBalance) < parseInt(totalCost / 100) ) {
			return (
				setDropDownHidden(true),
				setSeverty("error"),
				setMessage(process.env.React_APP_Message_Emp_detail_disburse_if_ro_insufficent_balance)
			)
		} else {
			var url = process.env.REACT_APP_URL_TO_GET_OTP
			var requestToSend = {
				"request": {
					"eventType": "rowalletlinkup",
					"destinations": [
						{
							"address": mdn,
							"type": "mobile"
						}
					]
				}
			}

			console.log('request allValues', requestToSend)

			var response = await apiCallService.postMethodCall(url, requestToSend);


			console.log('request response allValues', response);
			if (response.response_code === 0 && response.response !== undefined) {
				setReferenceNumber(response.response.referenceNumber)
				setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
				setOtpButton(false)
				document.getElementById("otpPopupOpen").click();
			} else {
				setDropDownHidden(true);
				setSeverty("error");
				setMessage(` ${response.response_description}`);
			}
		}




	}


	const handleChange = e => {
		const { name, value } = e.target;
		console.log('target', e.target.getAttribute('key'));
		console.log('name :', name, 'value :', value)

		if (name === 'otp') {
			console.log('otp', value)
			setOtp(value)
		} else if (name === 'password') {
			console.log('password', value)
			setPassword(value)
		} else if (name === 'reason') {
			console.log('reason', value);
			setReason(value)
		}
	};


	const handleResendOtp = async (event) => {
		console.log(' handleResendOtp hitted');
		const request = {
			"request": {
				"referenceNumber": referenceNumber
			}
		}
		const url = process.env.REACT_APP_RESEND_OTP_URL;

		var response = await apiCallService.postMethodCall(url, request);
		setTriggeretime(false)
		console.log('handleResendOtp', request, response);
		if (response.response_code === 0 && response.response !== undefined) {

			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_page_otp_pop_up_resend_otp));
			setSeverty("success");
			setReferenceNumber(response.response.referenceNumber)
			setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
			setTriggeretime(true)
			console.log('setTime(Date.now() +  parseInt(process.env.REACT_APP_OTP_TIME_OUT))', time)
		} else {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");

		}
	}


	const formDisburseSubmit = async (event) => {

		event.preventDefault();
		var arr = [];
		var isFullApproved = 2

		uploadeDetail.map(detail => {
			if (detail.isChecked) {
				arr.push(detail.requestId);
			}
		})



		if (parseInt(disbursalSize) === parseInt(arr.length)) {
			isFullApproved = 1
		}

		var RequestToSend =
		{
			"request": {
				"batchId": batchId,
				"approvedByEmail": currentUser,
				"approvedByName": fullName,
				"approvedOrReject": "0",
				"transactionPassword": password,
				"isFullApproved": isFullApproved,
				"requestIdList": arr,
				"referenceNumber": referenceNumber,
				"otp": otp,
				"reason": 2
			}
		}


		console.log('Detail That Are Processed', uploadeDetail);


		console.log('RequestToSend', RequestToSend);
		const url = process.env.REACT_APP_BULK_MONEY_DISBERSAL_ACTION;

		var response = await apiCallService.postMethodCall(url, RequestToSend);

		console.log("REACT_APP_BULK_MONEY_DISBERSAL_ACTION", response);

		if (response !== undefined && response !== null && response.response_code !== undefined && response.response_code === 0) {
			console.log('inside success')
			setDropDownHidden(true);
			setSeverty("success");
			setMessage(process.env.React_APP_Message_checker_success_disbursal_If_approved);
			const data = location.state.data;
			history.push({
				pathname: '/checker-success-disbursal',
				state: { data, status: '2,0,5', uploadedOn: uploadedOn }
			})
			history.go();

		} else {
			if (response.response_description !== undefined && response.response_description.toLowerCase().includes("otp")) {
				setDropDownHidden(true);
				setMessage(response.response_description);
				setSeverty("error");
			} else {
				setDropDownHidden(true);
				setMessage(response.response_description);
				setSeverty("error");
				setPassword('');
				setOtp('');
				document.getElementById("closeToggleAvail").click();
			}

		}
	}


	const formDisburseReject = async (event) => {

		event.preventDefault();
		var arr = [];


		uploadeDetail.map(detail => {
			if (detail.isChecked) {
				arr.push(detail.requestId);
			}
		})

		var isFullApproved = 2
		console.log('disbursalSize', disbursalSize, 'arr.length', arr.length)
		if (parseInt(disbursalSize) === parseInt(arr.length)) {
			isFullApproved = 1
		}
		var RequestToSend =
		{
			"request": {
				"batchId": batchId,
				"rejectedByEmail": currentUser,
				"rejectedByName": fullName,
				"approvedOrReject": "1",
				"transactionPassword": password,
				"isFullApproved": isFullApproved,
				"requestIdList": arr,
				"responseDescription": reason,
				"rejectedDate": Date.now(),
				"reason": 1
			}

		}


		console.log('Detail That Are Processed', uploadeDetail);



		console.log('RequestToSend', RequestToSend);
		const url = process.env.REACT_APP_BULK_MONEY_DISBERSAL_ACTION;

		var response = await apiCallService.postMethodCall(url, RequestToSend);

		console.log("REACT_APP_BULK_MONEY_DISBERSAL_ACTION", url, response);

		if (response !== undefined && response !== null && response.response_code !== undefined && response.response_code === 0) {
			console.log('inside success')
			setDropDownHidden(true);
			setSeverty("success");
			setMessage(process.env.React_APP_Message_checker_success_disbursal_If_reject);
			const data = location.state.data;
			history.push({
				pathname: '/failed-disbursal',
				state: { data, status: '4', uploadedOn: uploadedOn }
			})
			history.go();

		} else {
			setDropDownHidden(true);
			setSeverty("error");
			setMessage(` ${response.response_description}`);

		}
	}

	const closeToggle = () => {
		setPassword('');
		setOtp('');
		setOtpButton(true);
	}


	//console.log(posts);
	var currentPost = '';
	//Get Current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const valueToShow = indexOfFirstPost + 1;


	//Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const multiPropsFilter = (products, filters) => {
		const filterKeys = Object.keys(filters);
		return products.filter(product => {
			return filterKeys.every(key => {
				if (!filters[key].length) return true;
				// Loops again if product[key] is an array (for material attribute).
				if (Array.isArray(product[key])) {
					return product[key].some(keyEle => filters[key].includes(keyEle));
				}
				return filters[key].includes(product[key]);
			});
		});
	};

	var filteredPostData = [];

	if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
		filteredPostData = filteredRecord
		// filteredPost = filteredList;
		console.log('inside if of bandlistToView', allValuesForFilter)
	} else if (allValuesForFilter.length !== 0) {

		filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

		console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



	}

	const key = 'mdn';

	const filteredPost = [...new Map(filteredPostData.map(item =>
		[item[key], item])).values()];





	if (filteredPost === null || filteredPost === undefined) {
		currentPost = null
	} else {
		currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
	}


	console.log('currentpost', currentPost);
	console.log('filteredPost', uploadeDetail);

	var singleCheck = false
	currentPost.map(post => {
		if (post.isChecked && post.responseStatus !== 5) {
			singleCheck = true;
		}
	})

	var totalAmount = 0
	uploadeDetail.map(detail => {
		if (detail.isChecked) {
			if (detail.genral !== '') {
				totalAmount = parseFloat(totalAmount) + parseFloat(detail.genral);
			}
			if (detail.food !== '') {
				totalAmount = parseFloat(totalAmount) + parseFloat(detail.food);
			}
			if (detail.travel !== '') {
				totalAmount = parseFloat(totalAmount) + parseFloat(detail.travel);
			}
		}
	})

	var fileToExport = [];
	var j = 0;
	uploadeDetail.map(data => {
		var obj = {
			Employee_Name: "",
			Employee_Id: "",
			Mobile_No: "",
			Band: "",
			General: "",
			Food_and_Meal: "",
			Travel: "",
			Transaction_ID: "",
			Status: ""
		}
		if (data.isChecked) {
			j++
			obj.Employee_Name = data.empName;
			obj.Employee_Id = data.empId;
			obj.Mobile_No = data.mdn;
			obj.Band = data.band;
			obj.General = data.genral / 100;
			obj.Food_and_Meal = data.food / 100;
			obj.Travel = data.travel / 100;
			obj.Transaction_ID = data.transactionId;
			obj.Status = data.approveStatusVar
			fileToExport.push(obj);
		} else {
			// obj.Employee_Name = data.empName;
			// obj.Employee_Id = data.empId;
			// obj.Mobile_No = data.mdn;
			// obj.Band = data.band;
			// obj.General = data.genral / 100;
			// obj.Food_and_Meal = data.food / 100;
			// obj.Travel = data.travel / 100;
			// obj.Transaction_ID = data.transactionId;
			// obj.Status = data.approveStatusVar
			// fileToExport.pop(obj);
		}

	})


	var detail = false;

	currentPost.map(pos => {
		if (pos.isChecked) {
			detail = true
		}
	})


	if (mdn !== undefined) {
		var zeroOne = mdn.substring(0, 2);
	}

	if (mdn !== undefined) {
		var sevenNine = mdn.substring(8, 10);
	}


	var mystring = `${zeroOne}******${sevenNine}`;
	console.log('this.props.senderValues.mdn location.state.data', location.state.data)

	return (<div >
		{(authenticated)
			? (
				<div className="mid-container" id="heightFix">
					<div className="container-fluid">


						<Snackbar

							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={dropDownHidden}
							autoHideDuration={3000}
							onClose={e => setDropDownHidden(false)}
						>
							<Alert onClose={e => setDropDownHidden(false)} severity={severty}>
								{message}
							</Alert>
						</Snackbar>
						<MoneydisbursalRecordFilterComponent otpGeneration={otpGeneration} singleCheck={singleCheck} otpButton={otpButton}
							clearAll={clearAll} handleMultiBandchange={handleMultiBandchange} handleMultiCategorychange={handleMultiCategorychange}
							handleStatuschange={handleStatuschange} multiValueB={multiValueB} multiValueC={multiValueC} multiValueS={multiValueS}
							type="maker" varForHeader="Successful Money Disbursal" />


						<div className="emp-list">

							<div className="row">


								<div className="col-12">
									<div className="upload-success">{location.state.data.isBulk === 1 ? <>Single disbursal by</> : <>Uploaded by</>} {uploadedBy} on {uploadedOn}  <span>Total selected: {j} and Disbursal amount: ₹ {parseFloat(totalAmount / 100).toFixed(2)}</span></div>
								</div>

								<div className="col-12">
									<div className="table-responsive disbrslist">

										{currentPost.length > 0
											?
											<table className="table table-hover table-bordered">
												<tbody>
													<tr className="table-primary">
														<th>
															<label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
																<input type="checkbox" id="" className="mdl-checkbox__input" checked={masterChecked} onClick={handleMasterChecked} /> All
															</label>
														</th>
														<th>Employee Name & ID</th>
														<th>Mobile No.</th>
														<th>Band</th>
														<th>General</th>
														<th>Food</th>
														<th>Travel</th>
														<th>Transaction ID</th>
														<th>Status</th>
													</tr>

													{currentPost.map(detail => (
														<tr>
															<td>

																<input type="checkbox" id={detail.requestId} disabled={detail.responseStatus === 5 || detail.responseStatus === 0 || detail.responseStatus === 6} checked={detail.isChecked} onClick={handleChecked} />

															</td>
															<td><img src={image} alt="" className="userimg" /> {detail.empName === null ? <>NA</> : detail.empName} <br />{detail.empId === null ? <>NA</> : detail.empId} </td>
															<td>{detail.mdn === null ? <>NA</> : detail.mdn}</td>
															<td>{detail.band === null || detail.band === "null" ? <>NA</> : detail.band}</td>
															<td> {detail.genral === "" ? <> - </> : <>₹ {parseFloat(detail.genral / 100).toFixed(2)} </>}</td>
															<td> {detail.food === "" ? <> - </> : <>₹ {parseFloat(detail.food / 100).toFixed(2)} </>}</td>
															<td> {detail.travel === "" ? <> - </> : <>₹ {parseFloat(detail.travel / 100).toFixed(2)} </>}</td>
															<td>{detail.transactionId}</td>
															<td className={`${detail.className}`}>{detail.approveStatusVar}</td>
														</tr>
													))
													}
												</tbody>
											</table>
											:
											<div className="col-12">
												<div className="no-record">
													<i></i>
													<h3> No Record Available.</h3>
												</div>
											</div>
										}
									</div>
								</div>

								{parseInt(filteredPost.length) > parseInt(postsPerPage)
									?
									<>
										<div className="col-12 col-sm-6">
											<p>
												{!detail ? <></> :
													<CsvDownload className="mbtn btnbl" data={fileToExport} filename={"disbursal-detail.csv"}>Export to CSV</CsvDownload>
												}
												Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
										</div>

										<div className="col-12 col-sm-6">
											<div className="pager">
												<Pagination
													activePage={parseInt(currentPage)}
													itemsCountPerPage={parseInt(postsPerPage)}
													totalItemsCount={filteredPost.length}
													pageRangeDisplayed={parseInt(postsPerPage)}
													onChange={paginate}
													className="pagination"
													linkClass="page-link"
													itemClass="page-item"
													nextPageText="Next"
													prevPageText="Prev"
												/>
											</div>
										</div>
									</>
									:
									<div className="col-12 col-sm-6">
										<p>
											{!detail ? <></> :
												<CsvDownload className="mbtn btnbl" data={fileToExport} filename={"disbursal-detail.csv"}>Export to CSV</CsvDownload>
											}
										</p>
									</div>
								}
								<VerifyDisburse
									varFor='otp'
									targetid="approve"
									buttonActionName="Disburse"
									handleChange={handleChange}
									formDisburseSubmit={formDisburseSubmit}
									totalAmount={parseFloat(totalAmount / 100).toFixed(2)}
									handleResendOtp={handleResendOtp}
									mystring={mystring}
									closeToggle={closeToggle}
									otp={otp}
									password={password}
									time={time}
									triggeretime={triggeretime}
									referenceNumber={referenceNumber}
									otpButton={otpButton}
								/>
								<VerifyDisburse
									varFor=''
									targetid="reject"
									buttonActionName="Disburse"
									handleChange={handleChange}
									formDisburseReject={formDisburseReject}

								/>

							</div>

						</div>

					</div>

				</div>


			)
			: (
				<Redirect to='/' />
			)


		}

	</div>

	)

}


const mapStateToProps = ({ token: { authenticated, token }, user: { mdn, fullName, currentUser, roMdn }, dropdown: { hidden }, message: { message }, }) => ({
	authenticated, mdn, hidden, message, fullName, currentUser, roMdn, token
});

export default connect(mapStateToProps)(CheckerSuccessDisbursal);