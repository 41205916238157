
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import apiCallService from '../../service/api-call-service';
import Pagination from 'react-js-pagination';

import Loader from './loader.issuedBene';
import CommonFunction from '../../common-function/GenerateRequestId';
import Avatar from 'react-avatar';
import moment from 'moment';
import Can from '../../auth/can/Can';
import CsvDownload from 'react-json-to-csv';
var collectedTrueKeys = {
    status: []
}
var ToDateVar = "";
var FromDateVar = "";
class IssuedGiftCard extends React.Component {

    constructor() {
        super();

        this.state = {

            loading: false,
            posts: [],
            filteredPost: [],
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_GIFTCARD_ORDER_HISTORY,
            currentPage: 1,
            totalLength: 0,

            currentPost: [],

            bulkButtonShow: false,
            masterChecked: false,

            searchField: '',

            fromDate: "",
            toDate: "",


            allValuesForFilter: [],
            multiValue: [],
            multiValueLocation: [],
            multiValueForRole: [],
            multiValueForCardStatus: [],
            multiValueForKYCStatus: [],


            checkedPostForAction: [],


            updatedRole: '',
            maxUserAssignedToRole: 0,
            alphaSort: '',
            dateSort: '',
            orderOn: 'dateCreatedOn',
            orderType: 'desc',


            orderStatusOption: [
                { value: "2", label: "Success" },
                { value: "3", label: "Failed" }
            ],
            deliveryStatusOption: [
                { value: "0", label: "Ordered" },
                { value: "1", label: "Payment" },
                { value: "2", label: "Delivered" }
            ],

            placeHolder: "...",


        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards History"

        localStorage.setItem('url', '');

        this.getAllBeneficiary(collectedTrueKeys, this.state.currentPage, this.state.searchField, this.state.fromDate, this.state.toDate);
    }



    getAllBeneficiary = async (cTk, page, searchField, fromDate, toDate) => {

        this.setState({ currentPost: [] })

        this.setState({ loading: true })
        this.setState({ totalLength: 0 })


        var url = process.env.REACT_APP_GET_ALL_GIFT_CARD_ISSUED_BENEFICIARY_URL

        var request =
        {
            "fromDate": fromDate,
            "toDate": toDate,
            "roMdn": this.props.roMdn,
            "searchKeyword": searchField,
            "collectedTrueKeys": cTk,
            "size": this.state.postsPerPage,
            "page": page,
            "status": 5
        }




        var res = await apiCallService.postMethodCall(url, request);


        console.log("response getGiftCardOrderHistory", res, request, url)

        if (res !== undefined && res.status === 0 && res.response !== undefined && res.response != null
            && res.response.giftCardOrderDetail !== undefined && res.response.giftCardOrderDetail != null) {
            console.log('response inside fetch post', res);

            this.setState({ totalLength: res.response.totalGiftCardOrder })

            this.setState({ currentPost: res.response.giftCardOrderDetail })

            this.setState({ loading: false })
        }

        else {
            this.setState({ totalLength: 0 })
            this.setState({ currentPost: [] })
            this.setState({ loading: false })
        }

    }




    handleSearchChange = (event) => {
        this.setState({ searchField: event.target.value })
        this.setState({ currentPage: 1 });

        this.getAllBeneficiary(collectedTrueKeys, 1, event.target.value, this.state.fromDate, this.state.toDate);


    };


    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

        this.getAllBeneficiary(collectedTrueKeys, pageNumber, this.state.searchField, this.state.fromDate, this.state.toDate);


    };




    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");

            // this.getAllBeneficiary(collectedTrueKeys, 1, this.state.searchField, this.state.fromDate, this.state.toDate);

            this.getAllBeneficiary(collectedTrueKeys, 1, this.state.searchField, `${formatFromDate} 00:00:00`, `${formatToDate} 23:59:59`);


        }
    }



    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.listData })
        this.setState({ orderStatusValue: [] })
        this.setState({ deliveryStatusValue: [] })

        ToDateVar = '';
        FromDateVar = '';

        this.setState({ allValuesForFilter: [] })
        collectedTrueKeys.status = []
        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)

        this.getAllBeneficiary(collectedTrueKeys, 1, this.state.searchField, '', '');
    }





    render() {

        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;



        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };



        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");

        // =========================================
        var fileToExport = [];
        this.state.currentPost !== undefined &&
            this.state.currentPost !== null &&

            this.state.currentPost.map(exptdata => {


                var detailGoesInFile = {
                    "Beneficiary Name": exptdata.beneficiary_name,
                    "Mobile Number": exptdata.beneficiary_mdn,
                    "Total Gift Cards": exptdata.count,
                    "Total Issued Amount": parseFloat(exptdata.total_amount) / 100,


                }
                fileToExport.push(detailGoesInFile)


            })



        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?


                            <Can
                                role={this.props.userRole}
                                perform="subheader-manageGiftCard:visit"
                                roleData={this.props.roleData}
                                yes={() => (
                                    <>

                                        <div className="mid-container">
                                            <div className="container-fluid">


                                                <div className="head">
                                                    <div className="row">

                                                        <div className="col-6 col-md-4 order-md-1">
                                                            <h3>{process.env.REACT_APP_Gift_Cards_Issued}</h3>
                                                        </div>


                                                        <div className="col-6 col-md-4 order-md-3">
                                                            <div className="flterbox">
                                                                <div className="dropdown " data-toggle="tooltip" title="Filter">
                                                                    <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                                    <div className="dropdown-menu dropdown-menu-end">

                                                                        <div className="filters">
                                                                            <label htmlFor="select-state">From Date:</label>
                                                                            <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                        </div>

                                                                        <div className="filters">
                                                                            <label htmlFor="select-state">To Date:</label>
                                                                            <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                        </div>

                                                                        <div className="fbtn">
                                                                            <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-12 col-md-4 order-md-2">
                                                            <div className="searchdata">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" id="empsearch" name="search" placeholder="Search..." onChange={this.handleSearchChange} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>



                                                <div className="emp-list">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                {
                                                                    this.state.loading
                                                                        ?
                                                                        <Loader />
                                                                        :
                                                                        parseInt(this.state.totalLength) === 0
                                                                            ?
                                                                            <div className="col-12 ">
                                                                                <div className="no-record">
                                                                                    <i></i>
                                                                                    <h3> No Record Available.</h3>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            this.state.currentPost.map(data => (
                                                                                <div className="col-12 col-md-6 col-lg-4 col-xl-3">



                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: "/giftcards-beneficiary",
                                                                                            state: { beneMdn: data.beneficiary_mdn }
                                                                                        }}
                                                                                    >
                                                                                        {/* <a href="giftcards-beneficiary"> */}
                                                                                        <div className="giftcardata">
                                                                                            <div className="issuedto">
                                                                                                <Avatar src={data.imageurl} color={Avatar.getRandomColor(data.beneficiary_mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={data.beneficiary_mdn} name={`${CommonFunction.Capitalize(data.beneficiary_name)}`} size="48px" round={true} />

                                                                                                {/* <img className="avatar" data-name="Ramesh Swamy" alt="" /> */}
                                                                                                <h5>{data.beneficiary_name}</h5>
                                                                                                <p>+91-{data.beneficiary_mdn}</p>
                                                                                            </div>
                                                                                            <div className="gcardqty">
                                                                                                <p>{process.env.REACT_APP_Gift_Cards_Issued_Cards_Qty}</p>
                                                                                                <h6>{data.count}</h6>
                                                                                            </div>
                                                                                            <div className="gcardamt">
                                                                                                <p>{process.env.REACT_APP_Gift_Cards_Issued_Card_Amount}</p>
                                                                                                <h6>₹ {parseFloat(parseFloat(data.total_amount) / 100).toFixed(2)}</h6>
                                                                                            </div>

                                                                                            <div className="issuedate">{data.creationDate}</div>
                                                                                        </div>

                                                                                    </Link>
                                                                                </div>
                                                                            ))

                                                                }
                                                            </div>
                                                        </div>

                                                        {
                                                            !(parseInt(this.state.totalLength) > parseInt(this.state.postsPerPage))
                                                                ?


                                                                parseInt(this.state.totalLength) === 0
                                                                    ?
                                                                    null
                                                                    :
                                                                    <div className="col-12 col-md-6">
                                                                        <div className="entry">
                                                                            <p>         </p>
                                                                            <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"beneficiary-list.csv"}>Export to CSV</CsvDownload>


                                                                        </div>
                                                                    </div>
                                                                :

                                                                <>
                                                                    <div className="col-12 col-md-6">
                                                                        <div className="entry">
                                                                            <p>

                                                                                Showing {valueToShow} to {(parseInt(indexOfLastPost) > parseInt(this.state.totalLength)) ? this.state.totalLength : indexOfLastPost} of {this.state.totalLength} entries</p>

                                                                            <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"beneficiary-list.csv"}>Export to CSV</CsvDownload>


                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="pager">
                                                                            <Pagination
                                                                                activePage={parseInt(this.state.currentPage)}
                                                                                itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                                totalItemsCount={this.state.totalLength}
                                                                                pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                                onChange={this.paginate}
                                                                                className="pagination"
                                                                                linkClass="page-link"
                                                                                itemClass="page-item"
                                                                                nextPageText="Next"
                                                                                prevPageText="Prev"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </>

                                )}
                                no={() =>
                                    <Redirect to="/access-denied" />
                                }
                            />


                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser

});

export default connect(mapStateToProps)(IssuedGiftCard);