import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import '../css/employee.css';
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import Pagination from 'react-js-pagination';
import apiCallService from '../../service/api-call-service';
import CommonFunction from '../../common-function/GenerateRequestId';
import { useToasts } from 'react-toast-notifications';
import image1 from "../../assests/no-image.png";
import Can from '../../auth/can/Can';
import Avatar from 'react-avatar';
import LoadingComponent from './inactive-loader.component';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';



function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}


var listForAction = [];


// const { addToast } = useToasts()

class RemovedRecord extends React.Component {


    constructor() {
        super();

        this.state = {
            loading: true,
            listData: [],
            checkedPost: [],
            totalAmount: 0,
            currentPage: 1,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_REMOVED_EMPLOYEE,
            masterChecked: false,

            activateEmployeeProcessBar: false

        };
    }



    componentDidMount = () => {
        document.title = "ShakePe Employee List"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "active",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
        console.log(store)


        const getData = async () => {
            const url = process.env.REACT_APP_GET_INACTIVE_EMPLOYEE_LIST;
            console.log('Removed Employee url', `${url}`)

            var arr = [];
            var response = await apiCallService.getMethodCallWithoutParam(`${url}?ro_Id=${this.props.roMdn}`);

            console.log('Removed Employee url response', response);
            console.log('Removed Employee url response', response.response);
            if (response !== undefined && response.status !== undefined && response.status === 0 && response.response !== undefined && response.response.employeeDetails !== undefined) {
                console.log('Removed Employee url response employeeDetails', response.response.employeeDetails);

                response.response.employeeDetails.map(employeeDetail => {
                    var isChecked = 'isChecked';
                    employeeDetail[isChecked] = false;
                    arr.push(employeeDetail);
                })
            }
            this.setState({ listData: arr })
            this.setState({ loading: false })
        }

        getData();
    }



    handleMasterChecked = (event) => {

        this.setState({ masterChecked: event.target.checked })

        this.state.listData.map(posts => {
            console.log('inside if handleMasterChecked', posts);
            if (event.target.checked) {
                posts.isChecked = event.target.checked;
                listForAction.push(posts)
                var ref = posts.emp_id
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                // if (document.getElementById(ref)) { document.getElementById(ref).checked = true }
            } else if (!event.target.checked) {
                console.log('inside else handleMasterChecked');
                posts.isChecked = event.target.checked;
                var ref = posts.emp_id
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                // if (document.getElementById(ref)) { document.getElementById(ref).checked = false; }
                listForAction.pop(posts)
            }
        })
        this.setState({ checkedPost: this.state.listData })

    }




    handleChecked = async (event) => {
        var data = listForAction
        console.log(data.lenght, "and", this.state.postsPerPage)

        if (data.lenght !== this.state.postsPerPage) {
            this.setState({ checkedPost: false })
        }
        data.map(list => {
            listForAction.push(list);
        })
        this.state.listData.map(post => {
            if (post.emp_id === event.target.id && event.target.checked === true) {
                console.log('inside if handleChecked', post.emp_id);
                post.isChecked = event.target.checked
                var ref = post.emp_id
                // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                // document.getElementById(ref).checked = true;
                listForAction.push(post)
                // console.log('list to action performed correct', listForAction);
            } else if (post.emp_id === event.target.id && event.target.checked === false) {
                // console.log('removed post from action performed', post)
                listForAction.pop(post)
                this.setState({ masterChecked: false })
                post.isChecked = event.target.checked
                var ref = post.emp_id
                // console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                // document.getElementById(ref).checked = false;
                // console.log('list to action performed wrong', listForAction);
            }
        });
        console.log('list to action performed correct outside', this.state.listData);


        this.setState({ checkedPost: this.state.listData })
        console.log('list to action performed correct outside after', this.state.listData);


    }


    activateEmployee = async () => {

        console.log('ListToPrformAction', this.state.listData)

        var list = [];
        // this.state.checkedPost.map(pp => {
        //     list.push(pp)
        // })
        // var listDataFF = [];
        // this.state.listData.map(hh => {
        //     listDataFF.push(hh)
        // })
        console.log('list checkedpost', list)

        this.setState({ activateEmployeeProcessBar: true });

        this.state.listData.map(async employee => {

            if (employee.isChecked) {
                const RequestToSend = {
                    "request": {
                        "request_id": CommonFunction.geneRateRequestId(),
                        "employee": {
                            "corpId": this.props.roMdn,
                            "empId": employee.emp_id,
                            "mdn": employee.mdn,
                            "last_name": employee.last_name,
                            "email": employee.email,
                            "status": "0"
                        }
                    }
                }
                console.log(RequestToSend);
                const url = process.env.REACT_APP_UPDATE_INACTIVE_EMPLOYEE_TO_ACTIVE_URL;
                console.log('RequestToSend', RequestToSend);
                var response = await apiCallService.postMethodCall(url, RequestToSend);

                console.log('response', response);

                var lastName = ''
                var content = ''
                if (employee.last_name !== undefined) {
                    lastName = employee.last_name
                }
                if (response !== undefined && response.response !== undefined && response.response.response_code !== undefined && response.response.response_code === 0) {
                    console.log("Its working without error")
                    content = `${employee.first_name} ${lastName} Activated Successfully.`
                    this.props.addToast(content, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    console.log('employee removed is is checked', employee)
                    // if (employee.isChecked) {
                    // list.pop(employee);
                    // listDataFF.pop(employee);
                    // this.setState({ checkedPost: list })
                    // this.setState({ listData: listDataFF })
                    // // }

                    // this.setState({ masterChecked: false });
                    // window.location.reload(false);
                } else {

                    // this.setState({ checkedPost: list })
                    // this.setState({ listData: listDataFF })
                    content = `${employee.first_name} ${lastName} Failed To Activate.(${response.response_description !== undefined ? response.response_description : " "})`
                    this.props.addToast(content, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    employee.isChecked = false;
                    list.push(employee);
                }


            } else {

                list.push(employee)
            }


        })
        this.setState({ checkedPost: list });
        this.setState({ listData: list })
        this.setState({ activateEmployeeProcessBar: false })

    }

    setEmployeeDetail = (event) => {
        console.log('event.target.name', " : ", event.target.id)
        var arr = []
        this.state.listData.map(emp => {
            if (emp.mdn === event.target.id) {
                emp.isChecked = true;
                var ref = emp.emp_id
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                // if (document.getElementById(ref) !== null) { document.getElementById(ref).checked = true }
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', document.getElementById(ref).checked)
                arr.push(emp);
            } else {
                emp.isChecked = false;
                var ref = emp.emp_id
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                // if (document.getElementById(ref) !== null) { document.getElementById(ref).checked = false }
                arr.push(emp);
            }
        })

        this.setState({ listData: arr })
    }




    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber })
    };





    render() {
        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;

        // if (listData.length > 0) {
        //     currentPost = listData.slice(indexOfFirstPost, indexOfLastPost)
        // } else {
        //     currentPost = []
        // }

        if (this.state.checkedPost !== null && this.state.checkedPost.length > 0) {
            currentPost = null
            currentPost = this.state.checkedPost.slice(indexOfFirstPost, indexOfLastPost)
            console.log('Removed Employee url checkedPost', currentPost)
        } else {
            console.log('Removed Employee url listData', currentPost)
            currentPost = this.state.listData.slice(indexOfFirstPost, indexOfLastPost)
        }



        var heightAdjust = window.innerHeight - 230

        var viewButton = false;
        console.log('post is checked so showing button', viewButton, currentPost)
        currentPost.map(pos => {
            if (pos.isChecked) {
                viewButton = true
                console.log('post is checked so showing button', viewButton, currentPost)
            }
        })

        return (
            (this.props.authenticated)
                ?
                (
                    this.props.isPasswordReset ?
                        <div className="mid-container" >
                            <div className="container-fluid">


                                <div className="head">
                                    <div className="row">

                                        <div  className="col-6 col-md-4 order-md-1">
                                            <h3>



                                                <Can
                                                    role={this.props.userRole}
                                                    perform="removedEmp-bulkActivate:visit"
                                                    roleData={this.props.roleData}
                                                    yes={() => (

                                                        <input type="checkbox" id="all" data-toggle="tooltip" title="Select All" className="checkall" checked={this.state.masterChecked} onChange={this.handleMasterChecked} />

                                                    )}
                                                    no={() =>
                                                        null
                                                    }
                                                />



                                                Removed Employee
                                            </h3>

                                        </div>

                                        <div  className="col-6 col-md-4 order-md-3">

                                            <Can
                                                role={this.props.userRole}
                                                perform="removedEmp-bulkActivate:visit"
                                                roleData={this.props.roleData}
                                                yes={() => (
                                                    <div className="actnbtn">
                                                        {viewButton
                                                            ?
                                                            <button type="button" data-toggle="tooltip" title="Activate" data-bs-toggle="modal" data-bs-target="#activate" ><i  className="tick"></i>Activate</button>
                                                            // <button type="button" data-toggle="tooltip" title="Activate" data-bs-toggle="modal" data-bs-target="#activate"><i  className="tick"></i></button>

                                                            : null
                                                            // <button className="sbtn btngr">Activate</button>
                                                        }
                                                    </div>
                                                )}
                                                no={() =>
                                                    null
                                                }
                                            />

                                        </div>
                                        <div  className="col-12 col-md-4 order-md-2">
                                            <div  className="searchdata">
                                                <div  className="input-group">

                                                </div>
                                            </div>
                                        </div>








                                    </div>
                                </div>
                                <div className="emp-list" >
                                    <div className="row">




                                        {
                                            this.state.loading ?
                                                <LoadingComponent />
                                                :
                                                currentPost.length > 0 ?
                                                    currentPost.map(employee => (
                                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                            <div className="usercard">

                                                                <Can
                                                                    role={this.props.userRole}
                                                                    perform="removedEmp-singleCheckBox:visit"
                                                                    roleData={this.props.roleData}
                                                                    yes={() => (
                                                                        <div className="usercheck">

                                                                            <input type="checkbox" name="emplCheckbox" id={employee.emp_id} className="form-check-input" checked={employee.isChecked} onChange={this.handleChecked} />

                                                                        </div>
                                                                    )}
                                                                    no={() =>
                                                                        null
                                                                    }
                                                                />


                                                                <Link
                                                                    to={{
                                                                        pathname: '/inactive-profile',
                                                                        state: { employee }
                                                                    }}
                                                                    className="userbtn"
                                                                >

                                                                    <div className="userbasic">
                                                                        <Avatar src={employee.imageurl} color={Avatar.getRandomColor(employee.emp_id, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={employee.emp_id} name={`${CommonFunction.Capitalize(employee.first_name)} ${CommonFunction.Capitalize(employee.last_name)}`} size="48px" round={true} />

                                                                        <h5 className="text-truncate">{CommonFunction.Capitalize(employee.first_name)} {CommonFunction.Capitalize(employee.last_name)}</h5>
                                                                        <p>+91-{employee.mdn}</p>
                                                                    </div>

                                                                </Link>



                                                                <Can
                                                                    role={this.props.userRole}
                                                                    perform="removedEmp-singleActivate:visit"
                                                                    roleData={this.props.roleData}
                                                                    yes={() => (
                                                                        <div className="usrinact" id={employee.mdn}>
                                                                            <p>{employee.date_of_joining}</p>
                                                                            <button className="activate" data-bs-toggle="modal" id={employee.mdn} name={employee.emp_id} data-bs-target="#activate" onClick={this.setEmployeeDetail}></button>
                                                                        </div>
                                                                    )}
                                                                    no={() =>
                                                                        null
                                                                    }
                                                                />




                                                            </div>
                                                        </div>
                                                    ))



                                                    :
                                                    <div className="col-12">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3> No Record Available.</h3>
                                                        </div>
                                                    </div>



                                        }


                                        <div className="col-12">
                                            <div className="loading">

                                            </div>
                                        </div>
                                        {parseInt(this.state.listData.length) > parseInt(this.state.postsPerPage) ?
                                            <>
                                                <div className="col-12 col-sm-6">
                                                    <p>Showing {valueToShow} to {(indexOfLastPost > this.state.listData.length) ? this.state.listData.length : indexOfLastPost} of {this.state.listData.length} entries</p>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <div className="pager">
                                                        <Pagination
                                                            activePage={parseInt(this.state.currentPage)}
                                                            itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                            totalItemsCount={this.state.listData.length}
                                                            pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                            onChange={this.paginate}
                                                            className="pagination"
                                                            linkClass="page-link"
                                                            itemClass="page-item"
                                                            nextPageText="Next"
                                                            prevPageText="Prev"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className="modal fade" id="activate" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content alerttext">
                                        <div className="modal-header">
                                            <h5 id="">Activate Employee</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            Are you sure to activate this employee?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="mbtn btngr" data-bs-dismiss="modal">Close</button>
                                            <button type="button" className="mbtn btnbl" data-bs-dismiss="modal" onClick={this.activateEmployee} disabled={this.state.activateEmployeeProcessBar}>
                                                {this.state.activateEmployeeProcessBar
                                                    ?
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                    </div>
                                                    :
                                                    <>Activate</>
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <ResetLoginPassword />
                )
                :
                (
                    <Redirect to='/' />
                )
        )
    }
}



const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, mdn, roMdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset
});

export default withToast(connect(mapStateToProps)(RemovedRecord));