import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (


        <>

            <li><p className="logdate">  <Skeleton className="loading-basic-p" variant="text" animation="wave" /> </p>
                {/* <br/> */}
                <p>  <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
            </li>

            <li className="add"><p className="logdate">  <Skeleton className="loading-basic-p" variant="text" animation="wave" /> </p>
                {/* <br/> */}
                <p>  <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
            </li>
            <li className="delete"><p className="logdate">  <Skeleton className="loading-basic-p" variant="text" animation="wave" /> </p>
                {/* <br/> */}
                <p>  <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
            </li>

        </>
    );
}