import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (

        <>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="upload-card">
                    <button type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</button>

                    <div className="upload-type">
                        <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="up-date"> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></div>

                    {/* <div className="upload-btn">
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            </div> */}
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="upload-card">
                    <button type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</button>

                    <div className="upload-type">
                        <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="up-date"> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></div>

                    {/* <div className="upload-btn">
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            </div> */}
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="upload-card">
                    <button type="button" className="file-down mdl-js-button mdl-js-ripple-effect" data-toggle="tooltip" title="Download uploaded file">Download</button>

                    <div className="upload-type">
                        <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    </div>
                    <div className="up-date"> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></div>

                    {/* <div className="upload-btn">
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
            </div> */}
                </div>
            </div>

        </>
    );
}