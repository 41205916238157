import React from 'react';
//import ApiCalling from '../../service/api-call-service';
import axios, { post } from 'axios';

import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
//import {ProgressBar} from 'react-bootstrap';
import { sha256 } from 'js-sha256';

class UploadImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            hidden: 'hidden',
            image: '',
            url: '',
            uploadPercentage: '',
            size: '',
            loading: true,
            success: 'false',
            severty: 'success',
            dropDownHidden: false,
            message: '',
            inputKey: Date.now()
        };
    }

    componentDidMount = () => {
        localStorage.setItem('url', '');
    }


    generateHash(messageBody) {
        //var msg= JSON.stringify(messageBody) ;
        var privateKey = process.env.REACT_APP_CLIENT_ID;
        var hash = sha256.hmac.create(privateKey);
        hash.update(messageBody);
        var hexData = hash.hex();
        console.log('hexed data', hexData);
        var base64String = btoa(hexData);
        console.log(messageBody, '---->hash Generated --->', base64String);
        return base64String;
    }
    onChange = (event) => {
        this.setState({ files: [] });
        console.log('file event', event.target);
        console.log('file event', event.target.files[0]);
        this.setState({ size: event.target.files[0].size })

        if (event.target.files[0].type.includes('jpeg') || event.target.files[0].type.includes('jpg') || event.target.files[0].type.includes('png') || event.target.files[0].type.includes('gif')) {
            console.log('Inside ', event.target.files[0].type)

            if (event.target.files[0].size > parseInt(process.env.REACT_APP_PROFILE_PIC_SIZE_IN_KB) * 1000) {
                return (
                    this.setState({ hidden: '' }),
                    this.setState({ loading: false }),
                    this.setState({ success: false }),
                    this.setState({ uploadPercentage: 100 }),
                    this.setState({ dropDownHidden: true }),
                    this.setState({ severty: 'error' }),
                    this.setState({ message: 'File Size extended' })
                )
            }

            this.setState({ uploadPercentage: 1 })
            this.setState({ hidden: '' });
            this.setState({ files: event.target.files[0] });
            if (event.target.files && event.target.files[0]) {
                this.setState({
                    image: URL.createObjectURL(event.target.files[0])
                });
            }
            console.log('state file', this.state.files)
            const file = event.target.files[0];
            const url = process.env.REACT_APP_BULK_UPLOAD
            //const url = 'http://localhost:7072/api/upload';
            const formData = new FormData();
            formData.append('file', file)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const options2 = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total)
                    console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                    this.setState({ total: total / 100 });
                    this.setState({ loaded: loaded })

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent })
                    }
                }
            }
            var hashCodeForHeader = this.generateHash(JSON.stringify(formData));
            const headers = {
                "device_info": "",
                "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
                "hashCode": hashCodeForHeader,
                "loggedInUser": this.props.currentUser
            }
            const options = {
                method: "POST",
                body: formData,
                headers: headers
                // If you add this, upload won't work
                // headers: {
                //   'Content-Type': 'multipart/form-data',
                // }
            };

            console.log('url', url)
            fetch(url, options, options2).then(response => { return response.json(); })
                .then(responseData => {
                    console.log(responseData.fileDownloadUri);
                    this.setState({ url: responseData.fileDownloadUri });
                    this.setState({ uploadPercentage: 100 })
                    //     , ()=>{
                    //     setTimeout(() => {
                    //       this.setState({ uploadPercentage: 0 })
                    //     }, 1000);
                    //   })
                    var extrxctUrl = this.state.url.split('/')
                    console.log('extrxctUrl', extrxctUrl);
                    localStorage.setItem('url', `${process.env.REACT_APP_API_FILES_URL}/${extrxctUrl[5]}`);
                    this.setState({ loading: false })
                    this.setState({ success: true })
                    // this.setState({ dropDownHidden: true })
                    // this.setState({ severty: 'success' })
                    // this.setState({ message: `Profile Pic Upload Successfylly.` })
                    this.setState({ uploadPercentage: 100 })
                })
                .catch(error => {
                    console.log("error.data", error.data);
                    this.setState({ hidden: '' });
                    this.setState({ loading: false })
                    this.setState({ success: false })
                    this.setState({ uploadPercentage: 100 })
                    this.setState({ dropDownHidden: true });
                    this.setState({ severty: 'error' });
                    this.setState({ message: 'Incorrect file Type.' })
                })

        } else {

            this.setState({ hidden: '' });
            this.setState({ loading: false })
            this.setState({ success: false })
            this.setState({ uploadPercentage: 100 })
            this.setState({ dropDownHidden: true });
            this.setState({ severty: 'error' });
            this.setState({ message: 'Incorrect file Type.' })

        }
    }



    render() {
        return (

            <div className="file-upload">
                <label className="control-label">{this.props.header}</label>

                <div className="dropzone-wrapper">
                    <div className="dropzone-desc">
                        <i className="upload"></i>
                        <h6>{this.props.h6Value} <span> {this.props.h6SpanValue}</span> {this.props.h6ValueAfterbreak}</h6>
                        <p>{this.props.pValue}</p>
                    </div>
                    <input type="file" name="img_logo" className="dropzone" key={this.state.inputKey} onChange={this.onChange} />
                </div>

                <Snackbar

                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.dropDownHidden}
                    autoHideDuration={3000}
                    onClose={e => this.setState({ dropDownHidden: false })}
                >
                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                        {this.state.message}
                    </Alert>
                </Snackbar>

                {
                    // parseInt(this.state.uploadPercentage) === 100 ? null :
                    <div className={`preview-zone ${this.state.hidden}`}>
                        <div className="box">

                            {this.state.uploadPercentage > 0 && <LinearProgressWithLabel value={this.state.uploadPercentage} variant="determinate" />}

                            {
                                this.state.hidden === ''
                                    ?
                                    <div className="box-body">

                                        <img width="40" src={localStorage.getItem('url')} ></img><p style={{ "marginLeft": "1.5em" }}>File size {parseFloat(this.state.size / 1000).toFixed(2)}KB  </p>

                                    </div>
                                    :
                                    null
                            }

                            {(this.state.loading)
                                ?
                                <div className="spinner-border text-muted"></div>
                                :

                                (this.state.success)
                                    ?
                                    <button type="button" className="remove-preview" onClick={e => this.setState({ hidden: 'hidden', files: [], loading: true, inputKey: Date.now() })}>Success</button>
                                    :
                                    <button type="button" className="remove-preview" onClick={e => this.setState({ hidden: 'hidden', files: [], loading: true, inputKey: Date.now() })}>Remove</button>

                            }
                        </div>
                    </div>
                }


            </div>

        )
    }
}
export default UploadImage;