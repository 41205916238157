import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/create-new-band.styles.css'
import '../css/money-disbursal.styles.css'

import UploadMoneyDisbursalComponent from './upload-money-disbursal.component';
import Can from '../../auth/can/Can';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

const AddNewBand = ({ token, userRole, roleData, currentUser, authenticated, isPasswordReset, bandData, maxFoodAmount, maxGeneralAmount, editBandDetail, maxTravelAmount, roMdn, wallet }) => {


    const [bandName, setBandName] = useState('');
    const [generalAmount, setGeneralAmount] = useState(-1);
    const [travelAmount, setTravelAmount] = useState(-1);
    const [foodAmount, setFoodAmount] = useState(-1);

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');

    const [maxFood, setMaxFood] = useState(0);
    const [maxTravel, setMaxTravel] = useState(0);
    const [maxGeneral, setMaxGeneral] = useState(0);



    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));

        setMaxFood(parseFloat(maxFoodAmount) / 100);
        setMaxTravel(parseFloat(maxTravelAmount) / 100)
        setMaxGeneral(parseFloat(maxGeneralAmount) / 100);



        wallet !== undefined && wallet.map(oneData => {


            if (oneData.wallet_type_id === 1) {
                setGeneralAmount(0)
            }
            if (oneData.wallet_type_id === 2) {
                setFoodAmount(0)
            }
            if (oneData.wallet_type_id === 3) {
                setTravelAmount(0)
            }
        })



    }, []);


    const handleChange = (event) => {
        if (event.target.id === "bandName") {
            setBandName(event.target.value)
        }
        if (event.target.id === "generalAmount") {
            setGeneralAmount(event.target.value)
        }
        if (event.target.id === "travelAmount") {
            setTravelAmount(event.target.value)
        }
        if (event.target.id === "foodAmount") {
            setFoodAmount(event.target.value)
        }
    }


    const saveBand = async (event) => {
        event.preventDefault();


        console.log("bandName", bandName, "generalAmount", generalAmount, "travelAmount", travelAmount, "foodAmount", foodAmount)
        var bandArray = []
        var band = {
            "band": bandName,
            "roId": roMdn,
            "isActive": 0,
            "walletFoodLimit": foodAmount * 100,
            "generalLimit": generalAmount * 100,
            "travelLimit": travelAmount * 100,
            "bandUpdate": bandName,
            "type": "add",
            "updatedBy": currentUser,
            "createdBy": currentUser

        }
        bandArray.push(band);
        var request = {
            "request": bandArray
        }
        var url = process.env.REACT_APP_ADD_NEW_BAND

        var response = await apiCallService.postMethodCall(url, request);

        console.log("Add Band Response", url, request, response)

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setDropDownHidden(true);
            setSeverty("success");
            setMessage("Band Added SuccessFully");
            history.push('/band-list')
            history.go();
        } else {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
        }

    }



    var walletDetailToSet = [];

    console.log("Wallet Available", wallet)


    wallet !== undefined && wallet.map(oneData => {
        var max = "max";
        var label = "label";
        var nameId = "nameId"
        var value = "value"

        if (oneData.wallet_type_id === 1) {
            oneData[max] = maxGeneral
            oneData[label] = "General"
            oneData[nameId] = "generalAmount"
            oneData[value] = parseInt(generalAmount) === -1 ? 0 : generalAmount



            walletDetailToSet.push(oneData)
        }
        if (oneData.wallet_type_id === 2) {
            oneData[max] = maxFood
            oneData[label] = "Food"
            oneData[nameId] = "foodAmount"

            oneData[value] = parseInt(foodAmount) === -1 ? 0 : foodAmount
            walletDetailToSet.push(oneData)

        }
        if (oneData.wallet_type_id === 3) {
            oneData[max] = maxTravel
            oneData[label] = "Travel"
            oneData[nameId] = "travelAmount"
            oneData[value] = parseInt(travelAmount) === -1 ? 0 : travelAmount

            walletDetailToSet.push(oneData)
        }
    })





    return (
        < >
            {(authenticated)
                ? (
                    isPasswordReset
                        ?
                        <div className="mid-container">
                            <div className="container-fluid">





                                <div className="head">
                                    <div className="row">

                                        <div  className="col-10">
                                            <h3>Create New Band & Load Limits</h3>
                                        </div>
                                        <div  className="col-2">
                                            <div  className="actnbtn">
                                                <a href="band-list" className="back" data-toggle="tooltip" title="Back"><i className="back"></i></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="emp-list">

                                    <form className="bandcard" onSubmit={saveBand}>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="webform">
                                                    <div className="form-floating">
                                                        <input className="form-control" type="text" name="bandName" id="bandName" placeholder="Band" value={bandName} onChange={handleChange} required />


                                                        <label for="bandName">Band Name</label>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-12">
                                                {/* <h3>Disbursal Limits</h3> */}
                                            </div>

                                            <Snackbar

                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                open={dropDownHidden}
                                                autoHideDuration={3000}
                                                onClose={e => setDropDownHidden(false)}
                                            >
                                                <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                                    {message}
                                                </Alert>
                                            </Snackbar>



                                            {
                                                walletDetailToSet.map(detail => (
                                                    <div className="col-12 col-md-4">
                                                        <div className="webform">
                                                            <div className="form-floating">
                                                                <input className="form-control" type="number" step="0.01" placeholder="Limit" name={detail.nameId} min={0} max={detail.max} id={detail.nameId} value={detail.value} onChange={handleChange} />
                                                                <label for={detail.nameId}>{detail.label}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }







                                            <div className="col-12 mt-3">
                                                <a href="band-list" className="lbtn btngr" > Cancel</a>
                                                <input type="submit" value="Save" className="lbtn btnbl" />

                                            </div>

                                        </div>
                                    </form>

                                </div>




                            </div>
                        </div>
                        :
                        <ResetLoginPassword />


                )
                : (
                    <Redirect to='/' />
                )
            }

        </>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount, userRole,
    maxFoodAmount, currentUser,
    maxTravelAmount }, role: { roleData }, token: { authenticated, token, isPasswordReset }, band: { bandData }, seteditLimt: { editBandDetail } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount, currentUser,
        maxFoodAmount, editBandDetail,
        maxTravelAmount, token, userRole, roleData, isPasswordReset
    });

export default connect(mapStateToProps)(AddNewBand);
