import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import image1 from "../../assests/no-image.png";
import CommonFunction from '../../common-function/GenerateRequestId';
import MultiCardStatus from '../../common/component/multiCardStatus.component';
import { setEmployeeDetails } from '../../redux/user/user.actions';
import { setBackUrl } from '../../redux/user/user.actions';


const SubBandLimitComponent = ({ token, employee, location, authenticated, bandData, maxFoodAmount, maxGeneralAmount, editBandDetail, maxTravelAmount, roMdn, wallet }) => {


    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));



    }, []);

    const setEmployee = () => {
        store.dispatch(setEmployeeDetails(employee));
        store.dispatch(setBackUrl('/band-details'))
    }

    console.log('employee to test like how it looks', employee)

    return (
        < >
            {(authenticated)
                ? (
                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <div className="usercard">
                            <div  className="usercheck">
                                {/* <input type="checkbox"  className="form-check-input" id="" /> */}
                            </div>

                            <Link
                                to={{
                                    pathname: '/emp-detail',
                                    state: { employee, editShowHidden: true }
                                }}
                                className="userbtn"
                                onClick={setEmployee}

                            >
                                <div className="userbasic">
                                    <img src={employee.imageUrl === undefined || employee.imageUrl === '' ? image1 : employee.imageUrl} alt="" />
                                    <h5  className="text-truncate">{employee.first_name} {employee.last_name === undefined ? '' : employee.last_name}</h5>
                                    <p>+91-{employee.mdn}</p>
                                </div>

                                <div className="cardsts">

                                    <MultiCardStatus card={employee.card} />

                                    <p>{CommonFunction.Capitalize(employee.roleName)}</p>
                                </div>

                                <div className="bandamt">
                                    {employee.general === '' || employee.general === 0 ?
                                        null
                                        :
                                        <div className="waltamt">
                                            <p>General</p>
                                            <h6> {employee.general === '' ? <>₹ 0</> : <> ₹ {parseFloat(parseFloat(employee.general) / 100).toFixed(2)} </>}</h6>
                                        </div>}
                                    {employee.food === '' || employee.food === 0 ?
                                        null
                                        :
                                        <div className="waltamt">
                                            <p>Food</p>
                                            <h6>{employee.food === '' ? <> ₹ 0</> : <> ₹ {parseFloat(parseFloat(employee.food) / 100).toFixed(2)} </>}</h6>
                                        </div>}
                                    {employee.travel === '' || employee.travel === 0 ?
                                        null
                                        :
                                        <div className="waltamt">
                                            <p>Travel</p>
                                            <h6>{employee.travel === '' ? <> ₹ 0</> : <> ₹ {parseFloat(parseFloat(employee.travel) / 100).toFixed(2)} </>}</h6>
                                        </div>}
                                </div>

                            </Link>


                        </div>
                    </div>

                )
                : (
                    <Redirect to='/' />
                )
            }

        </>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount,
    maxFoodAmount,
    maxTravelAmount }, token: { authenticated, token }, band: { bandData }, seteditLimt: { editBandDetail } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, editBandDetail,
        maxTravelAmount, token
    });

export default connect(mapStateToProps)(SubBandLimitComponent);




