import OrderTypes from './order.types';


export const setOrderResponse = (quan) => ({
    type: OrderTypes.SET_ORDER_RESPONSE,
    payload: quan
});


export const resetOder = {
    type: OrderTypes.RESET_ORDER
}