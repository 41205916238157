 import KycType from './kyc.types';

export const setKyc = data =>({
    type: KycType.SET_KYC,
    payload: data
})

export const setKycListToView = data =>({
    type: KycType.SET_KYC_LIST_TO_VIEW,
    payload: data
})

export const setMasterRole = data =>({
  type: KycType.SET_ROLE_MASTER,
  payload: data
})

export const setMasterRoleToView = data =>({
  type: KycType.SET_ROLE_LIST_TO_VIEW,
  payload: data
})

export const setCardStatusAllowe = data =>({
    type: KycType.SET_ALLOWED_CARD_STATUS,
    payload: data
  })
  
  export const setCardStatusListToView = data =>({
    type: KycType.SET_CARD_STATUS_LIST_TO_VIEW,
    payload: data
  })