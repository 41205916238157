import { UserActionTypes } from './user.types';

export const setCurrentUser = user => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user
});

export const setUserRole = role => ({
  type: UserActionTypes.SET_USER_ROLE,
  payload: role
})

export const setRoMdn = mdn => ({
  type: UserActionTypes.SET_RO_MDN,
  payload: mdn
})



export const setCorporateWallet = data => ({
  type: UserActionTypes.SET_CORPORATE_WALLET,
  payload: data
})


export const setCorporateName = data => ({
  type: UserActionTypes.SET_RO_NAME,
  payload: data
})


export const setCorpBankDetail = data => ({
  type: UserActionTypes.SET_CORP_BANK_DETAIL,
  payload: data
})


export const setUserFullName = data => ({
  type: UserActionTypes.SET_FULL_NAME,
  payload: data
})

export const setLoginMdn = data => ({
  type: UserActionTypes.SET_MDN,
  payload: data
})

export const setMaxGeneralAmount = data => ({
  type: UserActionTypes.SET_MAX_GENERAL_AMOUNT,
  payload: data
})
export const setMaxTravelAmount = data => ({
  type: UserActionTypes.SET_MAX_TRAVEL_AMOUNT,
  payload: data
})
export const setMaxFoodAmount = data => ({
  type: UserActionTypes.SET_MAX_FOOD_AMOUNT,
  payload: data
})


export const setCorpBandLimit = data => ({
  type: UserActionTypes.SET_CORP_BAND_LIMITS,
  payload: data
})


export const setEmployeeDetails = data => ({
  type: UserActionTypes.SET_EMPLOYEE_DETAIL,
  payload: data
})

export const setBankId = data => ({
  type: UserActionTypes.SET_BANKID,
  payload: data
})


export const setBackUrl = data => ({
  type: UserActionTypes.SET_BACK_URL,
  payload: data
})

export const setBankBackButton = data => ({
  type: UserActionTypes.SET_BANK_BACK_BUTTON,
  payload: data
})


export const setBankAddAndUpdateUrl = data => ({
  type: UserActionTypes.SET_BANK_ADD_UPDATE_URL,
  payload: data
})

export const setButtonName = data => ({
  type: UserActionTypes.SET_BUTTON_NAME,
  payload: data
})


export const setSessionExpire = data => ({
  type: UserActionTypes.SET_SESSION_EXPIRE,
  payload: data
})


export const resetUser = {
  type: UserActionTypes.RESET_USER
}
