import React from "react";
import "../css/employee.css";
import "../css/dropdown.styles.css";
import Can from "../../auth/can/Can";
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import CardStatus from "../../constants/card-status.type";
import history from "../../auth/history";
import { store } from "../../redux/store";
import { setReissueMdn } from "../../redux/card/card.action";



class DropDown extends React.Component {

  constructor(props) {
    super(props);

    this.state = {


    };
  }


  componentDidMount = () => {



  }


  handleGoToReassign = () => {


    store.dispatch(setReissueMdn(this.props.employee.mdn))

    history.push({
      pathname: '/manage-card?to=reIssue',
      state: { mdn: this.props.employee.mdn },
    })
    history.go();

  }


  render() {



    var isTemBlock = false;
    var isPermBlock = false;
    var isUnlockCard = false;
    var isReissueCard = false;


    this.props.employee.card !== null && this.props.employee.card.map(cd => {
      if (parseInt(cd.cardStatus) === CardStatus.PERMANENT_BLOCK) {
        isReissueCard = true;
      }

      if (((parseInt(cd.cardStatus) === CardStatus.ACTIVE) || (parseInt(cd.cardStatus) === CardStatus.TEMPORARY_BLOCK)) && (cd.cardType !== 0)) {
        isPermBlock = true;
      }
      // (cd.cardType !== 0) && added here
      if (
        // (cd.cardType !== 0) && 

        parseInt(cd.cardStatus) === CardStatus.TEMPORARY_BLOCK) {
        isUnlockCard = true;
      }
      // (cd.cardType !== 0) && added here
      if (
        // (cd.cardType !== 0) && 
        (parseInt(cd.cardStatus) === CardStatus.ACTIVE)) {
        isTemBlock = true;
      }

    })



    console.log("Employee ka mdn sayad undefired hai isi vjha se nhi aa rha hai", this.props.employee.mdn)


    return (
      < >
        {(this.props.authenticated)
          ? (

            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby={this.props.id}>
              {isTemBlock && (
                <Can
                  role={this.props.userRole}
                  perform="empmng-tBlock:visit"
                  roleData={this.props.roleData}
                  yes={() => (


                    <li><a data-bs-toggle="modal" data-bs-target={this.props.targettempPopupId}><i className="tblock"></i> {process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_ButtonText}</a></li>

                  )}
                  no={() => null}
                />
              )}
              {isUnlockCard && (
                <Can
                  role={this.props.userRole}
                  perform="empmng-tBlock:visit"
                  roleData={this.props.roleData}
                  yes={() => (



                    <li><a data-bs-toggle="modal" data-bs-target={this.props.targetUnBlockPopupId}><i  className="unblock"></i>{process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_ButtonText}</a></li>


                  )}
                  no={() => null}
                />
              )}

              {isPermBlock && (
                <Can
                  role={this.props.userRole}
                  perform="empmng-pBlock:visit"
                  roleData={this.props.roleData}
                  yes={() => (



                    <li><a data-bs-toggle="modal" data-bs-target={this.props.targetpermPopupId}><i  className="pblock"></i>{process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_ButtonText}</a></li>

                  )}
                  no={() => null}
                />
              )}



              <Can
                role={this.props.userRole}
                perform="empmng-edit:visit"
                roleData={this.props.roleData}
                yes={() => (
                  <li>

                    <Link

                      to={{
                        pathname: '/emp-detail',
                        state: { employee: this.props.employee, editShowHidden: false },
                      }}
                      onClick={this.props.setEmployee}
                    >
                      <i className="edit"></i>
                      {process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Edit_ButtonText}
                    </Link>

                  </li>
                )}
                no={() => null}
              />


              <Can
                role={this.props.userRole}
                perform="empmng-remove:visit"
                roleData={this.props.roleData}
                yes={() => (
                  <li>
                    <a data-bs-toggle="modal" data-bs-target={this.props.targetRemovePopUpId}>
                      <i className="delete"></i>
                      {process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_remove_ButtonText}
                    </a>
                  </li>
                )}
                no={() => null}
              />
              <Can
                role={this.props.userRole}
                perform="empmng-remove:visit"
                roleData={this.props.roleData}
                yes={() => (

                  isReissueCard
                    ?

                    <li>
                      <a

                        onClick={this.handleGoToReassign}
                      >


                        <i className="reissue"></i>
                        {process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_ReissueCar_text}

                      </a>
                    </li>
                    :
                    null
                )}
                no={() => null}
              />
            </ul>


          )
          : (
            <Redirect to='/' />
          )


        }

      </>

    )
  }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
  authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(DropDown);



