import React, { useState, useEffect } from 'react';
import logo from '../../assests/logo.png';

import ApiCallingService from '../../service/api-call-service';
import CommonFunction from '../../common-function/GenerateRequestId';
import { connect } from "react-redux";
import { store } from '../../redux/store';
import { setRoMdn, setUserFullName, setLoginMdn, setEmployeeDetails, resetUser } from '../../redux/user/user.actions'
import Skeleton from '@material-ui/lab/Skeleton';
import apiCallService from '../../service/api-call-service';
import { setAvailableCard } from '../../redux/card/card.action';
import Can from '../../auth/can/Can';
import { Link } from 'react-router-dom';
import history from '../../auth/history';
import { resetRole, setCurrentUserRoleName } from '../../redux/role/role.action';
import '../css/header.styles.css'
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import NotificationType from '../../constants/notification.type';
import { resetToken, setUnseenCount } from '../../redux/token/token.action';
import Avatar from 'react-avatar';
import { setAdminViewType } from '../../redux/adminView/adminView.action';

const Header = ({ currentUser, userRole, roleData, countUnseen, roMdn, mdn }) => {

	const resultSet = {
		emp_id: '', mdn: '', first_name: '', last_name: '', gender: '', email: '', pan: '',
		band: '', department: '', location: '', lastLogin: '', pincode: '', dob: '', date_of_joining: '', card_ref_no: '', card_staus: '',
		is_paper_kyc: '', roMdn: '', type: '', roleName: '', modifyBy: '', wallet: []
	}
	const [employeeDetail, setEmployeeDetail] = useState(resultSet);
	const [loading, setLoading] = useState(false);
	const [notificationList, setNotificationList] = useState([]);
	// const [unseenCount, setUnseenCount] = useState(0);
	const [onPoupCount, setOnPopUpCount] = useState(3);
	const [listSize, setListSize] = useState(0);


	const [searchDetail, setSearchDetail] = useState([]);
	const [searchFeild, setSearchFeild] = useState('');
	const [searchBlock, setSearchBlock] = useState(false);


	useEffect(() => {
		const fetchEmployeeDetail = async () => {
			setLoading(true);
			store.dispatch(setAvailableCard([]))
			const url = process.env.REACT_APP_GET_EMPLOYEE_BY_EMAIL
			const res = await ApiCallingService.getMethodCallWithParam(url, { 'email': currentUser });
			const detail = res.response;
			// console.log('response data ', res);
			if (res.response_message === 'success') {
				console.log("fetchEmployeeDetail", res.response.employeeDetails);
				const detail = res.response.employeeDetails;
				// console.log('detail.roMdn', detail.roMdn)
				setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...detail }));
				setLoading(false);
				fetchNotification(res.response.employeeDetails);
				if (detail.first_name !== undefined) {
					var fname = detail.first_name
					var lname = '';
					if (detail.last_name !== undefined) {
						lname = detail.last_name
					}
					var fullName = `${fname} ${lname}`
				}

				store.dispatch(setRoMdn(detail.roMdn));
				store.dispatch(setLoginMdn(detail.mdn));
				store.dispatch(setUserFullName(fullName));
				store.dispatch(setCurrentUserRoleName(detail.roleName))



			}
			else {
				setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...null }));

			}
		}


		fetchEmployeeDetail();

		getSearchDetail();

	}, [])


	const getSearchDetail = async () => {
		const url = process.env.REACT_APP_GET_GLOBAL_SEARCH_DETAIL

		var response = await apiCallService.getMethodCallWithoutParam(url);

		if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
			setSearchDetail(response.response.globalNotifications);
		}

	}



	const fetchNotification = async (detail) => {
		// REACT_APP_GET_NOTIFICATION_ALLOWED=http://3.6.104.48:7072/getNotification
		const url = process.env.REACT_APP_GET_NOTIFICATION_ALLOWED;

		var request = {
			"roId": detail.roMdn
		}

		var response = await apiCallService.postMethodCall(url, request);

		// console.log('Notification Response', url, response, request);
		if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

			// console.log('Notification response.response.noOfUnseen', response.response.noOfUnseen)

			function isJson(str) {
				try {
					JSON.parse(str);
				} catch (e) {
					return false;
				}
				return true;
			}

			var arrayOfFive = [];
			var i = 0;
			var arrSize = 0;
			var unseenInc = 0;
			response.response.details.map(detail => {
				// console.log('Notification response.response.details', detail)



				if (roleData.includes(detail.action)) {
					if (i < onPoupCount) {
						// console.log('Notification response.response.details', i)
						if (isJson(detail.condition)) {
							const state = "state"
							detail[state] = JSON.parse(detail.condition);

						}
						arrayOfFive.push(detail);
						i++;
					}

					if (detail.status === NotificationType.Unseen) {
						unseenInc++;
					}
					arrSize++;

				}

			})

			// console.log('arrayOfFive', arrayOfFive)

			setNotificationList(arrayOfFive);
			// setUnseenCount(parseInt(unseenInc));
			store.dispatch(setUnseenCount(unseenInc))
			setListSize(parseInt(arrSize))
		}

	}


	// const updateNotificationStatus = async () => {

	// 	// console.log('Triggered updateNotificationStatus Notification')
	// 	const url = process.env.REACT_APP_UPDATE_NOTIFICATION_STATUS;
	// 	var arrId = [];

	// 	notificationList.map(subNoti => {

	// 		// console.log(' Notification subNoti', subNoti)

	// 		arrId.push(subNoti.id)

	// 	})


	// 	var request = {
	// 		"id": arrId,
	// 		"status": NotificationType.Unseen
	// 	}

	// 	var response = await apiCallService.postMethodCall(url, request);
	// 	// console.log("Notification update status ", url, request, notificationList, response);
	// 	if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
	// 		fetchNotification(employeeDetail)
	// 	}
	// }


	const updateNotificationStatusAsSeen = async () => {

		// console.log('Triggered updateNotificationStatus Notification')
		const url = process.env.REACT_APP_UPDATE_NOTIFICATION_STATUS;
		var arrId = [];


		notificationList.map(subNoti => {
			// console.log(' Notification subNoti', subNoti)
			arrId.push(subNoti.id)

		})


		var request = {
			"id": arrId,
			"status": NotificationType.Seen
		}

		var response = await apiCallService.postMethodCall(url, request);
		// console.log("Notification update status ", url, request, notificationList, response);
		if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
			fetchNotification(employeeDetail)
		}
	}


	const updateNotificationStatusAsRead = async (id, urlTo, condition) => {

		// console.log('Triggered updateNotificationStatus Notification Read')
		const url = process.env.REACT_APP_UPDATE_NOTIFICATION_STATUS;
		var arrId = [];
		var i = 0;

		arrId.push(id);
		var request = {
			"id": arrId,
			"status": NotificationType.Read
		}

		var response = await apiCallService.postMethodCall(url, request);
		// console.log("Notification update status Read", url, request, notificationList, response);
		if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
			fetchNotification(employeeDetail)

			history.push({
				pathname: urlTo,
				state: condition
			})
			history.go()
		}
	}


	const handleAuthentication = () => {


		var url = process.env.REACT_APP_USER_LOGOUT;

		var request = {
			"mdn": mdn,
			"roId": roMdn

		}

		console.log("Lout Request Shi Hai", request);

		var fun = async () => {
			var response = await apiCallService.LogoutPostMethodCall(url, request)

			if (response !== undefined && response.response_code === 0) {
				store.dispatch(setAdminViewType(false));
				store.dispatch(resetUser)
				store.dispatch(resetRole);
				store.dispatch(resetToken)

				// console.log(store);

			} else {
				store.dispatch(setAdminViewType(false));
				store.dispatch(resetUser)
				store.dispatch(resetRole);
				store.dispatch(resetToken)
				// var content = `Somthing Went Wrong Please Try In A Bit. [ ${response.response_description} ]`;
				// addToast(content, {
				//     appearance: 'warning',
				//     autoDismiss: true,
				//     autoDismissTimeout: 7000
				// })
			}

		}
		fun();


	}



	const setEmployee = () => {
		store.dispatch(setEmployeeDetails(employeeDetail));
		history.push({
			pathname: '/emp-detail',
			state: { employee, editShowHidden: true }
		})
		history.go();
	}


	const searchChange = (event) => {
		setSearchFeild(event.target.value);
		setSearchBlock(true);
	}


	var employee = employeeDetail;


	var searchedRecord = [];


	// var searchedBy = ""

	if (searchFeild !== '' && searchDetail !== undefined) {
		searchDetail.map(post => {
			if (post !== undefined && post !== null && JSON.stringify(post).toLowerCase().includes(searchFeild.toLowerCase())) {
				searchedRecord.push(post)
				// searchedBy = "fullName"
			}
		})
	}

	// console.log('searchDetail', searchDetail)


	return (
		<header className="top">
			<div className="container-fluid">
				<div className="row">
					<div className="col-3 col-sm-4 col-md-2 col-xl-4">
						<div className="logo"><img src={logo} alt="logo" className="img-fluid" /></div>
					</div>

					<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3 d-none d-sm-block">
						<div className="search">
							
						</div>
					</div>

					<div className="col-9 col-sm-4 col-md-6 col-xl-5">


						<div className="notification dropdown d-none d-md-block">
							{countUnseen === 0
								?

								<button className="notify dropdown-toggle" type="button" id="usernotify" data-bs-toggle="dropdown" aria-expanded="false" data-badge={countUnseen} onClick={updateNotificationStatusAsSeen}></button>

								:


								<button className="notify dropdown-toggle" type="button" id="usernotify" data-bs-toggle="dropdown" aria-expanded="false" data-badge={countUnseen} onClick={updateNotificationStatusAsSeen}></button>

							}
							<div className="dropdown-menu" aria-labelledby="usernotify">



								{
									notificationList.map(subNoti => (

										<Can
											role={userRole}
											perform={subNoti.action}
											roleData={roleData}
											yes={() => (

												<div className="noticelist" style={{ backgroundColor: subNoti.colorClass }}>

													<Link
														className='ntcbtn'
														to={{
															pathname: subNoti.redirectUrl,
															state: subNoti.condition
														}}
														onClick={e => updateNotificationStatusAsRead(subNoti.id, subNoti.redirectUrl, subNoti.state)}
													>
														<h6>  {subNoti.beforeLink}
															<span>	{subNoti.linkVariable}</span>  {subNoti.afterLink}
														</h6>
														<p>{subNoti.createdDate}</p>
													</Link>

												</div>

											)}
											no={() =>
												null
											}
										/>

									))
								}

								{listSize > onPoupCount
									?
									<div className="noticelink"><a href="/notification">{process.env.REACT_APP_Var_FOR_DASHBOARD_Notification_View_all_notification}</a></div>
									: null
								}
							</div>
						</div>

						{
							employeeDetail.lastLogin === undefined || employeeDetail.lastLogin === null || employeeDetail.lastLogin === ""
								?
								null :
								<div className="last-login d-none d-md-block">
									<i></i>
									<p>
										{process.env.REACT_APP_Var_FOR_Header_Last_Login}
										<br />
										<span>
											{
												loading
													?
													<Skeleton animation="wave" color="#fff" highlightColor="#999" variant="text" width={100} />
													:
													employeeDetail.lastLogin
											}
										</span>


									</p>
								</div>
						}

						<div className="user-info">
							<p>
								{
									loading
										?
										<Skeleton animation="wave" color="#202020" highlightColor="#444" variant="text" width={100} />
										:
										<>
											{process.env.REACT_APP_Var_FOR_Header_Welcome} {CommonFunction.Capitalize(employeeDetail.first_name)}
										</>
								}
								<br />
								{
									loading
										?
										<Skeleton animation="wave" color="#202020" highlightColor="#444" variant="text" width={100} />
										:
										<span>
											{employeeDetail.roleName}
										</span>
								}
							</p>


							<button className="userbtn dropdown-toggle" type="button" id="usermenu" data-bs-toggle="dropdown" aria-expanded="false">
								{/* <img src={user1} alt="user name" /> */}
								<Avatar src={employeeDetail.imageUrl} color={Avatar.getRandomColor(employeeDetail.emp_id, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={employeeDetail.emp_id} name={`${CommonFunction.Capitalize(employeeDetail.first_name)} ${CommonFunction.Capitalize(employeeDetail.last_name)}`} size="32px" round={true} />


							</button>

							<ul className="dropdown-menu" aria-labelledby="usermenu">



								<li>
									<a

										onClick={setEmployee}
									>
										<i className="user"></i>{process.env.REACT_APP_Var_FOR_Header_User_Toggle_Profile}
									</a>
								</li>




								<Can
									role={userRole}
									perform="header-cropProfile:visit"
									roleData={roleData}
									yes={() => (
										<li><a href="/corporate-profile"><i className="corp"></i>{process.env.REACT_APP_Var_FOR_HEADER_DROPDOWN_Corporate_Profile}</a></li>

									)}
									no={() =>
										null
									}
								/>


								<Can
									role={userRole}
									perform="header-rolesPermissions:visit"
									roleData={roleData}
									yes={() => (
										<li><a href="/roles-permissions"><i className="role"></i>{process.env.REACT_APP_Var_FOR_HEADER_DROPDOWN_ROLES_PERMISSION}</a></li>

									)}
									no={() =>
										null
									}
								/>


								<Can
									role={userRole}
									perform="header-adminRolesPermissions:visit"
									roleData={roleData}
									yes={() => (
										<li><a href="/superAdmin-roles-permissions"><i className="role"></i>{process.env.REACT_APP_Var_FOR_HEADER_DROPDOWN_SUPER_ADMIN_ROLES_PERMISSION}</a></li>

									)}
									no={() =>
										null
									}
								/>

								<Can
									role={userRole}
									perform="header-loadMoney:visit"
									roleData={roleData}
									yes={() => (
										<li><a href="/load-money"><i className="rupee"></i>{process.env.REACT_APP_Var_FOR_Header_User_Toggle_Load_Money} </a></li>

									)}
									no={() =>
										null
									}
								/>




								<li><a style={{ "cursor": "pointer" }} onClick={handleAuthentication}><i className="logout"></i>{process.env.REACT_APP_Var_FOR_Header_User_Toggle_Logout}</a></li>


							</ul>
						</div>
					</div>

				</div>
			</div>
		</header>


	)

}

const mapStateToProps = ({ role: { roleData }, user: { currentUser, userRole, roMdn, mdn }, token: { countUnseen } }) => ({
	currentUser, userRole, roleData, countUnseen, roMdn, mdn
});


export default connect(mapStateToProps)(Header);
