import MessageType from './message.types';

 
export const setMessageToBeShown = (message) => ({
    type: MessageType.MESSAGE_TO_BE_SHOWN,
    payload: message
});

export const setSeverty = (message) => ({
    type: MessageType.SET_SEVERTY,
    payload: message
});