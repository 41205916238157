import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import apiCallService from '../../../service/api-call-service';
import CsvDownload from 'react-json-to-csv';
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import Pagination from "react-js-pagination";
import Avatar from 'react-avatar';
import moment from 'moment';
import Select from "react-select";

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import history from '../../../auth/history';
import Skeleton from '@material-ui/lab/Skeleton';

class AddCorpBank extends React.Component {



    render() {



        return (
            < >
                <div className="reverscard">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 col-xl-4">
                            <div className="corprevers">
                                <Skeleton className="loading-basic-img" variant="circle" />
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 col-xl-2">
                            <div className="reverstype">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>
                        <div className="col-4 col-md-3 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>
                        <div className="col-8 col-md-3 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>

                        <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                            <div className="reversbtn">
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="reverscard">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 col-xl-4">
                            <div className="corprevers">
                                <Skeleton className="loading-basic-img" variant="circle" />
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 col-xl-2">
                            <div className="reverstype">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>
                        <div className="col-4 col-md-3 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>
                        <div className="col-8 col-md-3 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>

                        <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                            <div className="reversbtn">
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reverscard">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 col-xl-4">
                            <div className="corprevers">
                                <Skeleton className="loading-basic-img" variant="circle" />
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 col-xl-2">
                            <div className="reverstype">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>
                        <div className="col-4 col-md-3 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>
                        <div className="col-8 col-md-3 col-lg-2 col-xl">
                            <div className="reversamount">
                                <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                                <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            </div>
                        </div>

                        <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                            <div className="reversbtn">
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                                <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                            </div>
                        </div>
                    </div>
                </div>

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName, corpBankDetail, bankBackButton, bankUrl, mobileNum, buttonName }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, token, currentUser, fullName, isPasswordReset, corpBankDetail, bankBackButton, bankUrl, mobileNum, buttonName

});




export default connect(mapStateToProps)(AddCorpBank);