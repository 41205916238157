import React, { useEffect } from "react";
import '../styles/zero.login.styles.scss';

import BenefitsComponent from '../zero-login-pages/zeroBenefit.pages';
import LoginComponent from '../zero-login-pages/zeroLogin.form.pages';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ZeroHome = ({ authenticated }) => {

    useEffect(() => {
        document.title = "ShakePe Login";
    }, [])


    return (
        console.log('value of authenticated', { authenticated }),

        <div className='log-container'>

            {
                (authenticated)
                    ? (

                        <Redirect to="/dashboard" />

                    )
                    : (



                        <div className='container'>

                            <div className='row justify-content-sm-center'>

                                <LoginComponent />
                                {/* <BenefitsComponent /> */}



                            </div>
                        </div>
                    )
            }

            
        </div>

    )
}

const mapStateToProps = ({ token: { authenticated } }) => ({
    authenticated
});

export default connect(mapStateToProps)(ZeroHome);