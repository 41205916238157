import { TokenActionTypes } from './token.types';

const INITIAL_STATE = {
  token: null,
  authenticated: false,
  countUnseen: 0,
  isPasswordReset:true
};

const tokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TokenActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case TokenActionTypes.IS_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload
      };

    case TokenActionTypes.SET_USEEN_COUNT:
      return {
        ...state,
        countUnseen: action.payload
      };
      case TokenActionTypes.SET_IS_PASSWORD_RESET:
        return {
          ...state,
          isPasswordReset: action.payload
        };

    case TokenActionTypes.RESET_TOKEN:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default tokenReducer;