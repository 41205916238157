import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import dropDownReducer from './dropdown/dropdown.reducer';
import messageReducer from './message/message.reducer';
import checkboxReducer from './checkbox/checkbox.reducer';
import tokenReducer from './token/token.reducer';
import roleReducer from './role/role.reducer';
import bandReducer from "./band/band.reducer";
import listForActionResucer from './listForAction/listForAction.reducer'
import locationReducer from './location/location.reducer'
import kycReducer from "./kyc/kyc.reducer";
import activeHeaderReducer from './activeHeader/activeheader.reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rememberMeReducer from './rememberMe/rememberMe.reducer';
import showAndHideReducer from './div-show-and-hide/div-show-and-hide.reducer';
import orderReducer from './order/order.reducer';
import bulkReducer from './bulkUploadStates/bulk.reducer';
import setEditLimtReducer from './edit-band-limit/edit-band-limit.reducer';
import cardReducer from './card/card.reducer';
import kycCardReducer from './kyc-card/kyc-card.reducer';
import loadMoneyReducer from './load-money/loadMoney.reducer';
import forgotPasswordReducer from './forgot-password/forgot-password.reducer';
import adminViewReducer from './adminView/adminView.reducer';
import giftCardOrderReducer from './giftCardOrder/order.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'token', 'role', 'activeHeader',
        'rememberMe', 'kyc', 'showAndHide', 'bulk',
        'seteditLimt', 'card', 'seteditLimt', 'loadMoney','forgotPassword',"location","adminView","giftCardOrder"]
}

const rootReducer = combineReducers({
    user: userReducer,
    dropdown: dropDownReducer,
    message: messageReducer,
    checkbox: checkboxReducer,
    token: tokenReducer,
    role: roleReducer,
    band: bandReducer,
    location: locationReducer,
    kyc: kycReducer,
    listForAction: listForActionResucer,
    activeHeader: activeHeaderReducer,
    rememberMe: rememberMeReducer,
    showAndHide: showAndHideReducer,
    order: orderReducer,
    bulk: bulkReducer,
    seteditLimt: setEditLimtReducer,
    card: cardReducer,
    kycCard: kycCardReducer,
    loadMoney: loadMoneyReducer,
    forgotPassword:forgotPasswordReducer,
    adminView:adminViewReducer,
    giftCardOrder:giftCardOrderReducer
})


export default persistReducer(persistConfig, rootReducer);
