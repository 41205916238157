import { Alert } from '@material-ui/lab';
import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import CardStatus from '../../../constants/card-status.type';
import CsvDownload from 'react-json-to-csv';
import Select from "react-select";
import moment from 'moment';
import SmallPopUp from "../../../common/component/small.popup.component";
import { setSeverty } from '../../../redux/message/message.action';
import { Snackbar } from '@material-ui/core';
import { resetOder } from '../../../redux/order/order.action';
import SubBlockedCards from './sub-blocked.component';
import CardManagementService from '../../../service/service-impl/CardManagementService';
import LoadingComponent from '../loading/assigned.loading.component';
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import GenerateRequestId from '../../../common-function/GenerateRequestId';
import ReactTooltip from 'react-tooltip';
import { useToasts } from 'react-toast-notifications';


function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}

const collectedTrueKeys = {
    status: [],
    message: []
}
var ToDateVar = '';
var FromDateVar = '';
const BlockedCards = ({ authenticated, roleData, userRole, isPasswordReset, fullName, roMdn, currentUser, addToast }) => {

    var arr = [];
    var listForAction = [];


    const [loading, setLoading] = useState(true);

    const [cardList, setCardList] = useState([]);
    const [cardType, setCardType] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_BLOCKED_EMPLOYEE_RECORD);


    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');

    const [masterChecked, setMasterChecked] = useState(false);
    const [checkedPost, setCheckedPost] = useState([]);


    const [singleCheck, setSingleCheck] = useState(false)


    const [loadSubSection, setLoadSubSection] = useState(false)

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [searchField, setSearchField] = useState('');

    const [multiValueS, setMultiValueS] = useState([]);
    const [multiValueR, setmultiValueR] = useState([]);

    const [multiValueStatus, setMultiValueStatus] = useState([
        { value: "3", label: "Temporary" },
        { value: "4,5", label: "Permanent" },

    ])
    const [reasonMultiValue, setReasonByMultiValue] = useState([]);

    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [placeHolder, setPlaceHolder] = useState('...')


    const [detail, setDetail] = useState(false);


    const [arryOfCardDetail, setArrayOfCardDetail] = useState([]);

    useEffect(() => {
        document.title = "ShakePe Blocked Cards"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
        // store.dispatch(resetOder)


        getDetail();

    }, []);

    const getDetail = async () => {
        var status = [CardStatus.PERMANENT_BLOCK, CardStatus.TEMPORARY_BLOCK, CardStatus.REPLACED];

        setLoading(true);

        var url = `${process.env.REACT_APP_GET_CARD_EMPLOYEE_DETAIL_BY_ROID_AND_STATUS}?roId=${roMdn}&status=${status}`

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log('response history transaction', response, status);

        var reason = []
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.disbursalData !== undefined) {

            response.disbursalData.map(data => {
                var isChecked = 'isChecked';
                data[isChecked] = false;
                arr.push(data);

                reason.push(data.message);
            })
            var actualMap = []
            var uniq = reason.filter(onlyUnique);
            uniq.map(un => {
                var detail = {
                    value: "",
                    label: ""
                }
                detail.label = un;
                detail.value = un;
                actualMap.push(detail);
            })

        }
        setReasonByMultiValue(actualMap)
        setCardList(arr);
        setFilteredRecord(arr);
        setLoading(false);
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const setSearch = (e) => {
        setSearchField(e.target.value);
    }

    const handleStatuschange = (option) => {

        setMultiValueS(option)
        setCurrentPage(1);
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.status = [];
        if (option !== null) {
            option.map(op => {

                var val = op.value.split(",")
                val.map(sv => {
                    collectedTrueKeys.status.push(sv);
                })

            })
        }
    }

    const handleApprovedChangechange = (option) => {

        setmultiValueR(option)
        setCurrentPage(1);
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)

            setAllValuesForFilter(option)
        } else {
            console.log('else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })

            setAllValuesForFilter(data);
        }

        collectedTrueKeys.updatedBy = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.message.push(op.value)

            })
        }
    }


    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;

        if (name === 'toDate') {
            setToDate(value)

            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value)

            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD hh:mm:ss");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD hh:mm:ss");
            var status = [];
            // status.push(CardStatus.ACTIVE)
            // status.push(CardStatus.ASSIGNED)

            const requestToSend = {
                "roMdn": roMdn,
                "fromDate": formatFromDate,
                "toDate": formatToDate,
                "status": [
                    {
                        "status": CardStatus.TEMPORARY_BLOCK
                    },
                    {
                        "status": CardStatus.PERMANENT_BLOCK
                    }
                    ,
                    {
                        "status": CardStatus.REPLACED
                    }

                ]
            }


            const url = process.env.REACT_APP_GET_ASSIGNED_CARD_BY_DATERANGE;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('response', response)

            if (!(response === undefined) && (response.response_code === 0)) {

                var aty = []
                response.disbursalData.map(data => {
                    var isChecked = 'isChecked';
                    data[isChecked] = false;
                    aty.push(data);


                })
                setCurrentPage(1);
                setFilteredRecord(aty)

            } else {
                setFilteredRecord([])

            }

        }
    }

    const clearAll = () => {
        setMultiValueS([])

        setmultiValueR([])

        setFilteredRecord(cardList);

        setToDate('')
        setFromDate('')

        ToDateVar = ''
        FromDateVar = ''

        collectedTrueKeys.updatedBy = []
        collectedTrueKeys.status = []


        setAllValuesForFilter([])
    }

    const handleUnBlockedCard = async (event) => {


        arryOfCardDetail.map(async post => {

            var cardNumber = "";

            console.log("cardNumber = cn.cardReferenceNo", post.cardReferenceNo)
            cardNumber = post.cardReferenceNo
            if (post.cardStatus === CardStatus.PERMANENT_BLOCK || post.cardStatus === CardStatus.REPLACED) {
                return (
                    // setDropDownHidden(true),
                    // setSeverty("warning"),
                    // setMessage(process.env.REACT_APP_VAR_FOR_MESSAGE_Permanently_Blocked_Card_cannot_be_Unlock),
                    addToast(post.fullName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_Permanently_Blocked_Card_cannot_be_Unlock + "", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 7000
                    })
                )
            }
            else {
                var ref = post.mdn
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                if (document.getElementById(ref).checked) {

                    var url = process.env.REACT_APP_UPDATECARDSTATUS_URL

                    var responseMessage = "Card Unlock Successfully."
                    var responseFail = "Card Unlock Failed."
                    var cardStatus = CardStatus.ACTIVE


                    const request = {
                        device_info: {
                            api_version: "1.0",
                            app_version: "9.2",
                            device_id: "d215ab007e05c498",
                            device_os: "Android",
                            device_os_version: "5.1.1",
                            imei: "357869081907005",
                            ip_address: "172.160.2.140",
                            model_name: "SM-J320F",
                            source: "mobile",
                        },
                        request: {
                            requestId: GenerateRequestId.geneRateRequestId(),
                            source: "App",
                            type: "Virtual",
                            oxigenWallet: post.mdn,
                            statusCode: cardStatus,
                            issuer: "1",
                            userID: post.first_name,
                            reason: "UnBlock card",
                            otp: "otp",
                            cardReferenceNum: cardNumber,
                            updateType: "unBlock",
                            updatedBy: fullName,
                            ro_Id: roMdn,
                            updatedByEmail: currentUser,
                        }
                    };



                    var response = await apiCallService.postMethodCall(url, request);





                    console.log("Request date", url, post.mdn, post.first_name, "", "UnBlock card", cardStatus, "", post.cardRefNumber, "unBlock", currentUser, roMdn, '0')
                    if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {

                        var ref = post.mdn
                        console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                        document.getElementById(ref).checked = false

                        addToast(post.fullName + " - " + responseMessage + "", {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 7000
                        })




                        getDetail();


                    } else {

                        addToast(post.fullName + " - " + response.response_description + "", {
                            appearance: 'error',
                            autoDismiss: true,
                            autoDismissTimeout: 7000
                        })

                    }

                }
            }
        })



    }

    const handleBlockedCard = async (event, allValue, cD, reason) => {


        arryOfCardDetail.map(async post => {


            var cardNumber = "";

            // setTimeout(() => {
            //     post.employeeDetail.card.map(cn => {
            cardNumber = post.cardReferenceNo
            if (post.cardType === 0) {
                return (
                    // setDropDownHidden(true),
                    // setSeverty("warning"),
                    // setMessage(process.env.REACT_APP_VAR_FOR_MESSAGE_Virtual_Card_cannot_be_Blocked_Permanently)
                    addToast(post.fullName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_Virtual_Card_cannot_be_Blocked_Permanently + "", {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 7000
                    })
                )
            } else
                if (post.cardStatus === CardStatus.PERMANENT_BLOCK || post.cardStatus === CardStatus.REPLACED) {
                    return (
                        // setDropDownHidden(true),
                        // setSeverty("warning"),
                        // setMessage(process.env.REACT_APP_VAR_FOR_MESSAGE_Card_already_Permanently_Blocked)
                        addToast(post.fullName + " - " + process.env.REACT_APP_VAR_FOR_MESSAGE_Card_already_Permanently_Blocked + "", {
                            appearance: 'error',
                            autoDismiss: true,
                            autoDismissTimeout: 7000
                        })
                    )
                }
                else {

                    var ref = post.mdn
                    console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                    if (document.getElementById(ref).checked) {

                        var url = process.env.REACT_APP_UPDATECARDSTATUS_URL



                        var responseMessage = "Card Blocked Successfully."
                        var responseFail = "Card Block Failed."
                        var cardStatus = CardStatus.PERMANENT_BLOCK;


                        const request = {
                            device_info: {
                                api_version: "1.0",
                                app_version: "9.2",
                                device_id: "d215ab007e05c498",
                                device_os: "Android",
                                device_os_version: "5.1.1",
                                imei: "357869081907005",
                                ip_address: "172.160.2.140",
                                model_name: "SM-J320F",
                                source: "mobile",
                            },
                            request: {
                                requestId: GenerateRequestId.geneRateRequestId(),
                                source: "App",
                                type: "Virtual",
                                oxigenWallet: post.mdn,
                                statusCode: cardStatus,
                                issuer: "1",
                                userID: post.first_name,
                                reason: reason.reason,
                                otp: "otp",
                                cardReferenceNum: cardNumber,
                                updateType: 'permanent',
                                updatedBy: fullName,
                                ro_Id: roMdn,
                                updatedByEmail: currentUser,
                            }
                        };



                        var response = await apiCallService.postMethodCall(url, request);



                        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
                            // post.isChecked = false
                            var ref = post.mdn
                            console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                            document.getElementById(ref).checked = false

                            addToast(post.fullName + " - " + responseMessage + "", {
                                appearance: 'success',
                                autoDismiss: true,
                                autoDismissTimeout: 7000
                            })

                            getDetail();


                        } else {
                            // setDropDownHidden(true);
                            // setSeverty("error");
                            // setMessage(response.response_description);
                            addToast(post.fullName + " - " + response.response_description + "", {
                                appearance: 'error',
                                autoDismiss: true,
                                autoDismissTimeout: 7000
                            })

                        }

                    }
                }
        })



    }

    const handleMasterChecked = (event) => {
        setMasterChecked(event.target.checked);
        var arr = [];
        currentPost.map(posts => {
            console.log('inside if handleMasterChecked', posts);
            if (event.target.checked) {
                posts.isChecked = event.target.checked;
                console.log('worked for', posts.cardRefNumber)
                var ref = posts.mdn
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                document.getElementById(ref).checked = event.target.checked;
                listForAction.push(posts)


                posts.employeeDetail.card.map(cn => {
                    var fullName = "fullName"
                    cn[fullName] = posts.employeeDetail.fullName
                    arr.push(cn)
                    setArrayOfCardDetail(arr)

                })


                // setDetail(true)
            } else if (!event.target.checked) {
                console.log('inside else handleMasterChecked');
                posts.isChecked = event.target.checked;
                setArrayOfCardDetail(arr)
                var ref = posts.mdn
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                document.getElementById(ref).checked = event.target.checked;
                listForAction.pop(posts)
                // setDetail(false);
            }
        })
        setCheckedPost(filteredRecord)
    }



    const handleChecked = (event) => {
        var data = listForAction
        console.log(data.lenght, "and", postsPerPage)
        if (data.lenght !== postsPerPage) {
            setMasterChecked(false)
        }
        data.map(list => {
            listForAction.push(list);
        })

        var arr = [];

        arryOfCardDetail.map(cd => {
            arr.push(cd);
        })


        currentPost.map(post => {
            console.log('post.emp_id === event.target.id && event.target.checked === true', post.band === event.target.name && event.target.checked === false)
            if (post.mdn === event.target.id && event.target.checked === true) {
                console.log('inside if handleChecked');
                console.log('post to be performed action is : ', post)
                console.log('worked for', post.cardRefNumber)
                var ref = post.mdn
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                document.getElementById(ref).checked = event.target.checked;
                post.isChecked = event.target.checked
                listForAction.push(post)
                console.log('list to action performed correct', listForAction);
                setSingleCheck(true);
                post.employeeDetail.card.map(cn => {
                    var fullName = "fullName"
                    cn[fullName] = post.employeeDetail.fullName
                    arr.push(cn)
                    setArrayOfCardDetail(arr)

                })
            } else if (post.mdn === event.target.id && event.target.checked === false) {
                setSingleCheck(false)
                console.log('removed post from action performed', post)
                listForAction.pop(post)
                setMasterChecked(false)
                console.log('worked for', post.cardRefNumber)
                var ref = post.mdn
                console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                document.getElementById(ref).checked = event.target.checked;
                post.isChecked = event.target.checked
                console.log('list to action performed wrong', listForAction);


                var newarr = [];

                arryOfCardDetail.map(cd => {
                    if (cd.mdn === post.mdn) {

                    } else {
                        newarr.push(cd);
                    }

                })

                setArrayOfCardDetail(newarr);
                cardList.map(data => {
                    var ref = data.mdn
                    console.log('ref `${roleName}/${page.pageGroupId}/${page1.pageNameId}/${action.actionTypeId}`', ref)
                    if (document.getElementById(ref) != null && document.getElementById(ref).checked) {
                        setSingleCheck(true)
                    }
                })
            }
        })
        // setCheckedPost(filteredRecord)
    }


    const handleTblockButtonClick = (event, data) => {
        setCardType('unBlock');


    }
    const handlePblockButtonClick = (event, data) => {
        setCardType('permanent');

    }

    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    //Change Page
    const paginate = (pageNumber) => {
        setLoadSubSection(true)
        setCurrentPage(pageNumber);
        setLoadSubSection(false)
    }

    // console.log('reasonMultiValue', reasonMultiValue)

    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };

    console.log('-------------worked for before filter',)
    var filteredPostData = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



    }

    console.log('worked for after filter',)
    const key = 'mdn';

    const filteredPostNew = [...new Map(filteredPostData.map(item =>
        [item[key], item])).values()];


    var searchedRecord = [];
    console.log('worked for after uniq',)

    if (searchField !== '' && cardList !== undefined) {
        cardList.map(post => {
            if (post.employeeName !== undefined && post.employeeName !== null && post.employeeName.toLowerCase().includes(searchField.toLowerCase())) {
                searchedRecord.push(post)
            } else if (post.employeeId !== undefined && post.employeeId !== null && post.employeeId.includes(searchField)) {
                searchedRecord.push(post)
            } else if (post.mdn !== undefined && post.mdn !== null && post.mdn.includes(searchField)) {
                searchedRecord.push(post)
            }
        })
    }


    var size = 0;

    if (searchField !== '') {
        size = searchedRecord.length
        currentPost = searchedRecord.slice(indexOfFirstPost, indexOfLastPost)
    } else
        if (filteredPostNew === null || filteredPostNew === undefined) {
            currentPost = null
        } else {
            size = filteredPostNew.length
            currentPost = filteredPostNew.slice(indexOfFirstPost, indexOfLastPost)
        }

    console.log('worked for after search',)
    console.log('currentpost', currentPost);
    console.log("current post", cardList);


    var fileToExport = [];
    filteredRecord.map(data => {
        var obj = {
            Employee_Name: "",
            Employee_Id: "",
            Mobile_No: "",
            Band: "",
            Card_Ref_No: "",
            Blocked_By: "",
            Blocked_On: "",
            Reference_ID: "",
            Status: "",
            Reason: ''
        }
        if (data.isChecked) {
            obj.Employee_Name = data.employeeName;
            obj.Employee_Id = data.employeeId;
            obj.Mobile_No = data.mdn;
            obj.Band = data.band;
            obj.Card_Ref_No = data.cardRefNumber;
            obj.Blocked_By = data.updatedBy;
            obj.Blocked_On = data.dateUpdatedOn;
            obj.Reference_ID = data.reference_ID;
            obj.Status = data.statusVariable;
            obj.Reason = data.message;
            fileToExport.push(obj);
        } else {

        }

    })


    const customStyles = {
        control: (base, state) => ({
            ...base,
            'border': '1px solid #ddd',
            'borderRadius': '0',
            'boxSizing': 'content-box',
            'minHeight': '30px',
            'lineHeight': '18px',
            'display': 'flex'
        }),

        valueContainer: (base) => ({
            ...base,
            'position': 'relative',
            'overflow': 'hidden',
            'margin': '0',
            'padding': '5px',
            'width': '100%',
            'minHeight': '30px',
            'border': '1px solid #ddd',
            'backgroundColor': '#fff',
            'lineHeight': '18px',
            'cursor': 'text',
            'alignItems': 'center'
        }),
        multiValue: base => ({
            ...base,
            'boxSizing': 'content-box',
            'position': 'relative',
            'margin': '1px 5px 1px 0px',
            'padding': '1px 2px 3px 5px',
            'border': '1px solid #c4d5e1',
            'lineHeight': '18px',
            'maxWidth': '100%',
            'borderRadius': '3px',
            'backgroundColor': '#d6eeff',
            'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
            'color': '#4a546c',
            'lineHeight': '16px',
            'cursor': 'default',
            'height': '20px'
        }),
        menu: (base) => ({
            ...base,
            'padding': '2px 5px',
            'lineHeight': '16px',

        }),
        input: base => ({
            ...base,
            'height': '18px',
            'lineHeight': '18px'
        })

    };






    var startdateToDate = moment();
    startdateToDate = startdateToDate.format("YYYY-MM-DD");

    if (toDate === '') {
        var startdateFromDate = moment();
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    } else {
        var startdateFromDate = moment(toDate);
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    }



    var dateMax = moment(fromDate);
    dateMax = dateMax.format("YYYY-MM-DD");
    var dateFromMax = moment(toDate);
    dateFromMax = dateFromMax.format("YYYY-MM-DD");


    var height = window.innerHeight - 300


    var isCardActionHide = false;

    currentPost !== undefined && currentPost.map(postD => {

        console.log("condition of bulk action", postD.isChecked, postD.employeeDetail.card !== null && postD.employeeDetail.card.length > 1)


        if (postD.isChecked && postD.employeeDetail.card !== null && postD.employeeDetail.card.length > 1) {
            isCardActionHide = true;
        }

    })

    console.log("Card Detail Array", arryOfCardDetail);

    return (
        <div >
            {(authenticated)
                ? (
                    isPasswordReset ?
                        <div className="mid-container">
                            <div className="container-fluid">

                                <Snackbar

                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => setDropDownHidden(false)}
                                >
                                    <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                        {message}
                                    </Alert>
                                </Snackbar>
                                <div className="head">
                                    <div className="row">

                                        <div  className="col-6 col-md-4 order-md-1">
                                            <h3><input type="checkbox" id="blockedMasterCheck" className="checkall" checked={masterChecked} onClick={(event) => handleMasterChecked(event)} data-toggle="tooltip" title="Select All" /> Blocked Cards</h3>
                                        </div>


                                        <div className="col-6 col-md-4 order-md-3">

                                            <div className="flterbox">
                                                <div className="dropdown " data-toggle="tooltip" title="Filter">
                                                    <button  className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                    <div className="dropdown-menu dropdown-menu-right">


                                                        <div className="filters">
                                                            <label htmlFor="select-state">Block Type:</label>
                                                            <Select
                                                                name="filters"
                                                                placeholder={placeHolder}
                                                                value={multiValueS}
                                                                options={multiValueStatus}
                                                                onChange={handleStatuschange}
                                                                isMulti={true}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                        <div className="filters">
                                                            <label htmlFor="select-state">Reason :</label>
                                                            <Select
                                                                name="filters"
                                                                placeholder={placeHolder}
                                                                value={multiValueR}
                                                                options={reasonMultiValue}
                                                                onChange={handleApprovedChangechange}
                                                                isMulti={true}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                styles={customStyles}
                                                            />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">From Date:</label>
                                                            <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={fromDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">To Date:</label>
                                                            <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={toDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                        </div>

                                                        <div className="fbtn">
                                                            <button className="sbtn btnrd" type="reset" id="btnClear" onClick={clearAll}>Clear all</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {isCardActionHide
                                                ?
                                                null
                                                :
                                                <div className="actnbtn" >
                                                    {!(singleCheck || masterChecked) ?
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            <ReactTooltip
                                                                id="block"
                                                                place="top"
                                                                type='dark'
                                                                effect='solid'
                                                                // multiline={true}
                                                                backgroundColor="black"
                                                            />

                                                            <a data-for="block" data-tip="Unblock" data-iscapture="true" data-bs-toggle="modal" data-bs-target="#unlock-employee" onClick={handleTblockButtonClick} className="mdl-js-button mdl-js-ripple-effect"><i className="ublock" onClick={e => setCardType('unBlock')}>Unblock</i></a>

                                                            <a data-for="block" data-tip="Permanent Block" data-iscapture="true" data-bs-toggle="modal" data-bs-target="#permtBlock-employee" onClick={handlePblockButtonClick} className="mdl-js-button mdl-js-ripple-effect"><i className="pblock" onClick={e => setCardType('pBlock')}>P Block</i></a>

                                                        </>
                                                    }
                                                </div>}



                                            <SmallPopUp
                                                targetid="unlock-employee"
                                                React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Body
                                                headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Lable}
                                                bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Body}
                                                onclickFunctionName={handleUnBlockedCard}
                                                buttonActionName={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_UNLOCK_Button}
                                                buttonActionClose={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Cancel_Button}

                                            ></SmallPopUp>
                                            <SmallPopUp
                                                targetid="permtBlock-employee"
                                                headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Lable}
                                                bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Body}
                                                onclickFunctionName={handleBlockedCard}
                                                buttonActionName='Block'
                                                buttonActionClose="Cancel"

                                            ></SmallPopUp>
                                        </div>
                                        <div  className="col-12 col-md-4 order-md-2">
                                            <div  className="searchdata">
                                                <div  className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search Employee..." data-toggle="modal" title="Search" id="empsearch" name="search" onChange={setSearch} />

                                                    <button  className="btn srchbtn" type="button"></button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div  className="emp-list">
                                    <div className="row">

                                        {
                                            loading
                                                ?
                                                <LoadingComponent />
                                                :

                                                currentPost.length > 0 ?
                                                    currentPost.map(post => (
                                                        loadSubSection
                                                            ?
                                                            null
                                                            :
                                                            <SubBlockedCards post={post} singleCheck={singleCheck} handleChecked={handleChecked} />
                                                    ))
                                                    :
                                                    <div className="col-12">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3> No Record Available.</h3>
                                                        </div>
                                                    </div>


                                        }




                                        <div className="col-12"></div>

                                        {parseInt(size) > parseInt(postsPerPage)
                                            ?
                                            <>
                                                <div className="col-12 col-sm-6">
                                                    <p>

                                                        {detail
                                                            ?
                                                            <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"blocked-employee.csv"}>Export to CSV</CsvDownload>
                                                            : null
                                                        }
                                                        Showing {valueToShow} to {(indexOfLastPost > size) ? size : indexOfLastPost} of {size} entries</p>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <div className="pager">
                                                        <Pagination
                                                            activePage={parseInt(currentPage)}
                                                            itemsCountPerPage={parseInt(postsPerPage)}
                                                            totalItemsCount={size}
                                                            pageRangeDisplayed={parseInt(postsPerPage)}
                                                            onChange={paginate}
                                                            className="pagination"
                                                            linkClass="page-link"
                                                            itemClass="page-item"
                                                            nextPageText="Next"
                                                            prevPageText="Prev"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            filteredPostNew.length === 0
                                                ?
                                                null
                                                :

                                                <div className="col-12 col-sm-6">
                                                    <p>
                                                        {detail
                                                            ?
                                                            <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"blocked-employee.csv"}>Export to CSV</CsvDownload>
                                                            : null
                                                        }
                                                    </p>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        : <ResetLoginPassword />
                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, fullName, currentUser }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, fullName, isPasswordReset, currentUser
});

export default withToast(connect(mapStateToProps)(BlockedCards));
