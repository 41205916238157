import AdminViewType from './adminView.type';

const INITIAL_STATE = {
    adminView: "",
    loginUserEmail: "",
    loginUserRoID: "",
};

const adminViewReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case AdminViewType.SET_ADMIN_VIEW:
            return {

                ...state,
                adminView: action.payload
            };
        case AdminViewType.SET_ADMIN_LOGIN_USER_EMAIL:
            return {

                ...state,
                loginUserEmail: action.payload
            };
            case AdminViewType.SET_ADMIN_LOGIN_USER_ROID:
                return {
    
                    ...state,
                    loginUserRoID: action.payload
                };

        default:
            return state;
    }
};

export default adminViewReducer;