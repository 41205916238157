
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";




const AadharBiometry = ({ authenticated, roleData, cardRefNumber, userRole, roMdn, bandData }) => {


    useEffect(() => {


    }, []);



    return (
        <div >
            {(authenticated)
                ? (

                    <div className="col-12" id="adrbmc">


                        <div className="row">


                            {/* <div className="col-12 mt-3">
                                <h6>{process.env.REACT_APP_VAR_FOR_PENDING_KYC_IMPLEMNTATION}</h6>
                            </div> */}

                            <div className="col-12 col-sm-6 col-xl-5">
                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                                    <input className="mdl-textfield__input" type="text" id="" />
                                    <label className="mdl-textfield__label">Aadhaar Card Number</label>
                                </div>
                            </div>

                          

                            <div className="col-12 col-sm-6 col-xl-5">
                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder">
                                    <input className="mdl-textfield__input" type="text" value={cardRefNumber} disabled />
                                    <label className="mdl-textfield__label">Assigned Card Ref. Number</label>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="radio-checkbox">
                                    <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                        <input type="checkbox" id="" className="mdl-checkbox__input" />
                                        <span className="mdl-checkbox__label">  I agree to the <a href="#">terms and conditions</a></span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 mt-4">
                                <input type="reset" value="Reset" className="lbtn btngr" />
                                <input type="submit" value="Submit" className="lbtn btnbl" data-bs-toggle="modal" data-bs-target="#biometric" />
                            </div>

                            <div className="col-12 mt-5">
                                <div className="device"><i className="connect"></i> Biometric device connected, scan fingerprint of the customer for Aadhaar authentication</div>
                            </div>

                        </div>
                    </div>



                )
                : (
                    <Redirect to='/' />
                )


            }

        </div >

    )

}


const mapStateToProps = ({ band: { bandData }, token: { authenticated }, user: { userRole, roMdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, bandData
});

export default connect(mapStateToProps)(AadharBiometry);



