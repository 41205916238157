import { RememberMeTypes } from './rememberMe.types';

const INITIAL_STATE = {
    rememberMail: '',
    rememberMe: false,
    rememberPassword: ""
};

const remembermeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RememberMeTypes.SET_REMEMBER_ME:
            return {
                ...state,
                rememberMe: action.payload
            }

        case RememberMeTypes.SET_REMEMBER_ME_MAIL:
            return {
                ...state,
                rememberMail: action.payload
            }
        case RememberMeTypes.SET_REMEMBER_ME_PASSWORD:
            return {
                ...state,
                rememberPassword: action.payload
            }
        default:
            return state;
    }
};



export default remembermeReducer;
