import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import apiCallService from '../../../service/api-call-service';
import CsvDownload from 'react-json-to-csv';
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import Pagination from "react-js-pagination";
import Avatar from 'react-avatar';
import moment from 'moment';
import Select from "react-select";

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import history from '../../../auth/history';

import Loader from './loading-corp-reversal';


const collectedTrueKeys = {
    status: []
}

var ToDateVar = '';
var FromDateVar = '';
class AddCorpBank extends React.Component {

    constructor() {
        super();

        this.state = {
            allValuesForFilter: [],
            filteredRecord: [],
            requestedReversalDetail: [],
            currentPage: 1,
            postsPerPage: process.env.REACT_APP_VAR_FOR_REVERSAL_REQUEST_NUMER_OF_POST_PER_PAGE,
            fromDate: '',
            toDate: '',

            message: '',
            severty: '',
            dropDownHidden: false,


            loading: false,


            multiValueS: "",
            reason: "",
            multiValueStatus: [
                { value: "0", label: "Approved" },
                { value: "1", label: "Pending" },
                { value: "2", label: "Rejected" },

            ]
        };
    }


    componentDidMount = () => {
        console.log("getRequestedRevsal response without", process.env.REACT_APP_GET_REVERSAL_DETAIL_URL)
        this.setState({ loading: true })
        this.getRequestedRevsal();


    }

    getRequestedRevsal = async () => {


        const url = process.env.REACT_APP_GET_REVERSAL_DETAIL_URL;


        var request = {
            "fromDate": "",
            "toDate": ""
        }
        var response = await apiCallService.postMethodCall(url, request);


        console.log("getRequestedRevsal response", response)

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ requestedReversalDetail: response.reversalDetail });
            this.setState({ filteredRecord: response.reversalDetail });
            this.setState({ loading: false })
        } else {
            this.setState({ loading: false })
        }


    }

    handleStatuschange = (option) => {

        this.setState({ multiValueS: option })
        this.setState({ currentPage: 1 })

        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option })
        } else {
            console.log(' allValuesForFilter else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            this.setState({ allValuesForFilter: data })
        }

        collectedTrueKeys.status = [];
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.status.push((op.value));
            })
        }

    }




    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {
            this.setState({ loading: true })
            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");



            const url = process.env.REACT_APP_GET_REVERSAL_DETAIL_URL

            var request = {
                "fromDate": formatFromDate,
                "toDate": formatToDate
            }

            var response = await apiCallService.postMethodCall(url, request);

            console.log('response handleDateChange ', request, response)
            if (response !== undefined && response.response_code === 0) {

                this.setState({ filteredRecord: response.reversalDetail });
                this.setState({ currentPage: 1 })
                this.setState({ loading: false })
            } else {
                this.setState({ filteredRecord: [] })
                this.setState({ loading: false })
            }

        }
    }


    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.requestedReversalDetail })


        this.setState({ multiValueS: [] })
        ToDateVar = '';
        FromDateVar = '';

        this.setState({ allValuesForFilter: [] })
        collectedTrueKeys.status = []
        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)
    }

    updateReversal = async (request) => {

        console.log("updateReversal request", request)
        var url = process.env.REACT_APP_REVERSAL_URL;

        var response = await apiCallService.postMethodCall(url, request);

        console.log("updateReversal respnse", response)

        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: response.response_description })


            history.push('/corp-manage-reversal');
            history.go();

        } else {
            console.log("updateReversal respnse", response.response_description)
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }
    }



    approveReversal = (event, data) => {
        event.preventDefault();

        console.log("data of request", data)


        var request = {
            roId: data.corpId,
            amount: data.totalamt,
            beneAccountNumber: data.beneAccountNumber,
            beneName: data.beneName,
            ifscCode: data.ifscCode,
            emailAddress: data.emailAddress,
            mobileNumber: data.mobileNumber,
            remarks: data.remarks,
            reversalReqId: data.reversalRequestId,
            status: "0",
            updatedBy: this.props.fullName,
            updatedByEmail: this.props.currentUser,
            reason: "Reversal Approved",
        }

        this.updateReversal(request)

    }
    rejectReversal = (event, data) => {

        event.preventDefault();
        console.log("data of request", data)

        var request = {
            roId: data.corpId,
            amount: data.totalamt,
            beneAccountNumber: data.beneAccountNumber,
            beneName: data.beneName,
            ifscCode: data.ifscCode,
            emailAddress: data.emailAddress,
            mobileNumber: data.mobileNumber,
            remarks: data.remarks,
            reversalReqId: data.reversalRequestId,
            status: "2",
            updatedBy: this.props.fullName,
            updatedByEmail: this.props.currentUser,
            reason: this.state.reason,
        }

        this.updateReversal(request)
    }

    paginate = (pageNumber) => { this.setState({ currentPage: pageNumber }) };



    render() {
        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };



        //console.log(posts);
        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;




        var filteredPost = this.state.filteredRecord;

        if ((this.state.allValuesForFilter === null || !(this.state.allValuesForFilter !== undefined && this.state.allValuesForFilter.length && this.state.allValuesForFilter))) {
            filteredPost = this.state.filteredRecord
            // filteredPost = filteredList;
            console.log('inside if of bandlistToView', this.state.allValuesForFilter)
        } else if (this.state.allValuesForFilter.length !== 0) {

            filteredPost = multiPropsFilter(this.state.filteredRecord, collectedTrueKeys);

            console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)

        }


        if (filteredPost === null) {
            currentPost = null
        } else {
            currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost);
        }
        console.log("this.state.employeeByBatch true", filteredPost)


        var detail = true;
        if (currentPost.length === 0) {
            detail = false
        }

        var fileToExport = [];
        var j = 0;
        this.state.filteredRecord != null && this.state.filteredRecord.map(data => {
            var obj = {

                "Corporate Name": data.corpName,
                "Corporate Id": data.corpId,
                "Amount": parseFloat(parseFloat(data.reversalamt) / 100).toFixed(2),
                "Surcharge": parseFloat(parseFloat(data.surcharge) / 100).toFixed(2),
                "Total Amount": parseFloat(parseFloat(data.totalamt) / 100).toFixed(2)
            }
            fileToExport.push(obj);

        })


        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            var startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        var dateFromMax = moment(this.state.toDate);
        dateFromMax = dateFromMax.format("YYYY-MM-DD");


        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };


        console.log("this.state.filteredRecord", this.state)

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <div className="mid-container">

                                <div className="container-fluid">


                                    <div className="head">
                                        <div className="row">

                                            <div className="col-10">
                                                <h3>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Heading}</h3>
                                            </div>

                                            <Snackbar
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                open={this.state.dropDownHidden}
                                                autoHideDuration={3000}
                                                onClose={e => this.setState({ dropDownHidden: false })}
                                            >
                                                <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                    {this.state.message}
                                                </Alert>
                                            </Snackbar>
                                            <div className="col-2">
                                                <div className="flterbox">
                                                    <div className="dropdown" data-toggle="tooltip" title="Filter">
                                                        <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                        <div className="dropdown-menu dropdown-menu-right">

                                                            <div className="filters">
                                                                <label htmlFor="select-state">Status:</label>
                                                                <Select
                                                                    name="filters"
                                                                    placeholder={this.state.placeHolder}
                                                                    value={this.state.multiValueS}
                                                                    options={this.state.multiValueStatus}
                                                                    onChange={this.handleStatuschange}
                                                                    isMulti={true}
                                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                    styles={customStyles}
                                                                />
                                                            </div>



                                                            <div className="filters">
                                                                <label htmlFor="select-state">From Date:</label>
                                                                <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                            </div>

                                                            <div className="filters">
                                                                <label htmlFor="select-state">To Date:</label>
                                                                <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                            </div>

                                                            <div className="fbtn">
                                                                <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        {
                                            this.state.loading
                                                ?
                                                <Loader />
                                                :
                                                <>


                                                    <div className="col-12">



                                                        {


                                                            currentPost.length === 0
                                                                ?
                                                                <div className="col-12">
                                                                    <div className="no-record">
                                                                        <i></i>
                                                                        <h3> No Record Available.</h3>
                                                                    </div>
                                                                </div>
                                                                :

                                                                currentPost.map(rd => (
                                                                    <div className="reverscard">
                                                                        <div className="row">
                                                                            <div className="col-12 col-md-6 col-lg-3 col-xl-4">
                                                                                <div className="corprevers">
                                                                                    <Avatar src={rd.corpImageUrl} color={Avatar.getRandomColor(rd.corpId, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} name={rd.corpName} size="48px" round={true} />



                                                                                    <h5>{rd.corpName}</h5>
                                                                                    <p>#{rd.corpId} - {rd.reversalCreatedDate}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-md-6 col-lg-3 col-xl-2">
                                                                                <div className="reverstype">
                                                                                    <p>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reversal_Type}</p>
                                                                                    <h5>{rd.modeType}</h5>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-md-6 col-lg-2 col-xl">
                                                                                <div className="reversamount">
                                                                                    <p>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reversal_Amount}</p>
                                                                                    <h5>₹ {parseFloat(parseFloat(rd.reversalamt) / 100).toFixed(2)}</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 col-md-3 col-lg-2 col-xl">
                                                                                <div className="reversamount">
                                                                                    <p>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Surcharge}</p>
                                                                                    <h5>₹ {parseFloat(parseFloat(rd.surcharge) / 100).toFixed(2)}</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 col-md-3 col-lg-2 col-xl">
                                                                                <div className="reversamount">
                                                                                    <p>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Total_Amount}</p>
                                                                                    <h5>₹ {parseFloat(parseFloat(rd.totalamt) / 100).toFixed(2)}</h5>
                                                                                </div>
                                                                            </div>

                                                                            {rd.status === "1" &&
                                                                                <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                                                                                    <div className="reversbtn">
                                                                                        <button type="button" className="sbtn btngn" onClick={e => this.approveReversal(e, rd)}>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Approve_Button}</button>
                                                                                        <button type="button" className="sbtn btnrd" data-bs-toggle="modal" data-bs-target={`#rejectamt${rd.reversalRequestId}`}>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reject_Button}</button>
                                                                                    </div>
                                                                                    <div className="modal fade" id={`rejectamt${rd.reversalRequestId}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                                                                        <form className="modal-dialog modal-sm" onSubmit={e => this.rejectReversal(e, rd)}>
                                                                                            <div className="modal-content alerttext">
                                                                                                <div className="modal-header">
                                                                                                    <h5 id="">{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reject_Reversal_Popup_Heading}</h5>
                                                                                                    <button className="close" data-bs-dismiss="modal"  aria-label="Close">&times;</button>
                                                                                                </div>
                                                                                                <div className="modal-body">
                                                                                                    <div>
                                                                                                        <p>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Are_you_sure_to_reject_this_reversal}</p>

                                                                                                        <textarea className="form-control" placeholder="Enter reason" rows="4" value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })} required></textarea>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="modal-footer">
                                                                                                    <button type="button" className="mbtn btngr" data-bs-dismiss="modal" >{process.env.REACT_APP_Reversal_Management_VAR_FOR_No_Button}</button>
                                                                                                    <button type="submit" className="mbtn btnbl">{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reject_Button}</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {
                                                                                rd.status === "0" && <>  <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                                                                                    <div className="reversbtn">
                                                                                        <button type="button" className="sbtn aprv" disabled>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Approved_Button}</button>
                                                                                    </div>
                                                                                </div>

                                                                                    <div className="col-12">
                                                                                        <div className="rejectreason">
                                                                                            <div className="rejecttxt">
                                                                                                <p><span>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Note}</span> {rd.reason}</p>
                                                                                            </div>
                                                                                            <div className="rejectby">
                                                                                                <p><span>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Approved_By}</span> {rd.reversalUpdateBy} - {rd.reversalUpdatedDate}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>}

                                                                            {
                                                                                rd.status === "2" && <> <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                                                                                    <div className="reversbtn">
                                                                                        <button type="button" className="sbtn rjct" disabled>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Rejected_Button}</button>
                                                                                    </div>
                                                                                </div>

                                                                                    <div className="col-12">
                                                                                        <div className="rejectreason">
                                                                                            <div className="rejecttxt">
                                                                                                <p><span>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reason}</span> {rd.reason}</p>
                                                                                            </div>
                                                                                            <div className="rejectby">
                                                                                                <p><span>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Rejected_By}</span> {rd.reversalUpdateBy} - {rd.reversalUpdatedDate}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }

                                                                            {/* {
                                                                                rd.status === "4" && <> <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                                                                                    <div className="reversbtn">
                                                                                        <button type="button" className="sbtn rjct" disabled>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reject_Button}</button>
                                                                                        <button type="button" className="sbtn aprv" disabled>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Approve_Button}</button>

                                                                                    </div>
                                                                                </div>

                                                                                    <div className="col-12">
                                                                                        <div className="rejectreason">
                                                                                            <div className="rejecttxt">
                                                                                                <p><span>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Reason}</span> {rd.reason}</p>
                                                                                            </div>
                                                                                            <div className="rejectby">
                                                                                                <p><span>{process.env.REACT_APP_Reversal_Management_VAR_FOR_Action_Taken_By}</span> {rd.reversalUpdateBy} - {rd.reversalUpdatedDate}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            } */}


                                                                        </div>
                                                                    </div>
                                                                ))


                                                        }






                                                    </div>

                                                    <div className="w-100 mt-3"></div>


                                                    {parseInt(this.state.filteredRecord.length) > parseInt(this.state.postsPerPage) ?
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <p>
                                                                    {!detail ? <></> :
                                                                        <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"txn-report.csv"} >Export to CSV</CsvDownload>
                                                                    }



                                                                    Showing {valueToShow} to {(indexOfLastPost > this.state.filteredRecord.length) ? this.state.filteredRecord.length : indexOfLastPost} of {this.state.filteredRecord.length} entries</p>
                                                            </div>

                                                            <div className="col-12 col-sm-6">
                                                                <div className="pager">
                                                                    <Pagination
                                                                        activePage={parseInt(this.state.currentPage)}
                                                                        itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                        totalItemsCount={parseInt(this.state.requestedReversalDetail.length)}
                                                                        pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                        onChange={this.paginate}
                                                                        className="pagination"
                                                                        linkClass="page-link"
                                                                        itemClass="page-item"
                                                                        nextPageText="Next"
                                                                        prevPageText="Prev"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :

                                                        <div className="col-12 col-sm-6">
                                                            <p>
                                                                {!detail ? <></> :
                                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"reversal-data.csv"} >Export to CSV</CsvDownload>
                                                                }

                                                            </p>

                                                        </div>


                                                    }
                                                </>

                                        }


                                    </div>




                                </div>



                            </div>

                            :
                            <ResetLoginPassword />

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName, corpBankDetail, bankBackButton, bankUrl, mobileNum, buttonName }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, token, currentUser, fullName, isPasswordReset, corpBankDetail, bankBackButton, bankUrl, mobileNum, buttonName

});




export default connect(mapStateToProps)(AddCorpBank);