
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../../redux/activeHeader/activeheader.action';
import { store } from '../../../../redux/store';
import apiCallService from '../../../../service/api-call-service';
import moment from 'moment';
import { setKycDetail } from '../../../../redux/kyc-card/kyc-card.action';
import { showKycDetailSuccessSection } from '../../../../redux/div-show-and-hide/div-show-and-hide.action';




const MinUserKyc = ({ authenticated, cardRefNumber, employee, roleData, userRole, roMdn, bandData, lastFourDigit, currentUser, fullName }) => {


    const [panNum, setPanNum] = useState('');
    const [aadharNum, setaadharNum] = useState('');
    const [popUpbuttonShow, setpopUpbuttonShow] = useState(true);
    const [otpRefNumber, setOtpRefNum] = useState('');
    const [otp, setOtp] = useState('');
    const [kycIdentifier, setKycIdentifier] = useState('aadhar');

    const [disableVar, setdisableVar] = useState(true);
    const [time, setTime] = useState(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT));
    const [triggeretime, setTriggeretime] = useState(false);

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');

    const [kycDisable, setkycDisable] = useState(false);



    const [submitButtonProcessBar, setSubmitButtonProcessBar] = useState(false);


    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "active",
            manageLimitClass: "",
            activityClass: ""
        }
        setdisableVar(true);
        store.dispatch(setActiveHeader(payload));


        var date = employee.dob

        var dateOfBirth = moment(date).format("YYYY-MM-DD");
        console.log("dateOfBirth", date, " : ", dateOfBirth)
        setPanNum(employee.pan)



    }, []);

    const handleInputCheckBox = (event) => {
        setKycIdentifier(event.target.name)
    }

    const handleInputChangePan = (event) => {
        setPanNum(event.target.value);
    }
    const handleInputChangeAadhar = (event) => {
        setaadharNum(event.target.value);
    }
    const setOtpChange = (event) => {
        setOtp(event.target.value);
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();


        setSubmitButtonProcessBar(true)
        const url = `${process.env.REACT_APP_OEMS_REGESTER_USER_FOR_KYC}/${employee.mdn}`

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log(response)
        setTriggeretime(false);

        console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            setpopUpbuttonShow(false);
            setOtpRefNum(response.response.otp_ref_number);
            setSubmitButtonProcessBar(false)

            setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
            setTriggeretime(true);
            console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
            document.getElementById('sendOtpPopup').click();

        } else {
            setSubmitButtonProcessBar(false)

            if (response.response_description === "User already registered.") {

                doKyc();
            } else {
                setDropDownHidden(true);
                setSeverty("error");
                setMessage(response.response_description);
            }

        }

    }




    const resendOtp = async (event) => {
        const url = `${process.env.REACT_APP_OEMS_REGESTER_USER_FOR_KYC}/${employee.mdn}`



        var response = await apiCallService.getMethodCallWithoutParam(url);
        setTriggeretime(false);

        console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

            setDropDownHidden(true);
            setSeverty("sucess");
            setMessage(process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile);

            setOtpRefNum(response.response.otp_ref_number);
            setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
            setTriggeretime(true);

        } else {
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
        }
    }









    const MethodTosetDisabledVar = () => {
        setdisableVar(false);

    }
    const MethodTosetEnableVar = () => {
        setdisableVar(true);

    }

    const validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const doKyc = async (event) => {
        event.preventDefault();
        setkycDisable(true);

        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());

        var currentDatedd = currentDate.getDate();
        var currentDatemm = currentDate.getMonth() + 1;
        var currentDatey = currentDate.getFullYear();
        var currentDatefgg = currentDate.getHours();
        var currentDatefggm = currentDate.getMinutes();
        var currentDatefggs = currentDate.getSeconds();



        var maskcurrentDatedd = `${currentDatedd}`.length === 1 ? '0' + currentDatedd : currentDatedd
        var maskcurrentDatemm = `${currentDatemm}`.length === 1 ? '0' + currentDatemm : currentDatemm
        var maskcurrentDatefgg = `${currentDatefgg}`.length === 1 ? '0' + currentDatefgg : currentDatefgg
        var maskcurrentDatefggm = `${currentDatefggm}`.length === 1 ? '0' + currentDatefggm : currentDatefggm
        var maskcurrentDatefggs = `${currentDatefggs}`.length === 1 ? '0' + currentDatefggs : currentDatefggs





        var currentDatesomeFormattedDate = maskcurrentDatedd + '-' + maskcurrentDatemm + '-' + currentDatey + ' ' + maskcurrentDatefgg + ':' + maskcurrentDatefggm + ':' + maskcurrentDatefggs


        var someDate = new Date();
        var numberOfDaysToAdd = parseInt(process.env.REACT_APP_MIN_KYC_END_DATE_TIME_INDAYS);
        someDate.setDate(someDate.getDate() + numberOfDaysToAdd);

        var dd = someDate.getDate();
        var mm = someDate.getMonth() + 1;
        var y = someDate.getFullYear();
        var fgg = someDate.getHours();
        var fggm = someDate.getMinutes();
        var fggs = someDate.getSeconds();



        var maskdd = `${dd}`.length === 1 ? '0' + dd : dd
        var masky = `${mm}`.length === 1 ? '0' + mm : mm
        var maskfgg = `${fgg}`.length === 1 ? '0' + fgg : fgg
        var maskfggm = `${fggm}`.length === 1 ? '0' + fggm : fggm
        var maskfggs = `${fggs}`.length === 1 ? '0' + fggs : fggs;



        var someFormattedDate = maskdd + '-' + masky + '-' + y + ' ' + maskfgg + ':' + maskfggm + ':' + maskfggs

        console.log("someFormattedDate", someFormattedDate)

        var isPanOrNot = 0
        if (kycIdentifier === 'pan') {
            isPanOrNot = 1;
        }

        var dateOfBirth = moment(employee.dob).format("YYYY-MM-DD");
        var empDetail = employee;
        var roId = "roId"
        empDetail[roId] = roMdn;
        empDetail.dob = dateOfBirth;
        if (empDetail.gender.toLowerCase() === 'male') {
            empDetail.gender = "M"
        } else {
            empDetail.gender = "F"
        }


        var request = {
            "employeeDetailRequest": empDetail,
            // "cardRefNum": cardRefNumber,
            "isPanOrNot": isPanOrNot,
            "panNum": panNum,
            "aadharNum": aadharNum,
            "otpRefNumber": otpRefNumber,
            "otp": otp,
            // "lastFOurDigit": lastFourDigit,
            "kycExpiryDate": someFormattedDate,
            "updatedBy": fullName,
            "updatedByEmail": currentUser,
        }

        console.log(request)

        const url = process.env.REACT_APP_DO_MIN_KYC;

        var response = await apiCallService.postMethodCall(url, request);


        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            setkycDisable(false);
            document.getElementById('closeDic').click();
            setDropDownHidden(true);
            setSeverty("success");
            setMessage("response.response_description");
            var dataPayLoad = {
                "assignedCardReferenceNumber": cardRefNumber,
                "aadharCardNum": aadharNum,
                "panCardNum": panNum,
                "kycRefId": "NA",
                "dateAndTime": currentDatesomeFormattedDate,
                "kycEndDate": someFormattedDate,
                "kycEndDateMessage": process.env.REACT_APP_KYC_END_DATE_MESSAGE

            }
            store.dispatch(setKycDetail(dataPayLoad));
            store.dispatch(showKycDetailSuccessSection(true));
        } else {
            setkycDisable(false);
            setDropDownHidden(true);
            setSeverty("error");
            setMessage(response.response_description);
            if (response.response_description !== undefined && response.response_description !== null && response.response_description.toLowerCase().includes("otp")) {

            } else {
                setDropDownHidden(true);
                setSeverty("error");
                setMessage(response.response_description);
                document.getElementById('closeDic').click();
            }
        }


    }

    const restFunction = () => {
        setaadharNum('');
        setKycIdentifier('aadhar');
        // setPanNum('');
    }


    if (employee !== undefined && employee.mdn !== undefined) {
        var myString = employee.mdn.substring(0, 4);
    }

    console.log("cardRefNumber hhhhhhhhh", cardRefNumber)
    return (
        <div >
            {(authenticated)
                ? (

                    <div className="kyctab" id="minkyc">
                        <Snackbar

                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={dropDownHidden}
                            autoHideDuration={3000}
                            onClose={e => setDropDownHidden(false)}
                        >
                            <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                {message}
                            </Alert>
                        </Snackbar>

                        <form className="row" onSubmit={handleFormSubmit}>

                            <div className="col-12 mb-3">
                                <h6 className="mb-2"> Select Document type:</h6>

                                <div className="form-check form-check-inline">
                                    <input type="radio" id="aadharbtn" className="form-check-input" name="aadhar" value={kycIdentifier} checked={kycIdentifier === 'aadhar'} onClick={handleInputCheckBox} />
                                    <label className="form-check-label" for="aadharbtn" >
                                        Aadhar
                                    </label>
                                </div>



                                {/* <div className="form-check form-check-inline">
                                    <input type="radio" id="panbtn" className="form-check-input" name="pan" value={kycIdentifier} checked={kycIdentifier === 'pan'} onClick={handleInputCheckBox} />
                                    <label className="form-check-label" for="panbtn" >  PAN  </label>
                                </div> */}
                            </div>

                            {kycIdentifier === 'aadhar'
                                ?
                                <div className="col-12 col-sm-6 col-xl-5">
                                    <div className="webform">
                                        <div className="form-floating">
                                            <input className="form-control"
                                                type="number"
                                                id="adr-input"
                                                maxLength="12"
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                pattern="[1-9]{1}[0-9]{11}"
                                                onInput={validateInputLenght}
                                                value={aadharNum}
                                                onChange={handleInputChangeAadhar}
                                                placeholder="Aadhar Card Number"
                                                required
                                            />
                                            <label for="adr-input">Aadhar Card Number</label>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-12 col-sm-6 col-xl-5">
                                    <div className="webform">
                                        <div className="form-floating">
                                            <input className="form-control"
                                                type="pan"
                                                id="pan-input"
                                                maxlength="10"
                                                pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                                                value={panNum}
                                                onChange={handleInputChangePan}
                                                placeholder="PAN Card Number"
                                                required
                                            // disabled
                                            />
                                            <label for="pan-input">PAN Card Number</label>
                                        </div>
                                    </div>
                                </div>

                            }

                            <div className="col-12 col-sm-6 col-xl-5">
                                <div className="webform">
                                    <div className="form-floating">
                                        <input className="form-control" type="text" value={cardRefNumber} placeholder="Number" disabled />
                                        <label className="mdl-textfield__label">Assigned Card Ref. Number</label>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="col-12 mt-4">
                                <div className="form-check form-check-inline">

                                    <input type="checkbox" id="tnc" className="form-check-input" required />

                                    <label for="tnc" className="form-check-label" >   I agree to the <a
                                    >terms and conditions</a>
                                    </label>
                                </div>
                            </div> */}
                             <div  className="col-12 kyc_min_full_rbi_msg">
                                <div className="radio-checkbox">
                                    <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect">
                                        <input type="checkbox" id="" className="mdl-checkbox__input" required />
                                        <span className="mdl-checkbox__label"><img style={{height:'40px', width:'90px'}} src={require('../../../../assests/YB_LOGO.jpg')} alt="" />{ process.env.REACT_APP_KYC_RBI_MESSAGE}</span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 mt-4">
                                <input type="reset" value="Reset" className="lbtn btngr" onClick={restFunction} />
                                {/* <input type="reset" value="Reset" className="lbtn btngr" /> */}

                                {popUpbuttonShow
                                    ?
                                    <button type="submit" disabled={submitButtonProcessBar} className="lbtn btnbl" >
                                        {submitButtonProcessBar
                                            ?
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                            </div>
                                            :
                                            <> Submit </>
                                        }
                                    </button>
                                    :
                                    <button id="sendOtpPopup" type="button" className="lbtn btnbl" data-bs-toggle="modal" data-bs-target="#sendotp" >Submit</button>
                                }
                            </div>

                            {/* <div className="col-12 mt-5">
                                <div className="itxt">{process.env.REACT_APP_OEMS_TEXT_FOR_NOTE_MIN_KYC}</div>
                            </div> */}

                        </form>

                        <div className="modal fade" id="sendotp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"  >
                            <form className="modal-dialog modal-sm" onSubmit={doKyc}>
                                <div className="modal-content alerttext">
                                    <div className="modal-header">
                                        <h5>One-Time-Password </h5>
                                        <button className="close" id="closeDic" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="otptxt">
                                            <input className="form-control" type="number" placeholder="Enter OTP" maxLength="6"
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                onInput={validateInputLenght} value={otp} onChange={setOtpChange} required />
                                            <p>We have sent the OTP on this mobile number: {myString}XXXXXX</p>

                                            <h5>
                                                {triggeretime ?
                                                    <Countdown date={time} onMount={MethodTosetEnableVar} onComplete={MethodTosetDisabledVar} />
                                                    :
                                                    null
                                                }
                                            </h5>
                                        </div>

                                    </div>
                                    <div className="modal-footer">

                                        {
                                            disableVar
                                                ?
                                                <button type="button" className="mbtn btngr" onClick={resendOtp} disabled={disableVar}>

                                                    Resend OTP

                                                </button>
                                                :
                                                <button type="button" className="mbtn btngn" onClick={resendOtp} disabled={disableVar}>

                                                    Resend OTP

                                                </button>
                                        }

                                        <button type="submit" className="mbtn btnbl" disabled={kycDisable}>

                                            {
                                                kycDisable

                                                    ?
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                    </div>
                                                    :
                                                    <>Submit</>

                                            }


                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>



                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ band: { bandData }, token: { authenticated }, user: { userRole, roMdn, currentUser, fullName }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, bandData, currentUser, fullName
});

export default connect(mapStateToProps)(MinUserKyc);



