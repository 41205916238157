import { RememberMeTypes } from './rememberMe.types';

export const setRememberMe = data=> ({
  type: RememberMeTypes.SET_REMEMBER_ME,
  payload:data
})

export const setRememberMail = data=> ({
  type: RememberMeTypes.SET_REMEMBER_ME_MAIL,
  payload:data
})

export const setRememberPassword = data=> ({
  type: RememberMeTypes.SET_REMEMBER_ME_PASSWORD,
  payload:data
})