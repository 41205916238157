import React from "react";
import "../../employee/css/employee.css";
import CardStatusTypes from '../../constants/card-status.type';

const CardStatus = ({ cardStatus, roleName, type, classNameToSet, cardStatusClassName }) => (

    (() => {
        switch (cardStatus) {
            case CardStatusTypes.ACTIVE:
                return (
                    (type === 'button')
                        ?
                        <div className="cardsts">
                            {(roleName === '')
                                ?
                                <div className="card-active">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Active}
                                </div>
                                :
                                <div className="card-active">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Active} - {roleName}
                                </div>
                            }
                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <p>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</p>
                                <h5 className="active">{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Active}</h5>
                            </div>
                        </div>
                );
            case CardStatusTypes.ASSIGNED:
                return (
                    (type === 'button')
                        ?
                        <div className="cardsts">
                            {(roleName === '')
                                ?
                                <div className="card-inactive">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Assigned}
                                </div>
                                :
                                <div className="card-inactive">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Assigned} - {roleName}
                                </div>
                            }
                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <p>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</p>
                                <h5 >{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Assigned}</h5>
                            </div>
                        </div>
                );
            case CardStatusTypes.NOT_ASSIGNED:
                return (

                    (type === 'button')
                        ?
                        <div className="cardsts">
                            {(roleName === '')
                                ?
                                <div className="card-inactive">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Not_Assigned}
                                </div>
                                :
                                <div className="card-inactive">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Not_Assigned} - {roleName}
                                </div>
                            }
                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <p>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</p>
                                <h5 >{process.env.REACT_APP_Var_FOR_EMPLOYEE_Not_Assigned}</h5>
                            </div>
                        </div>
                );

            case CardStatusTypes.TEMPORARY_BLOCK:
                return (

                    (type === 'button')
                        ?
                        <div className="cardsts">

                            {(roleName === '')
                                ?
                                <div className="card-tblocked">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Temporary_Blocked}
                                </div>
                                :
                                <div className="card-tblocked">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Temporary_Blocked} - {roleName}
                                </div>
                            }

                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <p>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</p>
                                <h5 className="tblock">{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Temporary_Blocked} </h5>
                            </div>
                        </div>
                );
            case CardStatusTypes.PERMANENT_BLOCK:
                return (

                    (type === 'button')
                        ?
                        <div className="cardsts">

                            {(roleName === '')
                                ?
                                <div className="card-pblocked">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Card_PermanentBlocked}
                                </div>
                                :
                                <div className="card-pblocked">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Card_PermanentBlocked} - {roleName}
                                </div>
                            }

                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <p>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</p>
                                <h5 className="pblock">{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Permanent_Blocked}</h5>
                            </div>
                        </div>
                );

            case CardStatusTypes.REPLACED:
                return (

                    (type === 'button')
                        ?
                        <div className="cardsts">

                            {(roleName === '')
                                ?
                                <div className="card-pblocked">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Card_PermanentBlocked}
                                </div>
                                :
                                <div className="card-pblocked">
                                    {process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Card_PermanentBlocked} - {roleName}
                                </div>
                            }

                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <p>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</p>
                                <h5 className="pblock">{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Permanent_Blocked}</h5>
                            </div>
                        </div>
                );


            default:
                return (

                    (type === 'button')
                        ?
                        <div className="cardsts">
                            <div className="card-na">
                                NA
                            </div>
                        </div>
                        :
                        <div className={classNameToSet}>
                            <div className={cardStatusClassName}>
                                <label>{process.env.REACT_APP_Var_FOR_EMPLOYEE_CARD_STATUS_Card_Status}</label>
                                <h5 className="active">NA</h5>
                            </div>
                        </div>
                )
        }
    })()

);



export default CardStatus;
