import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import otpImg from '../../assests/otp-img.png'
import { setOTP } from '../../redux/forgot-password/forgot-password.action';
import { store } from '../../redux/store';
import history from '../../auth/history';
import apiCallService from '../../service/api-call-service';


import shakePeLogo from '../../assests/shakepe-logo.png'

class ResetLoginPassword extends React.Component {

	constructor() {
		super();

		this.state = {

			otp: "",
			messageValid: '',
			dropDownHidden: false,
			message: "",
			severty: "success",
			maskEmail: ''

		};
	}

	componentDidMount = () => {
		var maskEmail = this.props.email;

		var afterAt = maskEmail.split("@");


		console.log('afterAt', afterAt)

		var afterAtValue = `@${afterAt[1]}`;

		var lengthOfone = afterAt[0];

		console.log('afterAt', afterAtValue, lengthOfone.length);

		var slicedValue = afterAt[0].slice(0, 3);
		console.log('afterAt', slicedValue);

		var starsInclude = '';


		for (let i = 0; i < lengthOfone.length - 3; i++) {
			console.log('afterAt', i, starsInclude);
			starsInclude = `${starsInclude}*`;
		}

		console.log('afterAt', starsInclude);

		var finalValue = `${slicedValue}${starsInclude}${afterAtValue}`;

		console.log('afterAt', finalValue);



		this.setState({ maskEmail: finalValue });




	}


	formSubmit = async (event) => {
		event.preventDefault();

		if (this.state.otp !== '') {



			var url = process.env.REACT_APP_VALIDATE_OTP;

			var request = {
				"request": {
					"referenceNumber": this.props.referenceNum,
					"otp": this.state.otp
				}
			}



			var response = await apiCallService.postMethodCall(url, request);


			console.log("Response from validate otp", response, request, url);

			if (response !== undefined && response.response_code === 0) {
				store.dispatch(setOTP(this.state.otp));

				history.push("/reset-password")
				history.go();
			} else {
				this.setState({ messageValid: response.response_description });
			}


		}
	}


	resenOtp = async () => {
		const url = process.env.REACT_APP_RESEND_OTP_URL;

		const request = {
			"request": {
				"referenceNumber": this.props.referenceNum
			}
		}

		var response = await apiCallService.postMethodCall(url, request);

		console.log("response resenOtp", response)

		if (response !== undefined && response.response_code === 0 && response.response !== undefined) {
			this.setState({ dropDownHidden: true })
			this.setState({ severty: "success" })
			this.setState({ message: response.response.statusMessage })

		} else {
			this.setState({ messageValid: response.response_description });
		}


	}





	render() {

		// console.log("States here", this.state);

		return (
			// <div className="login-container">
			// 	<div className="container-fluid">
			// 		<div className="row flex-row-reverse">
			// 			<div className="col-12 col-md-6 col-lg-5 col-xl-4 frmbg">
			// 				<div className="login-form">

			// 					<Snackbar

			// 						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			// 						open={this.state.dropDownHidden}
			// 						autoHideDuration={20000}
			// 						onClose={e => this.setState({ dropDownHidden: false })}
			// 					>
			// 						<Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
			// 							{this.state.message}
			// 						</Alert>
			// 					</Snackbar>
			// 					<div className="logo"><img src={corpImg} alt="ZRO" className="img-fluid" /></div>
			// 					<h4>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_One_Time_Password}</h4>
			// 					<p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Please_enter_the_OTP_we_just_sent_on_your_registered_email_address}{this.state.maskEmail}</p>


			// 					<p>Reference Number : {this.props.referenceNum}</p>


			// 					<p style={{ color: "#f46a6a", marginTop: "-16px" }}> {this.state.messageValid}</p>

			// 					<form onSubmit={this.formSubmit}>
			// 						<div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
			// 							<input className="mdl-textfield__input" type="text" id="" value={this.state.otp} onChange={e => { this.setState({ otp: e.target.value }) }} required />
			// 							<label className="mdl-textfield__label">Enter OTP</label>
			// 						</div>

			// 						<div className="form-row">

			// 							<div className="col-12 formtxt">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_If_you_didn0t_receive_a_OTP} <button type="button" onClick={this.resenOtp}> {process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Resend_OTP}</button></div>

			// 							<div className="col-12 mt-5">
			// 								<button type="submit" id="" className="logbtn">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_One_Time_Password_Submit_Button}</button>
			// 							</div>

			// 						</div>
			// 					</form>
			// 				</div>
			// 			</div>



			// 		</div>
			// 	</div>
			// </div>
			<div  className="log-container">
				<div  className="container">
					<div  className="row justify-content-sm-center">
						<div  className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
							<div  className="logbox">
								<Snackbar

									anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
									open={this.state.dropDownHidden}
									autoHideDuration={20000}
									onClose={e => this.setState({ dropDownHidden: false })}
								>
									<Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
										{this.state.message}
									</Alert>
								</Snackbar>
								<div  className="logtxt">
									<img src={shakePeLogo} alt="ShakePe" className="img-fluid" />
									<h3>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_One_Time_Password}</h3>
									<p>{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Please_enter_the_OTP_we_just_sent_on_your_registered_email_address}{this.state.maskEmail}</p>
								</div>

								<p>Reference Number : {this.props.referenceNum}</p>
								<p style={{ color: "#f46a6a", marginTop: "-16px" }}> {this.state.messageValid}</p>

								<div  className="login-form">
									<form onSubmit={this.formSubmit}>
										<div  className="form-row">
											<div  className="form-floating">
												<input
													type="text"
													class="form-control"
													id="otp"
													placeholder="Enter OTP"
													value={this.state.otp}
													onChange={e => { this.setState({ otp: e.target.value }) }}
													required
												/>

												<label for="otp">Enter OTP</label>

											</div>
										</div>


										<div  className="form-row">

											<button type="submit" id="" className="lbtn btnbl"  >{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_One_Time_Password_Submit_Button}</button>

											<button type="button" onClick={this.resenOtp} className=" forgot"> {process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Resend_OTP}</button>

											<a href="/" className="forgot">{process.env.REACT_APP_FORGOT_PASSWORD_VAR_FOR_Back_to_login}</a>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData }, forgotPassword: { referenceNum, email },

}) => ({
	authenticated, userRole, roleData, roMdn, mdn, referenceNum, email

});

export default connect(mapStateToProps)(ResetLoginPassword); 