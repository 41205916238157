
import React from 'react';
import '../../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from '../../../auth/can/Can';
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import { store } from '../../../redux/store';
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import apiCallService from '../../../service/api-call-service';
import Pagination from 'react-js-pagination';
import Select from "react-select";
import SubOrderHistoryPart from './sub-historyDiv.pages';
import moment from 'moment';

var collectedTrueKeys = {
    status: []
}
var ToDateVar = "";
var FromDateVar = "";
class GiftCardHistory extends React.Component {

    constructor() {
        super();

        this.state = {


            loading: false,
            posts: [],
            filteredPost: [],
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_GIFTCARD_ORDER_HISTORY,
            currentPage: 1,
            totalLength: 0,

            currentPost: [],

            bulkButtonShow: false,
            masterChecked: false,

            searchField: '',


            allValuesForFilter: [],
            multiValue: [],
            multiValueLocation: [],
            multiValueForRole: [],
            multiValueForCardStatus: [],
            multiValueForKYCStatus: [],


            checkedPostForAction: [],


            updatedRole: '',
            maxUserAssignedToRole: 0,
            alphaSort: '',
            dateSort: '',
            orderOn: 'dateCreatedOn',
            orderType: 'desc',


            toDate: '',
            fromDate: '',
            orderStatusValue: [],
            deliveryStatusValue: [],
            orderStatusOption: [
                { value: "5", label: "Success" },
                { value: "7", label: "Partial Success" },
                { value: "6,12,13,14", label: "Failed" }
            ],
            deliveryStatusOption: [
                { value: "2", label: "Ordered" },
                { value: "11", label: "Payment" },
                { value: "5", label: "Process" }
            ],

            placeHolder: "...",


        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards History"

        localStorage.setItem('url', '');

        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "",
            adminReportCLass: "",
            manageGiftCardClass: "active"
        }

        store.dispatch(setActiveHeader(payload));


        this.getOrderHistory(collectedTrueKeys, this.state.currentPage, this.state.searchField, this.state.toDate, this.state.fromDate);





    }

    getOrderHistory = async (cTk, page, searchField, toDate, fromDate) => {

        this.setState({ currentPost: [] })

        this.setState({ loading: true })
        // this.setState({ totalLength: 0 })

        var url = process.env.REACT_APP_GET_GIFT_CARD_ORDER_HISTORY_URL;
        var request = {
            "roId": this.props.roMdn,
            "page": page,
            "size": this.state.postsPerPage,
            "searchKeyword": searchField,
            "fromDate": fromDate,
            "toDate": toDate,
            "collectedTrueKeys": cTk
        }

        var res = await apiCallService.postMethodCall(url, request);

        console.log("response getGiftCardOrderHistory", res)

        if (res !== undefined && res.response_code === 0) {
            console.log('response inside fetch post', res);

            this.setState({ totalLength: res.disbursalSize })

            this.setState({ currentPost: res.orderDetailResponses })

            this.setState({ loading: false })
        }

        else {
            this.setState({ currentPost: [] })
            this.setState({ loading: false })
        }


    }

    handleSearchChange = (event) => {
        this.setState({ searchField: event.target.value })
        this.setState({ currentPage: 1 });
        this.getOrderHistory(collectedTrueKeys, 1, event.target.value, this.state.toDate, this.state.fromDate);

    };


    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

        // this.getOrderHistory(pageNumber, this.state.searchField, collectedTrueKeys, this.state.orderOn, this.state.orderType);
        this.getOrderHistory(collectedTrueKeys, pageNumber, this.state.searchField, this.state.toDate, this.state.fromDate);

    };

    handleOrderSattusChange = async (option) => {
        this.setState({ orderStatusValue: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.status = []

        if (option !== null) {
            option.map(op => {
                var value = op.value.split(',');
                value.map(vv => {
                    collectedTrueKeys.status.push(vv)
                })


            })
        }

        this.getOrderHistory(collectedTrueKeys, 1, this.state.searchField, this.state.toDate, this.state.fromDate);

    }

    handleDeliveryStatusChange = async (option) => {
        //s  console.log('option log', option)
        this.setState({ deliveryStatusValue: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.status = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.status.push(op.value)

            })
        }

        this.getOrderHistory(collectedTrueKeys, 1, this.state.searchField, this.state.toDate, this.state.fromDate);

    }



    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");

            this.getOrderHistory(collectedTrueKeys, 1, this.state.searchField, `${formatToDate} 23:59:59`, `${formatFromDate} 00:00:00`);


        }
    }



    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.listData })
        this.setState({ orderStatusValue: [] })
        this.setState({ deliveryStatusValue: [] })

        ToDateVar = '';
        FromDateVar = '';

        this.setState({ allValuesForFilter: [] })
        collectedTrueKeys.status = []
        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)

        this.getOrderHistory(collectedTrueKeys, 1, this.state.searchField, '', '');
    }





    render() {

        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;



        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };




        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.state.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            startdateFromDate = moment(this.state.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.state.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        // var dateFromMax = moment(this.state.toDate);
        // dateFromMax = dateFromMax.format("YYYY-MM-DD");

        console.log("Collected true keys are", collectedTrueKeys);

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>

                                <Can
                                    role={this.props.userRole}
                                    perform="subheader-manageGiftCard:visit"
                                    roleData={this.props.roleData}
                                    yes={() => (
                                        <div className="mid-container">
                                            <div className="container-fluid">

                                                <div className="head">
                                                    <div className="row">
                                                        <div className="col-6 col-md-4 order-md-1">
                                                            <h3>Gift Cards Order History</h3>
                                                        </div>

                                                        <div className="col-6 col-md-4 order-md-3">
                                                            <div className="flterbox">
                                                                <div className="dropdown " data-toggle="tooltip" title="Filter">
                                                                    <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                                    <div className="dropdown-menu dropdown-menu-right">


                                                                        <div className="filters">
                                                                            <label for="select-state">Order Status:</label>

                                                                            <Select

                                                                                name="multiTypeValue"
                                                                                placeholder={this.state.placeHolder}
                                                                                value={this.state.orderStatusValue}
                                                                                options={this.state.orderStatusOption}
                                                                                onChange={this.handleOrderSattusChange}
                                                                                isMulti={true}
                                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                                styles={customStyles}
                                                                            />
                                                                        </div>

                                                                        <div className="filters">
                                                                            <label for="select-state">Delivery Status:</label>

                                                                            <Select

                                                                                name="multiTypeValue"
                                                                                placeholder={this.state.placeHolder}
                                                                                value={this.state.deliveryStatusValue}
                                                                                options={this.state.deliveryStatusOption}
                                                                                onChange={this.handleDeliveryStatusChange}
                                                                                isMulti={true}
                                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                                styles={customStyles}
                                                                            />
                                                                        </div>
                                                                        <div className="filters">
                                                                            <label htmlFor="select-state">From Date:</label>
                                                                            <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.state.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                        </div>

                                                                        <div className="filters">
                                                                            <label htmlFor="select-state">To Date:</label>
                                                                            <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.state.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.handleDateChange} />
                                                                        </div>

                                                                        <div className="fbtn">
                                                                            <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="actnbtn">
                                                                <a data-toggle="tooltip" title="Back" href="/manage-giftCard"><i className="back"></i></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4 order-md-2">
                                                            <div className="searchdata">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" id="empsearch" name="search" placeholder="Search orders..." onChange={this.handleSearchChange} />
                                                                    <button className="btn srchbtn" type="button"></button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="emp-list">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="orderlist" id="giftcardhistory">




                                                                {

                                                                    this.state.loading
                                                                        ?
                                                                        <></>
                                                                        :

                                                                        parseInt(this.state.totalLength) === 0
                                                                            ?
                                                                            <div className="col-12 ">
                                                                                <div className="no-record">
                                                                                    <i></i>
                                                                                    <h3> No Record Available.</h3>
                                                                                </div>
                                                                            </div>
                                                                            :


                                                                            this.state.currentPost.map(data => (
                                                                                <div className="historycard">
                                                                                    <div className="odr-basic" data-bs-toggle="collapse" href={`#odr${data.orderId}`} style={{ cursor: "pointer" }}>
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                                                                                                <div className="cardodrby">
                                                                                                    <i className="giftcard"></i>
                                                                                                    <h5>{data.uploadedByName}</h5>
                                                                                                    <h6>#{data.orderId}</h6>
                                                                                                    <p>{data.creation_date}</p>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-6 col-sm col-lg">
                                                                                                <div className="cardqty">
                                                                                                    <p>Beneficiaries</p>
                                                                                                    <h5>{data.totalBenificiary}</h5>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-6 col-sm col-lg">
                                                                                                <div className="cardqty">
                                                                                                    <p>Quantity</p>
                                                                                                    <h5>{data.totalCardQuantity}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm col-lg">
                                                                                                <div className="cardqty">
                                                                                                    <p>Amount</p>
                                                                                                    <h5>₹{parseFloat(parseFloat(data.totalAmt) / 100).toFixed(2)}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-lg-4">
                                                                                                <div className="order-track">
                                                                                                    <div className="row g-0">
                                                                                                        <div className="col-12">
                                                                                                            <h6>Order Status <span className={data.className}>{data.statusVariable}</span></h6>
                                                                                                        </div>
                                                                                                        <div className="col">
                                                                                                            <div className={data.orderedClass}>
                                                                                                                <i className="dot"></i>
                                                                                                                <i className="line"></i>
                                                                                                                <p>Ordered</p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col">
                                                                                                            <div className={data.paymentClass}>
                                                                                                                <i className="dot"></i>
                                                                                                                <i className="line"></i>
                                                                                                                <p>Payment</p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col">
                                                                                                            <div className={data.deliveredCLass}>
                                                                                                                <i className="dot"></i>
                                                                                                                <i className="line"></i>
                                                                                                                <p>Processed</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <SubOrderHistoryPart data={data} />
                                                                                </div>
                                                                            ))


                                                                }







                                                            </div>
                                                        </div>


                                                        {
                                                            !(parseInt(this.state.totalLength) > parseInt(this.state.postsPerPage))
                                                                ?
                                                                null
                                                                :
                                                                <>
                                                                    <div className="col-12 col-sm-6">
                                                                        <p>  Showing {valueToShow} to {(parseInt(indexOfLastPost) > parseInt(this.state.totalLength)) ? this.state.totalLength : indexOfLastPost} of {this.state.totalLength} entries</p>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="pager">
                                                                            <Pagination
                                                                                activePage={parseInt(this.state.currentPage)}
                                                                                itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                                totalItemsCount={this.state.totalLength}
                                                                                pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                                onChange={this.paginate}
                                                                                className="pagination"
                                                                                linkClass="page-link"
                                                                                itemClass="page-item"
                                                                                nextPageText="Next"
                                                                                prevPageText="Prev"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                    no={() =>
                                        <Redirect to="/access-denied" />
                                    }
                                />







                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser

});

export default connect(mapStateToProps)(GiftCardHistory);