import { RoleActionTypes } from './role.types';

const INITIAL_STATE = {
  roleData: null,
  roleForAdmin:2,
  currentUserRoleName:"",
  huburgVar:false,
  fromRoleStatus:0,

  userMaintain:0
};

const roleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RoleActionTypes.SET_ROLE_DATA:
      return {
        ...state,
        roleData: action.payload
      };
      case RoleActionTypes.RESET_ROLE:
        return INITIAL_STATE;
      case RoleActionTypes.SET_ROLE_FOR_ADMIN_BOOLEAN:
        return {
          ...state,
          roleForAdmin: action.payload
        };
        case RoleActionTypes.SET_CURRENT_USER_ROLE_NAME:
        return {
          ...state,
          currentUserRoleName: action.payload
        };

        case RoleActionTypes.SETHUBURGSHOWHIDE:
          return {
            ...state,
            huburgVar: action.payload
          };
          case RoleActionTypes.SET_FROM_ROLE_STATUS:
            return {
              ...state,
              fromRoleStatus: action.payload
            };

            case RoleActionTypes.SET_USER_MAINTAIN:
            return {
              ...state,
              userMaintain: action.payload
            };
       
    default:
      return state;
  }
};

export default roleReducer;
