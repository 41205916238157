import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import CsvDownload from 'react-json-to-csv'
import CommonFunction from '../../common-function/GenerateRequestId';
import image from "../../assests/user.jpg";
import Avatar from 'react-avatar';
import apiCallService from '../../service/api-call-service';
import Pagination from 'react-js-pagination';
import MoneydisbursalRecordFilterComponent from '../component/moneydisbursal-record-filter.component';

const collectedTrueKeys = {
    band: [],
    category: [],
    responseDescription: []
}
const FailedDisbursal = ({ authenticated, location }) => {

    var arr = [];
    var listForAction = [];

    var multiCategoryRecord = [];
    var multiBandRecord = [];
    var multiStatusRecord = [];

    const [uploadeDetail, setUploadeDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [masterChecked, setMasterChecked] = useState(false);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL_RECORD);
    const [checkedPost, setCheckedPost] = useState([]);
    const [uploadedBy, setUploadedBy] = useState('');
    const [approvedBy, setApprovedBy] = useState('');
    const [uploadedOn, setUploadedOn] = useState('')


    const [multiValueC, setMultiValueC] = useState('')
    const [multiValueB, setMultiValueB] = useState('')
    const [multiValueR, setMultiValueS] = useState('');
    const [filteredRecord, setFilteredRecord] = useState([]);

    const [reasonMultiValue, setReasonMultiValue] = useState([]);

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);


    useEffect(() => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));

        methodToSetUploadList();

    }, []);


    const methodToSetUploadList = async () => {

        if (location.state) {
            const batchId = location.state.data.batchId;
            const responseStatus = location.state.status
            var object = "object"
            var responseArr = responseStatus.split(",");
            console.log('responseArr', responseArr);
            const data = location.state.data;
            console.log('data', data)
            const uploadedBy = location.state.data.name
            const approvedBy = location.state.data.approvedBy
            const uploadedOn = location.state.uploadedOn

            setUploadedBy(uploadedBy);
            setApprovedBy(approvedBy);
            setUploadedOn(uploadedOn);

            //this.setState({ uploadeDetail: data });
            const url = `${process.env.REACT_APP_BULK_DISBERSAL_FETCH_BATCHID_BY_RSPONSE_STATUS}?batchId=${batchId}&responseStatus=${responseArr}`
            console.log('FailedDisbursal url', url);
            var reason = [];
            var response = await apiCallService.getMethodCallWithoutParam(url);
            console.log('data response', response)
            var i = 0;
            if (response !== undefined && response !== null && response.disbursalData !== undefined && response.disbursalData !== null) {
                response.disbursalData.map(corpBand => {
                    var isChecked = 'isChecked';
                    corpBand[isChecked] = false;
                    arr.push(corpBand);

                    reason.push(corpBand.responseDescription);

                    console.log('band list data', corpBand);
                })
                console.log('data response', response)
            }
            var actualMap = []
            var uniq = reason.filter(onlyUnique);
            uniq.map(un => {
                var detail = {
                    value: "",
                    label: ""
                }
                detail.label = un;
                detail.value = un;
                actualMap.push(detail);
            })

            console.log('band list data', actualMap)
            setUploadeDetail(arr);
            setFilteredRecord(arr);
            setReasonMultiValue(actualMap);

        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;



    const handleMultiCategorychange = (option) => {
        // ((bandListToView === null || !(bandListToView.length && bandListToView)
        setMultiValueC(option)
        setCurrentPage(1)
        multiCategoryRecord = option;
        var lis = []
        if ((multiCategoryRecord === null || !(multiCategoryRecord.length && multiCategoryRecord))) {
            console.log('In last Min Call')
            uploadeDetail.map(bat => {
                lis.push(bat)
            })
        } else if (multiCategoryRecord !== null && multiCategoryRecord.length !== 0) {
            multiCategoryRecord.map(rec => {
                uploadeDetail.map(fil => {
                    var cat = rec.value.toLowerCase();
                    console.log('matched', fil[cat] / 100 > 0);
                    if (fil[cat] / 100 > 0) {
                        console.log('matched')
                        lis.push(fil);
                    }
                })
            })

        }
        setFilteredRecord(lis);
    }
    const handleMultiBandchange = (option) => {
        //s  console.log('option log', option)
        setMultiValueB(option)

        setCurrentPage(1)
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.band = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.band.push(op.label);
            })
        }

    }
    const handleStatuschange = (option) => {
        //s  console.log('option log', option)
        setMultiValueS(option)
        setCurrentPage(1)
        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.responseDescription = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.responseDescription.push(op.label);
            })
        }

    }


    const clearAll = () => {
        setMultiValueS([])
        setMultiValueB([])
        setMultiValueC([])

        setFilteredRecord(uploadeDetail)


        collectedTrueKeys.responseDescription = []
        collectedTrueKeys.category = []
        collectedTrueKeys.band = [];

        multiCategoryRecord = []

        setAllValuesForFilter([])
    }

    const handleMasterChecked = (event) => {
        setMasterChecked(event.target.checked);
        filteredRecord.map(posts => {
            console.log('inside if handleMasterChecked', posts);
            if (event.target.checked) {
                posts.isChecked = event.target.checked;
                listForAction.push(posts)
            } else if (!event.target.checked) {
                console.log('inside else handleMasterChecked');
                posts.isChecked = event.target.checked;
                listForAction.pop(posts)
            }
        })
        setCheckedPost(currentPost);

    }

    const handleChecked = (event) => {
        var data = listForAction
        console.log(data.lenght, "and", postsPerPage)
        if (data.lenght !== postsPerPage) {
            setMasterChecked(false)
        }
        data.map(list => {
            listForAction.push(list);
        })
        filteredRecord.map(post => {
            console.log('post.emp_id === event.target.id && event.target.checked === true', post.band === event.target.name && event.target.checked === false)
            if (post.requestId === event.target.id && event.target.checked === true) {
                console.log('inside if handleChecked');
                console.log('post to be performed action is : ', post)
                post.isChecked = event.target.checked
                listForAction.push(post)
                console.log('list to action performed correct', listForAction);
            } else if (post.requestId === event.target.id && event.target.checked === false) {
                console.log('removed post from action performed', post)
                listForAction.pop(post)
                setMasterChecked(false)
                post.isChecked = event.target.checked
                console.log('list to action performed wrong', listForAction);
            }
        })
        setCheckedPost(currentPost)
    }


    const exportFileInOne = () => {
        console.log(currentPost, 'its hitted')

        currentPost.map(post => {

        })
    }

    //Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    console.log('list aagyi action m shi h ya glt', listForAction);


    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };



    var filteredPostData = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



    }

    const key = 'transactionId';

    const filteredPost = [...new Map(filteredPostData.map(item =>
        [item[key], item])).values()];







    if (filteredPost === null || filteredPost === undefined) {
        currentPost = null
    } else {
        currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
    }


    console.log('currentpost', currentPost);
    console.log('filteredPost', filteredPost);

    var fileToExport = [];
    filteredPost.map(data => {
        var obj = {
            Employee_Name: "",
            Employee_Id: "",
            Mobile_No: "",
            Band: "",
            General: "",
            Food_and_Meal: "",
            Travel: "",
            Transaction_ID: "",
            Reason: ""
        }
        if (data.isChecked) {
            obj.Employee_Name = data.empName;
            obj.Employee_Id = data.empId;
            obj.Mobile_No = data.mdn;
            obj.Band = data.band;
            obj.General = data.genral / 100;
            obj.Food_and_Meal = data.food / 100;
            obj.Travel = data.travel / 100;
            obj.Transaction_ID = data.transactionId;
            obj.Reason = data.responseDescription
            fileToExport.push(obj);
        } else {
            // obj.Employee_Name = data.empName;
            // obj.Employee_Id = data.empId;
            // obj.Mobile_No = data.mdn;
            // obj.Band = data.band;
            // obj.General = data.genral / 100;
            // obj.Food_and_Meal = data.food / 100;
            // obj.Travel = data.travel / 100;
            // obj.Transaction_ID = data.transactionId;
            // obj.Reason = data.responseDescription
            // fileToExport.pop(obj);
        }

    })

    var detail = false;

    currentPost.map(pos => {
        if (pos.isChecked) {
            detail = true
        }
    })


    return (<div >
        {(authenticated)
            ? (
                <div className="mid-container" id="heightFix">
                    <div className="container-fluid">



                        <MoneydisbursalRecordFilterComponent clearAll={clearAll} handleMultiBandchange={handleMultiBandchange} handleMultiCategorychange={handleMultiCategorychange} handleStatuschange={handleStatuschange}
                            multiValueB={multiValueB} multiValueC={multiValueC} multiValueR={multiValueR} type="checker" success="success" reasonMultiValue={reasonMultiValue} varForHeader="Failed Money Disbursal" />

                        
                        
                        <div className="emp-list">
                            <div className="row">

                                <div className="col-12">
                                    <div className="upload-failed">{location.state.data.isBulk === 1 ? <>Single disbursal by</> : <>Uploaded by</>} {uploadedBy} on {uploadedOn}</div>
                                </div>

                                <div className="col-12">
                                    <div className="table-responsive disbrslist">
                                        {currentPost.length > 0
                                            ?
                                            <table className="table table-hover table-bordered">
                                                <tbody>
                                                    <tr className='table-primary'>
                                                        <th>

                                                            <input type="checkbox" id="" checked={masterChecked} onClick={handleMasterChecked} />

                                                        </th>
                                                        <th>Employee Name & ID</th>
                                                        <th>Mobile No.</th>
                                                        <th>Band</th>
                                                        <th>General</th>
                                                        <th>Food</th>
                                                        <th>Travel</th>
                                                        <th>Transaction ID</th>
                                                        <th>Reason</th>
                                                    </tr>


                                                    {currentPost.map(detail => (
                                                        <tr>
                                                            <td>
                                                                <label className="">
                                                                    <input type="checkbox" id={detail.requestId} className="" checked={detail.isChecked} onClick={handleChecked} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                {/* <img src={image} alt="" className="userimg" />  */}

                                                                <Avatar color={Avatar.getRandomColor("109", ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={"109"} name={`${CommonFunction.Capitalize(detail.empName)}`} size="40px" round={true} />


                                                                {detail.empName === null || detail.empName === "null" ? <>NA</> : detail.empName} <br />{detail.empId === null ? <>NA</> : detail.empId} </td>
                                                            <td>{detail.mdn === null ? <>NA</> : detail.mdn}</td>
                                                            <td>{detail.band === null || detail.band === "null" ? <>NA</> : detail.band}</td>
                                                            <td> {detail.genral === "" ? <> - </> : <>₹ {isNaN((detail.genral)) ? detail.genral : ((detail.genral) * 10) / 1000} </>}</td>
                                                            <td> {detail.food === "" ? <> - </> : <>₹ {isNaN((detail.food)) ? detail.food : ((detail.food) * 10) / 1000} </>}</td>
                                                            <td> {detail.travel === "" ? <> - </> : <>₹ {isNaN((detail.travel)) ? detail.travel : ((detail.travel) * 10) / 1000} </>}</td>
                                                            <td>{detail.transactionId}</td>
                                                            <td>{detail.responseDescription}</td>
                                                        </tr>
                                                    ))



                                                    }

                                                </tbody>
                                            </table>
                                            :
                                            <div className="col-12">
                                                <div className="no-record">
                                                    <i></i>
                                                    <h3> No Record Available.</h3>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {parseInt(filteredPost.length) > parseInt(postsPerPage)
                                    ?
                                    <>
                                        <div className="col-12 col-sm-6">
                                            <p>
                                                {!detail ? <></> :
                                                    <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"disbursal-detail.csv"}>Export to CSV</CsvDownload>
                                                }
                                                Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <Pagination
                                                    activePage={parseInt(currentPage)}
                                                    itemsCountPerPage={parseInt(postsPerPage)}
                                                    totalItemsCount={filteredPost.length}
                                                    pageRangeDisplayed={parseInt(postsPerPage)}
                                                    onChange={paginate}
                                                    className="pagination"
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    nextPageText="Next"
                                                    prevPageText="Prev"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="col-12 col-sm-6">
                                        <p>
                                            {!detail ? <></> :
                                                <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"disbursal-detail.csv"}>Export to CSV</CsvDownload>
                                            }
                                        </p>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>

            )
            : (
                <Redirect to='/' />
            )


        }

    </div>

    )

}


const mapStateToProps = ({ token: { authenticated } }) => ({
    authenticated
});

export default connect(mapStateToProps)(FailedDisbursal);