import React from 'react';
import '../css/filter-styles.scss';
import Select from "react-select";
import { connect } from "react-redux";
import { store } from '../../redux/store';
import { setBandListToView } from '../../redux/band/band.action'
import { setLocationListToView } from '../../redux/location/location.action'
import { setCardStatusListToView } from '../../redux/kyc/kyc.action'
import { setKycListToView } from '../../redux/kyc/kyc.action'
import { setMasterRoleToView } from '../../redux/kyc/kyc.action'
import SmallPopUp from "../../common/component/small.popup.component";
import Can from '../../auth/can/Can';
import AssignRole from '../pages/sub-assign-user';
import ReactTooltip from 'react-tooltip';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';

import SmallPopUpTempBlockReason from '../../common/component/small.popup.componentempReason';

class FilterComponent extends React.Component {
	constructor(props) {
		super();
		this.state = {
			multiValue: [],
			multiValueLocation: [],
			multiValueForRole: [],
			multiValueForCardStatus: [],
			multiValueForKYCStatus: [],
			placeHolder: '...',
			isChecked: ''
		};
		this.handleMultiChange = this.handleMultiChange.bind(this);
	}


	handleMultiChange(option) {
		this.setState({ placeHolder: '' })
		console.log('multivalue', option)
		store.dispatch(setBandListToView(option));
		console.log(store)
		this.setState(state => {
			return {
				multiValue: option
			};
		});

	}

	handleMultiChangeLocation = (option) => {
		this.setState({ placeHolder: '' })
		console.log('multivalue', option)
		store.dispatch(setLocationListToView(option));
		console.log(store)
		this.setState(state => {
			return {
				multiValueLocation: option
			};
		});

	}

	handleMultiChangeRole = (option) => {
		this.setState({ placeHolder: '' })
		console.log('multivalue', option)
		store.dispatch(setMasterRoleToView(option));
		console.log(store)
		this.setState(state => {
			return {
				multiValueForRole: option
			};
		});

	}

	handleMultiChangeCardStatus = (option) => {
		this.setState({ placeHolder: '' })
		console.log('multivalue', option)
		store.dispatch(setCardStatusListToView(option));
		console.log(store)
		this.setState(state => {
			return {
				multiValueForCardStatus: option
			};
		});

	}

	handleMultiChangeKyc = (option) => {
		this.setState({ placeHolder: '' })
		console.log('multivalue', option)
		store.dispatch(setKycListToView(option));
		console.log(store)
		this.setState(state => {
			return {
				multiValueForKYCStatus: option
			};
		});

	}

	clearAllFilter = () => {
		store.dispatch(setBandListToView(''));
		store.dispatch(setLocationListToView(''));
		store.dispatch(setMasterRoleToView(''));
		store.dispatch(setCardStatusListToView(''));
		store.dispatch(setKycListToView(''));
		this.setState(state => {
			return {
				multiValue: [],
				multiValueLocation: [],
				multiValueForRole: [],
				multiValueForCardStatus: [],
				multiValueForKYCStatus: [],
				placeHolder: '...'
			};
		});

	}

	handleRemoveRoleChecked = () => {

	}

	handleChecked = () => {

	}

	clickToSendNextPort = async () => {
		const requestData = {
			mdn: "finalmdnData",
			name: "saruabh",
			email: "saruabh@gmail.com",
			gender: "Male",
			dob: "10-10-2020",
			address: "8th Floor, c/o COOFFIZ, Magnum Towers, Golf Course Extension, Sector 58, Gurugram - 122011, Haryana",
			cardRefNumber: "OEMS1234567890123455",
			role: "Corporate Admin",
			pan: "pan",
			form_60_flag: "form_60_flag",
			agriculture_income: "agriculture_income",
			callBackURl: 'http://localhost:3000/submitKycData',
			non_agriculture_income: "non_agriculture_income"
		}


		// var response = await apiCallService.postMethodCall("http://localhost:3001/getData",requestData);


		var request = JSON.stringify(requestData);

		var CryptoJS = require("crypto-js");
		var key = process.env.REACT_APP_ncryptionKey;
		var encryptedValue = CryptoJS.AES.encrypt(request, key);

		console.log("Encrypted value is", encryptedValue);


		window.location.replace(`http://localhost:3001/getData?value=${encryptedValue}`);

		// console.log("response",response);


	}


	render() {
		const customStyles = {
			control: (base, state) => ({
				...base,
				'border': '1px solid #ddd',
				'borderRadius': '0',
				'boxSizing': 'content-box',
				'minHeight': '30px',
				'lineHeight': '18px',
				'display': 'flex'
			}),

			valueContainer: (base) => ({
				...base,
				'position': 'relative',
				'overflow': 'hidden',
				'margin': '0',
				'padding': '3px',
				'width': '100%',
				'minHeight': '30px',
				// 'border': '1px solid #ddd',
				'backgroundColor': '#fff',
				'lineHeight': '18px',
				'cursor': 'text',
				'alignItems': 'center'
			}),
			multiValue: base => ({
				...base,
				'boxSizing': 'content-box',
				'position': 'relative',
				'margin': '1px 5px 1px 0px',
				'padding': '1px 2px 3px 5px',
				'border': '1px solid #c4d5e1',
				'lineHeight': '18px',
				'maxWidth': '100%',
				'borderRadius': '3px',
				'backgroundColor': '#d6eeff',
				'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
				'color': '#4a546c',
				'lineHeight': '16px',
				'cursor': 'default',
				'height': '20px'
			}),
			menu: (base) => ({
				...base,
				'padding': '2px 5px',
				'lineHeight': '16px',

			}),
			input: base => ({
				...base,
				'height': '18px',
				'lineHeight': '18px'
			})

		};


		var singleCheck = false
		console.log("this.props.currentPost", this.props.currentPost)
		this.props.currentPost !== undefined && this.props.currentPost.map(post => {
			if (post.isChecked) {
				singleCheck = true;
			}
		})
		if (this.props.masterChecked === false && singleCheck === false) {
			localStorage.setItem('listForAction', '');
		}



		var isCardActionHide = false;

		this.props.currentPost !== undefined && this.props.currentPost.map(post => {


			console.log("shoe tem and perm", post.isChecked, post.card !== null, post.card.length > 1)

			if (post.isChecked && post.card !== null && post.card.length > 1) {
				isCardActionHide = true;
			}
		})






		return (
			<>
				<div className="head">
					<div className='row'>
						<div  className="col-6 col-md-4 order-md-1">
							<h3 >
								<input type="checkbox" className="checkall" onClick={this.props.handleMasterChecked} checked={this.props.masterChecked} data-toggle="tooltip" title="Select All" />
								{process.env.React_APP_Var_FOR_ManageEmployee_Page_ManageEmployee}
							</h3>
						</div>
						<div  className="col-6 col-md-4 order-md-3">

							<div className="flterbox">

								<div className="dropdown">

									<button className="fltrbtn dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"></button>


									<div className="dropdown-menu dropdown-menu-end" >

										<div className="filters">
											<label >Band:</label>
											<Select

												name="filters"
												placeholder={this.props.placeHolder}
												value={this.props.multiValue}
												options={this.props.bandData}
												onChange={this.props.handleMultiChange}
												isMulti={true}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
												styles={customStyles}
											/>


										</div>

										<div className="filters">
											<label htmlFor="select-state">Location:</label>
											<Select

												name="filters"
												placeholder={this.props.placeHolder}
												value={this.props.multiValueLocation}
												options={this.props.locationData}
												onChange={this.props.handleMultiChangeLocation}
												isMulti={true}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
												styles={customStyles}
											/>

										</div>

										<div className="filters">
											<label htmlFor="select-state">Card Status:</label>

											<Select
												name="filters"
												placeholder={this.props.placeHolder}
												value={this.props.multiValueForCardStatus}
												options={this.props.cardStatusData}
												onChange={this.props.handleMultiChangeCardStatus}
												isMulti={true}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
												styles={customStyles}
											/>



										</div>

										<div className="filters">
											<label htmlFor="select-state">Role:</label>
											<Select
												name="filters"
												placeholder={this.props.placeHolder}
												value={this.props.multiValueForRole}
												options={this.props.masterRoleData}
												onChange={this.props.handleMultiChangeRole}
												isMulti={true}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
												styles={customStyles}
											/>


										</div>

										<div className="filters">
											<label htmlFor="select-state">KYC Status:</label>
											<Select
												name="filters"
												placeholder={this.props.placeHolder}
												value={this.props.multiValueForKYCStatus}
												options={this.props.kycData}
												onChange={this.props.handleMultiChangeKyc}
												isMulti={true}
												components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
												styles={customStyles}
											/>

										</div>

										<div className="fbtn">
											<button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.props.clearAllFilter}>Clear all</button>
											<button className={this.props.allValueFilter === null || this.props.allValueFilter.length === 0 ? "sbtn btngy" : "sbtn btnbl"} style={{ marginLeft: "20px" }} onClick={this.props.apply} type="reset" id="btnClear" disabled={this.props.allValueFilter === null || this.props.allValueFilter.length === 0}>Apply</button>
										</div>

									</div>

								</div>


							</div>


							<div className="actnbtn">


								{


									this.props.loading
										?
										null
										:

										this.props.masterChecked || singleCheck
											?
											<>


												<Can
													role={this.props.userRole}
													perform="empmng-page:bulkUpdateRole"
													roleData={this.props.roleData}
													yes={() => (
														<div className="dropdown roleAction" data-for="back" data-tip="Assign Role" data-iscapture="true" >

															<button type="button" data-bs-toggle="dropdown" title="Assign Role"><i  className="role"></i></button>
															<div className="dropdown-menu">
																{
																	this.props.masterRoleData.map(role => (

																		<div className="role-list">



																			<AssignRole updatedRole={this.props.updatedRole} role={role} updateRoleChangeHandler={this.props.updateRoleChangeHandler} type="filter" />
																		</div>

																	))

																}
																<div className="role-list">


																	<div  className="form-check">
																		<input className="form-check-input" type="radio" id="role5" name="removeRole/0" value={this.props.updatedRole} checked={this.props.updatedRole === "removeRole"} onClick={this.props.updateRoleChangeHandler} />
																		<label  className="form-check-label" for="role5">Remove Role</label>
																	</div>

																</div>
																<div className="role-list">
																	<button type="button" className="sbtn btnbl" onClick={this.props.handleUpdateRoleButton} disabled={this.props.updatedRole === ''}>Ok</button>
																</div>
															</div>

														</div>

													)}
													no={() =>
														null
													}
												/>
												{
													isCardActionHide
														?
														null
														:
														<>
															<Can
																role={this.props.userRole}
																perform="empmng-page:bulkTempBlock"
																roleData={this.props.roleData}
																yes={() => (

																	<button type="button" data-toggle="tooltip" data-bs-toggle="modal" data-bs-target="#tempBlock-employee" title="Temporary Block"><i  className="tblock"></i></button>


																)}
																no={() =>
																	null
																}
															/>
															<Can
																role={this.props.userRole}
																perform="empmng-page:bulkPermBlock"
																roleData={this.props.roleData}
																yes={() => (

																	<button type="button" data-toggle="tooltip" data-bs-toggle="modal" data-bs-target="#permtBlock-employee" title="Permanent Block"><i  className="pblock"></i></button>

																)}
																no={() =>
																	null
																}
															/>
														</>
												}
												<Can
													role={this.props.userRole}
													perform="empmng-page:bulkDeleteEmp"
													roleData={this.props.roleData}
													yes={() => (

														<button type="button" data-toggle="tooltip" data-bs-toggle="modal" data-bs-target="#delete-employee" title="Remove Profile"><i  className="delete"></i></button>


													)}
													no={() =>
														null
													}
												/>

											</>
											:
											<>


											</>
								}
							</div>




						</div>

						<div  className="col-12 col-md-4 order-md-2">
							<div  className="searchdata">
								<div  className="input-group">

									<input type="text" id="empsearch" className='form-control' name="search" placeholder={process.env.REACT_APP_Var_FOR_MANAGE_EMPLOYEE_SEARCH_BOX_PLACEHOLDER} onChange={this.props.handleSearchChange} />
									<button  className="btn srchbtn" type="button" onClick={this.props.handleSearchChange}></button>
								</div>
							</div>


						</div>
					</div>





				</div >

				<SmallPopUp
					targetid="delete-employee"
					headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Lable}
					bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Body}
					onclickFunctionName={this.props.handleRemoveEmployee}
					buttonActionName={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Remove_Button}
					buttonActionClose={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Cancel_Button}
				></SmallPopUp>

				<SmallPopUpTempBlockReason
					targetid="tempBlock-employee"
					headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_PopUp_Lable}
					bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_PopUp_Body}
					onclickFunctionName={this.props.handleTempBlock}
					buttonActionName='Block'
					buttonActionClose="Cancel"

				></SmallPopUpTempBlockReason>
				<SmallPopUp
					targetid="permtBlock-employee"
					headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Lable}
					bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Body}
					onclickFunctionName={this.props.handlePermanentBlock}
					buttonActionName='Block'
					buttonActionClose="Cancel"

				></SmallPopUp>
			</>


		)
	}

}


const mapStateToProps = ({ band: { bandData }, location: { locationData }, kyc: { kycData }, kyc: { masterRoleData }, kyc: { cardStatusData } }) => ({
	bandData,
	locationData,
	cardStatusData,
	kycData,
	masterRoleData
});


export default connect(mapStateToProps)(FilterComponent);