


import React, { useState, useEffect } from 'react';

import '../../employee/css/employee.css';
import "../css/dropdown.styles.css";
import CardStatus from '../../common/component/card-status.component';
import ApiCallingService from '../../service/api-call-service';
import SmallPopUp from "../../common/component/small.popup.component";

import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import CommonFunction from '../../common-function/GenerateRequestId';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setMessageToBeShown } from '../../redux/message/message.action';
import ApiCalling from '../../service/api-call-service';
import history from '../../auth/history';

import GenerateRequestId from '../../common-function/GenerateRequestId';

import moment from "moment";
import CardStatusType from '../../constants/card-status.type';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import VerifyDisburse from '../component/VerifyDisburse.component'
import apiCallService from '../../service/api-call-service';

import CategoryLimit from '../../constants/category-limit.type';
import Can from '../../auth/can/Can';

import AssignRole from './sub-assign-user';
import CreatableSelect from 'react-select/creatable';
import { setEmployeeDetails } from "../../redux/user/user.actions";
import img from '../../assests/icons/arrow-down.svg'

import Avatar from 'react-avatar';
import KycStatus from '../../constants/kyc-status.type';

import SubBalance from './sub-balance.page';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

import EmpDetailLoadingComponent from './empDetail.loader';


import AvailCardDiv from './availCard.employee';
import UploadImage from '../component/upload-image.component';

import ActivateCardComponet from './activate-card.component';
import { annualIncome, educationDetails, natureOfBusiness, professionalDetails, purposeofIssuingcard, selfEmployedProfession, sourceOfFund } from '../../constants/professional-details';

var roleStatustoSend = 1;
var currentUserT = '';
var ro_id = ''
var fullNameN = ''
const EmployeeDetail = (props) => {
	console.log('Ro Mdn', props.roMdn);
	ro_id = props.roMdn
	var resp = [];
	currentUserT = props.currentUser
	fullNameN = props.fullName
	//var arr = {};
	const resultSet = {
		emp_id: '', mdn: '', fullName: '', first_name: '', last_name: '', gender: '', email: '', pan: '', aadhar_no: '', createdBy: '', roleName: '',
		band: '', department: '', location: '', pincode: '', dob: '', date_of_joining: '', card_ref_no: '', card_staus: '', manager_emp_corp_id: '',
		is_paper_kyc: '', finance_emp_corp_id: '', roMdn: '', type: '', roleName: '', modifyBy: '', address: '', wallet: [], card: []
	}
	const balanceSet = {
		user: { username: '', wallet_level: '' }, Balance: {
			sub_wallet_balance: [{ balance: '', wallet: '', lean: '', name: '', iconUrl: '' }, { balance: '', wallet: '', lean: '', name: '', iconUrl: '' }],
			total_balance: '', monthly_available_limit: '', monthly_consumed_limit: '', annual_available_limit: '', annual_consumed_limit: '', holding_available_limit: '',
			annual_food_available_limit: '', annual_food_consumed_limit: '', total_lien: ''
		}, transaction_data: [{ transaction_RefNumber: '' }]
	}

	const [employeeDetail, setEmployeeDetail] = useState(resultSet);
	const [loading, setLoading] = useState(false);
	const [balanceEnquiry, setBalanceEnquiry] = useState(balanceSet);
	const [editShowHidden, setEditShowHidden] = useState(true);
	const [male, setMale] = useState(false);
	const [female, setFemale] = useState(false);
	const [other, setOther] = useState(false);
	const [severty, setSeverty] = useState('success');
	const [roleName, setRoleName] = useState('');

	const [kyc_status, setKycStatus] = useState(1);
	const [kycExpireDate, setKycExpiryDate] = useState("");


	const [nullEmailCheck, setNullEmailCheck] = useState(false)
	const [nullLocationCheck, setNullLocationCheck] = useState(false)
	const [nullPincodeCheck, setNullPincodeCheck] = useState(false)
	const [previousEmail, setPreviousEmail] = useState('');
	const [popupverifydisburse, setpopupverifydisburse] = useState('');
	const [otp, setOtp] = useState('');
	const [password, setPassword] = useState('');
	const [travelAmount, setTravelAmount] = useState(0.00);
	const [foodAmount, setFoodAmount] = useState(0.00);
	const [generalAmount, setgeneralAmount] = useState(0.00);
	const [referenceNumber, setReferenceNumber] = useState('');
	const [modal, setModal] = useState('');
	const [close, setClose] = useState('');
	const [lasttxndate, setLasttxndate] = useState('');
	const [walletArray, setWalletArray] = useState([]);
	const [checkLimit, setCheckLimit] = useState(true)
	const [time, setTime] = useState(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT));
	const [triggeretime, setTriggeretime] = useState(true);
	const [maxUserAssignedToRole, setMaxUserAssignedToRole] = useState(0);

	const [prevRole, setPrevRole] = useState('')

	const [option, setOption] = useState([]);
	
	const [education_qualification, setEducation_qualification] = useState({label:'', value:''})
	const [source_of_fund, setSource_of_fund] = useState({label:'', value:''})
	const [Self_employed_profession, setSelf_employed_profession] = useState({label:'', value:''})
	const [nature_of_business, setNature_of_business] = useState({label:'', value:''})
	const [employee_income, setEmployee_income] = useState({label:'', value:''})
	const [purposeofcard, setPurposeofcard] = useState({label:'', value:''})
	const [employee_worktype, setEmployee_worktype] = useState({label:'', value:''})

	const [allEmp, setAllEmp] = useState([]);
	const [searchVarForReportingMgn, setSearchVarForReportingMgn] = useState('');
	const [, setShowReportMangEMpSection] = useState(false);

	const [, setShowFinEMpSection] = useState(false);
	const [searchVarForFinAdmin, setSearchVarForFinAdmin] = useState('');


	const [pattern, setPattern] = useState('[1-9]{1}[0-9]{1}*{8}[0-9]{2}')

	const [updateEMployeeProcessBar, setUpdateEMployeeProcessBar] = useState(false);



	const [updateRoleProcessBar, setUpdateRoleProcessBar] = useState(false);


	const [loadingComponent, setLoadingComponent] = useState(false);

	const [updateProfileProcessBar, setUpdateProfileProcessBar] = useState(false);


	useEffect(() => {
		//var mdn = props.location.state.employee.mdn;
		var payload = {
			dashBoardClass: "",
			manageEmployeeClass: "active",
			moneyDisbursalClass: "",
			manageCardClass: "",
			manageLimitClass: "",
			activityClass: ""
		}

		store.dispatch(setActiveHeader(payload));
		localStorage.setItem('url', '');
		document.title = "ShakePe Employee Profile"





		const fetchEmployeeDetail = async () => {
			console.log('prps location', props.location.state);
			setLoadingComponent(true);

			if (props.location.state) {
				console.log("fetchEmployeeDetail", props.location.state);
				// var detail = props.location.state.employee;





				// const url = process.env.REACT_APP_GET_EMPLOYEE_BY_EMAIL
				const url = process.env.REACT_APP_GET_EMPLOYEE_BY_MDN_AND_STATUS;
				const res = await ApiCallingService.getMethodCallWithParam(url, { 'mdn': props.empDetail.mdn, 'status': 0 });



				console.log("Employee Detail Response Fo $$%5556%^^^", res);

				if (res !== undefined && res.status !== undefined && parseInt(res.status) === 0 && res.response !== undefined && res.response.employeeDetails !== undefined) {
					setLoadingComponent(false)
					var detail = res.response.employeeDetails;


					res.response.employeeDetails.card.map(dd => {
						setKycExpiryDate(dd.expiryDate);
						setKycStatus(dd.kycStatus);
					})

					console.log('prps location empDetail', props.empDetail);
					var dateOfBirth = moment(detail.dob).format("YYYY-MM-DD");
					detail.dob = dateOfBirth;
					var dateOfJoining = ''
					if (detail.date_of_joining !== undefined && detail.date_of_joining !== null) {
						dateOfJoining = detail.date_of_joining.split(' ');
						detail.date_of_joining = dateOfJoining[0]
					}
					var walletDetails = props.empDetail.wallet;
					var wallet = [];
					var arrayForGeneralAmount = [];
					var arrayForTravelAmount = [];
					var arrayForFoodAmount = [];

					arrayForGeneralAmount.push(props.maxGeneralAmount / 100);
					arrayForTravelAmount.push(props.maxTravelAmount / 100);
					arrayForFoodAmount.push(props.maxFoodAmount / 100);

					props.corpBand.map(band => {
						if (band.band === detail.band) {
							arrayForGeneralAmount.push(band.general_Limit / 100);
							arrayForTravelAmount.push(band.travel_Limit / 100);
							arrayForFoodAmount.push(band.wallet_food_limit / 100);
						}
					})
					console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount ", arrayForGeneralAmount, arrayForTravelAmount, arrayForFoodAmount)

					walletDetails.map(limit => {
						if (limit.wallet_id === parseInt(CategoryLimit.GENERAL)) {
							console.log('Inside maxFoodAmount')
							arrayForGeneralAmount.push(limit.amount / 100);
						}
						if (limit.wallet_id === parseInt(CategoryLimit.TRAVEL)) {
							arrayForTravelAmount.push(limit.amount / 100);
						}
						if (limit.wallet_id === parseInt(CategoryLimit.FOOD)) {
							arrayForFoodAmount.push(limit.amount / 100);
						}
					})



					arrayForGeneralAmount = arrayForGeneralAmount.filter(function (val) {
						return val !== 0;
					});
					arrayForTravelAmount = arrayForTravelAmount.filter(function (val) {
						return val !== 0;
					});
					arrayForFoodAmount = arrayForFoodAmount.filter(function (val) {
						return val !== 0;
					});


					console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount before 0 remove", arrayForGeneralAmount, arrayForTravelAmount, arrayForFoodAmount)

					var maxGeneralAmount = Math.min(...arrayForGeneralAmount);
					var maxTravelAmount = Math.min(...arrayForTravelAmount);
					var maxFoodAmount = Math.min(...arrayForFoodAmount);

					console.log(" maxGeneralAmount maxTravelAmount maxFoodAmount ", maxFoodAmount, maxTravelAmount, maxFoodAmount)
					walletDetails.map(wal => {
						var label = 'label';
						var max = 'max';

						if (wal.wallet_id === parseInt(CategoryLimit.GENERAL)) {
							console.log(' wallet detail GENERAL', wallet)
							wal[label] = 'General';
							wal[max] = maxGeneralAmount;
							wallet.push(wal);
						}
						if (wal.wallet_id === parseInt(CategoryLimit.FOOD)) {
							console.log(' wallet detail FOOD', wallet)
							wal[label] = 'Food';
							wal[max] = maxFoodAmount;
							wallet.push(wal);
						}
						if (wal.wallet_id === parseInt(CategoryLimit.TRAVEL)) {
							console.log(' wallet detail TRAVEL', wallet)
							wal[label] = 'Travel'
							wal[max] = maxTravelAmount;
							wallet.push(wal);
						}

					})
					const editShowHiddenProp = props.location.state.editShowHidden;
					console.log("editShowHiddenProp", editShowHiddenProp);
					setEditShowHidden(editShowHiddenProp)
					setPreviousEmail(detail.email);
					console.log(' wallet detail', walletDetails)
					if (detail.gender !== null && detail.gender.toLowerCase() === 'male') {
						setMale(true);
					} else if (detail.gender !== null && detail.gender.toLowerCase() === 'female') {
						setFemale(true);
					} else {
						setOther(true);
					}
					if(detail?.education_qualification !== undefined){
						setEducation_qualification({label: detail?.education_qualification, value: detail?.education_qualification })
					}
					if(detail?.source_of_fund !== undefined){
						console.log('detail1', detail?.source_of_fund)
						//label: detail?.source_of_fund, value: detail?.source_of_fund
						setSource_of_fund({label: detail?.source_of_fund, value: detail?.source_of_fund})
					}
					if(detail?.self_employed_profession !== undefined){
					
						setSelf_employed_profession({label: detail?.self_employed_profession, value: detail?.self_employed_profession})
					}
					if(detail?.nature_of_business !== undefined){
						// {label: detail?.nature_of_business, value: detail?.nature_of_business}
						setNature_of_business({label: detail?.nature_of_business, value: detail?.nature_of_business})
					}
					if(detail?.annualIncome !== undefined){
						
						setEmployee_income({label: detail?.annualIncome, value: detail?.annualIncome})
					}
					if(detail?.profession !== undefined){
					
						setEmployee_worktype({label: detail?.profession, value: detail?.profession})
					}
					if(detail?.cardIssuePurpose !== undefined){
						
						setPurposeofcard({label: detail?.cardIssuePurpose, value: detail?.cardIssuePurpose})
					}


					setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...detail }));
					setSearchVarForReportingMgn(detail.manager_emp_corp_id)
					setSearchVarForFinAdmin(detail.finance_emp_corp_id)
					setShowReportMangEMpSection(true);
					setShowFinEMpSection(true);
					console.log('roleName', detail.roleName)
					setRoleName(detail.roleName);
					setPrevRole(detail.roleName)
					setWalletArray(wallet);
					console.log('roleName', roleName)
					//setRoleName(rol);
					fetchEmployeeList();

				} else {
					setLoadingComponent(false);
				}




			}
			else {
				setEmployeeDetail(prevEmployeeDetail => ({ ...prevEmployeeDetail, ...null }));
			}

		}








		const fetchEmployeeList = async () => {
			const url = process.env.REACT_APP_GET_EMPLOYEE_BY_ROID
			console.log(url);
			const res = await apiCallService.getMethodCallWithParam(url, { 'ro_Id': props.roMdn });
			console.log('fetchEmployeeList', res)
			if (res !== undefined && res.response !== undefined && res.status === 0 && res.response.employeeDetails !== undefined) {
				setAllEmp(res.response.employeeDetails);
			}
		}


		const fetchBalanceDetail = async () => {
			setLoading(true);
			//resp =await BalanceEnquiryService.balanceEnquiry('http://172.160.0.249:7072/api/wallet/BalanceEnquiry', '919887835909', "")
			console.log('fetch blance enquiry', resp);
			console.log('fetch blance loading...', loading);
			const request = {
				device_info: {
					api_version: "1.0",
					app_version: "9.2",
					device_id: "d215ab007e05c498",
					device_os: "Android",
					device_os_version: "5.1.1",
					imei: "357869081907005",
					ip_address: "172.160.2.140",
					model_name: "SM-J320F",
					source: "mobile",
				},
				request: {
					user: {
						username: props.empDetail.mdn
					},
					transaction_data: {
						request_id: GenerateRequestId.geneRateRequestId(),
						limit_required: "true"
					}
				}
			};
			let headers = {
				"client_id": "OEMSAPP",
				"token": props.token,
				"Authorization": "BASIC abcd"
			}
			console.log("inside balanceEnquiry request :-", request, " Employee Details ", props);
			var response = [];
			async function run() {
				const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
				console.log(url, request, "header")
				response = await ApiCallingService.postMethodCall(url, request, headers);
				const detail = response.response;
				console.log('response', response.response)
				setBalanceEnquiry(prevBalaceEnquire => ({ ...prevBalaceEnquire, ...detail }));
				setLoading(false);
			}
			run();


		}
		const fetchLastTxnDate = () => {
			var response = {};
			async function run() {
				const url = process.env.REACT_APP_API_WALLET_LAST_TXN_ENQUIRY + props.empDetail.mdn;

				//const url =  `http://3.6.104.48:7072/fetchTransaction?mdn=${props.empDetail.mdn}`;
				console.log('response for lasttxn', url)
				response = await ApiCallingService.getMethodCallWithoutParam(url);

				console.log('response for lasttxn', response)
				if (response === undefined || response.transactionData === undefined || response.transactionData === null) {
					setLasttxndate('NA');
				} else {
					setLasttxndate(response.transactionData.date_created_on);
				}


				//setBalanceEnquiry(prevBalaceEnquire => ({ ...prevBalaceEnquire, ...detail }));
				//setLoading(false);
			}
			run();
		}
		fetchBalanceDetail();
		fetchEmployeeDetail();
		fetchLastTxnDate();
	}, [])



	const handleRemoveEmployee = async (event, data) => {
		console.log("clicked handleRemoveEmployee", data, "and ROID", props.roMdn)

		if (data.email === props.currentUser) {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(process.env.React_APP_Message_For_if_logged_in_user_try_to_delete_it_self_Emp_detail));
			setSeverty("error");

		}

		else {


			const RequestToSend = {
				"request": {
					"employee": {
						"corp_id": props.roMdn,
						"emp_id": data.emp_id,
						"mdn": data.mdn,
						"status": "1",
						"card_staus": data.card_staus,
						"cardReferenceNumber": data.card_ref_no,
						"modify_by": props.currentUser,

					}
				}
			}
			console.log(RequestToSend);
			const url = process.env.REACT_APP_UPDATE_EMPLOYEE;


			const response = await ApiCalling.postMethodCall(url, RequestToSend);

			console.log('Update EMployee Api response yes', response);
			if (response !== undefined && response.status === 'failure') {
				store.dispatch(toggleDropDownHidden(true));
				setSeverty("error")
				store.dispatch(setMessageToBeShown(response.response_description));
			} else if (response.response !== undefined && response.response !== undefined && response.response.response_message === 'success') {
				console.log('inside success')
				store.dispatch(toggleDropDownHidden(true));
				setSeverty("success");
				store.dispatch(setMessageToBeShown('Employee removed successfully.'));
				history.push('/emp-list');
				history.go();
			} else if (response !== undefined && response.response_description !== undefined) {
				store.dispatch(toggleDropDownHidden(true));
				store.dispatch(setMessageToBeShown(response.response_description));
				setSeverty("error")
				console.log(response.response_description);
			}

		}
	}



	const handleEditSubmit = () => {
		setEditShowHidden(false);
	}





	const updateProfileLogo = async (e) => {
		e.preventDefault();


		setUpdateProfileProcessBar(true);
		var imgUrl = '';

		if (localStorage.getItem('url') !== '') {
			imgUrl = localStorage.getItem('url');
		}
		if (imgUrl === '') {
			return (
				store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown(process.env.REACT_APP_MESSAGE_TO_UPLOAD_PICTURE_BEFORE_SUBMIT)),
				setSeverty("error"),
				setUpdateProfileProcessBar(false)
			)
		}

		var req_id = GenerateRequestId.geneRateRequestId();
		console.log("email : ", employeeDetail.email, " location : ", employeeDetail.location, " pincode : ", employeeDetail.pincode);

		var gender = '';
		if (male) {
			gender = 'Male'
		} else if (female) {
			gender = 'Female'
		} else if (other) {
			gender = 'Other'
		}
		var dateOfBirth = moment(employeeDetail.dob).format("YYYY/MM/DD");
		console.log("yes hitted")
		var req = {

			"request": {
				"request_id": req_id,
				"employee": {
					"corp_id": props.roMdn,
					"emp_id": employeeDetail.emp_id,
					"mdn": employeeDetail.mdn,
					"first_name": employeeDetail.first_name,
					"last_name": employeeDetail.last_name,
					"email": employeeDetail.email,
					"pan": employeeDetail.pan,
					"band": employeeDetail.band,
					"department": employeeDetail.department,
					"location": employeeDetail.location,
					"pincode": employeeDetail.pincode,
					"dob": dateOfBirth,
					"gender": gender,
					"created_by": employeeDetail.created_by,
					"date_of_joining": employeeDetail.date_of_joining,
					"roleName": employeeDetail.roleName,
					"password": null,
					// "aadhar_no": employeeDetail.aadhar_no,
					"imageUrl": imgUrl,
					"manager_emp_corp_id": employeeDetail.manager_emp_corp_id,
					"finance_emp_corp_id": employeeDetail.finance_emp_corp_id,
					"modify_by": props.currentUser,
					"previousEmail": previousEmail,
					"cardReferenceNumber": employeeDetail.card_ref_no,
					"card_staus": employeeDetail.card_staus,
					"address": employeeDetail.address
				}
			}
		}

		var url = process.env.REACT_APP_UPDATE_EMPLOYEE;

		var response = await apiCallService.postMethodCall(url, req);

		console.log('response Corporate save', url, req, response);

		if (!(response === undefined) && !(response.response === undefined) && response.response.response_code === 0) {
			setUpdateEMployeeProcessBar(false);
			console.log('Inside add EMployee Api response')
			setSeverty("success")
			store.dispatch(setMessageToBeShown("Profile Pic successfully updated."));
			store.dispatch(toggleDropDownHidden(true));
			setPreviousEmail(employeeDetail.email)
			setEditShowHidden(!editShowHidden);
			store.dispatch(setEmployeeDetails(employeeDetail));

			var aadharMask = employeeDetail.aadhar_no;

			var zeroOne = employeeDetail.aadhar_no.substring(0, 2);
			var sevenNine = employeeDetail.aadhar_no.substring(10, 12);
			aadharMask = `${zeroOne}********${sevenNine}`;
			employeeDetail.aadhar_no = aadharMask
			history.push({
				pathname: '/emp-detail',
				state: { employeeDetail, editShowHidden: true }
			})
			history.go();
		} else if (!(response === undefined) && response.response_code === 1) {
			setUpdateEMployeeProcessBar(false);
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");
		} else if (!(response === undefined) && !(response.response === undefined) && response.response.response_code === 1) {
			setUpdateEMployeeProcessBar(false);
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");
		} else {
			setUpdateEMployeeProcessBar(false);
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown('Somthing Went Wrong. We are Looking At Our End (:-'));
			setSeverty("warning");
		}
	}




	const handleCancel = () => {
		console.log("fetchEmployeeDetail", props.location.state);
		var detail = props.empDetail;
		var dateOfBirth = moment(detail.dob).format("YYYY-MM-DD");
		detail.dob = dateOfBirth;
		var dateOfJoining = detail.date_of_joining.split(' ');
		detail.date_of_joining = dateOfJoining[0]
		const walletDetails = props.empDetail.wallet;
		const editShowHiddenProp = props.location.state.editShowHidden;
		console.log("editShowHiddenProp", editShowHiddenProp);


		console.log(' wallet detail', walletDetails)
		if (detail.gender.toLowerCase() === 'male') {
			setMale(true);
		} else if (detail.gender.toLowerCase() === 'female') {
			setFemale(true);
		} else {
			setOther(true);
		}
		setPattern('[1-9]{1}[0-9]{1}*{8}[0-9]{2}');
		setEmployeeDetail(detail);
		setEditShowHidden(true);
	}

	const handleChange = e => {
		const { name, value, id } = e.target;
		console.log('target', e.target.getAttribute('key'));
		console.log('name :', name, 'value :', value)
		setNullPincodeCheck(false);
		setNullEmailCheck(false);
		setNullLocationCheck(false);
		setEmployeeDetail(prevState => ({
			...prevState,
			[name]: value
		}));


		if (id === "acId") {
			setPattern("[1-9]{1}[0-9]{11}")
		}


		if (name === 'otp') {
			console.log('otp', value)
			setOtp(value)
		} else if (name === 'password') {
			console.log('password', value)
			setPassword(value)
		}
		if (id === CategoryLimit.GENERAL) {
			console.log('generalAmount', value)
			setgeneralAmount(value)
		}
		if (id === CategoryLimit.FOOD) {
			console.log('foodAmount', value)
			setFoodAmount(value);
		}
		if (id === CategoryLimit.TRAVEL) {
			console.log('travelAmount', value)
			setTravelAmount(value)
		}
	};
	const handleSaveEmployee = async () => {
		var req_id = GenerateRequestId.geneRateRequestId();
		console.log("email : ", employeeDetail.email, " location : ", employeeDetail.location, " pincode : ", employeeDetail.pincode);

		var gender = '';
		if (male) {
			gender = 'Male'
		} else if (female) {
			gender = 'Female'
		} else if (other) {
			gender = 'Other'
		}
		var dateOfBirth = moment(employeeDetail.dob).format("YYYY/MM/DD");
		var RequestToSend = {}
		var RequestToSendDD = {}

		setUpdateEMployeeProcessBar(true)

		if (employeeDetail.aadhar_no === '' || employeeDetail.aadhar_no.includes("**")) {
			RequestToSend = {

				"request": {
					"request_id": req_id,
					"employee": {
						"corp_id": props.roMdn,
						"emp_id": employeeDetail.emp_id,
						"mdn": employeeDetail.mdn,
						"first_name": employeeDetail.first_name,
						"last_name": employeeDetail.last_name,
						"email": employeeDetail.email,
						"pan": employeeDetail.pan,
						"band": employeeDetail.band,
						"department": employeeDetail.department,
						"location": employeeDetail.location,
						"pincode": employeeDetail.pincode,
						"dob": dateOfBirth,
						"gender": gender,
						"created_by": employeeDetail.created_by,
						"date_of_joining": employeeDetail.date_of_joining,
						"roleName": employeeDetail.roleName,
						"password": null,
						"imageUrl": "",
						"manager_emp_corp_id": employeeDetail.manager_emp_corp_id,
						"finance_emp_corp_id": employeeDetail.finance_emp_corp_id,
						"modify_by": props.currentUser,
						"previousEmail": previousEmail,
						"wallet": employeeDetail.wallet,
						"cardReferenceNumber": employeeDetail.card_ref_no,
						"card_staus": employeeDetail.card_staus,
						"address": employeeDetail.address,
						"profession":employee_worktype?.value,
                        "annualIncome":employee_income.value,
                        "cardIssuePurpose":purposeofcard.value,
                        "education_qualification":education_qualification.value,
                        "source_of_fund":source_of_fund.value,
                        "self_employed_profession":Self_employed_profession.value,
                        "nature_of_business":nature_of_business.value
					}
				}
			}
			RequestToSendDD = {

				"request": {
					"request_id": req_id,
					"employee": {
						"corp_id": props.roMdn,
						"emp_id": employeeDetail.emp_id,
						"mdn": employeeDetail.mdn,
						"first_name": employeeDetail.first_name,
						"last_name": employeeDetail.last_name,
						"email": employeeDetail.email,
						"pan": employeeDetail.pan,
						"band": employeeDetail.band,
						"department": employeeDetail.department,
						"location": employeeDetail.location,
						"pincode": employeeDetail.pincode,
						"dob": dateOfBirth,
						"gender": gender,
						"created_by": employeeDetail.created_by,
						"date_of_joining": employeeDetail.date_of_joining,
						"roleName": employeeDetail.roleName,
						"password": null,
						"imageUrl": "",
						"manager_emp_corp_id": employeeDetail.manager_emp_corp_id,
						"finance_emp_corp_id": employeeDetail.finance_emp_corp_id,
						"modify_by": props.currentUser,
						"previousEmail": previousEmail,
						"cardReferenceNumber": employeeDetail.card_ref_no,
						"card_staus": employeeDetail.card_staus,
						"address": employeeDetail.address,
						"profession":employee_worktype?.value,
                        "annualIncome":employee_income.value,
                        "cardIssuePurpose":purposeofcard.value,
                        "education_qualification":education_qualification.value,
                        "source_of_fund":source_of_fund.value,
                        "self_employed_profession":Self_employed_profession.value,
                        "nature_of_business":nature_of_business.value
					}
				}
			}
		} else {
			RequestToSend = {

				"request": {
					"request_id": req_id,
					"employee": {
						"corp_id": props.roMdn,
						"emp_id": employeeDetail.emp_id,
						"mdn": employeeDetail.mdn,
						"first_name": employeeDetail.first_name,
						"last_name": employeeDetail.last_name,
						"email": employeeDetail.email,
						"pan": employeeDetail.pan,
						"band": employeeDetail.band,
						"department": employeeDetail.department,
						"location": employeeDetail.location,
						"pincode": employeeDetail.pincode,
						"dob": dateOfBirth,
						"gender": gender,
						"created_by": employeeDetail.created_by,
						"date_of_joining": employeeDetail.date_of_joining,
						"roleName": employeeDetail.roleName,
						"password": null,
						"aadhar_no": employeeDetail.aadhar_no,
						"imageUrl": "",
						"manager_emp_corp_id": employeeDetail.manager_emp_corp_id,
						"finance_emp_corp_id": employeeDetail.finance_emp_corp_id,
						"modify_by": props.currentUser,
						"previousEmail": previousEmail,
						"wallet": employeeDetail.wallet,
						"cardReferenceNumber": employeeDetail.card_ref_no,
						"card_staus": employeeDetail.card_staus,
						"address": employeeDetail.address
					}
				}
			}
			RequestToSendDD = {

				"request": {
					"request_id": req_id,
					"employee": {
						"corp_id": props.roMdn,
						"emp_id": employeeDetail.emp_id,
						"mdn": employeeDetail.mdn,
						"first_name": employeeDetail.first_name,
						"last_name": employeeDetail.last_name,
						"email": employeeDetail.email,
						"pan": employeeDetail.pan,
						"band": employeeDetail.band,
						"department": employeeDetail.department,
						"location": employeeDetail.location,
						"pincode": employeeDetail.pincode,
						"dob": dateOfBirth,
						"gender": gender,
						"created_by": employeeDetail.created_by,
						"date_of_joining": employeeDetail.date_of_joining,
						"roleName": employeeDetail.roleName,
						"password": null,
						"aadhar_no": employeeDetail.aadhar_no,
						// "imageUrl": "",
						"manager_emp_corp_id": employeeDetail.manager_emp_corp_id,
						"finance_emp_corp_id": employeeDetail.finance_emp_corp_id,
						"modify_by": props.currentUser,
						"previousEmail": previousEmail,
						"cardReferenceNumber": employeeDetail.card_ref_no,
						"card_staus": employeeDetail.card_staus,
						"address": employeeDetail.address
					}
				}
			}
		}

		console.log('RequestToSend', RequestToSend);

		const url = process.env.REACT_APP_UPDATE_EMPLOYEE;


		const response = await ApiCalling.postMethodCall(url, RequestToSendDD);
		//const response = 'failure'
		console.log('ADD EMployee Api response', response)

		if (!(response === undefined) && !(response.response === undefined) && response.response.response_code === 0) {
			setUpdateEMployeeProcessBar(false);
			console.log('Inside add EMployee Api response')
			setSeverty("success")
			store.dispatch(setMessageToBeShown("Employee details successfully updated."));
			store.dispatch(toggleDropDownHidden(true));
			setPreviousEmail(employeeDetail.email)
			setEditShowHidden(!editShowHidden);
			store.dispatch(setEmployeeDetails(employeeDetail));

			var aadharMask = employeeDetail.aadhar_no;

			var zeroOne = employeeDetail.aadhar_no.substring(0, 2);
			var sevenNine = employeeDetail.aadhar_no.substring(10, 12);
			aadharMask = `${zeroOne}********${sevenNine}`;
			employeeDetail.aadhar_no = aadharMask
			// history.push({
			// 	pathname: '/emp-detail',
			// 	state: { employeeDetail, editShowHidden: true }
			// })
			// history.go();
		} else if (!(response === undefined) && response.response_code === 1) {
			setUpdateEMployeeProcessBar(false);
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");
		} else if (!(response === undefined) && !(response.response === undefined) && response.response.response_code === 1) {
			setUpdateEMployeeProcessBar(false);
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");
		} else {
			setUpdateEMployeeProcessBar(false);
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown('Somthing Went Wrong. We are Looking At Our End (:-'));
			setSeverty("warning");
		}


	}

	const handleUpdateRole = async (e) => {

		var roleNameToSend = roleName;
		if (employeeDetail.email === props.currentUser) {
			return (store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown(`${props.fullName} ${process.env.React_APP_Message_For_if_logged_in_user_try_to_update_role}`)),
				setSeverty("warning"))

		}
		if (employeeDetail.email === null || employeeDetail.email === "") {
			return (store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown(`${props.fullName} - ${process.env.React_APP_Message_For_Role_Update_TRY_WITHOUT_EMAIL}`)),
				setSeverty("warning"))

		}
		console.log('handleUpdateRole roleName:', roleName, roleName === "")
		if (roleName === "Select Role" || roleName === "" && roleStatustoSend !== 0) {
			return (store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown("Please Select Any Role")),
				setSeverty("warning"))

		}

		var requatPrev = prevRole

		if (roleStatustoSend === 1) {

			console.log('condition toh chl rhi h')

			requatPrev = 'undefined'

		}

		setUpdateRoleProcessBar(true)

		var requestToSent = {
			"request": {
				"employee": {
					"corp_id": props.roMdn,
					"email": employeeDetail.email,
					"emp_id": employeeDetail.emp_id,
					"mdn": employeeDetail.mdn,
					"roleName": roleNameToSend,
					"role_status": roleStatustoSend,
					"maxRoleAssigned": maxUserAssignedToRole,
					"prevRole": requatPrev,
					"card_staus": employeeDetail.card_staus,
					"modify_by": props.currentUser,
				}
			}
		}

		console.log('request to send', requestToSent)


		var url = process.env.REACT_APP_UPDATE_EMPLOYEE_ROLE;

		var response = await ApiCallingService.postMethodCall(url, requestToSent);

		console.log('update role response', response)

		if (!(response === undefined) && !(response.response === undefined) && (response.response.response_code === 0)) {
			store.dispatch(toggleDropDownHidden(true));
			if (roleName === '') {
				console.log("Removed Role Successfully")
				store.dispatch(setMessageToBeShown("Role successfully removed"));
			}
			else {
				store.dispatch(setMessageToBeShown("Role successfully updated"));
			}
			setRoleName(roleNameToSend)
			setPrevRole(roleNameToSend)
			setSeverty("success");
			employeeDetail.roleName = roleName;
			roleStatustoSend = 1
			store.dispatch(setEmployeeDetails(employeeDetail));
			setUpdateRoleProcessBar(false)
		} else if (!(response === undefined) && !(response.response === undefined) && (response.response.response_code === 1)) {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");
			setUpdateRoleProcessBar(false)
		} else if (!(response === undefined) && response.response_code === 1) {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setUpdateRoleProcessBar(false)
			setSeverty("error");
		} else {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown('Somthing Went Wrong. We are Looking At Our End (:-'));
			setSeverty("warning");
			setUpdateRoleProcessBar(false)
		}


	}

	const handleRoleChange = (e) => {
		console.log(e.target.selected, 'handle role change')
		var index = e.target.selectedIndex;
		var optionElement = e.target.childNodes[index]
		var option = optionElement.getAttribute('id');
		setRoleName(e.target.value);
		if (option !== null) {
			var value = option.split('/')
			console.log(value, option, 'handle role change')
			setMaxUserAssignedToRole(parseInt(value[1]));
		}
		roleStatustoSend = 1
	}


	const validateInputLenght = (object) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(0, object.target.maxLength)
		}
	}

	console.log('fetchEmployeeDetail state', employeeDetail)
	if (roleName === 'Remove Role') {
		setRoleName('');
		roleStatustoSend = 0;
	}




	const otpGeneration = async (event) => {
		event.preventDefault();
		console.log('request allValues', event.target)

		if ((generalAmount === 0 || generalAmount === '') && (travelAmount === 0 || travelAmount === '') && (foodAmount === 0 || foodAmount === '')) {
			return (
				store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_disburse_if_does_not_enter_amount_for_any_category)),
				setSeverty("error")
			)
		}
		console.log('request allValues', props.mdn)

		// kyc check will goes here -


		if (kycExpireDate === undefined || kycExpireDate === null) {
			return (
				store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_disburse_if_kyc_expired_NOT_AVAILABLE)),
				setSeverty("error")
			)
		}

		var dateTo = kycExpireDate.split(" ");
		console.log("dateToCheck : ", dateTo[0], dateTo[1])

		var dateww = dateTo[0].split("-");
		console.log("dateToCheck : ", dateww[0], dateww[1], dateww[2])
		console.log("dateToCheck : ", `${dateww[2]}-${dateww[1]}-${dateww[0]} ${dateTo[1]}`)

		var date = new Date();
		var dateToCheck = new Date(`${dateww[2]}-${dateww[1]}-${dateww[0]} ${dateTo[1]}`);


		console.log("dateToCheck : ", dateToCheck, " and currentDate : ", date, " : ", dateToCheck < date)


		if (dateToCheck < date) {
			return (
				store.dispatch(toggleDropDownHidden(true)),
				store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_disburse_if_kyc_expired)),
				setSeverty("error")
			)
		}


		var gCost = generalAmount === '' ? 0 : parseFloat(generalAmount).toFixed(2);
		var tCost = travelAmount === '' ? 0 : parseFloat(travelAmount).toFixed(2);
		var fCost = foodAmount === '' ? 0 : parseFloat(foodAmount).toFixed(2);


		var totalCost = (parseFloat(gCost) + parseFloat(tCost) + parseFloat(fCost)) * 100;

		const request = {
			clientIndentifier:  props.roMdn
		
		};
		let headers = {
			"client_id": "OEMSAPP",
			"token": props.token,
			"Authorization": "BASIC abcd"
		}
		// console.log("Total Balnce is totalCost inside balanceEnquiry request :-", request, " Employee Details ", props);
		var response = [];

		const urlB = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY_CORP
		console.log(urlB, request, "header")
		response = await ApiCallingService.postMethodCall(urlB, request, headers);
		const detail = response.response;
		console.log('Total Balnce is totalCost response', response.response)


		var RoBalance = 0;
		if (response !== undefined && response.response_code !== undefined && response.response_code == 0 && response.response !== undefined) {
			RoBalance = response?.response?.availableBalance
		}
		console.log('Total Balnce is totalCost response', RoBalance, " : ", parseInt(RoBalance) < parseInt(totalCost))
		// if (parseInt(RoBalance) < parseInt(totalCost)) {
		// 	return (
		// 		store.dispatch(toggleDropDownHidden(true)),
		// 		store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_disburse_if_ro_insufficent_balance)),
		// 		setSeverty("error")
		// 	)
		// }


		var url = process.env.REACT_APP_URL_TO_GET_OTP
		var requestToSend = {
			"request": {
				"eventType": "rowalletlinkup",
				"destinations": [
					{
						"address": props.mdn,
						"type": "mobile"
					}
				]
			}
		}

		console.log('request allValues', requestToSend)

		var response = await apiCallService.postMethodCall(url, requestToSend);


		console.log('request response allValues', response);
		if (response.response_code === 0 && response.response !== undefined) {
			setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
			// store.dispatch(toggleDropDownHidden(true));
			// store.dispatch(setMessageToBeShown(response.response.statusMessage));
			// setSeverty("success");
			setReferenceNumber(response.response.referenceNumber)
			setCheckLimit(false)

			document.getElementById("btnDisbur1").click();
			// setCheckLimit(true);
		} else {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");
			// setCheckLimit(true);
		}
		setpopupverifydisburse('popup-verify-disburse');
	}


	const formDisburseSubmit = async (event) => {
		event.preventDefault();
		const d = Date.now();
		//const n = d.getMilliseconds();
		const val = Math.floor(1000 + Math.random() * 9000);
		console.log(d, ' ', val, ' ', `${d}${val}`)
		const reqestId = `${d}${val}`;
		var requestToSend = {
			"request": {
				"transaction": {
					"corporateId": props.roMdn,
					"transactionId": "96261121000",
					"requestId": reqestId,
					"mdn": employeeDetail.mdn,
					"genral": generalAmount,
					"food": foodAmount,
					"travel": travelAmount,
					"dateCreatedOn": new Date(),
					"dateModifiedOn": "",
					"transactionPassword": password,
					"referenceNumber": referenceNumber,
					"otp": otp,
					"approvedByEmail": props.currentUser,
					"approvedByName": props.fullName,
					"band": employeeDetail.band,
					"empId": employeeDetail.emp_id,
					"empName": employeeDetail.fullName
				}
			}
		}
		const url = process.env.REACT_APP_BULK_MANUAL_DISBERSAL;
		console.log('requestToSend', requestToSend, url)

		var response = await apiCallService.postMethodCall(url, requestToSend);

		console.log('response formDisburseSubmit', response);


		if (response !== undefined && response !== null && response.response_code !== undefined && response.response_code === 0) {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_page_otp_pop_up_submit_disburse_now));
			setSeverty("success");
			setPassword('');
			setOtp('');
			var employee = employeeDetail;
			history.push({
				pathname: '/emp-detail',
				state: { employee, editShowHidden: true }
			})
			history.go();

		} else {

			if (response.response_description !== undefined && response.response_description.toLowerCase().includes("otp")) {
				store.dispatch(toggleDropDownHidden(true));
				store.dispatch(setMessageToBeShown(response.response_description));
				setSeverty("error");
			} else {
				store.dispatch(toggleDropDownHidden(true));
				store.dispatch(setMessageToBeShown(response.response_description));
				setSeverty("error");
				setPassword('');
				setOtp('');
				document.getElementById("closeToggleAvail").click();
			}

		}



	}

	const closeToggle = () => {
		setCheckLimit(true);
		setPassword('');
		setOtp('');
	}


	const handleResendOtp = async (event) => {
		console.log(' handleResendOtp hitted');
		const request = {
			"request": {
				"referenceNumber": referenceNumber
			}
		}
		const url = process.env.REACT_APP_RESEND_OTP_URL;

		var response = await apiCallService.postMethodCall(url, request);

		console.log('handleResendOtp', response);
		setTriggeretime(false)
		if (response.response_code === 0 && response.response !== undefined) {

			// store.dispatch(toggleDropDownHidden(true));
			// store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_page_otp_pop_up_resend_otp));
			// setSeverty("success");
			// setTriggeretime(true)
			// setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))

			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(process.env.React_APP_Message_Emp_detail_page_otp_pop_up_resend_otp));
			setSeverty("success");
			setReferenceNumber(response.response.referenceNumber)
			setTime(Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT))
			setTriggeretime(true)
			console.log('setTime(Date.now() +  parseInt(process.env.REACT_APP_OTP_TIME_OUT))', time)
		} else {
			store.dispatch(toggleDropDownHidden(true));
			store.dispatch(setMessageToBeShown(response.response_description));
			setSeverty("error");

		}
	}




	const handleDepartmentChange = (newValue, actionMeta) => {
		console.group('Value Changed handleChange');
		console.log('Value Changed handleChange newValue', newValue);
		console.log(`action handleChange: ${actionMeta.action}`);
		console.groupEnd();
		if (newValue !== null && newValue.value !== null) {

			setEmployeeDetail(prevState => ({
				...prevState,
				department: newValue.value
			}));
		} else {
			setEmployeeDetail(prevState => ({
				...prevState,
				department: ""
			}));
		}
	};
	const handleDepartmentInputChange = (inputValue, actionMeta) => {
		console.group('Input Changed handleInputChange', inputValue);
		console.log(inputValue);
		console.log(`action handleInputChange: ${actionMeta.action}`);
		console.groupEnd();
		// setEmployeeDetail(prevState => ({
		// 	...prevState,
		// 	department: inputValue
		// }));
	};

	const handleBandChange = (newValue, actionMeta) => {
		console.group('Value Changed handleChange');
		console.log('Value Changed handleChange newValue', newValue);
		console.log(`action handleChange: ${actionMeta.action}`);
		console.groupEnd();
		if (newValue !== null && newValue.value !== null) {
			setEmployeeDetail(prevState => ({
				...prevState,
				band: newValue.value
			}));

		} else {
			setEmployeeDetail(prevState => ({
				...prevState,
				band: ""
			}));
		}

	};
	const handleBandInputChange = (inputValue, actionMeta) => {
		console.group('Input Changed handleInputChange');
		console.log(inputValue);
		console.log(`action handleInputChange: ${actionMeta.action}`);
		console.groupEnd();

		// setEmployeeDetail(prevState => ({
		// 	...prevState,
		// 	band: inputValue
		// }));

	};










	var zeroOneMdn = props.mdn.substring(0, 1);
	var sevenNineMdn = props.mdn.substring(8, 10);

	var mystring = `${zeroOneMdn}******${sevenNineMdn}`;
	console.log('props.senderValues.mdn', mystring)

	var totalAmount = parseFloat(generalAmount) + parseFloat(foodAmount) + parseFloat(travelAmount)

	var arrayForGeneralAmount = [];
	var arrayForTravelAmount = [];
	var arrayForFoodAmount = [];

	arrayForGeneralAmount.push(props.maxGeneralAmount / 100);
	arrayForTravelAmount.push(props.maxTravelAmount / 100);
	arrayForFoodAmount.push(props.maxFoodAmount / 100);

	props.corpBand.map(band => {
		if (band.band === employeeDetail.band) {
			arrayForGeneralAmount.push(band.general_Limit / 100);
			arrayForTravelAmount.push(band.travel_Limit / 100);
			arrayForFoodAmount.push(band.wallet_food_limit / 100);
		}
	})
	console.log('arrayForGeneralAmount', arrayForGeneralAmount, 'arrayForTravelAmount', arrayForTravelAmount, 'arrayForFoodAmount', arrayForFoodAmount)

	var i = arrayForGeneralAmount.indexOf(0);
	if (i >= 0) {
		arrayForGeneralAmount.splice(i, 1);
	}

	var j = arrayForTravelAmount.indexOf(0);
	if (j >= 0) {
		arrayForTravelAmount.splice(j, 1);
	}
	var k = arrayForFoodAmount.indexOf(0);
	if (k >= 0) {
		arrayForFoodAmount.splice(k, 1);
	}

	console.log('arrayForGeneralAmount', arrayForGeneralAmount, 'arrayForTravelAmount', arrayForTravelAmount, 'arrayForFoodAmount', arrayForFoodAmount)


	var minAm = 1;

	var imageUrl = employeeDetail.imageUrl;



	const colourStyles = {
		control: styles => ({
			...styles,
			backgroundColor: 'white',
			border: "none",
			borderRadius: "0px",
			boxShadow: "none",
			minHeight: "22px",
			lineHeight: "22px",
			background: `url(${img})`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "12px",
			backgroundPosition: "center right",
			marginTop: "-7px"
		}),

		valueContainer: styles => ({
			...styles,
			padding: "0px 5px 0px 0px"
		}),


		menu: styles => ({
			...styles,
			zIndex: "9999"
		}),


		input: styles => ({
			...styles,
			margin: "0px"
		}),



		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			//   const color = chroma(data.color);
			return {
				...styles,
			};
		},



	};





	var startdate = moment();
	startdate = startdate.subtract(18, "years");
	startdate = startdate.format("YYYY-MM-DD");


	var searchFinaceFinalList = [];

	if (searchVarForFinAdmin !== '' && allEmp !== undefined) {

		allEmp.map(post => {

			if (post.first_name !== undefined && post.first_name !== null && searchVarForFinAdmin !== undefined && post.first_name.toLowerCase().includes(searchVarForFinAdmin.toLowerCase())) {
				searchFinaceFinalList.push(post)
			} else if (post.last_name !== undefined && post.last_name !== null && searchVarForFinAdmin !== undefined && post.last_name.toLowerCase().includes(searchVarForFinAdmin.toLowerCase())) {
				searchFinaceFinalList.push(post)
			} else if (post.emp_id !== undefined && post.emp_id !== null && searchVarForFinAdmin !== undefined && post.emp_id.includes(searchVarForFinAdmin)) {
				searchFinaceFinalList.push(post)
			} else if (post.mdn !== undefined && post.mdn !== null && searchVarForFinAdmin !== undefined && post.mdn.includes(searchVarForFinAdmin)) {
				searchFinaceFinalList.push(post)
			}
		})
	}


	var searchReportingMgnFinal = [];

	if (searchVarForReportingMgn !== '' && allEmp !== undefined) {
		allEmp.map(post => {
			if (post.first_name !== undefined && post.first_name !== null && searchVarForReportingMgn !== undefined && post.first_name.toLowerCase().includes(searchVarForReportingMgn.toLowerCase())) {
				searchReportingMgnFinal.push(post)
			} else if (post.last_name !== undefined && post.last_name !== null && searchVarForReportingMgn !== undefined && post.last_name.toLowerCase().includes(searchVarForReportingMgn.toLowerCase())) {
				searchReportingMgnFinal.push(post)
			} else if (post.emp_id !== undefined && post.emp_id !== null && searchVarForReportingMgn !== undefined && post.emp_id.includes(searchVarForReportingMgn)) {
				searchReportingMgnFinal.push(post)
			} else if (post.mdn !== undefined && post.mdn !== null && searchVarForReportingMgn !== undefined && post.mdn.includes(searchVarForReportingMgn)) {
				searchReportingMgnFinal.push(post)
			}
		})
	}
	const handleWorktypeChange = (newValue, actionMeta) => {
        
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {
		
          setEmployee_worktype({label:newValue.value, value:newValue.value})

        } else {
			setEmployee_worktype({label: "", value: ""})
          
        }

    };
   const handleannualincomeChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {
		
		setEmployee_income({label: newValue.value, value: newValue.value})
           
        } else {
			setEmployee_income({label: "", value: ""})
          
        }

    };
   const handlepurposeofcardChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
		if (newValue !== null && newValue.value !== null) {
			
			setPurposeofcard({label: newValue.value, value: newValue.value})
        } else {
			
			setPurposeofcard({label: "", value: ""})
        }
       

    };

   const handleEductionChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
		if (newValue !== null && newValue.value !== null) {
			
			setEducation_qualification({label: newValue.value, value: newValue.value})
        } else {
			
			setEducation_qualification({label: "", value: ""})
        }
		
      

    };
  const  handleSourceOfFundChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();

		if (newValue !== null && newValue.value !== null) {
			
			setSource_of_fund({label: newValue.value, value: newValue.value})
        } else {
			
			setSource_of_fund({label: "", value: ""})
        }
		
      

    };
   const handleSelfEmpProfessionChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
		if (newValue !== null && newValue.value !== null) {
			
			setSelf_employed_profession({label: newValue.value, value: newValue.value})
        } else {
			
			setSelf_employed_profession({label: "", value: ""})
        }
		
      

    };
    //nature_of_business
   const handleNtureOfbussenessChange = (newValue, actionMeta) => {
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
		if (newValue !== null && newValue.value !== null) {
			
			setNature_of_business({label: newValue.value, value: newValue.value})
        } else {
			
			setNature_of_business({label: "", value: ""})
        }
	
      
    };



	return (

		<>
			{(props.authenticated)
				? (

					props.isPasswordReset ?

						<div className="mid-container">
							<div className="container-fluid">
								<Snackbar
									anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
									open={props.hidden}
									autoHideDuration={20000}
									onClose={() => store.dispatch(toggleDropDownHidden(false))}
								>
									<Alert onClose={() => store.dispatch(toggleDropDownHidden(false))} severity={severty}>
										{props.message}
									</Alert>
								</Snackbar>

								<div className="head">
									<div className="row">
										<div className="col-10">
											<h3>{process.env.React_APP_Var_FOR_Employee_Detail_Page_EmployeeDetail}</h3>
										</div>

										<div className="col-2">
											<div className="actnbtn">
												<Link to={{
													pathname: props.backUrl,
													state: { roId: employeeDetail.roMdn }
												}}
													data-toggle="tooltip" title="Back" data-iscapture="true"><i className="back"></i></Link>
											</div>
										</div>
									</div>
								</div>

								<div className="empdtls">
									<div className="row">

										{
											loadingComponent
												?
												<EmpDetailLoadingComponent></EmpDetailLoadingComponent>
												:
												<div className="col-12 col-md-4">

													<div className="emplcard">

														{
															editShowHidden
																?
																<>
																	<div className="userpro">
																		<Avatar src={imageUrl} color={Avatar.getRandomColor(employeeDetail.emp_id, ['#F44336', "#EC407A", '#AB47BC', "#7E57C2", "#5C6BC0", "#42A5F5", "#29B6F6", "#26C6DA", "#26A69A", "#66BB6A", "#9CCC65", "#D4E157", "#FF7043", "#FFEE58", "#BDBDBD"])} value={employeeDetail.emp_id} name={`${CommonFunction.Capitalize(employeeDetail.first_name)} ${CommonFunction.Capitalize(employeeDetail.last_name)}`} size="44px" round={true} />
																		<Can
																			role={props.userRole}
																			perform="emp-detail:editProfilePic"
																			roleData={props.roleData}
																			yes={() => (
																				<button className="editpro" type="button" data-bs-toggle="modal" data-bs-target="#edtpropic"></button>
																			)}
																			no={() => null}
																		/>

																		<h5 className="text-truncate">{CommonFunction.Capitalize(employeeDetail.first_name)} {CommonFunction.Capitalize(employeeDetail.last_name)}</h5>
																		<p>+91-{employeeDetail.mdn}</p>

																		<div className="empactn">

																			<Can
																				role={props.userRole}
																				perform="empDetail-page:empEdit"
																				roleData={props.roleData}
																				yes={() => (

																					<button className="edit" type="button" data-toggle="tooltip" title="Edit Profile" onClick={handleEditSubmit}></button>

																				)}
																				no={() =>
																					null
																				}
																			/>

																			<Can
																				role={props.userRole}
																				perform="empDetail-page:empDelete"
																				roleData={props.roleData}
																				yes={() => (

																					<button className="remove" type="button" data-toggle="tooltip" title="Remove" data-bs-toggle="modal" data-bs-target="#popup-remove" ></button>

																				)}
																				no={() =>
																					null
																				}
																			/>
																		</div>
																	</div>

																	{

																		kyc_status === KycStatus.MIN_KYC
																			?
																			<div className="kycsts">
																				<p className="float-start"> <i className="min"></i> {process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Min_KYC}</p>
																				{kycExpireDate === undefined || kycExpireDate === null || kycExpireDate === ""
																					?
																					null
																					:
																					<p className="float-end"><i className="date"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_KYC_EXPIRED_LABEL} {kycExpireDate}</p>
																				}
																			</div>
																			:
																			kyc_status === KycStatus.KYC_DONE
																				?
																				<div className="kycsts">
																					<p className="float-start">  <i className="full"></i> {process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Full_KYC}</p>
																					{
																						kycExpireDate === undefined || kycExpireDate === null || kycExpireDate === ""
																							?
																							null
																							:
																							<p className="float-end"><i className="date"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_KYC_EXPIRED_LABEL} {kycExpireDate}</p>
																					}
																				</div>
																				:
																				<div className="kycsts">
																					<p className="float-start">  <i className="none"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_No_KYC}</p>
																					{kycExpireDate === undefined || kycExpireDate === null || kycExpireDate === ""
																						?
																						null
																						:
																						<p className="float-end"><i className="date"></i>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_KYC_EXPIRED_LABEL} {kycExpireDate}</p>
																					}
																				</div>

																	}

																	<div className="usrprodtl">
																		<ul>
																			<li className="email">
																				<p>Email ID:</p>
																				<h5>{employeeDetail.email == null || employeeDetail.email === "" ? <>NA</> : employeeDetail.email}</h5>
																			</li>
																			<li className="cldr">
																				<p>Date of Birth:</p>
																				<h5>{employeeDetail.dob == null || employeeDetail.dob === "" ? <>NA</> : employeeDetail.dob}</h5>
																			</li>
																			<li className="gender">
																				<p>Gender:</p>
																				<h5>{employeeDetail.gender == null || employeeDetail.gender === "" ? <>NA</> : employeeDetail.gender}</h5>
																			</li>
																			<li className="doc">
																				<p>PAN:</p>
																				<h5> {employeeDetail.pan == null || employeeDetail.pan === "" ? <>NA</> : employeeDetail.pan}</h5>
																			</li>
																			<li className="locat">
																				<p>Location:</p>
																				<h5>{employeeDetail.address} - {employeeDetail.pincode}</h5>
																			</li>
																		</ul>
																	</div>

																	<div className="profndtls">
																		<ul>
																			<li>
																				<p>Employee ID:</p>
																				<h5>{employeeDetail.emp_id == null || employeeDetail.emp_id === "" ? <>NA</> : employeeDetail.emp_id}</h5>
																			</li>
																			<li>
																				<p>Date of Joining:</p>
																				<h5>{employeeDetail.date_of_joining == null || employeeDetail.date_of_joining === "" ? <>NA</> : employeeDetail.date_of_joining}</h5>
																			</li>
																			<li>
																				<p>Department:</p>
																				<h5>{employeeDetail.department == null || employeeDetail.department === "" ? <>NA</> : employeeDetail.department}</h5>
																			</li>
																			<li>
																				<p>Employee Band:</p>
																				<h5>{employeeDetail.band == null || employeeDetail.band === "" ? <>NA</> : employeeDetail.band}</h5>
																			</li>
																		</ul>
																	</div>

																</>
																:
																<>
																	<div className="name-mob-edit">
																		<div className="row">

																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="first_name" name='first_name' pattern="[a-zA-Z. ]{1,30}" onChange={handleChange} value={employeeDetail.first_name} required />
																						<label for="first_name">First Name</label>
																					</div>
																				</div>
																			</div>


																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="last_name" name='last_name' pattern="[a-zA-Z. ]{1,30}" onChange={handleChange} value={employeeDetail.last_name} />
																						<label for="last_name">Last Name</label>
																					</div>
																				</div>
																			</div>



																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="mdn" name='mdn' onChange={handleChange} value={employeeDetail.mdn} disabled />
																						<label for="mdn">Mobile no.</label>
																					</div>
																				</div>
																			</div>


																		</div>
																	</div>
																	<div className="userpro" id="edit-uesr-details" style={{ "display": "block" }}>
																		<div className="row">

																			<div className="col-12">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="email" id="email" value={employeeDetail.email} name='email' onChange={handleChange} disabled={kyc_status === KycStatus.MIN_KYC || kyc_status === KycStatus.KYC_DONE}
																							required={(previousEmail != null && previousEmail !== "") || (employeeDetail.roleName != null && employeeDetail.roleName !== "")}
																						/>
																						<label for="email">Email ID</label>
																					</div>
																				</div>
																			</div>


																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="date" max={startdate} id="dob" value={employeeDetail.dob} name='dob' onChange={handleChange} disabled={kyc_status === KycStatus.MIN_KYC || kyc_status === KycStatus.KYC_DONE} required />
																						<label for="dob">Date of Birth</label>
																					</div>
																				</div>
																			</div>

																			<div className="col-6">
																				<div className="webform">
																					<p className="gentxt">Gender</p>
																					{
																						(male)
																							?
																							<div className="form-check form-check-inline">

																								<input type="radio" id="op1" className="form-check-input" name="options" checked={male} disabled />
																								<label className="form-check-label" htmlFor="op1">{process.env.React_APP_LABELFOR_MALE}
																								</label>
																							</div>
																							:
																							(female)
																								?
																								<div className="form-check form-check-inline">

																									<input type="radio" id="op2" className="form-check-input" name="options" checked={female} disabled />
																									<label className="form-check-label" htmlFor="op2"> {process.env.React_APP_LABELFOR_FEMALE}
																									</label>
																								</div>
																								:
																								<div className="form-check form-check-inline">

																									<input type="radio" id="op2" className="form-check-input" name="options" checked={other} disabled />
																									<label className="form-check-label" htmlFor="op2"> {process.env.React_APP_LABELFOR_OTHER}
																									</label>
																								</div>

																					}

																				</div>
																			</div>



																			<div className="col-12">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="address" maxLength="500" value={employeeDetail.address} name='address' minLength="2" onChange={handleChange} disabled={kyc_status === KycStatus.MIN_KYC || kyc_status === KycStatus.KYC_DONE} required />
																						<label for="address">Address</label>
																					</div>
																					<div className="pan-note">{process.env.React_APP_ADDRESS_NOTE}</div>
																				</div>
																			</div>

																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="location" maxLength="60" value={employeeDetail.location} name='location' minLength="2" onChange={handleChange} required />
																						<label for="location">Location</label>
																					</div>
																				</div>
																			</div>


																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="number" id="pincode" pattern="[1-9]{1}[0-9]{5}" maxLength="6" onInput={validateInputLenght} value={employeeDetail.pincode} name='pincode' onChange={handleChange} required />
																						<label for="pincode">Pincode</label>
																					</div>
																				</div>
																			</div>


																		</div>
																	</div>

																	<div className="pro-details" id="edit-pro-details" style={{ "display": "block" }}>
																		<div className="row">



																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="emp_id" value={employeeDetail.emp_id} name='emp_id' disabled />
																						<label for="emp_id">Employee ID</label>
																					</div>
																				</div>
																			</div>



																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="date" id="doj" value={employeeDetail.date_of_joining} name='date_of_joining' disabled />
																						<label for="doj">Date of Joining</label>
																					</div>
																				</div>
																			</div>


																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="sg" style={{ color: "#3f51b5" }} >Department</label>
																						<CreatableSelect
																							isClearable
																							onChange={handleDepartmentChange}
																							onInputChange={handleDepartmentInputChange}
																							options={props.departmentaList}
																							styles={colourStyles}
																							components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
																							defaultValue={props.departmentaList.map(bandData => {
																								if (bandData.value === employeeDetail.department) {
																									return bandData
																								}
																							})}
																						/>
																					</div>
																				</div>
																			</div>



																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="cars">Band</label>
																						<CreatableSelect
																							isClearable
																							onChange={handleBandChange}
																							onInputChange={handleBandInputChange}
																							options={props.bandData}
																							styles={colourStyles}
																							components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
																							defaultValue={props.bandData.map(bandData => {
																								if (bandData.value === employeeDetail.band) {
																									return bandData
																								}
																							})}
																						/>
																					</div>
																				</div>
																			</div>







																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" id="pan" maxlength="10" pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" value={employeeDetail.pan} name='pan' onChange={handleChange}
																						/><label for="pan">PAN</label>
																					</div>
																				</div>
																			</div>



																			<div className="col-6">
																				<div className="webform">
																					<div className="form-floating">
																						<input className="form-control" type="text" maxLength="12" onKeyDown={(evt) => ["e", "E", "+", "-", ".", "*"].includes(evt.key) && evt.preventDefault()}
																							id="acId" pattern={pattern} value={employeeDetail.aadhar_no} name='aadhar_no' onInput={validateInputLenght} onChange={handleChange} disabled={kyc_status === KycStatus.MIN_KYC || kyc_status === KycStatus.KYC_DONE}
																						/>
																						<label for="acId">Aadhaar Number</label>
																					</div>
																				</div>
																			</div>
																			
																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="cars">Profession Work type *</label>
																						<CreatableSelect
																							isClearable
																							onChange={handleWorktypeChange}
																							onInputChange={handleBandInputChange}
																							options={professionalDetails}
																							styles={colourStyles}
																							value={employee_worktype}
																							components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
																							// defaultValue={props.bandData.map(bandData => {
																							// 	if (bandData.value === employeeDetail.band) {
																							// 		return bandData
																							// 	}
																							// })}
																						/>
																					</div>
																				</div>
																			</div>
																			
																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="cars">Education *</label>
																						<CreatableSelect
																						isClearable
																						onChange={handleEductionChange}
																						onInputChange={handleBandInputChange}
																						options={educationDetails}
																						value={education_qualification}
																						styles={colourStyles}
																						components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
																					
																							// isClearable
																							// onChange={handleBandChange}
																							// onInputChange={handleBandInputChange}
																							// options={props.bandData}
																							// styles={colourStyles}
																							// components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
																							// defaultValue={props.bandData.map(bandData => {
																							// 	if (bandData.value === employeeDetail.band) {
																							// 		return bandData
																							// 	}
																							// })}
																						/>
																					</div>
																				</div>
																			</div>

																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="cars">Annual Income *</label>
																						<CreatableSelect
																					     isClearable
																					     onChange={handleannualincomeChange}
																					     onInputChange={handleBandInputChange}
																					     options={annualIncome}
																					     value={employee_income}
																					     styles={colourStyles}
																					     components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
																						/>
																					</div>
																				</div>
																			</div>

																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="cars">Source of fund *</label>
																						<CreatableSelect
																			              isClearable
																			              onChange={handleSourceOfFundChange}
																			              onInputChange={handleBandInputChange}
																			              options={sourceOfFund}
																			              styles={colourStyles}
																			              value={source_of_fund}
																			              components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
																						/>
																					</div>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="webform">
																					<div className="formselect">
																						<label htmlFor="cars">Purpose of Issuing card *</label>
																						<CreatableSelect
																			              isClearable
																						  onChange={handlepurposeofcardChange}
																						  onInputChange={handleBandInputChange}
																						  options={purposeofIssuingcard}
																						  styles={colourStyles}
																						  value={purposeofcard}
																						  components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
																						/>
																					</div>
																				</div>
																			</div>

																			{employee_worktype.value == "SELF-EMPLOYED" &&
																			  <div className="col-6">
																			  <div className="webform">
																				  <div className="formselect">
																					  <label htmlFor="cars">Self Employed Profession *</label>
																					  <CreatableSelect
										                                                isClearable
                                                                                        onChange={handleSelfEmpProfessionChange}
                                                                                        onInputChange={handleBandInputChange}
                                                                                        options={selfEmployedProfession}
                                                                                        styles={colourStyles}
															          		            value={Self_employed_profession}
                                                                                        components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
																					  />
																				  </div>
																			  </div>
																		  </div>
																		}
													                    	{employee_worktype.value == "BUSINESS" &&
                                                                              <div className="col-6">
																			  <div className="webform">
																				  <div className="formselect">
																					  <label htmlFor="cars">Nature Of Business</label>
																					  <CreatableSelect
										                                                isClearable
																						onChange={handleNtureOfbussenessChange}
																						onInputChange={handleBandInputChange}
																						options={natureOfBusiness}
																						styles={colourStyles}
																						value={nature_of_business}
																						components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
																					  />
																				  </div>
																			  </div>
																		  </div>
																			}


																			<div className="col-12 profileEditBtn">
																				<button type="button" className="btn btngr" style={{ marginRight: "15px" }} onClick={handleCancel}>Cancel</button>
																				<button type="submit" className="btn btnbl" disabled={updateEMployeeProcessBar} onClick={handleSaveEmployee} >
																					{updateEMployeeProcessBar
																						?
																						<div className="spinner-border" role="status">
																							<span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
																						</div>
																						:
																						<>Save</>
																					}
																				</button>
																			</div>
																		</div>
																	</div>
																</>
														}

														<Can
															role={props.userRole}
															perform="empDetail-page:updateRole"
															roleData={props.roleData}
															yes={() => (
																<div className="roleform">
																	<div className="form-floating input-group">
																		<select className="form-select" id="role" onChange={handleRoleChange}>
																			{employeeDetail.roleName === ""
																				?
																				<option selected>Select Role</option>
																				:
																				null

																			}
																			{props.masterRoleData.map(role => (
																				<AssignRole employeeDetail={employeeDetail} role={role} />

																			))}
																			{
																				employeeDetail.roleName === ""
																					?
																					null
																					:
																					<option selected={'Remove Role' === employeeDetail.roleName}>Remove Role</option>
																			}
																		</select>
																		<label for="role">{process.env.React_APP_Var_FOR_Employee_Detail_Page_Assigned_Role}</label>

																		<button type="button" className="btn btnbl" id="uR" onClick={handleUpdateRole} disabled={updateRoleProcessBar}>
																			{
																				updateRoleProcessBar
																					?
																					<div className="spinner-border" role="status">
																						<span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
																					</div>
																					:
																					<>Ok</>
																			}

																		</button>

																	</div>
																</div>
															)}
															no={() =>
																null
															}
														/>


													</div>


												</div>


										}
										<div className="col-12 col-md-8">
											<div className="emplcard">

												<div className="balance-info">
													<h3 className="subhead">{process.env.React_APP_Var_FOR_Employee_Detail_Page_Balance_Information}</h3>

													<div className="row">
														<div className="col-12 col-lg-6 col-xl-4">
															<div className="total-balance">
																<i>&#8377;</i>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_TotalAvailableBalance}</label>
																{loading
																	?
																	<Skeleton animation="wave" variant="square" />
																	:
																	<h4>{parseFloat(balanceEnquiry.Balance.total_balance / 100).toFixed(2)}{console.log('loading..', loading)}</h4>
																}
															</div>
														</div>

														<div className="col-12 col-lg-6 col-xl-8">
															<div className="row">

																{
																	employeeDetail.wallet.map(wallet => (
																		<SubBalance loading={loading} wallet={wallet} sub_wallet_balance={balanceEnquiry.Balance.sub_wallet_balance} />

																	))
																}
															</div>
														</div>
													</div>
												</div>

												<div className="card-info">
													<h3 className="subhead" >
														<Can
															role={props.userRole}
															perform="empDetail-page:empMangeCard"
															roleData={props.roleData}
															yes={() => (
																<Link to={{
																	pathname: "/emp-manage-card",
																	state: { empMobile: employeeDetail.mdn }
																}}
																	className="cardsetting">Manage Card</Link>
															)}
															no={() =>
																null
															}
														/>

														Card Information</h3>
													{
														employeeDetail.card.map(cards => (

															parseInt(cards.cardStatus) === CardStatusType.NOT_ASSIGNED

																?

																<Can
																	role={props.userRole}
																	perform="emp-detail:assignAvailCard"
																	roleData={props.roleData}
																	yes={() => (

																		<>
																			<AvailCardDiv employeeDetail={employeeDetail} />
																			<hr></hr>
																		</>


																	)}
																	no={() => null}
																/>
																:
																<>
																	<div className="row">
																		{
																			cards.cardType === 1
																				?
																				<div className="col-12 col-md-12 col-lg-5 col-xl-4">
																					<div className="type-card">
																						<i className="phycl"></i>
																						<p>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Physical_Card_Ref_No}</p>
																						<h6>{cards.cardReferenceNo}</h6>
																					</div>
																				</div>
																				:
																				<div className="col-12 col-md-12 col-lg-5 col-xl-4">

																					<div className="type-card">
																						<i className="vrtl"></i>
																						<p>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Virtual_Card_Ref_No}</p>
																						<h6>{cards.cardReferenceNo}</h6>
																					</div>
																				</div>
																		}

																		<CardStatus cardStatus={parseInt(cards.cardStatus)} type='txt' classNameToSet="col-4 col-md-4 col-lg-3 " cardStatusClassName="type-card-status" />

																		<div className="col-8 col-md-8 col-lg-4 col-xl-5 ">
																			<div className="managecard-action">

																				{
																					(parseInt(cards.cardStatus) !== CardStatusType.ASSIGNED) &&

																					<Link

																						to={{
																							pathname: '/transaction-report',
																							state: { mdn: employeeDetail },
																						}}
																						className="sbtn btnbl"
																					>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Transaction}</Link>
																				}
																				{kyc_status === KycStatus.KYC_DONE
																					?
																					null
																					:
																					<Link
																						to={{
																							pathname: '/user-kyc',
																							state: { post: employeeDetail, employee: employeeDetail, from: "/emp-detail" }
																						}}
																						className="sbtn btngn"
																					>
																						{
																							kyc_status === KycStatus.MIN_KYC || kyc_status === KycStatus.AADHAR_BASED
																								?
																								<>
																									{process.env.REACT_APP_UPGRADE_KYC_VARIABLE}
																								</>
																								:
																								<>
																									{process.env.REACT_APP_DO_KYC_VARIABLE}
																								</>
																						}
																					</Link>

																				}
																				{
																					(parseInt(cards.cardStatus) === CardStatusType.ASSIGNED) &&

																					<ActivateCardComponet cards={cards} employeeDetail={employeeDetail} classActivate="sbtn btnbl" classUnAssign="sbtn btnrd" />
																				}
																			</div>
																		</div>

																	</div>
																	<hr></hr>
																</>

														))

													}
												</div>


												<Can
													role={props.userRole}
													perform="emp-detail:moneyDisbursal"
													roleData={props.roleData}
													yes={() => (

														parseInt(employeeDetail.kyc_status) === KycStatus.Documents_Not_Submitted ? null :
															<div className="manage-limit">
																<h3 className="subhead">{process.env.React_APP_Var_FOR_Employee_Detail_Page_Money_Disbursal} <span className="infotxt"><br />Last disbursal date: {lasttxndate}</span></h3>

																<form className="row" onSubmit={otpGeneration} >
																	{walletArray.map(wallet =>

																		<div className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl">
																			<div className="form-floating">
																				<input className="form-control" type="number" placeholder="Enter Amount" step="0.01" name={wallet.label} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} id={wallet.wallet_id} min={minAm} max={wallet.max} onChange={handleChange} disabled={!checkLimit} />
																				<label for={wallet.wallet_id} >{wallet.label}</label>
																			</div>
																		</div>


																	)}

																	{checkLimit
																		?
																		<div className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl">
																			<button type='submit' id='btnSub' className="mbtn btnbl float-md-end" >Disburse Now</button>
																		</div>
																		:
																		<div className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl">
																			<button type='button' className="mbtn btnbl float-md-end" id='btnDisbur1' data-bs-toggle="modal" data-bs-target='#popup-verify-disburse' >Disburse Now</button>
																		</div>

																	}
																</form>
															</div>

													)}
													no={() => null}
												/>

												<div className="load-info">
													<h3 className="subhead">{process.env.React_APP_Var_FOR_Employee_Detail_Page_LoadLimitsInformation}</h3>
													<div className="row">
														<div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
															<div className="load-limit">
																<h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_MonthlyLimit}</h5>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.monthly_available_limit / 100).toFixed(2)}</span>
																	}
																</label>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Consumed}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.monthly_consumed_limit / 100).toFixed(2)}</span>
																	}
																</label>
															</div>
														</div>

														<div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
															<div className="load-limit">
																<h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Annual_Limit}</h5>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.annual_available_limit / 100).toFixed(2)}</span>
																	}
																</label>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Consumed}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.annual_consumed_limit / 100).toFixed(2)}</span>
																	}
																</label>
															</div>
														</div>

														<div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
															<div className="load-limit">
																<h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_AnnualFoodLimit}</h5>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.annual_food_available_limit / 100).toFixed(2)}</span>
																	}
																</label>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Consumed}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.annual_food_consumed_limit / 100).toFixed(2)}</span>
																	}
																</label>
															</div>
														</div>

														<div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
															<div className="load-limit">
																<h5>{process.env.React_APP_Var_FOR_Employee_Detail_Page_HoldingLimit}</h5>
																<label>{process.env.React_APP_Var_FOR_Employee_Detail_Page_Available}
																	{loading
																		?
																		<Skeleton animation="wave" variant="square" />
																		:
																		<span>{parseFloat(balanceEnquiry.Balance.holding_available_limit / 100).toFixed(2)}</span>
																	}
																</label>
															</div>
														</div>

													</div>
												</div>

											</div>
										</div>

									</div>
								</div>
							</div>
							<div className="modal fade" id="edtpropic" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
								<div className="modal-dialog modal-sm">
									<div className="modal-content alerttext">
										<div className="modal-header">
											<h5 id="">{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_UPDATE_IMAGE_HEADING}</h5>
											<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
										</div>
										<div className="modal-body">
											<UploadImage currentUser={props.currentUser}
												header=""
												h6Value={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_DRAG_AND_DROP}
												h6SpanValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_BROWSE}
												h6ValueAfterbreak={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_PROFILE_PIC}
												pValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FILE_DETAIL}
											/>
										</div>
										<div className="modal-footer">
											<button type="button" className="mbtn btnbl" onClick={(e) => updateProfileLogo(e)} disabled={updateProfileProcessBar}>
												{
													updateProfileProcessBar
														?
														<div className="spinner-border" role="status">
															<span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
														</div>
														:
														<>Save</>}</button>
										</div>
									</div>
								</div>
							</div>

							<SmallPopUp
								targetid="popup-remove"
								headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Lable}
								bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Body}
								onclickFunctionName={handleRemoveEmployee}
								allValues={employeeDetail}
								buttonActionName={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_Button_Remove}
								buttonActionClose={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_Button_Cancel}
							>
							</SmallPopUp>

							<VerifyDisburse
								varFor='otp'
								totalAmount={parseFloat(totalAmount).toFixed(2)}
								targetid="popup-verify-disburse"
								allValues={employeeDetail}
								buttonActionName="Disburse"
								handleChange={handleChange}
								formDisburseSubmit={formDisburseSubmit}
								handleResendOtp={handleResendOtp}
								modal={modal}
								Close={close}
								mystring={mystring}
								closeToggle={closeToggle}
								otp={otp}
								password={password}
								time={time}
								triggeretime={triggeretime}
								referenceNumber={referenceNumber}
							/>
						</div>
						:
						<ResetLoginPassword />

				)
				: (
					<Redirect to='/' />
				)

			}

		</>
	)
}



const mapStateToProps = ({ band: { bandData }, dropdown: { hidden }, message: { message }, token: { authenticated, token, isPasswordReset }, kyc: { masterRoleData },
	role: { roleData }, user: { empDetail, roMdn, userRole, currentUser, mdn, fullName, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount }, location: { departmentaList },
	card: { availableCard }, user: { backUrl }

}) => ({
	hidden,
	message,
	authenticated,
	currentUser, token, empDetail,
	roMdn, mdn,
	maxFoodAmount, maxGeneralAmount, maxTravelAmount,
	bandData, corpBand,
	masterRoleData, availableCard, backUrl,
	departmentaList, fullName, userRole, roleData, isPasswordReset
});
const mapDispatchToProps = dispatch => ({
	toggleDropDownHidden: (data) => dispatch(toggleDropDownHidden(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetail);