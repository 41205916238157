import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import MoneyDisbursalCard from '../component/money-disbursal.card.component';

import Can from '../../auth/can/Can';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

const MoneyDisbursal = ({ authenticated, roleData, userRole, isPasswordReset }) => {

    useEffect(() => {
        document.title = "ShakePe Money Disbursal"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));
    }, []);

    return (<div >
        {(authenticated)
            ? (
                isPasswordReset
                    ?

                    <Can
                        role={userRole}
                        perform="subheader-moneyDisbursal:visit"
                        roleData={roleData}
                        yes={() => (
                            <div className="mid-container" id="heightFix">
                                <div className="container-fluid">
                                    <div className="row">


                                        <MoneyDisbursalCard />

                                    </div>
                                </div>
                            </div>
                        )}
                        no={() =>
                            <Redirect to="/manage-card" />

                        }
                    />
                    :
                    <ResetLoginPassword />



            )
            : (
                <Redirect to='/' />
            )


        }

    </div>

    )

}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole }, role: { roleData } }) => ({
    authenticated, userRole, roleData, isPasswordReset
});

export default connect(mapStateToProps)(MoneyDisbursal);