import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import '../css/money-disbursal.styles.css'
import { setEditBandDetail } from '../../redux/edit-band-limit/edit-band-limit.action';
import Can from '../../auth/can/Can';
import ReactTooltip from 'react-tooltip';

const SubEmployeeBandDiv = ({ userRole, roleData, setEditBandDetailLink, data, authenticated, roMdn, size }) => {



    // console.log('current post', currentPost)
    return (
        <div >
            {(authenticated)
                ? (
                    <div className="empband">
                        <ReactTooltip
                            id="back"
                            place="top"
                            type='dark'
                            effect='solid'
                            // multiline={true}
                            backgroundColor="black"
                        />

                        <Can
                            role={userRole}
                            perform="editBand-update:visit"
                            roleData={roleData}
                            yes={() => (

                                <Link
                                    to={{
                                        pathname: '/band-details',
                                        state: { data }
                                    }}
                                    className="edit" id={data.band} onClick={setEditBandDetailLink}
                                    data-for="back" data-tip="Edit" data-iscapture="true"
                                > Update</Link>

                            )}
                            no={() =>
                                null
                            }
                        />



                        <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Employees}</p>
                        <h5>{data.assignedEmployee === null ? 0 : data.assignedEmployee}</h5>
                    </div>


                )
                : (
                    <Redirect to='/' />
                )


            }

        </div>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount,
    maxFoodAmount,
    maxTravelAmount }, user: { userRole }, role: { roleData }, token: { authenticated, token }, band: { bandData }, }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, userRole, roleData,
        maxTravelAmount, token
    });

export default connect(mapStateToProps)(SubEmployeeBandDiv);

