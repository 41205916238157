import React from "react";
import SubTranstype from './subtrasactiontypes';

const CardStatus = ({ sub_transaction_type, merchant_name, type,mcc }) => (
    // console.log('sub_transaction_type for', sub_transaction_type),
    (() => {
        switch (sub_transaction_type) {
            case SubTranstype.MERCHANT_PAYMENT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MERCHANT_PAYMENT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")} </>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>
                );
            case SubTranstype.WITHDRAWAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_WITHDRAWAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")} </>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.SURCHARGE:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_SURCHARGE} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.LOAD:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_LOAD} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );
            case SubTranstype.SELF_Load:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_LOAD} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_LOAD:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_LOAD} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.DEBIT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_DEBIT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.CREDIT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_CREDIT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_DEBIT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_DEBIT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_CREDIT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_CREDIT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_WITHDRAWAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_WITHDRAWAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_MERCHANT_PAYMENT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_MERCHANT_PAYMENT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MERCHANT_PAYMENT_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MERCHANT_PAYMENT_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.WITHDRAWAL_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_WITHDRAWAL_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.SURCHARGE_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_SURCHARGE_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.LOAD_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_LOAD_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_MERCHANT_PAYMENT_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_MERCHANT_PAYMENT_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''} </h5>

                );

            case SubTranstype.MANUAL_WITHDRAWAL_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_WITHDRAWAL_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.MANUAL_SURCHARGE_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_SURCHARGE_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.MANUAL_LOAD_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_LOAD_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.ECOMMERECE_PAYMENT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_ECOMMERECE_PAYMENT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.MANUAL_ECOMMERECE_PAYMENT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_ECOMMERECE_PAYMENT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.ECOMMERECE_PAYMENT_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_ECOMMERECE_PAYMENT_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );

            case SubTranstype.MANUAL_ECOMMERECE_PAYMENT_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_MANUAL_ECOMMERECE_PAYMENT_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );




            case SubTranstype.DEBIT_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_DEBIT_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );



            case SubTranstype.DISBURSAL_DEBIT:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_DISBURSAL_DEBIT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );



            case SubTranstype.DISBURSAL_DEBIT_REVERSAL:
                return (
                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_DISBURSAL_DEBIT_REVERSAL} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>

                );






                case SubTranstype.P2P_TRANSACTIONS:
                    return (
                        <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_P2P} {merchant_name === null || merchant_name === "" ? "" : <>{merchant_name.replace("*", " ")}</>} {mcc !== undefined && mcc !== null ? `(${mcc})` : ''}</h5>
    
                    );
    





            default:
                return (

                    <h5>{process.env.React_APP_DESCRIPTION_FOR_TXN_TYPE_FOR_DEFAULT} {merchant_name === null || merchant_name === "" ? "" : <>{process.env.React_APP_DESCRIPTION_ON_TXN_TYPE_FOR_DASH} {merchant_name.replace("*", " ")}</>}</h5>

                )
        }
    })()

);



export default CardStatus;
