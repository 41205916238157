import React from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      // window.location.reload()
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>
        {process.env.REACT_APP_CAPTCHA_NOT_LOAD_MESSAGE_BEFORE_LINK}
        <a href="/" style={{ color: "#005da5" }}>   {process.env.REACT_APP_CAPTCHA_NOT_LOAD_MESSAGE_LINK_VARIBLE} </a>
        {process.env.REACT_APP_CAPTCHA_NOT_LOAD_MESSAGE_AFTER_LINK}

      </>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;