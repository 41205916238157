import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import CardStatusType from '../../constants/card-status.type';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import apiCallService from '../../service/api-call-service';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SmallPopUp from "../../common/component/small.popup.component";
import Countdown from 'react-countdown';
import KycStatus from '../../constants/kyc-status.type';
class ActivateCard extends React.Component {

    constructor() {
        super();

        this.state = {
            dropDownHidden: false,
            severty: "error",
            message: "",

            otpRefNum: "",
            buttonProcessBar: false,
            otp: "",

            popUpButtonShow: true,
            disableVar: true,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            triggeretime: false,

            kycDisable: false,
            activateCardDivShow: false,

            verifyUserShow: false,


        };
    }


    componentDidMount = () => {

        console.log("value of activate card", this.props.cards)


    }


    unAssignUserCard = async (e, value) => {
        e.preventDefault();
        console.log(" unAssignUserCard card values", value);


        var url = process.env.REACT_APP_UNASSIGN_CARD_FROM_USER;

        var request = {
            "roId": value.roId,
            "mdn": value.mdn,
            "cardReferenceNo": value.cardReferenceNo,
            "cardStatus": CardStatusType.NOT_ASSIGNED,
            "unAssignedBy": this.props.fullName,
            "unAssignedByEmail": this.props.currentUser
        }

        var response = await apiCallService.postMethodCall(url, request);

        console.log("response for unassigned card", response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {

            this.setState({
                dropDownHidden: true,
                message: "Card unassigned successfully",
                severty: "success"
            })

            window.location.reload();

        } else {
            this.setState({
                dropDownHidden: true,
                message: response.response_description,
                severty: "error"
            })

        }
    }




    activateCard = async (e, value) => {
        e.preventDefault();


        var url = `${process.env.REACT_APP_GET_getCardDetailByOrderId}`;

        var response = await apiCallService.getMethodCallWithParam(url, { orderId: value.cardReferenceNo });

        console.log(" unAssignUserCard card values", response);
        if (response !== null && response.status !== undefined && response.status === 0 && response.getCardOrder !== undefined) {

            var urlActivate = process.env.REACT_APP_ACTIVATE_USER_CARD;

            this.props.employeeDetail.roId = this.props.employeeDetail.roMdn

            var request = {
                "employeeDetailRequest": this.props.employeeDetail,
                "cardRefNum": value.cardReferenceNo,
                "panNum": this.props.employeeDetail.pan,
                "aadharNum": this.props.employeeDetail.aadhar_no,
                "lastFOurDigit": response.getCardOrder.lastFourDigit,
                "updatedBy": this.props.fullName,
                "updatedByEmail": this.props.currentUser,
            }

            var responseActivate = await apiCallService.postMethodCall(urlActivate, request);

            console.log("response for Activate card", responseActivate);
            if (responseActivate !== undefined && responseActivate.response_code !== undefined && responseActivate.response_code !== null && parseInt(responseActivate.response_code) === 0) {

                this.setState({
                    dropDownHidden: true,
                    message: "Card Activated successfully",
                    severty: "success"
                })

                window.location.reload();

            } else {
                this.setState({
                    dropDownHidden: true,
                    message: responseActivate.response_description,
                    severty: "error"
                })

            }



        }
        else {

        }



    }



    registerUser = async (event) => {
        event.preventDefault();

        this.setState({ buttonProcessBar: true });
        // setSubmitButtonProcessBar(true)
        const url = `${process.env.REACT_APP_OEMS_REGESTER_USER_FOR_KYC}/${this.props.employeeDetail.mdn}`

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log(response)
        this.setState({ triggeretime: false });
        // setTriggeretime(false);


        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            this.setState({ popUpButtonShow: false })
            this.setState({ verifyUserShow: true })
            this.setState({ otpRefNum: response.response.otp_ref_number })
            this.setState({ buttonProcessBar: false })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true });

            document.getElementById('sendOtpPopup').click();

        } else {
            this.setState({ buttonProcessBar: false })
            this.setState({ activateCardDivShow: true })


            if (response.response_description === "User already registered.") {
                this.setState({ popUpButtonShow: false })

                document.getElementById('activateCard').click();
            } else {
                this.setState({
                    dropDownHidden: true,
                    message: response.response_description,
                    severty: "error"
                })
            }
        }
    }


    resendOtp = async (event) => {
        const url = `${process.env.REACT_APP_OEMS_REGESTER_USER_FOR_KYC}/${this.props.employeeDetail.mdn}`



        var response = await apiCallService.getMethodCallWithoutParam(url);
        // setTriggeretime(false);
        this.setState({ triggeretime: false });
        // console.log("Is this the div which is opened", Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT), "time : ", time)
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {

            this.setState({
                dropDownHidden: true,
                message: process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile,
                severty: "sucess"
            })

            this.setState({ otpRefNum: response.response.otp_ref_number })

            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })

            this.setState({ triggeretime: true });

        } else {


            this.setState({
                dropDownHidden: true,
                message: response.response_description,
                severty: "error"
            })

        }
    }





    verifyUserRegister = async (event) => {
        event.preventDefault();
        // setkycDisable(true);

        this.setState({ kycDisable: true })




        var request = {
            "mobile_number": this.props.employeeDetail.mdn,
            "OTP_reference_number": this.state.otpRefNum,
            "otp": this.state.otp
        }



        console.log(request)

        const url = process.env.REACT_APP_VERIFY_MOTP_USER_REGISTER;

        var response = await apiCallService.postMethodCall(url, request);

        console.log("response", response);

        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            this.setState({ activateCardDivShow: true })
            this.setState({ verifyUserShow: false })
            this.setState({ kycDisable: false })

            document.getElementById('closeDic').click();

            document.getElementById('activateCard').click();

        } else {
            this.setState({ kycDisable: false })
            this.setState({
                dropDownHidden: true,
                message: response.response_description,
                severty: "error"
            })
            if (response.response_description !== undefined && response.response_description !== null && response.response_description.toLowerCase().includes("otp")) {

            } else {
                document.getElementById('closeDic').click();
            }
        }


    }





    setOtpChange = (event) => {
        this.setState({ otp: event.target.value })

    }
    MethodTosetDisabledVar = () => {
        this.setState({ disableVar: false })

    }
    MethodTosetEnableVar = () => {
        this.setState({ disableVar: true })

    }

    validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }







    render() {
        console.log("value of activate kycStatus", this.props.cards)
        var kycStatus = ''
        // this.props.cards !== undefined && this.props.cards != null && this.props.cards.map(op => {

        kycStatus = this.props.cards.kycStatus;
        // })


        console.log("value of activate kycStatus", kycStatus)

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={this.state.dropDownHidden}
                                    autoHideDuration={3000}
                                    onClose={e => this.setState({ dropDownHidden: false })}
                                >
                                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                        {this.state.message}
                                    </Alert>
                                </Snackbar>
                                {
                                    (parseInt(this.props.cards.cardStatus) === CardStatusType.ASSIGNED) &&

                                    <>
                                        <button type="button"

                                            className={this.props.classUnAssign}
                                            data-bs-toggle="modal" data-bs-target="#unAssignedCard">


                                            <>Unassign Card</>


                                        </button>


                                        {





                                            parseInt(kycStatus) === KycStatus.MIN_KYC || parseInt(kycStatus) === KycStatus.KYC_DONE || parseInt(kycStatus) === KycStatus.KYC_DONE
                                                ?

                                                <button

                                                    className={this.props.classActivate}
                                                    id="activateCard"
                                                    type="button" data-bs-toggle="modal" data-bs-target="#activateCard123" >
                                                    <>{process.env.REACT_APP_VAR_FOR_ACTIVATE_CARD_BUTTON} </>

                                                </button>
                                                :
                                                null


                                            // </>

                                        }



                                        <SmallPopUp
                                            targetid="activateCard123"
                                            headerText={process.env.REACT_APP_ACTIVATE_CARD_headerText}
                                            bodyText={process.env.REACT_APP_ACTIVATE_CARD_bodyText}
                                            onclickFunctionName={this.activateCard}
                                            allValues={this.props.cards}
                                            buttonActionName={process.env.REACT_APP_ACTIVATE_CARD_okButton}
                                            buttonActionClose={process.env.REACT_APP_ACTIVATE_CARD_cancelButton}
                                        >
                                        </SmallPopUp>

                                        <SmallPopUp
                                            targetid="unAssignedCard"
                                            headerText={process.env.REACT_APP_UNASSIGNED_CARD_headerText}
                                            bodyText={process.env.REACT_APP_UNASSIGNED_CARD_bodyText}
                                            onclickFunctionName={this.unAssignUserCard}
                                            allValues={this.props.cards}
                                            buttonActionName={process.env.REACT_APP_UNASSIGNED_CARD_okButton}
                                            buttonActionClose={process.env.REACT_APP_UNASSIGNED_CARD_cancelButton}
                                        >
                                        </SmallPopUp>

                                        <div className="modal fade" id="sendotp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                            <form className="modal-dialog modal-sm" onSubmit={this.verifyUserRegister}>
                                                <div className="modal-content alerttext">
                                                    <div className="modal-header">
                                                        <h5>One-Time-Password </h5>
                                                        <button className="close" id="closeDic" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="otptxt">
                                                            <input className="form-control" type="number" placeholder="Enter OTP" maxLength="6"
                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                onInput={this.validateInputLenght} value={this.state.otp} onChange={this.setOtpChange} required />
                                                            <p>We have sent the OTP on this mobile number: {this.state.myString}XXXXXX</p>

                                                            <h5>
                                                                {this.state.triggeretime ?
                                                                    <Countdown date={this.state.time} onMount={this.MethodTosetEnableVar} onComplete={this.MethodTosetDisabledVar} />
                                                                    :
                                                                    null
                                                                }
                                                            </h5>
                                                        </div>

                                                    </div>
                                                    <div className="modal-footer">

                                                        {
                                                            this.state.disableVar
                                                                ?
                                                                <button type="button" className="mbtn btngr" onClick={this.resendOtp} disabled={this.state.disableVar}>

                                                                    Resend OTP

                                                                </button>
                                                                :
                                                                <button type="button" className="mbtn btngn" onClick={this.resendOtp} disabled={this.state.disableVar}>

                                                                    Resend OTP

                                                                </button>
                                                        }

                                                        <button type="submit" className="mbtn btnbl" disabled={this.state.kycDisable}>

                                                            {
                                                                this.state.kycDisable

                                                                    ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                    </div>
                                                                    :
                                                                    <>Submit</>

                                                            }


                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }

                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, fullName, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, fullName, currentUser

});

export default connect(mapStateToProps)(ActivateCard);



