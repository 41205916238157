import React from "react";
import oxigenLogo from '../../assests/logo.png';

import { ReCaptcha } from 'react-recaptcha-google'
import { loadReCaptcha } from 'react-recaptcha-google'
import { TextField } from '@material-ui/core';
import AuthService from '../../service/service-impl/AuthService';
import history from '../../auth/history';
import { store } from "../../redux/store";
import { setRememberMe } from '../../redux/rememberMe/rememberMe.action';
import { connect } from "react-redux";

import { sha256 } from 'js-sha256';

import ErrorBoundary from './ErrorBoundary';
import apiCallService from "../../service/api-call-service";

import shakePeLogo from '../../assests/shakepe-logo.png'

class LoginComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            response: null,
            captchaDone: false,
            loginBtnDisable: false
        }
    }


    componentDidMount() {
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }

        loadReCaptcha();


        if (this.props.rememberMe) {
            this.setState({ email: this.props.rememberMail });
            this.setState({ password: this.props.rememberPassword });
        }


        // if (localStorage.getItem('errorMessage') === "Login is in process please wait") {
        //     localStorage.setItem('errorMessage', '');
        // }


    


    }

    



    onLoadRecaptcha = () => {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
        console.log('Testing-onLoadRecaptcha :' + this.state)
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({ captchaDone: true });
        console.log(recaptchaToken, "<= your recaptcha token")
    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
        console.log('Testing-handleEmailChange :' + this.state)
    }

    handlePasswordChange = (event) => {
        event.preventDefault();
        this.setState({
            password: event.target.value
        })
        console.log('Testing-handlePasswordChange :' + this.state)
    }

    onChange = (event) => {
        this.setState({
            otp: event.target.value
        })
        console.log('Testing-handlePasswordChange :' + this.state.otp)
    }

    handleSubmit = (event) => {


        event.preventDefault();
        if (this.props.rememberMe) {
            localStorage.setItem('errorMessage', '')
        }
        console.log('inside initiateLogin successfully', event);

        var emailGG = this.state.email
        var passwordGG = this.state.password

        if (event.target[3] !== undefined) {
            var captchaValue = event.target[3].value;
        }

        console.log('inside initiateLogin successfully', username);
        var password = event.target[1].value;
        var datEncrypted = this.generateHash(password);
        console.log('inside initiateLogin successfully', captchaValue);
        if (process.env.REACT_APP_CAPTCHA_CONFIG !== '1') {
            if (captchaValue === 'false' || this.state.captchaDone === false) {
                return (localStorage.setItem('errorMessage', process.env.REACT_APP_CAPTCHA_NOT_CHECKED_MESSAGE),
                    this.setState({ email: emailGG })),
                    this.setState({ password: passwordGG })
                // history.push('/');
                // history.go();
            }
        }

        if (event.target[3] !== undefined) {
            event.target[3].innerText = 'Logging in...'
            event.target[3].className = 'btngr lbtn ';
            event.target[3].disabled = true;
            console.log('inside initiateLogin successfully', password);
        }
        var username = event.target[0].value;



        localStorage.setItem('isbuttonDisabled', false);
        // event.refs.btn.setAttribute("disabled", "disabled");

        ////////////////////////////ACL-SERVICE-CALL////////////////////////////////////////////////////////
        this.setState({ loginBtnDisable: true });
        AuthService.apiCall(process.env.REACT_APP_LOGIN_URL, datEncrypted, username, this.props.rememberMe, 'OEMSAPP');
    }

    generateHash = (messageBody) => {
        //var msg= JSON.stringify(messageBody) ;
        var privateKey = '1234:aabbccdd';
        var hash = sha256.hmac.create(privateKey);
        hash.update(messageBody);
        var hexData = hash.hex();
        console.log('hexed data', hexData);
        var base64String = btoa(hexData);
        console.log(messageBody, '---->passowrd hash Generated --->', base64String);
        return base64String.replace(/[^a-zA-Z0-9]/g, "");
    }

    setRememberMe = (event) => {
        store.dispatch(setRememberMe(event.target.checked));
    }
    onBlurFunc = (e) => {
        var id = document.getElementById('password').readOnly = true;
    }
    onClickPassword = (e) => {
        var id = document.getElementById('password').readOnly = false;
    }


    handleChange = (e) => {
        e.preventDefault();
    };

    forgotPasswordClick = (e) => {

        if (this.state.loginBtnDisable) {
            // localStorage.setItem('errorMessage', 'Login is in process please wait')
        } else {
            history.push('/forgot-password');
            history.go();
        }


    }

    render() {
        return (


            <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <div className="logbox">
                    <div className="logtxt">
                        <img src={shakePeLogo} alt="ShakePe" className="img-fluid" />
                        <h3>Partner Admin Dashboard</h3>
                        <p>Use your credentials to access your account.</p>
                    </div>

                    <div className="login-form">
                        <form onSubmit={this.handleSubmit} autoComplete="off">

                            <div className='text-danger'>{localStorage.getItem('errorMessage')}</div>

                            {this.props.rememberMe ? null : localStorage.setItem('errorMessage', '')}

                            <div className="form-row">
                                <div className="form-floating">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email ID"
                                        spellCheck="false"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        required
                                    />

                                    <label for="email">{process.env.REACT_APP_Var_FOR_LoginForm_Email_Label} </label>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-floating">
                                    <input
                                        type="password"
                                        className="form-control abcd"
                                        id="password"
                                        placeholder="Password"
                                        autoComplete="off"
                                        pattern="^([@#](?=[^aeiou]{7,13}$)(?=[[:alnum:]]{7,13}$)(?=.*[A-Z]{1,}.*$).+)$"
                                        onClick={this.onClickPassword}
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                        onBlur={this.onBlurFunc} 
                                        readOnly={true}
                                        spellCheck="false"
                                        onCut={this.handleChange}
                                        onCopy={this.handleChange}
                                        onPaste={this.handleChange}
                                        required
                                    />
                                    {/* <input
                                        type="password"
                                        className='form-control  abcd'
                                        id='password'
                                        placeholder={process.env.REACT_APP_Var_FOR_LoginForm_Password_Label}
                                        autoComplete="off"
                                        pattern="^([@#](?=[^aeiou]{7,13}$)(?=[[:alnum:]]{7,13}$)(?=.*[A-Z]{1,}.*$).+)$"
                                        onClick={this.onClickPassword}
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                        onBlur={this.onBlurFunc}
                                        readOnly={true}
                                        spellCheck="false"
                                        onCut={this.handleChange}
                                        onCopy={this.handleChange}
                                        onPaste={this.handleChange}
                                        required /> */}


                                    <label for="pwd">Password</label>
                                </div>
                            </div>

                            <div className="form-row">
                                {/* <img src="images/captcha.png" alt="captcha" className="img-fluid" /> */}
                                <ErrorBoundary>
                                    <ReCaptcha
                                        id='captcha'
                                        ref={(el) => { this.captchaDemo = el; }}
                                        size="normal"
                                        data-theme="light"
                                        render="explicit"
                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                        onloadCallback={this.onLoadRecaptcha}
                                        verifyCallback={this.verifyCallback}
                                        isCaptured={this.state.captchaDone}
                                        required
                                    />
                                </ErrorBoundary>
                            </div>

                            <div className="form-row">
                               
                                <button className='lbtn btnbl' id="logBtn" type='submit' value={this.state.captchaDone} disabled={this.state.loginBtnDisable} >{process.env.REACT_APP_Var_FOR_LoginForm_Button_Label} </button>
                              
                                <button type="button" onClick={this.forgotPasswordClick} className='forgot' id="forgetId">{process.env.REACT_APP_Var_FOR_LoginForm_Link_ForgetPassword}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        )
    }

}


const mapStateToProps = ({ rememberMe: { rememberMe, rememberPassword, rememberMail } }) => ({
    rememberMe, rememberPassword, rememberMail
})

export default connect(mapStateToProps)(LoginComponent);