import React, { useState, useEffect } from "react";
import '../styles/corporate.css'
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import moment from 'moment';
import GenerateRequestId from "../../common-function/GenerateRequestId";
import apiCallService from "../../service/api-call-service";
import { store } from "../../redux/store";
import { setActiveHeader } from "../../redux/activeHeader/activeheader.action";
import CategoryLimit from "../../constants/category-limit.type";
import Avatar from "react-avatar";
import history from '../../auth/history';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ResetLoginPassword from "../../corporate-profile/component/reset-login-password";
import ReactTooltip from 'react-tooltip';
import UploadImage from '../../employee/component/upload-image.component';
import CreatableSelect from 'react-select/creatable';
import img from '../../assests/icons/arrow-down.svg'
import { sha256 } from 'js-sha256';
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import { convertToObject } from "typescript";
import { resetUser, setBankAddAndUpdateUrl, setBankBackButton, setButtonName } from "../../redux/user/user.actions";
import { setAdminLoginUserEmail, setAdminLoginUserRoId, setAdminViewType } from "../../redux/adminView/adminView.action";
import { resetToken, setToken } from "../../redux/token/token.action";
import { resetRole } from "../../redux/role/role.action";
import Can from "../../auth/can/Can";


const CorporateProfile = ({ authenticated, token, roMdn, currentUser, location, isPasswordReset, roleData, userRole }) => {




    const [corpDetail, setCorpDetail] = useState([])
    const [cardDetail, setCardDetail] = useState([])
    const [balanceDetail, setBalanceDetail] = useState(0)
    const [lienBalance, setLienBalance] = useState(0)

    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message, setMessage] = useState('');
    const [severty, setSeverty] = useState('');

    const [showEdit, setShowEdit] = useState(false);

    const [corpDetailToUpdate, setCorpDetailToUpdate] = useState([]);


    const [districtBilling, setDistrictBilling] = useState('');

    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [cityList, setCityList] = useState([]);


    const [pricePlan, setPricePlan] = useState([]);

    const [corpPlanAssigned, setCorpPlanAssigned] = useState([]);
    const [upgradeCorpPlanAssigned, setUpgradeCorpPlanAssigned] = useState([]);

    const [showAddEmployee, setShowAddEmployee] = useState(false);

    const [corporateDocuments, setCorporateDocuments] = useState([]);


    const [documentName, setDocumentName] = useState('');
    const [urlFF, setUrl] = useState('');

    const [documentNameForSelect, setDocumentNameForSelect] = useState('');


    const [documentList, setDocumentList] = useState([]);

    const [inputKey, setInputKey] = useState(Date.now());

    const [files, setFiles] = useState([]);
    const [size, setSize] = useState(0);
    const [image, setImage] = useState('');

    const [hidden, setHidden] = useState('hidden');
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState();
    const [uploadPercentage, setuploadPercentage] = useState('')
    const [total, setTotal] = useState('');
    const [loaded, setLoaded] = useState('');


    const [uploadedDocumemnt, setUploadedDocument] = useState([]);


    const [upgradePlanProcessBar, setUpgradePlanProcessBar] = useState(false);

    const [activateCorpProcessBar, setActivateCorpProcessBar] = useState(false);

    const [deleteCorpProcessBar, setDeleteProcessBar] = useState(false);

    const [documentUpdateProcessBar, setDocumentUpdateProcessBar] = useState(false);

    const [updateCorpProcessBar, setUpdateCorpProcessBar] = useState(false);

    const [showInputDoc, setshowInputDoc] = useState(false);

    const [tableLoading, setTableLoading] = useState(false);
    const [tableDate, setTableDate] = useState([]);

    const generateHash = (messageBody) => {
        //var msg= JSON.stringify(messageBody) ;
        var privateKey = process.env.REACT_APP_CLIENT_ID;
        var hash = sha256.hmac.create(privateKey);
        hash.update(messageBody);
        var hexData = hash.hex();
        console.log('hexed data', hexData);
        var base64String = btoa(hexData);
        console.log(messageBody, '---->hash Generated --->', base64String);
        return base64String;
    }



    useEffect(() => {
        document.title = "ShakePe Corporate Profile"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            corpListClass: "active"

        }

        store.dispatch(setActiveHeader(payload))
        localStorage.setItem('url', '');

        fetchDetail();

        getCardDetail();

        getBalanceDetail();

        getStates();

        getPricePlan();
        getAssignedPlan();
        getDocumemntAllowed();


    }, []);

    const getEmployeeAvailableOrNot = async () => {

        const url = process.env.REACT_APP_GET_EMPLOYEE_BY_ROID

        const res = await apiCallService.getMethodCallWithParam(url, { 'ro_Id': location.state.roId });
        console.log('response emp-list', res);
        if (res.response_code === 0 || res.status === 0) {
            setShowAddEmployee(false)
        } else {
            setShowAddEmployee(true)
        }
    }


    const getDocumemntAllowed = async () => {


        var url = process.env.REACT_APP_GET_CORP_DOCUMENT_ALLOWED;

        var response = await apiCallService.getMethodCallWithoutParam(url);


        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            var documentArray = [];
            response.documents.map(docu => {
                var value = "value";
                var label = "label";
                var isDisabled = "isDisabled";
                docu[isDisabled] = false
                docu[value] = docu.document
                docu[label] = docu.document
                documentArray.push(docu);

            })
        }


        // var documentArray = [{ "value": "Agreement", "label": "Agreement", isDisabled: false }, { "value": "Company Pan", "label": "Company Pan", isDisabled: false }, { "value": "Other", "label": "Other" }];

        setDocumentList(documentArray);
    }



    const getPricePlan = async () => {

        const url = process.env.REACT_APP_GET_CORPORATE_PRICE_PLAN;

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log(response);
        if (response !== undefined && response.pricePlans !== undefined && response.pricePlans !== null) {
            setPricePlan(response.pricePlans);
        }

    }


    const getAssignedPlan = async () => {
        const url = `${process.env.REACT_APP_Get_ASSIGNED_CORPORATE_PRICE_PLAN}?roId=${location.state.roId}`

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log('response getAssignedPlan', response);

        if (response !== undefined && response !== null && response.pricePlanCode !== undefined) {
            setCorpPlanAssigned(response.pricePlanCode);
        }
    }
    const getStates = async () => {

        var url = process.env.REACT_APP_OEMS_GET_ALL_STATES;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("state response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("state response", response.statesList);

            var arr = [];

            response.statesList.map(state => {
                var value = "value";
                var label = "label";
                state[value] = state.stateName;
                state[label] = state.stateName;
            })

            setStateList(response.statesList);
        }
    }

    const validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const getDistrict = async (id, type) => {

        var distListTOSet = [];
        console.log('get cities method call', id)
        var url = `${process.env.REACT_APP_OEMS_GET_ALL_DISTRICT}/${id}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("districtList response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("districtList response", response.districtList);

            response.districtList.map(disstrict => {

                var value = "value";
                var label = "label";
                disstrict[value] = disstrict.distName;
                disstrict[label] = disstrict.distName;
                distListTOSet.push(disstrict);


            })


            setDistrictList(distListTOSet);


        }

    }

    const getCities = async (id) => {

        var cityListTOSet = [];
        console.log('get cities method call', id)


        var urlDis = `${process.env.REACT_APP_OEMS_GET_ALL_CITIES}/${id}`;

        var responsecityList = await apiCallService.getMethodCallWithoutParam(urlDis);
        console.log("cityList response", responsecityList);

        if (responsecityList !== undefined && responsecityList.response_code !== undefined && responsecityList.response_code === 0) {
            console.log("cityList response", responsecityList.cityList);

            responsecityList.cityList.map(city => {
                var value = "value";
                var label = "label";
                city[value] = city.cityName;
                city[label] = city.cityName;
                cityListTOSet.push(city);


            })


            setCityList(cityListTOSet);

            console.log('setCityList(cityListTOSet);', cityList)
        } else {
            setCityList(cityListTOSet);
        }


    }



    const getCardDetail = async () => {
        const url = `${process.env.REACT_APP_GET_CARD_EMPLOYEE_COUNT}?roId=${location.state.roId}`;
        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log('ManageCard response ', response)
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setCardDetail(response)
        }

    }
    const getBalanceDetail = async () => {
        const request = {
         
         
            clientIndentifier: location.state.roId
            // clientIndentifier :'1932159428'
        };
        let headers = {
            "client_id": "OEMSAPP",
            "token": 'this.props.token',
            "Authorization": "BASIC abcd"
        }
        console.log("inside balanceEnquiry request :-", request, " Employee Details ");
        var response = [];

        const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY_CORP
        console.log(url, request, "header")
        response = await apiCallService.postMethodCall(url, request, headers);
        console.log('response balance', response, response?.response_code == 0)
        if (response !== undefined && response?.response_code !== undefined && response?.response_code == 0) {
            const detail = response.response;
           
            setBalanceDetail(response.response);
            setLienBalance(response.response)

        }
    }
    // const getBalanceDetail = async () => {
    //     const request = {
    //         device_info: {
    //             api_version: "1.0",
    //             app_version: "9.2",
    //             device_id: "d215ab007e05c498",
    //             device_os: "Android",
    //             device_os_version: "5.1.1",
    //             imei: "357869081907005",
    //             ip_address: "172.160.2.140",
    //             model_name: "SM-J320F",
    //             source: "mobile",
    //         },
    //         request: {
    //             user: {
    //                 username: location.state.roId
    //             },
    //             transaction_data: {
    //                 request_id: '988df6a3-8bd6-495e-8373-05bd4671ccfb',
    //                 limit_required: "true"
    //             }
    //         }
    //     };
    //     let headers = {
    //         "client_id": "OEMSAPP",
    //         "token": 'this.props.token',
    //         "Authorization": "BASIC abcd"
    //     }
    //     console.log("inside balanceEnquiry request :-", request, " Employee Details ");
    //     var response = [];

    //     const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
    //     console.log(url, request, "header")
    //     response = await apiCallService.postMethodCall(url, request, headers);
    //     if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
    //         const detail = response.response;
    //         console.log('response balanceEnquiry', response.response.Balance.total_balance);
    //         setBalanceDetail(response.response.Balance.total_balance);
    //         setLienBalance(response.response.Balance.total_lien)

    //     }
    // }

    const fetchDetail = async () => {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        const res = await apiCallService.getMethodCallWithParam(url, { 'roId': location.state.roId });

        console.log('CorporateProfile', res)

        if (res !== undefined && res.response_code !== undefined && res.response_code === 0 && res.response !== undefined) {
            setCorpDetail(res.response);
            setCorpDetailToUpdate(res.response);
            setCorporateDocuments(res.response.corporateDocuments);

            if (parseInt(res.response.is_active) === 1) {
                getEmployeeAvailableOrNot();
            }

            var distArray = []
            var cityzArray = []
            var districtListToSet = {
                distId: 96,
                distName: res.response.district,
                label: res.response.district,
                stateId: 7,
                value: res.response.district
            }

            var cityListToSet = {
                cityId: 599,
                cityName: res.response.city,
                districtId: 125,
                label: res.response.city,
                stateId: 12,
                value: res.response.city,
            }

            distArray.push(districtListToSet)
            cityzArray.push(cityListToSet);
            setDistrictList(distArray);
            setCityList(cityzArray)


        }

    }



    const deleteCorp = async () => {
        setDeleteProcessBar(true);
        var req = {
            "request": {
                "ro_id": corpDetail.roId,
                "corporateRequest": {
                    "is_active": 0,
                    "pricePlanCode": upgradeCorpPlanAssigned,
                    "status": "1",
                    "updated_by": currentUser,
                    "updated_byRoId": roMdn,
                    "updated_byEmail": currentUser,
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL_WITHOUT_OTP;

        console.log('response Corporate save', url, req, "currentUser : ", currentUser);

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setDeleteProcessBar(false);
            setDropDownHidden(true);
            setSeverty('success')
            setMessage(process.env.React_APP_Message_Corp_Profile_DELETE)
            history.push({
                pathname: '/corp-list',
                state: { roId: corpDetail.roId }
            })
            history.go();

        } else {
            setDeleteProcessBar(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);

        }
    }


    const activateCorp = async () => {

        setActivateCorpProcessBar(true);
        var req = {
            "request": {
                "ro_id": corpDetail.roId,
                "corporateRequest": {
                    "is_active": 1,
                    "pricePlanCode": upgradeCorpPlanAssigned,
                    "status": "1",
                    "updated_by": currentUser,
                    "updated_byRoId": roMdn,
                    "updated_byEmail": currentUser,
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL_WITHOUT_OTP;

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {

            setActivateCorpProcessBar(false);
            setDropDownHidden(true);
            setSeverty('success')
            setMessage(process.env.React_APP_Message_Corp_Profile_ACTIVATE)
            history.push({
                pathname: '/corp-list',
                state: { roId: corpDetail.roId }
            })
            history.go();

        } else {

            setActivateCorpProcessBar(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);

        }
    }


    const handleChange = e => {
        const { name, value, id } = e.target;
        console.log('target', e.target.getAttribute('key'));
        console.log('name :', name, 'value :', value)

        setCorpDetailToUpdate(prevState => ({
            ...prevState,
            [name]: value
        }));

    };






    const updateCorp = async (e) => {
        e.preventDefault();

        setUpdateCorpProcessBar(true);
        var imgUrl = corpDetailToUpdate.logoUrl;

        if (localStorage.getItem('url') !== '') {
            imgUrl = localStorage.getItem('url');
        }
        var fullAddress = `${corpDetailToUpdate.address1}, ${corpDetailToUpdate.address2}, ${corpDetailToUpdate.city}, ${corpDetailToUpdate.state} - ${corpDetailToUpdate.pincode}`;
        console.log("yes hitted")
        var req = {
            "request": {
                "ro_id": location.state.roId,
                "corporateRequest": {
                    "is_active": 1,
                    "name": corpDetailToUpdate.name,
                    "email": corpDetailToUpdate.email,
                    "companyNumber": corpDetailToUpdate.companyNumber,
                    "panTanNum": corpDetailToUpdate.panTanNum,
                    "gstNum": corpDetailToUpdate.gstNum,
                    "address": corpDetailToUpdate.address,
                    "contact_first_name": corpDetailToUpdate.contact_first_name,
                    "contact_last_name": corpDetailToUpdate.contact_last_name,
                    "contact_person": `${corpDetailToUpdate.contact_person}`,
                    "contact_number": corpDetailToUpdate.contact_number,
                    "contact_email": corpDetailToUpdate.contact_email,
                    "terminal_id": corpDetailToUpdate.terminal_id,
                    "callback": "null",
                    "created_byEmail": currentUser,
                    "pincode": corpDetailToUpdate.pincode,
                    "logoUrl": imgUrl,
                    "address1": corpDetailToUpdate.address1,
                    "address2": corpDetailToUpdate.address2,
                    "city": corpDetailToUpdate.city,
                    "state": corpDetailToUpdate.state,
                    "address": fullAddress,
                    "district": corpDetailToUpdate.district,
                    "updated_by": currentUser,
                    "updated_byRoId": roMdn,
                    "updated_byEmail": currentUser
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL_WITHOUT_OTP;

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setUpdateCorpProcessBar(false);
            setDropDownHidden(true);
            setSeverty('success')
            setMessage(process.env.React_APP_Message_Corp_Profile_Page_If_edit_corp_detail_and_click_in_save)

            history.push({
                pathname: '/corp-profile',
                state: { roId: corpDetail.roId }
            })
            history.go();

        } else {
            setUpdateCorpProcessBar(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);

        }
    }




    const updateCorpLogo = async (e) => {
        e.preventDefault();

        setUpdateCorpProcessBar(true);
        var imgUrl = corpDetailToUpdate.logoUrl;

        if (localStorage.getItem('url') !== '') {
            imgUrl = localStorage.getItem('url');
        }

        if (imgUrl === '') {
            return (
                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_MESSAGE_TO_UPLOAD_PICTURE_BEFORE_SUBMIT),
                setUpdateCorpProcessBar(false)

            )
        }
        console.log("yes hitted")
        var req = {
            "request": {
                "ro_id": location.state.roId,
                "corporateRequest": {
                    "created_byEmail": currentUser,
                    "logoUrl": imgUrl,
                    "updated_by": currentUser,
                    "updated_byRoId": roMdn,
                    "updated_byEmail": currentUser
                }
            }
        }

        var url = process.env.REACT_APP_GET_UPDATE_CORP_DETAIL_WITHOUT_OTP;

        var response = await apiCallService.postMethodCall(url, req);

        console.log('response Corporate save', url, req, response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setUpdateCorpProcessBar(false);
            setDropDownHidden(true);
            setSeverty('success')
            setMessage(process.env.React_APP_Message_FOR_SUCCESS_ON_PROFILE_PIC_UPDATE)

            history.push({
                pathname: '/corp-profile',
                state: { roId: corpDetail.roId }
            })
            history.go();

        } else {
            setUpdateCorpProcessBar(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);

        }
    }

    const handleStateChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        console.log('event.target', customAtrribute)


        const { name, value, id } = { name: "state", value: event.target.value };
        console.log('target', event.target.getAttribute('key'));
        console.log('name :', name, 'value :', value)

        setCorpDetailToUpdate(prevState => ({
            ...prevState,
            [name]: value
        }));


        // setState(event.target.value)
        getDistrict(customAtrribute, '');

    }
    const handleDistrictChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        const { name, value, id } = { name: "district", value: event.target.value };

        setDistrictBilling(event.target.value)
        setCorpDetailToUpdate(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log('event.target', customAtrribute)
        getCities(customAtrribute);

    }

    const handleCityChange = (event) => {

        const { name, value, id } = { name: "city", value: event.target.value };
        console.log('target', event.target.getAttribute('key'));
        console.log('name :', name, 'value :', value)

        setCorpDetailToUpdate(prevState => ({
            ...prevState,
            [name]: value
        }));


    }

    const handleDepartmentChange = (newValue, actionMeta) => {
        console.log("documentName !== '' && uploadedDocumemnt!==null && uploadedDocumemnt.length>0", uploadedDocumemnt !== null && uploadedDocumemnt.length > 0)
        // if (uploadedDocumemnt!==null && uploadedDocumemnt.length>0) {
        //     return (

        //         setDropDownHidden(true),
        //         setSeverty('error'),
        //         setMessage("Please save your change first or refresh page to remove them"))
        // }else{
        console.group('Value Changed handleChange');
        console.log('Value Changed handleChange newValue', newValue);
        console.log(`action handleChange: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue !== null && newValue.value !== null) {
            if (newValue.value.toLowerCase().includes("other")) {
                setshowInputDoc(true);
                setDocumentNameForSelect(newValue.value)
                setDocumentName('')
            } else {
                setDocumentNameForSelect(newValue.value)
                setDocumentName(newValue.value)
                setshowInputDoc(false);
            }
        } else {
            setDocumentName('')
            setDocumentNameForSelect('')
        }
        // }
    };
    const handleDepartmentInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed handleInputChange');
        console.log(inputValue);
        console.log(`action handleInputChange: ${actionMeta.action}`);
        console.groupEnd();
    };





    const show = () => {
        setShowEdit(true);
    }


    const assignPlanToCorp = (e) => {
        setUpgradeCorpPlanAssigned(e.target.id)
    }


    const upgradeCorpPlan = async () => {

        console.log("Upgrade price plan upgradeCorpPlanAssigned", upgradeCorpPlanAssigned)

        setUpgradePlanProcessBar(true);
        if (upgradeCorpPlanAssigned === null || upgradeCorpPlanAssigned.length === 0 || upgradeCorpPlanAssigned === "") {
            return (
                setUpgradePlanProcessBar(false),
                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_MESSAGE_TO_SELECT_PRICE_PLAN_FOR_UPDATE)
            )
        }



        const url = process.env.REACT_APP_UPDATE_CORPORATE_PRICE_PLAN
        const request = {
            "roId": location.state.roId,
            "pricePlanCode": upgradeCorpPlanAssigned,
            "status": "1"
        }

        var response = await apiCallService.postMethodCall(url, request);
        console.log('upgradeCorpPlan upgradeCorpPlanAssigned', request, response);

        if (response !== undefined && response.response_code === 0) {
            setUpgradePlanProcessBar(false);
            setDropDownHidden(true);
            setSeverty('success')
            setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_Plan_Upgarded)

            history.push({
                pathname: '/corp-profile',
                state: { roId: corpDetail.roId }
            })
            history.go();

        } else {
            setUpgradePlanProcessBar(false)
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);

        }
    }



    const uploadDocument = (event) => {
        setFiles([]);
        console.log('file event', event.target);
        console.log('file event', event.target.files[0]);
        setSize(event.target.files[0].size)
        // if (documentName !== '' && uploadedDocumemnt!==null && uploadedDocumemnt.length>0) {
        //     return (

        //         setDropDownHidden(true),
        //         setSeverty('error'),
        //         setMessage("Please save your change first or refresh page to remove them"))
        // }else

        if (documentName === '' && documentNameForSelect.toLowerCase().includes("other")) {
            return (

                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_Please_Select_Document_NAME_FOR_DOCUMENT_TYPE_OTHER))
        } else
            if (documentName === '') {
                return (

                    setDropDownHidden(true),
                    setSeverty('error'),
                    setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_Please_Select_Document_Type))
            }
            else

                if (uploadedDocumemnt.length === 5) {
                    return (setHidden(''),
                        setLoading(false),
                        setSuccess(false),
                        setuploadPercentage(0),
                        setDropDownHidden(true),
                        setSeverty('error'),
                        setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_Number_of_File_Extended))
                } else

                    if (event.target.files[0].type.includes('jpeg') || event.target.files[0].type.includes('jpg') || event.target.files[0].type.includes('png') || event.target.files[0].type.includes('doc') || event.target.files[0].type.includes('pdf') || event.target.files[0].type.includes('zip')) {
                        console.log('Inside ', event.target.files[0].type)

                        if (event.target.files[0].size > parseInt(process.env.REACT_APP_CORP_DOCUMENT_SIZE_IN_KB) * 1000) {
                            return (
                                setHidden(''),
                                setLoading(false),
                                setSuccess(false),
                                setuploadPercentage(100),
                                setDropDownHidden(true),
                                setSeverty('error'),
                                setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_File_Size_extended)
                            )
                        }

                        setuploadPercentage(1);
                        setHidden('');
                        setFiles(event.target.files[0])
                        if (event.target.files && event.target.files[0]) {
                            setImage(URL.createObjectURL(event.target.files[0]))

                        }

                        const file = event.target.files[0];
                        const url = process.env.REACT_APP_BULK_UPLOAD
                        //const url = 'http://localhost:7072/api/upload';
                        const formData = new FormData();
                        formData.append('file', file)

                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }

                        const options2 = {
                            onUploadProgress: (progressEvent) => {
                                const { loaded, total } = progressEvent;
                                let percent = Math.floor((loaded * 100) / total)
                                console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                                setTotal(total / 100)
                                setLoaded(loaded)

                                if (percent < 100) {
                                    setuploadPercentage(percent)
                                }
                            }
                        }
                        var hashCodeForHeader = generateHash(JSON.stringify(formData));
                        const headers = {
                            "device_info": "",
                            "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
                            "hashCode": hashCodeForHeader,
                            "loggedInUser": currentUser
                        }
                        const options = {
                            method: "POST",
                            body: formData,
                            headers: headers
                            // If you add this, upload won't work
                            // headers: {
                            //   'Content-Type': 'multipart/form-data',
                            // }
                        };

                        console.log('url', url)
                        fetch(url, options, options2).then(response => { return response.json(); })
                            .then(responseData => {
                                console.log("error.data exception hai responseData", responseData);


                                if (responseData.fileDownloadUri != undefined) {
                                    setuploadPercentage(100)

                                    var extrxctUrl = responseData.fileDownloadUri.split('/')
                                    console.log('extrxctUrl', extrxctUrl);

                                    setLoading(false);
                                    setSuccess(true)
                                    setuploadPercentage(100)

                                    var anArrayForDoc = [];
                                    // uploadedDocumemnt.map(up => {
                                    //     anArrayForDoc.push(up);
                                    // })

                                    if (documentName !== '') {
                                        var variableCnnn = {
                                            documentType: documentName,
                                            documentUrl: `${process.env.REACT_APP_API_FILES_URL}/${extrxctUrl[5]}`,
                                            status: "1"
                                        }
                                        anArrayForDoc.push(variableCnnn);
                                    }

                                    setUrl(`${process.env.REACT_APP_API_FILES_URL}/${extrxctUrl[5]}`);


                                    setUploadedDocument(anArrayForDoc);
                                    var array = [];
                                    documentList.map(list => {
                                        if (list.label === documentName && list.label !== 'Other') {
                                            // list.isDisabled = true
                                        }
                                        array.push(list)
                                    })
                                    // setDocumentName('');
                                    // setDocumentNameForSelect('')
                                    setshowInputDoc(false);
                                    setDocumentList(array);

                                } else {
                                    console.log("error.data exception hai", responseData.message);
                                    setHidden('')
                                    setLoading(false)
                                    setSuccess(false)
                                    setuploadPercentage(100)
                                    setDropDownHidden(true);
                                    setSeverty('error');
                                    setMessage(responseData.message);
                                }

                            })
                            .catch(error => {
                                console.log("error.data exception hai", error);
                                setHidden('')
                                setLoading(false)
                                setSuccess(false)
                                setuploadPercentage(100)
                                setDropDownHidden(true);
                                setSeverty('error');
                                setMessage("Some Error in Service call.");
                            })

                    } else {

                        setHidden('')
                        setLoading(false)
                        setSuccess(false)
                        setuploadPercentage(100)
                        setDropDownHidden(true);
                        setSeverty('error');
                        setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_Incorrect_file_Type);

                    }
    }


    const successPreview = () => {
        setHidden('hidden')
        setFiles([])
        setLoading(true)
        // setInputKey(Date.now())

        var array = [];
        documentList.map(list => {
            if (list.label === documentName && list.label !== 'Other') {
                list.isDisabled = true
            }
            array.push(list)
        })

        setDocumentList(array);


    }

    const removeDocFromArray = (doc, event) => {

        var final = []
        uploadedDocumemnt.map(uplo => {
            if (uplo.documentType === doc) {

            } else {
                final.push(uplo);
            }
        })

        setUploadedDocument(final);

        var array = [];
        documentList.map(list => {
            if (list.label === doc && list.label !== 'Other') {
                list.isDisabled = false
            }
            array.push(list)
        })

        setDocumentList(array);
    }


    const removePreview = () => {
        setHidden('hidden')
        setFiles([])
        setLoading(true)
        // setInputKey(Date.now())
    }


    const hide = () => {
        // setShowEdit(false);
        // setCorpDetailToUpdate(corpDetail);

        resetButtonFunction();
    }

    const resetButtonFunction = () => {

        setShowEdit(false);
        setCorpDetailToUpdate(corpDetail);

        var distArray = []
        var cityzArray = []
        var districtListToSet = {
            distId: 96,
            distName: corpDetail.district,
            label: corpDetail.district,
            stateId: 7,
            value: corpDetail.district
        }

        var cityListToSet = {
            cityId: 599,
            cityName: corpDetail.city,
            districtId: 125,
            label: corpDetail.city,
            stateId: 12,
            value: corpDetail.city,
        }

        distArray.push(districtListToSet)
        cityzArray.push(cityListToSet);
        setDistrictList(distArray);
        setCityList(cityzArray)
    }


    const upgradeDocument = async () => {


        setDocumentUpdateProcessBar(true);

        if (uploadedDocumemnt === null || uploadedDocumemnt.length === 0) {
            return (
                setDocumentUpdateProcessBar(false),
                setDropDownHidden(true),
                setSeverty('error'),
                setMessage(process.env.REACT_APP_Var_FOR_CORP_PROFILE_MESSAGE_FOR_No_Document_to_Upgrade))
        }

        const url = process.env.REACT_APP_ADD_CORPORATE_DOCUMENT;

        var anArrayForDoc = [];

        if (documentName !== '' && url !== '') {
            var variableCnnn = {
                documentType: documentName,
                documentUrl: urlFF,
                status: "1"
            }
            anArrayForDoc.push(variableCnnn);
        }

        const request = {
            "corpDocumentRequestlist": anArrayForDoc,
            roId: corpDetail.roId
        }



        const response = await apiCallService.postMethodCall(url, request);
        console.log("corpDocumentRequestlist", request, response)

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            history.push({
                pathname: '/corp-profile',
                state: { roId: corpDetail.roId }
            })
            history.go();
            setDocumentUpdateProcessBar(false);
        } else {
            setDocumentUpdateProcessBar(false);
            setDropDownHidden(true);
            setSeverty('error');
            setMessage(response.response_description);
        }
    }





    const goToBankDetailPage = (event) => {
        event.preventDefault();
        console.log('event.target.id', event.target.id);
        store.dispatch(setBankBackButton('/corp-profile'));

        if (event.target.id === 'addBank') {
            store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_ADD_BANK_DETAIL_Without_OTP));
        } else {
            store.dispatch(setBankAddAndUpdateUrl(process.env.REACT_APP_CORP_UPDATE_BANK_DETAIL_Without_OTP));
        }
        store.dispatch(setButtonName(process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Cancel_Button));


        history.push({
            pathname: '/add-bank-account',
            state: { corpDetail: corpDetail }
        })
        history.go();
    }


    const swithToCorpView = async () => {


        await store.dispatch(setAdminViewType(true))
        await store.dispatch(setAdminLoginUserEmail(corpDetail.contact_email));
        await store.dispatch(setAdminLoginUserRoId(corpDetail.roId))
        await store.dispatch(resetUser)
        await store.dispatch(resetRole);
        await store.dispatch(resetToken)

        //   history.push('/view-Acess-Login');
        //     history.go();
    }



    const getTableDetailPlan = async (event, priceCode) => {

        setTableLoading(true);
        setTableDate([]);

        const url = `${process.env.REACT_APP_GET_PRICE_DETAIL_API_URL}?priceDetail=${priceCode}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            setTableDate(response);
        }

    }




    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'white',
            border: "none",
            borderRadius: "0px",
            boxShadow: "none",
            minHeight: "22px",
            lineHeight: "22px",
            background: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "12px",
            backgroundPosition: "center right",
            marginTop: "-7px"
        }),

        valueContainer: styles => ({
            ...styles,
            padding: "0px 5px 0px 0px"
        }),


        menu: styles => ({
            ...styles,
            zIndex: "9999"
        }),


        input: styles => ({
            ...styles,
            margin: "0px"
        }),



        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //   const color = chroma(data.color);
            return {
                ...styles,
            };
        },



    };



    console.log("COrp Detail Data", corpDetail);

    console.log("COrp Detail Data cardDetail", cardDetail);
    console.log("COrp Detail Data balanceDetail", balanceDetail);

    return (

        <>
            {(authenticated)
                ? (

                    isPasswordReset
                        ?
                        <div className="mid-container">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="head">
                                            <div className="head-right">
                                                <ReactTooltip
                                                    id="back"
                                                    place="top"
                                                    type='dark'
                                                    effect='solid'
                                                    // multiline={true}
                                                    backgroundColor="black"
                                                />

                                                {showAddEmployee
                                                    ?

                                                    <div className="head-btn" style={{ float: "left" }}>
                                                        <Link className="employee"
                                                            to={{
                                                                pathname: '/add-corp-emp',
                                                                state: { roId: corpDetail.roId, currentUser: corpDetail.email, fullname: corpDetail.contact_person }
                                                            }}

                                                        > {process.env.REACT_APP_Var_FOR_CORP_PROFILE_Add_Employee_For_Corp_Button} </Link>
                                                    </div>
                                                    :
                                                    null

                                                }


                                                <Can
                                                    role={userRole}
                                                    perform="corpDetail-showCorpView:action"
                                                    roleData={roleData}
                                                    yes={() => (
                                                        <div className="head-btn" style={{ float: "left" }}>
                                                            <button className="employee"

                                                                onClick={swithToCorpView}
                                                            > Show Corporate View </button>
                                                        </div>
                                                    )}
                                                    no={() => null}
                                                />





                                                <a href="/corp-list" data-for="back" data-tip="Back" data-iscapture="true" className="back"><i></i> </a>

                                            </div>



                                            <h3>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Corporate_Details}</h3>
                                        </div>
                                    </div>

                                    <Snackbar

                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={dropDownHidden}
                                        autoHideDuration={3000}
                                        onClose={e => setDropDownHidden(false)}
                                    >
                                        <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                            {message}
                                        </Alert>
                                    </Snackbar>

                                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                        <div className="corpHeihgt">


                                            {
                                                showEdit

                                                    ?
                                                    <div className="corp-cardInfo" id="editcorprfile" style={{ "display": "block" }}>
                                                        <ReactTooltip
                                                            id="backClose"
                                                            place="top"
                                                            type='dark'
                                                            effect='solid'
                                                            // multiline={true}
                                                            backgroundColor="black"
                                                        />
                                                        <div className="corp-actn-btn">
                                                            <button type="button" className="mdl-js-button mdl-js-ripple-effect" data-for="backClose" data-tip="Cancel" data-iscapture="true" id="editcnl" onClick={hide}><i className="cancel">Cancel</i></button>
                                                        </div>
                                                        <form className="corprofiledit" onSubmit={updateCorp}>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="text" id="roId" name="roId" value={corpDetailToUpdate.roId} disabled />
                                                                            <label for="roId">{process.env.REACT_APP_ADD_NEW_CORPORATE_VARIABLE_FOR_ROID_LABEL}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="text" id="name" pattern="[a-zA-Z0-9.- ]{2,100}" name="name" onChange={handleChange} value={corpDetailToUpdate.name} required />
                                                                            <label for="name">Company Name</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="email" id="email" name="email" onChange={handleChange} value={corpDetailToUpdate.email} required />
                                                                            <label for="email">Company Email ID</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control"
                                                                                type="number"
                                                                                maxLength="10"
                                                                                pattern="[0-9]{10}"
                                                                                onInput={validateInputLenght}
                                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                                id="companyNumber" name="companyNumber" onChange={handleChange} value={corpDetailToUpdate.companyNumber} required />
                                                                            <label for="companyNumber">Company Phone Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="text"
                                                                                id="panTanNum"
                                                                                name="panTanNum"
                                                                                maxlength="10"
                                                                                pattern="[A-Z]{4}[A-Z0-9]{1}[0-9]{4}[A-Z]{1}" onChange={handleChange} value={corpDetailToUpdate.panTanNum} required />
                                                                            <label for="panTanNum">PAN/TAN Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="text" id="address1" maxLength="100" name="address1" onChange={handleChange} value={corpDetailToUpdate.address1} required />
                                                                            <label for="address1">Address 1</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="text" id="address2" maxLength="100" name="address2" onChange={handleChange} value={corpDetailToUpdate.address2} />
                                                                            <label for="address2">Address 2</label>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                                <div className="col-12 col-sm-6">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <select className="form-select" id="sg" name="documentType3" value={corpDetailToUpdate.state} onChange={handleStateChange} required>
                                                                                <option value="">Select</option>
                                                                                {stateList.map((documentType) =>
                                                                                (<option keyId={documentType.stateId} value={documentType.stateName}>
                                                                                    {documentType.stateName}
                                                                                </option>))
                                                                                }
                                                                            </select>
                                                                            <label htmlFor="sg" >State</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <select className="form-select" id="sg" name="documentType3" value={corpDetailToUpdate.district} onChange={handleDistrictChange} required>
                                                                                <option value="">select</option>
                                                                                {districtList.map((documentType) =>
                                                                                (<option keyId={documentType.distId} value={documentType.distName}>
                                                                                    {documentType.distName}
                                                                                </option>))
                                                                                }
                                                                            </select>
                                                                            <label htmlFor="sg" >District</label>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <select className="form-select" id="sg" name="documentType4" value={corpDetailToUpdate.city} onChange={handleCityChange} required >
                                                                                <option value="">select</option>
                                                                                {cityList.map((documentType) =>
                                                                                (<option keyId={documentType.cityId} value={documentType.cityName}>
                                                                                    {documentType.cityName}
                                                                                </option>))
                                                                                }
                                                                            </select>
                                                                            <label htmlFor="sg" >City</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="number"
                                                                                id="pincode"
                                                                                name="pincode"
                                                                                maxLength='6'
                                                                                pattern="[0-9]{6}"
                                                                                onInput={validateInputLenght}
                                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={handleChange} value={corpDetailToUpdate.pincode} required />
                                                                            <label for="pincode">Pincode</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <UploadImage currentUser={currentUser}
                                                                        header=""
                                                                        h6Value={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_DRAG_AND_DROP}
                                                                        h6SpanValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_BROWSE}
                                                                        h6ValueAfterbreak={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_PROFILE_PIC}
                                                                        pValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FILE_DETAIL}
                                                                    />
                                                                </div>



                                                                <div className="col-12"><h4>Contact Person</h4></div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="text" pattern="[a-zA-Z ]{2,71}" id="contact_person" name="contact_person" onChange={handleChange} value={corpDetailToUpdate.contact_person} required />
                                                                            <label for="contact_person">Full Name</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control"
                                                                                type="number"
                                                                                maxLength="10"
                                                                                pattern="[0-9]{10}"
                                                                                onInput={validateInputLenght}
                                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                                id="contact_number" name="contact_number" onChange={handleChange} value={corpDetailToUpdate.contact_number} required />
                                                                            <label for="contact_number">Mobile Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="webform">
                                                                        <div className="form-floating">
                                                                            <input className="form-control" type="email" id="contact_email" name="contact_email" onChange={handleChange} value={corpDetailToUpdate.contact_email} required />
                                                                            <label for="contact_email">Company Email ID</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 corpsavebtn">

                                                                    <button className="btn btngr" type="reset" onClick={resetButtonFunction}>Cancel</button>
                                                                    <button type="submit" className="btn btnbl" id="savecorp" disabled={updateCorpProcessBar}>
                                                                        {updateCorpProcessBar
                                                                            ?
                                                                            <div className="spinner-border" role="status">
                                                                                <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                            </div>
                                                                            :
                                                                            <>Save</>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>


                                                    </div>


                                                    :



                                                    <div className="corp-cardInfo">
                                                        <div className="corp-basic-pro">
                                                            <Avatar src={corpDetail.logoUrl} color={Avatar.getRandomColor(corpDetail.roId, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={corpDetail.roId} name={corpDetail.name} size="60px" round={true} />
                                                            <button className="editpro" data-bs-toggle="modal" data-bs-target="#edtpropic"></button>

                                                            <h5>{corpDetail.name}</h5>
                                                            <p>{process.env.REACT_APP_CORPORATE_DETAIL_PAGE_VARIABLE_FOR_ROID}: {corpDetail.roId}</p>


                                                            {
                                                                corpDetail.is_active === 1 ?

                                                                    <>  <ReactTooltip
                                                                        id="edit"
                                                                        place="top"
                                                                        type='dark'
                                                                        effect='solid'
                                                                        // multiline={true}
                                                                        backgroundColor="black"
                                                                    />
                                                                        <div className="corp-actn-btn">

                                                                            <button className="mdl-js-button mdl-js-ripple-effect" data-for="edit" data-tip="Edit" data-iscapture="true" id="edtemp" onClick={show}><i className="edit">Edit</i></button>
                                                                            <button className="mdl-js-button mdl-js-ripple-effect" data-bs-toggle="modal" data-for="edit" data-tip="Deactivate" data-iscapture="true" data-bs-target="#remove"><i className="inactive">inactive</i></button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="corp-actn-btn">
                                                                        <ReactTooltip
                                                                            id="edit"
                                                                            place="top"
                                                                            type='dark'
                                                                            effect='solid'
                                                                            // multiline={true}
                                                                            backgroundColor="black"
                                                                        />
                                                                        <button className="mdl-js-button mdl-js-ripple-effect" data-bs-toggle="modal" data-for="edit" data-tip="Activate" data-iscapture="true" data-bs-target="#activateCorp"><i className="active">inactive</i></button>
                                                                    </div>
                                                            }
                                                        </div>


                                                        {
                                                            corpDetail.is_active === 1 ?
                                                                <div className="corp-status"><i className="active"></i> Active Corporate

                                                                </div>
                                                                :
                                                                <div className="corp-status"><i className="inactive"></i> In-Active Corporate

                                                                </div>
                                                        }
                                                        <div className="corp-pro-details">
                                                            <p><i className="email"></i> <span>Company Email ID</span><br /> {corpDetail.email}</p>
                                                            <p><i className="phone"></i> <span>Company Phone Number</span><br /> {corpDetail.companyNumber}</p>
                                                            <p><i className="date"></i> <span>Created Date</span><br /> {corpDetail.dateCreatedOn}</p>
                                                            <p><i className="termnl"></i> <span>GST Number</span><br /> {corpDetail.gstNum}</p>
                                                            <p><i className="doc"></i> <span>PAN/TAN Number</span><br /> {corpDetail.panTanNum}</p>
                                                            <p><i className="adrs"></i> <span>Address</span><br /> {corpDetail.address}</p>

                                                            <h3>Contact Person</h3>
                                                            <p><i className="name"></i> <span>Full Name</span><br /> {corpDetail.contact_person}</p>
                                                            <p><i className="mobile"></i> <span>Mobile Number</span><br /> {corpDetail.contact_number}</p>
                                                            <p><i className="email"></i> <span>Email ID</span><br /> {corpDetail.contact_email}</p>
                                                        </div>
                                                    </div>




                                            }
                                            <div className="corpbankdetails">
                                                <h3>Bank Account Details


                                                    {
                                                        corpDetail.corpBankDetail != null
                                                            ?
                                                            <button className="sbtn btnbl" type="button" id="updateBank" data-toggle="tooltip" title="Update" onClick={goToBankDetailPage}>Update</button>
                                                            :

                                                            <button className="sbtn btnbl" type="button" id="addBank" onClick={goToBankDetailPage}>Add</button>

                                                    }



                                                </h3>


                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Bank_Name_Label}</span> {corpDetail.corpBankDetail !== undefined && corpDetail.corpBankDetail !== null ? corpDetail.corpBankDetail.bank : <>NA</>}</p>
                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR_Full_Name_Label}</span> {corpDetail.corpBankDetail !== undefined && corpDetail.corpBankDetail !== null ? corpDetail.corpBankDetail.fullName : <>NA</>}</p>
                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__Account_No_Label}</span> {corpDetail.corpBankDetail !== undefined && corpDetail.corpBankDetail !== null ? corpDetail.corpBankDetail.accountnumber : <>NA</>}</p>
                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__Account_Type_Label}</span> {corpDetail.corpBankDetail !== undefined && corpDetail.corpBankDetail !== null ? corpDetail.corpBankDetail.accountType : <>NA</>}</p>
                                                <p><span>{process.env.REACT_APP_REVERS_MONEY_VAR_FOR__IFSC_Code_Label}</span> {corpDetail.corpBankDetail !== undefined && corpDetail.corpBankDetail !== null ? corpDetail.corpBankDetail.ifscCode : <>NA</>}</p>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-7 col-xl-8">

                                        <div className="corpHeihgt">

                                            <div className="corp-cardInfo">
                                                <h3 className="subhead">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Balance_Information}</h3>

                                                <div className="row">
                                                    <div className="col-12 col-sm-8 border-right mb-4">
                                                        <div className="corp-balance">
                                                            <i>&#8377;</i>
                                                            <p>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Total_Available_Balance}</p>
                                                            <h4> {balanceDetail !== 'N/A' && balanceDetail?.availableBalance  !== undefined ? parseFloat(balanceDetail?.availableBalance).toFixed(2) : 0.00}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-4 mb-4">
                                                        <div className="corp-balance">
                                                        <p>{"Cureent Balance"}</p>
                                                        <h4>{lienBalance !== 'N/A' && lienBalance?.currentBalance  !== undefined ? parseFloat(lienBalance?.currentBalance).toFixed(2) : 0.00}</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className="subhead">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Card_Information}</h3>

                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-xl-4 border-right mb-4">
                                                        <div className="corpcard-ref">
                                                            <i></i>
                                                            <p>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Total_Card}</p>
                                                            <h4>{cardDetail.totalCard}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-xl-4 border-right mb-4">
                                                        <div className="corpcard-ref">
                                                            <Link
                                                                to={{
                                                                    pathname: '/assigned-cards',
                                                                    state: { roId: location.state.roId }
                                                                }}
                                                                className="sbtn btnbl"
                                                            >View</Link>
                                                            {/* <a href="/assigned-cards" className="sbtn btnbl">View</a> */}
                                                            <p>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Assigned_Cards}</p>
                                                            <h4 className="assign">{cardDetail.assignedCard}</h4>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-4 mb-4">
                                                        <div className="corpcard-ref">
                                                            <Link
                                                                to={{
                                                                    pathname: '/order-history',
                                                                    state: { roId: location.state.roId }
                                                                }}
                                                                className="sbtn btnbl"
                                                            >Order History</Link>
                                                            {/* <a href="/order-history" className="sbtn btnbl">Order History</a> */}
                                                            <p>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Available_Card}</p>
                                                            <h4 className="availe">{cardDetail.availableCard}</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className="subhead">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Corporate_Wallet}</h3>
                                                <div className="row">
                                                    {
                                                        corpDetail.walletTypes !== undefined && corpDetail.walletTypes.map(wallet => (
                                                            <div className="col-7 col-sm-4 col-md-7 col-lg-4 border-right mb-4">
                                                                <div className="corp-wallet">
                                                                    <i></i>
                                                                    <p>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Wallet}</p>


                                                                    {parseInt(wallet.wallet_type_id) === parseInt(CategoryLimit.GENERAL) ?

                                                                        <h4>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_General}</h4>
                                                                        :

                                                                        parseInt(wallet.wallet_type_id) === parseInt(CategoryLimit.FOOD) ?
                                                                            <h4>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Food}</h4>
                                                                            :
                                                                            parseInt(wallet.wallet_type_id) === parseInt(CategoryLimit.TRAVEL) ?
                                                                                <h4>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Travel}</h4>
                                                                                :
                                                                                null


                                                                    }

                                                                </div>
                                                            </div>
                                                        ))

                                                    }


                                                </div>

                                                <h3 className="subhead">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Uploaded_Document} <span className="infotxt">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Uploaded_Document_TYPE_ALLOWED_TEXT}</span></h3>
                                                <div className="row">
                                                    <div className="col">


                                                        {corporateDocuments.map(document => (

                                                            <div className="corpfileupload">
                                                                <a href={document.documentUrl} >Document</a>
                                                                <p>{document.documentType}</p>
                                                            </div>
                                                        ))}
                                                    </div>



                                                    {
                                                        corpDetail.is_active === 1 ?

                                                            <div className="col-12 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                                                                <div className="adddocument">
                                                                    <div className="webform">
                                                                        <div className="formselect">
                                                                            <label htmlFor="sg" className="select-label">Document Type</label>
                                                                            <CreatableSelect
                                                                                isClearable
                                                                                onChange={handleDepartmentChange}
                                                                                onInputChange={handleDepartmentInputChange}
                                                                                options={documentList}
                                                                                styles={colourStyles}
                                                                                components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                                                value={documentList.map(bandData => {
                                                                                    if (bandData.value === documentNameForSelect) {
                                                                                        return bandData
                                                                                    }
                                                                                })}
                                                                                formatCreateLabel={() => undefined}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {showInputDoc
                                                                        ?
                                                                        <div className="selectdoc">
                                                                            <input type="text" value={documentName} className="otherfiled"
                                                                                placeholder="Enter document name" onChange={e => setDocumentName(e.target.value)} />
                                                                        </div>
                                                                        : null
                                                                    }

                                                                    <div className="uploaddoc">
                                                                        <div className="dropzone-wrapper">
                                                                            <div className="dropzone-desc">
                                                                                <i className="addoc"></i>
                                                                                <h6>Add file</h6>
                                                                            </div>
                                                                            <input type="file" name="img_logo" className="dropzone" key={inputKey} onChange={uploadDocument} />
                                                                        </div>


                                                                        {
                                                                            parseInt(uploadPercentage) === 100 ? null :

                                                                                <div className={`preview-zone ${hidden}`}>
                                                                                    <div className="box">

                                                                                        {uploadPercentage > 0 && <LinearProgressWithLabel value={uploadPercentage} variant="determinate" />}

                                                                                        {
                                                                                            hidden === ''
                                                                                                ?
                                                                                                <div className="box-body">

                                                                                                    <img width="40" src={localStorage.getItem('url')} ></img><p style={{ "marginLeft": "1.5em" }}>File size {parseFloat(size / 1000).toFixed(2)}KB  </p>

                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        {(loading)
                                                                                            ?
                                                                                            <div className="spinner-border text-muted"></div>
                                                                                            :

                                                                                            (success)
                                                                                                ?
                                                                                                <button type="button" className="success-preview" onClick={successPreview}>Success</button>
                                                                                                :
                                                                                                <button type="button" className="remove-preview" onClick={removePreview}>Remove</button>

                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                                {uploadedDocumemnt === null || uploadedDocumemnt.length === 0
                                                                        ?
                                                                        null
                                                                        :
                                                                        <div className="adddocument-mybutton">

                                                                            <button className="sbtn btnbl" onClick={upgradeDocument} disabled={documentUpdateProcessBar}>
                                                                                {documentUpdateProcessBar
                                                                                    ?
                                                                                    <div className="spinner-border" role="status">
                                                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                                    </div>
                                                                                    :
                                                                                    <>Save</>
                                                                                }
                                                                            </button>

                                                                        </div>
                                                                    }
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>


                                                <h3 className="subhead">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Selected_Pricing}</h3>
                                                <div className="row">
                                                    <div className="col-12 col-xl-10">
                                                        <div className="row">

                                                            {
                                                                pricePlan.map(plan => (
                                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                                        <div className={corpPlanAssigned === plan.pricePlanCode ? "pricetype addedprice" : "pricetype"}>
                                                                            <label>
                                                                                {
                                                                                    corpPlanAssigned === plan.pricePlanCode
                                                                                        ?
                                                                                        <input type="checkbox" name={plan.pricePlanCode} id={plan.pricePlanCode} checked onClick disabled={corpPlanAssigned === plan.pricePlanCode} />
                                                                                        :
                                                                                        <input type="radio" name={plan.pricePlanCode} id={plan.pricePlanCode} checked={upgradeCorpPlanAssigned === plan.pricePlanCode} onClick={assignPlanToCorp} disabled={corpPlanAssigned === plan.pricePlanCode} />
                                                                                }
                                                                                <h5 className={plan.pricePlanName.toLowerCase()}>{plan.pricePlanName}</h5>
                                                                            </label>

                                                                            <i className="info" data-bs-toggle="modal" data-bs-target={`#pricePlan${plan.pricePlanCode}`} onClick={e => getTableDetailPlan(e, plan.pricePlanCode)}></i>

                                                                            <div className="modal fade" id={`pricePlan${plan.pricePlanCode}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
                                                                                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                                                                                    <div className="modal-content alerttext">
                                                                                        <div className="modal-header">
                                                                                            <h5 id="" style={{ background: "none" }}>{tableDate.priceName} - {tableDate.planProvider}</h5>
                                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                        </div>
                                                                                        <div className="modal-body">
                                                                                            <table className="table table-bordered">


                                                                                                {
                                                                                                    tableDate.priceDetails !== undefined && tableDate.priceDetails.length === 0

                                                                                                        ?

                                                                                                        <div className="col-12">
                                                                                                            <div className="no-record">
                                                                                                                <i></i>
                                                                                                                <h3> No Record Available.</h3>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :


                                                                                                        tableDate.priceDetails !== undefined && tableDate.priceDetails.map(tD => (
                                                                                                            <>
                                                                                                                {
                                                                                                                    tD.priceFor === ""
                                                                                                                        ?
                                                                                                                        null
                                                                                                                        :
                                                                                                                        <tr className="table-primary">
                                                                                                                            <td colspan="2">{tD.priceFor}</td>
                                                                                                                        </tr>
                                                                                                                }

                                                                                                                {tD.priceDetails !== undefined && tD.priceDetails.map(inpd => (
                                                                                                                    <tr>
                                                                                                                        <td>{inpd.pricePlanName}</td>
                                                                                                                        <td>{inpd.priceValue}</td>
                                                                                                                    </tr>
                                                                                                                ))
                                                                                                                }
                                                                                                            </>
                                                                                                        ))
                                                                                                }



                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }





                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-2">
                                                        {
                                                            corpDetail.is_active === 1 ?

                                                                <div className="plnbtn mt-4">
                                                                    <button className="sbtn btnbl" onClick={upgradeCorpPlan} disabled={upgradePlanProcessBar}>
                                                                        {upgradePlanProcessBar
                                                                            ?
                                                                            <div className="spinner-border" role="status">
                                                                                <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                            </div>
                                                                            :
                                                                            <>{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Upgrade_Plan_Button}</>

                                                                        }
                                                                    </button>

                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>



                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <ResetLoginPassword />
                )
                : (
                    <Redirect to='/' />
                )
            }
            <div className="modal fade" id="remove" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
                <div className="modal-dialog modal-sm">
                    <div className="modal-content alerttext">
                        <div className="modal-header">
                            <h5 id="">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Incative_Corporate_Popup_heading}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {process.env.REACT_APP_Var_FOR_CORP_PROFILE_Incative_Corporate_Popup_Message_to_sure}</div>
                        <div className="modal-footer">
                            <button type="button" className="mbtn btngr" data-bs-dismiss="modal">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Incative_Corporate_Popup_No_Button}</button>
                            <button type="button" className="mbtn btnbl" onClick={deleteCorp} disabled={deleteCorpProcessBar}>
                                {deleteCorpProcessBar
                                    ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                    </div>
                                    :
                                    <> {process.env.REACT_APP_Var_FOR_CORP_PROFILE_Incative_Corporate_Popup_Yes_Button}</>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="edtpropic" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
                <div className="modal-dialog modal-sm">
                    <div className="modal-content alerttext">
                        <div className="modal-header">
                            <h5 id="">Update Corporate Logo</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <UploadImage currentUser={currentUser}
                                header=""
                                h6Value={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_DRAG_AND_DROP}
                                h6SpanValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_BROWSE}
                                h6ValueAfterbreak={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_PROFILE_PIC}
                                pValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FILE_DETAIL}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="mbtn btnbl" onClick={updateCorpLogo} disabled={updateCorpProcessBar}>  {updateCorpProcessBar
                                ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                </div>
                                :
                                <>Save</>}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="activateCorp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
                <div className="modal-dialog modal-sm">
                    <div className="modal-content alerttext">
                        <div className="modal-header">
                            <h5 id="">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Acative_Corporate_Popup_heading}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {process.env.REACT_APP_Var_FOR_CORP_PROFILE_Acative_Corporate_Popup_Message_to_sure}</div>
                        <div className="modal-footer">
                            <button type="button" className="mbtn btngr" data-bs-dismiss="modal">{process.env.REACT_APP_Var_FOR_CORP_PROFILE_Acative_Corporate_Popup_No_Button}</button>
                            <button type="button" className="mbtn btnbl" onClick={activateCorp} disabled={activateCorpProcessBar}>
                                {activateCorpProcessBar
                                    ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                    </div>
                                    :
                                    <>   {process.env.REACT_APP_Var_FOR_CORP_PROFILE_Acative_Corporate_Popup_Yes_Button}</>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, currentUser }, role: { roleData } }) => ({
    userRole, authenticated, token, roMdn, isPasswordReset, currentUser, roleData
});

export default connect(mapStateToProps)(CorporateProfile);