import DropDownTypes from './dropdown.types';

const INITIAL_STATE = {
    hidden: false
};

const dropDownReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case DropDownTypes.TOGGLE_DROPDOWN_HIDDEN:
            console.log('TOGGLE_DROPDOWN_HIDDEN');
            return {

                ...state,
                hidden: action.payload
            };
       
        default:
            return state;
    }
};

export default dropDownReducer;