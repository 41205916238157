import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { store } from '../../redux/store';

import '../css/notification.styles.css'

import Pagination from 'react-js-pagination';

import apiCallService from '../../service/api-call-service';
import Select from "react-select";

import history from '../../auth/history';
import moment from 'moment';
import Can from '../../auth/can/Can';
import NotificationType from "../../constants/notification.type";
import { setUnseenCount } from '../../redux/token/token.action';
import LoadingComponent from './notification.loading.component';

const collectedTrueKeys = {
    notificationType: []
}
var ToDateVar = '';
var FromDateVar = '';
const Notification = ({ token, location, authenticated, roMdn, userRole, roleData, currentUser }) => {


    const [loading, setLoading] = useState(true);


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_NotificationList);

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);
    const [multiValueB, setMultiValueB] = useState('')
    const [filteredRecord, setFilteredRecord] = useState([]);

    const [notificationList, setNotificationList] = useState([]);
    // const [unSeenCount, setUnseenCount] = useState(0);

    const [bandData, setBandData] = useState([]);

    const [placeHolder, setplaceHolder] = useState("...");

    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');


    useEffect(() => {

        fetchNotification()

        fetNotiData();


    }, []);


    const fetNotiData = async () => {
        const url = process.env.REACT_APP_GET_ALL_NOTIFICATION_TYPE;

        var response = await apiCallService.getMethodCallWithoutParam(url);

        console.log("fetNotiData", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
            var arr = [];
            response.response.notificationTypes.map(notType => {
                const obj = {
                    value: notType.id,
                    label: notType.notificationName,
                }
                arr.push(obj);
            })

            setBandData(arr);
        }
    }


    const fetchNotification = async () => {
        // REACT_APP_GET_NOTIFICATION_ALLOWED=http://3.6.104.48:7072/getNotification
        const url = process.env.REACT_APP_GET_NOTIFICATION_ALLOWED;

        var request = {
            "roId": roMdn
        }

        var response = await apiCallService.postMethodCall(url, request);

        console.log('Notification Response', url, response, request);
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            console.log('Notification response.response.details', response.response.details)
            console.log('Notification response.response.noOfUnseen ', response.response.noOfUnseen)

            function isJson(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            }

            var arr = [];

            var countIncment = 0;

            response.response.details.map(detail => {
                if (roleData.includes(detail.action)) {
                    if (isJson(detail.condition)) {
                        const state = "state"
                        detail[state] = JSON.parse(detail.condition);
                    }
                    arr.push(detail)
                    if (detail.status === NotificationType.Unseen) {
                        countIncment++;
                    }
                }
            })


            setNotificationList(arr);
            setFilteredRecord(arr)
            setLoading(false);
            // setUnseenCount(parseInt(countIncment));
            store.dispatch(setUnseenCount(parseInt(countIncment)))
            updateNotificationStatusAsSeen(arr);
        }

    }


    const updateNotificationStatusAsSeen = async (notificationListTT) => {

        console.log('Triggered updateNotificationStatus Notification')
        const url = process.env.REACT_APP_UPDATE_NOTIFICATION_STATUS;
        var arrId = [];


        notificationListTT.map(subNoti => {
            console.log(' Notification subNoti', subNoti)
            if (subNoti.status === NotificationType.Unseen) {
                arrId.push(subNoti.id)
            }

        })


        var request = {
            "id": arrId,
            "status": NotificationType.Seen
        }

        var response = await apiCallService.postMethodCall(url, request);
        console.log("Notification update status ", url, request, notificationList, response);
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            store.dispatch(setUnseenCount(0))
        }
    }


    const updateNotificationStatusAsRead = async (id, urlTo, condition) => {

        console.log('Triggered updateNotificationStatus Notification Read')
        const url = process.env.REACT_APP_UPDATE_NOTIFICATION_STATUS;
        var arrId = [];
        var i = 0;

        arrId.push(id);
        var request = {
            "id": arrId,
            "status": NotificationType.Read
        }

        var response = await apiCallService.postMethodCall(url, request);
        console.log("Notification update status Read", url, request, notificationList, response);
        if (response !== undefined && response.response_code !== undefined && parseInt(response.response_code) === 0) {
            // fetchNotification();
            history.push({
                pathname: urlTo,
                state: condition
            })
            history.go()
        }
    }




    const handleMultiBandchange = (option) => {
        console.log('option before', option)
        setMultiValueB(option)
        setCurrentPage(1)

        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.notificationType = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.notificationType.push(op.value);
            })
        }

    }


    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            setToDate(value)

            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value)
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("DD-MM-YYYY hh:mm:ss");
            var formatToDate = moment(ToDateVar).format("DD-MM-YYYY hh:mm:ss");

            const requestToSend =
            {
                "roId": roMdn,
                "from_date": formatFromDate,
                "to_date": formatToDate
            }


            const url = process.env.REACT_APP_GET_NOTIFICATION_ALLOWED;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('response', response)

            if (!(response === undefined) && (response.response_code === 0) && response.response !== null) {

                function isJson(str) {
                    try {
                        JSON.parse(str);
                    } catch (e) {
                        return false;
                    }
                    return true;
                }

                var arr = [];

                var countIncment = 0;

                response.response.details.map(detail => {
                    if (roleData.includes(detail.action)) {
                        if (isJson(detail.condition)) {
                            const state = "state"
                            detail[state] = JSON.parse(detail.condition);
                        }
                        arr.push(detail)
                        if (detail.status === NotificationType.Unseen) {
                            countIncment++;
                        }
                    }
                })


                setFilteredRecord(arr);

                // setFilteredRecord(response.response.details)
                setCurrentPage(1)
            } else {
                setFilteredRecord([])
            }

        }
    }


    const clearAll = () => {

        setMultiValueB([])


        setFilteredRecord(notificationList)


        collectedTrueKeys.notificationType = [];


        setAllValuesForFilter([])

        setToDate('')
        setFromDate('')
        ToDateVar = '';
        FromDateVar = '';


    }


    var startdateToDate = moment();
    startdateToDate = startdateToDate.format("YYYY-MM-DD");

    if (toDate === '') {
        var startdateFromDate = moment();
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    } else {
        var startdateFromDate = moment(toDate);
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    }



    var dateMax = moment(fromDate);
    dateMax = dateMax.format("YYYY-MM-DD");
    var dateFromMax = moment(toDate);
    dateFromMax = dateFromMax.format("YYYY-MM-DD");

    const customStyles = {
        control: (base, state) => ({
            ...base,
            'border': '1px solid #ddd',
            'borderRadius': '0',
            'boxSizing': 'content-box',
            'minHeight': '30px',
            'lineHeight': '18px',
            'display': 'flex'
        }),

        valueContainer: (base) => ({
            ...base,
            'position': 'relative',
            'overflow': 'hidden',
            'margin': '0',
            'padding': '5px',
            'width': '100%',
            'minHeight': '30px',
            'border': '1px solid #ddd',
            'backgroundColor': '#fff',
            'lineHeight': '18px',
            'cursor': 'text',
            'alignItems': 'center'
        }),
        multiValue: base => ({
            ...base,
            'boxSizing': 'content-box',
            'position': 'relative',
            'margin': '1px 5px 1px 0px',
            'padding': '1px 2px 3px 5px',
            'border': '1px solid #c4d5e1',
            'lineHeight': '18px',
            'maxWidth': '100%',
            'borderRadius': '3px',
            'backgroundColor': '#d6eeff',
            'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
            'color': '#4a546c',
            'lineHeight': '16px',
            'cursor': 'default',
            'height': '20px'
        }),
        menu: (base) => ({
            ...base,
            'padding': '2px 5px',
            'lineHeight': '16px',

        }),
        input: base => ({
            ...base,
            'height': '18px',
            'lineHeight': '18px'
        })

    };


    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;






    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };


    var filteredPostData = [];

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPostData)



    }

    const key = 'id';

    const filteredPost = [...new Map(filteredPostData.map(item =>
        [item[key], item])).values()];


    if (filteredPost === null || filteredPost === undefined) {
        currentPost = null
    } else {
        currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
    }

    console.log('currentpost', currentPost);
    var height = window.innerHeight - 300

    return (
        < >
            {(authenticated)
                ? (
                    <div className="mid-container">
                        <div className="container-fluid">



                            <div className="head">
                                <div className="row">


                                    <div className="col-6 col-md-4 order-md-1">
                                        <h3>Notifications</h3>
                                    </div>


                                    <div className="col-6 col-md-4 order-md-3">
                                        <div className="flterbox">
                                            <div className="dropdown" data-toggle="tooltip" title="Filter">
                                                <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="ftlrmenu">

                                                    {/* <i className="closefil">x</i> */}

                                                    <div className="filters">
                                                        <label htmlFor="select-state">Type:</label>
                                                        <Select
                                                            name="filters"
                                                            placeholder={placeHolder}
                                                            value={multiValueB}
                                                            options={bandData}
                                                            onChange={handleMultiBandchange}
                                                            isMulti={true}
                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                            styles={customStyles}
                                                        />
                                                    </div>

                                                    <div className="filters">
                                                        <label htmlFor="select-state">From Date:</label>
                                                        <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={fromDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                    </div>

                                                    <div className="filters">
                                                        <label htmlFor="select-state">To Date:</label>
                                                        <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={toDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                    </div>

                                                    <div className="fbtn">
                                                        <button className="sbtn btnrd" type="reset" id="btnClear" onClick={clearAll}>Clear all</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 order-md-2">
                                        <div className="searchdata">
                                            <div className="input-group">

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="emp-list">

                                <div className="row">

                                    <div className="col-12">




                                        {
                                            loading ?
                                                <div className="notecard">
                                                    <LoadingComponent />
                                                </div>
                                                :
                                                currentPost.length === 0
                                                    ?
                                                    <div className="col-12">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3> No Record Available.</h3>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="notecard">
                                                        {


                                                            currentPost.map(subNoti => (

                                                                <Can
                                                                    role={userRole}
                                                                    perform={subNoti.action}
                                                                    roleData={roleData}
                                                                    yes={() => (

                                                                        <div className="notelist" style={{ backgroundColor: subNoti.colorClass }}>

                                                                            <Link

                                                                                onClick={e => updateNotificationStatusAsRead(subNoti.id, subNoti.redirectUrl, subNoti.state)}
                                                                                className="ntcbtn"
                                                                            >
                                                                                <h6>  {subNoti.beforeLink}
                                                                                    <span>	{subNoti.linkVariable}</span>  {subNoti.afterLink}
                                                                                </h6>
                                                                                <p>{subNoti.createdDate}</p>
                                                                            </Link>

                                                                        </div>

                                                                    )}
                                                                    no={() =>
                                                                        null
                                                                    }
                                                                />

                                                            ))


                                                        }
                                                    </div>
                                        }



                                    </div>

                                    {parseInt(filteredPost.length) > parseInt(postsPerPage)
                                        ?
                                        <>
                                            <div className="col-12 col-sm-6">
                                                <p>


                                                    Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="pager">
                                                    <Pagination
                                                        activePage={parseInt(currentPage)}
                                                        itemsCountPerPage={parseInt(postsPerPage)}
                                                        totalItemsCount={filteredPost.length}
                                                        pageRangeDisplayed={parseInt(postsPerPage)}
                                                        onChange={paginate}
                                                        className="pagination"
                                                        linkClass="page-link"
                                                        itemClass="page-item"
                                                        nextPageText="Next"
                                                        prevPageText="Prev"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                )
                : (
                    <Redirect to='/' />
                )
            }

        </>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount, currentUser,
    maxFoodAmount,
    maxTravelAmount }, role: { roleData }, token: { authenticated, token }, band: { bandData }, seteditLimt: { editBandDetail } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, editBandDetail, currentUser,
        maxTravelAmount, token, roleData
    });

export default connect(mapStateToProps)(Notification);
