import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import history from '../../auth/history';
import { store } from '../../redux/store';
import { setSessionExpire } from '../../redux/user/user.actions';
import '../css/idle-monitor.styles.css'

function IdleMonitor() {
    //Modal
    const [idleModal, setIdleModal] = useState(false);




    let idleTimeout = parseInt(process.env.REACT_APP_SESSION_EXPIRE_TIME);  //1 minute
    let idleLogout = parseInt(process.env.REACT_APP_SESSION_EXPIRE_TIME); //2 Minutes
    let idleEvent;
    let idleLogoutEvent;

    /**
     * Add any other events listeners here
     */
    const events = [
        'mousemove',
        'click',
        'keypress'
    ];


    /**
     * @method sessionTimeout
     * This function is called with each event listener to set a timeout or clear a timeout.
     */
    const sessionTimeout = () => {
        if (!!idleEvent) clearTimeout(idleEvent);
        if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
        const metodToCall = () => {

            if(window.location.pathname==='/'){
                console.log('props.location.href IdleMonitor', window.location.pathname, window.location)
            }else{
                setIdleModal(true)
                store.dispatch(setSessionExpire(true))
                console.log('props.location.href IdleMonitor',window.location, window.location.pathname)
            }
          
        }

        idleEvent = setTimeout(() => metodToCall(), idleTimeout); //show session warning modal.
        idleLogoutEvent = setTimeout(() => logOut, idleLogout); //Call logged out on session expire.
    };

    /**
     * @method extendSession
     * This function will extend current user session.
     */
    const extendSession = () => {
        console.log('user wants to stay logged in');
    }


    /**
     * @method logOut
     * This function will destroy current user session.
     */
    const logOut = () => {
        setIdleModal(false)
        console.log('logging out');
        history.push('/logout')
        history.go();
    }

    useEffect(() => {
        for (let e in events) {

            window.addEventListener(events[e], sessionTimeout);
        }

        return () => {
            for (let e in events) {
                // store.dispatch(setSessionExpire(true));
                window.removeEventListener(events[e], sessionTimeout);
            }
        }
    }, []);


    return (

     
        <Modal className="modal-sm session" isOpen={idleModal} toggle={() => logOut()} >
            <ModalHeader toggle={() => logOut()}>
                {process.env.REACT_APP_SESSION_EXPIRE_TEXT_HEADER}
        </ModalHeader>
            <ModalBody>
                {process.env.REACT_APP_SESSION_EXPIRE_TEXT_BEFORE_TIME} {idleLogout / 60 / 1000} {process.env.REACT_APP_SESSION_EXPIRE_TEXT_AFTER_TIME}
            </ModalBody>
            <ModalFooter>
                <button className="mbtn btnbl" onClick={() => logOut()}>{process.env.REACT_APP_SESSION_EXPIRE_TEXT_OK}</button>
                {/* <button className="btn btn-success" onClick={()=> extendSession()}>Extend session</button> */}

            </ModalFooter>
        </Modal>
    )

}

export default IdleMonitor;