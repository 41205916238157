import React from 'react';
import '../css/header.styles.css'

const Footer = () => (

    // <div className="footer-txt">
    //     {process.env.REACT_APP_Var_FOR_Powered_by}
    //     <br />
    //     {process.env.REACT_APP_Var_FOR_Copyright_All_rights_Reserved}
    // </div>

<footer>
<div className="copytxt">Copyright &copy; 2022 All rights reserved.<br/> Powered by: <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions Pvt Ltd</a></div>
</footer>

)
export default Footer;
