import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import GenerateRequestId from '../../common-function/GenerateRequestId';
import apiCallService from '../../service/api-call-service';
import CategoryLimit from '../../constants/category-limit.type';
import Pagination from 'react-js-pagination';
import CsvDownload from 'react-json-to-csv';
import Select from "react-select";
import { createThisTypeNode } from 'typescript';
import moment from 'moment';
import Avatar from 'react-avatar';
import CommonFunction from '../../common-function/GenerateRequestId';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
// var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');


var ToDateVar = '';
var FromDateVar = '';
class CardBalanceReport extends React.Component {

    constructor() {
        super();

        this.state = {
            transactionData: [],
            empDetail: [],
            totalAmount: 0,
            currentPage: 1,
            loading: false,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL,
            size: 0,
            searchField: "",

            filteredRecord: [],
            toDate: '',
            fromDate: '',
            paginatedList: [],
            allValuesForFilter: [],


            placeHolder: "...",

        };
    }


    componentDidMount = () => {

        document.title = "ShakePe MIS Daily Report"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: "",
            adminDashboardClass: "",
            adminMngCardClass: "",
            adminReportCLass: "active"
        }

        store.dispatch(setActiveHeader(payload));


    }



    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

    };


    handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            this.setState({ toDate: value })
            ToDateVar = value
        }
        if (name === 'fromDate') {
            this.setState({ fromDate: value })
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD");


            let headers = {
                "client_id": "OMTS_77B",
                "token": this.props.token,
                "Authorization": this.props.token
            }

            const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY

            var request = {
                "device_info": {},
                "request": {
                    "user": {
                        "username": `91${this.props.roMdn}`
                    },
                    "transaction_data": {
                        "count": 100,
                        "from_date": `${formatFromDate} 00:00:00`,
                        "page_index": 0,
                        "request_id": GenerateRequestId.geneRateRequestId(),
                        "time_stamp": new Date(),
                        "to_date": `${formatToDate} 23:59:59`,
                        "transaction_status": "3",
                        "wallet_type": null,
                        "transaction_type": '2'
                    }
                }
            }

            var response = await apiCallService.postMethodCall(url, request, headers);

            console.log('response txn history', request, response)
            if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {
                // this.setState({ transactionData: response.response.transaction_data })
                this.setState({ filteredRecord: response.response.transaction_data })
                this.setState({ currentPage: 1 })
            } else {
                this.setState({ filteredRecord: [] })
            }

        }
    }



    clearAll = () => {
        console.log('clear all triggered')
        this.setState({ filteredRecord: this.state.transactionData })


        ToDateVar = '';
        FromDateVar = '';

        this.setState({ toDate: '' })
        this.setState({ fromDate: '' })

        console.log('clear all triggered  toDate', this.state.toDate)
        console.log('clear all triggered fromDate', this.state.fromDate)
    }


    render() {



        return (
            < >
                {(this.props.authenticated)
                    ? (

                        <div className="mid-container">
                            <div className="container-fluid">
                                <div className="emp-list">
                                    <div className="head">
                                        <div className="head-right">
                                            <div className="dropdown float-right" data-toggle="tooltip" title="Filter">
                                                <a href="#" className="fil-btn" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter</a>
                                                <form>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        {/* <i className="closefil">x</i> */}
                                                        <div className="filters">
                                                            <label for="">From Date:</label>
                                                            <input type="date" value="" name="" id="" />
                                                        </div>

                                                        <div className="filters">
                                                            <label for="">To Date:</label>
                                                            <input type="date" value="" name="" id="" />
                                                        </div>

                                                        <div className="fbtn">
                                                            <button className="sbtn btnrd" type="reset" id="btnClear">Clear all</button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <h3>Card Balance Report</h3>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive ">
                                                <table className="table table-bordered tablereport">
                                                    <tr>
                                                        <th>Mobile No.</th>
                                                        <th>ID</th>
                                                        <th>Balance</th>
                                                        <th>Campaign ID</th>
                                                        <th>Expiry Date</th>
                                                        <th>Lean</th>
                                                        <th>Owner</th>
                                                        <th>Profile ID</th>
                                                        <th>Version</th>
                                                    </tr>
                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>

                                                    <tr>
                                                        <td>9001022101</td>
                                                        <td>34</td>
                                                        <td>₹ 2059.37</td>
                                                        <td>1939</td>
                                                        <td>04-02-2021</td>
                                                        <td>₹ 1000.00</td>
                                                        <td>1</td>
                                                        <td>123456789</td>
                                                        <td>1.5</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <p><button className="mbtn btnbl">Export to CSV</button> Showing 1 to 30 of 270 entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                                                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(CardBalanceReport);