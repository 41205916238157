import ListForActionType from "./listForAction.types";

const INITIAL_STATE = {
    listForAction:[]
};


const listForActionReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case ListForActionType.SET_LOCATION:
            return {
                ...state,
                listForAction : action.payload
            };
        case ListForActionType.SET_LOCATION_LIST_TO_VIEW:
            return {
                ...state,
                listForAction : action.payload
            }
        default:
            return state;
    }
};

export default listForActionReducer;
