import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import apiCallService from '../../service/api-call-service';



const AssignRole = ({ authenticated, roMdn, role, employeeDetail, type, updatedRole, updateRoleChangeHandler }) => {


    const [roleCount, setRoleCount] = useState(0);

    useEffect(() => {

        const funName = async () => {
            var url = `${process.env.REACT_APP_GET_EMPLOYEE_COUNT_BY_ROLENAME}?ro_Id=${roMdn}&role_name=${role.label}`;

            var resp = await apiCallService.getMethodCallWithoutParam(url);

            console.log(resp)
            if (resp !== undefined && resp.response_code !== undefined && resp.response_code === 0) {
                setRoleCount(resp.activeemployeeSizeByRoleName)
            }
        }
        funName();

    }, []);


    console.log('roleCount', roleCount)

    return (
        < >
            {(authenticated)
                ? (
                    <>


                        {
                            type === "filter"
                                ?



                                <div  className="form-check">
                                    <input className="form-check-input" type="radio" id={role.value} name={`${role.label}/${parseInt(role.maxUserAllowed)}`} checked={updatedRole === role.label} value={updatedRole} onClick={updateRoleChangeHandler} disabled={parseInt(role.maxUserAllowed) !== 0 && parseInt(roleCount) >= parseInt(role.maxUserAllowed)} />
                                    <label  className="form-check-label" for={role.value}>
                                        {role.label}
                                    </label>
                                </div>


                                :
                                <option selected={employeeDetail.roleName === "" ? false : role.label === employeeDetail.roleName} id={`${role.value}/${parseInt(role.maxUserAllowed)}`} disabled={parseInt(role.maxUserAllowed) !== 0 && parseInt(roleCount) >= parseInt(role.maxUserAllowed)}> {role.label} </option>
                        }
                    </>
                )
                : (
                    <Redirect to='/' />
                )


            }

        </>

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount,
    maxFoodAmount,
    maxTravelAmount }, token: { authenticated, token }, band: { bandData }, seteditLimt: { editBandDetail } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount,
        maxFoodAmount, editBandDetail,
        maxTravelAmount, token
    });

export default connect(mapStateToProps)(AssignRole);




