import React from 'react';


const DropDownIcon = ({ id, bulKButtonShow }) => (
  console.log('masterChecked inside dropdown', bulKButtonShow),

  bulKButtonShow
    ?
    <button className="more dropdown-toggle" type="button" id='2' ></button>
    :
    <button className="more dropdown-toggle" type="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></button>


);



export default DropDownIcon;