import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (


        <div className="col-12 col-md-6 col-lg-4">
            <div className="lastorder">
                <h3>Order Details</h3>
                <div className="lstorderid">
                    {/* <p>Order ID: <strong> */}
                    <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                    {/* </strong></p> */}
                </div>

                <div className="lstorderby">
                    <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_By}</h5>
                    <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                    <p><i className="mobile"></i> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                    <p><i className="calander"></i> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                </div>

                <div className="lstorderqty">
                    <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Card_Quantity}:</h5>
                    <h4> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h4>
                </div>

                <div className="lstordradrs">
                    <div className="lstshipadrs">
                        <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Shipping_Address}</h5>
                        <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        <p><i className="mobile"></i> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                    </div>

                    <div className="lstbiladrs">
                        <h5>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Billing_Address}</h5>
                        <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        <p><i className="mobile"></i> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                    </div>
                </div>

                <div className="order-track">
                    <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                </div>
            </div>
        </div>


    );
}