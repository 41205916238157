// import { Snackbar } from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import React from 'react';
import '../css/dashboard-reports.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import GenerateRequestId from '../../common-function/GenerateRequestId';
import apiCallService from '../../service/api-call-service';
// import CategoryLimit from '../../constants/category-limit.type';
import Pagination from 'react-js-pagination';
import CsvDownload from 'react-json-to-csv';
// import Select from "react-select";
// import { createThisTypeNode } from 'typescript';
// import moment from 'moment';
import LoadingComponent from './loading/txn-loading.component';
import TrasactionDescription from './constants/corpSubtrsaction-type.coponent';


class ViewTA extends React.Component {

    constructor() {
        super();

        this.state = {
            transactionData: [],
            empDetail: [],
            totalAmount: 0,
            currentPage: 1,
            loading: true,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_MONEY_DISBURSAL,
            size: 0,
            searchField: "",

            filteredRecord: [],
            multiValueType: [],
            multiValueRecord: [],
            multiValueStatus: [],
            toDate: '',
            fromDate: '',
            paginatedList: [],
            allValuesForFilter: [],


            placeHolder: "...",
            multiValueTypeOpt: [
                { value: "1", label: "General" },
                { value: "2", label: "Food" },
                { value: "3", label: "Travel" }
            ],
            multiValueRecords: [
                { value: "Debit", label: "Debit" },
                { value: "Credit", label: "Credit" }
            ],
            multiValueStatusOptn: [
                { value: "1", label: "Success" },
                { value: "2", label: "Failed" }
            ],
        };
    }


    componentDidMount = () => {

        function getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }
        var dateRang = getQueryVariable('dateRang')
        var type = getQueryVariable('type')

        this.getTransactionData(dateRang, type);

        console.log(' dateRang  type ', dateRang, type)

    }

    getTransactionData = async (dateRang, type) => {
        let headers = {
            "client_id": "OMTS_77B",
            "token": this.props.token,
            "Authorization": this.props.token
        }

        var transaction_type = 1;
        if (type === "credit") {
            transaction_type = 2
        }

        const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY

        var request = {
            "device_info": {},
            "request": {
                "user": {
                    "username": `91${this.props.roMdn}`
                },
                "transaction_data": {
                    "from_date": `${dateRang} 00:00:00`,
                    "page_index": 0,
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "time_stamp": new Date(),
                    "to_date": `${dateRang} 23:59:59`,
                    "transaction_status": "1",
                    "wallet_type": null,
                    "transaction_type": transaction_type
                }
            }
        }

        var response = await apiCallService.postMethodCall(url, request, headers);

        console.log('response txn history', request, response)
        if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {
            this.setState({ transactionData: response.response.transaction_data })
            this.setState({ filteredRecord: response.response.transaction_data })
            this.setState({ loading: false })
        } else {
            this.setState({ loading: false })
        }

        //  parseFloat(parseFloat(tAm.transaction_amount.balance).toFixed(2)/100).toFixed(2)
    }




    paginate = (pageNumber) => {
        this.setState({ currentPage: pageNumber });

    };



    render() {

        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;



        var filteredPostData = this.state.transactionData;
        var size = this.state.transactionData.length;

        if (filteredPostData === null || filteredPostData === undefined) {
            currentPost = null
        } else {
            currentPost = filteredPostData.slice(indexOfFirstPost, indexOfLastPost)
        }




        var detail = true;
        if (currentPost.length === 0) {
            detail = false
        }


        var fileToExport = [];
        var j = 0;
        filteredPostData.map(data => {
            var obj = {
                Transaction_ID: "",
                Transaction_Date: "",
                // Wallet_Type: "",
                Transaction_Type: "",
                Surcharge: "",
                Amount: "",
                Status: ""
            }



            obj.Transaction_ID = data.transaction_refnumber;
            obj.Transaction_Date = data.transaction_string_date;

            // {
            //     data.wallet_type === CategoryLimit.FOOD
            //         ?
            //         obj.Wallet_Type = "Food Wallet"
            //         :
            //         data.wallet_type === CategoryLimit.TRAVEL
            //             ?
            //             obj.Wallet_Type = "Travel Wallet"
            //             :
            //             data.wallet_type === CategoryLimit.GENERAL
            //                 ?

            //                 obj.Wallet_Type = "General Wallet"
            //                 :
            //                 obj.Wallet_Type = "NA"


            // }
            obj.Type = data.wallet_name;


            obj.Transaction_Type = data.transaction_type;
            obj.Surcharge = parseFloat(parseFloat(data.transaction_amount.surcharge).toFixed(2) / 100).toFixed(2)
            obj.Amount = parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2);
            obj.Available_Balance = parseFloat(parseFloat(data.transaction_amount.balance).toFixed(2) / 100).toFixed(2);;


            data.transaction_status === "1"
                ?
                obj.Status = "Success"
                :
                obj.Status = `${data.transaction_description}`


            fileToExport.push(obj);

        })

        var heightAdjust = window.innerHeight - 230

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        <>
                            <div className="mid-container">
                                <div className="container-fluid">
                                    <div className="row">


                                        <>


                                            <div className="col-12">
                                                <div className="head">
                                                    <div className="head-right" data-toggle="tooltip" title="Back">
                                                        <a href="/ta-report" className="back"><i></i> </a>
                                                    </div>
                                                    <h3>{process.env.REACT_APP_VAR_FOR_VIEW_TA_REPORT_VAR_FOR_TA_Report}</h3>
                                                </div>
                                            </div>




                                            <div className="col-12">
                                                <div style={{ minHeight: heightAdjust }}>

                                                    {
                                                        this.state.loading ?
                                                            <LoadingComponent />
                                                            :

                                                            currentPost.length > 0
                                                                ?
                                                                currentPost.map(data => (
                                                                    <div className="txncard">
                                                                        <div className="row">
                                                                            <div className="col-12 col-md-6 col-lg-3 col-xl-4">
                                                                                <div className="txntype">
                                                                                    <i className={data.transaction_type === 'Credit' ? "credit" : "debit"} data-toggle="tooltip" title={data.transaction_type}></i>


                                                                                    <TrasactionDescription sub_transaction_type={data.sub_transaction_type} transaction_description={data.transaction_description} />


                                                                                    {/* <h5>Load Money - TA Balance</h5> */}


                                                                                    <p>#{data.transaction_refnumber} - {data.transaction_string_date}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                                                                                <div className="txnmode">
                                                                                    <p>Mode</p>
                                                                                    <h6>{data.payment_mode === null ? <>NA</> : data.payment_mode}
                                                                                        {/* - {data.merchant_name === null ? <>NA</> : data.merchant_name} */}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-12 col-md-6 col-lg-2 col-xl-2">
                                                                                {data.transaction_type === 'Credit'
                                                                                    ?
                                                                                    <div className="txnfrom">
                                                                                        <p>{process.env.React_APP_DESCRIPTION_FOR_TXN_History_Credit_TO}</p>

                                                                                        <h6> {data.wallet_name === null ? <>NA</> : data.wallet_name}</h6>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="txnfrom">
                                                                                        <p>{process.env.React_APP_DESCRIPTION_FOR_TXN_History_Debited_from}</p>

                                                                                        <h6> {data.wallet_name === null ? <>NA</> : data.wallet_name}</h6>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className="col-4 col-md-3 col-lg-2 col-xl-1">
                                                                                <div className="txnsurchage">
                                                                                    <p>Surcharge</p>
                                                                                    <h5>₹ {parseFloat(parseFloat(data.transaction_amount.surcharge).toFixed(2) / 100).toFixed(2)}</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 col-md-3 col-lg-2 col-xl-2">
                                                                                <div className="txnamount">
                                                                                    <h4>₹ {parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2)}</h4>
                                                                                    {data.transaction_status === "1"
                                                                                        ?
                                                                                        <p className="success">Success</p>
                                                                                        :
                                                                                        <p className="failed">{data.transaction_header}</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                <div className="col-12">
                                                                    <div className="no-record">
                                                                        <i></i>
                                                                        <h3> No Record Available.</h3>
                                                                    </div>
                                                                </div>
                                                    }


                                                </div>
                                            </div>



                                            <div className="w-100 mt-3"></div>


                                            {parseInt(filteredPostData.length) > parseInt(this.state.postsPerPage)
                                                ?
                                                <>
                                                    <div className="col-12 col-sm-6">
                                                        <p>
                                                            {!detail ? <></> :
                                                                <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"ta-report.csv"}>Export to CSV</CsvDownload>
                                                            }
                                                            Showing {valueToShow} to {(indexOfLastPost > filteredPostData.length) ? filteredPostData.length : indexOfLastPost} of {filteredPostData.length} entries</p>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="pager">
                                                            <Pagination
                                                                activePage={parseInt(this.state.currentPage)}
                                                                itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                                totalItemsCount={size}
                                                                pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                                onChange={this.paginate}
                                                                className="pagination"
                                                                linkClass="page-link"
                                                                itemClass="page-item"
                                                                nextPageText="Next"
                                                                prevPageText="Prev"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="col-12 col-sm-6">
                                                    <p>
                                                        {!detail ? <></> :
                                                            <CsvDownload className="mbtn btnbl" data={fileToExport} filename={"ta-report.csv"} >Export to CSV</CsvDownload>
                                                        }
                                                    </p>
                                                </div>
                                            }








                                        </>



                                    </div>
                                </div>
                            </div>
                        </>

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn

});

export default connect(mapStateToProps)(ViewTA);