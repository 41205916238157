import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { store } from '../../redux/store';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import '../css/money-disbursal.styles.css'
import Pagination from 'react-js-pagination';
import ApiCallingService from '../../service/api-call-service'

import apiCallService from '../../service/api-call-service';
import Select from "react-select";
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { setEditBandDetail } from '../../redux/edit-band-limit/edit-band-limit.action';
import EmployeeBandDiv from './employee-band-component';
import moment from 'moment';
import LoadingComponent from './band-wise.loading.component';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import Can from '../../auth/can/Can';

//  REACT_APP_CORPORATE_SERVICE_GET_BAND_DETAIL


const collectedTrueKeys = {
    band: []
}
var ToDateVar = '';
var FromDateVar = '';
const SetBandWiseDisbursalLimits = ({ roleData, userRole, maxGeneralAmount, isPasswordReset, token, maxFoodAmount, maxTravelAmount, authenticated, bandData, roMdn, wallet }) => {
    var arr = [];
    var listForAction = [];

    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_BAND_RECORD);

    const [bandList, setBandList] = useState([]);
    const [dropDownHidden, setDropDownHidden] = useState(false);
    const [message,] = useState('');
    const [severty,] = useState('');
    const [, setMaxFood] = useState(0);
    const [, setMaxTravel] = useState(0);
    const [, setMaxGeneral] = useState(0);
    const [placeHolder,] = useState("...");

    const [, setVirtualCheck] = useState(false);

    const [allValuesForFilter, setAllValuesForFilter] = useState([]);
    const [multiValueB, setMultiValueB] = useState('')
    const [filteredRecord, setFilteredRecord] = useState([]);

    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');

    useEffect(() => {
        document.title = "ShakePe Manage Band & Disbursal Limits"
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "",
            moneyDisbursalClass: "active",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass: ""
        }

        store.dispatch(setActiveHeader(payload));



        console.log('bandData', bandData)

        setMaxGeneral(parseFloat(maxGeneralAmount) / 100);

        setMaxFood(parseFloat(maxFoodAmount) / 100);

        setMaxTravel(parseFloat(maxTravelAmount) / 100);


        setLimitResponse();


    }, []);


    const setLimitResponse = async () => {
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        console.log(url);
        console.log(roMdn)
        const res = await ApiCallingService.getMethodCallWithParam(url, { 'roId': roMdn });
        console.log('response inside AuthService', res);
        if (!(res.response === undefined) && !(res === undefined) && !(res.response.corpBands === undefined) && !(res.response.corpBands === null)) {
            res.response.corpBands.map(corpBand => {
                var isChecked = 'isChecked';
                var disableButton = 'disableButton';
                console.log('response inside AuthService', corpBand.travel_Limit, " : ", corpBand.general_Limit, " : ", corpBand.wallet_food_limit);
                if ((corpBand.travel_Limit === null || parseInt(corpBand.travel_Limit) === 0) && (corpBand.general_Limit === null || parseInt(corpBand.general_Limit) === 0) && (corpBand.wallet_food_limit === null || parseInt(corpBand.wallet_food_limit) === 0)) {
                    corpBand[isChecked] = true;
                    corpBand[disableButton] = false;
                    setVirtualCheck(true)
                } else {
                    corpBand[isChecked] = false;
                    corpBand[disableButton] = true
                }
                corpBand.wallet_food_limit = parseFloat(parseFloat(corpBand.wallet_food_limit) / 100).toFixed(2)
                corpBand.general_Limit = parseFloat(parseFloat(corpBand.general_Limit) / 100).toFixed(2)
                corpBand.travel_Limit = parseFloat(parseFloat(corpBand.travel_Limit) / 100).toFixed(2)
                arr.push(corpBand);
                console.log('band list data', corpBand);
            })

        }
        setBandList(arr);
        setFilteredRecord(arr);
        setLoading(false);
    }


    const handleDateChange = async (event) => {
        console.log(event.target.value, " : ", event.target.name)
        const { value, name } = event.target;
        if (name === 'toDate') {
            setToDate(value)

            ToDateVar = value
        }
        if (name === 'fromDate') {
            setFromDate(value)
            FromDateVar = value
        }
        console.log(ToDateVar, " and ", FromDateVar);
        if (ToDateVar !== '' && FromDateVar !== "") {

            var formatFromDate = moment(FromDateVar).format("YYYY-MM-DD hh:mm:ss");
            var formatToDate = moment(ToDateVar).format("YYYY-MM-DD hh:mm:ss");

            const requestToSend = {
                "roId": roMdn,
                "fromDate": formatFromDate,
                "toDate": formatToDate

            }


            const url = process.env.REACT_APP_CORPORATE_SERVICE_GET_BAND_DETAIL;
            console.log('request to send :', requestToSend, "url :", url)
            var response = await apiCallService.postMethodCall(url, requestToSend);

            console.log('response', response)

            if (!(response === undefined) && (response.response_code === 0) && response.response !== null) {

                setFilteredRecord(response.response)
                setCurrentPage(1)
            } else {
                setFilteredRecord([])
            }

        }
    }



    const handleMultiBandchange = (option) => {
        console.log('option before', option)
        setMultiValueB(option)
        setCurrentPage(1)

        var data = []
        if (allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', allValuesForFilter.length === 0)
            setAllValuesForFilter(option)
        } else {
            console.log(' allValuesForFilter else', allValuesForFilter.length === 0)
            allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);

            })
            setAllValuesForFilter(data)
        }

        collectedTrueKeys.band = [];
        if (option !== null) {
            option.map(op => {

                collectedTrueKeys.band.push(op.label);
            })
        }

    }


    const clearAll = () => {

        setMultiValueB([])


        setFilteredRecord(bandList)


        collectedTrueKeys.band = [];


        setAllValuesForFilter([])

        setToDate('')
        setFromDate('')
        ToDateVar = '';
        FromDateVar = '';


    }





    const customStyles = {
        control: (base, state) => ({
            ...base,
            'border': '1px solid #ddd',
            'borderRadius': '0',
            'boxSizing': 'content-box',
            'minHeight': '30px',
            'lineHeight': '18px',
            'display': 'flex'
        }),

        valueContainer: (base) => ({
            ...base,
            'position': 'relative',
            'overflow': 'hidden',
            'margin': '0',
            'padding': '5px',
            'width': '100%',
            'minHeight': '30px',
            'border': '1px solid #ddd',
            'backgroundColor': '#fff',
            'lineHeight': '18px',
            'cursor': 'text',
            'alignItems': 'center'
        }),
        multiValue: base => ({
            ...base,
            'boxSizing': 'content-box',
            'position': 'relative',
            'margin': '1px 5px 1px 0px',
            'padding': '1px 2px 3px 5px',
            'border': '1px solid #c4d5e1',
            'lineHeight': '18px',
            'maxWidth': '100%',
            'borderRadius': '3px',
            'backgroundColor': '#d6eeff',
            'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
            'color': '#4a546c',
            'lineHeight': '16px',
            'cursor': 'default',
            'height': '20px'
        }),
        menu: (base) => ({
            ...base,
            'padding': '2px 5px',
            'lineHeight': '16px',

        }),
        input: base => ({
            ...base,
            'height': '18px',
            'lineHeight': '18px'
        })

    };



    //console.log(posts);
    var currentPost = '';
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const valueToShow = indexOfFirstPost + 1;


    //Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);





    const setEditBandDetailLink = (event) => {
        currentPost.map(curr => {
            if (curr.band === event.target.id) {

                var payload = {
                    band: curr.band,
                    corp_id: curr.corp_id,
                    date_created_on: curr.date_created_on,
                    disableButton: curr.disableButton,
                    general_Limit: curr.general_Limit,
                    isChecked: curr.isChecked,
                    is_active: curr.is_active,
                    travel_Limit: curr.travel_Limit,
                    wallet_food_limit: curr.wallet_food_limit
                }

                store.dispatch(setEditBandDetail(payload));

            }
        })
    }




    const multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };

    var filteredPostData = [];
    console.log('filteredRecord', filteredRecord)

    if ((allValuesForFilter === null || !(allValuesForFilter !== undefined && allValuesForFilter.length && allValuesForFilter))) {
        filteredPostData = filteredRecord
        // filteredPost = filteredList;
        console.log('inside if of bandlistToView filteredRecord', allValuesForFilter)
    } else if (allValuesForFilter.length !== 0) {

        filteredPostData = multiPropsFilter(filteredRecord, collectedTrueKeys);

        console.log('multiPropsFilter multiPropsFilter filteredRecord', collectedTrueKeys, " : ", filteredPostData)



    }

    const key = 'band';

    const filteredPost = [...new Map(filteredPostData.map(item =>
        [item[key], item])).values()];





    if (filteredPost === null || filteredPost === undefined) {
        currentPost = null
    } else {
        currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost)
    }
    var minVar = 1


    var detail = false;

    currentPost.map(pos => {
        if (pos.isChecked) {
            detail = true
        }
    })


    var height = window.innerHeight - 400


    var startdateToDate = moment();
    startdateToDate = startdateToDate.format("YYYY-MM-DD");

    if (toDate === '') {
        var startdateFromDate = moment();
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    } else {
        var startdateFromDate = moment(toDate);
        startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
    }



    var dateMax = moment(fromDate);
    dateMax = dateMax.format("YYYY-MM-DD");
    var dateFromMax = moment(toDate);
    dateFromMax = dateFromMax.format("YYYY-MM-DD");

    // console.log('current post', currentPost)
    return (
        <div >
            {(authenticated)
                ? (


                    isPasswordReset ?



                        <div className="mid-container" id="heightFix">
                            <div className="container-fluid">




                                <div className="head">
                                    <div className="row">

                                        <div  className="col-10">
                                            <h3>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Band_and_Disbursal_Limits}</h3>

                                        </div>

                                        <div  className="col-2">
                                            <div className="flterbox">

                                                <div className="dropdown float-right" data-toggle="tooltip" title="Filter">
                                                    <button  className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>


                                                    <div className="dropdown-menu dropdown-menu-right">


                                                        <div className="filters">
                                                            <label htmlFor="select-state">Band:</label>
                                                            <Select
                                                                name="filters"
                                                                placeholder={placeHolder}
                                                                value={multiValueB}
                                                                options={bandData}
                                                                onChange={handleMultiBandchange}
                                                                isMulti={true}
                                                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                                                styles={customStyles}
                                                            />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">From Date:</label>
                                                            <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={fromDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                        </div>

                                                        <div className="filters">
                                                            <label htmlFor="select-state">To Date:</label>
                                                            <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={toDate} onKeyDown={(e) => e.preventDefault()} onChange={handleDateChange} />
                                                        </div>

                                                        <div className="fbtn">
                                                            <button className="sbtn btnrd" type="reset" id="btnClear" onClick={clearAll}>Clear all</button>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                            <div  className="actnbtn">
                                                <Can
                                                    role={userRole}
                                                    perform="bandList-addBand:visit"
                                                    roleData={roleData}
                                                    yes={() => (

                                                        <a href="/create-new-band" data-toggle="tooltip" title="Add New Band" className="add"><i className="add"></i> </a>


                                                    )}
                                                    no={() =>
                                                        null
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="emp-list">
                                    <div className="row">



                                        <Snackbar

                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            open={dropDownHidden}
                                            autoHideDuration={3000}
                                            onClose={e => setDropDownHidden(false)}
                                        >
                                            <Alert onClose={e => setDropDownHidden(false)} severity={severty}>
                                                {message}
                                            </Alert>
                                        </Snackbar>


                                        {
                                            loading
                                                ?
                                                <LoadingComponent />

                                                :

                                                currentPost.length > 0
                                                    ?


                                                    currentPost.map(data => (
                                                        <div className="col-12 col-md-6 col-xl-4">
                                                            <div className="bandcard">
                                                                <div className="bandtype">
                                                                    <i></i>
                                                                    <h5> {data.band}</h5>
                                                                    <p>{data.date_updated_on}</p>
                                                                </div>


                                                                <EmployeeBandDiv data={data} setEditBandDetailLink={setEditBandDetailLink} />

                                                                <div className="walletrow">
                                                                    <div className="row">


                                                                        {parseInt(data.general_Limit) >= 0
                                                                            ?
                                                                            <div className="col-6 col-sm">
                                                                                <div className="empwallet">

                                                                                    <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_General}</p>
                                                                                    <h6 className="general">₹ {data.general_Limit}</h6>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                        {parseInt(data.wallet_food_limit) >= 0
                                                                            ?
                                                                            <div className="col-6 col-sm">
                                                                                <div className="empwallet">
                                                                                    <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Food}</p>
                                                                                    <h6 className="food">₹ {data.wallet_food_limit}</h6>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                        {parseInt(data.travel_Limit) >= 0
                                                                            ?
                                                                            <div className="col-6 col-sm">
                                                                                <div className="empwallet">
                                                                                    <p>{process.env.React_APP_MONEY_DISBURSAL_MANAGE_BAND_VAR_FOR_Travel}</p>
                                                                                    <h6 className="travel">₹ {data.travel_Limit}</h6>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))

                                                    :
                                                    <div className="col-12 ">
                                                        <div className="no-record">
                                                            <i></i>
                                                            <h3> No Record Available.</h3>
                                                        </div>
                                                    </div>
                                        }




                                      

                                            {parseInt(filteredPost.length) > parseInt(postsPerPage)
                                                ?
                                                <>

                                                    <div className="col-12 col-sm-6">
                                                        <p> Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <div className="pager">
                                                            <Pagination
                                                                activePage={parseInt(currentPage)}
                                                                itemsCountPerPage={parseInt(postsPerPage)}
                                                                totalItemsCount={filteredPost.length}
                                                                pageRangeDisplayed={parseInt(postsPerPage)}
                                                                onChange={paginate}
                                                                className="pagination"
                                                                linkClass="page-link"
                                                                itemClass="page-item"
                                                                nextPageText="Next"
                                                                prevPageText="Prev"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                            }

                                      

                                    </div>
                                </div>
                            </div>
                        </div>





                        :
                        <ResetLoginPassword />

                )
                : (
                    <Redirect to='/' />
                )


            }

        </div >

    )

}


const mapStateToProps = ({ user: { roMdn, wallet, maxGeneralAmount, userRole,
    maxFoodAmount,
    maxTravelAmount }, token: { authenticated, token, isPasswordReset }, band: { bandData }, role: { roleData } }) => ({
        authenticated, bandData, roMdn, wallet, maxGeneralAmount, roleData, userRole,
        maxFoodAmount,
        maxTravelAmount, token, isPasswordReset
    });

export default connect(mapStateToProps)(SetBandWiseDisbursalLimits);

