import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (


        <>

            <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                <div className="uesr-card">
                    <a href="profile.html" className="user-link">
                        <div className="user-basic">
                            <img src="images/user-1.jpg" alt="" />
                            <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /> <br /><span> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></span></p>
                        </div>

                        <div className="cardsts">
                            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        </div>

                        <div className="bandamt">
                            <div className="waltamt">
                                <p>General</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>

                            <div className="waltamt">
                                <p>Food</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>

                            <div className="waltamt">
                                <p>Travel</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>
                    </a>

                </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                <div className="uesr-card">
                    <a href="profile.html" className="user-link">
                        <div className="user-basic">
                            <img src="images/user-1.jpg" alt="" />
                            <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /> <br /><span> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></span></p>
                        </div>

                        <div className="cardsts">
                            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        </div>

                        <div className="bandamt">
                            <div className="waltamt">
                                <p>General</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>

                            <div className="waltamt">
                                <p>Food</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>

                            <div className="waltamt">
                                <p>Travel</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>
                    </a>

                </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                <div className="uesr-card">
                    <a href="profile.html" className="user-link">
                        <div className="user-basic">
                            <img src="images/user-1.jpg" alt="" />
                            <p> <Skeleton className="loading-basic-p" variant="text" animation="wave" /> <br /><span> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></span></p>
                        </div>

                        <div className="cardsts">
                            <Skeleton className="loading-basic-p" variant="text" animation="wave" />
                        </div>

                        <div className="bandamt">
                            <div className="waltamt">
                                <p>General</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>

                            <div className="waltamt">
                                <p>Food</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>

                            <div className="waltamt">
                                <p>Travel</p>
                                <h6> <Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
        </>
    );
}