import React, { useEffect, useState } from "react";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../redux/token/token.action'
import { Link, Redirect } from "react-router-dom";
import TrasactionDescription from '../../dasboard-reports/component/constants/subtrsaction-type.coponent';
import apiCallService from "../../service/api-call-service";
import GenerateRequestId from "../../common-function/GenerateRequestId";
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

const LoadInfo = ({ roMdn, authenticated, token, isPasswordReset }) => {


    const [recentLoadMoney, setRecentLoadMoney] = useState([]);
    const [size, setSize] = useState(0);


    useEffect(() => {

        document.title = "ShakePe Info Load Money "
        getRecentLoadMoney(roMdn);


        // REACT_APP_LOAD_MONEY_INFO_PAGE_HEADING=Load Money Info
        // REACT_APP_PayeeName=ZRO and Company Name
        // REACT_APP_PayeeBankIFCCode=YESB0CMSNOC
        // REACT_APP_PayeeAcccount_StartingAlphabets=ZROPAY

    }, []);


    const getRecentLoadMoney = async (mdn) => {
        let headers = {
            "client_id": "OMTS_77B",
            "token": token,
            "Authorization": token
        }

        const url = process.env.REACT_APP_GET_TRANSACTION_HISTORY

        var request = {
            "device_info": {},
            "request": {
                "user": {
                    "username": mdn
                },
                "transaction_data": {
                    "count": 5,
                    "from_date": null,
                    "page_index": 0,
                    "request_id": GenerateRequestId.geneRateRequestId(),
                    "time_stamp": new Date(),
                    "to_date": null,
                    "transaction_status": "3",
                    "wallet_type": null,
                    "transaction_type": "2"
                }
            }
        }

        var response = await apiCallService.postMethodCall(url, request, headers);

        console.log('response txn history', response)
        if (response !== undefined && response.response_code === 0 && response.response !== undefined && response.response.transaction_data !== undefined) {

            var arr = []
            var int = 0;
            response.response.transaction_data.map(data => {
                if (int < 6) {
                    arr.push(data)
                    int++
                }
            })
            setRecentLoadMoney(arr)
            setSize(response.response.transaction_data.lenght);
        }

    }


    return (

        (authenticated)
            ? (

                isPasswordReset
                    ?

                    <div className="mid-container">
                        <div className="container-fluid" >
                            <div className="row">
                                <div className="col-12 col-lg-6 col-xl-8">
                                    <div className="banktobank" style={{ height: "610px" }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <h3>{process.env.REACT_APP_LOAD_MONEY_INFO_PAGE_HEADING} <a href="/load-money" className="back">Back</a></h3>
                                            </div>

                                            <div className="col-12">
                                                <p>{process.env.REACT_APP_TEXT_FOR_Now_you_can_load}</p>
                                                <p>{process.env.REACT_APP_TEXT_FOR_Details_for_adding_Payee}</p>

                                                <table className="table table-hover table-bordered">
                                                    <tbody>
                                                    <tr className="table-primary">
                                                        <th width="180">{process.env.REACT_APP_TEXT_FOR_Payee_Name_LABEL}</th>
                                                        <th>{process.env.REACT_APP_PayeeName}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{process.env.REACT_APP_TEXT_FOR_Payee_Account_Number_LABEL}</td>
                                                        <td>{`${process.env.REACT_APP_PayeeAcccount_StartingAlphabets}${roMdn}`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{process.env.REACT_APP_TEXT_FOR_Payee_Bank_Name_Label}</td>
                                                        <td>{process.env.REACT_APP_TEXT_FOR_Payee_Bank_Name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{process.env.REACT_APP_TEXT_FOR_Payee_Bank_IFSC_Code_Label}</td>
                                                        <td>{process.env.REACT_APP_PayeeBankIFCCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{process.env.REACT_APP_TEXT_FOR_Account_Type_Label}</td>
                                                        <td>{process.env.REACT_APP_TEXT_FOR_Account_Type}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>

                                                <h5>{process.env.REACT_APP_TEXT_FOR_Please_follow_below_steps}</h5>
                                            </div>

                                            <div className="col-12">
                                                <div className="banksteps">
                                                    <ul>
                                                        <li>
                                                            <i className="step1"></i>
                                                            <h6>{process.env.REACT_APP_TEXT_FOR_Login_to_bank_account_and_go_to}</h6>
                                                            <span className="steparrow"></span>
                                                        </li>
                                                        <li>
                                                            <i className="step2"></i>
                                                            <h6>{process.env.REACT_APP_TEXT_FOR_Click_on_Add_Beneficiary_or_Register_a_new_Payee}</h6>
                                                            <span className="steparrow"></span>
                                                        </li>
                                                        <li>
                                                            <i className="step3"></i>
                                                            <h6>{process.env.REACT_APP_TEXT_FOR_Select_Other_Bank_NEFT_transfer_and_enter_the_amount}</h6>
                                                            <span className="steparrow"></span>
                                                        </li>
                                                        <li>
                                                            <i className="step4"></i>
                                                            <h6>{process.env.REACT_APP_TEXT_FOR_Click_on_Confirm_button_to_complete_the_transaction}</h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <p className="txtgry">{process.env.REACT_APP_TEXT_FOR_If_the_transaction_was_a_success}</p>
                                                <p className="impnote">{process.env.REACT_APP_TEXT_FOR_NOTE_TO_TRANSFER_FROM_BANK_TO_BANK}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 col-lg-6 col-xl-4">
                                    <div className="recent-txn">
                                        <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Recent_Load_Money}
                                            {
                                                size < 6
                                                    ?
                                                    null
                                                    :
                                                    <Link

                                                        to={{
                                                            pathname: '/load-txn',
                                                            state: { roMdn: roMdn },

                                                        }}
                                                        className="sbtn btnbl"
                                                    >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Recent_Load_Money_View_all_Button}</Link>
                                            }
                                        </h3>
                                        {size === 0 ?
                                            <div className="col-12">
                                                <div className="no-record">
                                                    <i></i>
                                                    <h3 style={{ borderBottom: "none" }}> No Record Available.</h3>
                                                </div>
                                            </div>
                                            :


                                            recentLoadMoney.map(data => (
                                                <div className="txncard">
                                                    <a style={{ color: "#354558" }} href={() => false} >
                                                        <div className="row">
                                                            <div className="col-12 col-sm-8">
                                                                <div className="txntype">
                                                                    <i className="credit" data-toggle="tooltip" title="Credit"></i>
                                                                    <TrasactionDescription sub_transaction_type={data.sub_transaction_type} transaction_description={data.transaction_description} merchant_name={data.merchant_name} />

                                                                    {/* <h5>Load Money - TA Balance</h5> */}
                                                                    <p>#{data.transaction_refnumber} <br />{data.transaction_string_date === null ? data.transaction_date : data.transaction_string_date}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-4">

                                                                <div className="txnamount">
                                                                    <h4>₹ {parseFloat(parseFloat(data.transaction_amount.amount).toFixed(2) / 100).toFixed(2)}</h4>
                                                                    {data.transaction_status === "1"
                                                                        ?
                                                                        <p className="success">Success</p>
                                                                        :
                                                                        <p className="failed">{data.transaction_description}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )

                                            )


                                        }


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <ResetLoginPassword />

            )
            : (
                <Redirect to='/' />
            )



    );


};

const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { roMdn } }) => ({
    roMdn, authenticated, token, isPasswordReset
});


export default connect(mapStateToProps)(LoadInfo);
