import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import '../css/employee.css';
import ApiCallingService from '../../service/api-call-service'
import Pagination from "react-js-pagination";
import RecordFilterComponent from './record-filter';
import RecordList from './record-list.component';
import { setActiveHeader } from '../../redux/activeHeader/activeheader.action';
import { store } from '../../redux/store';




const collectedTrueKeys = {
    band: [],
    location: [],
    department: []
}

class SingleSuccessRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeByBatch: [],
            batchId: '',
            currentPage: 1,
            postsPerPage: process.env.REACT_APP_NUM_OF_COUNT_PER_PAGE_FOR_BULK_EMPLOYEE_RECORDS,
            uploadeDetail: [],
            multiRecord: [],
            filteredRecord: [],
            multiValueD: [],
            multiValueB: [],
            multiValueL: [],
            allValuesForFilter: []

        }
    }

    componentDidMount = () => {
        var payload = {
            dashBoardClass: "",
            manageEmployeeClass: "active",
            moneyDisbursalClass: "",
            manageCardClass: "",
            manageLimitClass: "",
            activityClass:""
        }

        store.dispatch(setActiveHeader(payload));



        const getDetail = async () => {
            console.log('this.props.location.search', this.props.location.state)
            // const queryString = require('query-string',);
            // var parsed = queryString.parse(this.props.location.search);
            // console.log('parsed',parsed.status);
            // console.log('success record props', parsed.id);
            if (this.props.location.state) {
                const batchId = this.props.location.state.bulkDetail.batchId;
                const responseStatus = this.props.location.state.status
                var object = "object"
                const data = this.props.location.state.bulkDetail;
                console.log('data', data)
                this.setState({ uploadeDetail: data })

                const url = `${process.env.REACT_APP_GET_Employee_By_BATCHID}?batchId=${batchId}&responseStatus=${responseStatus}`
                //const url = `http://localhost:7072/employee/fetchbatch?batchId=${batchId}`
                var response = await ApiCallingService.getMethodCallWithoutParam(url)
                console.log("SuccessRecord response by batchId", response);
                if (!(response === undefined) && !(response.employees === undefined) && !(response.employees === null)) {
                    this.setState({ employeeByBatch: response.employees });
                    this.setState({ filteredRecord: response.employees });
                    var successRecord = response.size;
                    console.log('successRecord', successRecord)
                    console.log("this.state record", this.state.employeeByBatch);
                }
            }
        }

        getDetail();

    }

    paginate = (pageNumber) => { this.setState({ currentPage: pageNumber }) };

    handleMultiDepartmentchange = (option) => {
        // ((bandListToView === null || !(bandListToView.length && bandListToView)
        this.setState({ multiValueD: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.department = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.department.push(op.value)

            })
        }


    }
    handleMultiBandchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueB: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.band = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.band.push(op.value)

            })
        }


    }
    handleMultiLocationchange = (option) => {
        //s  console.log('option log', option)
        this.setState({ multiValueL: option })

        this.setState({ currentPage: 1 })
        var data = []
        if (this.state.allValuesForFilter.length === 0) {

            console.log('allValuesForFilter.length===0', this.state.allValuesForFilter.length === 0)
            this.setState({ allValuesForFilter: option });
        } else {
            console.log('else', this.state.allValuesForFilter.length === 0)
            this.state.allValuesForFilter.map(all => {
                data.push(all);
            })
            option !== null && option.map(op => {
                data.push(op);
            })
            this.setState({ allValuesForFilter: data });
        }

        collectedTrueKeys.location = []
        if (option !== null) {
            option.map(op => {
                collectedTrueKeys.location.push(op.value)

            })
        }

    }

    clearAll = () => {
        this.setState({ filteredRecord: this.state.employeeByBatch })
        this.setState({ multiValueD: [] })
        this.setState({ multiValueL: [] })
        this.setState({ multiValueB: [] })

        this.setState({ allValuesForFilter: [] })


        collectedTrueKeys.department = [];
        collectedTrueKeys.location = [];
        collectedTrueKeys.band = [];

    }



    render() {

        const multiPropsFilter = (products, filters) => {
            const filterKeys = Object.keys(filters);
            return products.filter(product => {
                return filterKeys.every(key => {
                    if (!filters[key].length) return true;
                    // Loops again if product[key] is an array (for material attribute).
                    if (Array.isArray(product[key])) {
                        return product[key].some(keyEle => filters[key].includes(keyEle));
                    }
                    return filters[key].includes(product[key]);
                });
            });
        };


        //console.log(posts);
        var currentPost = '';
        //Get Current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const valueToShow = indexOfFirstPost + 1;



        var filteredPost = [];

        if ((this.state.allValuesForFilter === null || !(this.state.allValuesForFilter !== undefined && this.state.allValuesForFilter.length && this.state.allValuesForFilter))) {
            filteredPost = this.state.filteredRecord
            // filteredPost = filteredList;
            console.log('inside if of bandlistToView', this.state.allValuesForFilter)
        } else if (this.state.allValuesForFilter.length !== 0) {

            filteredPost = multiPropsFilter(this.state.filteredRecord, collectedTrueKeys);

            console.log('multiPropsFilter multiPropsFilter', collectedTrueKeys, " : ", filteredPost)



        }





        if (filteredPost === null) {
            currentPost = null
        } else {
            currentPost = filteredPost.slice(indexOfFirstPost, indexOfLastPost);
        }
        console.log("this.state.employeeByBatch true", filteredPost)

        return (

            (this.props.authenticated)
                ?
                (

                    <div className="mid-container" id="heightFix">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">

                                    <div className="head">

                                        <RecordFilterComponent multiValueD={this.state.multiValueD} multiValueB={this.state.multiValueB} multiValueL={this.state.multiValueL} clearAll={this.clearAll} multiValue={this.state.multiValue} handleMultiDepartmentchange={this.handleMultiDepartmentchange} handleMultiBandchange={this.handleMultiBandchange} handleMultiLocationchange={this.handleMultiLocationchange} />
                                        <h3>Successful Records </h3>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="alert alert-success">Added by {this.state.uploadeDetail.name} on {this.state.uploadeDetail.downloadDate}</div>
                                </div>

                                <RecordList employeeList={currentPost} recordType="success" />
                                {parseInt(filteredPost.length) > parseInt(this.state.postsPerPage)
                                    ?
                                    <>
                                        <div className="col-12 col-sm-6">
                                            <p>Showing {valueToShow} to {(indexOfLastPost > filteredPost.length) ? filteredPost.length : indexOfLastPost} of {filteredPost.length} entries</p>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <div className="pager">
                                                <Pagination
                                                    activePage={parseInt(this.state.currentPage)}
                                                    itemsCountPerPage={parseInt(this.state.postsPerPage)}
                                                    totalItemsCount={filteredPost.length}
                                                    pageRangeDisplayed={parseInt(this.state.postsPerPage)}
                                                    onChange={this.paginate}
                                                    className="pagination"
                                                    linkClass="page-link"
                                                    itemClass="page-item"
                                                    nextPageText="Next"
                                                    prevPageText="Prev"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <Redirect to='/' />
                )


        )
    }

}

const mapStateToProps = ({ token: { authenticated }, bulk: { bulkDetailSingleSuccess, statusSingleSucess } }) => ({
    authenticated, bulkDetailSingleSuccess, statusSingleSucess
});

export default connect(mapStateToProps)(SingleSuccessRecord);