import React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import apiCallService from '../../../service/api-call-service';

import ResetLoginPassword from '../../component/reset-login-password';
import SearchIfsc from './search-ifsc.pages';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import CreatableSelect from 'react-select/creatable';
import img from '../../../assests/icons/arrow-down.svg'

import UploadImage from '../../../employee/component/upload-image.component';
import Countdown from 'react-countdown';
import history from '../../../auth/history';

class AddCorpBank extends React.Component {

    constructor() {
        super();

        this.state = {
            ifscCode: '',
            bankName: '',
            fullName: '',
            accountNumber: '',
            confirmAccountNum: '',
            accountType: '',
            cancelledChequeUrl: '',
            bankArray: [],
            accountTypeArray: [],

            submitButtonDisable: true,
            isValidAccountType: false,
            isValidBankName: false,


            message: '',
            severty: '',
            otp: "",
            referenceNum: "",
            dropDownHidden: false,
            buttonShow: false,
            triggeretime: false,
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            disableVar: true,

            otpButtonClose: false,
            buttonShowLimit: false,


            isValidAccount: false




        };
    }


    componentDidMount = async () => {

        localStorage.setItem('url', '')

        this.getBank();
        this.getBankTypeAllowed();

        if (this.props.corpBankDetail !== null) {
            this.setState({
                ifscCode: this.props.corpBankDetail.ifscCode,
                bankName: this.props.corpBankDetail.bank,
                fullName: this.props.corpBankDetail.fullName,
                accountNumber: this.props.corpBankDetail.accountnumber,
                confirmAccountNum: this.props.corpBankDetail.accountnumber,
                accountType: this.props.corpBankDetail.accountType,
                cancelledChequeUrl: this.props.corpBankDetail.cancelledChequeURL,
            })
        }

    }

    getBank = async () => {

        console.log('Response bankDetail update mai hai: iskeliye entered');
        // const url = `${process.env.REACT_APP_GET_BANK_DETAIL}/${process.env.REACT_APP_Var_FOR_ACTIVE_STATUS}`;
        // const response = await apiCallService.getMethodCallWithoutParam(url)

        // console.log('Response bankDetail : ', response);

        // if (!(response === undefined) && (response.response_code == 0)) {

        //     response.bank_details.map(bank => {
        const RequestToSend = {

            "request": {
                // "bank_name": newValue.bankName,
                "bank_name": null,
                "state_name": null,
                "city_name": null,
                "branch_name": null
            }
        }

        const url = process.env.REACT_APP_SEARCH_IFSC_CODE

        const response = await apiCallService.postMethodCall(url, RequestToSend)


        // const response = await apiCallService.getMethodCallWithoutParam(url)

        console.log('Response bankDetail update mai hai: iskeliye ', response);

        if (!(response === undefined) && (response.response_code === 0 && response.response.get_details != null)) {

            response.response.get_details.map(bank => {
                var value = "value";
                var label = "label";
                bank[value] = bank.bankName;
                bank[label] = bank.bankName;
            })
            this.setState({ bankArray: response.response.get_details });
        } else {
            this.setState({ severity: "error" })
            this.setState({ message: response.response_description })
            this.setState({ messagePopUpHidden: true });
            console.log('message printed')
            this.setState({ bankArray: [] });
        }

    }


    getBankTypeAllowed = async () => {



        this.setState({ accountTypeArray: [] });
        const url = process.env.REACT_APP_CORP_GET_BANK_TYPE_ALLOWED;
        const response = await apiCallService.getMethodCallWithoutParam(url)

        console.log('Response bankDetail : ', response);

        if ((response !== undefined) && (response !== null) && (response !== "") && response.response_description === undefined) {

            response.map(bank => {
                var value = "value";
                var label = "label";
                bank[value] = bank.accountType;
                bank[label] = bank.accountType;
            })
            this.setState({ accountTypeArray: response });
        } else {
            this.setState({ severity: "error" })
            this.setState({ message: response.response_description })
            this.setState({ messagePopUpHidden: true });
            console.log('message printed')
        }

    }



    handleChangeBankDropDown = async (newValue, actionMeta) => {



        if (newValue !== null && newValue.value !== null) {

            this.setState({ bankName: newValue.bankName });
        } else {
            this.setState({ bankName: '' })
        }


    }


    handleChangeAccountTypeDown = async (newValue, actionMeta) => {



        if (newValue !== null && newValue.value !== null) {

            this.setState({ accountType: newValue.accountType });
        } else {
            this.setState({ accountType: '' })
        }


    }


    setIfscCode = (e, searchIfscCode, bankNameToset) => {

        console.log("searchIfscCode y kya aa rha hai aur save ku nhi ho rha", searchIfscCode)
        this.setState({
            ifscCode: searchIfscCode,
            bankName: bankNameToset
        })



    }


    hadleDefaultSubmit = async (event) => {
        event.preventDefault();


        this.setState({
            isValidAccountType: false,
            isValidBankName: false
        })
        if (this.state.bankName === "") {
            this.setState({
                isValidBankName: true
            })

        } else if (this.state.accountType === "") {
            this.setState({
                isValidAccountType: true
            })
        } else {



            console.log('request allValues', this.props.mdn)
            console.log('event target id otpGenerationLimit', event.target.id)
            console.log('fetch target id by different method')

            // var detail = event.target.id;

            var url = process.env.REACT_APP_URL_TO_GET_OTP
            var requestToSend = {
                "request": {
                    "eventType": "rowalletlinkup",
                    "destinations": [
                        {
                            "address": this.props.mdn,
                            "type": "mobile"
                        }
                    ]
                }
            }

            console.log('request allValues', requestToSend)

            var response = await apiCallService.postMethodCall(url, requestToSend);


            console.log('request response allValues', response);
            if (response.response_code === 0 && response.response !== undefined) {

                this.setState({ buttonShowLimit: true })
                this.setState({ referenceNum: response.response.referenceNumber })
                this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
                this.setState({ triggeretime: true })
                // console.log("sendotpsaveCorpLimitButton", document.getElementById("sendotpsaveCorpLimitButton"))
                document.getElementById("sendotp").click();

            } else {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'error' })
                this.setState({ message: response.response_description })
            }

        }
    }

    resendOtp = async (event) => {
        var url = process.env.REACT_APP_URL_TO_GET_OTP
        var requestToSend = {
            "request": {
                "eventType": "rowalletlinkup",
                "destinations": [
                    {
                        "address": this.state.mobileNum,
                        "type": "mobile"
                    }
                ]
            }
        }

        console.log('request allValues', requestToSend)

        var response = await apiCallService.postMethodCall(url, requestToSend);
        this.setState({ triggeretime: false })

        console.log('request response allValues', response);
        if (response.response_code === 0 && response.response !== undefined) {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile })
            this.setState({ referenceNum: response.response.referenceNumber })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true })

        } else {
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }

    }



    saveBankDetail = async (event) => {
        event.preventDefault();

        this.setState({
            isValidAccountType: false,
            isValidBankName: false
        })

        if (this.state.accountType === "") {
            this.setState({
                isValidAccountType: true
            })
        } else if (this.state.bankName === "") {
            this.setState({
                isValidBankName: true
            })
        } else {



            var urlImg = localStorage.getItem('url');

            var request = {
                "bank": this.state.bankName,
                "fullName": this.state.fullName,
                "accountnumber": this.state.accountNumber,
                "accountType": this.state.accountType,
                "ifscCode": this.state.ifscCode,
                "cancelledChequeURL": urlImg,
                "corpId": this.props.roMdn,
                "updatedby": this.props.currentUser,
                "addedBy": this.props.currentUser,
                "referenceNumber": this.state.referenceNum,
                "otp": this.state.otp
            }

            var response = await apiCallService.postMethodCall(this.props.bankUrl, request);
            console.log('response Corporate bankDetail', request, response);

            if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {


                this.setState({ otpButtonClose: true })
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'success' })
                this.setState({ message: process.env.React_APP_Message_FOR_BANK_DETAIL_SAVED_SUCESSFLY })

                // console.log("closeOtpLimitSection", document.getElementById("closeOtpLimitSection"))
                document.getElementById("closeOtpLimitSection").click();
                this.setState({ buttonShow: false })
                this.setState({ otpButtonClose: false })
                this.setState({ otp: '' })

                history.push(this.props.bankBackButton);
                history.go();


            } else {
                this.setState({ otpButtonClose: true })
                this.setState({ dropDownHidden: true })
                this.setState({ severty: 'error' })
                this.setState({ message: response.response_description })
                this.setState({ buttonShow: false })
                this.setState({ otpButtonClose: false })

            }
        }
    }


    validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    MethodTosetEnableVar = () => {
        this.setState({ disableVar: true });
    }

    MethodTosetDisabledVar = () => {
        this.setState({ disableVar: false });
    }


    validateAccountNumber = () => {
        this.setState({ isValidAccount: false });
        console.log("Response bankDetail :", this.state.accountNumber !== "", this.state.confirmAccountNum !== "", this.state.confirmAccountNum !== this.state.accountNumber)
        if (this.state.accountNumber !== "" && this.state.confirmAccountNum !== "" && this.state.confirmAccountNum !== this.state.accountNumber) {
            this.setState({ isValidAccount: true });
        }
    }



    render() {

        // this.getSurchargeCalulated();
        console.log("States here", this.state)

        const colourStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: 'white',
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                minHeight: "22px",
                lineHeight: "22px",
                background: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "12px",
                backgroundPosition: "center right",
                marginTop: "-7px"
            }),

            valueContainer: styles => ({
                ...styles,
                padding: "0px 5px 0px 0px"
            }),


            menu: styles => ({
                ...styles,
                zIndex: "9999"
            }),


            input: styles => ({
                ...styles,
                margin: "0px"
            }),



            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //   const color = chroma(data.color);
                return {
                    ...styles,
                };
            },



        };

        var zeroOne = this.props.mdn.substring(0, 1);
        var sevenNine = this.props.mdn.substring(8, 10);

        console.log("State aa rhi hai", this.state);



        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <div className="mid-container">
                                <div className="container-fluid">
                                    <Snackbar
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={this.state.dropDownHidden}
                                        autoHideDuration={3000}
                                        onClose={e => this.setState({ dropDownHidden: false })}
                                    >
                                        <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                            {this.state.message}
                                        </Alert>
                                    </Snackbar>
                                    <form onSubmit={this.hadleDefaultSubmit}>

                                        <div className="head">
                                            <div className="row">
                                                <div className="col-10">
                                                    <h3>{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Corporate_Bank_Account}</h3>

                                                </div>


                                                <div className="col-2">
                                                    <div className="actnbtn">
                                                        <a href={this.props.bankBackButton} data-toggle="tooltip" title="Back" className="back"><i className="back"></i></a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="addformcard">


                                            <div className="row">




                                                <div className="col-12 col-sm-6 col-lg-4">


                                                    <div className="webform">
                                                        <div className="formselect">
                                                            <label for="depart">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Bank_Name_Label}</label>
                                                            <CreatableSelect
                                                                isClearable
                                                                onChange={this.handleChangeBankDropDown}
                                                                options={this.state.bankArray}
                                                                styles={colourStyles}
                                                                components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                                value={this.state.bankArray.map(bA => {
                                                                    if (bA.value === this.state.bankName) {
                                                                        return bA
                                                                    }
                                                                })}
                                                                required={true}
                                                                formatCreateLabel={() => undefined}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 ">
                                                    <div className="webform">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="fname" placeholder="Name" type="text" value={this.state.fullName} pattern="[a-zA-Z ]{2,50}" onChange={e => { this.setState({ fullName: e.target.value }) }} required />
                                                            <label for="fname">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Full_Name}</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 col-sm-6 col-lg-4 ">
                                                    <div className="webform">
                                                        <div className="form-floating">
                                                            <input className="form-control"
                                                                type="number"
                                                                // pattern="[0-9]{18}"
                                                                onInput={this.validateInputLenght}
                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                id="bankant" maxLength="18"
                                                                value={this.state.accountNumber} onChange={e => { this.setState({ accountNumber: e.target.value }) }} onBlur={this.validateAccountNumber} required />
                                                            <label for="bankant">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Account_Number}</label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-12 col-sm-6 col-lg-4 ">
                                                    <div className="webform">
                                                        <div className="form-floating">
                                                            <input className="form-control"
                                                                type="number"
                                                                // pattern="[0-9]{10}"
                                                                onInput={this.validateInputLenght}
                                                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                                maxLength="18" id="conbankacnt" value={this.state.confirmAccountNum} onChange={e => { this.setState({ confirmAccountNum: e.target.value }) }} onBlur={this.validateAccountNumber} required />
                                                            <label for="conbankacnt">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Account_number_not_same}</label>
                                                            {
                                                                this.state.isValidAccount
                                                                    ?
                                                                    <span className="text-danger">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Account_number_not_same}</span>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4">
                                                    <div className="webform">
                                                        <div className="formselect">
                                                            <label htmlFor="sg" >{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Account_Type}</label>
                                                            <CreatableSelect
                                                                isClearable
                                                                onChange={this.handleChangeAccountTypeDown}
                                                                options={this.state.accountTypeArray}
                                                                styles={colourStyles}
                                                                components={{ IndicatorSeparator: () => null, IndicatorsContainer: () => null, }}
                                                                value={this.state.accountTypeArray.map(bandData => {
                                                                    if (bandData.accountType === this.state.accountType) {
                                                                        return bandData
                                                                    }
                                                                })}
                                                                required={true}
                                                                formatCreateLabel={() => undefined}
                                                            />
                                                            {
                                                                this.state.isValidAccountType
                                                                    ?
                                                                    <span className="text-danger" style={{ color: "#f05125" }}>{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_IS_VALID_ACCOUNT_TYPE}</span>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4">
                                                    <div className="webform">
                                                        <div className="form-floating">
                                                            <input className="form-control" type="text" id="ifsc" placeholder="IFSC" pattern="^[A-Z]{4}0[A-Z0-9]{6}$" maxLength="11" onInput={this.validateInputLenght} value={this.state.ifscCode} onChange={e => { this.setState({ ifscCode: e.target.value }) }} required />
                                                            <label for="ifsc">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_IFSC_Code}</label>
                                                            <button type="button" className="srcode" data-bs-toggle="modal" data-bs-target="#srchifsc"></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <UploadImage currentUser={this.props.currentUser} header="Upload Cancelled Cheque"
                                                        h6Value={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_DRAG_AND_DROP}
                                                        h6SpanValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_BROWSE}
                                                        h6ValueAfterbreak="Cheque"
                                                        pValue={process.env.REACT_APP_Var_FOR_ADD_EMPLOYEE_FILE_DETAIL}
                                                    />


                                                </div>
                                                <div className="col-12 pt-3">
                                                    <a className="lbtn btngr" href={this.props.bankBackButton}>{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Cancel_Button}</a>

                                                    {
                                                        this.state.buttonShowLimit
                                                            ?
                                                            <button type="button" className="mbtn btnbl mt-3" id="sendotp" data-bs-toggle="modal" data-bs-target="#sendotpTarget" >{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Submit_Button}</button>
                                                            :
                                                            <button className="mbtn btnbl mt-3" disabled={this.state.isValidAccount} type="submit">{process.env.REACT_APP_Add_Corp_BANK_VAR_FOR_Submit_Button}</button>
                                                    }
                                                    {/* <input type="submit" value="Submit" className="lbtn btnbl" data-bs-toggle="modal" data-bs-target="#sendotp" disabled={this.state.submitButtonDisable} /> */}
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <SearchIfsc setIfscCode={this.setIfscCode} />

                                <div className="modal fade" id="sendotpTarget" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                    <div className="modal-dialog modal-sm">

                                        <form className="modal-content alerttext" onSubmit={this.saveBankDetail}>
                                            <div className="modal-header">
                                                <h5>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_HEADING_One_Time_Password} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                            </div>
                                            <div className="modal-body">
                                                <div className="otptxt">
                                                    <input className="form-control" type="text" placeholder={process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_Enter_OTP} value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} required />
                                                    <p>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_PARAGRAPH_OF_OTP} {zeroOne}XXXXXXX{sevenNine}</p>

                                                    <h5>
                                                        {
                                                            this.state.triggeretime ?
                                                                <Countdown date={this.state.time} onMount={this.MethodTosetEnableVar} onComplete={this.MethodTosetDisabledVar} />
                                                                :
                                                                null
                                                        }
                                                    </h5>
                                                    ref no. {this.state.referenceNum}
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                {this.state.disableVar
                                                    ?
                                                    <button type="button" className="mbtn btngr" onClick={this.resendOtp} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                    :
                                                    <button type="button" className="mbtn btngn" onClick={this.resendOtp} disabled={this.state.disableVar}>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}</button>
                                                }
                                                {
                                                    this.state.otpButtonClose
                                                        ?
                                                        <button type="button" className="mbtn btnbl" id="closeOtpLimitSection" data-bs-dismiss="modal" aria-label="Close">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                        :
                                                        <button type="submit" className="mbtn btnbl" >{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            :
                            <ResetLoginPassword />

                    )
                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, token, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName, corpBankDetail, bankBackButton, bankUrl, mobileNum }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, token, currentUser, fullName, isPasswordReset, corpBankDetail, bankBackButton, bankUrl, mobileNum

});




export default connect(mapStateToProps)(AddCorpBank);