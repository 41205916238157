import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import CardStatus from '../../../constants/card-status.type';

import { setEmployeeDetails } from "../../../redux/user/user.actions";
import { setBackUrl } from "../../../redux/user/user.actions";
import Avatar from 'react-avatar';
import KycStatus from "../../../constants/kyc-status.type";
import MultiCardStatus from '../../../common/component/multiCardStatus.component';
import ReactTooltip from "react-tooltip";
import ResetLoginPassword from '../../../corporate-profile/component/reset-login-password';
import ActivateCardComponet from '../../../employee/pages/activate-card.component';
import CardStatusType from '../../../constants/card-status.type';
class SubAssignedCards extends React.Component {

    constructor() {
        super();

        this.state = {
            employee: [],
            cardInformation: [],
            cardNumber: '',
            loadingMess: false,
            show: ""

        };
    }


    componentDidMount = () => {

        if (this.props.post.employeeDetail !== undefined && this.props.post.employeeDetail !== null && this.props.post.employeeDetail.card !== undefined) {

            var count = 0;
            this.props.post.employeeDetail.card.map(cd => {

                console.log("(parseInt(cd.status)===parseInt(CardStatus.PERMANENT_BLOCK )|| parseInt(cd.status)===parseInt(CardStatus.TEMPORARY_BLOCK)", parseInt(cd.status), "pb", parseInt(CardStatus.PERMANENT_BLOCK), "ststus", parseInt(cd.status), "tb", parseInt(CardStatus.TEMPORARY_BLOCK))
                // if (count === 0 && (parseInt(cd.cardStatus) === parseInt(CardStatus.PERMANENT_BLOCK) || parseInt(cd.cardStatus) === parseInt(CardStatus.TEMPORARY_BLOCK))) {
                this.setState({ cardNumber: cd.cardReferenceNo })
                // setCardNumber(cd.cardReferenceNo);
                this.func("", this.props.post.employeeDetail.mdn, cd.cardReferenceNo);
                count++;
                // }
            })
        }


    }

    func = async (event, mdn, carN) => {
        this.setState({ loadingMess: true })
        // setLoadingMess(true);
        var url = `${process.env.REACT_APP_CORPORATE_CARDSERVICE_GETCARDINFORMATION}?cardRefNumber=${carN}&mdn=${mdn}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("data for REACT_APP_CORPORATE_CARDSERVICE_GETCARDINFORMATION", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.cardEmployeeDetail !== null) {
            this.setState({ cardNumber: response.cardEmployeeDetail.cardRefNumber })
            // setCardNumber(response.cardEmployeeDetail.cardRefNumber);
            this.setState({ cardInformation: response.cardEmployeeDetail })
            this.setState({ loadingMess: false })
            // setCardInformation(response.cardEmployeeDetail)
            // setLoadingMess(false);
        } else {
            this.setState({ cardNumber: carN })
            this.setState({ cardInformation: [] })
            this.setState({ loadingMess: false })
            // setCardNumber(carN);
            // setCardInformation([])
            // setLoadingMess(false);
        }

    }



    setEmployeeDetail = () => {
        store.dispatch(setBackUrl("/assigned-cards"));
        store.dispatch(setEmployeeDetails(this.props.post.employeeDetail));
        // setEmployee(employee);
    }



    render() {


        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>
                                <div className="col-12 col-sm-6 col-md-4 col-xl-3 ">
                                    <div className="usercard">

                                        <div className="usercheck">
                                            {
                                                this.props.iscalledfromassigncard ? "" :
                                            
                                            <input type="checkbox" id={this.props.post.id} className="form-check-input" checked={this.props.post.isChecked} onClick={this.props.handleChecked} />
                                            }

                                        </div>


                                        <Link
                                            to={{
                                                pathname: '/emp-detail',
                                                state: { employee: this.props.post !== undefined && this.props.post.employeeDetail, editShowHidden: true }
                                            }}
                                            onClick={e => this.setEmployeeDetail(this.props.post !== undefined && this.props.post.employeeDetail)}
                                            className="userbtn"
                                        >
                                            <div className="userbasic">
                                                <Avatar src={this.props.post.employeeDetail.imageurl} color={Avatar.getRandomColor(this.props.post.mdn, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={this.props.post.mdn} name={this.props.post.employeeName} size="48px" round={true} />

                                                <h5 className="text-truncate">{this.props.post.employeeName}</h5>
                                                <p>+91-{this.props.post.mdn}</p>
                                            </div>
                                        </Link>

                                        {
                                            this.props.post.employeeDetail.kyc_status === KycStatus.AADHAR_BASED
                                                ?
                                                <>
                                                    <ReactTooltip
                                                        id="kyc"
                                                        place="top"
                                                        type='dark'
                                                        effect='solid'
                                                        // multiline={true}
                                                        backgroundColor="black"
                                                    />
                                                    <div className="kyc adharkyc" data-for="kyc" data-tip="Aadhar Kyc" data-iscapture="true"></div>
                                                </>
                                                :

                                                this.props.post.employeeDetail.kyc_status === KycStatus.MIN_KYC
                                                    ?
                                                    <>
                                                        <ReactTooltip
                                                            id="kyc"
                                                            place="top"
                                                            type='dark'
                                                            effect='solid'
                                                            // multiline={true}
                                                            backgroundColor="black"
                                                        />
                                                        <div className="kyc minkyc" data-for="kyc" data-tip="Min Kyc" data-iscapture="true"></div>
                                                    </>
                                                    :
                                                    this.props.post.employeeDetail.kyc_status === KycStatus.KYC_DONE
                                                        ?
                                                        <>
                                                            <ReactTooltip
                                                                id="kyc"
                                                                place="top"
                                                                type='dark'
                                                                effect='solid'
                                                                // multiline={true}
                                                                backgroundColor="black"
                                                            />
                                                            <div className="kyc fullkyc" data-for="kyc" data-tip="Full Kyc" data-iscapture="true"></div>
                                                        </>
                                                        :
                                                        <>
                                                            <ReactTooltip
                                                                id="kyc"
                                                                place="top"
                                                                type='dark'
                                                                effect='solid'
                                                                // multiline={true}
                                                                backgroundColor="black"
                                                            />
                                                            <div className="kyc nonekyc" data-for="kyc" data-tip="No Kyc" data-iscapture="true"></div>
                                                        </>

                                        }



                                        <div className="cardsts" >
                                            <MultiCardStatus changeReason={this.func} selectedCard={this.state.cardNumber} card={this.props.post.employeeDetail !== undefined && this.props.post.employeeDetail !== null ? this.props.post.employeeDetail.card : []} />
                                        </div>

                                        <div  className="blockview">
                                            <div  className="blockref">
                                                <h5><span>Ref. No.</span> {this.state.cardNumber}</h5>
                                            </div>
                                            <div  className="asgndby">
                                                <p  className="date">{this.state.cardInformation.dateCreatedOn}</p>
                                                <p>Assigned By: </p>
                                                <h6>{this.state.cardInformation.createdBy}</h6>

                                            </div>

                                            <div  className="cardbtn">

                                                {
                                                    this.props.show
                                                        ?
                                                        null
                                                        :

                                                        this.props.post.employeeDetail.card.map(cards => (
                                                            <>

                                                                {(parseInt(cards.cardStatus) === CardStatusType.ASSIGNED) && cards.cardReferenceNo === this.state.cardNumber &&

                                                                    <ActivateCardComponet cards={cards} employeeDetail={this.props.post.employeeDetail} classActivate="sbtn btnbl" classUnAssign="sbtn btnrd" />}</>
                                                        ))

                                                }

                                                {
                                                    this.props.show
                                                        ?
                                                        null
                                                        :


                                                        this.props.post.employeeDetail !== null && parseInt(this.props.post.employeeDetail.kyc_status) === parseInt(KycStatus.KYC_DONE) ?
                                                            null
                                                            :

                                                            <Link
                                                                to={{
                                                                    pathname: '/user-kyc',
                                                                    state: { post: this.props.post, employee: this.props.post.employeeDetail, from: "/assigned-cards" }
                                                                }}
                                                                 className="sbtn btngn"
                                                            >
                                                                {this.props.post.employeeDetail !== null && (parseInt(this.props.post.employeeDetail.kyc_status) === parseInt(KycStatus.MIN_KYC) || parseInt(this.props.post.employeeDetail.kyc_status) === parseInt(KycStatus.AADHAR_BASED))
                                                                    ?
                                                                    <>
                                                                        {process.env.REACT_APP_UPGRADE_KYC_VARIABLE}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {process.env.REACT_APP_DO_KYC_VARIABLE}

                                                                        {/* Do Kyc */}
                                                                    </>
                                                                }

                                                            </Link>


                                                }
                                            </div>


                                        </div>






                                    </div>
                                </div>

                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset

});

export default connect(mapStateToProps)(SubAssignedCards);