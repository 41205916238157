import React, { useEffect } from "react";
import image1 from "../../assests/no-image.png";
import DropDownIcon from "../component/dropdown-icon.component";
import DropDown from "./dropdown.component";
import SmallPopUp from "../../common/component/small.popup.component";
import MultiCardSmallPopUp from "../../common/component/multicard.smallPopup.component copy";
import CardManagementService from '../../service/service-impl/CardManagementService'
import '../css/employee.css'
import { Link } from 'react-router-dom';
import MultiCardStatus from '../../common/component/multiCardStatus.component';
import { toggleDropDownHidden } from '../../redux/dropdown/dropdown.action';
import { store } from '../../redux/store';
import { setMessageToBeShown, setSeverty } from '../../redux/message/message.action';
import CommonFunction from '../../common-function/GenerateRequestId';
import ApiCalling from '../../service/api-call-service';
import history from '../../auth/history';
import { connect } from "react-redux";
import { setEmployeeDetails } from "../../redux/user/user.actions";
import { setBackUrl } from "../../redux/user/user.actions";
import Avatar from 'react-avatar';
import CardStatusTypes from '../../constants/card-status.type';


var imgArray = [];
var ro_id = '';
var currentUserT = '';
var fullNameV = ''

const Card = ({ key, employee, roMdn, currentUser, handleChecked, bulKButtonShow, huburgVar, fullName }) => {

  ro_id = roMdn
  currentUserT = currentUser
  fullNameV = fullName


  const setEmployee = () => {
    store.dispatch(setEmployeeDetails(employee));
    store.dispatch(setBackUrl("/emp-list"))
  }
  var imageurl = employee.imageUrl




  console.log('employee checkbox', employee.isChecked)
  return (
    <div className="col-12 col-sm-6 col-xl-4">
      <div className="usercard">
        {/* <a href={`/emp-detail?email=${employee.email}`} className="userbtn"> */}

        {
          bulKButtonShow
            ?
            <>

              <div className="usercheck">
                <input type="checkbox" name="emplCheckbox" className="form-check-input" />
              </div>
              <a className="userbtn">
                <div className="userbasic">
                  <img src={imageurl} />
                  <h5  className="text-truncate">{CommonFunction.Capitalize(employee.first_name)} {CommonFunction.Capitalize(employee.last_name)}</h5>
                  <p>
                    {`+91-${employee.mdn}`}
                  </p>
                </div>

              </a>

              <MultiCardStatus card={employee.card} />




            </>
            :
            <>


              <div className="usercheck">
                <input type="checkbox" className="form-check-input" name="emplCheckbox" id={employee.emp_id} onChange={handleChecked} />
              </div>
              <Link
                to={{
                  pathname: '/emp-detail',
                  state: { employee, editShowHidden: true }
                }}
                className="userbtn"
                onClick={setEmployee}
              >
                <div className="userbasic">
                  {/* <img src={imageurl} /> */}
                  <Avatar src={imageurl} color={Avatar.getRandomColor(employee.emp_id, ["#ba000d", "#003c8f", "#087f23", "#c43e00", "#ad1457", "#6a0080", "#6a1B9a", "#5e35b1", "#303f9f", "#0d47a1", "#4b830d", "#00695c", "#2e7d32", "#006db3", "#003c8f", "#9e00c5"])} value={employee.emp_id} name={`${CommonFunction.Capitalize(employee.first_name)} ${CommonFunction.Capitalize(employee.last_name)}`} size="42px" round={true} />

                  <h5  className="text-truncate">{CommonFunction.Capitalize(employee.first_name)} {CommonFunction.Capitalize(employee.last_name)}</h5>
                  <p>
                    {`+91-${employee.mdn}`}
                  </p>
                </div>


              </Link>

              <div className="cardsts">

                <MultiCardStatus card={employee.card} />

                <p>{CommonFunction.Capitalize(employee.roleName)}</p>
              </div>
            </>
        }

        {/* } */}
        <div className="usermore dropdown">
          {
            huburgVar &&
            <DropDownIcon id={employee.mdn} bulKButtonShow={bulKButtonShow}></DropDownIcon>
          }
          <DropDown
            id={employee.mdn}
            targettempPopupId={`#popup-temp${employee.mdn}`}
            targetpermPopupId={`#popup-perm${employee.mdn}`}
            targetRemovePopUpId={`#popup-remove${employee.mdn}`}
            targetUnBlockPopupId={`#popup-unBLock${employee.mdn}`}
            employee={employee}
            setEmployee={setEmployee}
          />

          <MultiCardSmallPopUp
            targetid={`popup-temp${employee.mdn}`}
            headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_PopUp_Lable}
            bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_PopUp_Body}
            onclickFunctionName={handleTempBlock}
            firstName={employee.first_name}
            allValues={employee}
            cardDetails={employee.card}
            buttonActionName='Block'
            buttonActionClose="Cancel"
          ></MultiCardSmallPopUp>

          <MultiCardSmallPopUp
            targetid={`popup-unBLock${employee.mdn}`}
            headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Lable}
            bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Body}
            onclickFunctionName={handleCardUnLock}
            firstName={employee.first_name}
            cardDetails={employee.card}
            allValues={employee}
            buttonActionName={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_UNLOCK_Button}
            buttonActionClose={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Cancel_Button}
          ></MultiCardSmallPopUp>
          <MultiCardSmallPopUp
            targetid={`popup-perm${employee.mdn}`}
            headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Lable}
            bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Body}
            onclickFunctionName={handlePermanentBlock}
            firstName={employee.first_name}
            cardDetails={employee.card}
            allValues={employee}
            buttonActionName='Block'
            buttonActionClose="Cancel"
          ></MultiCardSmallPopUp>
          <SmallPopUp
            targetid={`popup-remove${employee.mdn}`}
            headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Lable}
            bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Body}
            onclickFunctionName={handleRemoveEmployee}
            firstName={employee.first_name}
            allValues={employee}
            buttonActionName={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Remove_Button}
            buttonActionClose={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_Delete_PopUp_Cancel_Button}
          >
          </SmallPopUp>
        </div>
      </div>
    </div>
  )
};


const handleTempBlock = (event, data, cardDetails, reason) => {

  console.log("clicked ", data);

  console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
  // updateCardStatus(url, mdn, useraname, otp, reason, status,header,cardReferenceNum,updateType,updatedBy ) {

  const response = CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name,
    "", reason.reason, "3", "", cardDetails.cardReferenceNo, "temp", fullNameV, ro_id, '',
    process.env.REACT_APP_SUCESS_MESSAGE_FOR_TEMPORARY_BLOCK, currentUserT);


}
const handlePermanentBlock = (event, data, cardDetails, reason) => {
  console.log("clicked handlePermanentBlock", data);

  console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
  CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "",
    reason.reason, "4", "", cardDetails.cardReferenceNo, "permanent", fullNameV, ro_id, '',
    process.env.REACT_APP_SUCESS_MESSAGE_FOR_PERMANENT_BLOCK, currentUserT);

  console.log("clicked ", data);

}

const handleCardUnLock = (event, data, cardDetails) => {
  console.log("clicked handlePermanentBlock", data);
  //store.dispatch(toggleDropDownHidden());

  //store.dispatch(setMessageToBeShown('SuccessFull Permanent Block.'));
  //url, mdn, useraname, otp, reason, status
  console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
  // updateCardStatus(url, mdn, useraname, otp, reason, status,header,cardReferenceNum,updateType,updatedBy ) {

  CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "",
    "Unlock Card", "0", "", cardDetails.cardReferenceNo, 'unBlock', fullNameV, ro_id, '',
    process.env.REACT_APP_SUCESS_MESSAGE_FOR_UNLOCK_CARD, currentUserT);

  console.log("clicked ", data);
}




const handleRemoveEmployee = async (event, data) => {
  console.log("clicked handleRemoveEmployee", data.email, "and ROID", currentUserT, " : ", data.email === currentUserT)

  if (data.email === currentUserT) {
    store.dispatch(toggleDropDownHidden(true));
    store.dispatch(setMessageToBeShown('You Are Currently Logged In'));
    store.dispatch(setSeverty('warning'))
  }

  else {
    const RequestToSend = {
      "request": {
        "employee": {
          "corp_id": ro_id,
          "emp_id": data.emp_id,
          "mdn": data.mdn,
          "status": "1",
          "card_staus": data.card_staus,
          "cardReferenceNumber": data.card_ref_no,
          "modify_by": currentUserT
        }
      }
    }
    console.log(RequestToSend);
    const url = process.env.REACT_APP_UPDATE_EMPLOYEE;




    const response = await ApiCalling.postMethodCall(url, RequestToSend);

    console.log('Update EMployee Api response yes', RequestToSend, response);
    if (response.status === 'failure') {
      store.dispatch(setSeverty('error'))
      store.dispatch(toggleDropDownHidden(true));
      store.dispatch(setMessageToBeShown(response.response_description));
    } else if (response.response.response_message === 'success') {
      console.log('inside success')
      store.dispatch(setSeverty('success'))
      store.dispatch(toggleDropDownHidden(true));
      store.dispatch(setMessageToBeShown('Employee removed Successfully.'));
      history.push('/emp-list');
      history.go();
    }
  }

}

const mapStateToProps = ({ user: { roMdn, currentUser, fullName }, role: { huburgVar } }) => ({
  roMdn, currentUser, huburgVar, fullName
});

export default connect(mapStateToProps)(Card);
