import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';


export default function Animations() {

    return (
        <>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <a href="giftcards-beneficiary">
                    <div className="giftcardata">
                        <div className="issuedto">
                            <img className="avatar" data-name="Ramesh Swamy" alt="" />
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                        <div className="gcardqty">
                            <p>Cards Qty.</p>
                            <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        </div>
                        <div className="gcardamt">
                            <p>Card Amount</p>
                            <h6 ><Skeleton className="loading-basic-p" variant="text" animation="wave" style={{ float: "right" }} /></h6>
                        </div>
                        <div className="issuedate"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></div>
                    </div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <a href="giftcards-beneficiary">
                    <div className="giftcardata">
                        <div className="issuedto">
                            <img className="avatar" data-name="Ramesh Swamy" alt="" />
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                        <div className="gcardqty">
                            <p>Cards Qty.</p>
                            <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        </div>
                        <div className="gcardamt">
                            <p>Card Amount</p>
                            <h6 ><Skeleton className="loading-basic-p" variant="text" animation="wave" style={{ float: "right" }} /></h6>
                        </div>
                        <div className="issuedate"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></div>
                    </div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <a href="giftcards-beneficiary">
                    <div className="giftcardata">
                        <div className="issuedto">
                            <img className="avatar" data-name="Ramesh Swamy" alt="" />
                            <h5><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h5>
                            <p><Skeleton className="loading-basic-p" variant="text" animation="wave" /></p>
                        </div>
                        <div className="gcardqty">
                            <p>Cards Qty.</p>
                            <h6><Skeleton className="loading-basic-p" variant="text" animation="wave" /></h6>
                        </div>
                        <div className="gcardamt">
                            <p>Card Amount</p>
                            <h6 ><Skeleton className="loading-basic-p" variant="text" animation="wave" style={{ float: "right" }} /></h6>
                        </div>
                        <div className="issuedate"><Skeleton className="loading-basic-p" variant="text" animation="wave" /></div>
                    </div>
                </a>
            </div>
        </>

    );
}