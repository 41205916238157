import React from "react";
import "../../employee/css/employee.css";
import CardStatusTypes from '../../constants/card-status.type';
import ReactTooltip from "react-tooltip";

const CardStatus = ({ card, changeReason, selectedCard }) => (

    card !== undefined && card !== null && card.lenght !== 0 && card.map(cardDetail => (
        <>
            {
                cardDetail.cardType === 1
                    ?
                    <>
                        <ReactTooltip
                            id="backClose"
                            place="top"
                            type='dark'
                            effect='solid'
                            // multiline={true}
                            backgroundColor="black"
                        />
                        {CardStatusTypes.ACTIVE === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "phyl active selected" : "phyl active"} data-for="backClose" data-tip="Physical Card" data-iscapture="true" onClick={changeReason !== undefined ? e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo) : null}></i>}
                        {CardStatusTypes.PERMANENT_BLOCK === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "phyl blocked selected" : "phyl blocked"} data-for="backClose" data-tip="Physical Card" data-iscapture="true" onClick={changeReason !== undefined ? e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo) : null} ></i>}
                        {CardStatusTypes.REPLACED === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "phyl blocked selected" : "phyl blocked"} data-for="backClose" data-tip="Physical Card" data-iscapture="true" onClick={changeReason !== undefined ? e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo) : null} ></i>}
                        {CardStatusTypes.TEMPORARY_BLOCK === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "phyl temp selected" : "phyl temp "} data-for="backClose" data-tip="Physical Card" data-iscapture="true" onClick={changeReason !== undefined ? e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo) : null}></i>}
                        {CardStatusTypes.NOT_ASSIGNED === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "nocard selected" : "nocard "} data-for="backClose" data-tip="Card Not Assigned" data-iscapture="true" onClick={changeReason !== undefined ? e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo) : null}></i>}
                        {CardStatusTypes.ASSIGNED === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "phyl assigned selected" : "phyl assigned"} data-for="backClose" data-tip="Physical Card" data-iscapture="true" onClick={changeReason !== undefined ? e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo) : null}></i>}
                    </>
                    :
                    <>
                        <ReactTooltip
                            id="backClose"
                            place="top"
                            type='dark'
                            effect='solid'
                            // multiline={true}
                            backgroundColor="black"
                        />
                        {CardStatusTypes.ACTIVE === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "vrtl active selected" : "vrtl active"} data-for="backClose" data-tip="Virtual Card" data-iscapture="true" onClick={e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo)}></i>}
                        {CardStatusTypes.PERMANENT_BLOCK === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "vrtl blocked selected" : "vrtl blocked"} data-for="backClose" data-tip="Virtual Card" data-iscapture="true" onClick={e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo)}></i>}
                        {CardStatusTypes.REPLACED === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "vrtl blocked selected" : "vrtl blocked"} data-for="backClose" data-tip="Virtual Card" data-iscapture="true" onClick={e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo)}></i>}
                        {parseInt(CardStatusTypes.TEMPORARY_BLOCK) === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "vrtl temp selected" : "vrtl temp"} data-for="backClose" data-tip="Virtual Card" data-iscapture="true" onClick={e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo)}></i>}
                        {parseInt(CardStatusTypes.NOT_ASSIGNED) === parseInt(cardDetail.cardStatus) && <i className={selectedCard === cardDetail.cardReferenceNo ? "nocard selected" : "nocard"} data-for="backClose" data-tip="Not Assigned" data-iscapture="true" onClick={e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo)}></i>}
                        {CardStatusTypes.ASSIGNED === cardDetail.cardStatus && <i className={selectedCard === cardDetail.cardReferenceNo ? "vrtl assigned selected" : "vrtl assigned"} data-for="backClose" data-tip="Virtual Card" data-iscapture="true" onClick={e => changeReason(e, cardDetail.mdn, cardDetail.cardReferenceNo)}></i>}

                    </>

            }

        </>
    ))


);



export default CardStatus;
