import React from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import moment from 'moment';



class DisburseListFilterComponent extends React.Component {
    constructor(props) {
        super();
        this.state = {
            multiValueDisburseType: [
                { value: "1", label: "Single" },
                { value: "2", label: "Bulk" }
            ],
            multiValueRecords: [
                { value: "1", label: "Success" },
                { value: "2", label: "Failed" }
            ],
            multiValueStatus: [
                { value: "Approved By", label: "Approved" },
                { value: "Partial Aproved By", label: "Partial Approved" },
                { value: "Pending From", label: "Pending" },
                { value: "Rejected By", label: "Rejected" },
            ],
            placeHolder: '...',
            isChecked: ''
        };

    }




    handleRemoveRoleChecked = () => {

    }

    handleChecked = () => {

    }


    render() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '5px',
                'width': '100%',
                'minHeight': '30px',
                'border': '1px solid #ddd',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })

        };



        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.props.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            var startdateFromDate = moment(this.props.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.props.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        var dateFromMax = moment(this.props.toDate);
        dateFromMax = dateFromMax.format("YYYY-MM-DD");

        return (
            <div className="head">
                <div className="row">

                    <div  className="col-10">
                        <h3>Load Cards</h3>
                    </div>

                    <div  className="col-2">
                        <div  className="flterbox">
                            <div className="dropdown float-right" data-toggle="tooltip" title="Filter">
                                <button className="fltrbtn dropdown-toggle" type="button" id="ftlrmenu" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false"></button>

                                <div className="dropdown-menu dropdown-menu-end">


                                    <div className="filters">
                                        <label htmlFor="select-state">Disbursal Type:</label>
                                        <Select

                                            name="multiTypeValue"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiValueType}
                                            options={this.state.multiValueDisburseType}
                                            onChange={this.props.handleMultiTypechange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />

                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">Records:</label>
                                        <Select

                                            name="multiTypeValue"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiValueRecord}
                                            options={this.state.multiValueRecords}
                                            onChange={this.props.handleMultiRecordchange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />

                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">Disbursal Status:</label>
                                        <Select

                                            name="multiTypeValue"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiValueStatus}
                                            options={this.state.multiValueStatus}
                                            onChange={this.props.handleMultiStatuschange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />

                                    </div>


                                    <div className="filters">
                                        <label htmlFor="select-state">From Date:</label>
                                        <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.props.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.props.handleDateChange} />
                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">To Date:</label>
                                        <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.props.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.props.handleDateChange} />
                                    </div>

                                    <div className="fbtn">
                                        <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.props.clearAll}>Clear all</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

}


const mapStateToProps = ({ band: { bandData }, location: { locationData }, kyc: { kycData }, kyc: { masterRoleData }, kyc: { cardStatusData } }) => ({
    bandData,
    locationData,
    cardStatusData,
    kycData,
    masterRoleData
});


export default connect(mapStateToProps)(DisburseListFilterComponent);