import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import corpImg from '../../assests/logo.png'
import otpImg from '../../assests/otp-img.png'
import history from '../../auth/history';
import { sha256 } from 'js-sha256';
import apiCallService from '../../service/api-call-service';
import '../css/corpoeate-profile.styles.css'



class ResetLoginPassword extends React.Component {

    constructor() {
        super();

        this.state = {
            currentPassword: '',
            newPassword: '',
            cnfrmPassword: '',
            isValid: true,
            dropDownHidden: false,
            message: "",
            severty: "success"

        };
    }

    generateHash = (messageBody) => {
        //var msg= JSON.stringify(messageBody) ;
        var privateKey = '1234:aabbccdd';
        var hash = sha256.hmac.create(privateKey);
        hash.update(messageBody);
        var hexData = hash.hex();
        console.log('hexed data', hexData);
        var base64String = btoa(hexData);
        console.log(messageBody, '---->passowrd hash Generated --->', base64String);
        return base64String.replace(/[^a-zA-Z0-9]/g, "");
    }


    formSubmit = async (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.cnfrmPassword) {
            this.setState({ isValid: false })
        } else {

            var url = process.env.REACT_APP_RESET_OR_UPDATE_LOGIN_PASSWORD;

            var request = {
                "email": this.props.currentUser,
                "password": this.generateHash(this.state.cnfrmPassword),
                "oldPassword": this.generateHash(this.state.currentPassword),
                "updateOrReset": "update",
                "deviceInfo":
                {
                    "device_os": "11",
                    "device_os_version": "23",
                    "device_id": "12",
                    "imei": "1234",
                    "latitude": "2345",
                    "longitude": "23456",
                    "ipaddress": "2344",
                    "channel": "1",
                    "browserName": "fgg",
                    "deviceType": "ert",
                    "model_name": "hhjj",
                    "time_stamp": "12-12-122",
                    "locationCountry": "india",
                    "locationState": "bihar"

                }
            }

            var response = await apiCallService.postMethodCall(url, request);

            console.log('response formSubmit', url, request, response);

            if (response !== undefined && response.response_code === 0 && response.response_message !== undefined) {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: "success" })
                this.setState({ message: response.response_message })
                history.push('/logout')
                history.go();
            } else if (response !== undefined && response.response_message !== undefined) {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: "error" })
                this.setState({ message: response.response_message })
            }
            else {
                this.setState({ dropDownHidden: true })
                this.setState({ severty: "error" })
                this.setState({ message: response.response_description })
            }

        }



    }




    render() {

        console.log("States here", this.state);
        var zeroOne = this.props.mdn.substring(0, 1);
        var sevenNine = this.props.mdn.substring(8, 10);
        return (< >
            {(this.props.authenticated)
                ? (

                    <div className="mid-container">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="head">
                                        <div className="head-btn">
                                            <a href="/reset-login-password" className="logpass">Reset Login Password</a>
                                            <Link to={{
                                                pathname: "/corporate-profile",
                                                state: { txnSection: true }
                                            }} className="txnpass">Reset TXN Password</Link>
                                            <a href="/band-list" className="band">Manage Band</a>
                                        </div>
                                        <h3>Reset Login Password</h3>
                                    </div>
                                </div>
                                <Snackbar

                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={this.state.dropDownHidden}
                                    autoHideDuration={20000}
                                    onClose={e => this.setState({ dropDownHidden: false })}
                                >
                                    <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                        {this.state.message}
                                    </Alert>
                                </Snackbar>

                                <div className="col-12">
                                    <div className="log-pass-reset">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <form onSubmit={this.formSubmit} autoComplete="off">
                                                    <div className="row">

                                                        <div className="col-12 col-lg-4">
                                                            <div className="webform">
                                                                <div className="form-floating">
                                                                    <input className="form-control" placeholder="Password" type="password" spellCheck="false" id="password" style={{ fontFamily: 'text-security-disc' }} value={this.state.currentPassword} onChange={e => { this.setState({ currentPassword: e.target.value }) }} autoComplete="off" required />
                                                                    <label for="password">Current Password</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-lg-4">
                                                            <div className="webform">
                                                                <div className="form-floating">
                                                                    <input className="form-control" placeholder="Password" type="password" spellCheck="false" id="password" autoComplete="off" style={{ fontFamily: 'text-security-disc' }} value={this.state.newPassword} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" onChange={e => { this.setState({ newPassword: e.target.value, isValid: true }) }} required />
                                                                    <label for="password">New Password</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-lg-4">
                                                            <div className="webform">
                                                                <div className="form-floating">
                                                                    <input className="form-control" placeholder="Password" type="password" spellCheck="false" id="password" autoComplete="off" style={{ fontFamily: 'text-security-disc' }} value={this.state.cnfrmPassword} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" onChange={e => { this.setState({ cnfrmPassword: e.target.value, isValid: true }) }} required />
                                                                    <label for="password">Confirm New Password</label>
                                                                </div>
                                                            </div>
                                                            {this.state.isValid
                                                                ?
                                                                null
                                                                :

                                                                <p style={{ color: "#f46a6a", marginTop: "-16px" }}> {process.env.REACT_APP_VAR_FOR_Warning_for_password_not_match}</p>
                                                            }
                                                        </div>



                                                        <div className="col-12">
                                                            {/* <button className="mbtn btngr mt-3" id="savecorpinfo">Cancel</button> */}
                                                            <button className="mbtn btnbl mt-3">Submit</button>
                                                        </div>

                                                        <div className="col-12 mt-4">
                                                            <div className="passtype">
                                                                <p>Password should be of 8 characters with atleast:</p>
                                                                <ul>
                                                                    <li>1 UPPER Case (A-Z)</li>
                                                                    <li>1 lower case (a-z)</li>
                                                                    <li>1 numeric character (0-9)</li>
                                                                    <li>1 special character (!, @, #, $, %, ?, &amp;)</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4"><img src={otpImg} alt="OTP" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >


                )
                : (
                    <Redirect to='/' />
                )


            }

        </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn, mdn, currentUser }, role: { roleData },

}) => ({
    authenticated, userRole, roleData, roMdn, mdn, currentUser

});

export default connect(mapStateToProps)(ResetLoginPassword); 