import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { getStoredState } from 'redux-persist';
import { setShowCardDetailSection, showAvailableCardSection, showReviewOderSection } from '../../../redux/div-show-and-hide/div-show-and-hide.action';
import {
    resetOder, setAddressOne, setAddressTwo, setCardQuantity, setCity, setFullNameOder,
    setMobileNumber, setSameAddressCheckbox, setState, setPinCode, setShippingAddress, setBillingAddress,
    setShippingPersonName, setShippingPersonMDN, setOrderBack, setDistrictShip, setDistrictBilling, setCardType
} from '../../../redux/order/order.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import CreatableSelect from 'react-select/creatable';


// REACT_APP_OEMS_GET_ALL_STATES=http://3.6.104.48:7072/getStates
// REACT_APP_OEMS_GET_ALL_CITIES=http://3.6.104.48:7072/getCities
// REACT_APP_OEMS_GET_ALL_DISTRICT=http://3.6.104.48:7072/getDistrict


const CreateCardOder = ({ authenticated, roMdn, roleData, userRole, shippingPersonName, shippingPersonMdn, back,
    cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox,
    shippingAddress, billingAddress, disrtrictShip, districtBilling, cardType }) => {


    const [nameShip, setNameShip] = useState('')
    const [mobileNumShip, setMobileNumShip] = useState('')
    const [address1Ship, setAddress1Ship] = useState('')
    const [address2Ship, setAddress2Ship] = useState('')
    const [stateShip, setStateShip] = useState('')
    const [cityShip, setCityShip] = useState('')
    const [pincodeShip, setPincodeShip] = useState('')
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([]);
    const [cityShipList, setCityShipList] = useState([]);
    const [toCallRender, setToCallRender] = useState(true);


    const [districtList, setDistrictList] = useState([]);
    const [districtShipList, setDistrictShipList] = useState([]);

    const [physicalCardQuantity, setPhysicalCardQuantity] = useState('')
    const [virtualCardQuantity, setVirtualCardQuantity] = useState('')

    useEffect(() => {



        if (shippingAddress !== '' && back) {
            var arrCity = [];
            var shipping = shippingAddress.split(',')
            var shipD = shipping[3].split('-');
            var cityyy = {
                "cityId": 148,
                "cityName": shipping[2],
                "districtId": 19,
                "stateId": 2
            }
            var cityyy2 = {
                "cityId": 148,
                "cityName": city,
                "districtId": 19,
                "stateId": 2
            }
            arrCity.push(cityyy2)
            arrCity.push(cityyy)

            // store.dispatch(setCity(shipping[2]))
            setNameShip(shippingPersonName)
            setMobileNumShip(shippingPersonMdn);
            setAddress1Ship(shipping[0]);
            setAddress2Ship(shipping[1]);
            setPincodeShip(parseInt(shipD[1]));
            setCityShip(shipping[2]);
            setStateShip(shipD[0].trim());





            console.log('shipD', shipping, shipping[2], arrCity)
            store.dispatch(setOrderBack(false));

            var arrDist = []

            var districBillTOSet = { distId: 24, stateId: 3, distName: districtBilling, value: districtBilling, label: districtBilling }
            var districShipTOSet = { distId: 24, stateId: 3, distName: disrtrictShip, value: disrtrictShip, label: disrtrictShip }
            arrDist.push(districBillTOSet)
            arrDist.push(districShipTOSet)


            setDistrictList(arrDist);
            setDistrictShipList(arrDist);
            setCityList(arrCity)
            setCityShipList(arrCity)

            if (cardType === 1) {
                setPhysicalCardQuantity(cardQuantity)
            } else {
                setVirtualCardQuantity(cardQuantity)
            }


        } else {
            store.dispatch(setCardType(1));
            fetchRo();
        }

        getStates();

    }, [])



    var myInput = document.getElementById("cardQuantity");
    if (myInput !== null && myInput !== undefined) {
        myInput.addEventListener("invalid", validate);
        myInput.addEventListener("keyup", validate);
    }


    function validate() {
        var val = parseFloat(this.value);
        var min = parseFloat(this.min);
        var max = parseFloat(this.max);


        console.log("val min max", val, min, max)

        if (val < min) {
            this.setCustomValidity(process.env.REACT_APP_MESSAGE_FOR_Please_enter_Atleast_ONE_Card_Quantity);
        } else if (val > max) {
            this.setCustomValidity(process.env.REACT_APP_MESSAGE_FOR_Please_enter_Card_Quantity_less_than + " " + max);
        } else {
            this.setCustomValidity("");
        }
    }

    const fetchRo = async () => {
        // store.dispatch(resetOder);
        store.dispatch(setCardQuantity(''))
        const url = process.env.REACT_APP_CORPINFOBYROID_URL;
        // console.log(url);
        console.log(roMdn)
        const res = await apiCallService.getMethodCallWithParam(url, { 'roId': roMdn });
        console.log('response inside AuthService', res);
        if (!(res === undefined) && !(res.response === undefined) && res.response !== undefined) {

            store.dispatch(setFullNameOder(res.response.contact_person))

            store.dispatch(setAddressOne(res.response.address1))
            store.dispatch(setAddressTwo(res.response.address2))
            store.dispatch(setCity(res.response.city))
            store.dispatch(setState(res.response.state))
            store.dispatch(setPinCode(res.response.pincode))
            store.dispatch(setDistrictBilling(res.response.district))
            store.dispatch(setMobileNumber(res.response.contact_number))




            var arrCity = [];


            var cityyy = {
                "cityId": 148,
                "cityName": res.response.city,
                "districtId": 19,
                "stateId": 2
            }

            arrCity.push(cityyy)
            setCityList(arrCity)


            var arrDist = []

            var districShipTOSet = { distId: 24, stateId: 3, distName: res.response.district, value: res.response.district, label: res.response.district }
            arrDist.push(districShipTOSet)

            setDistrictList(arrDist);
            console.log('this.state detail districtList', res, arrDist)

        }
    }






    const getStates = async () => {

        var url = process.env.REACT_APP_OEMS_GET_ALL_STATES;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("state response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("state response", response.statesList);

            var arr = [];

            response.statesList.map(state => {
                var value = "value";
                var label = "label";
                state[value] = state.stateName;
                state[label] = state.stateName;
            })

            setStateList(response.statesList);
        }
    }

    const getDistrict = async (id, type) => {

        var distListTOSet = [];
        console.log('get cities method call', id)
        var url = `${process.env.REACT_APP_OEMS_GET_ALL_DISTRICT}/${id}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("districtList response", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            console.log("districtList response", response.districtList);

            response.districtList.map(disstrict => {

                var value = "value";
                var label = "label";
                disstrict[value] = disstrict.distName;
                disstrict[label] = disstrict.distName;
                distListTOSet.push(disstrict);
                setToCallRender(false)

            })

            if (type === 'ship') {
                console.log('get cities set ship', id)
                setDistrictShipList(distListTOSet);
                setCityShipList([])
            } else {
                console.log('get cities set billing', id)
                setDistrictList(distListTOSet);
                setCityList([])
            }

        } else {
            if (type === 'ship') {
                console.log('get cities set ship', id)
                setDistrictShipList(distListTOSet);
                setCityShipList(distListTOSet)
            } else {
                console.log('get cities set billing', id)
                setDistrictList(distListTOSet);
                setCityList(distListTOSet)

            }
        }

    }

    const getCities = async (id, cityShipList) => {

        var cityListTOSet = [];
        console.log('get cities method call', id)


        var urlDis = `${process.env.REACT_APP_OEMS_GET_ALL_CITIES}/${id}`;

        var responsecityList = await apiCallService.getMethodCallWithoutParam(urlDis);
        console.log("cityList response", responsecityList);

        if (responsecityList !== undefined && responsecityList.response_code !== undefined && responsecityList.response_code === 0) {
            console.log("cityList response", responsecityList.cityList);

            responsecityList.cityList.map(city => {
                var value = "value";
                var label = "label";
                city[value] = city.cityName;
                city[label] = city.cityName;
                cityListTOSet.push(city);
                setToCallRender(false)

            })
            if (cityShipList === 'cityShipList') {
                setCityShipList(cityListTOSet)
            } else {

                setCityList(cityListTOSet);
            }
            console.log('setCityList(cityListTOSet);', cityList)
        } else {
            if (cityShipList === 'cityShipList') {
                setCityShipList(cityListTOSet)
            } else {

                setCityList(cityListTOSet);
            }
        }


    }


    const submitForm = (event) => {
        event.preventDefault();
        if (parseInt(cardType) === 1) {
            console.log("Inside physicalCardQuantity change", cardType, physicalCardQuantity)
            store.dispatch(setCardQuantity(physicalCardQuantity));
        }

        if (parseInt(cardType) === 0) {
            console.log("Inside virtualCardQuantity change", cardType, virtualCardQuantity)
            store.dispatch(setCardQuantity(virtualCardQuantity));
        }

        store.dispatch(setBillingAddress(`${address1}, ${address2}, ${city}, ${state} - ${pincode}`))
        console.log('shipD', sameAddressCheckbox)
        if (sameAddressCheckbox) {
            store.dispatch(setShippingAddress(`${address1}, ${address2}, ${city}, ${state} - ${pincode}`))

            store.dispatch(setShippingPersonName(fullNameOder));
            store.dispatch(setShippingPersonMDN(mobileNum));

            store.dispatch(setDistrictShip(event.target.value));
        } else {
            store.dispatch(setShippingAddress(`${address1Ship}, ${address2Ship}, ${cityShip}, ${stateShip} - ${pincodeShip}`))

            store.dispatch(setShippingPersonName(nameShip));
            store.dispatch(setShippingPersonMDN(mobileNumShip));
        }


        store.dispatch(showReviewOderSection());
    }

    const reset = () => {
        store.dispatch(resetOder)
        setNameShip('');
        setMobileNumShip('');
        setAddress2Ship('');
        setAddress1Ship('');
        setStateShip('');
        setCityShip('');
        setPincodeShip('');

    }

    const handleSameAddressChecked = (event) => {
        store.dispatch(setSameAddressCheckbox(event.target.checked))
    }
    const handlePhysicalCardQuantityChange = (event) => {


        console.log("Inside physical change")
        setPhysicalCardQuantity(event.target.value);
        setVirtualCardQuantity('');



    }

    const handleVirtualCardQuantityChange = (event) => {


        console.log("Inside virtual change")
        setVirtualCardQuantity(event.target.value);
        setPhysicalCardQuantity('');



    }





    const handleFullNameOderChange = (event) => {
        store.dispatch(setFullNameOder(event.target.value));

    }
    const handleMobileNumChange = (event) => {
        store.dispatch(setMobileNumber(event.target.value));

    }
    const handleAddressOneChange = (event) => {
        store.dispatch(setAddressOne(event.target.value));

    }
    const handleAddressTwoChange = (event) => {
        store.dispatch(setAddressTwo(event.target.value));

    }
    const handleStateChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        console.log('event.target', customAtrribute)


        store.dispatch(setState(event.target.value));

        // getCities(event.target.options.selectedIndex);
        getDistrict(customAtrribute, '');

    }
    const handleDistrictChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');

        store.dispatch(setDistrictBilling(event.target.value));


        console.log('event.target', customAtrribute)
        getCities(customAtrribute);

    }
    const handleSipDistrictChange = (event) => {
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        console.log('event.target', customAtrribute)

        store.dispatch(setDistrictShip(event.target.value));

        getCities(customAtrribute, 'cityShipList');

    }




    // const handleStateChange = (newValue, actionMeta) => {
    //     console.group('Value Changed handleChange');
    //     console.log('Value Changed handleChange newValue', newValue);
    //     console.log(`action handleChange: ${actionMeta.action}`);
    //     console.groupEnd();
    //     if (newValue !== null && newValue.value !== null) {

    //         store.dispatch(setState(newValue.value));
    //         getCities(newValue.stateId);
    //     }
    // };





    const setStateShipMethod = (event) => {
        setStateShip(event.target.value);
        // getCities(event.target.options.selectedIndex);
        var selectedIndex = event.target.options.selectedIndex;
        var customAtrribute = event.target.options[selectedIndex].getAttribute('keyId');
        console.log('event.target', customAtrribute)
        getDistrict(customAtrribute, 'ship')
    }

    // const setStateShipMethod = (newValue, actionMeta) => {
    //     console.group('Value Changed handleChange');
    //     console.log('Value Changed handleChange newValue', newValue);
    //     console.log(`action handleChange: ${actionMeta.action}`);
    //     console.groupEnd();
    //     if (newValue !== null && newValue.value !== null) {

    //         setStateShip(newValue.value);
    //         getCities(newValue.stateId);
    //     }
    // };



    const handleCityChange = (event) => {

        store.dispatch(setCity(event.target.value))

    }


    // const handleCityChange = (newValue, actionMeta) => {
    //     console.group('Value Changed handleChange');
    //     console.log('Value Changed handleChange newValue', newValue);
    //     console.log(`action handleChange: ${actionMeta.action}`);
    //     console.groupEnd();
    //     if (newValue !== null && newValue.value !== null) {

    //         store.dispatch(setCity(newValue.value));

    //     }
    // };



    const handlePincodeChange = (event) => {
        store.dispatch(setPinCode(event.target.value));

    }


    const validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const showAvailbleDiv = (e) => {
        store.dispatch(showAvailableCardSection());

        store.dispatch(setShowCardDetailSection(true))
    }


    // const showAvailbleDiv = (e) => {
    //     store.dispatch(showAvailableCardSection());

    //     store.dispatch(setShowCardDetailSection(true))
    // }

    const setPhysicalCard = (e) => {
        console.log("This method is calling")
        store.dispatch(setCardType(1));
    }
    const setVirtualCard = (e) => {
        store.dispatch(setCardType(0));
    }



    console.log('setCityList(cityListTOSet); render method', cityList)


    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'white',
            border: "none",
            borderBottom: "1px solid #e0e0e0",
            borderRadius: "0px",
            boxShadow: "none",
            "&:hover": {
                borderBottom: "2px solid #3f51b5",
                boxShadow: "none"
            },
            "&:focus": {
                borderBottom: "2px solid #3f51b5",
                boxShadow: "none"
            }
        }),

        valueContainer: styles => ({
            ...styles,
            padding: "0px 5px 0px 0px"
        }),


        menu: styles => ({
            ...styles,
            zIndex: "9999"
        }),


        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //   const color = chroma(data.color);
            return {
                ...styles,
            };
        },

    };


    console.log("this.state detail districtList", stateList, `++${stateShip}++`)

    return (<div >
        {(authenticated)
            ? (
                <div className="cardorderform">
                    <form onSubmit={submitForm}>
                        <div className="row">


                            <div  className="col-12">
                                <h3>{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_New_Card}
                               
                                    <a
                                        href={() => false}
                                        onClick={showAvailbleDiv}
                                        data-for="back" data-tip="Back" data-iscapture="true" className="back" 
                                    ></a>
                                    </h3>
                           
                            </div>

                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="checkform">
                                    <div  className="form-check">
                                        <input type="radio" id="pCard" className="form-check-input" name="pCard" value={cardType} checked={cardType === 1} onClick={setPhysicalCard} />
                                        <label  className="form-check-label" for="pCard">Physical Cards</label>
                                    </div>
                                </div>
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="number" placeholder="Card Qty" id="physical" max={process.env.REACT_APP_MAX_CARD_ORDER_ALLOWED} min="1" onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} value={physicalCardQuantity} onChange={handlePhysicalCardQuantityChange} disabled={cardType !== 1} required />
                                        <label for="physical">Physical Card Qty</label>
                                    </div>
                                </div>
                            </div>


                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="checkform">
                                    <div  className="form-check">
                                        <input type="radio" id="vCard" className="form-check-input" name="vCard" value={cardType} checked={cardType === 0} onClick={setVirtualCard} />
                                        <label  className="form-check-label" for="vCard">Virtual Cards</label>
                                    </div>
                                </div>
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="number" placeholder="Card Qty" id="virtual" max={process.env.REACT_APP_MAX_CARD_ORDER_ALLOWED} min="1" onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} value={virtualCardQuantity} onChange={handleVirtualCardQuantityChange} disabled={cardType != 0} required />
                                        <label for="virtual">Virtual Card Quantity</label>
                                    </div>
                                </div>
                            </div>



                            <div className="d-none d-xl-block col-xl-4"></div>




                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="text" pattern="[a-zA-Z ]{2,60}" placeholder="Name" id="fullNameOder" value={fullNameOder} onChange={handleFullNameOderChange} required />
                                        <label for="fullNameOder">Full Name</label>
                                    </div>
                                </div>
                            </div>


                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="number" id="orderMobileNum"
                                            maxLength="10"
                                            placeholder="Mobile"
                                            pattern="[0-9]{10}"
                                            onInput={validateInputLenght}
                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                            value={mobileNum} onChange={handleMobileNumChange} required />
                                        <label for="orderMobileNum">Mobile Number</label>
                                    </div>
                                </div>
                            </div>




                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="text" placeholder="address line 1" maxLength={100} id="address1" value={address1} onChange={handleAddressOneChange} required />
                                        <label for="address1">Address 1</label>
                                    </div>
                                </div>
                            </div>



                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="text" maxLength={100} placeholder="address line 2" id="address2" value={address2} onChange={handleAddressTwoChange} />
                                        <label for="address2">Address 2</label>
                                    </div>
                                </div>
                            </div>


                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <select  className="form-select" id="sg" aria-label="State" name="documentType3" value={state} onChange={handleStateChange} required>
                                            <option value="">Select</option>
                                            {stateList.map((documentType) =>
                                            (<option keyId={documentType.stateId} value={documentType.stateName}>
                                                {documentType.stateName}
                                            </option>))
                                            }
                                        </select>
                                        <label htmlFor="sg" >State</label>
                                    </div>
                                </div>
                            </div>



                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <select  className="form-select" id="sg" name="documentType3" value={districtBilling} onChange={handleDistrictChange} required>
                                            <option value="">select</option>
                                            {districtList.map((documentType) =>
                                            (<option keyId={documentType.distId} value={documentType.distName}>
                                                {documentType.distName}
                                            </option>))
                                            }
                                        </select>
                                        <label htmlFor="sg" >District</label>
                                    </div>
                                </div>
                            </div>






                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <select  className="form-select" id="sg" name="documentType4" value={city} onChange={handleCityChange} required>
                                            <option value="">select</option>
                                            {cityList.map((documentType) =>
                                            (<option keyId={documentType.cityId} value={documentType.cityName}>
                                                {documentType.cityName}
                                            </option>))
                                            }
                                        </select>
                                        <label htmlFor="sg" >City</label>
                                    </div>
                                </div>
                            </div>


                            <div  className="col-12 col-lg-6 col-xl-4">
                                <div  className="webform">
                                    <div  className="form-floating">
                                        <input className="form-control" type="number" id="pincode"
                                            maxLength='6'
                                            placeholder="Pincode"
                                            pattern="[0-9]{6}"
                                            onInput={validateInputLenght}
                                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                            value={pincode} onChange={handlePincodeChange} required />
                                        <label for="pincode">Pincode</label>
                                    </div>
                                </div>
                            </div>



                            {!sameAddressCheckbox
                                ?
                                <>
                                    <div className="col-12">
                                        <h3>Shipping Address</h3>
                                    </div>


                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <input className="form-control" type="text" id="nameShip" pattern="[a-zA-Z ]{2,60}" value={nameShip} onChange={eve => setNameShip(eve.target.value)} required />
                                                <label for="nameShip">Full Name</label>
                                            </div>
                                        </div>
                                    </div>




                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <input className="form-control" type="number" id="shipMobileNum"
                                                    maxLength="10"
                                                    pattern="[0-9]{10}"
                                                    onInput={validateInputLenght}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                    value={mobileNumShip} onChange={e => setMobileNumShip(e.target.value)} required />
                                                <label for="shipMobileNum">Mobile Number</label>
                                            </div>
                                        </div>
                                    </div>





                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <input className="form-control" type="text" maxLength="150" id="address1Ship" placeholder="address line 1" value={address1Ship} onChange={e => setAddress1Ship(e.target.value)} required />
                                                <label for="address1Ship">Address 1</label>
                                            </div>
                                        </div>
                                    </div>



                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <input className="form-control" type="text" maxLength="150" id="address2Ship" placeholder="address line 2" value={address2Ship} onChange={e => setAddress2Ship(e.target.value)} />
                                                <label for="address2Ship">Address 2</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <select  className="form-select" id="stateShip" name="documentType1" value={stateShip} onChange={setStateShipMethod} required>
                                                    <option value="">select</option>
                                                    {stateList.map((documentType) =>
                                                    (<option keyId={documentType.stateId} value={documentType.stateName} >
                                                        {documentType.stateName}
                                                    </option>))
                                                    }
                                                </select>
                                                <label htmlFor="sg" >State</label>
                                            </div>
                                        </div>
                                    </div>



                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <select  className="form-select" id="sg" name="documentType3" value={disrtrictShip} onChange={handleSipDistrictChange} required>
                                                    <option value="">select</option>
                                                    {districtShipList.map((documentType) =>
                                                    (<option keyId={documentType.distId} value={documentType.distName}>
                                                        {documentType.distName}
                                                    </option>))
                                                    }
                                                </select>
                                                <label htmlFor="sg" >District</label>
                                            </div>
                                        </div>
                                    </div>




                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <select  className="form-select" id="cityShip" name="documentType2" value={cityShip} onChange={e => setCityShip(e.target.value)} required >
                                                    <option value="">select</option>
                                                    {cityShipList.map((documentType) =>
                                                    (<option keyId={documentType.cityId} value={documentType.cityName}>
                                                        {documentType.cityName}
                                                    </option>))
                                                    }
                                                </select>
                                                <label htmlFor="cityShip" >City</label>
                                            </div>
                                        </div>
                                    </div>




                                    <div  className="col-12 col-lg-6 col-xl-4">
                                        <div  className="webform">
                                            <div  className="form-floating">
                                                <input className="form-control" type="number" id="pincodeShip"
                                                    maxLength='6'
                                                    placeholder="Pincode"
                                                    pattern="[0-9]{6}"
                                                    onInput={validateInputLenght}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                                    value={pincodeShip} onChange={e => setPincodeShip(e.target.value)} required />
                                                <label for="pincodeShip">Pincode</label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null

                            }



                            {cardType === 0
                                ?
                                null
                                :
                                <div  className="col-12 mt-4">
                                    <div  className="checkform">
                                        <div  className="form-check">
                                            <input type="checkbox" id="ad12" className="form-check-input" checked={sameAddressCheckbox} onChange={handleSameAddressChecked} />
                                            <label  className="form-check-label" for="ad12">{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Shipping_and_Billing_address_are_the_same}</label>
                                        </div>
                                    </div>
                                </div>
                            }



                            <div className="col-12 mt-4">
                                <button type="reset" value={process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_New_Card_Reset_Button} className="lbtn btngr" onClick={reset} >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_New_Card_Reset_Button}</button>
                                <button type="submit" value={process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_New_Card_Proceed_Button} className="lbtn btnbl" >{process.env.REACT_APP_MANAGE_CARD_VAR_FOR_Order_New_Card_Proceed_Button}</button>
                            </div>

                        </div>
                    </form>
                </div>

            )
            : (
                <Redirect to='/' />
            )


        }

    </div>

    )

}


const mapStateToProps = ({ token: { authenticated }, user: { userRole, roMdn }, role: { roleData },
    order: { cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox,
        shippingAddress, billingAddress, shippingPersonName, shippingPersonMdn, back, districtBilling, disrtrictShip, cardType }
}) => ({
    authenticated, userRole, roleData, shippingPersonName, shippingPersonMdn, roMdn,
    cardQuantity, fullNameOder, mobileNum, address1, address2, state, city, pincode, sameAddressCheckbox,
    shippingAddress, billingAddress, back, districtBilling, disrtrictShip, cardType
});

export default connect(mapStateToProps)(CreateCardOder);