import OrderTypes from './order.types';

const INITIAL_STATE = {
    cardQuantity: '',
    fullNameOder: '',
    mobileNum: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    pincode: '',
    shippingAddress: "",
    billingAddress: "",
    shippingPersonName: '',
    shippingPersonMdn: '',
    sameAddressCheckbox: true,
    totalAmt: 0,
    ratePerCard: 0,
    gstPercent: 0,
    gstAmt: 0,
    orderId: '',
    orderDate: '',
    back: false,
    totalAmtWithGst: 0,
    disrtrictShip: '',
    districtBilling: '',
    cardType: 1
};

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderTypes.SET_CARD_QUANTITY:
            return {
                ...state,
                cardQuantity: action.payload
            };
        case OrderTypes.SET_FULL_NAME:
            return {
                ...state,
                fullNameOder: action.payload
            };
        case OrderTypes.SET_MOBILE_NUMBER:
            return {
                ...state,
                mobileNum: action.payload
            };
        case OrderTypes.SET_ADDRESS_ONE:
            return {
                ...state,
                address1: action.payload
            };
        case OrderTypes.SET_STATE:
            return {
                ...state,
                state: action.payload
            };

        case OrderTypes.SET_ADDRESS_TWO:
            return {
                ...state,
                address2: action.payload
            };
        case OrderTypes.SET_PINCODE:
            return {
                ...state,
                pincode: action.payload
            };
        case OrderTypes.SET_CITY:
            return {
                ...state,
                city: action.payload
            };
        case OrderTypes.SET_SAME_ADDRESS_CHECKBOX:
            return {
                ...state,
                sameAddressCheckbox: action.payload
            };
        case OrderTypes.SET_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            };
        case OrderTypes.SET_BILLING_ADDRESS:
            return {
                ...state,
                billingAddress: action.payload
            };
        case OrderTypes.SET_SHIPPING_PERSON_MDN:
            return {
                ...state,
                shippingPersonMdn: action.payload
            };
        case OrderTypes.SET_SHIPPING_PERSON_NAME:
            return {
                ...state,
                shippingPersonName: action.payload
            };

        case OrderTypes.SET_TOTAL_AMOUNT:
            return {
                ...state,
                totalAmt: action.payload
            }

        case OrderTypes.SET_RATE_PER_CARD:
            return {
                ...state,
                ratePerCard: action.payload
            }
        case OrderTypes.SET_GST_PERCENTAGE:
            return {
                ...state,
                gstPercent: action.payload
            }

        case OrderTypes.SET_GST_AMOUNT:
            return {
                ...state,
                gstAmt: action.payload
            }
        case OrderTypes.SET_ORDER_ID:
            return {
                ...state,
                orderId: action.payload
            }
        case OrderTypes.SET_ORDER_DATE:
            return {
                ...state,
                orderDate: action.payload
            }

        case OrderTypes.SET_ORDER_BACK:
            return {
                ...state,
                back: action.payload
            }

        case OrderTypes.SET_TOATAL_AMOUNT_WITH_GST:
            return {
                ...state,
                totalAmtWithGst: action.payload
            }

        case OrderTypes.SET_DISTRICT_SHIP:
            return {
                ...state,
                disrtrictShip: action.payload
            }

        case OrderTypes.SET_DISTRICT_BILLING:
            return {
                ...state,
                districtBilling: action.payload
            }

        case OrderTypes.SET_CARD_TYPE:
            return {
                ...state,
                cardType: action.payload
            }


        case OrderTypes.RESET_ORDER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default orderReducer;