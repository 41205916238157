const BulkUploadType = {
    SET_BULK_DETAIL_SINGLE_SUCCESS: 'SET_BULK_DETAIL_SINGLE_SUCCESS',
    SET_BULK_DETAIL_SUCCESS: 'SET_BULK_DETAIL_SUCCESS',
    SET_BULK_DETAIL_INPROGRESS: 'SET_BULK_DETAIL_INPROGRESS',
    SET_BULK_DETAIL_FAIL: 'SET_BULK_DETAIL_FAIL',
    SET_BULK_STATUS_SUCCESS: 'SET_BULK_STATUS_SUCCESS',
    SET_BULK_STATUS_FAIL: 'SET_BULK_STATUS_FAIL',
    SET_BULK_STATUS_INPROGESS: 'SET_BULK_STATUS_INPROGESS',
    SET_BULK_STATUS_SINGLE_SUCCESS: 'SET_BULK_STATUS_SINGLE_SUCCESS',

}
export default BulkUploadType;