import AdminViewType from './adminView.type';


export const setAdminViewType = data => ({
    type: AdminViewType.SET_ADMIN_VIEW,
    payload: data
})


export const setAdminLoginUserEmail = data => ({
    type: AdminViewType.SET_ADMIN_LOGIN_USER_EMAIL,
    payload: data
})


export const setAdminLoginUserRoId = data => ({
    type: AdminViewType.SET_ADMIN_LOGIN_USER_ROID,
    payload: data
})

