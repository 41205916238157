import OrderTypes from './order.types';

const INITIAL_STATE = {
    orderResponse: []
};

const giftCardOrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderTypes.SET_ORDER_RESPONSE:
            return {
                ...state,
                orderResponse: action.payload
            };

        case OrderTypes.RESET_ORDER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default giftCardOrderReducer;