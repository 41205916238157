import '../css/upload-filter.styles.css';

import React from 'react';
import Select from "react-select";
import { connect } from "react-redux";
import moment from 'moment'
import { format } from "date-fns";



class UploadFilterComponent extends React.Component {
    constructor(props) {
        super();
        this.state = {

            placeHolder: '...',
            uploadStatusOption: [
                { value: "1", label: "Success" },
                { value: "2", label: "Failed" }
            ],
            uploadTypeOption: [
                { value: "2", label: "Bulk" },
                { value: "1", label: "Manual" }
            ]
        };

    }




    render() {
        const customStyles = {
            control: (base, state) => ({
                ...base,
                'border': '1px solid #ddd',
                'borderRadius': '0',
                'boxSizing': 'content-box',
                'minHeight': '30px',
                'lineHeight': '18px',
                'display': 'flex'
            }),

            valueContainer: (base) => ({
                ...base,
                'position': 'relative',
                'overflow': 'hidden',
                'margin': '0',
                'padding': '3px',
                'width': '100%',
                'minHeight': '30px',
                // 'border': '1px solid #f00',
                'backgroundColor': '#fff',
                'lineHeight': '18px',
                'cursor': 'text',
                'alignItems': 'center'
            }),
            multiValue: base => ({
                ...base,
                'boxSizing': 'content-box',
                'position': 'relative',
                'margin': '1px 5px 1px 0px',
                'padding': '1px 2px 3px 5px',
                'border': '1px solid #c4d5e1',
                'lineHeight': '18px',
                'maxWidth': '100%',
                'borderRadius': '3px',
                'backgroundColor': '#d6eeff',
                'boxShadow': '0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05)',
                'color': '#4a546c',
                'lineHeight': '16px',
                'cursor': 'default',
                'height': '20px'
            }),
            menu: (base) => ({
                ...base,
                'padding': '2px 5px',
                'lineHeight': '16px',

            }),
            input: base => ({
                ...base,
                'height': '18px',
                'lineHeight': '18px'
            })


        };

        console.log('this.props.toDate', this.props.toDate)

        var startdateToDate = moment();
        startdateToDate = startdateToDate.format("YYYY-MM-DD");

        if (this.props.toDate === '') {
            var startdateFromDate = moment();
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        } else {
            var startdateFromDate = moment(this.props.toDate);
            startdateFromDate = startdateFromDate.format("YYYY-MM-DD");
        }



        var dateMax = moment(this.props.fromDate);
        dateMax = dateMax.format("YYYY-MM-DD");
        var dateFromMax = moment(this.props.toDate);
        dateFromMax = dateFromMax.format("YYYY-MM-DD");


        return (



            <div className="head">
                <div className="row">

                    <div className="col-10">
                        <h3>Employee Uploaded History</h3>
                    </div>

                    <div className="col-2">
                        <div className="flterbox">
                            <div className="dropdown " data-toggle="tooltip" title="Filter">
                                <button type="button" className="fltrbtn dropdown-toggle" id="" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" >Filter</button>

                                <div className="dropdown-menu dropdown-menu-right">


                                    <div className="filters">
                                        <label htmlFor="select-state">Uploaded Type:</label>
                                        <Select
                                            name="filters"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiTypeRecord}
                                            options={this.state.uploadTypeOption}
                                            onChange={this.props.handleTypeMultichange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />

                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">Status:</label>
                                        <Select
                                            name="filters"
                                            placeholder={this.state.placeHolder}
                                            value={this.props.multiStatusRecord}
                                            options={this.state.uploadStatusOption}
                                            onChange={this.props.handleStatusMultichange}
                                            isMulti={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, IndicatorsContainer: () => null }}
                                            styles={customStyles}
                                        />

                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">From Date:</label>
                                        <input type="date" name="fromDate" id="fromDate" max={startdateFromDate} value={this.props.fromDate} onKeyDown={(e) => e.preventDefault()} onChange={this.props.handleDateChange} />
                                    </div>

                                    <div className="filters">
                                        <label htmlFor="select-state">To Date:</label>
                                        <input type="date" name="toDate" id="toDate" min={dateMax} max={startdateToDate} value={this.props.toDate} onKeyDown={(e) => e.preventDefault()} onChange={this.props.handleDateChange} />
                                    </div>
                                    <div className="fbtn">
                                        <button className="sbtn btnrd" type="reset" id="btnClear" onClick={this.props.clearAll}>Clear all</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div  className="actnbtn">
                            <button type="button" data-toggle="tooltip" title="Back" onclick="window.location.href='emp-list.html'"><i  className="back"></i></button>
                        </div>
                    </div>

                </div>
            </div>



        )
    }

}


const mapStateToProps = () => ({

});


export default connect(mapStateToProps)(UploadFilterComponent);
