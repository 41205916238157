import axios from 'axios';
import { LogTypes } from '../logs/LogType'
import logger from '../logs/log';
import { store } from '../redux/store';
import { sha256 } from 'js-sha256';
import history from '../auth/history';
import { resetUser } from '../redux/user/user.actions';
import { resetRole } from '../redux/role/role.action';
import { resetToken } from '../redux/token/token.action';
var response = [];
var errorData = '';

const httpClient = axios.create();
httpClient.defaults.timeout = parseInt(process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
class ApiCall {


  generateHash(messageBody) {
    //var msg= JSON.stringify(messageBody) ;
    var privateKey = process.env.REACT_APP_CLIENT_ID;
    var hash = sha256.hmac.create(privateKey);
    hash.update(messageBody);
    var hexData = hash.hex();
    console.log('hexed data', hexData);
    var base64String = btoa(hexData);
    console.log(messageBody, '---->hash Generated --->', base64String);
    return base64String;
  }


  handleAuthentication = () => {


    var url = process.env.REACT_APP_USER_LOGOUT;

    var fun = async () => {
      const state = store.getState();
      var request = {
        "mdn":state.user.mdn,
        "roId":state.user.roMdn
        
    }
      var response = await this.LogoutPostMethodCall(url, request)

      if (response !== undefined && response.response_code === 0) {
        store.dispatch(resetUser)
        store.dispatch(resetRole);
        store.dispatch(resetToken)

        console.log(store);

      } else {
        store.dispatch(resetUser)
        store.dispatch(resetRole);
        store.dispatch(resetToken)
        // var content = `Somthing Went Wrong Please Try In A Bit. [ ${response.response_description} ]`;
        // addToast(content, {
        //     appearance: 'warning',
        //     autoDismiss: true,
        //     autoDismissTimeout: 7000
        // })
      }

    }
    fun();


  }



  async getMethodCallWithoutParam(url, headers) {
    logger.infolog('200', url, LogTypes.REQUEST, ' ', '', 'Inside Api Calling');
    const str = (new URL(url)).pathname + (new URL(url)).search;
    console.log("only without param url value ---> ",(new URL(url)).pathname + (new URL(url)).search);
    console.log('test ---->', str.replace(/[^a-zA-Z0-9 ]/g, ""));
    const state = store.getState();

    var long = '';
    var lat = '';
    var locationCountry = '';
    var locationState = ''
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          console.log('geolocation permission state is ', permissionStatus.state);

          permissionStatus.onchange = function () {
            console.log('geolocation permission state has changed to ', this.state);
          };
        });
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        //imitate map latlng construct
        const marker = {
          lat: position.coords.latitude,
          long: position.coords.longitude
        };
      })
    }



    navigator.geolocation.watchPosition(async function (position) {
      long = position.coords.longitude;
      lat = position.coords.latitude;
      console.log("Latitude is :", position);
      console.log("Longitude is :", position);
    });

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log('position', position)
    });



    var sBrowser, sUsrAg = navigator.userAgent;


    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }


    const mobile = Boolean(
      window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    var mobileVar = 'Laptop'

    if (mobile) {
      mobileVar = 'Mobile'
    }


    var responseIp = ''
    // await axios.get('https://api.ipify.org?format=json')

    var hashCodeForHeader = await this.generateHash(str.replace(/[^a-zA-Z0-9 ]/g, ""));
    //var hashCodeForHeader = await this.generateHash("");

    var nav = navigator.userAgent;


    var subNav = nav.split(';');
    var os = ''
    var device_os = ''

    if (mobile) {
      os = subNav[2].split(') ')
      device_os = os[0]
    } else {
      os = subNav[0].split('(')
      device_os = os[1]
    }


    var osVersion = subNav[1].split(')')


    console.log('subNav   to calulate', subNav, " : ", os, " : => ", osVersion[0]);




    var device_info = {
      "device_info": {
        "device_os": device_os,
        "device_os_version": osVersion[0],
        "imei": '',
        "time_stamp": new Date(),
        "latitude": lat,
        "longitude": long,
        // "ipaddress": responseIp.ip,
        "ipaddress": state.location.ip,
        "channel": "",
        "browserName": sBrowser,
        "deviceType": mobileVar,
        "locationCountry": state.location.locationData.locationCountry,
        "locationState": state.location.locationState

      }
    }
    console.log('headers aaya h', headers)
    if (headers === undefined || headers === null || headers === '') {
      headers = {
        "device_info": JSON.stringify(device_info),
        "token": state.token.token,
        "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
        "hashCode": hashCodeForHeader,
        "loggedInUser": state.user.currentUser
      }
    } else {
      headers.device_info = JSON.stringify(device_info);
      headers.token = state.token.token;
      headers.client_id = process.env.REACT_APP_CLIENT_ID_HEADER;
      headers.hashCode = hashCodeForHeader;
      headers.loggedInUser = state.user.currentUser;
    }



    console.log('inside axios getMethodCallWithoutParam', url, headers, parseInt(process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT));

    return await httpClient.get(url, { headers })
      .then(async res => {
        response = await res.data;
        logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
        if (response.response_code === 6503) {
          // history.push('/logout');
          // history.go();
          this.handleAuthentication();
        }
        return response;
      }).catch(error => {
        // console.log(error);
        // errorData = 'error';
        // logger.errorlog('200', url, LogTypes.RESPONSE, ' ', 'Inside Error', error);
        // return error;

        var detail = {
          response_description: ""
        }
        console.log('inside errorr', error.response);
        if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
          console.log('error', error.response.data.debugMessage);
          console.log('error', error.response.status);
          console.log('inside errorr', error);
          if (error.response.data.code !== undefined) {

            //detail.response_description = `${error.response.data.message}(Error:${error.response.data.code})`;
            // detail.response_description = `${error.response.data.debugMessage}`;
            if (error.response.data.debugMessage !== null && error.response.data.debugMessage !== "null") {
              detail.response_description = `${error.response.data.debugMessage}`
            }
            else {
              detail.response_description = `${error.response.data.message}`
            }
            errorData = detail;
          } else {
            // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
            if (error.response.data.debugMessage !== null && error.response.data.debugMessage !== "null") {
              detail.response_description = `${error.response.data.debugMessage}`
            }
            else {
              detail.response_description = `${error.response.data.message}`
            }
            errorData = detail;
          }
          console.log('inside errorr', errorData);
          if (error.response.status === 500) {
            if (error.response.data.code !== undefined) {
              // detail.response_description =  `${error.response.data.message}(Error:${error.response.data.code})`
              // detail.response_description =  `${error.response.data.debugMessage}`
              if (error.response.data.debugMessage !== null && error.response.data.debugMessage !== "null") {
                detail.response_description = `${error.response.data.debugMessage}`
              }
              else {
                detail.response_description = `${error.response.data.message}`
              }
              errorData = detail;
            } else {
              // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
              // detail.response_description =`${error.response.data.debugMessage}`
              if (error.response.data.debugMessage !== null && error.response.data.debugMessage !== "null") {
                detail.response_description = `${error.response.data.debugMessage}`
              }
              else {
                detail.response_description = `${error.response.data.message}`
              }
              errorData = detail;
            }
            // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          }
          logger.errorlog('200', url, LogTypes.RESPONSE, ' ', 'Inside Error', errorData);

        } else {
          console.log('inside errorr ', error);
          errorData = 'error';
        }
        return errorData;

      })
  }


  async getMethodCallWithParam(url, param, headers) {
    logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(param), '', 'Inside Api Calling');
    const dataWithParam=url+JSON.stringify(param);
    console.log("param value ---> ",dataWithParam.replace(/[^a-zA-Z0-9 ]/g, ""));
    console.log("only with param url value ---> ",(new URL(url)).pathname);
    //const str = url.split("?")[0];
    var finalUrl= (new URL(url)).pathname;
    if(param === undefined){

    }else{
      finalUrl = finalUrl+JSON.stringify(param);
    }
    const str = finalUrl;
    console.log('test ---->', str.replace(/[^a-zA-Z0-9 ]/g, ""));

    const state = store.getState();



    var long = '';
    var lat = '';
    var locationCountry = '';
    var locationState = ''
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          console.log('geolocation permission state is ', permissionStatus.state);

          permissionStatus.onchange = function () {
            console.log('geolocation permission state has changed to ', this.state);
          };
        });
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        //imitate map latlng construct
        const marker = {
          lat: position.coords.latitude,
          long: position.coords.longitude
        };
      })
    }

    navigator.geolocation.watchPosition(async function (position) {
      long = position.coords.longitude;
      lat = position.coords.latitude;
      console.log("Latitude is :", position);
      console.log("Longitude is :", position);
    });


    var sBrowser, sUsrAg = navigator.userAgent;


    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }


    const mobile = Boolean(
      window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    var mobileVar = 'Laptop'

    if (mobile) {
      mobileVar = 'Mobile'
    }


    var responseIp = ''
    // await axios.get('https://api.ipify.org?format=json')

    //var hashCodeForHeader = await this.generateHash('');
    var hashCodeForHeader = await this.generateHash(str.replace(/[^a-zA-Z0-9 ]/g, ""));


    var nav = navigator.userAgent;


    var subNav = nav.split(';');
    var os = ''
    var device_os = ''

    if (mobile && subNav[2]!==undefined) {
      os = subNav[2].split(') ')
      device_os = os[0]
    } else {
      os = subNav[0].split('(')
      device_os = os[1]
    }


    var osVersion = subNav[1].split(')')


    console.log('subNav   to calulate', subNav, " : ", os, " : => ", osVersion[0]);




    var device_info = {
      "device_info": {
        "device_os": device_os,
        "device_os_version": osVersion[0],
        "imei": '',
        "time_stamp": new Date(),
        "latitude": lat,
        "longitude": long,
        // "ipaddress": responseIp.ip,
        "ipaddress": state.location.ip,
        "channel": "",
        "browserName": sBrowser,
        "deviceType": mobileVar,
        "locationCountry": state.location.locationCountry,
        "locationState": state.location.locationState

      }
    }
    console.log('headers aaya h', headers)
    if (headers === undefined || headers === null || headers === '') {
      headers = {
        "device_info": JSON.stringify(device_info),
        "token": state.token.token,
        "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
        "hashCode": hashCodeForHeader,
        "loggedInUser": state.user.currentUser
      }
    } else {
      headers.device_info = JSON.stringify(device_info);
      headers.token = state.token.token;
      headers.client_id = process.env.REACT_APP_CLIENT_ID_HEADER;
      headers.hashCode = hashCodeForHeader;
      headers.loggedInUser = state.user.currentUser;
    }





    console.log('inside axios getMethodCallWithParam', headers, parseInt(process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT));







    console.log('url', url);
    return await httpClient.get(url, { params: param, headers }, {
      headers: {
        "device_info": JSON.stringify(device_info),
        "token": state.token.token,
        "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
        "hashCode": hashCodeForHeader,
        "loggedInUser": state.user.currentUser
      }
    })
      .then(async res => {
        response = await res.data;
        console.log('get response for ', url, "  :", response);
        logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
        if (response.response_code === 6503) {
          // history.push('/logout');
          // history.go();
          this.handleAuthentication();
        }
        return response;
      }).catch(error => {
        // console.log(error);
        // errorData = 'error';
        // logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(param), 'Inside Error', error);
        // return error;

        var detail = {
          response_description: ""
        }
        console.log('inside errorr', error.response);
        if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
          console.log('error', error.response.data.debugMessage);
          console.log('error', error.response.status);
          console.log('inside errorr', error);
          if (error.response.data.code !== undefined) {

            //detail.response_description = `${error.response.data.message}(Error:${error.response.data.code})`;
            detail.response_description = `${error.response.data.debugMessage}`;
            errorData = detail;
          } else {
            // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
            detail.response_description = `${error.response.data.debugMessage}`
            errorData = detail;
          }
          console.log('inside errorr', errorData);
          if (error.response.status === 500) {
            if (error.response.data.code !== undefined) {
              // detail.response_description =  `${error.response.data.message}(Error:${error.response.data.code})`
              detail.response_description = `${error.response.data.debugMessage}`
              errorData = detail;
            } else {
              // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
              detail.response_description = `${error.response.data.debugMessage}`
              errorData = detail;
            }
            // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          }
          logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(param), 'Inside Error', errorData);

        } else {
          console.log('inside errorr ', error);
          errorData = 'error';
        }
        return errorData;

      })
  }

  async postMethodCall(url, request, headers) {
    const state = store.getState();

    var long = '';
    var lat = '';
    var locationCountry = '';
    var locationState = ''
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          console.log('geolocation permission state is ', permissionStatus.state);

          permissionStatus.onchange = function () {
            console.log('geolocation permission state has changed to ', this.state);
          };
        });
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        //imitate map latlng construct
        const marker = {
          lat: position.coords.latitude,
          long: position.coords.longitude
        };
      })
    }

    navigator.geolocation.watchPosition(async function (position) {
      long = position.coords.longitude;
      lat = position.coords.latitude;
      console.log("Latitude is :", position);
      console.log("Longitude is :", position);
    });



    var sBrowser, sUsrAg = navigator.userAgent;


    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }


    const mobile = Boolean(
      window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    var mobileVar = 'Laptop'

    if (mobile) {
      mobileVar = 'Mobile'
    }


    var responseIp = ''
    // await axios.get('https://api.ipify.org?format=json')
    //   .then(async res => {
    //     response = res.data;
    //     console.log(res.data);
    //     logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
    //     return response;
    //   });

    // this.getMethodCallWithoutParam('https://api.ipify.org?format=json');

    var hashCodeForHeader = await this.generateHash(JSON.stringify(request));


    var nav = navigator.userAgent;


    var subNav = nav.split(';');
    var os = ''
    var device_os = ''

    if (mobile) {
      os = subNav[2].split(') ')
      device_os = os[0]
    } else {
      os = subNav[0].split('(')
      device_os = os[1]
    }


    var osVersion = subNav[1].split(')')


    console.log('subNav   to calulate', subNav, " : ", os, " : => ", osVersion[0]);




    var device_info = {
      "device_info": {
        "device_os": device_os,
        "device_os_version": osVersion[0],
        "imei": '',
        "time_stamp": new Date(),
        "latitude": lat,
        "longitude": long,
        "ipaddress": state.location.ip,
        // "ipaddress": responseIp.ip,
        "channel": "",
        "browserName": sBrowser,
        "deviceType": mobileVar,
        "locationCountry": state.location.locationCountry,
        "locationState": state.location.locationState

      }
    }
    console.log('headers aaya h', headers)
    if (headers === undefined || headers === null || headers === '') {
      headers = {
        "device_info": JSON.stringify(device_info),
        "token": state.token.token,
        "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
        "hashCode": hashCodeForHeader,
        "loggedInUser": state.user.currentUser
      }
    } else {
      headers.device_info = JSON.stringify(device_info);
      headers.token = state.token.token;
      headers.client_id = process.env.REACT_APP_CLIENT_ID_HEADER;
      headers.hashCode = hashCodeForHeader;
      headers.loggedInUser = state.user.currentUser;
    }


    if (url.includes('/logout')) {
      headers.Authorization = state.token.token
    }


    logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('inside axios', headers, parseInt(process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT));




    return await httpClient.post(url, request, { headers })
      .then(async res => {
        response = res.data;
        console.log(res.data);
        logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
        if (response.response_code === 6503) {
          // history.push('/logout');
          // history.go();
          this.handleAuthentication();
        }
        return response;
      }).catch(async (error) => {
        var detail = {
          response_description: ""
        }
        console.log('inside errorr', error.response);
        if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
          console.log('error', error.response.data.debugMessage);
          console.log('error', error.response.status);
          console.log('inside errorr', error);
          if (error.response.data.code !== undefined) {

            //detail.response_description = `${error.response.data.message}(Error:${error.response.data.code})`;
            if (error.response.data.debugMessage !== undefined && error.response.data.debugMessage !== null) {
              detail.response_description = `${error.response.data.debugMessage}`;
              errorData = detail;
            } else {
              detail.response_description = `${error.response.data.message}`;
              errorData = detail;
            }
          } else {
            // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
            detail.response_description = `${error.response.data.debugMessage}`
            errorData = detail;
          }
          console.log('inside errorr', errorData);
          if (error.response.status === 500) {
            if (error.response.data.code !== undefined) {
              // detail.response_description =  `${error.response.data.message}(Error:${error.response.data.code})`
              if (!error.response.data.debugMessage === undefined) {
                detail.response_description = `${error.response.data.debugMessage}`;
                errorData = detail;
              } else {
                detail.response_description = `${error.response.data.message}`;
                errorData = detail;
              }
            } else {
              // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
              if (!error.response.data.debugMessage === undefined) {
                detail.response_description = `${error.response.data.debugMessage}`;
                errorData = detail;
              } else {
                detail.response_description = `${error.response.data.message}`;
                errorData = detail;
              }
            }
            // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          }
          logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(request), 'Inside Error', errorData);

        } else {
          console.log('inside errorr ', error);
          errorData = 'error';
        }
        return errorData;


        // errorData = 'error';
        // //errorData ='error';
        // console.log(error);
        // logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(request), 'Inside Error', error);
        // return errorData;
      })

  }





  async LogoutPostMethodCall(url, request, headers) {
    const state = store.getState();

    var long = '';
    var lat = '';
    var locationCountry = '';
    var locationState = ''
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          console.log('geolocation permission state is ', permissionStatus.state);

          permissionStatus.onchange = function () {
            console.log('geolocation permission state has changed to ', this.state);
          };
        });
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        //imitate map latlng construct
        const marker = {
          lat: position.coords.latitude,
          long: position.coords.longitude
        };
      })
    }

    navigator.geolocation.watchPosition(async function (position) {
      long = position.coords.longitude;
      lat = position.coords.latitude;
      console.log("Latitude is :", position);
      console.log("Longitude is :", position);
    });



    var sBrowser, sUsrAg = navigator.userAgent;


    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }


    const mobile = Boolean(
      window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    var mobileVar = 'Laptop'

    if (mobile) {
      mobileVar = 'Mobile'
    }


    var responseIp = ''
    //  await axios.get('https://api.ipify.org?format=json')
    //   .then(async res => {
    //     response = res.data;
    //     console.log(res.data);
    //     logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
    //     return response;
    //   });

    // this.getMethodCallWithoutParam('https://api.ipify.org?format=json');

    var hashCodeForHeader = await this.generateHash(JSON.stringify(request));


    var nav = navigator.userAgent;


    var subNav = nav.split(';');
    var os = ''
    var device_os = ''

    if (mobile) {
      os = subNav[2].split(') ')
      device_os = os[0]
    } else {
      os = subNav[0].split('(')
      device_os = os[1]
    }


    var osVersion = subNav[1].split(')')


    console.log('subNav   to calulate', subNav, " : ", os, " : => ", osVersion[0]);




    var device_info = {
      "device_info": {
        "device_os": device_os,
        "device_os_version": osVersion[0],
        "imei": '',
        "time_stamp": new Date(),
        "latitude": lat,
        "longitude": long,
        // "ipaddress": responseIp,
        "ipaddress": state.location.ip,
        "channel": "",
        "browserName": sBrowser,
        "deviceType": mobileVar,
        "locationCountry": state.location.locationCountry,
        "locationState": state.location.locationState

      }
    }
    console.log('headers aaya h', headers)
    if (headers === undefined || headers === null || headers === '') {
      headers = {
        "device_info": JSON.stringify(device_info),
        "token": state.token.token,
        "client_id": process.env.REACT_APP_CLIENT_ID_HEADER,
        "hashCode": hashCodeForHeader,
        "loggedInUser": state.user.currentUser
      }
    } else {
      headers.device_info = JSON.stringify(device_info);
      headers.token = state.token.token;
      headers.client_id = process.env.REACT_APP_CLIENT_ID_HEADER;
      headers.hashCode = hashCodeForHeader;
      headers.loggedInUser = state.user.currentUser;
    }


    if (url.includes('/logout')) {
      headers.Authorization = state.token.token
    }


    logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('inside axios', headers, parseInt(process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT));




    return await httpClient.post(url, request, { headers })
      .then(async res => {
        response = res.data;
        console.log(res.data);
        logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
        return response;
      }).catch(async (error) => {
        var detail = {
          response_description: ""
        }
        console.log('inside errorr', error.response);
        if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
          console.log('error', error.response.data.debugMessage);
          console.log('error', error.response.status);
          console.log('inside errorr', error);
          if (error.response.data.code !== undefined) {

            //detail.response_description = `${error.response.data.message}(Error:${error.response.data.code})`;
            if (error.response.data.debugMessage !== undefined) {
              detail.response_description = `${error.response.data.debugMessage}`;
              errorData = detail;
            } else {
              detail.response_description = `${error.response.data.message}`;
              errorData = detail;
            }
          } else {
            // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
            detail.response_description = `${error.response.data.debugMessage}`
            errorData = detail;
          }
          console.log('inside errorr', errorData);
          if (error.response.status === 500) {
            if (error.response.data.code !== undefined) {
              // detail.response_description =  `${error.response.data.message}(Error:${error.response.data.code})`
              if (!error.response.data.debugMessage === undefined) {
                detail.response_description = `${error.response.data.debugMessage}`;
                errorData = detail;
              } else {
                detail.response_description = `${error.response.data.message}`;
                errorData = detail;
              }
            } else {
              // detail.response_description =`${error.response.data.message}(Error:${error.response.status})`
              if (!error.response.data.debugMessage === undefined) {
                detail.response_description = `${error.response.data.debugMessage}`;
                errorData = detail;
              } else {
                detail.response_description = `${error.response.data.message}`;
                errorData = detail;
              }
            }
            // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          }
          logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(request), 'Inside Error', errorData);

        } else {
          console.log('inside errorr ', error);
          errorData = 'error';
        }
        return errorData;


        // errorData = 'error';
        // //errorData ='error';
        // console.log(error);
        // logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(request), 'Inside Error', error);
        // return errorData;
      })

  }

}

export default new ApiCall();