import MessageType from './message.types';

const INITIAL_STATE = {
    message:'',
    severty:'success'
};

const messageReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        
        case MessageType.MESSAGE_TO_BE_SHOWN:
            console.log('TOGGLE_DROPDOWN_HIDDEN');
            return {
                
                ...state,
                message: action.payload
            };
        case MessageType.SET_SEVERTY:
            return{
                ...state,
                severty:action.payload

            }
        default:
      return state;
    }
};

export default messageReducer;