export const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USER_ROLE: 'SET_USER_ROLE',
  SET_RO_MDN: 'SET_RO_MDN',
  SET_CORPORATE_WALLET: 'SET_CORPORATE_WALLET',
  RESET_USER: "RESET_USER",
  SET_FULL_NAME: "SET_FULL_NAME",
  SET_MDN: 'SET_MDN',
  SET_MAX_GENERAL_AMOUNT: 'SET_MAX_GENERAL_AMOUNT',
  SET_MAX_FOOD_AMOUNT: 'SET_MAX_FOOD_AMOUNT',
  SET_MAX_TRAVEL_AMOUNT: 'SET_MAX_TRAVEL_AMOUNT',
  SET_CORP_BAND_LIMITS: 'SET_CORP_BAND_LIMITS',
  SET_EMPLOYEE_DETAIL: 'SET_EMPLOYEE_DETAIL',
  SET_BANKID: 'SET_BANKID',
  SET_BACK_URL: 'SET_BACK_URL',
  SET_SESSION_EXPIRE: 'SET_SESSION_EXPIRE',
  SET_RO_NAME: 'SET_RO_NAME',
  SET_CORP_BANK_DETAIL: 'SET_CORP_BANK_DETAIL',
  SET_BANK_BACK_BUTTON: 'SET_BANK_BACK_BUTTON',
  SET_BANK_ADD_UPDATE_URL: 'SET_BANK_ADD_UPDATE_URL',
  SET_BUTTON_NAME: 'SET_BUTTON_NAME'

};
