
import React from 'react';
import '../css/giftCard.styles.css'
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';
import apiCallService from '../../service/api-call-service';
import history from '../../auth/history';
import GenerateRequestId from '../../common-function/GenerateRequestId';
import Countdown from 'react-countdown';

class SuccessReviewPage extends React.Component {

    constructor() {
        super();

        this.state = {
            dropDownHidden: false,
            message: "",
            severty: "success",
            surchargeAmount: 0.00,
            totalLoadAmount: 0.00,
            buttonDisable: true,
            totalAvailableBalance: 0.00,

            otp: "",
            referenceNum: "",
            time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT),
            triggeretime: true,
            buttonShowLimit: false,
            disableVar: true,


            resendLoader: false,
            buttonLoader: false,
            gnerateOtpLoader: false


        };
    }


    componentDidMount = () => {

        document.title = "ShakePe Gift Cards"

        localStorage.setItem('url', '');


        this.calculateSurcharge(parseFloat(this.props.orderResponse.totalAmount), process.env.REACT_APP_SERVICE_NAME_FOR_GIFT_CARD_ORDER);
        this.getBalance(this.props.roMdn)

    }


    getBalance = async (mdn) => {

        const request = {
            device_info: {
                api_version: "1.0",
                app_version: "9.2",
                device_id: "d215ab007e05c498",
                device_os: "Android",
                device_os_version: "5.1.1",
                imei: "357869081907005",
                ip_address: "172.160.2.140",
                model_name: "SM-J320F",
                source: "mobile",
            },
            request: {
                user: {
                    username: `91${mdn}`
                },
                transaction_data: {
                    request_id: GenerateRequestId.geneRateRequestId(),
                    limit_required: "true"
                }
            }
        };
        let headers = {
            "client_id": "OEMSAPP",
            "token": this.props.token,
            "Authorization": "BASIC abcd"
        }
        console.log("inside balanceEnquiry request :-", request, " Employee Details ", this.props);
        var response = [];

        const url = process.env.REACT_APP_API_WALLET_BALANCE_ENQUIRY
        console.log(url, request, "header")
        response = await apiCallService.postMethodCall(url, request, headers);
        const detail = response.response;
        console.log('response balanceEnquiry', response)
        if (detail !== undefined && response.response_code === 0) {
            this.setState({ totalAvailableBalance: parseFloat(detail.Balance.total_balance / 100).toFixed(2) })
            //  this.setState({ totalAvailableBalance: 500 })
            // this.setState({ totalLienBalance: parseFloat(detail.Balance.total_lien / 100).toFixed(2) })
        } else {
            return 0.00
        }

    }


    calculateSurcharge = async (amount, serviceName) => {
        var message = 'Api not called'
        var url = process.env.REACT_APP_CALCULATE_SURCHARGE;
        var request = {
            "roId": this.props.roMdn,
            "quantity": amount,
            "serviceName": serviceName
        }
        console.log('response inside render method', message, ' : ', amount, serviceName)
        // if (amount !== 0 && serviceName !== '') {
        var response = await apiCallService.postMethodCall(url, request);
        message = 'Api called'
        console.log('response inside render method', message)
        console.log('response inside render method', response, request)
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.response !== undefined) {
            this.setState({ buttonDisable: false })
            this.setState({ surchargeAmount: parseFloat(parseFloat(response.response.surcharge) / 100) })
            this.setState({ totalLoadAmount: parseFloat(parseFloat(response.response.surcharge) / 100) + parseFloat(parseFloat(amount) / 100) })
            // this.setState({ loadAmount: amount })

        } else {
            this.setState({ surchargeAmount: 0 })
            this.setState({ totalLoadAmount: 0 })
            // this.setState({ loadAmount: amount })
            this.setState({ message: response.response_description })
            this.setState({ severty: "error" })
            this.setState({ dropDownHidden: true })
        }
    }


    orderAgain = () => {
        history.push("/manage-giftCard");
        history.go();
    }






    hadleDefaultSubmit = async (event) => {
        event.preventDefault();

        // var detail = event.target.id;


        if( this.state.totalAvailableBalance < this.state.totalLoadAmount){
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Low_Balance_Message })
        }else{

        this.setState({ gnerateOtpLoader: true })
        var url = process.env.REACT_APP_URL_TO_GET_OTP
        var requestToSend = {
            "request": {
                "eventType": "rowalletlinkup",
                "destinations": [
                    {
                        "address": this.props.mdn,
                        "type": "mobile"
                    }
                ]
            }
        }

        console.log('request allValues', requestToSend)

        var response = await apiCallService.postMethodCall(url, requestToSend);


        console.log('request response allValues', response);
        if (response.response_code === 0 && response.response !== undefined) {
            this.setState({ gnerateOtpLoader: false })
            this.setState({ buttonShowLimit: true })
            this.setState({ referenceNum: response.response.referenceNumber })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true })
            // console.log("sendotpsaveCorpLimitButton", document.getElementById("sendotpsaveCorpLimitButton"))
            document.getElementById("sendotp").click();

        } else {
            this.setState({ gnerateOtpLoader: false })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }

}
    }

    resendOtp = async (event) => {
        this.setState({ resendLoader: true });
        var url = process.env.REACT_APP_URL_TO_GET_OTP
        var requestToSend = {
            "request": {
                "eventType": "rowalletlinkup",
                "destinations": [
                    {
                        "address": this.state.mobileNum,
                        "type": "mobile"
                    }
                ]
            }
        }

        console.log('request allValues', requestToSend)

        var response = await apiCallService.postMethodCall(url, requestToSend);
        this.setState({ triggeretime: false })

        console.log('request response allValues', response);
        if (response.response_code === 0 && response.response !== undefined) {
            this.setState({ resendLoader: false });
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'success' })
            this.setState({ message: process.env.REACT_APP_Message_For_Resend_otp_AT_Corp_profile })
            this.setState({ referenceNum: response.response.referenceNumber })
            this.setState({ time: Date.now() + parseInt(process.env.REACT_APP_OTP_TIME_OUT) })
            this.setState({ triggeretime: true })

        } else {
            this.setState({ resendLoader: false });
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }

    }



    updateOrder = async (event) => {
        event.preventDefault();

        this.setState({ buttonLoader: true })
        const url = process.env.REACT_APP_UPDATE_GIFT_CARD_ORDER_URL;

        var request = {
            "orderStatus": 2,
            "surcharge": this.state.surchargeAmount,
            "orderId": this.props.orderResponse.orderId,
            "updated_by": this.props.currentUser,
            "otp": this.state.otp,
            "referenceNumber": this.state.referenceNum
        }

        var response = await apiCallService.postMethodCall(url, request);

        console.log("response from update order", response);

        if (response !== undefined && response.response_code !== undefined && response.response_code === 0) {
            this.setState({ buttonLoader: false })
            document.getElementById("closePopup").click();
            this.props.showDetailedSection(event, response.order);

        } else {
            this.setState({ buttonLoader: false })
            this.setState({ dropDownHidden: true })
            this.setState({ severty: 'error' })
            this.setState({ message: response.response_description })
        }
    }



    MethodTosetDisabledVar = () => {
        this.setState({ disableVar: false })


    }
    MethodTosetEnableVar = () => {
        this.setState({ disableVar: true })


    }

    validateInputLenght = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }



    render() {
        var zeroOne = this.props.mdn.substring(0, 1);
        var sevenNine = this.props.mdn.substring(8, 10);

        return (
            < >
                {(this.props.authenticated)
                    ? (

                        this.props.isPasswordReset
                            ?
                            <>


                                <div className="col-12 col-md-6 col-lg-8">
                                    <div className="giftreview">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Gift_Cards_Review} <a href="/manage-giftCard" data-toggle="tooltip" title="" className="back" data-original-title="Back">Back</a></h3>
                                            </div>
                                            <Snackbar

                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                open={this.state.dropDownHidden}
                                                autoHideDuration={20000}
                                                onClose={e => this.setState({ dropDownHidden: false })}
                                            >
                                                <Alert onClose={e => this.setState({ dropDownHidden: false })} severity={this.state.severty}>
                                                    {this.state.message}
                                                </Alert>
                                            </Snackbar>


                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Ordered_By}</p>
                                                    <h5>{this.props.orderResponse.uploadedByName}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Mobile_Number}</p>
                                                    <h5>+91-{this.props.orderResponse.mobileNumber}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Gift_Cards_Quantity}</p>
                                                    <h5>{this.props.orderResponse.giftCardQuantity}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Beneficiaries}</p>
                                                    <h5>{this.props.orderResponse.totalBenificiary}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Amount}</p>
                                                    <h5>₹{parseFloat(parseFloat(this.props.orderResponse.totalAmount) / 100).toFixed(2)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Surcharge}</p>
                                                    <h5>₹{parseFloat(this.state.surchargeAmount).toFixed(2)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="reviewtxt">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Total_Amount}</p>
                                                    <h5>₹{parseFloat(this.state.totalLoadAmount).toFixed(2)}</h5>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="reviewadrs">
                                                    <p>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Billing_Address}</p>
                                                    <h5>{this.props.orderResponse.uploadedByName}</h5>
                                                    <p>{this.props.orderResponse.billingAddress}</p>
                                                    <p><i className="mobile"></i>+91-{this.props.orderResponse.mobileNumber}</p>
                                                </div>
                                            </div>


                                            <div className="col-12 mt-3">
                                                {/* <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="ad12"> */}
                                                   <span style={{color:"#dc3545"}}> *</span>
                                                  <span>  {process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Total_Amount_to_be_deducted_from_Available_Balance} - <strong>₹{this.state.totalAvailableBalance}</strong></span>
                                                {/* </label> */}
                                            </div>

                                            {/* {
                                                this.state.totalAvailableBalance < this.state.totalLoadAmount
                                                    ?
                                                    <div className="col-12 mt-3">


                                                        <span className="mdl-checkbox__label"> <strong style={{ color: "#dc3545" }}>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Low_Balance_Message}</strong></span>

                                                    </div>
                                                    :
                                                    null
                                            } */}

                                            <div className="col-12 mt-5">
                                                <button className="mbtn btngr" id="savecorpinfo" onClick={this.orderAgain}>{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Cancel_Button}</button>

                                                {
                                                    this.state.buttonShowLimit
                                                        ?
                                                        <button type="button" className="mbtn btnbl" id="sendotp" data-bs-toggle="modal" data-bs-target="#sendotpTarget"
                                                            disabled={this.state.buttonDisable
                                                                // || this.state.totalAvailableBalance < this.state.totalLoadAmount
                                                            }
                                                        >{process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Order_Now_Button}</button>
                                                        :
                                                        <button className="mbtn btnbl" type="button" onClick={this.hadleDefaultSubmit} disabled={this.state.buttonDisable
                                                            // || this.state.totalAvailableBalance < this.state.totalLoadAmount
                                                        }>
                                                            {this.state.gnerateOtpLoader
                                                                ?
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                </div>

                                                                :
                                                                <>
                                                                    {process.env.REACT_APP_MANAGE_GIFT_CARD_SuccessReview_VAR_FOR_Order_Now_Button}
                                                                </>
                                                            }

                                                        </button>
                                                }
                                              
                                            </div>

                                        </div>
                                    </div>
                                </div>




                                <div className="modal fade" id="sendotpTarget" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                                    <div className="modal-dialog modal-sm">

                                        <form className="modal-content alerttext" onSubmit={this.updateOrder}>
                                            <div className="modal-header">
                                                <h5>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_HEADING_One_Time_Password} </h5>
                                                <a href={() => false} className="close" id="closePopup" data-bs-dismiss="modal"  aria-label="Close">&times;</a>
                                            </div>
                                            <div className="modal-body">
                                                <div className="otptxt">
                                                    <input className="form-control"
                                                        type="number"
                                                        maxLength='6'
                                                        pattern="[0-9]{6}"
                                                        onInput={this.validateInputLenght}
                                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}

                                                        placeholder={process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_Enter_OTP} value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} required />
                                                    <p>{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_PARAGRAPH_OF_OTP} {zeroOne}XXXXXXX{sevenNine}</p>

                                                    <h5>
                                                        {
                                                            this.state.triggeretime ?
                                                                <Countdown date={this.state.time} onMount={this.MethodTosetEnableVar} onComplete={this.MethodTosetDisabledVar} />
                                                                :
                                                                null
                                                        }
                                                    </h5>
                                                    ref no. {this.state.referenceNum}
                                                </div>

                                            </div>
                                            <div className="modal-footer">

                                                {
                                                    this.state.disableVar
                                                        ?
                                                        // <button type="button" className="mbtn btngr" onClick={handleResendOtp} disabled={disableVar}>{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_Resend_OTP}</button>
                                                        <button type="button" className="mbtn btngr" onClick={this.resendOtp} disabled={this.state.disableVar}>
                                                            {
                                                                this.state.resendLoader
                                                                    ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                    </div>

                                                                    :
                                                                    <>

                                                                        {process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}
                                                                    </>

                                                            }
                                                        </button>
                                                        :
                                                        // <button type="button" className="mbtn btngn" onClick={handleResendOtp} disabled={disableVar}>{process.env.REACT_APP_VAR_FOR_DISBURSE_OTP_POPUP_Resend_OTP}</button>
                                                        <button type="button" className="mbtn btngn" onClick={this.resendOtp} disabled={this.state.disableVar}>
                                                            {
                                                                this.state.resendLoader
                                                                    ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                    </div>

                                                                    :
                                                                    <>

                                                                        {process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Resend_OTP}
                                                                    </>

                                                            }
                                                        </button>

                                                }

                                                {
                                                    this.state.otpButtonClose
                                                        ?
                                                        <button type="button" className="mbtn btnbl" id="closeOtpLimitSection" data-bs-dismiss="modal"  aria-label="Close">{process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}</button>
                                                        :
                                                        <button type="submit" className="mbtn btnbl" >

                                                            {
                                                                this.state.buttonLoader
                                                                    ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">{process.env.REACT_APP_Var_FOR__Loading}</span>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {process.env.REACT_APP_CORP_PROFILE_LABEL_FOR_POPUP_BUTTON_Submit}
                                                                    </>
                                                            }

                                                        </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </>

                            :
                            <ResetLoginPassword />
                    )

                    : (
                        <Redirect to='/' />
                    )


                }

            </>

        )
    }
}


const mapStateToProps = ({ token: { authenticated, isPasswordReset }, user: { userRole, roMdn, mdn, currentUser, fullName }, role: { roleData },
    giftCardOrder: { orderResponse }
}) => ({
    authenticated, userRole, roleData, roMdn, mdn, isPasswordReset, currentUser, fullName, orderResponse

});

export default connect(mapStateToProps)(SuccessReviewPage);