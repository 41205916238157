

import EditBandDetailType from "./edit-band-limit.types";

const INITIAL_STATE = {
   editBandDetail:[]
};


const SetEditLimtReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
       
        case EditBandDetailType.SET_EDIT_BAND_DETAIL:
            return{
                ...state,
                editBandDetail : action.payload 
            }
        default:
            return state;
    }
};

export default SetEditLimtReducer;
