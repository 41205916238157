import React, { useEffect } from 'react';
import '../css/employee.css';
import Can from '../../auth/can/Can';
import { Redirect } from 'react-router-dom';

import { connect } from "react-redux";

import ResetLoginPassword from '../../corporate-profile/component/reset-login-password';

import CardStatusType from '../../constants/card-status.type';
import CardStatus from '../../common/component/card-status.component';
import SmallPopUp from "../../common/component/small.popup.component";
import CardManagementService from '../../service/service-impl/CardManagementService';
import SmallPopUpTempBlock from '../../common/component/small.popup.componentempReason';

var currentUserT = '';
var ro_id = ''
var fullNameV = '';
const EmpMangeCardSection = ({ roMdn, fullName, currentUser, cardDetails, roleData, userRole, isPasswordReset, authenticated, employeeDetail
}) => {
    ro_id = roMdn
    currentUserT = currentUser
    fullNameV = fullName

    useEffect(() => {

        document.title = "ShakePe Employee Mange Card"

    }, []);




    const handleTempBlock = (event, data, cd, reason) => {

        console.log("clicked ", data);
        console.log("inside card update status request :- ", currentUserT);

        console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
        // updateCardStatus(url, mdn, useraname, otp, reason, status,header,cardReferenceNum,updateType,updatedBy ) {
        // updateCardStatus(url, mdn, useraname, otp, reason, status, header, cardReferenceNum, updateType, updatedBy, ro_Id, isMessageShow, message,updatedByEmail) {

        CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "", reason.reason, "3", "", cardDetails.cardReferenceNo, "temp", fullNameV, ro_id, 7, process.env.REACT_APP_SUCESS_MESSAGE_FOR_TEMPORARY_BLOCK, currentUserT);


    }
    const handlePermanentBlock = (event, data, cd, reason) => {
        console.log("clicked handlePermanentBlock", data);


        console.log("inside card update status request :- ", currentUserT);

        console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
        CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "", reason.reason, "4", "", cardDetails.cardReferenceNo, "permanent", fullNameV, ro_id, 7, process.env.REACT_APP_SUCESS_MESSAGE_FOR_PERMANENT_BLOCK, currentUserT);

        console.log("clicked ", data);

    }

    const handleCardUnLock = (event, data, cd, reason) => {
        console.log("clicked handlePermanentBlock", data);
        //store.dispatch(toggleDropDownHidden(true));

        //store.dispatch(setMessageToBeShown('SuccessFull Permanent Block.'));
        //url, mdn, useraname, otp, reason, status
        console.log(process.env.REACT_APP_UPDATECARDSTATUS_URL);
        // updateCardStatus(url, mdn, useraname, otp, reason, status,header,cardReferenceNum,updateType,updatedBy ) {

        CardManagementService.updateCardStatus(process.env.REACT_APP_UPDATECARDSTATUS_URL, data.mdn, data.first_name, "", "Unlock Card",
            "0", "", cardDetails.cardReferenceNo, 'unBlock', fullNameV, ro_id, 7, process.env.REACT_APP_SUCESS_MESSAGE_FOR_UNLOCK_CARD, currentUserT);

        console.log("clicked ", data);
    }




    return (

        console.log('inside dashboard before can :', { userRole }),
        console.log('inside dashboard before can :', { roleData }),
        <>
            {(authenticated)
                ? (
                    <Can
                        role={userRole}
                        perform="dashboard-page:visit"
                        roleData={roleData}
                        yes={() => (
                            <>

                                {isPasswordReset ?

                                    <>
                                        <div className="row">


                                            {
                                                parseInt(cardDetails.cardType) === 1
                                                    ?
                                                    <div className="col-12 col-md-5 col-lg-4">
                                                        <div className="type-card">
                                                            <i className="phycl"></i>
                                                            <p>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Physical_Card_Ref_No} </p>
                                                            <h6>{cardDetails.cardReferenceNo}</h6>
                                                        </div>
                                                    </div>
                                                    :

                                                    <div className="col-12 col-md-5 col-lg-4">
                                                        <div className="type-card">
                                                            <i className="vrtl"></i>
                                                            <p>{process.env.REACT_APP_EMP_DETAIL_TEXT_FOR_Virtual_Card_Ref_No}</p>
                                                            <h6>{cardDetails.cardReferenceNo} </h6>
                                                        </div>
                                                    </div>

                                            }



                                            <CardStatus cardStatus={parseInt(cardDetails.cardStatus)} type='txt' classNameToSet="col-12 col-sm-6 col-md-4 col-lg-4" cardStatusClassName="type-card-status" />


                                            {
                                                parseInt(cardDetails.cardStatus) === CardStatusType.NOT_ASSIGNED
                                                    ?
                                                    null
                                                    :
                                                    <div className="col-12 col-sm-6 col-md-3 col-lg-4">
                                                        <div className="managecard-action">


                                                            {(


                                                                (parseInt(cardDetails.cardStatus) === CardStatusType.ACTIVE)) &&
                                                                <button type="button" className="sbtn btnyl" data-bs-toggle="modal" data-bs-target={`#tBlock${cardDetails.cardReferenceNo}`}>{process.env.React_APP_Var_FOR_Employee_Detail_Page_BlockTemporary}</button>}


                                                            {

                                                                (parseInt(cardDetails.cardStatus) === CardStatusType.TEMPORARY_BLOCK) &&
                                                                <button type="button" className="sbtn btnbl" data-bs-toggle="modal" data-bs-target={`#unBlock${cardDetails.cardReferenceNo}`}>{process.env.React_APP_Var_FOR_Employee_Detail_Page_UnLock}</button>}


                                                            {((parseInt(cardDetails.cardStatus) === CardStatusType.ACTIVE) || (parseInt(cardDetails.cardStatus) === CardStatusType.TEMPORARY_BLOCK)) && (cardDetails.cardType !== 0) &&
                                                                <button type="button" className="sbtn btnrd" data-bs-toggle="modal" data-bs-target={`#pBlock${cardDetails.cardReferenceNo}`}>{process.env.React_APP_Var_FOR_Employee_Detail_Page_BlockPermanent}</button>}

                                                        </div>
                                                    </div>
                                            }
                                            <SmallPopUpTempBlock
                                                targetid={`tBlock${cardDetails.cardReferenceNo}`}
                                                headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_PopUp_Lable}
                                                bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_TemporaryBlock_PopUp_Body}
                                                onclickFunctionName={handleTempBlock}
                                                firstName="Hello "
                                                allValues={employeeDetail}
                                                cardDetails={cardDetails}
                                                buttonActionName="Block"
                                                buttonActionClose="Cancel"
                                            ></SmallPopUpTempBlock>
                                            <SmallPopUp
                                                targetid={`pBlock${cardDetails.cardReferenceNo}`}
                                                headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Lable}
                                                bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_PermanentBlock_PopUp_Body}
                                                onclickFunctionName={handlePermanentBlock}
                                                cardDetails={cardDetails}
                                                firstName="Hello "
                                                allValues={employeeDetail}
                                                buttonActionName="Block"
                                                buttonActionClose="Cancel"
                                            ></SmallPopUp>

                                            <SmallPopUp
                                                targetid={`unBlock${cardDetails.cardReferenceNo}`}
                                                headerText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Lable}
                                                bodyText={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Body}
                                                onclickFunctionName={handleCardUnLock}
                                                cardDetails={cardDetails}
                                                firstName={employeeDetail.first_name}
                                                allValues={employeeDetail}
                                                buttonActionName={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_UNLOCK_Button}
                                                buttonActionClose={process.env.React_APP_Var_FOR_ManageEmployee_Page_DropDown_UnBlock_PopUp_Cancel_Button}
                                            ></SmallPopUp>
                                        </div>

                                        <hr />
                                    </>



                                    :
                                    <ResetLoginPassword />
                                }
                            </>
                        )}
                        no={() =>


                            null



                        }
                    />
                )
                : (
                    <Redirect to='/' />
                )



            }
        </>
    )
}


const mapStateToProps = ({ user: { userRole, roMdn, empDetail, currentUser, mdn, fullName, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount }, role: { roleData }, token: { authenticated, isPasswordReset }, band: { bandData } }) => ({
    userRole,
    roMdn,
    roleData,
    authenticated, isPasswordReset, bandData, empDetail, currentUser, mdn, fullName, corpBand, maxFoodAmount, maxGeneralAmount, maxTravelAmount
});


export default connect(mapStateToProps)(EmpMangeCardSection);
