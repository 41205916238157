import Pagination from "react-js-pagination";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setActiveHeader } from '../../../redux/activeHeader/activeheader.action';
import { store } from '../../../redux/store';
import apiCallService from '../../../service/api-call-service';
import CardStatus from '../../../constants/card-status.type';
import CsvDownload from 'react-json-to-csv';
import Select from "react-select";
import moment from 'moment'
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import image1 from "../../../assests/no-image.png"
import { resetOder } from "../../../redux/order/order.action";
import { setEmployeeDetails } from "../../../redux/user/user.actions";
import { setBackUrl } from "../../../redux/user/user.actions";
import MultiCardStatus from '../../../common/component/multiCardStatus.component';


const SubBlockedCards = ({ setEmployee, post, handleChecked }) => {


    const [employee, setEmployeeDetailState] = useState([]);

    const [cardInformation, setCardInformation] = useState([]);
    const [cardNumber, setCardNumber] = useState([]);

    const [loadingMess, setLoadingMess] = useState(false);

    useEffect(() => {

        if (post.employeeDetail !== undefined && post.employeeDetail !== null && post.employeeDetail.card !== undefined) {

            var count = 0;
            post.employeeDetail.card.map(cd => {

                console.log("(parseInt(cd.status)===parseInt(CardStatus.PERMANENT_BLOCK )|| parseInt(cd.status)===parseInt(CardStatus.TEMPORARY_BLOCK)", parseInt(cd.status), "pb", parseInt(CardStatus.PERMANENT_BLOCK), "ststus", parseInt(cd.status), "tb", parseInt(CardStatus.TEMPORARY_BLOCK))
                if (count === 0 && (parseInt(cd.cardStatus) === parseInt(CardStatus.REPLACED) || parseInt(cd.cardStatus) === parseInt(CardStatus.PERMANENT_BLOCK) || parseInt(cd.cardStatus) === parseInt(CardStatus.TEMPORARY_BLOCK))) {
                    setCardNumber(cd.cardReferenceNo);
                    func("", post.employeeDetail.mdn, cd.cardReferenceNo);
                    count++;
                }
            })
        }



    }, []);


    const func = async (event, mdn, carN) => {
        setLoadingMess(true);
        var url = `${process.env.REACT_APP_CORPORATE_CARDSERVICE_GETCARDINFORMATION}?cardRefNumber=${carN}&mdn=${mdn}`;

        var response = await apiCallService.getMethodCallWithoutParam(url);
        console.log("data for REACT_APP_CORPORATE_CARDSERVICE_GETCARDINFORMATION", response);
        if (response !== undefined && response.response_code !== undefined && response.response_code === 0 && response.cardEmployeeDetail !== null) {
            setCardNumber(response.cardEmployeeDetail.cardRefNumber);
            setCardInformation(response.cardEmployeeDetail)
            setLoadingMess(false);
        } else {
            setCardNumber(carN);
            setCardInformation([])
            setLoadingMess(false);
        }

    }

    const setEmployeeDetail = () => {
        store.dispatch(setBackUrl("/blocked-cards"));
        setEmployee(post.employeeDetail);

    }



    return (

        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="usercard">

                <div className="usercheck">
                    <input type="checkbox" id={post.mdn} className="form-check-input" onClick={handleChecked} />

                </div>


                <Link
                    to={{
                        pathname: '/emp-detail',
                        state: { employee: post.employeeDetail, editShowHidden: true }
                    }}
                    onClick={() => setEmployeeDetail(post.employeeDetail)}
                    className="userbtn"
                >


                    <div className="userbasic">
                        <img src="images/user-3.jpg" alt="" />
                        <h5 className="text-truncate">{post.employeeName} </h5>
                        <p>+91-{post.mdn}</p>
                    </div>

                </Link>



                <div className="cardsts" style={{ "cursor": "pointer" }}>

                    <MultiCardStatus changeReason={func} selectedCard={cardNumber} card={post.employeeDetail !== undefined && post.employeeDetail !== null ? post.employeeDetail.card : []} />


                </div>

                {
                    cardInformation !== undefined && cardInformation !== null
                        && cardInformation.updatedBy !== undefined && cardInformation.updatedBy !== ""
                        && cardInformation.dateUpdatedOn !== undefined && cardInformation.dateUpdatedOn !== ""
                        ?
                        <div className="blockview">
                            <div className="blockref">
                                <h5><span>Ref. No.</span> {cardNumber}</h5>
                            </div>
                            <div className="blockby">
                                <p>Blocked By:</p>
                                <h6>{cardInformation.updatedBy}</h6>
                                <p className="date">{cardInformation.dateUpdatedOn}</p>
                            </div>

                            <div className="blockreason">
                                <p>Reason</p>
                                <h6>{cardInformation.message !== undefined && cardInformation.message !== "" ? cardInformation.message : <>NA</>}</h6>
                            </div>
                        </div>
                        : null
                }





            </div>
        </div>


    )

}


const mapStateToProps = ({ band: { bandData }, token: { authenticated }, user: { userRole, roMdn }, role: { roleData } }) => ({
    authenticated, userRole, roleData, roMdn, bandData
});

const mapDispatchToProps = dispatch => ({
    setEmployee: emp => dispatch(setEmployeeDetails(emp))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubBlockedCards);